import React, { useCallback, useEffect } from 'react';
import { Button, COLORS, Stack, toaster, Typography, } from '@humanfirst/elektron';
import { useVersion } from 'src/hooks/version';
const VersionUpdate = () => {
    const { isUpdateAvailable } = useVersion();
    const reload = useCallback(() => window.location.reload(), []);
    useEffect(() => {
        if (isUpdateAvailable) {
            toaster.info(React.createElement(Stack, null,
                React.createElement(Typography, { color: COLORS.white }, "A new release of Atlas is available."),
                React.createElement(Button, { variant: "secondary", onClick: reload }, "Reload Atlas Now")), { autoHideDuration: 0 });
        }
    }, [isUpdateAvailable, reload]);
    return null;
};
export { VersionUpdate };
