import { __awaiter } from "tslib";
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import { useContext, useCallback } from 'react';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from 'src/config/api';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { useLogger } from './logger';
/**
 * A hook that support logging in a new user.
 *
 * WARNING: This requires both of the authentication context and
 * the Atlas api context.
 */
const useLogin = () => {
    const requestToken = useToken();
    return useCallback((credentials) => __awaiter(void 0, void 0, void 0, function* () {
        return yield requestToken({
            grant_type: 'password',
            username: credentials.email,
            password: credentials.password,
        });
    }), [requestToken]);
};
/**
 * A hook for retrieving an access token. This can be done one of two ways:
 * 1. A password grant: Provide the username and password in exchange for an access token.
 * 2. A workos grant: Provide a code from workos in exchange for an access token.
 */
const useToken = () => {
    const { mutateAsync } = useElektraApiMutation(AuthResource.getToken());
    const { setToken, setSessionIdentifier } = useContext(AuthenticationContext);
    return useCallback((tokenRequest) => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield mutateAsync(Object.assign(Object.assign({}, tokenRequest), { scope: 'offline legacy' }));
        const token = resp === null || resp === void 0 ? void 0 : resp.access_token;
        if (token) {
            setToken(token);
            setSessionIdentifier(uuid());
            return token;
        }
    }), [mutateAsync, setToken, , setSessionIdentifier]);
};
/**
 * Axios is used directly here as this is called from an axios interceptor outside of react query context.
 * This is a hook that is used to handle token expiration.
 * It will refresh the token if possible, otherwise it will log the user out.
 */
const useHandleRefreshToken = () => {
    const { setToken } = useContext(AuthenticationContext);
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield axiosInstance.post(`/v2/auth/token`, {
            grant_type: 'refresh_token',
            scope: 'offline legacy',
        }, {
            skipInterceptor: true,
            withCredentials: true,
        });
        const data = response.data;
        setToken(data.access_token);
        return data.access_token;
    }), [setToken]);
};
/**
 * A hook that supports revoking a refresh token.
 */
const useRevokeRefreshToken = () => {
    const logger = useLogger('useRevokeRefreshToken');
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield axiosInstance.post(`/v2/auth/revoke`, {
                token_type_hint: 'refresh_token',
            }, {
                withCredentials: true,
                skipInterceptor: true,
            });
        }
        catch (e) {
            logger.error(e);
            return;
        }
    }), [logger]);
};
/**
 * A hook that supports logging out the current user.
 * If a refresh token is present, it will be revoked
 */
const useLogout = () => {
    const { setToken, shadowerToken, shadoweeToken, setShadowerToken, setSessionIdentifier, setShadoweeToken, } = useContext(AuthenticationContext);
    const revokeRefreshToken = useRevokeRefreshToken();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (shadoweeToken) {
            setToken(shadowerToken);
            setShadowerToken(null);
            setShadoweeToken(null);
            setSessionIdentifier(uuid());
        }
        else {
            setToken(null);
            setSessionIdentifier(null);
            yield revokeRefreshToken();
        }
    }), [
        shadowerToken,
        setToken,
        shadoweeToken,
        setShadowerToken,
        setShadoweeToken,
        setSessionIdentifier,
        revokeRefreshToken,
    ]);
};
/**
 * A hook that indicates whether or not shadowing is occuring.
 * @returns
 */
const useIsShadowing = () => {
    const { shadoweeToken } = useContext(AuthenticationContext);
    return shadoweeToken !== null;
};
/**
 * A hook that allows you start shadowing
 * @returns
 */
const useStartShadowing = () => {
    const { token, setToken, setShadowerToken, setSessionIdentifier, setShadoweeToken, } = useContext(AuthenticationContext);
    return useCallback((newToken) => {
        setShadowerToken(token);
        setToken(newToken);
        setShadoweeToken(newToken);
        setSessionIdentifier(uuid());
    }, [setToken, setShadowerToken, token, setSessionIdentifier, setShadoweeToken]);
};
/**
 * A hook that allows you to stop shadowing.
 * @returns
 */
const useStopShadowing = () => {
    const { shadowerToken, setToken, setShadowerToken, setSessionIdentifier, setShadoweeToken, } = useContext(AuthenticationContext);
    const logger = useLogger('useStopShadowing');
    const navigate = useNavigate();
    const handleRefresh = useHandleRefreshToken();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setShadowerToken(null);
        setShadoweeToken(null);
        setSessionIdentifier(uuid());
        setToken(shadowerToken);
        // If we have refreshed during this shadow time the token will be null. We will need to refetch so the shadower
        // may resume their session unblocked.
        if (!shadowerToken) {
            try {
                yield handleRefresh();
            }
            catch (e) {
                logger.error(e, {
                    message: 'Failed to refresh token after shadowing',
                });
                navigate(generatePath(PATHS.authentication.logout));
            }
        }
    }), [
        setToken,
        setShadowerToken,
        shadowerToken,
        setSessionIdentifier,
        setShadoweeToken,
        handleRefresh,
        navigate,
        logger,
    ]);
};
/** Loads the current authentication token. */
const useAuthenticationToken = () => {
    const { token } = useContext(AuthenticationContext);
    return token;
};
const useSessionIdentifier = () => {
    const { sessionIdentifier } = useContext(AuthenticationContext);
    return sessionIdentifier;
};
export { useLogin, useLogout, useRevokeRefreshToken, useHandleRefreshToken, useIsShadowing, useStartShadowing, useStopShadowing, useAuthenticationToken, useSessionIdentifier, useToken, };
