import { useCallback, useEffect } from 'react';
import { useDebouncedState } from './useDebouncedState';
function useDebouncedMemo(factory, deps, debounceTime) {
    const [value, setValue] = useDebouncedState(factory, debounceTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoFactory = useCallback(factory, deps !== null && deps !== void 0 ? deps : []);
    useEffect(() => {
        setValue(memoFactory());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoFactory, setValue, ...(deps !== null && deps !== void 0 ? deps : [])]);
    return value;
}
export { useDebouncedMemo };
