import { createGenericTable } from '../shared/createGenericTable';
import { CREATED_AT_COLUMN, NAME_COLUMN, STATUS_COLUMN, TEAM_COLUMN, UPDATED_AT_COLUMN, } from './columns';
const columns = [
    NAME_COLUMN,
    STATUS_COLUMN,
    TEAM_COLUMN,
    UPDATED_AT_COLUMN,
    CREATED_AT_COLUMN,
];
const INITIAL_STATE = {
    sort: { column: 'updatedAt', ascending: false },
};
export const WorkspaceTable = createGenericTable({
    columns,
}, undefined, INITIAL_STATE);
/** A version that enables column configuration. Used for experiments. */
export const WorkspaceTableWithConfigurabeColumns = createGenericTable({
    columns,
    configurableColumns: true,
}, undefined, INITIAL_STATE);
