import { Block, Button } from '@humanfirst/elektron';
import React, { memo, useRef, useState, useEffect, useCallback } from 'react';
const ShowMore = ({ children, limitedHeight, }) => {
    const [overflow, setOverflow] = useState('hidden');
    const [viewPortHeight, setViewPortHeight] = useState(limitedHeight);
    const viewPortRef = useRef(null);
    const [showMore, setShowMore] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const updateViewPort = useCallback(() => {
        if (!isExpanded) {
            setOverflow(undefined);
            setViewPortHeight(undefined);
        }
        else {
            setOverflow('hidden');
            setViewPortHeight(limitedHeight);
        }
        setIsExpanded(!isExpanded);
    }, [isExpanded, limitedHeight]);
    /** On its own ref will not update when ref.current changes so
     *  we need to update a showMore state. This useEffect is
     *  necessary to trigger those runs after the useRef and therefore
     *  the ref would be null the first render.  */
    useEffect(() => {
        var _a, _b, _c, _d;
        if (!!((_a = viewPortRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) &&
            !!((_b = viewPortRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) &&
            ((_c = viewPortRef.current) === null || _c === void 0 ? void 0 : _c.scrollHeight) > ((_d = viewPortRef.current) === null || _d === void 0 ? void 0 : _d.offsetHeight)) {
            setShowMore(true);
        }
    }, [limitedHeight, setShowMore]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Block, { maxHeight: viewPortHeight, overflow: overflow, ref: viewPortRef }, children),
        showMore && (React.createElement(Block, { marginLeft: "-8px" },
            React.createElement(Button, { variant: "minimal", onClick: updateViewPort, size: 'compact' }, isExpanded ? 'Show Less' : 'Show More')))));
};
const MemoShowMore = memo(ShowMore);
export { MemoShowMore as ShowMore };
