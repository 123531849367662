import { __awaiter } from "tslib";
import { Button, Spread, Typography, Spacer, TypographyVariant, Modal, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
const ConfirmationModal = ({ onSubmit, title, body, submitText, isOpen, closeModal, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitCallback = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        try {
            yield onSubmit();
        }
        catch (e) {
            // Do nothing
        }
        closeModal();
        setIsSubmitting(false);
    }), [setIsSubmitting, closeModal, onSubmit]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, title),
        React.createElement(Spacer, null),
        React.createElement(Typography, null, body),
        React.createElement(Spacer, { size: '24px' }),
        React.createElement(Spread, { direction: 'horizontal', justifyContent: 'flex-end' },
            React.createElement(Button, { size: "large", variant: "tertiary", onClick: closeModal }, "Cancel"),
            React.createElement(Spacer, null),
            React.createElement(Button, { size: "large", isLoading: isSubmitting, variant: "primary", onClick: submitCallback }, submitText))));
};
export { ConfirmationModal };
