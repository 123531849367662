import React from 'react';
import { Typography, TypographyVariant, Block, Card, Divider, Spread, Heading2, Link, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { ToolImage } from 'src/components/ToolImage';
import { ToolStatusHoverState } from 'src/components/ToolImageWithHoverState/ToolStatusHoverState';
const RecentTechnologyInternal = ({ data: tools }) => {
    var _a, _b, _c;
    return (React.createElement(Block, null,
        React.createElement(Card, { fill: true, padding: '48px' },
            React.createElement(Block, null,
                React.createElement(Spread, null,
                    React.createElement(Heading2, null, "New Technology in Atlas")),
                React.createElement(TechnologyItem, { tool: tools[0], key: (_a = tools[0]) === null || _a === void 0 ? void 0 : _a.id }),
                React.createElement(Divider, null),
                React.createElement(TechnologyItem, { tool: tools[1], key: (_b = tools[1]) === null || _b === void 0 ? void 0 : _b.id }),
                React.createElement(Divider, null),
                React.createElement(TechnologyItem, { tool: tools[2], key: (_c = tools[2]) === null || _c === void 0 ? void 0 : _c.id })))));
};
const RecentTechnology = () => {
    const result = useElektraApi(ToolResource.list({
        join: [
            'evidence',
            'evidence.source',
            'evidence.source.medicalConditions',
            'maker',
        ],
        limit: '3',
        filter: [
            ['ingestionStatus', '$eq', 'Ingested - Full Display'],
            ['componentType', '$excl', 'Model Unspecified'],
        ],
        sort: [['createdAt', 'DESC']],
    }));
    return React.createElement(QueryWrapper, Object.assign({ renderSuccess: RecentTechnologyInternal }, result));
};
const TechnologyItem = ({ tool }) => {
    var _a;
    if (!tool) {
        return null;
    }
    return (React.createElement(Block, { marginBottom: '24px', marginTop: '24px' },
        React.createElement(Spread, { marginTop: "24px", gap: "16px", alignItems: 'flex-start' },
            React.createElement(Block, { width: '30%' },
                React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                        id: tool.id,
                    }) },
                    React.createElement(ToolStatusHoverState, { tool: tool },
                        React.createElement(ToolImage, { tool: tool, imageSize: 120 })))),
            React.createElement(Block, { width: '20%' },
                React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                        id: tool.id,
                    }) }, tool.model),
                React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name)),
            React.createElement(Block, { width: '50%' },
                React.createElement(Typography, { variant: TypographyVariant.Details }, tool.productDescription)))));
};
export { RecentTechnology };
