import { CardContent, CardSurface, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { EvidenceResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { TechnologiesTagged } from 'src/components/TechnologyTagged/TechnologyTagged';
import { isIntegratorAlgorithmUtil } from 'src/utils/vendorType';
import { getIntegratedTech } from '../utils/util';
export const VendorIntegratedTech = ({ organization }) => {
    const integratedTech = getIntegratedTech(organization);
    const { data: algoTech = [] } = useElektraApi(ToolResource.list({
        filter: [['maker', '$eq', organization.id]],
        join: ['vendorStatedIntegrations||id'],
    }));
    const { data: sources = [] } = useElektraApi(EvidenceResource.list({
        filter: [['maker', '$cont', organization.id]],
        join: ['sourceMeasures||id', 'sourceMeasures.tools||sbcSubtype,id'],
    }));
    if (integratedTech.length === 0) {
        return null;
    }
    const evidenceBasedTech = sources
        .flatMap((x) => x.sourceMeasures)
        .flatMap((x) => x === null || x === void 0 ? void 0 : x.tools)
        .filter((t) => !!t)
        .filter((t) => !isIntegratorAlgorithmUtil(t))
        .map((r) => r.id);
    const vendorStatedTech = algoTech
        .flatMap((i) => i.vendorStatedIntegrations)
        .filter((t) => !!t)
        .map((t) => t.id);
    return (React.createElement(CardSurface, null,
        React.createElement(CardContent, null,
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Supported Technologies"),
                React.createElement(TechnologiesTagged, { evidenceBasedTechIds: evidenceBasedTech, vendorStatedTechIds: vendorStatedTech, allTechnologies: integratedTech })))));
};
