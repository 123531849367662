import { useViewportDimensions } from 'src/hooks/viewport-dimensions';
import { useDebouncedMemo } from 'src/hooks/debounce';
const APP_DATA_DEBOUNCE_MS = 10000;
function useAppData() {
    const viewport = useViewportDimensions();
    return useDebouncedMemo(() => ({
        version: process.env.REACT_APP_VERSION,
        screenWidth: viewport.screen.width,
        screenHeight: viewport.screen.height,
        windowWidth: viewport.window.width,
        windowHeight: viewport.window.height,
    }), [viewport], APP_DATA_DEBOUNCE_MS);
}
export { useAppData };
