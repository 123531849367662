import { __rest } from "tslib";
import React from 'react';
import { Icons, Select, expandShorthand, COLORS, } from '@humanfirst/elektron';
import { t } from 'src/constants/i18n';
const overrides = {
    ControlContainer: {
        style: expandShorthand({
            backgroundColor: COLORS.gray4,
            border: `solid ${COLORS.gray3} 1px`,
            height: '40px',
        }),
    },
    SearchIcon: {
        component: Icons.Misc.Search,
    },
    DropdownListItem: {
        style: expandShorthand({
            padding: '0px',
        }),
    },
    Dropdown: {
        style: expandShorthand({
            padding: '0px',
        }),
    },
    StatefulMenu: {
        style: {
            border: 'solid blue',
        },
        props: {
            overrides: {
                EmptyState: {
                    style: expandShorthand({
                        padding: '0px',
                    }),
                },
            },
        },
    },
};
// We don't want the omnisearch select to filter out any
// of our results because the values that come from the API
// are already filtered.  As such, we use this in the `<Select>`
// `filterOptions` prop.
const FILTER_OPTIONS_NOOP = (value) => value;
const OmniSearchSelect = (_a) => {
    var rest = __rest(_a, []);
    return (React.createElement(Select, Object.assign({}, rest, { clearable: true, placeholder: t('search.input.placeholder'), deleteRemoves: true, searchable: true, type: "search", "aria-label": t('search.input.label'), overrides: overrides, filterOptions: FILTER_OPTIONS_NOOP })));
};
export { OmniSearchSelect };
