import { Block, Button, Stack, Tooltip, Typography } from '@humanfirst/elektron';
import React, { useState } from 'react';
export const ComparePDFButton = ({ openModal, technologies }) => {
    const isExportDisabled = technologies.length > 5;
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(Tooltip, { placement: "bottom", triggerType: "hover", content: React.createElement(Block, { width: '160px' },
            React.createElement(Typography, { align: 'center' },
                "Please limit compare to 5 technologies to export as PDF",
                ' ')), isOpen: isOpen && isExportDisabled },
        React.createElement("div", { onPointerEnter: () => setIsOpen(true), onPointerLeave: () => setIsOpen(false) },
            React.createElement(Stack, null,
                React.createElement(Button, { onClick: openModal, disabled: isExportDisabled, variant: 'minimal', size: 'large' }, "To PDF")))));
};
