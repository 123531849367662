import { COLORS, Stack, Tag, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { useElektraApi, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import { uniq } from 'ramda';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { ListContext } from '../../contexts/ListContext';
const OtherListsCell = ({ bookmark }) => {
    var _a;
    const { activeList } = useContext(ListContext);
    const workspaceId = useActiveWorkspaceId();
    const { data } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'tag',
        resource: bookmark.resource,
        resourceType: bookmark.resourceType,
    }));
    const uniqueLists = uniq((_a = data === null || data === void 0 ? void 0 : data.map((x) => x.metadataValue).filter((y) => y !== activeList)) !== null && _a !== void 0 ? _a : []);
    if (uniqueLists.length < 1) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "N/A"));
    }
    return (React.createElement(Stack, { direction: 'horizontal', flexWrap: true, maxWidth: '360px' }, uniqueLists.map((x) => (React.createElement(Tag, { key: x, closeable: false, variant: 'solid', color: COLORS.gray1 }, x)))));
};
const OtherListsColumn = {
    id: 'other-lists',
    header: 'Existing Lists',
    render: (item) => React.createElement(OtherListsCell, { bookmark: item.__bookmark }),
};
export { OtherListsColumn, OtherListsCell };
