import { __awaiter } from "tslib";
import { Block, Breadcrumbs, Center, COLORS, Breakpoint, DisplayBreakpoint, Heading, Heading2, Image, Link, Spacer, toaster, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useCallback } from 'react';
import { useElektraApiMutation, WorkspaceResource, } from '@humanfirst/use-elektra-api';
import { useNavigate } from 'react-router';
import CreateWorkspaceImage from 'src/assets/create-workspace.png';
import { generatePath } from 'src/utils/path';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { WorkspaceCreateForm } from 'src/components/WorkspaceForm';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { PATHS } from 'src/config/path';
const WorkspaceCreate = () => {
    const canCreateWorkspace = useHasPermission('workspace', 'create');
    const { mutateAsync } = useElektraApiMutation(WorkspaceResource.create());
    const navigate = useNavigate();
    const onSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield mutateAsync(data);
        toaster.positive('Project Created', {});
        navigate(generatePath(PATHS.projects.detail, { projectId: result.id }), {
            replace: true,
        });
    }), [mutateAsync, navigate]);
    if (!canCreateWorkspace) {
        return (React.createElement(ContentContainer, null,
            React.createElement(Center, null,
                React.createElement(Typography, null, "You do not have access to create projects"))));
    }
    return (React.createElement(Block, { height: "100%" },
        React.createElement(PageTitle, { title: "Create Project" }),
        React.createElement(Block, { display: "grid", height: "100%", gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr'], gridTemplateRows: "1fr" },
            React.createElement(Block, null,
                React.createElement(Block, { padding: "16px 24px 0px 24px" },
                    React.createElement(Breadcrumbs, { crumbs: [
                            BREADCRUMBS.Workspaces,
                            { url: '', label: 'Create Project' },
                        ] })),
                React.createElement(Block, { padding: "56px" },
                    React.createElement(Heading, { marginBottom: "8px" }, "Create Project"),
                    React.createElement(Typography, { variant: TypographyVariant.Heading3, marginBottom: "32px" }, "Choose your project details and set your research criteria"),
                    React.createElement(WorkspaceCreateForm, { onSubmit: onSubmit }))),
            React.createElement(Block, { padding: "56px", backgroundColor: COLORS.blue4 },
                React.createElement(DisplayBreakpoint, { min: Breakpoint.M },
                    React.createElement(Block, { display: "flex", justifyContent: "center" },
                        React.createElement(Image, { maxWidth: "454px", src: CreateWorkspaceImage, alt: "create a workspace graphic" }))),
                React.createElement(Heading2, { marginBottom: "16px" }, "How It Works"),
                React.createElement(Typography, { variant: TypographyVariant.Heading3, marginBottom: "16px" },
                    React.createElement("ol", null,
                        React.createElement("li", null, "Enter your research criteria. You can edit these at any time."),
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement("li", null, "Review evidence and technology that matches your research. Filter, compare and save your research directly within your project."),
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement("li", null, "Create custom lists to organize and share with your team."))),
                React.createElement(Link, { href: PATHS.helpCenter.usingAtlas.createProject }, "Learn more")))));
};
export { WorkspaceCreate };
