import React from 'react';
import { TypographyVariant, Link, StatefulTooltip, Stack, Text, Block, } from '@humanfirst/elektron';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
export const ToolStatusHoverState = ({ tool, children }) => {
    var _a;
    const isUnavailable = tool.availability === 'Discontinued';
    if (!isUnavailable) {
        return React.createElement(React.Fragment, null, children);
    }
    if (!(tool === null || tool === void 0 ? void 0 : tool.maker)) {
        return (React.createElement(StatefulTooltip, { placement: "auto", triggerType: "hover", content: React.createElement(Stack, { direction: "horizontal", gap: '2px' },
                React.createElement(Text, null, "This tool is discontinued.")) }, children));
    }
    return (React.createElement(StatefulTooltip, { placement: "auto", triggerType: "hover", content: React.createElement(Block, { maxWidth: '160px' },
            React.createElement(Text, null,
                "This tool is discontinued. See other options from",
                ' ',
                React.createElement(Link, { href: generatePath(PATHS.atlas.vendors.detail, {
                        id: tool === null || tool === void 0 ? void 0 : tool.maker.id,
                    }), typographyVariant: TypographyVariant.Body }, (_a = tool === null || tool === void 0 ? void 0 : tool.maker) === null || _a === void 0 ? void 0 :
                    _a.name,
                    "."))) }, children));
};
