import React from 'react';
import { Block, StatefulTooltip } from '@humanfirst/elektron';
import { TECHNOLOGY_TAG_DATA } from './data';
import { TechnologyTag } from './TechnologyTag';
/** Renders an appropriately colored pip for technology tags. */
const TechnologyTagPip = ({ tag }) => {
    const { color, title } = TECHNOLOGY_TAG_DATA[tag];
    return (React.createElement(Block, { display: "flex", alignItems: "center", justifyContent: "center", width: "14px", height: "14px" },
        React.createElement(Block, { borderRadius: '50%', width: "8px", height: "8px", backgroundColor: color },
            React.createElement(Block, { display: "none" }, title))));
};
/** Renders a technology tag pip with a description in a tool tip. */
const TechnologyTagPipWithTooltip = ({ tag }) => {
    return (React.createElement(StatefulTooltip, { content: React.createElement(TechnologyTag, { tag: tag, includeDescription: true }), triggerType: 'hover', placement: "bottom" },
        React.createElement(TechnologyTagPip, { tag: tag })));
};
export { TechnologyTagPipWithTooltip, TechnologyTagPip };
