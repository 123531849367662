import React from 'react';
import { Activate } from 'src/pages/Activate';
import { ForgotPassword } from 'src/pages/ForgotPassword';
import { ForgotPasswordSuccess } from 'src/pages/ForgotPasswordSuccess';
import { Invitation } from 'src/pages/Invitation';
import { Login } from 'src/pages/Login';
import { Logout } from 'src/pages/Logout';
import { MFA } from 'src/pages/MFA/MFA';
import { ResetPassword } from 'src/pages/ResetPassword';
import { Signup } from 'src/pages/Signup';
import { SignupSuccess } from 'src/pages/SignupSuccess';
import { SsoCallback } from 'src/pages/SsoCalback';
export const AuthenticationRoutes = {
    children: {
        signup: {
            path: 'signup',
            children: {
                request: {
                    index: true,
                    element: React.createElement(Signup, null),
                },
                success: {
                    path: 'success',
                    element: React.createElement(SignupSuccess, null),
                },
            },
        },
        forgotPassword: {
            path: 'forgot-password',
            children: {
                request: {
                    index: true,
                    element: React.createElement(ForgotPassword, null),
                },
                success: {
                    path: 'success',
                    element: React.createElement(ForgotPasswordSuccess, null),
                },
            },
        },
        activate: {
            path: 'activate',
            element: React.createElement(Activate, null),
        },
        resetPassword: {
            path: 'reset-password',
            element: React.createElement(ResetPassword, null),
        },
        login: {
            path: 'login',
            children: {
                request: {
                    index: true,
                    element: React.createElement(Login, null),
                },
                ssoCallback: {
                    path: 'sso-callback',
                    element: React.createElement(SsoCallback, null),
                },
            },
        },
        logout: {
            path: 'logout',
            element: React.createElement(Logout, null),
        },
        invitation: {
            path: 'invitation',
            element: React.createElement(Invitation, null),
        },
        mfa: {
            path: 'mfa',
            element: React.createElement(MFA, null),
        },
    },
};
