import { PermissionResource, useElektraApi, AuthResource, } from '@humanfirst/use-elektra-api';
import React, { useEffect, useMemo, useState, createContext, useContext, } from 'react';
import { useIsShadowing } from 'src/hooks/authentication';
import { updateRollbarUser } from 'src/utils/rollbar';
import { LoggerContext } from './LoggerContext';
/** A context to represent the current user. */
const UserContext = createContext({
    user: undefined,
    isLoading: false,
    grants: [],
});
/**
 * Provides the current user.
 *
 * This will attempt to load the user (as long as we have an authentication
 * token).
 */
const UserProvider = ({ children, }) => {
    // Note: This will be disabled by default if we do not have an access token,
    // but will still be marked as isUserLoading. To work around this we combine the loading
    // state with the explicit check if we are authenticated.
    const { data: userData, isInitialLoading: isUserLoading } = useElektraApi(AuthResource.getCurrent(), {
        staleTime: Infinity,
    });
    const { data: grants = [], isInitialLoading: isGrantsLoading } = useElektraApi(PermissionResource.list());
    const { setUser: setDataDogUser } = useContext(LoggerContext);
    const isShadow = useIsShadowing();
    const [userOverrides, setUserOverrides] = useState({});
    const [permissionOverrides, setPermissionOverrides] = useState([]);
    useEffect(() => {
        updateRollbarUser(userData);
        setDataDogUser(userData, { isShadow });
    }, [userData, isShadow, setDataDogUser]);
    const user = useMemo(() => {
        if (!userData) {
            return undefined;
        }
        return Object.assign(Object.assign({}, userData), userOverrides);
    }, [userData, userOverrides]);
    const permissionGrants = useMemo(() => {
        if (permissionOverrides.length) {
            return permissionOverrides;
        }
        return grants;
    }, [grants, permissionOverrides]);
    const isLoading = isUserLoading || isGrantsLoading;
    const value = useMemo(() => ({
        user,
        isLoading,
        setUserOverrides,
        setPermissionOverrides,
        grants: permissionGrants !== null && permissionGrants !== void 0 ? permissionGrants : [],
    }), [user, isLoading, setUserOverrides, permissionGrants]);
    return React.createElement(UserContext.Provider, { value: value }, children);
};
export { UserProvider, UserContext };
