import { WorkspaceStatus } from '@humanfirst/api-models';
import { Button, ButtonLink, Heading, Heading2, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceResource } from '@humanfirst/use-elektra-api';
import React, { useState } from 'react';
import { When } from 'react-if';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { Can } from 'src/components/Permissions';
import { WorkspaceTable } from 'src/components/WorkspaceTable';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { IfNotToggle, IfToggle } from 'src/components/IfToggle';
import { WorkspaceTableWithConfigurabeColumns } from 'src/components/tables/WorkspaceTable';
import { WorkspaceTemplates } from './components/WorkspaceTemplates';
import { ArchivedWorkspacesTable } from './components/ArchivedWorkspacesTable';
const WorkspacesInternal = ({ data: workspaces }) => {
    const [showArchived, setShowArchived] = useState(false);
    const availableWorkspaces = workspaces.filter((x) => !(x.status === WorkspaceStatus.Archived || x.locked));
    const archivedWorkspaces = workspaces.filter((x) => x.status === WorkspaceStatus.Archived);
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Workspaces] },
        React.createElement(PageTitle, { title: "My Projects" }),
        React.createElement(Stack, { gap: "32px" },
            React.createElement(Spread, { direction: "horizontal", alignItems: "start" },
                React.createElement(Heading, { marginBottom: "0px" }, "Projects"),
                React.createElement(Can, { resource: "workspace", action: "create" },
                    React.createElement(ButtonLink, { href: generatePath(PATHS.projects.create), size: "large" }, "Create New Project"))),
            React.createElement(WorkspaceTemplates, { hasWorkspaces: availableWorkspaces.length > 0 }),
            React.createElement(When, { condition: availableWorkspaces.length > 0 },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "My Projects"),
                React.createElement(IfToggle, { name: "custom-columns" },
                    React.createElement(WorkspaceTableWithConfigurabeColumns, { data: availableWorkspaces })),
                React.createElement(IfNotToggle, { name: "custom-columns" },
                    React.createElement(WorkspaceTable, { data: availableWorkspaces }))),
            React.createElement(When, { condition: !showArchived && archivedWorkspaces.length > 0 },
                React.createElement(Stack, { direction: 'horizontal', alignItems: "center" },
                    React.createElement(Button, { variant: "tertiary", onClick: () => setShowArchived(true) },
                        "Show Archived Projects (",
                        archivedWorkspaces.length,
                        ")"))),
            React.createElement(When, { condition: showArchived && archivedWorkspaces.length > 0 },
                React.createElement(Heading2, null, "Archived Projects"),
                React.createElement(ArchivedWorkspacesTable, { data: archivedWorkspaces })))));
};
const Workspaces = () => {
    const result = useElektraApi(WorkspaceResource.list());
    return React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: WorkspacesInternal }));
};
export { Workspaces };
