import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
/**
 * Returns the currently logged in user, if any.
 */
const useUser = () => {
    const { user } = useContext(UserContext);
    return user;
};
export { useUser };
