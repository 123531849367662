import { useElektraApi, TherapeuticAreaResource, MedicalConditionsResource, ToolResource, } from '@humanfirst/use-elektra-api';
import { FilterType } from '@humanfirst/elektron';
import { deserializeWorkspaceMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
import { getStudyWithRelationships } from './getStudyWithRelationships';
const getToolIdsFromStudy = (study) => {
    const parsedMetadata = deserializeWorkspaceMetadata(study.additionalMetadata);
    const { measureMetadata } = splitMetadata(parsedMetadata);
    return measureMetadata
        .filter((m) => m.field.measuresLabel === 'conceptsOfInterest')
        .map((m) => m.field.toolId);
};
const checkStudyTools = (study, filterValue) => {
    if (typeof filterValue !== 'string') {
        return true;
    }
    return !!study.tools && study.tools.includes(filterValue);
};
const isIncludesMatch = (study, filter) => {
    const { fieldId, filterValue } = filter;
    if (!Array.isArray(filterValue)) {
        return false;
    }
    switch (fieldId) {
        case 'toolIds':
            return study.relationships.tools.some((tool) => filterValue.includes(tool.id));
        case 'techMaker':
            return study.relationships.tools.some((tool) => { var _a; return filterValue.includes((_a = tool.maker) === null || _a === void 0 ? void 0 : _a.id); });
        default:
            return false;
    }
};
const isEqualMatch = (study, filter) => {
    const { fieldId, filterValue } = filter;
    switch (fieldId) {
        case 'medicalConditions':
            return study.relationships.medicalConditions.some((mc) => mc.name === filterValue);
        case 'therapeuticAreas':
            return study.relationships.therapeuticAreas.some((ta) => ta.therapeuticArea === filterValue);
        case 'allMeasuresFlat':
        case 'conceptsOfInterest':
            return study.relationships.measureCategories.some((m) => m.name === filterValue);
        case 'teamId':
            return !(!study.team || study.team.id !== filterValue);
        case 'technologies':
            return checkStudyTools(study, filterValue);
        default:
            return false;
    }
};
const isMatching = (study, filters) => {
    if (!filters) {
        return true;
    }
    for (const filter of filters) {
        const { filterType } = filter;
        if (filterType === FilterType.EQUALS) {
            return isEqualMatch(study, filter);
        }
        if (filterType === FilterType.INCLUDES_ANY) {
            return isIncludesMatch(study, filter);
        }
    }
    return true;
};
export const useStudiesWithRelationships = (data, scope) => {
    const therapeuticAreaIds = Array.from(new Set(data
        .map((study) => study.therapeuticArea)
        .filter((id) => typeof id === 'string')
        .filter((id) => id.length > 0)));
    const medicalConditionIds = Array.from(new Set(data
        .flatMap((study) => study.medicalConditions)
        .filter((id) => typeof id === 'string')
        .filter((id) => id.length > 0)));
    const toolIds = Array.from(new Set(data.flatMap((study) => getToolIdsFromStudy(study))));
    const { data: therapeuticAreas = [] } = useElektraApi(TherapeuticAreaResource.getBulk(therapeuticAreaIds), {
        enabled: therapeuticAreaIds.length > 0,
    });
    const { data: medicalConditions } = useElektraApi(MedicalConditionsResource.getBulk(medicalConditionIds), {
        enabled: medicalConditionIds.length > 0,
    });
    const { data: tools } = useElektraApi(ToolResource.getBulk(toolIds, { join: ['maker||id'] }), {
        enabled: toolIds.length > 0,
    });
    return data
        .map((x) => {
        return getStudyWithRelationships(x, {
            technologies: tools !== null && tools !== void 0 ? tools : [],
            medicalConditions: medicalConditions !== null && medicalConditions !== void 0 ? medicalConditions : [],
            therapeuticAreas: therapeuticAreas !== null && therapeuticAreas !== void 0 ? therapeuticAreas : [],
        });
    })
        .filter((x) => isMatching(x, scope));
};
