import React from 'react';
import { MedicalConditionsResource, TherapeuticAreaResource, } from '@humanfirst/use-elektra-api';
import { toOptions } from '@humanfirst/elektron';
import { SmartForm } from 'src/components/SmartForm';
import { SmartInput, SmartSelect, SmartTextarea, } from 'src/components/SmartForm/fields';
import { HARD_CODED_COIS } from 'src/constants/projectConceptsOfInterest';
import { SmartApiModelSelect } from '../SmartForm/SmartApiModelSelect';
import { serialize } from './utils/serialization';
const WorkspaceCreateFormInternals = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(SmartInput, { label: "Project Name", name: "name", id: "projectName", autoComplete: "off", rules: { required: true } }),
        React.createElement(SmartTextarea, { label: "Description (Optional)", name: "description", id: "description", rows: 4 }),
        React.createElement(SmartApiModelSelect, { id: "therapeuticAreas", label: "Therapeutic Area (Optional)", name: "therapeuticAreas", optionGetter: (ta) => ({
                id: ta.therapeuticArea,
                label: ta.therapeuticArea,
            }), definition: TherapeuticAreaResource.list(), labelKey: "therapeuticArea", multi: true }),
        React.createElement(SmartApiModelSelect, { id: "medicalConditions", label: "Medical Condition (Optional)", name: "medicalConditions", optionGetter: (mc) => ({ id: mc.name, label: mc.name }), definition: MedicalConditionsResource.list(), labelKey: "name", multi: true }),
        React.createElement(SmartSelect, { id: "allMeasuresFlat", label: "Concept of Interest (Optional)", name: "allMeasuresFlat", options: toOptions(HARD_CODED_COIS), multi: true })));
};
const WorkspaceCreateForm = ({ onSubmit }) => {
    return (React.createElement(SmartForm, { onSubmit: (data) => onSubmit(serialize(data)), submitText: "Save" },
        React.createElement(WorkspaceCreateFormInternals, null)));
};
export { WorkspaceCreateForm, WorkspaceCreateFormInternals };
