import { Block, Button, ButtonLink, Icons, SelectContext, Tooltip, Typography, } from '@humanfirst/elektron';
import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { PATHS } from 'src/config/path';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
const CompareButtonInternal = ({ isDisabled, technologies }) => {
    const workspaceId = useActiveWorkspaceId();
    const href = workspaceId
        ? generatePath(PATHS.projects.compare, { projectId: workspaceId }) +
            `?selection=${technologies.map((x) => x).join(',')}`
        : generatePath(PATHS.compare) +
            `?selection=${technologies.map((x) => x).join(',')}`;
    if (isDisabled) {
        return (React.createElement(Button, { variant: "secondary", disabled: isDisabled, startEnhancer: React.createElement(Icons.Misc.Compare, { size: "16px", decorative: true }) }, "Compare"));
    }
    return (React.createElement(ButtonLink, { variant: "secondary", href: href, startEnhancer: React.createElement(Icons.Misc.Compare, { size: "16px", decorative: true }) }, "Compare"));
};
const CompareButton = () => {
    const { selection: compareTechnologies } = useContext(SelectContext);
    const isCompareDisabled = compareTechnologies.length < 2;
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(Tooltip, { placement: "bottom", triggerType: "hover", content: React.createElement(Block, { width: '160px' },
            React.createElement(Typography, { align: 'center' }, "Select 2 or more technologies you'd like to compare.")), isOpen: isOpen },
        React.createElement("div", { onPointerEnter: () => setIsOpen(true), onPointerLeave: () => setIsOpen(false) },
            React.createElement(CompareButtonInternal, { isDisabled: isCompareDisabled, technologies: compareTechnologies }))));
};
export { CompareButton, CompareButtonInternal };
