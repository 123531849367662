import { __rest } from "tslib";
import React, { useCallback, } from 'react';
import { Checkbox, DatePicker, FormControl, Input, Select, Textarea, RadioGroup, MarkdownInput, } from '@humanfirst/elektron';
import { AtlasMarkdownPreview } from '../AtlasMarkdown';
import { useSmartFormController } from './useSmartFormController';
const SmartCheckbox = (_a) => {
    var { label, inputRef, rules, defaultValue = false, tooltip, name: fieldName } = _a, props = __rest(_a, ["label", "inputRef", "rules", "defaultValue", "tooltip", "name"]);
    const { field: { onChange, onBlur, name, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    const onChangeCallback = useCallback((e) => onChange(e.currentTarget.checked), [onChange]);
    return (React.createElement(FormControl, { tooltip: tooltip, error: error, 
        // This actually does nothing accessibility wise. Just display label. The actual label text will be the children of the checkbox component
        label: label },
        React.createElement(Checkbox, Object.assign({ onBlur: onBlur, onChange: onChangeCallback, checked: value, inputRef: ref, name: name }, props))));
};
const SmartDatePicker = (_a) => {
    var { label, tooltip, rules, defaultValue = false, name: fieldName, inputRef } = _a, props = __rest(_a, ["label", "tooltip", "rules", "defaultValue", "name", "inputRef"]);
    const { field: { onChange, onBlur, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    const onChangeCallback = useCallback(({ date }) => onChange(date), [onChange]);
    return (React.createElement(FormControl, { error: error, label: label, tooltip: tooltip },
        React.createElement(DatePicker, Object.assign({ label: label, onBlur: onBlur, onChange: onChangeCallback, clearable: true, value: value, inputRef: ref }, props))));
};
function isSelectOption(value) {
    if (!Array.isArray(value)) {
        return false;
    }
    return value.every((v) => {
        if (typeof v !== 'object' || v === null) {
            return false;
        }
        const maybeOption = v;
        return !('id' in maybeOption &&
            typeof maybeOption.id !== 'string' &&
            typeof maybeOption.id !== 'number');
    });
}
const EMPTY_ARRAY = [];
const SmartSelect = (_a) => {
    var { rules, label, tooltip, defaultValue = EMPTY_ARRAY, name: fieldName, inputRef } = _a, props = __rest(_a, ["rules", "label", "tooltip", "defaultValue", "name", "inputRef"]);
    const { field: { onChange, onBlur, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    const onChangeCallback = useCallback(({ value: newValue }) => onChange(newValue), [onChange]);
    return (React.createElement(FormControl, { label: label, tooltip: tooltip, error: error },
        React.createElement(Select, Object.assign({ onBlur: onBlur, onChange: onChangeCallback, value: isSelectOption(value) ? value : undefined, controlRef: ref }, props))));
};
const SmartTextarea = (_a) => {
    var { rules, label, tooltip, defaultValue = '', name: fieldName, inputRef } = _a, props = __rest(_a, ["rules", "label", "tooltip", "defaultValue", "name", "inputRef"]);
    const { field: { onChange, onBlur, name, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    return (React.createElement(FormControl, { label: label, tooltip: tooltip, error: error },
        React.createElement(Textarea, Object.assign({ name: name, onChange: onChange, value: value, inputRef: ref, onBlur: onBlur }, props))));
};
const SmartRadioGroup = (_a) => {
    var { rules, label, tooltip, defaultValue = '', name: fieldName, inputRef } = _a, props = __rest(_a, ["rules", "label", "tooltip", "defaultValue", "name", "inputRef"]);
    const { field: { onChange, onBlur, name, value }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    return (React.createElement(FormControl, { label: label, tooltip: tooltip, error: error },
        React.createElement(RadioGroup, Object.assign({ name: name, onBlur: onBlur, value: value, onChange: onChange }, props))));
};
const SmartInput = (_a) => {
    var { label, tooltip, rules, name: fieldName, defaultValue = '', inputRef } = _a, props = __rest(_a, ["label", "tooltip", "rules", "name", "defaultValue", "inputRef"]);
    const { field: { onChange, onBlur, name, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    return (React.createElement(FormControl, { label: label, tooltip: tooltip, error: error },
        React.createElement(Input, Object.assign({ onChange: onChange, onBlur: onBlur, name: name, value: value, inputRef: ref }, props))));
};
const renderAtlasMarkdown = (markdown) => (React.createElement(AtlasMarkdownPreview, { content: markdown }));
const SmartMarkdownInput = (_a) => {
    var { rules, label, tooltip, defaultValue = '', name: fieldName, inputRef } = _a, props = __rest(_a, ["rules", "label", "tooltip", "defaultValue", "name", "inputRef"]);
    const { field: { onChange, onBlur, name, value, ref }, error, } = useSmartFormController({
        name: fieldName,
        rules,
        defaultValue,
        inputRef,
    });
    return (React.createElement(FormControl, { label: label, tooltip: tooltip, error: error },
        React.createElement(MarkdownInput, Object.assign({ customMarkdownRenderer: renderAtlasMarkdown, name: name, onChange: onChange, value: value, inputRef: ref, onBlur: onBlur }, props))));
};
export { SmartInput, SmartSelect, SmartTextarea, SmartCheckbox, SmartRadioGroup, SmartDatePicker, SmartMarkdownInput, };
