import { Link, Typography, TypographyVariant } from '@humanfirst/elektron';
import React from 'react';
import { SmartForm } from 'src/components/SmartForm';
import { SmartRecaptcha } from 'src/components/SmartForm/SmartRecaptcha';
const SignupForm = ({ name, email, onSubmit, }) => {
    return (React.createElement(SmartForm, { onSubmit: onSubmit, submitText: 'Create Account', defaultValues: {
            name,
            email,
            subscribeUpdates: true,
        } },
        React.createElement(SmartForm.Input, { label: "Name", rules: { required: true }, id: "name", name: "name", placeholder: "Full Name" }),
        React.createElement(SmartForm.Input, { label: "Work Email", rules: { required: true }, id: "email", name: "email", type: "email", placeholder: "name@company.com" }),
        React.createElement(SmartForm.Input, { label: "Create Password", tooltip: React.createElement(Typography, { variant: TypographyVariant.Body }, "Your password must contain at least 8 characters, at least 1 number, and at least 1 special character."), rules: { required: true, minLength: 8 }, id: "password", name: "password", type: "password", placeholder: "Password" }),
        React.createElement(SmartForm.Checkbox, { name: "readTos", rules: { required: true } },
            React.createElement(React.Fragment, null,
                "I\u2019ve read and accepted the",
                ' ',
                React.createElement(Link, { href: "https://www.gohumanfirst.com/terms-and-conditions" }, "Terms and Conditions"))),
        React.createElement(SmartForm.Checkbox, { name: "subscribeUpdates" }, "I want to receive updates about features and industry news"),
        React.createElement(SmartRecaptcha, null)));
};
export { SignupForm };
