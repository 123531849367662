import React from 'react';
import { Block, Card, COLORS, FillContainer, Stack } from '@humanfirst/elektron';
import { StatusIndicator } from '../StatusIndicator';
/**
 * A card that contains the primary content.
 *
 * This component is optimized for when the page contains a single
 * primary action (login/sign-up).
 */
const PrimaryContentCard = ({ children, }) => {
    return (React.createElement(FillContainer, { display: "flex", justifyContent: "center", alignItems: "center", fillHeight: "viewport", backgroundColor: COLORS.gray4 },
        React.createElement(Block, { maxWidth: "600px", width: "100%", marginTop: "32px" },
            React.createElement(Stack, { gap: "24px" },
                React.createElement(StatusIndicator, null),
                React.createElement(Card, null,
                    React.createElement(Block, { padding: "48px" }, children))))));
};
export { PrimaryContentCard };
