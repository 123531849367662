import React from 'react';
import { Block, COLORS, Icons, Link, Spacer, Stack, Text, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { t } from 'src/constants/i18n';
import { attachAttributeMetadata, DEFAULT_NOT_SPECIFIED_TEXT, } from './ModelAttribute';
import { AttributeTooltip } from './ModelAttributeTooltip';
var PolicyState;
(function (PolicyState) {
    // The policy is known to exist and has a valid URL.
    PolicyState[PolicyState["EXISTS"] = 0] = "EXISTS";
    // The policy is not available online (the record may exist,
    // but there is no URL
    PolicyState[PolicyState["UNAVAILABLE"] = 1] = "UNAVAILABLE";
    // The policy is known not to exist
    PolicyState[PolicyState["DOES_NOT_EXIST"] = 2] = "DOES_NOT_EXIST";
})(PolicyState || (PolicyState = {}));
const POLICY_ICON_SIZE = '18px';
const getPolicyState = (policy) => {
    if (!policy) {
        // We have no record. We will default to not available.
        return PolicyState.UNAVAILABLE;
    }
    if (policy.doesNotExist) {
        // The policy is known to not exist.
        return PolicyState.DOES_NOT_EXIST;
    }
    if (!policy.url) {
        // The policy record exists, but we don't have a URL.
        return PolicyState.UNAVAILABLE;
    }
    // The policy exists and we have valid URL.
    return PolicyState.EXISTS;
};
const PolicyAttribute = ({ icon, subtitle, children }) => (React.createElement(Stack, { direction: "horizontal" },
    React.createElement(Block, { width: POLICY_ICON_SIZE, display: "flex", flexDirection: "row", justifyContent: "center" }, icon),
    React.createElement(Stack, null,
        React.createElement(Text, null, children),
        subtitle ? (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, subtitle)) : null)));
const AcceptableAttribute = ({ subtitle, children }) => (React.createElement(PolicyAttribute, { icon: React.createElement(Icons.Product.Yes, { size: POLICY_ICON_SIZE, decorative: true }), subtitle: subtitle }, children));
const UnclearAttribute = ({ subtitle, children }) => (React.createElement(PolicyAttribute, { icon: 
    // This is using the yes icon, but with a different color scheme.
    React.createElement(Icons.Product.Yes, { color1: COLORS.gray3, color2: COLORS.white, size: "18px", title: "Unclear" }), subtitle: subtitle }, children));
function buildPolicyAttribute(definition) {
    var _a;
    const doesNotExist = definition.doesNotExist;
    const doesNotExistTooltip = (_a = definition.doesNotExistTooltip) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const unavailable = definition.unavailable;
    const unavailableTooltip = definition.unavailableTooltip;
    const datedTooltip = definition.datedTooltip;
    const notDatedTooltip = definition.notDatedTooltip;
    const exists = definition.exists;
    const existsTooltip = definition.existsTooltip;
    const component = ({ item }) => {
        const state = getPolicyState(item);
        if (state === PolicyState.DOES_NOT_EXIST) {
            return (React.createElement(UnclearAttribute, null,
                React.createElement(Stack, { gap: "0px", direction: "horizontal", alignItems: "center" },
                    doesNotExist,
                    React.createElement(When, { condition: !!doesNotExistTooltip },
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement(AttributeTooltip, { tooltip: doesNotExistTooltip })))));
        }
        else if (state === PolicyState.UNAVAILABLE) {
            return (React.createElement(UnclearAttribute, null,
                React.createElement(Stack, { gap: "0px", direction: "horizontal", alignItems: "center" },
                    unavailable,
                    React.createElement(When, { condition: !!unavailableTooltip },
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement(AttributeTooltip, { tooltip: unavailableTooltip })))));
        }
        else {
            const dated = (item === null || item === void 0 ? void 0 : item.date) && (item === null || item === void 0 ? void 0 : item.date) !== 'Not listed';
            const policyDated = dated ? `Dated ${item === null || item === void 0 ? void 0 : item.date}` : `Policy not dated`;
            const policyDatedTooltip = dated ? datedTooltip : notDatedTooltip;
            return (React.createElement(AcceptableAttribute, { subtitle: React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                    policyDated,
                    React.createElement(When, { condition: !!policyDatedTooltip },
                        React.createElement(AttributeTooltip, { tooltip: policyDatedTooltip }))) },
                React.createElement(Stack, { gap: "0px", direction: "horizontal", alignItems: "center" },
                    React.createElement(Link, { href: item === null || item === void 0 ? void 0 : item.url }, exists !== null && exists !== void 0 ? exists : item === null || item === void 0 ? void 0 : item.name),
                    React.createElement(When, { condition: !!existsTooltip },
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement(AttributeTooltip, { tooltip: existsTooltip })))));
        }
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Holds all of the attributes we care about for a policy.
 */
const PolicyAttributes = {
    PrivacyPolicy: buildPolicyAttribute({
        label: t('policies.privacy.label'),
        existsTooltip: {
            maker: t('policies.privacy.existsTooltip.maker'),
            searcher: t('policies.privacy.existsTooltip.searcher'),
        },
        doesNotExist: t('policies.privacy.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.privacy.doesNotExistTooltip.maker'),
            searcher: t('policies.privacy.doesNotExistTooltip.searcher'),
        },
        unavailable: t('policies.privacy.unavailable'),
        unavailableTooltip: {
            maker: t('policies.privacy.unavailableTooltip.maker'),
            searcher: t('policies.privacy.unavailableTooltip.searcher'),
        },
        datedTooltip: {
            searcher: t('policies.privacy.datedTooltip.searcher'),
        },
        notDatedTooltip: {
            searcher: t('policies.privacy.notDatedTooltip.searcher'),
        },
    }),
    ProductPrivacyPolicy: buildPolicyAttribute({
        label: t('policies.productPrivacy.label'),
        exists: t('policies.productPrivacy.exists'),
        unavailable: t('policies.productPrivacy.unavailable'),
        doesNotExist: t('policies.productPrivacy.doesNotExist'),
    }),
    CVDPolicy: buildPolicyAttribute({
        label: t('policies.cvd.label'),
        exists: t('policies.cvd.exists'),
        existsTooltip: {
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        unavailable: t('policies.cvd.unavailable'),
        unavailableTooltip: {
            maker: t('policies.cvd.unavailableTooltip.maker'),
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746-020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        doesNotExist: t('policies.cvd.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.cvd.doesNotExistTooltip.maker'),
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746-020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        datedTooltip: {
            searcher: t('policies.cvd.datedTooltip.searcher'),
        },
    }),
    SoftwareUpdatesPolicy: buildPolicyAttribute({
        label: t('policies.softwareUpdates.label'),
        exists: t('policies.softwareUpdates.exists'),
        unavailable: t('policies.softwareUpdates.unavailable'),
        unavailableTooltip: {
            maker: t('policies.softwareUpdates.unavailableTooltip.maker'),
            searcher: t('policies.softwareUpdates.unavailableTooltip.searcher'),
        },
        doesNotExist: t('policies.softwareUpdates.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.softwareUpdates.doesNotExistTooltip.maker'),
            searcher: t('policies.softwareUpdates.doesNotExistTooltip.searcher'),
        },
        datedTooltip: {
            searcher: t('policies.softwareUpdates.datedTooltip.searcher'),
        },
    }),
    HIPAAPolicy: buildPolicyAttribute({
        label: t('policies.hipaa.label'),
        exists: t('policies.hipaa.label'),
        unavailable: t('policies.hipaa.label'),
        unavailableTooltip: {
            maker: t('policies.hipaa.tooltip'),
            searcher: t('policies.hipaa.tooltip'),
        },
        doesNotExist: t('policies.hipaa.label'),
        doesNotExistTooltip: {
            maker: t('policies.hipaa.tooltip'),
            searcher: t('policies.hipaa.tooltip'),
        },
        datedTooltip: {
            searcher: t('policies.hipaa.datedTooltip.searcher'),
        },
    }),
    GDPRPolicy: buildPolicyAttribute({
        label: t('policies.gdpr.label'),
        exists: t('policies.gdpr.label'),
        unavailable: t('policies.gdpr.label'),
        unavailableTooltip: {
            maker: t('policies.gdpr.tooltip'),
            searcher: t('policies.gdpr.tooltip'),
        },
        doesNotExist: t('policies.gdpr.label'),
        doesNotExistTooltip: {
            maker: t('policies.gdpr.tooltip'),
            searcher: t('policies.gdpr.tooltip'),
        },
        datedTooltip: {
            searcher: t('policies.gdpr.datedTooltip.searcher'),
        },
    }),
};
export { buildPolicyAttribute, PolicyAttributes };
