import React, { useContext, useMemo } from 'react';
import { Stack, Link, Typography, TypographyVariant, Block, Spinner, } from '@humanfirst/elektron';
import { Else, If, Then, When } from 'react-if';
import { MedicalConditionsResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { uniqBy } from 'ramda';
import { ToolImage } from 'src/components/ToolImage';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { getMeasurementCategory, parseQuotedStrings, } from 'src/utils/measurements';
import { checkIsCEMarked } from 'src/utils/regulatoryStatus';
import { ToolStatusHoverState } from 'src/components/ToolImageWithHoverState';
import { LimitedList } from 'src/components/LimitedList';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { ConnectHitIndicator } from '../shared/ConnectHitIndicator/ConnectHitIndicator';
/** Renders the product model, maker, and an image. */
const ProductCell = ({ item }) => {
    var _a;
    const { isViewingFromConnectContext } = useContext(ConnectContext);
    const hasConnect = useHasPermission('connect', 'read', 'all');
    return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: "16px", width: '320px' },
        React.createElement(ToolStatusHoverState, { tool: item },
            React.createElement(ToolImage, { tool: item, imageSize: 72 })),
        React.createElement(Block, null,
            React.createElement(Stack, { direction: 'horizontal' },
                React.createElement(Link, { href: `${generatePath(PATHS.atlas.technologies.detail, {
                        id: item.id,
                    })}${isViewingFromConnectContext ? '?view=1' : ''}` }, item.model),
                React.createElement(When, { condition: hasConnect },
                    React.createElement(ConnectHitIndicator, { tool: item }))),
            React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = item.maker) === null || _a === void 0 ? void 0 : _a.name))));
};
/** Renders the count of evidence measures. */
const EvidenceCell = ({ item }) => {
    var _a;
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = item.countOfEvidenceMeasures) !== null && _a !== void 0 ? _a : 0));
};
/** Renders the cost of a product. */
const CostCell = ({ item }) => {
    var _a;
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = item.priceCompare) !== null && _a !== void 0 ? _a : 'Contact Manufacturer'));
};
/** Renders the regulatory decisions of a product. */
const RegulatoryCell = ({ item }) => {
    const hasCeMark = checkIsCEMarked(item);
    return (React.createElement(Block, null,
        React.createElement(When, { condition: hasCeMark },
            React.createElement(Typography, { variant: TypographyVariant.Details }, "CE Marked")),
        React.createElement(When, { condition: item.fdaStatus },
            React.createElement(Typography, { variant: TypographyVariant.Details }, item.fdaStatus))));
};
/** Renders the medical conditions associated with a product. */
const MedicalConditionCell = ({ item }) => {
    const { data = [], isLoading } = useElektraApi(MedicalConditionsResource.list({
        filter: [['tools', '$cont', item.id]],
    }));
    const sortedMedicalConditions = useMemo(() => [...data].sort((a, b) => a.name.localeCompare(b.name)), [data]);
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(Block, { width: '160px' },
        React.createElement(LimitedList, { limit: 1, elements: (sortedMedicalConditions === null || sortedMedicalConditions === void 0 ? void 0 : sortedMedicalConditions.map((m) => (React.createElement(Block, { key: m.id },
                React.createElement(Link, { typographyVariant: TypographyVariant.Details, href: generatePath(PATHS.atlas.medicalConditions.detail, {
                        id: m.id,
                    }) }, m.name))))) || [] })));
};
/** Renders the measures associated with a product. */
const MeasuresCell = ({ item }) => {
    var _a;
    const measuresCombined = [];
    for (const measure of (_a = item.measuresCategory) !== null && _a !== void 0 ? _a : []) {
        const measureNames = getMeasurementCategory(measure);
        const measureNameFull = parseQuotedStrings([measure]);
        const fullName = measureNameFull.length > 0 ? measureNameFull[0] : undefined;
        measuresCombined.push({ name: measureNames, fullName: fullName });
    }
    const uniqueMeasures = uniqBy((x) => x.fullName, measuresCombined);
    uniqueMeasures.sort((a, b) => a.name.localeCompare(b.name));
    return (React.createElement(React.Fragment, null,
        React.createElement(LimitedList, { elements: uniqueMeasures === null || uniqueMeasures === void 0 ? void 0 : uniqueMeasures.map((m) => (React.createElement(Block, { key: m.fullName },
                React.createElement(If, { condition: m.fullName },
                    React.createElement(Then, null,
                        React.createElement(Link, { href: generatePath(PATHS.atlas.measuresName, {
                                name: encodeURIComponent(m.fullName),
                            }), typographyVariant: TypographyVariant.Details }, m.name)),
                    React.createElement(Else, null,
                        React.createElement(Typography, { variant: TypographyVariant.Details }, m.name)))))), limit: 1 })));
};
export { ProductCell, MeasuresCell, CostCell, EvidenceCell, RegulatoryCell, MedicalConditionCell, };
