import { Block, COLORS, Icons, Link, SemanticButton, Spacer, Spread, Stack, StatefulTooltip, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { uniq } from 'ramda';
import React, { useCallback } from 'react';
import { Else, If, Then, When } from 'react-if';
import { BackButton } from 'src/components/BackButton';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { DATA_RIGHTS_SECURITY_ATTRIBUTES, EVIDENCE_ATTRIBUTES, EVIDENCE_REGULATORY_ATTRIBUTES, getAttributesForComponentType, PRICE_AVAILABILITY_ATTRIBUTES, ToolAttribute, } from 'src/components/ModelAttribute/ToolAttribute';
import { ToolImage } from 'src/components/ToolImage';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { ComponentInstrumentTypeTag } from 'src/components/ComponentInstrumentTypeTag';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { WorkspaceResourceComments, WorkspaceResourceCommentsIcon, } from 'src/components/WorkspaceResourceComments';
import { SharedAttributeContextProvider } from 'src/contexts/SharedAttributeContext';
import { LoadingState } from 'src/components/LoadingState';
import { ExportTechButton } from 'src/components/ExportTechButton';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { CompareCellContent, CompareHeading, CompareRow, CompareSection, LockableCompareSection, } from '../CompareTable';
const EmptyStateText = () => (React.createElement(Block, { display: "flex", alignItems: "center", minHeight: "100%", paddingLeft: "56px" },
    React.createElement(Block, { width: "450px" },
        React.createElement(Typography, { variant: TypographyVariant.Body },
            "Add products from the search results page to compare them. Click the back button or",
            ' ',
            React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.list) }, "explore technologies"),
            "."))));
const DescriptionRow = ({ tools }) => {
    return (React.createElement(CompareRow, null,
        React.createElement(CompareHeading, null,
            React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.gray1 }, "Description")),
        tools.map((tool) => {
            var _a;
            return (React.createElement(CompareCellContent, { key: tool.id },
                React.createElement(If, { condition: !tool.productDescription },
                    React.createElement(Then, null,
                        React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.gray3 }, "No description")),
                    React.createElement(Else, null,
                        React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.black }, (_a = tool.productDescription) !== null && _a !== void 0 ? _a : '')))));
        })));
};
const CompareBoardHeaderComments = ({ resource, }) => {
    const workspaceId = useActiveWorkspaceId();
    const tooltipContent = useCallback(() => (React.createElement(WorkspaceResourceComments, { workspaceId: workspaceId !== null && workspaceId !== void 0 ? workspaceId : 'invalid', resourceType: 'Tool', resource: resource })), [workspaceId, resource]);
    if (!workspaceId) {
        return null;
    }
    return (React.createElement(StatefulTooltip, { placement: "auto", content: tooltipContent, autoFocus: true, 
        // It's a bit odd that we're using tooltip with menu. The problem is that the
        // base popover does not have our style overrides so they would have to be duplicated here.
        accessibilityType: "menu" },
        React.createElement(WorkspaceResourceCommentsIcon, { workspaceId: workspaceId, resourceType: 'Tool', resource: resource })));
};
/**
 * Renders a table that can be used to compare tools
 */
const CompareBoard = ({ tools, removeTool, isLoading, }) => {
    const techSpecAttributes = uniq(tools.flatMap((x) => getAttributesForComponentType(x)).map((x) => x[0]));
    const hasPDFExport = useHasPermission('compare.export', 'read', 'all');
    const hasCSVToggle = useHasPermission('compare.export.csv', 'read', 'all');
    const lockableAttributes = EVIDENCE_ATTRIBUTES;
    const lockableRegulatoryAttributes = EVIDENCE_REGULATORY_ATTRIBUTES;
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(SharedAttributeContextProvider, { tools: tools },
        React.createElement(Stack, { gap: "0", display: "inline-flex", minWidth: "100%" },
            React.createElement(Block, { position: "sticky", top: "-176px", overrides: {
                    Block: {
                        style: {
                            zIndex: 1,
                        },
                    },
                } },
                React.createElement(CompareRow, null,
                    React.createElement(CompareHeading, { minHeight: "230px", overrides: {
                            Block: { style: { zIndex: 1, overflowWrap: 'anywhere' } },
                        } },
                        React.createElement(Spread, { direction: 'vertical', alignItems: 'flex-start' },
                            React.createElement(Stack, { direction: "vertical", gap: "0" },
                                React.createElement(Spacer, { size: "16px" }),
                                React.createElement(BackButton, null),
                                React.createElement(Spacer, { size: "32px" }),
                                React.createElement(Typography, { variant: TypographyVariant.Heading1 },
                                    "Compare",
                                    React.createElement("br", null),
                                    "Technology (",
                                    tools.length,
                                    ")")),
                            React.createElement(When, { condition: hasPDFExport || hasCSVToggle },
                                React.createElement(Block, { height: "42px" },
                                    React.createElement(ExportTechButton, { resource: tools }))))),
                    React.createElement(When, { condition: tools.length === 0 && !isLoading },
                        React.createElement(EmptyStateText, null)),
                    tools.map((tool) => {
                        var _a;
                        const detailsURL = generatePath(PATHS.atlas.technologies.detail, {
                            id: tool.id,
                        });
                        return (React.createElement(CompareCellContent, { key: tool.id },
                            React.createElement(Block, { display: "flex", flex: "1", 
                                // Since this component is pretty nested, we need to set min width at this level as well.
                                // Without it, the entire stack won't be able to shrink past it's content (containing the link).
                                // If this happens, any width setting we do for the truncation below won't have any effect.
                                minWidth: "0", flexDirection: "column", justifyContent: "flex-end" },
                                React.createElement(Stack, { gap: "16px" },
                                    React.createElement(Link, { href: detailsURL },
                                        React.createElement(ToolImage, { tool: tool, imageSize: 120 })),
                                    React.createElement(Block, null,
                                        React.createElement(ComponentInstrumentTypeTag, { componentType: tool.componentType, subType: tool.coaSubtype })),
                                    React.createElement(Spread, { alignItems: "flex-start" },
                                        React.createElement(Link, { href: detailsURL, style: { minWidth: 0 } },
                                            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, singleLine: true }, tool.model),
                                            React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1, singleLine: true }, (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name)),
                                        React.createElement(Stack, { direction: "horizontal" },
                                            React.createElement(CompareBoardHeaderComments, { resource: tool.id }),
                                            React.createElement(SemanticButton, { title: `Remove ${tool.model}`, onClick: () => removeTool === null || removeTool === void 0 ? void 0 : removeTool(tool) },
                                                React.createElement(Icons.Misc.Close, { color: COLORS.black, size: '16px', decorative: true }))))))));
                    }))),
            React.createElement(DescriptionRow, { tools: tools }),
            React.createElement(CompareSection, { heading: 'Measures', disabled: tools.length === 0, attributes: [ToolAttribute.Measures], items: tools }),
            React.createElement(LockableCompareSection, { resource: 'component.evidence', action: 'read', heading: "Indications", disabled: tools.length === 0, lockedHeading: React.createElement(Stack, { alignItems: "center", direction: "horizontal", gap: "0px" },
                    "Indications",
                    React.createElement(Spacer, { size: "9px" }),
                    React.createElement(Icons.Misc.Lock, { size: '24px', color: COLORS.gray1, title: "Locked" }),
                    React.createElement(Spacer, { size: "4px" })), lockedDescription: React.createElement(React.Fragment, null,
                    "To access evidence indications, please",
                    React.createElement(ContactUsFormTrigger, { location: 'Compare board - evidence indications', contactType: 'Evidence Request', buttonVariant: 'minimal' }, "Contact us")), attributes: [ToolAttribute.Population], items: tools }),
            React.createElement(Stack, { gap: "0" },
                React.createElement(LockableCompareSection, { resource: 'component.evidenceSummary', action: 'read', heading: "Evidence", disabled: tools.length === 0, lockedHeading: React.createElement(Stack, { alignItems: "center", direction: "horizontal", gap: "0px" },
                        "Evidence",
                        React.createElement(Spacer, { size: "9px" }),
                        React.createElement(Icons.Misc.Lock, { size: '24px', color: COLORS.gray1, title: "Locked" }),
                        React.createElement(Spacer, { size: "4px" })), lockedDescription: React.createElement(React.Fragment, null,
                        "To access evidence counts, publications, and more,",
                        React.createElement(ContactUsFormTrigger, { location: 'Compare board - evidence', contactType: 'Evidence Request', buttonVariant: 'minimal' }, "Contact us")), attributes: lockableAttributes, items: tools }),
                React.createElement(LockableCompareSection, { resource: 'component.regulatoryDecisions', action: 'read', heading: "Regulatory Decisions", disabled: tools.length === 0, lockedHeading: React.createElement(Stack, { alignItems: "center", direction: "horizontal", gap: "0px" },
                        "Regulatory Decisions",
                        React.createElement(Spacer, { size: "9px" }),
                        React.createElement(Icons.Misc.Lock, { size: '24px', color: COLORS.gray1, title: "Locked" }),
                        React.createElement(Spacer, { size: "4px" })), lockedDescription: React.createElement(React.Fragment, null,
                        "To access regulatory decision information,",
                        React.createElement(ContactUsFormTrigger, { location: 'Compare board - regulatory decisions', contactType: 'Evidence Request', buttonVariant: 'minimal' }, "Contact us")), attributes: lockableRegulatoryAttributes, items: tools }),
                React.createElement(CompareSection, { heading: "Technical Specifications", disabled: tools.length === 0, attributes: techSpecAttributes, items: tools }),
                React.createElement(CompareSection, { heading: "Price & Availability", disabled: tools.length === 0, attributes: PRICE_AVAILABILITY_ATTRIBUTES, items: tools }),
                React.createElement(CompareSection, { heading: 'Data Rights & Security', disabled: tools.length === 0, attributes: DATA_RIGHTS_SECURITY_ATTRIBUTES, items: tools })))));
};
export { CompareBoard };
