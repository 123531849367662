import { useElektraApi, UserProfileResource } from '@humanfirst/use-elektra-api';
import { useUser } from '../user';
import { useCanUserSeeUserProfileQuestionnaire } from './useCanUserSeeUserProfileQuestionnaire';
/** Check if the user has an existing user profile if they technically can see the modal */
export const useHasExistingUserProfile = () => {
    var _a;
    const canSeeModal = useCanUserSeeUserProfileQuestionnaire();
    const user = useUser();
    const enabled = !!(user === null || user === void 0 ? void 0 : user.id) && canSeeModal;
    const { data: existingProfile, isLoading } = useElektraApi(
    // This will never be called if a user is not available
    UserProfileResource.getByUser((_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : ''), {
        enabled,
        retry: false,
    });
    return {
        existingProfile: !!existingProfile,
        isLoading: enabled ? isLoading : false,
    };
};
