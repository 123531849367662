import { Block, COLORS, FilterType, Spacer, Tab, TabList, TabPanel, } from '@humanfirst/elektron';
import { MeasureCategoryResource } from '@humanfirst/use-elektra-api';
import React, { useCallback, useMemo } from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { ArrayParam, NumberParam, useQueryParams, withDefault, } from 'use-query-params';
import ImageMeasureExample from 'src/assets/measure_example.png';
import { CatalogResourceDetails, CatalogResourceError, } from 'src/components/CatalogResourceDetails';
import { ContentContainer, HeaderContentContainer, } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { UpgradeBanner } from 'src/components/UpgradeBanner';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { LockableEvidenceTable } from 'src/pages/Tool/components/LockableEvidenceTable';
import { getPathFromString } from 'src/utils/measurements';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { MeasureCategoryMedicalConditionTable } from './components/MeasureCategoryMedicalConditionTable';
import { MeasureCategoryResourceSummaryCard } from './components/MeasureCategoryResourceSummaryCard';
import { MeasureCategoryToolTable } from './components/MeasureCategoryToolTable';
import { MeasureCategoryTopTechnology } from './components/MeasureCategoryTopTechnology';
import { TopMedicalConditionsForCategory } from './components/TopMedicalConditionsCard';
const MeasureCategoryDetailsInternal = ({ data }) => {
    const categoryName = getPathFromString(data.category).pop();
    const [queryParams, setQueryP] = useQueryParams({
        view: withDefault(NumberParam, 0),
        filters: withDefault(ArrayParam, []),
    });
    const setView = (view) => setQueryP({ view }, 'push');
    const { view } = queryParams;
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasMeasureEvidence = useHasPermission('measure.evidence', 'read', data);
    const hasAllMeasures = useHasPermission('measure', 'read', 'all');
    const enterprise = hasEvidenceSource && hasMeasureEvidence;
    const setViewAndFilters = useCallback((view, filters) => setQueryP({ view, filters }, 'push'), [setQueryP]);
    const showEvidence = useCallback((source, selected) => {
        const preFilter = source === 'MedicalCondition'
            ? [`medicalConditions||Includes Any Of||["${selected}"]`]
            : [`tools||Includes Any Of||["${selected}"]`];
        setViewAndFilters(2, preFilter);
    }, [setViewAndFilters]);
    const scope = useMemo(() => {
        return [
            {
                fieldId: 'measures',
                filterType: FilterType.EQUALS,
                filterValue: data.name,
            },
        ];
    }, [data.name]);
    const { evidenceTableScopes } = useGetConnectInstrumentScopes();
    const finalScope = [...scope, ...evidenceTableScopes];
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: categoryName }),
        React.createElement(HeaderContentContainer, { backButton: false, breadcrumbs: [
                BREADCRUMBS.Atlas,
                BREADCRUMBS.Measures,
                BREADCRUMBS.MeasureDetails(data),
            ] },
            React.createElement(When, { condition: !hasAllMeasures },
                React.createElement(UpgradeBanner, { body: 'Get in touch with our team to upgrade and unlock more information on technologies and medical conditions in this measure.', title: 'Access Measure', actionLabel: 'Request Access' }),
                React.createElement(Spacer, { size: "24px" })),
            React.createElement(Block, { display: "grid", 
                // repeat(3, 1fr) doesn’t work here. The reason is that 1fr is about the space avaliable. It doesn’t prevent content that is larger than a track from overflowing.
                // 1fr isn’t guarenteed to be the smae as another 1fr for that reason. Below we say the MAXIMUM you can be is one fr of the space available. By default the maximum is auto.
                gridTemplateColumns: "repeat(3, minmax(0, 1fr))", gridTemplateRows: "1fr", gap: "16px" },
                React.createElement(MeasureCategoryResourceSummaryCard, { category: data }),
                React.createElement(When, { condition: hasEvidenceSource },
                    React.createElement(MeasureCategoryTopTechnology, { category: data, showEvidence: showEvidence }),
                    React.createElement(TopMedicalConditionsForCategory, { category: data, showEvidence: showEvidence, hasEvidencePermission: hasMeasureEvidence })))),
        React.createElement(ContentContainer, { padding: "24px 56px 0px 56px" },
            React.createElement(TabList, { onChange: setView, active: view },
                React.createElement(Tab, null, "Technologies"),
                React.createElement(Tab, null, "Medical Conditions"),
                enterprise ? React.createElement(Tab, null, "Evidence") : null)),
        React.createElement(ContentContainer, { backgroundColor: COLORS.white },
            React.createElement(TabPanel, { index: 0, active: view },
                React.createElement(MeasureCategoryToolTable, { category: data, showEvidence: showEvidence })),
            React.createElement(TabPanel, { index: 1, active: view },
                React.createElement(MeasureCategoryMedicalConditionTable, { category: data, showEvidence: showEvidence })),
            enterprise ? (React.createElement(TabPanel, { index: 2, active: view },
                React.createElement(LockableEvidenceTable, { isLocked: !hasMeasureEvidence, scope: finalScope, title: "Evidence", lockedCta: "Tell us more to unlock evidence for this measure." }))) : null)));
};
const MeasureCategoryUpgrade = ({ error }) => (React.createElement(CatalogResourceError, { error: error, resourceName: 'measure', imageUrl: ImageMeasureExample }));
const MeasureDetails = () => {
    const { id } = useParams();
    return (React.createElement(CatalogResourceDetails, { resource: MeasureCategoryResource.get(id, {
            join: ['descendants'],
        }), errorComponent: MeasureCategoryUpgrade, resourceDetails: MeasureCategoryDetailsInternal, permissionResourceName: 'measure' }));
};
export { MeasureDetails };
