import React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, FormProvider, } from 'react-hook-form';
/**
 * Renders a form with full controls, error messages, etc.
 */
function SmartFormProvider({ validationSchema, defaultValues, children, }) {
    const resolver = validationSchema ? joiResolver(validationSchema) : undefined;
    const formMethods = useForm({ mode: 'onChange', defaultValues, resolver });
    return React.createElement(FormProvider, Object.assign({}, formMethods), children);
}
export { SmartFormProvider };
