import { useBuildDataTableProps, } from '@humanfirst/elektron';
import { useSearchStateUrl } from './use-search-state-url';
import { useApplySearchAlgolia, } from './use-apply-search-algolia';
import { useApplyScopes } from './shared';
/** A combine hook that will use a URL backed storage and Algolia for searching. */
function useDataTableUrlAlgolia(schema, algoliaConfiguration, initialState, scope) {
    const [state, dispatch] = useSearchStateUrl(initialState);
    const scopedState = useApplyScopes(state, scope);
    const searchResults = useApplySearchAlgolia(scopedState, schema, algoliaConfiguration);
    // Note: This intentionally uses the unscoped state because we don't want
    // to show the extra scopes.
    return useBuildDataTableProps(state, dispatch, searchResults, schema);
}
export { useDataTableUrlAlgolia };
