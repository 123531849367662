import React, { useMemo } from 'react';
import { ButtonLink, COLORS, Icons, Stack } from '@humanfirst/elektron';
import { When } from 'react-if';
import { deserializeWorkspaceMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
import { formatDateFromNow } from 'src/utils/date';
import { PrimaryCard } from 'src/components/ResourceDetails';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { t } from 'src/constants/i18n';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
const StudyActions = ({ study }) => {
    const editHref = generatePath(PATHS.catalog.studies.view.edit, {
        id: study.id,
    });
    const hasEditPermission = useHasPermission('study', 'update');
    const hasStudiesInProjects = useHasPermission('connect', 'read', 'all');
    const isDemo = study.id === 'demo';
    if (!(hasEditPermission && !isDemo) && !hasStudiesInProjects) {
        return null;
    }
    return (React.createElement(Stack, { direction: 'horizontal', alignItems: 'center', paddingTop: '16px' },
        React.createElement(When, { condition: hasEditPermission && !isDemo },
            React.createElement(ButtonLink, { size: "large", href: editHref, endEnhancer: React.createElement(Icons.Misc.Edit, { decorative: true, color: COLORS.white }) }, t('studies.detail.editStudy'))),
        React.createElement(When, { condition: hasStudiesInProjects },
            React.createElement(AddToWorkspaceButton, { resourceId: study.id, resourceType: 'Study', variant: 'button' }))));
};
const StudyPrimaryCard = ({ study }) => {
    const generalMetadata = useMemo(() => {
        const parsedMetadata = deserializeWorkspaceMetadata(study.additionalMetadata);
        return splitMetadata(parsedMetadata).generalMetadata;
    }, [study]);
    const year = useMemo(() => {
        var _a;
        const value = (_a = generalMetadata.find(({ title }) => title === 'Study Start Date')) === null || _a === void 0 ? void 0 : _a.value;
        if (!value) {
            return undefined;
        }
        const valueDate = new Date(value);
        return valueDate.getFullYear();
    }, [generalMetadata]);
    const relativeUpdatedAt = study.updatedAt
        ? formatDateFromNow(study.updatedAt)
        : 'in the future';
    const primaryCardsTags = useMemo(() => [
        {
            tag: 'Study',
        },
        {
            tag: year ? year.toString() : '',
        },
        {
            tag: `Updated ${relativeUpdatedAt}`,
        },
    ].filter(({ tag }) => tag && tag.length > 0), [year, relativeUpdatedAt]);
    return (React.createElement(PrimaryCard, { title: study.name, tags: primaryCardsTags, actions: React.createElement(StudyActions, { study: study }), hasFeedback: false }));
};
export { StudyPrimaryCard };
