import { Heading, StatefulTabs } from '@humanfirst/elektron';
import { InviteResource, TeamMemberResource, TeamResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { useParams } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { TeamSSO } from 'src/components/TeamSSO/TeamSSO';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { TeamUsers } from './components/Users';
const TeamDetailInternal = ({ data: members, invitees, team }) => {
    const canManageDomain = useHasPermission('team.domain', 'read', team);
    const tabs = [
        React.createElement(StatefulTabs.TabPanel, { key: 'Users', title: 'Users' },
            React.createElement(TeamUsers, { data: members, invitees: invitees, team: team })),
    ];
    if (canManageDomain) {
        tabs.push(React.createElement(StatefulTabs.TabPanel, { key: 'SSO', title: 'SSO' },
            React.createElement(TeamSSO, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, null, team.name),
        React.createElement(StatefulTabs, null, tabs)));
};
const TeamDetailWithTeam = ({ data: team }) => {
    var _a;
    const { data: invites } = useElektraApi(InviteResource.list(team.id));
    const filteredInvites = (_a = invites === null || invites === void 0 ? void 0 : invites.filter((invite) => !invite.claimedBy)) !== null && _a !== void 0 ? _a : [];
    const result = useElektraApi(TeamMemberResource.list(team.id), {
        enabled: !!filteredInvites,
    });
    return (React.createElement(ContentContainer, { backButton: true },
        React.createElement(PageTitle, { title: team.name }),
        React.createElement(QueryWrapper, Object.assign({ renderSuccess: TeamDetailInternal }, result, { invitees: filteredInvites, team: team }))));
};
const TeamDetail = () => {
    const { id } = useParams();
    const result = useElektraApi(TeamResource.get(id));
    return React.createElement(QueryWrapper, Object.assign({ renderSuccess: TeamDetailWithTeam }, result));
};
export { TeamDetail };
