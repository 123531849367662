import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Link, Icons, Stack } from '@humanfirst/elektron';
import { useNavigate } from 'react-router-dom';
const BackButton = ({ content = 'Back', }) => {
    const navigate = useNavigate();
    const [source] = useQueryParam('source', StringParam);
    const displayContent = source === 'search' ? 'Back to results' : content;
    return (React.createElement(Link, { href: "#", onClick: (e) => {
            e.preventDefault();
            navigate(-1);
        } },
        React.createElement(Stack, { direction: "horizontal", gap: "5px", alignItems: "center" },
            React.createElement(Icons.Misc.ArrowBack, { decorative: true, size: content === null ? '24px' : undefined }),
            content !== null ? displayContent : null)));
};
export { BackButton };
