import { DataTable, useApplySearchMemory, useBuildDataTableProps, useSearchStateMemory, } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { useTrackDataTableRefinement } from 'src/hooks/analytics/track-table-refinement';
import { useSearchStateUrl } from '../hooks/use-search-state-url';
/** Defaults for all tables. */
const SCHEMA_DEFAULTS = {
    pagination: { pageSize: 20 },
    sortable: true,
    searchable: true,
};
/**
 * Dummy function for when we don't care about tracking.
 *
 * Because of the rules of hooks, we need something to call. But by using a dummy
 * we don't have to waste time tracking only to throw it out at the end.
 */
const useDummyTrack = () => undefined;
/**
 * Creates a generic table.
 *
 * This includes extra provisions for defaults (pagination/searching/sorting) and
 * support for providing extra columns.
 *
 * There are three parts that can be configured with this function:
 * 1. The schema of the data table. This determines columns, sorting, etc.
 * 2. Default data table props. This can be used for extra components/empty state, etc.
 * 3. Which hooks should be used. You can configure your storage backend (url/memory) and
 * whether you want built in tracking. Note that these hooks are determined once (when
 * the component is constructed), so are not called conditionally and therefore still obey
 * the rules of hooks (at least by the letter of the law...).
 */
export function createGenericTable(schema, dataTableProps, defaultInitialState, hookConfig = { stateStorage: 'memory' }) {
    const useSearchStateHook = hookConfig.stateStorage === 'memory'
        ? useSearchStateMemory
        : useSearchStateUrl;
    const useTrackHook = hookConfig.track
        ? useTrackDataTableRefinement
        : useDummyTrack;
    return ({ data, extraColumns, initialState = defaultInitialState, extraActions, }) => {
        var _a;
        // Combine extra columns with the given schema.
        const finalSchema = useMemo(() => {
            var _a;
            return (Object.assign(Object.assign(Object.assign({}, SCHEMA_DEFAULTS), schema), { columns: [...schema.columns, ...(extraColumns !== null && extraColumns !== void 0 ? extraColumns : [])], selectable: (_a = schema.selectable) !== null && _a !== void 0 ? _a : !!extraActions }));
        }, [extraColumns, extraActions]);
        // Call the backend (configured once at component time).
        const [state, dispatch] = useSearchStateHook(initialState);
        // Apply search (currently only in memory)
        const searchResults = useApplySearchMemory(state, data, finalSchema);
        // Finally, rebuild everything for DataTable
        const props = useBuildDataTableProps(state, dispatch, searchResults, finalSchema);
        // Track (if the table is configured for it).
        useTrackHook((_a = hookConfig.track) !== null && _a !== void 0 ? _a : '', props);
        return (React.createElement(DataTable, Object.assign({}, dataTableProps, props, { postSelectionActions: extraActions })));
    };
}
