import { useMemo } from 'react';
import { useRelated } from './useRelated';
const useRelatedIDs = ({ indexName, objectID, enabled, }) => {
    const result = useRelated({
        indexName,
        objectID,
        enabled,
    });
    return useMemo(() => {
        const { data } = result;
        return Object.assign(Object.assign({}, result), { data: data === null || data === void 0 ? void 0 : data.map(({ objectID }) => objectID) });
    }, [result]);
};
export { useRelatedIDs };
