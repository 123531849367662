export const HARD_CODED_COIS = [
    'Activity',
    'Activities of Daily Living',
    'Ambulation/Mobility',
    'Personal and Home Hygiene',
    'Activity Detection and Metrics',
    'Activity Frequency/Duration',
    'Activity Intensity',
    'Steps',
    'Heart Rate',
    'Heart Rate Variability',
    'Peripheral Blood Pressure',
    'Body Temperature',
    'Respiratory Rate',
    'Oxygen Saturation',
    'Sleep',
    'Circadian Rhythm',
    'Sleep-Wake Detection',
    'Sleep Efficiency',
    'Sleep Fragmentation',
    'Sleep Latency',
    'Wake After Sleep Onset',
    'Total Sleep Time',
    'Sleep Activity and Quality',
    'Sleep Stages',
    'Body Movement',
    'Head Movement',
    'Spine Movement',
    'Gait',
    'Trunk Movement',
    'Gait Cycle',
    'Lower Extremity Movement',
    'Walking Speed',
    'Posture/Balance',
    'Upper Extremity Movement',
    'Joint Range of Motion',
    'Cough',
    'Cognition',
    'Pain',
    'Voice/Speech',
    'Scratching',
    'Emotional/Cognitive State',
    'Nocturnal Scratching',
    'Fatigue',
];
