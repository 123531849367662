const LISTS_GROUP = {
    id: 'lists',
    label: 'List',
    getValue: (item) => {
        var _a;
        const currentLists = (_a = item.lists) === null || _a === void 0 ? void 0 : _a.map((x) => x.metadataValue);
        return (currentLists === null || currentLists === void 0 ? void 0 : currentLists.length) ? currentLists : ['No List'];
    },
};
const REVIEWED_STATUS_GROUP = {
    id: 'reviewed',
    label: 'Reviewed Status',
    getValue: (item) => (item.reviewed ? ['Reviewed'] : ['Not Reviewed']),
};
const CUSTOM_TAG_GROUP = {
    id: 'tags',
    label: 'Custom Tags',
    getValue: (item) => {
        var _a;
        const tags = (_a = item.tags) === null || _a === void 0 ? void 0 : _a.map((x) => x.metadataValue);
        return (tags === null || tags === void 0 ? void 0 : tags.length) ? tags : ['No Tag'];
    },
};
export { LISTS_GROUP, REVIEWED_STATUS_GROUP, CUSTOM_TAG_GROUP };
