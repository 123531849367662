import { Block, COLORS, Heading2, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import React from 'react';
import { MedicalConditionsResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const MedicalConditionsInternal = ({ data, }) => {
    return (React.createElement(Stack, { gap: "0px" }, data.map((x) => (React.createElement(Block, { key: x.name },
        React.createElement(Link, { href: generatePath(PATHS.atlas.medicalConditions.detail, {
                id: x.id,
            }) }, x.name))))));
};
const MedicalConditions = ({ study }) => {
    var _a;
    const medicalConditionIds = (_a = study.medicalConditions) !== null && _a !== void 0 ? _a : [];
    const result = useElektraApi(MedicalConditionsResource.getBulk(medicalConditionIds), { enabled: !!medicalConditionIds.length });
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Medical Conditions"),
        React.createElement(If, { condition: !!medicalConditionIds.length },
            React.createElement(Then, null,
                React.createElement(QueryWrapper, Object.assign({ renderSuccess: MedicalConditionsInternal }, result))),
            React.createElement(Else, null,
                React.createElement(Typography, { color: COLORS.gray2 }, "No medical conditions listed.")))));
};
export { MedicalConditions };
