import React from 'react';
import { Block, Button, Icons, Stack, StatefulTooltip, Typography, } from '@humanfirst/elektron';
import { If, Else, Then } from 'react-if';
const DEFAULT_TOOLTIP = "Get notified of any changes to this product's terms of service or privacy policy, recall and availability info, and more.";
const SubscribeButton = ({ isSubscribed, onSubscribe, onUnsubscribe, isLoading, tooltip = DEFAULT_TOOLTIP, }) => {
    return (React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
        React.createElement(StatefulTooltip, { triggerType: "hover", placement: "bottom", content: React.createElement(Block, { maxWidth: '400px' },
                React.createElement(Typography, null, tooltip)) },
            React.createElement(If, { condition: isSubscribed },
                React.createElement(Then, null,
                    React.createElement(Stack, { direction: 'horizontal' },
                        React.createElement(Button, { variant: "tertiary", onClick: onUnsubscribe, isLoading: isLoading, size: "large", startEnhancer: React.createElement(Icons.Misc.NotificationsBell, { decorative: true, size: "16px" }) }, "Stop Updates"))),
                React.createElement(Else, null,
                    React.createElement(Stack, { direction: 'horizontal' },
                        React.createElement(Button, { variant: "tertiary", onClick: onSubscribe, isLoading: isLoading, size: "large", startEnhancer: React.createElement(Icons.Misc.NotificationsBell, { decorative: true, size: "16px" }) }, "Get Updates")))))));
};
export { SubscribeButton };
