import { __awaiter, __rest } from "tslib";
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { getHeap } from 'src/utils/heap';
import { initialize } from './initialize';
const CDN_URL = 'https://heap-cdn.gohumanfirst.com/';
const INGEST_URL = 'https://heap-ingest.gohumanfirst.com/';
const HEALTH_URL = 'https://heap-ingest.gohumanfirst.com/';
const getSessionId = () => {
    const heap = getHeap();
    if (!(heap === null || heap === void 0 ? void 0 : heap.getSessionId)) {
        throw new TypeError('session ID is not available');
    }
    return heap.getSessionId();
};
const isSessionIdAvailable = new Promise((resolve) => {
    try {
        const id = getSessionId();
        if (id) {
            resolve(true);
            return;
        }
    }
    catch (e) {
        // Do nothing
    }
    const interval = setInterval(() => {
        try {
            const id = getSessionId();
            if (id) {
                clearInterval(interval);
                resolve(true);
                return;
            }
        }
        catch (e) {
            // Do nothing
        }
    }, 500);
});
const HeapAnalyticsService = {
    name: 'heap',
    /** @override */
    isInitialized: () => !!getHeap(),
    /** @override */
    initialize: () => {
        const appId = process.env.HEAP_APP_ID;
        if (appId) {
            initialize(appId, CDN_URL, INGEST_URL);
        }
    },
    getSessionIdentifier: () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(yield isSessionIdAvailable)) {
            throw new TypeError('session ID is not available');
        }
        return getSessionId();
    }),
    /** @override */
    isConnectable: () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield fetch(HEALTH_URL, {
                method: 'HEAD',
                mode: 'no-cors',
                cache: 'no-store',
            });
            return true;
        }
        catch (e) {
            return false;
        }
    }),
    /** @override */
    identify: (userData, appData) => {
        const heap = getHeap();
        if (heap) {
            heap.addEventProperties(appData);
            if (userData !== null) {
                const { id: userId } = userData, otherUserData = __rest(userData, ["id"]);
                heap.identify(userId);
                // Always include user ID and user Email because heap
                // early during sign up will omit these when firing them off
                // as webhooks for other systems to interact with
                heap.addEventProperties({
                    userId: userData === null || userData === void 0 ? void 0 : userData.id,
                    userEmail: userData === null || userData === void 0 ? void 0 : userData.email,
                });
                heap.addUserProperties(Object.assign(Object.assign({}, appData), otherUserData));
            }
            else {
                heap.addEventProperties({
                    userId: null,
                    userEmail: null,
                });
                heap.resetIdentity();
            }
        }
    },
    /** @override */
    pathChange: (_pathname, _search, appData) => {
        const heap = getHeap();
        if (heap) {
            // Event properties are associated at the page level, so we should
            // explicitly clear them on page changes.
            heap.clearEventProperties();
            if (appData) {
                heap.addEventProperties({
                    version: appData.version,
                    screenWidth: appData.screenWidth,
                    screenHeight: appData.screenHeight,
                    windowWidth: appData.windowWidth,
                    windowHeight: appData.windowHeight,
                });
            }
        }
    },
    track: (action, properties) => {
        const heap = getHeap();
        if (heap) {
            heap.track(action, properties);
        }
    },
};
export { HeapAnalyticsService };
