import React from 'react';
import { Block, Card, COLORS, Heading2, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { ToolAttribute } from 'src/components/ModelAttribute/ToolAttribute';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { isCoa, isLab, isSmartphone } from '../utils/selectors';
const getAttributes = (tool) => {
    if (isSmartphone(tool)) {
        return [ToolAttribute.ProductLifeCycle, ToolAttribute.Price];
    }
    if (isLab(tool)) {
        return [ToolAttribute.Distribution, ToolAttribute.Price];
    }
    if (isCoa(tool)) {
        return [ToolAttribute.Distribution, ToolAttribute.Price];
    }
    return [
        ToolAttribute.ProductLifeCycle,
        ToolAttribute.Price,
        ToolAttribute.Distribution,
    ];
};
const PriceCard = ({ tool, }) => {
    const attributes = getAttributes(tool);
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Block, { minWidth: "496px" },
            React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, isCoa(tool) ? 'Licensing Information' : 'Price & Availability'),
            React.createElement(Stack, { gap: "24px" }, attributes.map((Attribute) => (React.createElement(Stack, { key: Attribute.label },
                React.createElement(Typography, { color: COLORS.gray1 },
                    React.createElement(Spread, null,
                        Attribute.label,
                        React.createElement(EditFlagTooltip, { field: Attribute.label }))),
                React.createElement(Attribute, { item: tool }))))))));
};
export { PriceCard };
