import { StudyResource, useElektraApi } from '@humanfirst/use-elektra-api';
/**
 * Maps a study to a study with metadata.
 *
 * Right now this just parses out the technologies, but is split to support
 * other things that could come in on the metadata.
 */
export const mapStudyToStudyWithMetadata = (study) => {
    var _a;
    return {
        study,
        parsedMetadata: {
            technologies: (_a = study.tools) !== null && _a !== void 0 ? _a : [],
        },
    };
};
/** Returns all the technologies on all studies associated with a user. */
const useGetAllStudiesWithMetadata = () => {
    const { isLoading, data: studies } = useElektraApi(StudyResource.list());
    return { isLoading, data: studies === null || studies === void 0 ? void 0 : studies.map(mapStudyToStudyWithMetadata) };
};
/** Returns all the studies that have a technology. */
const useGetAllStudiesForTech = (toolId) => {
    const { isLoading, data: studies } = useElektraApi(StudyResource.list());
    const studiesWithTech = studies === null || studies === void 0 ? void 0 : studies.filter((x) => { var _a; return (_a = x.tools) === null || _a === void 0 ? void 0 : _a.includes(toolId); });
    return {
        isLoading,
        studies: studiesWithTech === null || studiesWithTech === void 0 ? void 0 : studiesWithTech.map(mapStudyToStudyWithMetadata),
    };
};
export { useGetAllStudiesForTech, useGetAllStudiesWithMetadata };
