import { __awaiter } from "tslib";
import React, { useCallback } from 'react';
import { toaster } from '@humanfirst/elektron';
import { useIsSubscribed } from 'src/hooks/subscribe';
import { SubscribeButton } from './SubscribeButton';
const SubscribeToTool = ({ tool }) => {
    const { subscribe, unsubscribe, isSubscribed, isLoading } = useIsSubscribed('Tool', tool.id);
    const onSubscribe = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield subscribe();
        toaster.info(`Subscribed to updates for ${tool.model}`, {});
    }), [subscribe, tool.model]);
    const onUnsubscribe = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield unsubscribe();
        toaster.info(`Unsubscribed to updates for ${tool.model}`, {});
    }), [tool.model, unsubscribe]);
    return (React.createElement(SubscribeButton, { onSubscribe: onSubscribe, onUnsubscribe: onUnsubscribe, isLoading: isLoading, isSubscribed: isSubscribed }));
};
export { SubscribeToTool };
