import { Block, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { TechnologyTagPip, } from 'src/components/TechnologyTag';
import { ToolImageLink } from 'src/components/ToolImageLink/ToolImageLink';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { TechnologyTagButton } from '../TechnologyTag/TechnologyTagButton';
import { ToolSummary } from '../ToolSummary';
const TechnologiesTagged = ({ evidenceBasedTechIds, vendorStatedTechIds, allTechnologies }) => {
    const [highlight, setHighlight] = useState();
    const toggleHighlight = useCallback((tag) => {
        setHighlight((h) => {
            if (h === tag) {
                return undefined;
            }
            return tag;
        });
    }, []);
    const toggleHighlightVendor = useCallback(() => toggleHighlight('vendor'), [toggleHighlight]);
    const toggleHighlightEvidence = useCallback(() => toggleHighlight('evidence'), [toggleHighlight]);
    const allTechTagged = allTechnologies.map((x) => {
        const tag = [];
        if (evidenceBasedTechIds.includes(x.id)) {
            tag.push('evidence');
        }
        if (vendorStatedTechIds.includes(x.id)) {
            tag.push('vendor');
        }
        return { tool: x, tag };
    });
    const vendorStatedTechCount = allTechTagged.filter((x) => x.tag.includes('vendor')).length;
    const evidenceBasedTechCount = allTechTagged.filter((x) => x.tag.includes('evidence')).length;
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(TechnologyTagButton, { tag: 'vendor', highlight: highlight, toggle: toggleHighlightVendor }),
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, `(${vendorStatedTechCount})`),
            React.createElement(TechnologyTagButton, { tag: 'evidence', highlight: highlight, toggle: toggleHighlightEvidence }),
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, `(${evidenceBasedTechCount})`)),
        allTechTagged.map((x) => {
            return (React.createElement(Stack, { key: x.tool.id, direction: "horizontal", gap: "8px", paddingTop: '8px', alignItems: 'center' },
                React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                    React.createElement(If, { condition: !highlight || (highlight && x.tag.includes(highlight)) },
                        React.createElement(Then, null,
                            React.createElement(ToolImageLink, { tool: x.tool }),
                            React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                                    id: x.tool.id,
                                }) }, x.tool.model),
                            x.tag.map((t) => (React.createElement(TechnologyTagPip, { key: t, tag: t })))),
                        React.createElement(Else, null,
                            React.createElement(Block, { overrides: {
                                    Block: {
                                        style: {
                                            opacity: '0.3',
                                        },
                                    },
                                } },
                                React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                                    React.createElement(ToolSummary, { tool: x.tool }),
                                    x.tag.map((t) => (React.createElement(TechnologyTagPip, { key: t, tag: t }))))))))));
        })));
};
export { TechnologiesTagged };
