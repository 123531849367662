import { Stack, Typography, TypographyVariant, Divider, Markdown, Card, Block, COLORS, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { TechAndMeasures } from 'src/components/Evidence/EvidenceTechWithMeasures';
import { ShowMore } from 'src/components/TopNavigationBar/components/ShowMore';
import { useDedupeTechnologyForEvidence } from 'src/hooks/evidence';
import { getMeasureCategoryByTechnology } from 'src/utils/evidence';
const SourceMeasureTitle = ({ hasMeasures, isPartialIngestion }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Block, { width: "5%" }),
            React.createElement(Block, { flex: "1" },
                React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Technology")),
            hasMeasures || isPartialIngestion ? (React.createElement(Block, { flex: "1" },
                React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                    React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Measures")))) : null),
        React.createElement(Divider, null)));
};
const MeasuresByTech = ({ item }) => {
    var _a;
    const technologies = useDedupeTechnologyForEvidence(item);
    const measuresByTechnology = getMeasureCategoryByTechnology(item);
    // Partially ingested may have tech tagged without measures. In this case we won't show
    // the measures column.
    const hasMeasures = [...measuresByTechnology.values()].some((x) => x.length > 0);
    const unspecifiedTechnology = measuresByTechnology.get('NOT FOUND');
    if (!((_a = item.sourceMeasures) === null || _a === void 0 ? void 0 : _a.length)) {
        return React.createElement(Typography, null, "No technologies or measures listed.");
    }
    return (React.createElement(React.Fragment, null,
        technologies.map((tech) => (React.createElement(Card, { key: `${item.id} ${tech.id}` },
            React.createElement(Stack, null,
                React.createElement(SourceMeasureTitle, { hasMeasures: hasMeasures, isPartialIngestion: item.ingestionStatus === 'Partially Ingested' }),
                React.createElement(TechAndMeasures, { tool: tech, measures: measuresByTechnology.get(tech.id), hasMeasures: hasMeasures, isPartialIngestion: item.ingestionStatus === 'Partially Ingested' }))))),
        React.createElement(When, { condition: !!(unspecifiedTechnology === null || unspecifiedTechnology === void 0 ? void 0 : unspecifiedTechnology.length) },
            React.createElement(Card, null,
                React.createElement(Stack, null,
                    React.createElement(SourceMeasureTitle, { hasMeasures: hasMeasures, isPartialIngestion: item.ingestionStatus === 'Partially Ingested' }),
                    React.createElement(TechAndMeasures, { measures: unspecifiedTechnology, hasMeasures: hasMeasures, isPartialIngestion: item.ingestionStatus === 'Partially Ingested' }))))));
};
const SourceMeasures = ({ evidence, }) => {
    var _a;
    if (!((_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return (React.createElement(Block, { width: "100%", display: "grid", gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'], gridGap: ['8px', '8px', '8px', '24px'] },
        React.createElement(MeasuresByTech, { item: evidence })));
};
const EvidenceSecondaryInfo = ({ evidence, }) => {
    return (React.createElement(Stack, { gap: "24px", padding: '24px' },
        React.createElement(Divider, null),
        React.createElement(When, { condition: !!evidence.abstract },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Abstract"),
            React.createElement(ShowMore, { limitedHeight: '64px' },
                React.createElement(Markdown, { content: evidence.abstract }))),
        React.createElement(SourceMeasures, { evidence: evidence })));
};
export { EvidenceSecondaryInfo };
