/**
 * Converts from a format with child keys to an array based format used
 * by react-router
 * @param route
 */
const toRouteObject = (route) => {
    if (!route.children) {
        return Object.assign(Object.assign({}, route), { children: undefined });
    }
    return Object.assign(Object.assign({}, route), { children: Object.values(route.children).map(toRouteObject) });
};
/**
 * Creates a paths object from a route object.
 *
 * NOTE: The type definitions are correct at the root, but there's
 * enough magic happening here that we have to cast the return types
 * in the body.
 * @param route
 * @param prefix
 * @returns
 */
function toPathsObject(route, prefix = '') {
    const fullPath = route.path ? prefix + '/' + route.path : prefix;
    if (route.children) {
        const children = route.children;
        const keys = Object.keys(children);
        return Object.fromEntries(keys.map((key) => [key, toPathsObject(children[key], fullPath)]));
    }
    else {
        return fullPath;
    }
}
export { toRouteObject, toPathsObject };
