import { __rest } from "tslib";
import React, { useCallback } from 'react';
import { StatefulTooltip, Button, Icons, COLORS, expandShorthand, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { RequestComparePDF } from 'src/components/ExportTechButton/ComparePDFRequest';
import { FHIRButton } from './components/FHIRButton';
import { CSVButton } from './components/CSVButton';
import { useGetToolsWithEvidenceAndVendorMetadata } from './utils';
const TOOLTIP_OVERRIDES = {
    Body: {
        style: expandShorthand({
            borderRadius: '14px',
            width: '200px',
            border: `solid ${COLORS.gray4} 1px`,
        }),
    },
    Inner: {
        style: expandShorthand({
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '14px',
            margin: '0px',
        }),
    },
};
const ExportTechButton = (_a) => {
    var { resource } = _a, props = __rest(_a, ["resource"]);
    const hasEvidenceSummary = useHasPermission('component.evidenceSummary', 'read');
    const hasPDFExport = useHasPermission('compare.export', 'read', 'all');
    const hasCSVToggle = useHasPermission('compare.export.csv', 'read', 'all');
    const resourceArray = Array.isArray(resource) ? resource : [resource];
    const { data: evidence } = useElektraApi(EvidenceResource.list({
        or: resourceArray.map(({ id }) => ['tools', '$cont', id]),
        join: ['sourceMeasures||id', 'sourceMeasures.tools||id'],
    }), { enabled: hasEvidenceSummary });
    const newResource = resourceArray.map((x) => {
        var _a;
        return {
            tool: x,
            evidence: (_a = evidence === null || evidence === void 0 ? void 0 : evidence.filter((y) => { var _a; return (_a = y.sourceMeasures) === null || _a === void 0 ? void 0 : _a.some((z) => { var _a; return (_a = z.tools) === null || _a === void 0 ? void 0 : _a.some((t) => t.id === x.id); }); })) !== null && _a !== void 0 ? _a : [],
        };
    });
    const resourceWithEvidenceAndVendorMetadata = useGetToolsWithEvidenceAndVendorMetadata(newResource);
    const renderCallback = useCallback(() => {
        return (React.createElement(React.Fragment, null,
            hasCSVToggle ? (React.createElement(CSVButton, { resource: resourceWithEvidenceAndVendorMetadata })) : null,
            hasCSVToggle ? React.createElement(FHIRButton, { resource: resource }) : null,
            hasPDFExport ? React.createElement(RequestComparePDF, null) : null));
    }, [
        hasCSVToggle,
        hasPDFExport,
        resource,
        resourceWithEvidenceAndVendorMetadata,
    ]);
    return (React.createElement(StatefulTooltip, { overrides: TOOLTIP_OVERRIDES, triggerType: "click", placement: "bottomLeft", showArrow: false, content: renderCallback },
        React.createElement(Button, Object.assign({ startEnhancer: React.createElement(Icons.Misc.Export, { size: "16px", color: "currentColor", decorative: true }) }, props), "Export")));
};
export { ExportTechButton };
