import { useEffect, useState } from 'react';
function getScreenSize() {
    const { width, height } = window.screen;
    return {
        width,
        height,
    };
}
function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
function getViewportDimensions() {
    return {
        window: getWindowSize(),
        screen: getScreenSize(),
    };
}
const useViewportDimensions = () => {
    const [dimensions, setDimensions] = useState(getViewportDimensions());
    useEffect(() => {
        const handler = () => setDimensions(getViewportDimensions());
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, [setDimensions]);
    return dimensions;
};
export { useViewportDimensions };
