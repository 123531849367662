import React, { useState } from 'react';
import { Block, Typography, TypographyVariant, COLORS, Spread, Button, Modal, Stack, toaster, Card, Icons, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { useListUploadedFiles } from 'src/hooks/upload/list-uploaded-files';
import { WorkspaceFileUploader } from '../WorkspaceFileUploader';
import { DocumentTable } from './DocumentTable';
/**
 * Modal for the workspace upload.
 * Mostly a wrapper around the raw form, but has some extra copy text and the button
 * to launch the modal.
 */
const UploadModal = ({ workspaceId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalState, setModalState] = useState('form');
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement(If, { condition: modalState === 'form' },
                React.createElement(Then, null,
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Upload Your Data"),
                        React.createElement(Typography, { variant: TypographyVariant.Body }, "Upload your internal data to enrich your Atlas research. You will be able to view and filter by your qualified vendor list."),
                        React.createElement(Card, { backgroundColor: COLORS.blue4, variant: 'tertiary' },
                            React.createElement(Stack, { direction: "horizontal", gap: "24px" },
                                React.createElement(Block, { width: "24px" },
                                    React.createElement(Icons.Misc.Info, { decorative: true, size: "24px", color: COLORS.primary })),
                                React.createElement(Typography, null, "The data you upload can be viewed and used by anyone in this project."))),
                        React.createElement(WorkspaceFileUploader, { workspaceId: workspaceId, onUpload: (successFiles) => {
                                toaster.info(`Uploaded ${successFiles.length} ${successFiles.length === 1 ? 'file' : 'files'}`);
                                setModalState('post-submit');
                            } }))),
                React.createElement(Else, null,
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Files Uploaded"),
                        React.createElement(Typography, { variant: TypographyVariant.Body }, "Thank you, you will be notified via email when your file has been processed."),
                        React.createElement(Block, { display: "flex", flexDirection: "row-reverse" },
                            React.createElement(Button, { size: "large", onClick: () => setIsOpen(false) }, "Ok")))))),
        React.createElement(Button, { variant: "primary", onClick: () => setIsOpen(true) }, "Upload")));
};
const Documents = ({ workspaceId }) => {
    const { data, isLoading } = useListUploadedFiles(workspaceId);
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Spread, null,
            React.createElement(Typography, { marginBottom: "16px", variant: TypographyVariant.Heading2 }, "Custom Data Uploads"),
            React.createElement(UploadModal, { workspaceId: workspaceId })),
        React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1, marginBottom: "24px" }, "Here you will find the list of custom data uploaded to this project."),
        React.createElement(DocumentTable, { workspaceId: workspaceId, data: data, isLoading: isLoading })));
};
export { Documents };
