import { runPermissionCheck } from '@humanfirst/api-models';
/** Checks a user with a set of resource/action permissions */
const checkPermissions = (userContext, resource, action, instance) => {
    return runPermissionCheck({
        context: userContext,
        resource,
        action,
        instance,
    });
};
export { checkPermissions };
