import { Card, Heading2, Stack, TypographyVariant } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { MetadataItem } from './MetadataItem';
const GeneralEvidenceProperties = ({ evidence, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const partiallyIngested = evidence.ingestionStatus === 'Partially Ingested';
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Details"),
            React.createElement(Stack, null,
                React.createElement(MetadataItem, { label: 'DHT Use Context', condition: !!((_a = evidence.dhtUseContext) === null || _a === void 0 ? void 0 : _a.length), metadata: (_b = evidence.dhtUseContext) === null || _b === void 0 ? void 0 : _b.join(', '), partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Study Design', condition: !!((_c = evidence.studyDesign) === null || _c === void 0 ? void 0 : _c.length), metadata: (_d = evidence.studyDesign) === null || _d === void 0 ? void 0 : _d.join(', '), partiallyIngested: partiallyIngested }),
                React.createElement(When, { condition: !((_e = evidence.typeOfSource) === null || _e === void 0 ? void 0 : _e.includes('ClinicalTrials.gov')) },
                    React.createElement(MetadataItem, { label: 'Journal', condition: !!evidence.journal, metadata: evidence.journal, partiallyIngested: partiallyIngested })),
                React.createElement(MetadataItem, { label: 'Recruitment Age', condition: !!((_f = evidence.ageRecruitmentCriteria) === null || _f === void 0 ? void 0 : _f.length), metadata: (_g = evidence.ageRecruitmentCriteria) === null || _g === void 0 ? void 0 : _g.join(', '), partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Sample Size', condition: !!evidence.sampleSize, metadata: evidence.sampleSize, partiallyIngested: partiallyIngested })))));
};
export { GeneralEvidenceProperties };
