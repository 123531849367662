import React from 'react';
import { MeasureTable } from 'src/components/MeasureTable';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { MeasureCategorySupportingEvidenceCell } from '../cells';
const MedicalConditionMeasureTable = ({ showEvidence, measuresWithEvidence, condition }) => {
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasMedicalConditionEvidence = useHasPermission('medicalConditions.evidence', 'read', {
        id: condition.id,
    });
    const measureCategorySupportingEvidenceColumn = {
        id: 'supportingEvidence',
        header: 'Supporting Evidence',
        sortable: 'numeric',
        getStringValue: (item) => String(item.evidenceCount),
        render: (item) => (React.createElement(MeasureCategorySupportingEvidenceCell, { item: item, showEvidence: showEvidence })),
    };
    if (!hasMedicalConditionEvidence && hasEvidenceSource) {
        return React.createElement(MeasureTable, { measureCategories: measuresWithEvidence || [] });
    }
    return (React.createElement(MeasureTable, { measureCategories: measuresWithEvidence || [], extraColumns: [measureCategorySupportingEvidenceColumn], initialState: !hasEvidenceSource
            ? undefined
            : {
                sort: {
                    column: 'supportingEvidence',
                    ascending: false,
                },
            } }));
};
export { MedicalConditionMeasureTable };
