import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useState } from 'react';
import { Block, Heading, Stack } from '@humanfirst/elektron';
import { When } from 'react-if';
import { PasswordlessCTA, PasswordlessSuccess, LoginForm } from '../';
const MultiStageLogin = ({ onSubmit, defaultEmail, }) => {
    const [stage, setStage] = useState('email');
    const [email, setEmail] = useState(defaultEmail !== null && defaultEmail !== void 0 ? defaultEmail : '');
    const [password, setPassword] = useState('');
    const [formError, setFormError] = useState();
    const [isEmailValid, setEmailValidity] = useState(false);
    const [isPasswordValid, setPasswordValidity] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onChangeEmailCallback = useCallback((email) => {
        setFormError(undefined);
        setStage('email');
        setEmail(email);
    }, []);
    const attemptLogin = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!isEmailValid) {
            setFormError('Please enter a valid email address.');
            return;
        }
        if (!isPasswordValid) {
            setFormError('Please enter a password.');
            return;
        }
        setIsLoading(true);
        if (stage === 'email') {
            try {
                yield onSubmit({ email, password: '' });
            }
            catch (e) {
                // To avoid user enumeration, we will move all requests to the next stage.
                setStage('credentials');
                setFormError(undefined);
            }
            setIsLoading(false);
            return;
        }
        try {
            yield onSubmit({ email, password });
            setFormError(undefined);
            setIsLoading(false);
        }
        catch (e) {
            setFormError(String(e));
            setIsLoading(false);
        }
    }), [onSubmit, email, password, stage, isEmailValid, isPasswordValid]);
    const [hasAttemptedDefaultSubmit, setHasAttemptedDefaultSubmit] = useState(false);
    useEffect(() => {
        if (!hasAttemptedDefaultSubmit && isEmailValid && defaultEmail) {
            // If we've never tried before,if email is valid, if default email is set, and we're in the "email" stage
            // then we should "continue" automatically
            setHasAttemptedDefaultSubmit(true);
            if (stage === 'email') {
                attemptLogin().catch(() => {
                    // Do nothing
                });
            }
        }
    }, [
        isEmailValid,
        defaultEmail,
        stage,
        hasAttemptedDefaultSubmit,
        attemptLogin,
    ]);
    const onSubmitCallback = useCallback(() => {
        attemptLogin().catch(() => {
            // Do nothing
        });
    }, [attemptLogin]);
    const onPasswordlessLinkSent = useCallback(() => {
        setStage('passwordlessLinkSent');
    }, [setStage]);
    if (stage === 'passwordlessLinkSent') {
        return React.createElement(PasswordlessSuccess, { email: email });
    }
    return (React.createElement(Block, null,
        React.createElement(Heading, null, "Sign In to Atlas"),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(LoginForm, { stage: stage, email: email, onChangeEmail: onChangeEmailCallback, onEmailValidityStateChange: setEmailValidity, password: password, onChangePassword: setPassword, onPasswordValidityStateChange: setPasswordValidity, onSubmit: onSubmitCallback, isLoading: isLoading, formError: formError }),
            React.createElement(When, { condition: stage === 'credentials' },
                React.createElement(PasswordlessCTA, { email: email, onLinkSendSuccess: onPasswordlessLinkSent })))));
};
export { MultiStageLogin };
