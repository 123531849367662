import React, { useMemo } from 'react';
import { LoadingState } from 'src/components/LoadingState';
import { MedicalConditionTable } from 'src/components/tables/MedicalConditionTable';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useHasPermissionsList } from 'src/hooks/permissions/useHasPermissionsList';
import { useGetEvidenceForMeasureCategory, useGetMedicalConditionsForMeasureCategory, } from 'src/hooks/relatedResources';
import { MedicalConditionSupportingEvidenceCell } from '../cells';
import { getMedicalConditionEvidence, } from '../utils/utils';
const MeasureCategoryMedicalConditionTable = ({ category, showEvidence }) => {
    const { isLoading: isMedicalConditionsLoading, data: medicalConditions } = useGetMedicalConditionsForMeasureCategory(category);
    const { data: evidence, isLoading: isEvidenceLoading } = useGetEvidenceForMeasureCategory(category);
    const medicalConditionsWithEvidence = useMemo(() => getMedicalConditionEvidence(medicalConditions !== null && medicalConditions !== void 0 ? medicalConditions : [], evidence !== null && evidence !== void 0 ? evidence : []), [medicalConditions, evidence]);
    const medicalConditionsPermissionsList = useHasPermissionsList({ resource: 'medicalConditions', action: 'read' }, medicalConditions !== null && medicalConditions !== void 0 ? medicalConditions : []);
    const medicalConditionsWithEvidenceAndPermissions = useMemo(() => {
        return medicalConditionsWithEvidence.map((x) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, x), { access: (_b = (_a = medicalConditionsPermissionsList.find(([{ id }]) => id === x.id)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : false }));
        });
    }, [medicalConditionsWithEvidence, medicalConditionsPermissionsList]);
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasMeasureEvidence = useHasPermission('measure.evidence', 'read', {
        id: category.id,
    });
    const medicalConditionSupportingEvidenceColumn = {
        id: 'supportingEvidenceCount',
        header: 'Supporting Evidence',
        sortable: 'numeric',
        getStringValue: (item) => String(item.evidenceCount),
        render: (item) => (React.createElement(MedicalConditionSupportingEvidenceCell, { item: item, showEvidence: showEvidence })),
    };
    if (isMedicalConditionsLoading || isEvidenceLoading) {
        return React.createElement(LoadingState, null);
    }
    if (!hasMeasureEvidence && hasEvidenceSource) {
        return (React.createElement(MedicalConditionTable, { data: medicalConditionsWithEvidenceAndPermissions }));
    }
    return (React.createElement(MedicalConditionTable, { data: medicalConditionsWithEvidenceAndPermissions, extraColumns: [medicalConditionSupportingEvidenceColumn], initialState: {
            sort: !hasEvidenceSource
                ? undefined
                : {
                    column: 'supportingEvidenceCount',
                    ascending: false,
                },
        } }));
};
export { MeasureCategoryMedicalConditionTable };
