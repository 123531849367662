import React, { useMemo } from 'react';
import { ExportJSONButton } from 'src/components/ExportJSONButton';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const DEFAULT_FHIR_CONTENT_TYPE = 'application/fhir+json';
function processArray(attributeValue) {
    if (attributeValue.every((a) => typeof a === 'string')) {
        return attributeValue.map((a) => ({ text: String(a) }));
    }
    else {
        return attributeValue.map((a) => {
            if (!!a && typeof a === 'object' && 'url' in a) {
                return { text: a.url };
            }
            return { text: String(a) };
        });
    }
}
function attributeToProperty(item, attribute, label) {
    const attributeValue = item[attribute];
    const returnObj = {
        type: {
            text: label,
        },
    };
    if (typeof attributeValue === 'string' ||
        typeof attributeValue === 'number') {
        returnObj.valueCode = [{ text: String(attributeValue) }];
    }
    if (Array.isArray(attributeValue)) {
        returnObj.valueCode = processArray(attributeValue);
    }
    return returnObj;
}
function getFHIRDeviceDefinition(tool, permissions) {
    var _a, _b, _c, _d;
    const toolPath = generatePath(PATHS.atlas.technologies.detail, {
        id: tool.id,
    });
    const toolsListPath = generatePath(PATHS.atlas.technologies.list);
    const toolsListUrl = new URL(toolsListPath, window.location.href);
    const toolUrl = new URL(toolPath, window.location.href);
    const makerPath = generatePath(PATHS.atlas.vendors.detail, {
        id: (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.id,
    });
    const makerUrl = new URL(makerPath, window.location.href);
    return {
        resourceType: 'DeviceDefinition',
        onlineInformation: toolUrl.toString(),
        identifier: [
            {
                system: toolsListUrl.toString(),
                value: tool.id,
            },
            {
                system: 'urn:ietf:rfc:3986',
                value: toolUrl.toString(),
            },
        ],
        manufacturerReference: {
            type: 'Organization',
            reference: makerUrl.toString(),
            display: (_c = (_b = tool.maker) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '',
        },
        deviceName: [
            {
                name: tool.model,
                type: 'user-friendly-name',
            },
        ],
        physicalCharacteristics: {
            image: (_d = tool.imageSubmittedCDN) === null || _d === void 0 ? void 0 : _d.map((attachment) => ({
                url: attachment.url,
                size: attachment.size,
                contentType: attachment.type,
            })),
        },
        property: [
            attributeToProperty(tool, 'modalitiesSensorType', 'Sensor Modality'),
            attributeToProperty(tool, 'dataExchangeModalities', 'Connectivity'),
            attributeToProperty(tool, 'compatibleOperatingSystem', 'Compatible Operating System'),
            attributeToProperty(tool, 'formFactor', 'Form Factor'),
            attributeToProperty(tool, 'wearLocation', 'Wear Location'),
            attributeToProperty(tool, 'batteryType', 'Battery Type'),
            attributeToProperty(tool, 'batteryLifeMin', 'Battery Life Low (hours)'),
            attributeToProperty(tool, 'batteryLifeMax', 'Battery Life High (hours)'),
            attributeToProperty(tool, 'manualCDN', 'Manual'),
            attributeToProperty(tool, 'softwareAlgorithm', 'Software'),
            attributeToProperty(tool, 'waterproof', 'Waterproof'),
            permissions.showRegulatoryDecisions
                ? attributeToProperty(tool, 'fdaClassification', 'FDA Classification')
                : undefined,
            permissions.showRegulatoryDecisions
                ? attributeToProperty(tool, 'ceMark', 'CE Mark')
                : undefined,
            permissions.showDetailedEvidence
                ? attributeToProperty(tool, 'countOfEvidenceMeasures', 'Total Product Evidence')
                : undefined,
        ].filter((a) => a !== undefined),
    };
}
function getFHIRBundle(resources, timestamp) {
    return {
        resourceType: 'Bundle',
        type: 'collection',
        timestamp: timestamp,
        entry: resources.map((r) => ({ resource: r })),
    };
}
const FHIRButton = ({ filename, resource, }) => {
    const showRegulatoryDecisions = useHasPermission('component.regulatoryDecisions', 'read');
    const showDetailedEvidence = useHasPermission('component.evidenceSummary', 'read');
    const { serializedResource, serializedFilename } = useMemo(() => {
        const timestamp = new Date()
            .toISOString()
            .replace(/\..+/, '')
            .replace(/[^0-9T]/g, '-');
        if (Array.isArray(resource)) {
            const serializedResource = getFHIRBundle(resource.map((r) => getFHIRDeviceDefinition(r, {
                showDetailedEvidence,
                showRegulatoryDecisions,
            })), timestamp);
            return {
                serializedResource,
                serializedFilename: `atlas-technology-bundle-${timestamp}.json`,
            };
        }
        const serializedResource = getFHIRDeviceDefinition(resource, {
            showDetailedEvidence,
            showRegulatoryDecisions,
        });
        return {
            serializedResource,
            serializedFilename: `atlas-technology-${timestamp}.json`,
        };
    }, [showRegulatoryDecisions, showDetailedEvidence, resource]);
    return (React.createElement(ExportJSONButton, { download: serializedFilename !== null && serializedFilename !== void 0 ? serializedFilename : filename, payload: serializedResource, contentType: DEFAULT_FHIR_CONTENT_TYPE, variant: "minimal", size: "large" }, "To JSON (FHIR)"));
};
export { FHIRButton };
