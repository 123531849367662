import { __awaiter } from "tslib";
import { ProjectCollaboratorResource, TeamMemberResource, useElektraApi, useElektraApiMutation, UserResource, } from '@humanfirst/use-elektra-api';
import { uniqBy } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useUser } from 'src/hooks/user';
import { useAnalyticsEvent } from 'src/hooks/analytics';
/**
 * Reconciles between a known state in the API and the updated state in the form.
 *
 * This is necessary since the API works on individual collaborators, but the form
 * wants to group all actions together.
 * @param project
 * @param existing
 * @returns
 */
export const useReconcileCollaboratorState = (project, existing) => {
    const { mutateAsync: addCollaborator } = useElektraApiMutation(ProjectCollaboratorResource.add(project.id));
    const { mutateAsync: removeCollaborator } = useElektraApiMutation(ProjectCollaboratorResource.remove(project.id));
    const { mutateAsync: updateCollaborator } = useElektraApiMutation(ProjectCollaboratorResource.updateAccess(project.id));
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const track = useAnalyticsEvent();
    const projectId = project.id;
    const callback = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            track('project-sharing-submitted', { projectId });
            // We need to identify all users who have been added or had their state updated.
            for (const collaborator of data.collaborators) {
                const current = existing.find((x) => x.user.id === collaborator.user.id);
                if (current) {
                    if (current.isEditor !== collaborator.isEditor) {
                        // The user's edit access was changed.
                        yield updateCollaborator({
                            isEditor: collaborator.isEditor,
                            collaboratorId: current.id,
                        });
                        track('project-collaborator-updated', {
                            userId: current.id,
                            isEditor: collaborator.isEditor,
                            projectId,
                        });
                    }
                }
                else {
                    // This user did not exist in our previous version, so add them.
                    yield addCollaborator({
                        userId: collaborator.user.id,
                        isEditor: collaborator.isEditor,
                    });
                    track('project-collaborator-added', {
                        userId: collaborator.user.id,
                        isEditor: collaborator.isEditor,
                        projectId,
                    });
                }
            }
            // Next we need to find all users who were removed.
            for (const current of existing) {
                const found = data.collaborators.find((x) => x.user.id === current.user.id);
                if (!found) {
                    yield removeCollaborator({ collaboratorId: current.id });
                    track('project-collaborator-removed', {
                        userId: current.id,
                        projectId,
                    });
                }
            }
        }
        catch (e) {
            setIsLoading(false);
            setError(String(e));
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }), [
        addCollaborator,
        removeCollaborator,
        updateCollaborator,
        existing,
        projectId,
        track,
    ]);
    return { callback, error, isLoading };
};
/** Determines if this project can be shared by the current user. */
export const useCanUpdateSharing = (project) => {
    var _a;
    const user = useUser();
    const hasPermission = useHasPermission('workspace', 'update', project);
    const hasTeams = !!((_a = user === null || user === void 0 ? void 0 : user.teams) === null || _a === void 0 ? void 0 : _a.length);
    // Sharing is enabled for anyone who can update the project and has a team available.
    return hasPermission && hasTeams;
};
/**
 * Loads a list of users who can be shared with.
 *
 * In the future this may look at the organization. For the time being, this will
 * just look at all of the teams a user belongs to.
 */
export const useAllShareableUsers = () => {
    var _a, _b;
    const user = useUser();
    const isAdmin = user === null || user === void 0 ? void 0 : user.isAdministrator;
    const { data: teamMembers, isLoading: isLoadingTeamMembers } = useElektraApi(TeamMemberResource.listBulk((_b = (_a = user === null || user === void 0 ? void 0 : user.teams) === null || _a === void 0 ? void 0 : _a.map((x) => x.id)) !== null && _b !== void 0 ? _b : []), { enabled: !isAdmin });
    const { data: allUsers, isLoading: isLoadingForAdmin } = useElektraApi(UserResource.list(), { enabled: !!isAdmin });
    const returnData = useMemo(() => {
        var _a;
        return isAdmin
            ? allUsers !== null && allUsers !== void 0 ? allUsers : []
            : (_a = teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.flatMap((member) => member).map((x) => x.user)) !== null && _a !== void 0 ? _a : [];
    }, [allUsers, isAdmin, teamMembers]);
    const isLoading = isAdmin ? isLoadingForAdmin : isLoadingTeamMembers;
    return useMemo(() => ({
        data: uniqBy((x) => x.id, returnData),
        isLoading,
    }), [returnData, isLoading]);
};
