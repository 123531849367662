import React from 'react';
const ComposeComponents = ({ components, children }) => {
    return (React.createElement(React.Fragment, null, components.reduceRight((previous, current) => {
        if (Array.isArray(current)) {
            const Component = current[0];
            return React.createElement(Component, Object.assign({}, current[1]), previous);
        }
        else {
            const Component = current;
            return React.createElement(Component, null, previous);
        }
    }, children)));
};
export { ComposeComponents };
