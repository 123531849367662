import React, { useCallback, useState } from 'react';
import { Stack } from '@humanfirst/elektron';
import { useElektraApi, UserProfileResource } from '@humanfirst/use-elektra-api';
import { useUser } from 'src/hooks/user';
import { UpdatePasswordModal } from './components/UpdatePasswordModal';
import { UpdateProfileModal } from './components/UpdateProfileModal';
import { PersonalInformationCard } from './components/PersonalInformationCard';
import { ProfessionalProfileCard } from './components/ProfessionalProfileCard';
const Profile = () => {
    var _a;
    const user = useUser();
    const [personalInfoModalOpen, setPersonalInfoModalOpen] = useState(false);
    const [professionalInfoModalOpen, setProfessionalModalOpen] = useState(false);
    const openEditProfessionalInfoModal = useCallback(() => setProfessionalModalOpen(true), []);
    const closeEditProfessionalInfoModal = useCallback(() => setProfessionalModalOpen(false), []);
    const openEditPersonalInfoModal = useCallback(() => setPersonalInfoModalOpen(true), []);
    const closeEditPersonalInfoModal = useCallback(() => setPersonalInfoModalOpen(false), []);
    const { data: userProfile } = useElektraApi(UserProfileResource.getByUser((_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : ''), { enabled: !!(user === null || user === void 0 ? void 0 : user.id) });
    if (!user) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(PersonalInformationCard, { user: user, onEditPersonalInformation: openEditPersonalInfoModal }),
            React.createElement(ProfessionalProfileCard, { profile: userProfile, onEditProfessionalInformation: openEditProfessionalInfoModal })),
        React.createElement(UpdateProfileModal, { isOpen: professionalInfoModalOpen, profile: userProfile, user: user, onClose: closeEditProfessionalInfoModal }),
        React.createElement(UpdatePasswordModal, { isOpen: personalInfoModalOpen, onClose: closeEditPersonalInfoModal })));
};
export { Profile };
