import React from 'react';
import { Block, Image } from '@humanfirst/elektron';
const VendorLogo = ({ vendor, imageSize, }) => {
    var _a;
    const logo = vendor.logoCDN;
    const logoURL = (_a = logo === null || logo === void 0 ? void 0 : logo[0]) === null || _a === void 0 ? void 0 : _a.url;
    return (React.createElement(Block, { width: imageSize, height: imageSize, minWidth: imageSize, minHeight: imageSize, display: "flex" },
        React.createElement(Image, { width: "100%", alt: vendor.name, src: logoURL, objectFit: "contain" })));
};
export { VendorLogo };
