import { Spinner, ToasterContainer } from '@humanfirst/elektron';
import React, { Suspense, useContext } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ComposeComponents } from './components/ComposeComponents';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ModalContainer } from './components/ModalContainer';
import { VersionUpdate } from './components/VersionUpdate';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { CelebrationProvider } from './contexts/CelebrationContext';
import { IntercomProviderWithUser } from './contexts/IntercomContext';
import { StatusProvider } from './contexts/StatusContext';
import { ToggleProvider } from './contexts/ToggleContext';
import { UserContext, UserProvider } from './contexts/UserContext';
import { VersionProvider } from './contexts/VersionContext';
import { WrappedApiProvider } from './contexts/WrappedApiProvider';
import { WrappedElektronProvider } from './contexts/WrappedElektronProvider';
import { LoggerProvider } from './contexts/LoggerContext';
import { RecaptchaProvider } from './contexts/RecaptchaContext';
import { useAnalytics } from './hooks/analytics';
import { useScrollToTopOnPathChange } from './hooks/scroll';
import { store } from './state';
import { useHasPermissions } from './hooks/permissions/useHasPermissions';
import { LockedAppRouter } from './RootRouter';
import { UrlWorkspaceProvider } from './contexts/WorkspaceContext';
import { ConnectProvider } from './contexts/ConnectContext';
import { useResetConnectFilters } from './hooks/connectReset';
import { PersistAuthWrapper } from './components/PersistAuthWrapper';
// We can split the pages loading while we do the rest of our API set up.
const NewRootRouter = React.lazy(() => import('./RootRouter'));
/**
 * Main entry point for the atlas-web application.
 * This establishes all providers/contexts and renders elements that
 * exist on all routes.
 */
const App = () => {
    return (React.createElement(ErrorBoundary, null,
        React.createElement(ComposeComponents, { components: [
                LoggerProvider,
                [Provider, { store }],
                HelmetProvider,
                WrappedElektronProvider,
                BrowserRouter,
                [
                    QueryParamProvider,
                    {
                        adapter: ReactRouter6Adapter,
                        options: { removeDefaultsFromUrl: true },
                    },
                ],
                AuthenticationProvider,
                PersistAuthWrapper,
                WrappedApiProvider,
                ToggleProvider,
                IntercomProviderWithUser,
                UserProvider,
                VersionProvider,
                StatusProvider,
                CelebrationProvider,
                RecaptchaProvider,
                UrlWorkspaceProvider,
                ConnectProvider,
            ] },
            React.createElement(RootRouter, null),
            React.createElement(VersionUpdate, null),
            React.createElement(ToasterContainer, { autoHideDuration: 5000, placement: 'topRight', overrides: {
                    Root: {
                        style: {
                            paddingTop: '56px',
                        },
                    },
                } }),
            React.createElement(ModalContainer, null))));
};
/**
 * The root of our app router.
 *
 * This component handles unauthenticated requests (login/logout) and
 * defers all others to the AuthenticatedRoutes.
 */
const RootRouter = () => {
    // Global logic that should be applied on all pages.
    useScrollToTopOnPathChange();
    useResetConnectFilters();
    useAnalytics();
    const { isLoading, user } = useContext(UserContext);
    const hasAnyValidPermissions = useHasPermissions([
        { resource: 'evidenceSource', action: 'read' },
        { resource: 'component', action: 'read' },
        { resource: 'medicalConditions', action: 'read' },
        { resource: 'vendor', action: 'read' },
        { resource: 'measure', action: 'read' },
    ], 'some');
    if (!isLoading && user && !hasAnyValidPermissions) {
        return React.createElement(LockedAppRouter, null);
    }
    return (
    // Some routes use asynchronous loading, so use a suspense here with a generic fallback.
    React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(NewRootRouter, null)));
};
export { App, RootRouter };
