import { useMemo } from 'react';
import { serializeFilters } from './filters';
import { useRawSearch } from './useRawSearch';
const MAX_PAGEABLE_HITS = 1000;
function useSearch({ searchClient, indexName, query = '', filters, page = 1, enabled, }) {
    var _a, _b, _c;
    const options = useMemo(() => ({
        query,
        filters: serializeFilters(filters !== null && filters !== void 0 ? filters : []),
        page: page - 1,
    }), [query, filters, page]);
    const { isError, isLoading, error, response } = useRawSearch({
        searchClient,
        indexName,
        options,
        enabled,
    });
    const hitCount = (_a = response === null || response === void 0 ? void 0 : response.nbHits) !== null && _a !== void 0 ? _a : 0;
    const hitsPerPage = (_b = response === null || response === void 0 ? void 0 : response.hitsPerPage) !== null && _b !== void 0 ? _b : 1;
    const hasMoreHitsThanPageable = hitCount > MAX_PAGEABLE_HITS;
    const pageCount = hasMoreHitsThanPageable
        ? MAX_PAGEABLE_HITS / hitsPerPage
        : (_c = response === null || response === void 0 ? void 0 : response.nbPages) !== null && _c !== void 0 ? _c : 0;
    const data = response === null || response === void 0 ? void 0 : response.hits;
    return useMemo(() => ({
        isLoading,
        isError,
        error,
        data,
        pageCount,
        hitCount,
    }), [isLoading, isError, error, data, hitCount, pageCount]);
}
export { useSearch };
