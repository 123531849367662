import { Spread, Block, Icons, Typography, TypographyVariant, COLORS, Stack, Link, } from '@humanfirst/elektron';
import React from 'react';
import { generatePath } from 'src/utils/path';
import { LockedTooltip } from 'src/components/LockedTooltip';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
export const MedicalConditionTitleCell = ({ condition }) => {
    const canViewMedicalCondition = useHasPermission('medicalConditions', 'read', condition);
    const medicalConditionHref = generatePath(PATHS.atlas.medicalConditions.detail, {
        id: condition.id,
    });
    if (!canViewMedicalCondition) {
        return (React.createElement(LockedTooltip, { body: 'Get in touch with our team to upgrade and unlock details for this medical condition.', title: 'Access Medical Condition' },
            React.createElement(Spread, { justifyContent: "flex-start", gap: "8px" },
                React.createElement(Block, { width: '24px' },
                    React.createElement(Icons.Misc.Lock, { size: "24px", title: "Locked" })),
                React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.primary }, condition.name))));
    }
    return (React.createElement(Stack, { direction: 'horizontal' },
        React.createElement(Link, { href: medicalConditionHref }, condition.name),
        ' '));
};
