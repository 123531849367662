import { __awaiter } from "tslib";
import { WorkspaceStatus } from '@humanfirst/api-models';
import { Button, toaster } from '@humanfirst/elektron';
import { useElektraApiMutation, WorkspaceResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { WorkspaceTable } from 'src/components/WorkspaceTable';
const useUnarchiveWorkspace = (workspace) => {
    const { mutateAsync } = useElektraApiMutation(WorkspaceResource.update(workspace.id));
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield mutateAsync({ status: WorkspaceStatus.Active });
        toaster.info(`Unarchived Project: ${workspace.name}`);
    }), [mutateAsync, workspace.name]);
};
const UnarchiveButton = ({ workspace }) => {
    const doUnarchive = useUnarchiveWorkspace(workspace);
    return (React.createElement(Button, { variant: "tertiary", onClick: doUnarchive }, "Unarchive"));
};
const UNARCHIVE_COLUMN = {
    id: 'unarchive',
    header: '',
    render: (item) => React.createElement(UnarchiveButton, { workspace: item }),
};
export const ArchivedWorkspacesTable = ({ data, }) => {
    return React.createElement(WorkspaceTable, { data: data, extraColumns: [UNARCHIVE_COLUMN] });
};
