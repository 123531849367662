export const PermissionsByRoles = [
    {
        name: 'OpenAtlas',
        grants: [
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recYv0jQ0vGF9JKfn',
            },
            {
                resource: 'component',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'study',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'receI30E5SWWE4vyR',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recq9WDDZTQ3mtyOI',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec2y2zkH2RVcVusQ',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recG4fHo5bXeLv30X',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'receI30E5SWWE4vyR',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'recq9WDDZTQ3mtyOI',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'rec2y2zkH2RVcVusQ',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'recG4fHo5bXeLv30X',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'recxNlNCxfMuS9ag3',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recxNlNCxfMuS9ag3',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'recxStQY6w8e2RSEj',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recP5QA4Us7cIWgf0',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rechu0eicR0jLCZDs',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recPey1JHJzX5hd0Z',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recDmj8iitEMLPSfM',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recBPvXMKCEJ60I7g',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recDbZk3iNNHC3J8z',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recnhQcP4hfaeGjHa',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec0rCFD1v2hio0Su',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recey2La9ZDW9wTzW',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recLN4lkjwlNNsU6z',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recndfwkRAuj7b5qn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec8gTaqBxb140o6W',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recuRAeau2pTnKRxs',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recCy6BE4HNN6QGC2',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recSaaY4xs33phpRL',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recvmXRaFkWoTLbT0',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recLuYqkvTVw8JdaG',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reces5wIgQbIfYVHT',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reciLPVZpbQ0mmuEr',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recT7WmEYa3ZuO63p',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recWucX0UiDsEPnlV',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recq6ubGmljEDcCfr',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recMXswcSSoHtE1DV',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recOFBc9qWlxQ5JS2',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reciqiFhPzQzDsbIs',
            },
            {
                resource: 'vendor',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'workspace',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recnL5P222PiQHQnu',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recrhtlarUAkFLaXx',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recEtRqW9yC7klwf9',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'receQUaRJJ3COc6qn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reczYuxHykjVq4c6X',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recMgRXgMlxK2ecYG',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recWZYD7329hxWfEl',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recK6N09sCshFlcvR',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recNLKUD2W3V7mh13',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec9p0IeGAgfqBUGn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recMGno3TRoXImd8U',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recLh7AbUHzRhXSrf',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recD0RETGJPAD6SGm',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec5tYPX4xOBxs1s2',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec0wCsMK5Y9WGXhE',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recuPl7OSFjNOaoZ6',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reciRHBZ4uETrq9vM',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec0sYNHF6PF2C7Qx',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recd2RZs68hf9eUzf',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reczC5V6DJb0F6qMr',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recjXFri7A6J4Y7YE',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recnL5P222PiQHQnu',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recrhtlarUAkFLaXx',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recEtRqW9yC7klwf9',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recCTWB4tg0cJP6L3',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'receQUaRJJ3COc6qn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recsqtYBeZuYQmxz4',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recvSB2JY2Ikor5cc',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reczYuxHykjVq4c6X',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recMgRXgMlxK2ecYG',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recQd8fRWblGb37Ap',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recWZYD7329hxWfEl',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recK6N09sCshFlcvR',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recNLKUD2W3V7mh13',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec9p0IeGAgfqBUGn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recLh7AbUHzRhXSrf',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recoWLuPmh0kPtUNz',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recD0RETGJPAD6SGm',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec5tYPX4xOBxs1s2',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec0wCsMK5Y9WGXhE',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recuPl7OSFjNOaoZ6',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recJrmjtqNlmyO2BN',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec0sYNHF6PF2C7Qx',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'rec65HZ7XCR0Y5ltn',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recVebZF9dCkNGiLy',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recZ2yeCTHr6cLTxF',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'reckMtSwns3UjWASF',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recd2RZs68hf9eUzf',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recEeGp1qH1lWlHQI',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'id',
                predicateArgument: 'recYv0jQ0vGF9JKfn',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'id',
                predicateArgument: 'recxStQY6w8e2RSEj',
            },
        ],
    },
    {
        name: 'AtlasPro',
        grants: [
            {
                resource: 'component.integrationOptions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.evidenceSummary',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.measures',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.regulatoryDecisions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'searchResult.evidenceMeasures',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'searchResult.medicalConditions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search.medicalConditions',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.similar',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure.similar',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.history',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'medicalConditions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'study',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'vendor',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'vendor.history',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
        ],
    },
    {
        name: 'AtlasEnterprise',
        grants: [
            {
                resource: 'component',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.history',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.integrationOptions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.measures',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'evidenceSource',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search.medicalConditions',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'searchResult.medicalConditions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'medicalConditions.evidence',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'medicalConditions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.evidenceSummary',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.regulatoryDecisions',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.similar',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure.similar',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'organization.evidence',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'search.measures',
                action: 'create',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'searchResult.evidenceMeasures',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'vendor',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'study',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'measure.evidence',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'component.evidence',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
            {
                resource: 'vendor.history',
                action: 'read',
                predicate: 'all',
                predicateArgument: '',
            },
        ],
    },
];
