const getDate = (source) => {
    if (source.year) {
        return `${source.year}`;
    }
    else if (source.fdaDecisionDate) {
        return source.fdaDecisionDate;
    }
    else if (source.ctStartDate) {
        return source.ctStartDate;
    }
    else {
        return '';
    }
};
/**
 * Gets a list of all of the secondary facts related to a source.
 * This is metadata that should be rendered underneath the title.
 */
const getSecondaryFacts = (item) => {
    var _a, _b, _c;
    return [
        (_a = item.typeOfSource) === null || _a === void 0 ? void 0 : _a.join(', '),
        (_b = item.dhtUseContext) === null || _b === void 0 ? void 0 : _b.join(', '),
        item.ctStatus,
        item.ctPhase,
        (_c = item.ctCountry) === null || _c === void 0 ? void 0 : _c.join(', '),
        item.journal,
        item.ctSponsor,
        item.sampleSize ? `Sample size: ${item.sampleSize}` : null,
    ].filter((x) => !!x);
};
/**
 * Gets all associated tool models for an evidence.
 */
const getToolModels = (item) => {
    var _a, _b;
    return ((_b = (_a = item.sourceMeasures) === null || _a === void 0 ? void 0 : _a.flatMap((x) => { var _a; return (_a = x.tools) === null || _a === void 0 ? void 0 : _a.map((tool) => tool.model).filter((t) => t); })) !== null && _b !== void 0 ? _b : []);
};
export { getDate, getSecondaryFacts, getToolModels };
