import { Card, Stack } from '@humanfirst/elektron';
import { OrganizationResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { IntegratorComingSoon } from './components/IntegratorComingSoon';
import { PrimaryCard } from './components/PrimaryCard';
import { VendorDataRightsAndSecurityCard } from './components/VendorDataRightsAndSecurityCard';
import { VendorIntegratedTech } from './components/VendorIntegratedTech';
import { VendorTechnologies } from './components/VendorTechnologies';
import { VendorTherapeuticAreas } from './components/VendorTherapeuticAreas';
import { isActiveIntegrator, isInactiveIntegrator, isInProgressIntegrator, isMaker, } from './utils/util';
import { VendorEvidenceBreakdown } from './components/VendorEvidenceBreakdown';
import { VendorEvidence } from './components/VendorEvidence';
const OrganizationPageContainer = ({ data: organization, }) => {
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Atlas,
            BREADCRUMBS.Vendors,
            BREADCRUMBS.VendorDetails(organization),
        ] },
        React.createElement(PageTitle, { title: organization.name }),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(When, { condition: 
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                isInactiveIntegrator(organization) ||
                    isInProgressIntegrator(organization) },
                React.createElement(Card, null,
                    React.createElement(IntegratorComingSoon, { organization: organization }))),
            React.createElement(PrimaryCard, { organization: organization }),
            React.createElement(OrganizationCards, { organization: organization }),
            React.createElement(VendorEvidence, { organization: organization }))));
};
const OrganizationCards = ({ organization, }) => {
    const integrator = isActiveIntegrator(organization);
    const maker = isMaker(organization);
    if (!maker && !integrator) {
        return null;
    }
    return (React.createElement(Stack, { direction: "horizontal", gap: "24px" },
        React.createElement(Stack, { width: '50%', gap: '24px' },
            React.createElement(VendorEvidenceBreakdown, { organization: organization }),
            React.createElement(When, { condition: maker },
                React.createElement(VendorTechnologies, { organization: organization })),
            React.createElement(When, { condition: integrator },
                React.createElement(VendorIntegratedTech, { organization: organization }))),
        React.createElement(Stack, { width: "50%", gap: "24px" },
            React.createElement(When, { condition: integrator },
                React.createElement(VendorTherapeuticAreas, { organization: organization })),
            React.createElement(VendorDataRightsAndSecurityCard, { organization: organization }))));
};
/**
 * Renders all of the evidence for an organization.
 */
const OrganizationPage = () => {
    const { id } = useParams();
    const result = useElektraApi(OrganizationResource.get(id, {
        join: [
            'legalPolicies',
            'securityPolicies',
            'components',
            'components.integrations',
        ],
    }));
    return React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: OrganizationPageContainer }));
};
export { OrganizationPage as Organization };
