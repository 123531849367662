import { MeasureResource, MedicalConditionsResource, OrganizationResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
/**
 * Loads summary statistics for the app.
 * This combines several different statistic endpoints to get comprehensive statistics.
 */
export const useAppStatistics = () => {
    const { data: medicalConditionResult, isLoading: isMedicalConditionLoading } = useElektraApi(MedicalConditionsResource.getStatistics());
    const { data: toolsResult, isLoading: isToolsLoading } = useElektraApi(ToolResource.getStatistics());
    const { data: measuresResult, isLoading: isMeasuresLoading } = useElektraApi(MeasureResource.getStatistics());
    const { data: vendorsResult, isLoading: isVendorsLoading } = useElektraApi(OrganizationResource.getStatistics());
    if (isMeasuresLoading ||
        isToolsLoading ||
        isMedicalConditionLoading ||
        isVendorsLoading) {
        return { data: {}, isLoading: true };
    }
    const data = {
        technologies: toolsResult === null || toolsResult === void 0 ? void 0 : toolsResult.count,
        measures: measuresResult === null || measuresResult === void 0 ? void 0 : measuresResult.count,
        medicalConditions: medicalConditionResult === null || medicalConditionResult === void 0 ? void 0 : medicalConditionResult.count,
        vendors: vendorsResult === null || vendorsResult === void 0 ? void 0 : vendorsResult.count,
    };
    return { data, isLoading: false };
};
