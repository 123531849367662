import React from 'react';
import { COLORS, Icons, Link, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { t } from 'src/constants/i18n';
import { isCoa } from 'src/pages/Tool/utils/selectors';
import { attachAttributeMetadata, buildLinkModelAttribute, buildListAttribute, buildLockableAttribute, buildPermissionBasedAttribute, buildRankedStackModelAttribute, buildStringModelAttribute, buildMarkdownModelAttribute, DEFAULT_NOT_SPECIFIED_TEXT, } from './ModelAttribute';
import { buildPolicyAttribute, } from './PolicyAttribute';
import { CEMarkDisplay, ConnectivityDisplay, CTPhasesDisplay, FormFactorDisplay, MDDTClassificationDisplay, MeasuresDisplay, OperatingSystemDisplay, PopulationDisplay, RequiredHardwareDisplay, SensorTypeDisplay, TotalValidationDisplay, V3AnalyicalDisplay, V3ClinicalDisplay, V3VerificationDisplay, WaterproofDisplay, WearLocationDisplay, } from './DisplayHelpers';
/** Units for handling battery life. */
var TimeUnit;
(function (TimeUnit) {
    TimeUnit["Hours"] = "hours";
    TimeUnit["Days"] = "days";
    TimeUnit["Months"] = "months";
    TimeUnit["Years"] = "years";
})(TimeUnit || (TimeUnit = {}));
/**
 * Determines how a set of time units should be displayed.
 *
 * General rules are:
 *  - < 72    -> hours
 *  - < 720   -> days
 *  - < 17520 -> months
 *  - else    -> years
 */
const getAppropriateUnit = (hours) => {
    const onlyNumbers = hours.filter((x) => x !== null);
    if (onlyNumbers.every((x) => x < 72)) {
        return TimeUnit.Hours;
    }
    else if (onlyNumbers.every((x) => x < 720)) {
        return TimeUnit.Days;
    }
    else if (onlyNumbers.every((x) => x < 17520)) {
        return TimeUnit.Months;
    }
    else {
        return TimeUnit.Years;
    }
};
/** Normalize a number of hours into a more readable number. */
const normalizeHours = (hours, unit) => {
    switch (unit) {
        case TimeUnit.Hours:
            return hours;
        case TimeUnit.Days:
            return Math.round(hours / 24);
        case TimeUnit.Months:
            return Math.round(hours / 720);
        case TimeUnit.Years:
            // Years aren't rounded so we keep some precision.
            return (hours / 8760).toFixed(1);
    }
};
/**
 * Gets the battery life information (low/high hours) as a string array.
 */
const getBatteryLife = (item) => {
    const value = [];
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const [min, max] = [item.batteryLifeMin || null, item.batteryLifeMax || null];
    const unit = getAppropriateUnit([min, max]);
    if (min === max && min !== null) {
        // If the values are the same (and non null), we just show that single value.
        value.push(`${normalizeHours(min, unit)} ${unit}`);
    }
    else if (min === null && max !== null) {
        // If the min is present, but _not_ the max we show "Up to"
        value.push(`Up to ${normalizeHours(max, unit)} ${unit}`);
    }
    else if (min !== null && max === null) {
        // If the max is present, but _not_ the min, we show "+"
        value.push(`${normalizeHours(min, unit)}+ ${unit}`);
    }
    else if (min !== null && max !== null) {
        // If both values are present we show the range.
        value.push(`${normalizeHours(min, unit)} - ${normalizeHours(max, unit)} ${unit}`);
    }
    return value;
};
/**
 * Gets the combined battery information (type, low/high hours) as a string array.
 */
const getCombinedBattery = (item) => {
    var _a;
    const value = [];
    if ((_a = item.batteryType) === null || _a === void 0 ? void 0 : _a.length) {
        value.push(item.batteryType.join(', '));
    }
    value.push(...getBatteryLife(item));
    return value;
};
function buildToolPolicyAttribute(definition) {
    const PolicyAttribute = buildPolicyAttribute(definition);
    const component = ({ item }) => {
        var _a, _b;
        const policy = (_b = (_a = item.maker) === null || _a === void 0 ? void 0 : _a[definition.policyClass]) === null || _b === void 0 ? void 0 : _b.find((x) => x.type === definition.policyType);
        return React.createElement(PolicyAttribute, { item: policy });
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Works like buildLinkModelAttribute, but accepts a fallback attribute.
 * If the link is missing the fallback attribute will be rendered as a simple
 * text value.
 */
function buildLinkWithFallback(definition) {
    const component = ({ item }) => {
        const linkValue = item[definition.attribute];
        if (linkValue) {
            return React.createElement(Link, { href: linkValue }, "Available Here");
        }
        else {
            const value = item[definition.fallbackAttribute];
            if (!value ||
                (typeof value === 'string' &&
                    value.toLowerCase() === DEFAULT_NOT_SPECIFIED_TEXT.toLowerCase())) {
                return (React.createElement(Typography, { color: COLORS.gray2 }, DEFAULT_NOT_SPECIFIED_TEXT));
            }
            return (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, item[definition.fallbackAttribute]));
        }
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Holds all of the attributes we care about for a tool.
 */
const ToolAttribute = {
    SensorType: attachAttributeMetadata(SensorTypeDisplay, {
        label: 'Sensor Modality',
    }),
    Connectivity: attachAttributeMetadata(ConnectivityDisplay, {
        label: 'Connectivity',
    }),
    Waterproof: attachAttributeMetadata(WaterproofDisplay, {
        label: 'Waterproof',
    }),
    WearLocation: attachAttributeMetadata(WearLocationDisplay, {
        label: 'Wear Location',
    }),
    Price: buildStringModelAttribute({
        attribute: 'price',
        label: 'Price',
    }),
    Availability: buildStringModelAttribute({
        attribute: 'availability',
        label: 'Availability',
    }),
    ProductLifeCycle: buildRankedStackModelAttribute({
        attribute: 'availability',
        label: 'Product Life Cycle',
        mappings: {
            'Announced/In development': {
                label: 'In development',
                backgroundColor: COLORS.primary,
            },
            'Available to purchase': {
                label: 'Available/Supported',
                backgroundColor: COLORS.success,
            },
            'Soon to be discontinued': {
                label: 'Phasing out',
                backgroundColor: COLORS.tertiary,
            },
            Discontinued: {
                label: 'Discontinued',
                backgroundColor: COLORS.alert,
            },
        },
    }),
    OperatingSystem: attachAttributeMetadata(OperatingSystemDisplay, {
        label: 'Compatible Operating System',
    }),
    FormFactor: attachAttributeMetadata(FormFactorDisplay, {
        label: 'Form Factor',
    }),
    Distribution: buildStringModelAttribute({
        attribute: 'distributionChannel',
        label: 'Distribution Channel',
    }),
    Manual: buildLinkModelAttribute({
        attribute: (item) => { var _a, _b; return (_b = (_a = item.manualCDN) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url; },
        label: 'Manual',
        linkText: () => 'Manual',
    }),
    SpecSheet: buildLinkModelAttribute({
        attribute: (item) => { var _a, _b; return (_b = (_a = item.specificationSheetCDN) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url; },
        label: 'Spec Sheet',
        linkText: () => 'Spec Sheet',
    }),
    Software: buildStringModelAttribute({
        attribute: 'softwareAlgorithm',
        label: 'Software',
    }),
    BatteryType: buildStringModelAttribute({
        attribute: 'batteryType',
        label: 'Battery Type',
    }),
    BatteryLife: buildStringModelAttribute({
        attribute: getBatteryLife,
        label: 'Battery Life',
    }),
    BatteryMin: buildStringModelAttribute({
        attribute: 'batteryLifeMin',
        label: 'Battery Life Low (hours)',
    }),
    BatteryMax: buildStringModelAttribute({
        attribute: 'batteryLifeMax',
        label: 'Battery Life High (hours)',
    }),
    BatteryCombined: buildStringModelAttribute({
        attribute: getCombinedBattery,
        label: 'Battery',
    }),
    HasApi: buildPermissionBasedAttribute({
        hasPermissionAttribute: buildLinkWithFallback({
            attribute: 'apiUrl',
            fallbackAttribute: 'hasAPI',
            label: 'API Availability',
        }),
        noPermissionAttribute: buildStringModelAttribute({
            attribute: 'hasAPI',
            label: 'API Availability',
        }),
        resource: 'component.integrationOptions',
        action: 'read',
    }),
    Evidence: buildLockableAttribute({
        attribute: buildStringModelAttribute({
            attribute: 'countOfEvidenceMeasures',
            label: 'Total Product Evidence',
            info: t('toolAttributes.productEvidenceTooltip'),
        }),
        resource: 'component.evidenceSummary',
        action: 'read',
    }),
    ValidationTotal: buildLockableAttribute({
        attribute: attachAttributeMetadata(TotalValidationDisplay, {
            label: 'Total Validation Evidence',
            info: t('toolAttributes.v3EvidenceTooltip'),
        }),
        resource: 'component.evidenceSummary',
        action: 'read',
    }),
    V3Analytical: buildLockableAttribute({
        attribute: attachAttributeMetadata(V3AnalyicalDisplay, {
            label: 'V3: Analytical Validation',
        }),
        resource: 'component.evidenceSummary',
        action: 'read',
    }),
    V3Clinical: buildLockableAttribute({
        attribute: attachAttributeMetadata(V3ClinicalDisplay, {
            label: 'V3: Clinical Validation',
        }),
        resource: 'component.evidenceSummary',
        action: 'read',
    }),
    V3Verification: buildLockableAttribute({
        attribute: attachAttributeMetadata(V3VerificationDisplay, {
            label: 'V3: Verification',
        }),
        resource: 'component.evidenceSummary',
        action: 'read',
    }),
    Population: buildLockableAttribute({
        attribute: attachAttributeMetadata(PopulationDisplay, {
            label: 'Population',
        }),
        resource: 'component.evidence',
        action: 'read',
    }),
    CTPhases: buildLockableAttribute({
        attribute: attachAttributeMetadata(CTPhasesDisplay, {
            label: 'Clinical Trial Phases',
            info: t('toolAttributes.ctPhasesEvidenceTooltip'),
        }),
        resource: 'component.evidence',
        action: 'read',
    }),
    Measures: attachAttributeMetadata(MeasuresDisplay, {
        label: 'Measures',
    }),
    InternalMemory: buildListAttribute({
        attribute: 'internalMemory',
        label: 'Internal Memory',
    }),
    PhoneCamera: buildListAttribute({
        attribute: 'phoneCamera',
        label: 'Phone Camera',
    }),
    PhonePorts: buildStringModelAttribute({
        attribute: 'phonePorts',
        label: 'Phone Ports',
    }),
    BatteryCapacity: buildStringModelAttribute({
        attribute: 'phoneBatteryCapacity',
        label: 'Battery Capacity',
    }),
    OtherUsefulLinks: buildMarkdownModelAttribute({
        attribute: 'otherUsefulLinks',
        label: 'Other Useful Links',
    }),
    SoftwareBasedComponent: buildStringModelAttribute({
        attribute: 'softwareBasedComponent',
        label: 'Software Based Component',
    }),
    AccessToPreprocessedData: buildStringModelAttribute({
        attribute: 'accessToPreprocessedData',
        label: 'Access To Preprocessed Data',
    }),
    FDAClassification: buildLockableAttribute({
        attribute: buildStringModelAttribute({
            attribute: 'fdaClassifications',
            label: 'FDA Classification',
        }),
        resource: 'component.regulatoryDecisions',
        action: 'read',
    }),
    FDAStatus: buildLockableAttribute({
        attribute: buildStringModelAttribute({
            attribute: 'fdaStatus',
            label: 'FDA Status',
        }),
        resource: 'component.regulatoryDecisions',
        action: 'read',
    }),
    MDDTClassification: buildLockableAttribute({
        attribute: attachAttributeMetadata(MDDTClassificationDisplay, {
            label: 'MDDT Classification',
        }),
        resource: 'component.regulatoryDecisions',
        action: 'read',
    }),
    CEMark: buildLockableAttribute({
        attribute: attachAttributeMetadata(CEMarkDisplay, {
            label: 'CE Mark',
        }),
        resource: 'component.regulatoryDecisions',
        action: 'read',
    }),
    ProductDescription: buildStringModelAttribute({
        attribute: 'productDescription',
        label: 'Product Description',
    }),
    RequiredHardwareSBC: attachAttributeMetadata(RequiredHardwareDisplay, {
        label: 'Required Hardware',
    }),
    CoaSubtype: buildStringModelAttribute({
        attribute: 'coaSubtype',
        label: 'COA Subtype',
    }),
    AdministrationMethod: buildStringModelAttribute({
        attribute: 'administrationMethod',
        label: 'Administrative Method',
    }),
    QuestionType: buildStringModelAttribute({
        attribute: 'questionTypes',
        label: 'Question Type',
    }),
    ScoringMethodology: buildMarkdownModelAttribute({
        attribute: 'scoringMethodology',
        label: 'Scoring Methodology',
    }),
    TestTime: buildStringModelAttribute({
        attribute: 'testTime',
        label: 'Time for Completion',
    }),
    QuestionCount: buildStringModelAttribute({
        attribute: 'questionCount',
        label: 'Question Count',
    }),
    RecallPeriod: buildStringModelAttribute({
        attribute: 'recallPeriod',
        label: 'Question Recall Period',
    }),
    YearPublished: buildStringModelAttribute({
        attribute: 'yearAvailable',
        label: 'Year Published',
    }),
    LanguageSupport: buildStringModelAttribute({
        attribute: 'languageSupport',
        label: 'Language Support',
    }),
    GeneralNotes: buildMarkdownModelAttribute({
        attribute: 'generalNotes',
        label: 'General Notes',
    }),
    DataCollection: buildStringModelAttribute({
        attribute: 'dataCollection',
        label: 'Data Collection (Active vs Passive)',
    }),
    RequiredSensorsSBC: buildStringModelAttribute({
        attribute: 'requiredSensorsSBC',
        label: 'Required Sensors',
    }),
    HasSDK: buildPermissionBasedAttribute({
        hasPermissionAttribute: buildLinkWithFallback({
            attribute: 'sdkUrl',
            fallbackAttribute: 'hasSDK',
            label: 'SDK Availability',
        }),
        noPermissionAttribute: buildStringModelAttribute({
            attribute: 'hasSDK',
            label: 'SDK Availability',
        }),
        resource: 'component.integrationOptions',
        action: 'read',
    }),
    CVD: buildToolPolicyAttribute({
        policyClass: 'securityPolicies',
        policyType: 'Coordinated Vulnerability Disclosure (CVD)',
        label: t('policies.cvd.label'),
        exists: t('policies.cvd.exists'),
        existsTooltip: {
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        unavailable: t('policies.cvd.unavailable'),
        unavailableTooltip: {
            maker: t('policies.cvd.unavailableTooltip.maker'),
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746-020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        doesNotExist: t('policies.cvd.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.cvd.doesNotExistTooltip.maker'),
            searcher: (React.createElement(Typography, null,
                "This is an early action sign: organizations that have an established Coordinated Vulnerability Disclosure (CVD) policy may have a more robust security posture, which may make procurement easier for your team. To learn more about why we think CVDs are important,",
                ' ',
                React.createElement(Link, { href: 'https://www.nature.com/articles/s41746-020-0237-3' }, "read this Nature article"),
                ' ',
                "that we co-authored in 2020.")),
        },
        datedTooltip: {
            searcher: t('policies.cvd.datedTooltip.searcher'),
        },
    }),
    HIPAA: buildToolPolicyAttribute({
        policyClass: 'legalPolicies',
        policyType: 'HIPAA',
        label: t('policies.hipaa.label'),
        exists: t('policies.hipaa.label'),
        existsTooltip: {
            searcher: t('policies.hipaa.tooltip'),
        },
        unavailable: t('policies.hipaa.label'),
        unavailableTooltip: {
            maker: t('policies.hipaa.tooltip'),
            searcher: t('policies.hipaa.tooltip'),
        },
        doesNotExist: t('policies.hipaa.label'),
        doesNotExistTooltip: {
            maker: t('policies.hipaa.tooltip'),
            searcher: t('policies.hipaa.tooltip'),
        },
        datedTooltip: {
            searcher: t('policies.hipaa.datedTooltip.searcher'),
        },
    }),
    GDPR: buildToolPolicyAttribute({
        policyClass: 'legalPolicies',
        policyType: 'GDPR',
        label: t('policies.gdpr.label'),
        exists: t('policies.gdpr.label'),
        existsTooltip: {
            searcher: t('policies.gdpr.tooltip'),
        },
        unavailable: t('policies.gdpr.label'),
        unavailableTooltip: {
            maker: t('policies.gdpr.tooltip'),
            searcher: t('policies.gdpr.tooltip'),
        },
        doesNotExist: t('policies.gdpr.label'),
        doesNotExistTooltip: {
            maker: t('policies.gdpr.tooltip'),
            searcher: t('policies.gdpr.tooltip'),
        },
        datedTooltip: {
            searcher: t('policies.gdpr.datedTooltip.searcher'),
        },
    }),
    PublishedUpdates: buildToolPolicyAttribute({
        policyClass: 'securityPolicies',
        policyType: 'Published Software Updates',
        label: t('policies.softwareUpdates.label'),
        exists: t('policies.softwareUpdates.exists'),
        unavailable: t('policies.softwareUpdates.unavailable'),
        unavailableTooltip: {
            maker: t('policies.softwareUpdates.unavailableTooltip.maker'),
            searcher: t('policies.softwareUpdates.unavailableTooltip.searcher'),
        },
        doesNotExist: t('policies.softwareUpdates.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.softwareUpdates.doesNotExistTooltip.maker'),
            searcher: t('policies.softwareUpdates.doesNotExistTooltip.searcher'),
        },
        datedTooltip: {
            searcher: t('policies.softwareUpdates.datedTooltip.searcher'),
        },
    }),
    PrivacyPolicy: buildToolPolicyAttribute({
        policyClass: 'legalPolicies',
        policyType: 'Privacy Policy',
        label: t('policies.privacy.label'),
        existsTooltip: {
            maker: t('policies.privacy.existsTooltip.maker'),
            searcher: t('policies.privacy.existsTooltip.searcher'),
        },
        doesNotExist: t('policies.privacy.doesNotExist'),
        doesNotExistTooltip: {
            maker: t('policies.privacy.doesNotExistTooltip.maker'),
            searcher: t('policies.privacy.doesNotExistTooltip.searcher'),
        },
        unavailable: t('policies.privacy.unavailable'),
        unavailableTooltip: {
            maker: t('policies.privacy.unavailableTooltip.maker'),
            searcher: t('policies.privacy.unavailableTooltip.searcher'),
        },
        datedTooltip: {
            searcher: t('policies.privacy.datedTooltip.searcher'),
        },
        notDatedTooltip: {
            searcher: t('policies.privacy.notDatedTooltip.searcher'),
        },
    }),
    ProductPrivacyPolicy: buildToolPolicyAttribute({
        policyClass: 'legalPolicies',
        policyType: 'Privacy policy - Product',
        label: t('policies.productPrivacy.label'),
        exists: t('policies.productPrivacy.exists'),
        unavailable: t('policies.productPrivacy.unavailable'),
        doesNotExist: t('policies.productPrivacy.doesNotExist'),
    }),
};
const getAttributesForComponentType = (tool) => {
    if (tool.componentType === 'Smartphone/Tablet') {
        return [
            [ToolAttribute.OperatingSystem, Icons.Product.OperatingSystem],
            [ToolAttribute.BatteryCapacity, Icons.Product.Battery],
            [ToolAttribute.Waterproof, Icons.Product.Waterproof],
            [ToolAttribute.Connectivity, Icons.Product.Connectivity],
            [ToolAttribute.InternalMemory, Icons.Product.Memory],
            [ToolAttribute.SensorType, Icons.Product.Sensors],
            [ToolAttribute.PhonePorts, Icons.Product.Port],
            [ToolAttribute.PhoneCamera, Icons.Product.Camera],
            [ToolAttribute.Manual, Icons.Product.Manual],
        ];
    }
    if (tool.componentType === 'Software-Based Component') {
        return [
            [ToolAttribute.RequiredHardwareSBC, Icons.Product.FormFactor],
            [ToolAttribute.RequiredSensorsSBC, Icons.Product.Sensors],
            [ToolAttribute.DataCollection, Icons.Product.Security],
            [ToolAttribute.HasApi, Icons.Product.OperatingSystem],
            [ToolAttribute.HasSDK, Icons.Product.Api],
            [ToolAttribute.AccessToPreprocessedData, Icons.Product.Data],
            [ToolAttribute.Manual, Icons.Product.Manual],
        ];
    }
    if (isCoa(tool)) {
        return [
            // We don't have dedicated icons for these attributes, so we've chosen the
            // best substitutes.
            [ToolAttribute.QuestionCount, Icons.Product.OperatingSystem],
            [ToolAttribute.QuestionType, Icons.Product.Sensors],
            [ToolAttribute.RecallPeriod, Icons.Product.Memory],
            [ToolAttribute.TestTime, Icons.Misc.NotificationsBell],
            [ToolAttribute.YearPublished, Icons.Product.Manual],
            [ToolAttribute.DataCollection, Icons.Product.Security],
            [ToolAttribute.AdministrationMethod, Icons.Product.Notes],
            [ToolAttribute.RequiredHardwareSBC, Icons.Product.FormFactor],
            [ToolAttribute.LanguageSupport, Icons.Product.WearLocation],
            [ToolAttribute.ScoringMethodology, Icons.Product.Software],
            [ToolAttribute.OtherUsefulLinks, Icons.Product.Notes],
        ];
    }
    return [
        [ToolAttribute.SensorType, Icons.Product.Sensors],
        [ToolAttribute.FormFactor, Icons.Product.FormFactor],
        [ToolAttribute.WearLocation, Icons.Product.WearLocation],
        [ToolAttribute.BatteryCombined, Icons.Product.Battery],
        [ToolAttribute.Waterproof, Icons.Product.Waterproof],
        [ToolAttribute.Connectivity, Icons.Product.Connectivity],
        [ToolAttribute.OperatingSystem, Icons.Product.OperatingSystem],
        [ToolAttribute.Manual, Icons.Product.Manual],
        [ToolAttribute.SpecSheet, Icons.Product.Manual],
        [ToolAttribute.Software, Icons.Product.Software],
        [ToolAttribute.HasApi, Icons.Product.OperatingSystem],
        [ToolAttribute.HasSDK, Icons.Product.Api],
        [ToolAttribute.AccessToPreprocessedData, Icons.Product.Data],
    ];
};
const PRICE_AVAILABILITY_ATTRIBUTES = [
    ToolAttribute.Availability,
    ToolAttribute.Distribution,
    ToolAttribute.Price,
];
const DATA_RIGHTS_SECURITY_ATTRIBUTES = [
    ToolAttribute.ProductPrivacyPolicy,
    ToolAttribute.PrivacyPolicy,
    ToolAttribute.CVD,
    ToolAttribute.PublishedUpdates,
];
const EVIDENCE_ATTRIBUTES = [
    ToolAttribute.Evidence,
    ToolAttribute.ValidationTotal,
    ToolAttribute.V3Verification,
    ToolAttribute.V3Analytical,
    ToolAttribute.V3Clinical,
    ToolAttribute.CTPhases,
];
const EVIDENCE_REGULATORY_ATTRIBUTES = [
    ToolAttribute.FDAClassification,
    ToolAttribute.FDAStatus,
    ToolAttribute.CEMark,
];
export { ToolAttribute, getCombinedBattery, getAttributesForComponentType, PRICE_AVAILABILITY_ATTRIBUTES, DATA_RIGHTS_SECURITY_ATTRIBUTES, EVIDENCE_ATTRIBUTES, EVIDENCE_REGULATORY_ATTRIBUTES, };
