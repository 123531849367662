import { __awaiter } from "tslib";
import { redactSensitiveUrl } from '../utils';
// THis is hard-coded for now, we should eventually pull in from environment variables
const LOGROCKET_CDN_URL = 'https://lr-cdn.gohumanfirst.com/logger.min.js';
const LOGROCKET_INGEST_URL = 'https://lr-ingest.gohumanfirst.com/i';
const LOGROCKET_HEALTH_URL = 'https://lr-ingest.gohumanfirst.com/';
/** Whether LogRocket has ever been initialized. */
const isInitialized = () => !!window.logRocketInitialized;
/** Sets the initialized state. */
const setInitialized = (value) => {
    ;
    window.logRocketInitialized = value;
};
const getLogRocket = () => {
    ;
    window._lrAsyncScript = LOGROCKET_CDN_URL;
    return require('logrocket');
};
const LogRocketAnalyticsService = {
    name: 'logrocket',
    /** @override */
    isInitialized,
    /** @override */
    initialize() {
        const appId = process.env.LOGROCKET_APP_ID;
        const release = process.env.REACT_APP_VERSION;
        if (!appId) {
            return;
        }
        getLogRocket().init(appId, {
            release,
            browser: {
                urlSanitizer: redactSensitiveUrl,
            },
            network: {
                requestSanitizer: (request) => {
                    request.url = redactSensitiveUrl(request.url);
                    for (const r in request.headers) {
                        if (r.toLowerCase() === 'authorization' ||
                            r.toLowerCase() === 'x-algolia-api-key') {
                            request.headers[r] = '<<redacted>>';
                        }
                    }
                    request.body = null;
                    return request;
                },
                responseSanitizer: () => {
                    return null;
                },
            },
            serverURL: LOGROCKET_INGEST_URL,
        });
        setInitialized(true);
    },
    /** @override */
    isConnectable() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield fetch(LOGROCKET_HEALTH_URL, {
                    method: 'HEAD',
                    mode: 'no-cors',
                    cache: 'no-store',
                });
                return true;
            }
            catch (e) {
                return false;
            }
        });
    },
    /** @override */
    identify(userData) {
        if (isInitialized() && userData) {
            getLogRocket().identify(userData.email, userData);
        }
    },
    track(action, properties) {
        if (isInitialized()) {
            getLogRocket().track(action, properties);
        }
    },
    pathChange() {
        // Do nothing
    },
    getSessionIdentifier() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield new Promise((resolve) => {
                return getLogRocket().getSessionURL(resolve);
            });
        });
    },
};
export { LogRocketAnalyticsService };
