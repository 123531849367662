import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
/**
 * Formats a date as the Atlas default (Month Name Day, Year).
 */
const formatDate = (date) => {
    return date ? dayjs(date).format('MMMM D, YYYY') : '---';
};
const formatDateFromNow = (date) => {
    return dayjs(date).fromNow();
};
export { formatDate, formatDateFromNow };
