import React from 'react';
import { PageLayout } from 'src/components/PageLayout';
import { RequiresAuthentication } from 'src/components/RequiresAuthentication';
import { toRouteObject } from 'src/utils/route';
import { LockedApp } from 'src/components/LockedApp';
import { Logout } from 'src/pages/Logout';
import { AtlasRedirects, AtlasRoutes } from './atlas';
import { AuthenticationRoutes } from './authentication';
import { CommonRoutes } from './common';
import { MakerRoutes } from './maker';
import { ProjectRoutes } from './projects';
// Lazy loading for page access that are less common.
const AdminRoutes = React.lazy(() => /* webpackChunkName: "admin" */ import('./admin'));
const routes = {
    children: {
        authenitication: AuthenticationRoutes,
        rest: {
            element: (React.createElement(RequiresAuthentication, null,
                React.createElement(PageLayout, null))),
            children: {
                admin: {
                    path: 'admin/*',
                    element: React.createElement(AdminRoutes, null),
                },
                projects: ProjectRoutes,
                maker: MakerRoutes,
                atlas: AtlasRoutes,
                common: CommonRoutes,
            },
        },
        // We include the common pages, but some of them should redirect to the equivalent
        // atlas pages.
        atlasRedirects: AtlasRedirects,
    },
};
const lockedAppRoutes = {
    children: {
        upgrade: {
            path: '*',
            element: (React.createElement(RequiresAuthentication, null,
                React.createElement(LockedApp, null))),
        },
        logout: {
            path: 'logout',
            element: React.createElement(Logout, null),
        },
    },
};
const ROUTES = toRouteObject(routes);
const LOCKED_APP_ROUTES = toRouteObject(lockedAppRoutes);
export { ROUTES, LOCKED_APP_ROUTES };
