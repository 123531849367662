import { Block, CardSurface, COLORS, Icons, Link, Stack, } from '@humanfirst/elektron';
import React, { useCallback, useEffect } from 'react';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { useIntercom } from 'src/contexts/IntercomContext';
import { useIsModalOpen } from 'src/hooks/modal';
export const HelpMenu = ({ close }) => {
    const { showNewMessage } = useIntercom();
    const isModalOpen = useIsModalOpen();
    const onClickIntercom = useCallback(() => {
        close();
        showNewMessage();
    }, [close, showNewMessage]);
    // Close this popover whenever a modal is open
    // TODO: Should this move to a higher abstraction?
    useEffect(() => {
        if (isModalOpen) {
            close();
        }
    }, [isModalOpen, close]);
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { margin: "20px", width: "200px" },
            React.createElement(Stack, null,
                React.createElement(Stack, null,
                    React.createElement(Block, null,
                        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center', gap: '0px' },
                            React.createElement(Icons.Misc.Mail, { color: COLORS.primary, size: "1.5em", decorative: true }),
                            React.createElement(ContactUsFormTrigger, { location: `Top Nav - Contact Us`, buttonVariant: 'minimal' },
                                React.createElement("span", null, "Send us a message")))),
                    React.createElement(Link, { href: "", onClick: (e) => {
                            e.preventDefault();
                            onClickIntercom();
                        } },
                        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                            React.createElement(Icons.Misc.Chat, { size: "1.5em", decorative: true }),
                            React.createElement("span", null, "Chat with us"))))))));
};
