import React from 'react';
import { Block, StatefulTooltip } from '@humanfirst/elektron';
import { MEASURE_TAG_DATA } from './data';
import { MeasureTag } from './MeasureTag';
/** Renders an appropriately colored pip for measure tags. */
const MeasureTagPip = ({ tag }) => {
    const { color, title } = MEASURE_TAG_DATA[tag];
    return (React.createElement(Block, { display: "flex", alignItems: "center", justifyContent: "center", width: "14px", height: "14px" },
        React.createElement(Block, { borderRadius: '50%', width: "8px", height: "8px", backgroundColor: color },
            React.createElement(Block, { display: 'none' }, title))));
};
/** Renders a measure tag pip with a description in a tool tip. */
const MeasureTagPipWithTooltip = ({ tag }) => {
    return (React.createElement(StatefulTooltip, { content: React.createElement(MeasureTag, { tag: tag, includeDescription: true }), triggerType: 'hover', placement: "bottom" },
        React.createElement(MeasureTagPip, { tag: tag })));
};
export { MeasureTagPipWithTooltip, MeasureTagPip };
