import { __rest } from "tslib";
import React from 'react';
import { Block, Breadcrumbs } from '@humanfirst/elektron';
import { BackButton } from '../BackButton';
/**
 * Wrapper for core product content.
 * This wrapper includes both navigation elements (like the back button)
 * and positioning information(min/max width + margins).
 */
const ContentContainer = ({ children, controls, backButton, backgroundColor, breadcrumbs, padding = '56px', }) => {
    return (React.createElement(Block, { display: "inline-block", minWidth: 'max(100%, 992px)' },
        backButton || controls || breadcrumbs ? (React.createElement(Block, { display: "flex", justifyContent: controls && !backButton ? 'flex-end' : 'space-between', padding: "16px 24px 0px 24px" },
            backButton ? React.createElement(BackButton, null) : null,
            breadcrumbs ? React.createElement(Breadcrumbs, { crumbs: breadcrumbs }) : null,
            controls)) : null,
        React.createElement(Block, { padding: padding, backgroundColor: backgroundColor },
            React.createElement(Block, { margin: "auto", maxWidth: "1800px", width: "100%" }, children))));
};
/* This is a wrapper for the header section on pages separated by two content containers */
const HeaderContentContainer = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(Block, null,
        React.createElement(ContentContainer, Object.assign({ backButton: true, padding: "32px 56px 20px 56px" }, props), children)));
};
export { ContentContainer, HeaderContentContainer };
