import React from 'react';
import { ErrorBoundary as BaseErrorBoundary, } from 'react-error-boundary';
import { getRollbarInstance } from 'src/utils/rollbar';
/**
 * Fallback page for the error.
 * Note that since this could be rendered at the root of the application
 * it should make no assumptions about contexts. Because of this we use
 * inline styles instead of elektron styled components.
 */
const ErrorFallback = ({ error, }) => {
    var _a;
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            margin: '16px',
        } },
        React.createElement("div", { style: { maxWidth: '600px' } },
            React.createElement("h1", null, "Error"),
            React.createElement("p", null, "Something went wrong."),
            React.createElement("ul", null,
                React.createElement("li", null, "Try refreshing the page to see if that fixes the issue."),
                React.createElement("li", null, "If possible, try loading the page in the latest version of Chrome, Firefox, or Safari."),
                React.createElement("li", null,
                    "Check the",
                    ' ',
                    React.createElement("a", { href: "https://elektralabs.statuspage.io", target: "_blank", rel: "noopener noreferrer" }, "status of the HumanFirst services.")),
                React.createElement("li", null,
                    "If you're still encountering an issue reach out to our",
                    ' ',
                    React.createElement("a", { href: "mailto:admin@gohumanfirst.com" }, "team"),
                    " for assistance. Please copy the text below to help our team fix your issue.")),
            React.createElement("pre", null, (_a = error === null || error === void 0 ? void 0 : error.stack) !== null && _a !== void 0 ? _a : 'Unknown'))));
};
const logError = (error) => {
    getRollbarInstance().error(error, { reactErrorBoundary: true });
};
const ErrorBoundary = ({ children, }) => (React.createElement(BaseErrorBoundary, { FallbackComponent: ErrorFallback, onError: logError }, children));
export { ErrorBoundary };
