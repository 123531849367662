import { Block, Button, COLORS, Icons, Stack, Typography, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { When } from 'react-if';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
import { Can } from 'src/components/Permissions';
import { PrimaryCard } from 'src/components/ResourceDetails';
import { SubscribeToTool } from 'src/components/Subscribe';
import { ToolImage } from 'src/components/ToolImage';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { generatePath } from 'src/utils/path';
import { MakerEditingContext } from '../contexts/MakerEditingContext';
import { isCoa, isLab, isPartiallyIngested, isPhysicalSensor, isSmartphone as isSmartphoneUtil, isSoftware, } from '../utils/selectors';
const EditProductButton = () => {
    const { isEditing, setIsEditing } = useContext(MakerEditingContext);
    if (isEditing) {
        return (React.createElement(Button, { variant: "tertiary", size: "large", startEnhancer: React.createElement(Icons.Misc.Flag, { size: "16px", decorative: true }) },
            React.createElement(Typography, { color: COLORS.gray2 }, "Flagging Changes")));
    }
    return (React.createElement(Block, null,
        React.createElement(Button, { variant: "tertiary", onClick: () => setIsEditing(true), size: "large", startEnhancer: React.createElement(Icons.Misc.Flag, { size: "16px", decorative: true, color1: COLORS.gray1, color2: 'transparent' }) }, "Edit Product")));
};
/** Shows possible actions for interacting with the technology */
const ActionsSection = ({ tool }) => {
    const hasEditPermissions = useHasPermission('component.updateRequest', 'create', tool);
    return (React.createElement(Stack, { direction: 'horizontal' },
        React.createElement(Block, null,
            React.createElement(AddToWorkspaceButton, { resourceId: tool.id, resourceType: 'Tool', variant: "button" })),
        React.createElement(Can, { resource: 'component.history', action: 'read', extra: tool },
            React.createElement(When, { condition: !isPartiallyIngested(tool) },
                React.createElement(Block, null,
                    React.createElement(SubscribeToTool, { tool: tool })))),
        React.createElement(When, { condition: hasEditPermissions },
            React.createElement(EditProductButton, null))));
};
/** Get tags for a tool */
const getToolTags = (tool) => {
    var _a;
    const tags = [];
    if (isSmartphoneUtil(tool)) {
        tags.push({ tag: 'Smartphone/tablet' });
    }
    if (isSoftware(tool)) {
        tags.push({ tag: 'Software' });
    }
    if (isPhysicalSensor(tool)) {
        tags.push({ tag: 'Physical Sensor' });
    }
    if (isCoa(tool)) {
        const subtype = !!((_a = tool.coaSubtype) === null || _a === void 0 ? void 0 : _a.length)
            ? `(${tool.coaSubtype.join(', ')})`
            : '';
        tags.push({
            tag: `Questionnaire ${subtype}`,
        });
    }
    if (isLab(tool)) {
        tags.push({ tag: 'Laboratory' });
    }
    return tags;
};
const getToolLinks = (tool) => {
    const links = [];
    if (tool.productWebsite) {
        links.push({
            text: 'Product Website',
            href: tool.productWebsite,
        });
    }
    return links;
};
const ToolPrimaryCard = ({ tool }) => {
    var _a;
    const parent = ((_a = tool.maker) === null || _a === void 0 ? void 0 : _a.id)
        ? {
            text: tool.maker.name,
            href: generatePath(PATHS.atlas.vendors.detail, { id: tool.maker.id }),
        }
        : undefined;
    return (React.createElement(PrimaryCard, { title: tool.model, parentResource: parent, secondaryParent: tool.authors, description: tool.productDescription, image: React.createElement(ToolImage, { tool: tool, imageSize: 208 }), tags: getToolTags(tool), actions: React.createElement(ActionsSection, { tool: tool }), links: getToolLinks(tool) }));
};
export { ToolPrimaryCard as PrimaryCard };
