import { __awaiter } from "tslib";
import React, { useCallback } from 'react';
import { toaster } from '@humanfirst/elektron';
import { useIsSubscribed } from 'src/hooks/subscribe';
import { SubscribeButton } from './SubscribeButton';
const SubscribeToVendor = ({ vendor }) => {
    const { subscribe, unsubscribe, isSubscribed, isLoading } = useIsSubscribed('Vendor', vendor.id);
    const onSubscribe = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield subscribe();
        toaster.info(`Subscribed to updates for ${vendor.name}`, {});
    }), [subscribe, vendor.name]);
    const onUnsubscribe = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield unsubscribe();
        toaster.info(`Unsubscribed to updates for ${vendor.name}`, {});
    }), [vendor.name, unsubscribe]);
    return (React.createElement(SubscribeButton, { onSubscribe: onSubscribe, onUnsubscribe: onUnsubscribe, isLoading: isLoading, isSubscribed: isSubscribed }));
};
export { SubscribeToVendor };
