import { Block, Card, COLORS, Icons, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
const HelpCenterCardLink = ({ href, title, readTime }) => {
    return (React.createElement("a", { href: href, target: "_blank", rel: "noreferrer", style: { textDecoration: 'none' } },
        React.createElement(Card, { backgroundColor: COLORS.blue4, variant: 'tertiary' },
            React.createElement(Spread, { alignItems: 'start' },
                React.createElement(Stack, { direction: "horizontal", width: '320px' },
                    React.createElement(Icons.Misc.Help, { decorative: true, color: COLORS.primary, size: '24px' }),
                    React.createElement(Block, null,
                        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, title),
                        React.createElement(When, { condition: readTime },
                            React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, readTime)))),
                React.createElement(Icons.Misc.LinkArrow, { decorative: true, color: COLORS.primary })))));
};
export { HelpCenterCardLink };
