import React from 'react';
import { Typography, TypographyVariant, Block, COLORS, Card, Divider, Spread, Heading2, Link, Spacer, Stack, } from '@humanfirst/elektron';
import { EvidenceResource, SourceMeasureResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { Else, If, Then } from 'react-if';
import { uniq } from 'ramda';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { getMeasurementCategory } from 'src/utils/measurements';
import { PATHS } from 'src/config/path';
import { LimitedList } from 'src/components/LimitedList/LimitedList';
import { generatePath } from 'src/utils/path';
/** Wrapper for research item to interact with query wrapper. */
const WrappedResearchItem = ({ data }) => data.length ? React.createElement(ResearchItem, { evidence: data[0] }) : null;
/** Shows a research item based on a specific scope. */
const ScopedResearchItem = ({ scope, }) => {
    const result = useElektraApi(EvidenceResource.list({
        join: ['sourceMeasures', 'sourceMeasures.tools', 'medicalConditions'],
        limit: '1',
        sort: [['createdAt', 'DESC']],
        filter: [
            ['year', '$gt', '2022'],
            ['ingestionStatus', '$eq', 'Display on Website'],
            ...scope,
        ],
    }));
    return React.createElement(QueryWrapper, Object.assign({ renderSuccess: WrappedResearchItem }, result));
};
const RecentResearch = () => {
    return (React.createElement(Block, null,
        React.createElement(Card, { fill: true, padding: '48px' },
            React.createElement(Block, null,
                React.createElement(Spread, null,
                    React.createElement(Heading2, null, "New Research in Atlas")),
                React.createElement(ScopedResearchItem, { scope: [['typeOfSource', '$eq', 'Paper']] }),
                React.createElement(Spacer, { size: '24px' }),
                React.createElement(Divider, null),
                React.createElement(Spacer, { size: '24px' }),
                React.createElement(ScopedResearchItem, { scope: [['typeOfSource', '$eq', 'ClinicalTrials.gov']] })))));
};
const ResearchItem = ({ evidence }) => {
    var _a, _b;
    if (!evidence) {
        return null;
    }
    const evidenceHref = generatePath(PATHS.atlas.evidence.detail, {
        id: evidence.id,
    });
    const category = ((_a = evidence.typeOfSource) === null || _a === void 0 ? void 0 : _a[0]) === 'Paper' ? 'Paper' : 'Clinical Trials';
    return (React.createElement(Block, null,
        React.createElement(Spread, { marginBottom: '8px' },
            React.createElement(Typography, { textTransform: 'uppercase', variant: TypographyVariant.DetailsBold }, category)),
        React.createElement(Link, { href: evidenceHref }, evidence.title),
        React.createElement(Spread, { marginTop: "24px", gap: "16px", alignItems: 'flex-start' },
            React.createElement(Stack, null,
                React.createElement(Block, null,
                    React.createElement(If, { condition: category === 'Paper' },
                        React.createElement(Then, null,
                            React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Source"),
                            React.createElement(Typography, { variant: TypographyVariant.Details }, evidence.journal)),
                        React.createElement(Else, null,
                            React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Sponsor"),
                            React.createElement(Typography, { variant: TypographyVariant.Details }, (_b = evidence.ctSponsor) !== null && _b !== void 0 ? _b : 'N/A')))),
                React.createElement(Block, null,
                    React.createElement(Spacer, { size: '24px' }),
                    React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Medical Conditions"),
                    React.createElement(DisplayMedicalConditions, { evidence: evidence }))),
            React.createElement(Block, { width: '50%' },
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Measures"),
                React.createElement(EvidenceMeasures, { item: evidence })))));
};
const DisplayMedicalConditions = ({ evidence }) => {
    var _a;
    if (evidence.medicalConditions && evidence.medicalConditions.length < 1) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "None Listed Yet"));
    }
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = evidence.medicalConditions) === null || _a === void 0 ? void 0 : _a.map((x) => x.name).join(', ')));
};
const EvidenceMeasures = ({ item }) => {
    var _a, _b, _c;
    const sourceMeasureIds = (_b = (_a = item.sourceMeasures) === null || _a === void 0 ? void 0 : _a.map((x) => x.id)) !== null && _b !== void 0 ? _b : [];
    const { data: sourceMeasures } = useElektraApi(SourceMeasureResource.getBulk(sourceMeasureIds, {
        join: ['measures', 'measures.category'],
    }));
    const categories = uniq((_c = sourceMeasures === null || sourceMeasures === void 0 ? void 0 : sourceMeasures.flatMap((x) => { var _a; return (_a = x.measures) === null || _a === void 0 ? void 0 : _a.map((y) => { var _a; return (_a = y.category) === null || _a === void 0 ? void 0 : _a.category; }).filter((z) => !!z); })) !== null && _c !== void 0 ? _c : []).sort();
    const measureNames = categories.flatMap((x) => getMeasurementCategory(x)) || [];
    if (!categories.length) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "None Listed Yet"));
    }
    return (React.createElement(Block, null,
        React.createElement(LimitedList, { elements: categories.map((m, i) => (React.createElement(Block, { key: `${m}`, paddingLeft: '12px' },
                React.createElement(Link, { typographyVariant: TypographyVariant.Details, href: generatePath(PATHS.atlas.measuresName, {
                        name: encodeURIComponent(m),
                    }) },
                    React.createElement("li", null, measureNames[i]))))), limit: 2 })));
};
export { RecentResearch };
