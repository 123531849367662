import { Stack, Typography, TypographyVariant, Block, Spread, COLORS, Link, Spacer, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { MeasureCategoryLink } from 'src/components/Measures/MeasureCategoryLink';
import { ToolSummary } from 'src/components/ToolSummary';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { generatePath } from 'src/utils/path';
import { LoadingState } from '../LoadingState';
/** Render tech and the associated measures. */
export const TechAndMeasures = ({ measures, tool, hasMeasures, isPartialIngestion }) => {
    var _a;
    const { data: fullTool, isInitialLoading } = useElektraApi(ToolResource.get((_a = tool === null || tool === void 0 ? void 0 : tool.id) !== null && _a !== void 0 ? _a : '', { join: ['maker'] }), {
        enabled: !!(tool === null || tool === void 0 ? void 0 : tool.id),
    });
    if (isInitialLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(Spread, { gap: '8px' },
        React.createElement(Block, { width: "5%" }),
        React.createElement(Block, { flex: "1" },
            React.createElement(When, { condition: !fullTool },
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, t('evidenceTable.unspecifiedTechnology'))),
            React.createElement(When, { condition: !!fullTool },
                React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                        id: tool === null || tool === void 0 ? void 0 : tool.id,
                    }) },
                    React.createElement(ToolSummary, { tool: fullTool })),
                React.createElement(Spacer, null))),
        React.createElement(When, { condition: hasMeasures },
            React.createElement(Block, { flex: "1" },
                React.createElement(If, { condition: !!(measures === null || measures === void 0 ? void 0 : measures.length) },
                    React.createElement(Then, null,
                        React.createElement(Stack, null,
                            React.createElement("ul", { style: { paddingInlineStart: '10px' } }, measures === null || measures === void 0 ? void 0 : measures.map((y) => (React.createElement("li", { key: y },
                                React.createElement(MeasureCategoryLink, { measure: y }))))))),
                    React.createElement(Else, null,
                        React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "None Listed"))))),
        React.createElement(When, { condition: !hasMeasures && isPartialIngestion },
            React.createElement(Block, { width: '180px' },
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "N/A - Awaiting Full Ingestion")))));
};
