import { Stack } from '@humanfirst/elektron';
import React from 'react';
import { Can } from 'src/components/Permissions';
import { SubscribeToVendor } from 'src/components/Subscribe';
import { VendorLogo } from 'src/components/VendorLogo';
import { getVendorTypes } from 'src/utils/vendorType';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
import { PrimaryCard } from 'src/components/ResourceDetails';
const OrganizationActions = ({ organization, }) => {
    return (React.createElement(Stack, { direction: 'horizontal' },
        React.createElement(AddToWorkspaceButton, { resourceId: organization.id, resourceType: 'Vendor', variant: "button" }),
        React.createElement(Can, { resource: 'vendor.history', action: 'read' },
            React.createElement(SubscribeToVendor, { vendor: organization }))));
};
const getOrganizationLinks = (organization) => {
    const links = [];
    if (organization.url) {
        links.push({
            text: 'Website',
            href: organization.url,
        });
    }
    if (organization.linkedIn) {
        links.push({
            text: 'LinkedIn',
            href: organization.linkedIn,
        });
    }
    if (organization.clinicalRoadmap) {
        links.push({
            text: 'Clinical Roadmap',
            href: organization.clinicalRoadmap,
        });
    }
    return links;
};
const OrganizationPrimaryCard = ({ organization, }) => {
    const vendorTypes = getVendorTypes(organization);
    const tags = vendorTypes.map((x) => ({ tag: x }));
    return (React.createElement(PrimaryCard, { image: React.createElement(VendorLogo, { vendor: organization, imageSize: '208px' }), title: organization.name, description: organization.description, secondaryFact: organization.yearFounded
            ? `Founded ${organization.yearFounded}`
            : undefined, tags: tags, actions: React.createElement(OrganizationActions, { organization: organization }), links: getOrganizationLinks(organization) }));
};
export { OrganizationPrimaryCard as PrimaryCard };
