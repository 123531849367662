import React from 'react';
import { COLORS, Icons, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useFormContext } from 'react-hook-form';
const SmartFormErrors = () => {
    const { formState } = useFormContext();
    if (!formState.errors.root) {
        return null;
    }
    return (React.createElement(Stack, { gap: "4px", direction: "horizontal", alignItems: 'center' }, Object.values(formState.errors.root).map((error) => {
        if (typeof error === 'number') {
            return null;
        }
        const message = typeof error === 'string' ? error : error.message;
        const type = typeof error === 'string' ? error : error.type;
        return (React.createElement(React.Fragment, { key: type },
            React.createElement(Icons.Misc.Error, { color: COLORS.alert, size: "1em", decorative: true }),
            React.createElement(Typography, { color: COLORS.alert, variant: TypographyVariant.Body }, message)));
    })));
};
export { SmartFormErrors };
