import { FilterableFieldType, NumericRangeFilter, } from '@humanfirst/elektron';
import { useMemo } from 'react';
import { TRANSLATE_TO_ROMAN_NUM } from 'src/utils/clinicalTrials';
import { getCategories } from 'src/utils/filters';
import { getMeasurementHierarchy } from 'src/utils/measurements';
import { flattenTree } from 'src/utils/tree';
import { AlgoliaCategoricalFilter } from '../shared/algolia-filter-helpers';
import { getToolModels } from './utils';
const CURRENT_YEAR = new Date().getFullYear();
const getYearLabelForRange = (range) => {
    if (range[0] === CURRENT_YEAR - 1) {
        return 'In the last 1 year';
    }
    else if (range[0] === CURRENT_YEAR - 5) {
        return 'In the last 5 years';
    }
    else if (range[0] === CURRENT_YEAR - 10) {
        return 'In the last 10 years';
    }
    else {
        return 'Unknown';
    }
};
/** Builds a filter specifically for tool models. */
const MODEL_FILTER = AlgoliaCategoricalFilter('tools', {
    label: 'Model',
    getValue: getToolModels,
    visualFacetTransform: (x) => x.split('||')[0],
    getValueLabel: (x) => String(x).split('||')[0],
    metadata: { group: 2, example: 'e.g., GT3X' },
});
const MODEL_ID_FILTER = AlgoliaCategoricalFilter('toolIds', {
    label: 'Model IDs',
    getValue: getToolModels,
    metadata: { priority: 'hidden' },
});
/** Filter for concept of interest (only works with Algolia). */
const CONCEPT_OF_INTEREST_FILTER = AlgoliaCategoricalFilter('conceptsOfInterest', {
    label: 'Concept of Interest',
    metadata: { group: 1 },
});
/**
 * Builds a set of filters for an evidence table.
 */
const buildEvidenceFilters = () => {
    return [
        AlgoliaCategoricalFilter('therapeuticAreas', {
            label: 'Therapeutic Area',
            getValue: (item) => { var _a, _b; return (_b = (_a = item.therapeuticArea) === null || _a === void 0 ? void 0 : _a.flatMap((y) => y.therapeuticArea)) !== null && _b !== void 0 ? _b : []; },
            metadata: { group: 1, priority: 'priority', example: 'e.g., Neurology' },
        }),
        AlgoliaCategoricalFilter('primaryMedicalConditions', {
            label: 'Primary Medical Condition',
            getValue: (item) => { var _a, _b; return (_b = (_a = item.primaryMedicalConditions) === null || _a === void 0 ? void 0 : _a.flatMap((y) => y.name)) !== null && _b !== void 0 ? _b : []; },
            metadata: {
                group: 1,
                priority: 'priority',
                example: "e.g., Parkinson's Disease",
            },
        }),
        AlgoliaCategoricalFilter('typeOfSource', {
            label: 'Evidence Type',
            getValue: (item) => { var _a; return (_a = item.typeOfSource) !== null && _a !== void 0 ? _a : []; },
            metadata: { group: 3, priority: 'priority', example: 'e.g., paper' },
        }),
        AlgoliaCategoricalFilter('dhtUseContext', {
            label: 'DHT Use Context',
            getValue: (item) => { var _a; return (_a = item.dhtUseContext) !== null && _a !== void 0 ? _a : []; },
            metadata: {
                priority: 'priority',
                group: 2,
                example: 'e.g., validation, U&U',
            },
        }),
        AlgoliaCategoricalFilter('v3', {
            label: 'V3 Type',
            getValue: (item) => { var _a; return (_a = item.v3) !== null && _a !== void 0 ? _a : []; },
            metadata: {
                priority: 'priority',
                group: 2,
                example: 'e.g., analytical validation',
            },
        }),
        AlgoliaCategoricalFilter('medicalConditions', {
            label: 'Concomitant Medical Condition',
            getValue: (item) => { var _a, _b; return (_b = (_a = item.medicalConditions) === null || _a === void 0 ? void 0 : _a.flatMap((y) => y.name)) !== null && _b !== void 0 ? _b : []; },
            metadata: { group: 1, example: "e.g., Alzheimer's Disease" },
        }),
        AlgoliaCategoricalFilter('allMeasuresFlat', {
            label: 'Measure',
            getValue: (item) => {
                var _a, _b;
                return (_b = getMeasurementHierarchy((_a = item.measureCategory) !== null && _a !== void 0 ? _a : []).flatMap(flattenTree)) !== null && _b !== void 0 ? _b : [];
            },
            metadata: { group: 1, example: 'e.g., sleep, activity' },
        }),
        AlgoliaCategoricalFilter('studyDesign', {
            label: 'Study Design',
            getValue: (item) => { var _a; return (_a = item.studyDesign) !== null && _a !== void 0 ? _a : []; },
            metadata: { group: 3, example: 'e.g., observational, longitudinal' },
        }),
        NumericRangeFilter('sampleSize', {
            label: 'Sample Size',
            categories: getCategories([
                [5, Infinity],
                [25, Infinity],
                [100, Infinity],
                [1000, Infinity],
            ], FilterableFieldType.NUMERIC_RANGE),
            getValue: (item) => { var _a; return (_a = item.sampleSize) !== null && _a !== void 0 ? _a : 0; },
            metadata: { group: 3, example: 'e.g., >5' },
        }),
        NumericRangeFilter('year', {
            label: 'Publication Year',
            categories: [
                {
                    value: [CURRENT_YEAR - 1, Infinity],
                    label: getYearLabelForRange([CURRENT_YEAR - 1, Infinity]),
                },
                {
                    value: [CURRENT_YEAR - 5, Infinity],
                    label: getYearLabelForRange([CURRENT_YEAR - 5, Infinity]),
                },
                {
                    value: [CURRENT_YEAR - 10, Infinity],
                    label: getYearLabelForRange([CURRENT_YEAR - 10, Infinity]),
                },
            ],
            getValueLabel: (x) => getYearLabelForRange(x),
            metadata: { group: 3, example: 'e.g., in the last five years' },
        }),
        AlgoliaCategoricalFilter('participantAge', {
            label: 'Age',
            getValue: (item) => { var _a; return (_a = item.ageRecruitmentCriteria) !== null && _a !== void 0 ? _a : []; },
            metadata: { group: 1, example: 'e.g., adults, children' },
        }),
        AlgoliaCategoricalFilter('ctSponsorType', {
            label: 'Sponsor Type (CT Only)',
            getValue: (item) => { var _a; return (_a = item.ctSponsorType) !== null && _a !== void 0 ? _a : ''; },
            metadata: { group: 4, example: 'e.g., educational institution' },
        }),
        AlgoliaCategoricalFilter('ctSponsor', {
            label: 'Sponsor (CT Only)',
            getValue: (item) => { var _a; return (_a = item.ctSponsor) !== null && _a !== void 0 ? _a : ''; },
            metadata: { group: 4, example: 'e.g., Mayo Clinic' },
        }),
        AlgoliaCategoricalFilter('ctPhases', {
            label: 'Phase (CT Only)',
            getValue: (item) => item.ctPhase && TRANSLATE_TO_ROMAN_NUM.hasOwnProperty(item.ctPhase)
                ? TRANSLATE_TO_ROMAN_NUM[item.ctPhase]
                : [],
            metadata: { group: 4, example: 'e.g., Phase I' },
        }),
    ];
};
const useEvidenceFilters = (hasTechnology, hasConceptOfInterest) => {
    return useMemo(() => {
        const baseFilters = buildEvidenceFilters();
        if (hasTechnology) {
            baseFilters.push(MODEL_FILTER, MODEL_ID_FILTER);
        }
        if (hasConceptOfInterest) {
            baseFilters.push(CONCEPT_OF_INTEREST_FILTER);
        }
        return baseFilters;
    }, [hasTechnology, hasConceptOfInterest]);
};
export { buildEvidenceFilters, useEvidenceFilters };
