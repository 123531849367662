/** Utility functions for interacting with trees. */
import { mapTree } from '@humanfirst/elektron';
var TreePathMatch;
(function (TreePathMatch) {
    TreePathMatch[TreePathMatch["NONE"] = 0] = "NONE";
    TreePathMatch[TreePathMatch["PARTIAL"] = 1] = "PARTIAL";
    TreePathMatch[TreePathMatch["FULL"] = 2] = "FULL";
})(TreePathMatch || (TreePathMatch = {}));
/**
 * Generates a list of all paths through a given tree. The paths
 * are returned in Pre-order
 */
function getAllPaths(tree, prefix = []) {
    const currentPath = prefix.concat([tree.value]);
    let paths = [currentPath];
    for (const child of tree.children) {
        paths = paths.concat(getAllPaths(child, currentPath));
    }
    return paths;
}
/**
 * Similar to getAllPaths, but does not return intermediate paths
 * @param tree
 * @param prefix
 */
function getAllLeafPaths(tree) {
    if (tree.children.length === 0) {
        return [[tree.value]];
    }
    let paths = [];
    for (const child of tree.children) {
        const childPaths = getAllLeafPaths(child);
        paths = paths.concat(childPaths.map((x) => [tree.value, ...x]));
    }
    return paths;
}
/**
 * Checks whether a path can be applied to a tree. There are three possible results.
 *
 * - FULL: The path can be entirely applied to the tree.
 * - PARTIAL: The path can be partially applied to the tree, but the tree ends too
 *   early. For example if our tree is Foo -> Bar and our path is Foo -> Bar -> Baz.
 *   We would get a partial.
 * - NONE: Neither of the above are true.
 */
function hasPathMatch(tree, path) {
    if (path.length === 0) {
        return TreePathMatch.FULL;
    }
    if (tree.value !== path[0]) {
        return TreePathMatch.NONE;
    }
    if (path.length === 1) {
        // We just had to match the root, so we're done.
        return TreePathMatch.FULL;
    }
    for (const child of tree.children) {
        if (child.value === path[1]) {
            return hasPathMatch(child, path.slice(1));
        }
    }
    return TreePathMatch.PARTIAL;
}
/** Returns true if the predicate matches somewhere in the tree.  */
function someTree(func, tree) {
    return func(tree.value) || tree.children.some((c) => someTree(func, c));
}
/** Returns the total number of leaves/tags in a tree.  */
const countLeaves = (trees) => {
    return trees.reduce((carry, tree) => carry + getAllPaths(tree).length, 0);
};
/** Returns the total number of nodes in a tree.  */
const countNodes = (tree) => {
    return 1 + tree.children.reduce((c, c1) => c + countNodes(c1), 0);
};
/** Flattens a tree into a list. Order is not guaranteed. */
function flattenTree(tree) {
    return [tree.value].concat(...tree.children.map(flattenTree));
}
/**
 * Produces a tree that is the intersection of the given trees.
 */
function intersectTrees(trees) {
    if (trees.length === 0 || trees.some((x) => x.value !== trees[0].value)) {
        // There is no intersection between thse trees or this is an empty list.
        return null;
    }
    // Now that we've determined all of the roots have an intersection we, we need to figure
    // out which of the children should be included. We'll use the first tree as a reference.
    // and find all of the child node that have the same value
    const children = trees[0].children
        .map((child) => {
        const allChildren = trees.map((x) => x.children.find((y) => y.value === child.value));
        return allChildren.every((x) => !!x)
            ? intersectTrees(allChildren)
            : null;
    })
        .filter((x) => !!x);
    return {
        value: trees[0].value,
        children,
    };
}
export { hasPathMatch, getAllPaths, TreePathMatch, someTree, countLeaves, countNodes, flattenTree, mapTree, intersectTrees, getAllLeafPaths, };
