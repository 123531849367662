import dayjs from 'dayjs';
import { useMemo } from 'react';
import { SEEN_PMF_V1 } from 'src/constants/persistentKeys';
import { getCollectorId } from 'src/constants/surveyMonkey';
import { useActivityHistory } from 'src/hooks/activity/activity';
import { usePersistentState } from 'src/hooks/persistence';
import { useUser } from 'src/hooks/user';
import { useIsShadowing } from 'src/hooks/authentication';
const TEAM_EMAILS = ['elektralabs.com', 'gohumanfirst.com', 'gohumanfirst.dev'];
const isTeamEmail = (email) => {
    if (!email) {
        return false;
    }
    return TEAM_EMAILS.some((domain) => email.toLowerCase().endsWith(`@${domain}`));
};
/**
 * A user is qualified and may be shown the PMF survey if:
 * 1. They have not seen the V1 PMF survey before.
 * 2. The does not have a maker role.
 * 3. The toggle is set.
 * 4. The user is not a HumanFirst teammate
 * 5. They have ActivityEvents the past, and have and ActivityEvent from today.
 * 6. Thay have ActivityEvents in the past, and one of those activity events has occured in the past two weeks.
 * 7. The user is not being shadowed by an admin.
 */
const useIsUserQualified = () => {
    const user = useUser();
    const [seenPMFV1] = usePersistentState(SEEN_PMF_V1);
    const activityHistory = useActivityHistory();
    const isShadowing = useIsShadowing();
    return useMemo(() => {
        if (isShadowing ||
            seenPMFV1 ||
            (user === null || user === void 0 ? void 0 : user.role) === 4 ||
            !activityHistory ||
            isTeamEmail(user === null || user === void 0 ? void 0 : user.email) ||
            !getCollectorId()) {
            return false;
        }
        const activityList = Object.entries(activityHistory).flatMap(([, activity]) => [...activity]);
        const now = dayjs();
        const todaysEvents = activityList.filter(({ time }) => now.diff(dayjs(time), 'days') === 0);
        const pastEvents = activityList.filter(({ time }) => now.diff(dayjs(time), 'days') >= 1);
        // If no events have occured in the past the user is not qualified to see the survey .
        if (!pastEvents.length) {
            return false;
        }
        else if (!todaysEvents.length) {
            // If there are no events today, only show if we have more than one past event and at least one of those has occured in the past 2 weeks.
            return (pastEvents.length > 1 &&
                pastEvents.some(({ time }) => now.diff(dayjs(time), 'days') <= 14));
        }
        else {
            // Otherwise, we have some events from the past, and some from today. Today counts as less than 2 weeks, so the user is qualified.
            return true;
        }
    }, [seenPMFV1, user === null || user === void 0 ? void 0 : user.role, user === null || user === void 0 ? void 0 : user.email, activityHistory, isShadowing]);
};
export { useIsUserQualified };
