import { Block, CardSurface, COLORS, Icons, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ContactUsFormTrigger } from '../ContactUsFormTrigger';
export const LockedCard = ({ title, body, contactType = 'Evidence Request' }) => {
    return (React.createElement(CardSurface, { variant: "tertiary" },
        React.createElement(Stack, { padding: '24px', alignItems: 'center', gap: '24px' },
            React.createElement(Icons.Misc.LockSolid, { color: COLORS.tertiary, size: '24px', decorative: true }),
            React.createElement(Stack, { alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, title),
                React.createElement(Block, { padding: '8px' },
                    React.createElement(Typography, { variant: TypographyVariant.Body, align: 'center' }, body))),
            React.createElement(ContactUsFormTrigger, { location: `Locked Card - ${title}`, contactType: contactType }, "Request Access"),
            React.createElement(Spacer, null))));
};
