/**
 * Reports are currently pretty heavy, loading Victory for charting.
 * As such, we delay report loading with a lazy loading component.
 */
import React, { Suspense } from 'react';
import { LoadingState } from 'src/components/LoadingState';
const ReportDisplay = React.lazy(() => import('./ReportDisplay'));
export const ReportDisplayLazy = ({ report }) => {
    return (React.createElement(Suspense, { fallback: React.createElement(LoadingState, null) },
        React.createElement(ReportDisplay, { report: report })));
};
