import React from 'react';
import { Block, COLORS, Icons, Link, Stack, Tag, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { PATHS } from 'src/config/path';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { useRemoveMetadata } from 'src/pages/Workspace/utils';
import { generatePath } from 'src/utils/path';
import { EvidencePrimaryInfoActions } from './EvidencePrimaryInfoActions';
const EvidencePrimaryInfo = ({ item, select: Select }) => {
    var _a, _b, _c, _d;
    const workspaceId = useActiveWorkspaceId();
    const { mutateAsync: removeTag } = useRemoveMetadata(workspaceId);
    const evidenceHref = generatePath(PATHS.atlas.evidence.detail, {
        id: item.id,
    });
    const sourceTypesAndDhtUseContext = [
        ...(item.typeOfSource ? item.typeOfSource : []),
        ...(item.dhtUseContext ? item.dhtUseContext : []),
    ];
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(Stack, { gap: "4px" },
            React.createElement(Stack, { direction: "horizontal", gap: "48px", alignItems: "flex-start" },
                React.createElement(Block, { flex: "1" },
                    React.createElement(Link, { href: evidenceHref, target: '_blank' },
                        React.createElement(When, { condition: !!item.reviewed || !item.seen },
                            React.createElement(Block, { display: 'inline-flex', height: "100%", paddingRight: "4px", alignItems: 'center' },
                                React.createElement(When, { condition: !item.seen && !item.reviewed },
                                    React.createElement(Icons.Misc.Circle, { title: "New", size: "8px", color1: COLORS.secondary, color2: COLORS.secondary })))),
                        item.title)),
                React.createElement(Stack, { direction: "horizontal", alignItems: "center", flex: "0 0 128px", height: "25px" },
                    React.createElement(EvidencePrimaryInfoActions, { item: item }),
                    !!Select ? (React.createElement(React.Fragment, null,
                        React.createElement(Block, { border: `1px solid ${COLORS.gray3}`, height: "16px" }),
                        React.createElement(Select, { item: item }))) : null)),
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, item.journal),
                React.createElement(Link, { href: item.url, typographyVariant: TypographyVariant.Details }, "Source"))),
        React.createElement(Stack, { direction: "horizontal", justifyContent: 'space-between' },
            React.createElement(Stack, { direction: "horizontal", gap: "8px", alignItems: 'start' },
                sourceTypesAndDhtUseContext.map((type) => (React.createElement(Tag, { key: type, textColor: COLORS.black, closeable: false }, type))),
                ((_a = item.tags) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(Block, { border: `1px solid ${COLORS.gray3}`, height: "24px" })) : null,
                React.createElement(Stack, { direction: 'horizontal', flexWrap: true }, (_b = item.tags) === null || _b === void 0 ? void 0 : _b.map((x) => (React.createElement(Tag, { key: x.metadataValue, textColor: COLORS.black, color: COLORS.blue4, closeable: true, onActionClick: () => removeTag({ id: x.id }) }, x.metadataValue))))),
            React.createElement(Block, { minWidth: '130px', alignContent: 'end' },
                React.createElement(Typography, { color: COLORS.gray1 },
                    "Added ",
                    new Date((_d = (_c = item.bookmark) === null || _c === void 0 ? void 0 : _c.createdAt) !== null && _d !== void 0 ? _d : '').toLocaleString())))));
};
export { EvidencePrimaryInfo };
