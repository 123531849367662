import { Button, Spread, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { SmartForm } from 'src/components/SmartForm';
import { SmartInput } from 'src/components/SmartForm/fields';
import { FORBIDDEN_EMAILS } from 'src/constants/forbiddenEmails';
function InviteMemberFormActionBar({ isLoading, isValid, closeModal, }) {
    return (React.createElement(Spread, { justifyContent: 'flex-end', gap: '16px' },
        React.createElement(Button, { onClick: closeModal, type: "reset", variant: "tertiary", size: "large" }, "Cancel"),
        React.createElement(Button, { type: "submit", isLoading: isLoading, disabled: !isValid, size: "large" }, "Send Invite")));
}
const InviteMemberForm = ({ closeModal, onSubmit }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Invite Member"),
        React.createElement(SmartForm, { submitComponent: (props) => (React.createElement(InviteMemberFormActionBar, Object.assign({ closeModal: closeModal }, props))), onSubmit: (data) => onSubmit(data.email) },
            React.createElement(SmartInput, { name: "email", id: "email", type: "email", required: true, placeholder: "Member Email person@company.com", rules: {
                    validate: (val) => !!FORBIDDEN_EMAILS.exec(val) || 'Please use a work email.',
                } }))));
};
export { InviteMemberForm };
