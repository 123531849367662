const getTemplateBookmarksByType = (template, type) => {
    switch (type) {
        case 'search':
            return template.bookmarks.filter((t) => t.resourceType === 'MeasureSearch' ||
                t.resourceType === 'ToolSearch' ||
                t.resourceType === 'VendorSearch' ||
                t.resourceType === 'EvidenceSearch');
        case 'technology':
            return template.bookmarks.filter((t) => t.resourceType === 'Tool');
        case 'vendor':
            return template.bookmarks.filter((t) => t.resourceType === 'Vendor');
        case 'evidence':
            return template.bookmarks.filter((t) => t.resourceType === 'EvidenceSource');
        case 'measure':
            return template.bookmarks.filter((t) => t.resourceType === 'Measure');
        default:
            return [];
    }
};
export { getTemplateBookmarksByType };
