import { Block, Card, Heading, Stack, Center, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { t } from 'src/constants/i18n';
const Contact = () => {
    return (React.createElement(ContentContainer, null,
        React.createElement(PageTitle, { title: "Contact Us" }),
        React.createElement(Center, null,
            React.createElement(Stack, { width: '1200px', gap: '72px', direction: "horizontal", alignItems: 'center' },
                React.createElement(Block, null,
                    React.createElement(Heading, { variant: TypographyVariant.Heading0 },
                        React.createElement("strong", null, t('contactUs.title'))),
                    React.createElement(Typography, { variant: TypographyVariant.Heading3 },
                        t('contactUs.paragraph1'),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("strong", null, t('contactUs.paragraph2')))),
                React.createElement(Block, null,
                    React.createElement(Card, null,
                        React.createElement("iframe", { className: "airtable-embed", src: "https://airtable.com/embed/shrHzqXec6233zMd3", frameBorder: "0", width: "600px", height: "600px" })))))));
};
export { Contact };
