import { Block, Card, COLORS, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { RequestIngestionButton } from 'src/components/RequestIngestionButton';
import { t } from 'src/constants/i18n';
/** Renderes a tool that has not been fully ingested. */
const PartiallyIngestedToolCard = ({ tool }) => {
    var _a;
    return (React.createElement(Card, null,
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, t('toolDetails.partiallyIngested.header')),
            React.createElement(Typography, { color: COLORS.gray1 }, t('toolDetails.partiallyIngested.body')),
            React.createElement(Block, null,
                React.createElement(RequestIngestionButton, { resourceId: tool.id, resourceType: 'tool', formData: { model: tool.model, maker: (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name } })))));
};
export { PartiallyIngestedToolCard };
