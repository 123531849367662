import { StatefulTooltip } from '@humanfirst/elektron';
import React, { useCallback } from 'react';
import { WorkspaceResourceComments, WorkspaceResourceCommentsIcon, } from 'src/components/WorkspaceResourceComments';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
/**
 * Renders the "cell" for comments.
 *
 * Comments live mostly in the tooltip, so this just renders the icon to enter
 * the comments flow.
 */
const CommentsCell = ({ item, }) => {
    const bookmark = item.__bookmark;
    const workspaceId = useActiveWorkspaceId();
    const tooltipContent = useCallback(() => (React.createElement(WorkspaceResourceComments, { workspaceId: workspaceId !== null && workspaceId !== void 0 ? workspaceId : 'invalid', resourceType: bookmark.resourceType, resource: bookmark.resource })), [workspaceId, bookmark.resourceType, bookmark.resource]);
    if (!workspaceId) {
        return null;
    }
    return (React.createElement(StatefulTooltip, { placement: "auto", content: tooltipContent, autoFocus: true, 
        // It's a bit odd that we're using tooltip with menu. The problem is that the
        // base popover does not have our style overrides so they would have to be duplicated here.
        accessibilityType: "menu" },
        React.createElement(WorkspaceResourceCommentsIcon, { workspaceId: workspaceId, resourceType: bookmark.resourceType, resource: bookmark.resource })));
};
/**
 * A comment column represents all comments for a single resource.
 *
 * NOTE: This works regardless of what type of resource we are dealing with
 * because we only look at the __bookmark property.
 */
const CommentsColumn = {
    render: (item) => React.createElement(CommentsCell, { item: item }),
    id: 'comments',
    header: 'Comments',
};
export { CommentsColumn, CommentsCell };
