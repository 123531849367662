import { Typography, TypographyVariant, CardSurface, Block, } from '@humanfirst/elektron';
import React from 'react';
import { useElektraApi, WorkspaceBookmarkResource, } from '@humanfirst/use-elektra-api';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { EvidenceReviewDataList } from './components/EvidenceReviewDataList';
const EvidenceReview = ({ data }) => {
    const result = useElektraApi(WorkspaceBookmarkResource.list(data.id, {
        filter: [['resourceType', '$eq', 'EvidenceSource']],
    }));
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { padding: "32px" },
            React.createElement(Typography, { marginBottom: "16px", variant: TypographyVariant.Heading2 }, "Evidence Review"),
            React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: EvidenceReviewDataList, workspaceId: data.id })))));
};
export { EvidenceReview };
