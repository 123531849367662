import { Block, Breakpoint, ButtonLink, Card, COLORS, DisplayBreakpoint, Divider, Link, Spacer, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
const ExploreAtlasOption = ({ title, subtitle, example, action, icon, buttonTitle, exampleLink, count, }) => {
    return (React.createElement(Card, null,
        React.createElement(Spread, { direction: ['horizontal', 'horizontal', 'horizontal', 'vertical'], alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'], minHeight: ['0px', '0px', '0px', '270px'], gap: "16px", height: "100%" },
            React.createElement(Block, null, icon),
            React.createElement(Block, { flex: "1" },
                React.createElement(DisplayBreakpoint, { min: Breakpoint.L },
                    React.createElement(Stack, null,
                        React.createElement(Typography, { align: "center", variant: TypographyVariant.Heading2Bold }, title),
                        React.createElement(Typography, { align: "center", variant: TypographyVariant.Heading2 }, count !== undefined ? count.toLocaleString() : null),
                        React.createElement(Block, { height: "48px" },
                            React.createElement(Typography, { align: "center", variant: TypographyVariant.Body, color: COLORS.gray1 }, subtitle)))),
                React.createElement(DisplayBreakpoint, { max: Breakpoint.M },
                    React.createElement(Stack, { direction: 'horizontal' },
                        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, title),
                        React.createElement(Typography, { variant: TypographyVariant.Heading2 }, count !== undefined ? count.toLocaleString() : null)),
                    React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1 }, subtitle))),
            React.createElement(Block, { width: '216px' },
                React.createElement(ButtonLink, { size: "full", href: action }, buttonTitle)),
            React.createElement(DisplayBreakpoint, { min: Breakpoint.L },
                React.createElement(When, { condition: !!example && !!exampleLink },
                    React.createElement(Divider, null),
                    React.createElement(Spacer, { size: "16px" }),
                    React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                        "Try looking for:",
                        ' ',
                        React.createElement(Link, { href: exampleLink, typographyVariant: TypographyVariant.DetailsBold }, example)))))));
};
export { ExploreAtlasOption };
