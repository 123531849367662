import React from 'react';
import { Tag, Typography, TypographyVariant, } from '@humanfirst/elektron';
const APPLIED_SCOPE_MAPPING = {
    therapeuticAreas: 'Therapeutic Area',
    medicalConditions: 'Medical Condition',
    conceptsOfInterest: 'Concept of Interest',
    allMeasuresFlat: 'Concept of Interest',
};
const ScopeTagList = ({ scope }) => {
    return (React.createElement(React.Fragment, null, scope
        .flatMap((x) => {
        const label = APPLIED_SCOPE_MAPPING[x.fieldId];
        if (!label) {
            return [];
        }
        return Array.isArray(x.filterValue)
            ? x.filterValue.map((y) => ({
                label: label,
                value: String(y),
            }))
            : [{ label: label, value: String(x.filterValue) }];
    })
        .map((filter) => (React.createElement(Tag, { key: filter.value, closeable: false, variant: "solid" },
        React.createElement(Typography, { variant: TypographyVariant.Details },
            filter.label,
            ": ",
            filter.value))))));
};
export { ScopeTagList };
