import React, { useMemo } from 'react';
import { generatePath } from 'react-router';
import { Block, COLORS, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { PATHS } from 'src/config/path';
import { PathContextType } from 'src/utils/path';
import { useActiveApp } from 'src/hooks/activeApp';
import { deserializeWorkspaceMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
/** Renders the cell for a study name (used to redirect to that study). */
export const NameCell = ({ item }) => {
    // This allows us to reuse this table until we have admin UI
    const activeApp = useActiveApp();
    const isAdmin = activeApp === PathContextType.admin;
    const href = isAdmin
        ? generatePath(PATHS.admin.studies.detail, { id: item.id })
        : generatePath(PATHS.catalog.studies.view.index, { id: item.id });
    if (!item.name) {
        return React.createElement(Link, { href: href }, "Untitled Study");
    }
    return React.createElement(Link, { href: href }, item.name);
};
/** Render measures for a single tech. */
const MeasuresForTech = ({ model, measures, }) => {
    if (!measures || measures.length === 0) {
        return null;
    }
    return (React.createElement(Stack, { gap: "0px" },
        React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, model),
        React.createElement("ul", { style: { paddingInlineStart: '10px' } }, measures.map((y) => (React.createElement("li", { key: y },
            React.createElement(Typography, { variant: TypographyVariant.Details }, y)))))));
};
export const MeasuresCell = ({ item }) => {
    const measuresByTechnology = useMemo(() => {
        const parsedMetadata = deserializeWorkspaceMetadata(item.additionalMetadata);
        const { measureMetadata } = splitMetadata(parsedMetadata);
        return measureMetadata
            .filter((m) => m.field.measuresLabel === 'conceptsOfInterest')
            .map((m) => [m.field.toolId, m.value])
            .reduce((c, [toolId, measureName]) => {
            if (!(toolId in c)) {
                c[toolId] = [];
            }
            c[toolId].push(measureName);
            return c;
        }, {});
    }, [item]);
    const ids = Object.keys(measuresByTechnology);
    if (ids.length === 0) {
        return null;
    }
    return (React.createElement(Stack, { gap: "16px" }, item.relationships.tools.map((tech) => (React.createElement(MeasuresForTech, { key: `${tech.id}`, model: tech.model, measures: measuresByTechnology[tech.id] })))));
};
/** Renders the cell for therapeutic areas */
export const TherapeuticAreasCell = ({ item }) => {
    return (React.createElement(Stack, null, item.relationships.therapeuticAreas.map((x) => (React.createElement(Block, { key: x.id }, x.therapeuticArea)))));
};
/** Renders the cell for medical conditions */
export const MedicalConditionsCell = ({ item }) => {
    return (React.createElement(Stack, null, item.relationships.medicalConditions.map((x) => (React.createElement(Link, { key: x.name, href: generatePath(PATHS.atlas.medicalConditions.detail, {
            id: x.id,
        }) }, x.name)))));
};
