import { COLORS, Typography, Card, TypographyVariant, Heading2, Stack, Link, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
const MedicalConditionsCard = ({ medicalConditions }) => {
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Medical Conditions"),
            React.createElement(When, { condition: !medicalConditions.length },
                React.createElement(Typography, { color: COLORS.gray2 }, "No medical conditions listed.")),
            React.createElement(Stack, null, medicalConditions.map((x) => (React.createElement(Link, { key: x.id, href: generatePath(PATHS.atlas.medicalConditions.detail, {
                    id: x.id,
                }) }, x.name)))))));
};
export { MedicalConditionsCard };
