import { Stack, Typography, TypographyVariant, ControlsPopover, COLORS, Icons, } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceResource } from '@humanfirst/use-elektra-api';
import React, { useCallback, useMemo, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { useParams } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { LoadingState } from 'src/components/LoadingState';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { getBookmarksMatchingMetadata, useGetBookmarksAndMetadata, } from '../../utils';
import { ListActionsModal } from './components/ListActionsModal.tsx';
import { ListContextProvider } from './contexts/ListContext';
import { SearchAndPlanSectionsList } from './SearchAndPlanSectionsList';
const ListOptions = ({ listName, projectId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState();
    const closeModal = useCallback(() => setIsOpen(false), []);
    const openModal = useCallback(() => setIsOpen(true), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListActionsModal, { isOpen: isOpen, onClose: closeModal, workspaceId: projectId, listName: listName, action: action }),
        React.createElement(ControlsPopover, { popoverPlacement: "bottomLeft", iconColor: COLORS.primary, iconBackgroundColor: 'transparent', variant: "button-like", menuItems: [
                {
                    title: 'Rename',
                    onClick: () => {
                        setAction('rename');
                        openModal();
                    },
                    icon: React.createElement(Icons.Misc.Edit, { color: COLORS.gray1, decorative: true }),
                },
                {
                    title: 'Delete',
                    onClick: () => {
                        setAction('deleteList');
                        openModal();
                    },
                    icon: React.createElement(Icons.Misc.Trash, { color: COLORS.gray1, decorative: true }),
                },
            ] })));
};
const ArchivedList = ({ bookmarks, listName }) => {
    return (React.createElement(ListContextProvider, { activeList: listName },
        React.createElement(SearchAndPlanSectionsList, { bookmarks: bookmarks, isArchive: true })));
};
const AllSavedList = ({ bookmarks, listName }) => {
    return (React.createElement(ListContextProvider, { activeList: listName },
        React.createElement(SearchAndPlanSectionsList, { bookmarks: bookmarks })));
};
const TrueList = ({ bookmarks, metadata, listName }) => {
    const listBookmarks = getBookmarksMatchingMetadata(bookmarks, metadata, 'tag', listName);
    return (React.createElement(ListContextProvider, { activeList: listName },
        React.createElement(SearchAndPlanSectionsList, { bookmarks: listBookmarks, isList: true })));
};
const ListWrapper = ({ data: project, listName, }) => {
    const { isLoading, metadata, bookmarks } = useGetBookmarksAndMetadata(project);
    const archivedBookmarks = useMemo(() => getBookmarksMatchingMetadata(bookmarks, metadata, 'systemMetadata', 'Archived'), [bookmarks, metadata]);
    const activeBookmarks = useMemo(() => bookmarks.filter((b) => !archivedBookmarks.includes(b)), [bookmarks, archivedBookmarks]);
    const isUserMadeList = !(listName === 'Archived' || listName === 'All Saved');
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Workspaces,
            BREADCRUMBS.WorkspaceDetails({
                id: project.id,
                name: project.name,
            }),
            BREADCRUMBS.WorkspaceLists({
                projectId: project.id,
                listName: listName,
            }),
        ] },
        React.createElement(PageTitle, { title: "My Project List" }),
        React.createElement(Stack, { gap: '24px' },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading1 }, listName),
                React.createElement(When, { condition: isUserMadeList },
                    React.createElement(ListOptions, { listName: listName, projectId: project.id }))),
            React.createElement(If, { condition: isUserMadeList },
                React.createElement(Then, null,
                    React.createElement(TrueList, { bookmarks: activeBookmarks, metadata: metadata, listName: listName })),
                React.createElement(Else, null, listName === 'Archived' ? (React.createElement(ArchivedList, { bookmarks: archivedBookmarks, listName: listName })) : (React.createElement(AllSavedList, { bookmarks: activeBookmarks, listName: listName })))))));
};
export const ListPage = () => {
    const { listName, projectId } = useParams();
    const result = useElektraApi(WorkspaceResource.get(projectId));
    return (React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: ListWrapper, listName: listName })));
};
