import { Block, Button, CardSurface, Center, Stack, Typography, } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceReportResource, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { ErrorComponent } from 'src/components/ErrorComponent';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { ReportTable } from 'src/components/ReportTable';
import { useShowModalBound } from 'src/hooks/modal';
const ReportTableWrapper = ({ data, workspace, }) => {
    const showContactUsModal = useShowModalBound('contact-us', {
        location: `Report List: ${workspace.id}`,
        contactType: 'General Contact',
    });
    return (React.createElement(Stack, { gap: "80px" },
        React.createElement(If, { condition: !data.length },
            React.createElement(Then, null,
                React.createElement(CardSurface, null,
                    React.createElement(Center, null,
                        React.createElement(Block, { padding: "32px" },
                            React.createElement(Stack, { alignItems: 'center' },
                                React.createElement(Typography, null, "Have a specific research question for this TA?"),
                                React.createElement(Button, { variant: "secondary", onClick: showContactUsModal }, "Contact our experts")))))),
            React.createElement(Else, null,
                React.createElement(ReportTable, { reports: data })))));
};
const ReportsList = ({ data }) => {
    const result = useElektraApi(WorkspaceReportResource.list(data.id));
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: ReportTableWrapper, renderError: ErrorComponent, workspace: data }, result)));
};
export { ReportsList };
