import { DateColumn, TextColumn, Typography, } from '@humanfirst/elektron';
import React from 'react';
import { getMetadata } from 'src/utils/workspaceMetadata';
import { useActiveApp } from 'src/hooks/activeApp';
import { PathContextType } from 'src/utils/path';
import { MeasuresCell, MedicalConditionsCell, NameCell, TherapeuticAreasCell, } from './cells';
/***************************************
 * Column Utilities                    *
 ***************************************/
const MetadataColumn = (id, overrides) => TextColumn(id, Object.assign({ getStringValue: (study) => { var _a; return (_a = getMetadata(study, id)) !== null && _a !== void 0 ? _a : ''; }, render: (study) => { var _a; return (_a = getMetadata(study, id)) !== null && _a !== void 0 ? _a : ''; } }, overrides));
/***************************************
 * Column Definitions                  *
 ***************************************/
const TITLE_COLUMN = TextColumn('name', {
    header: 'Title',
    render: (item) => React.createElement(NameCell, { item: item }),
    metadata: { displayRole: 'required' },
});
const STUDY_ID_COLUMN = MetadataColumn('Study ID', {
    id: 'studyId',
    header: 'Study ID',
    metadata: { displayRole: 'hidden' },
});
const DATE_COLUMN = DateColumn('createdAt', {
    header: 'Date Added',
});
const MEASURES_COLUMN = {
    id: 'measures',
    header: 'Measures',
    render: (item) => React.createElement(MeasuresCell, { item: item }),
    sortable: true,
    searchable: true,
    getStringValue: (x) => x.relationships.measureCategories.map((x) => x.category).join(' '),
};
const MEDICAL_CONDITIONS_COLUMN = {
    id: 'medical-conditions',
    header: 'Medical Conditions',
    render: (item) => React.createElement(MedicalConditionsCell, { item: item }),
    sortable: true,
    searchable: true,
    metadata: { displayRole: 'hidden' },
    getStringValue: (x) => x.relationships.medicalConditions.map((x) => x.name).join(' '),
};
const THERAPEUTIC_AREAS_COLUMN = {
    id: 'therapeutic-areas',
    header: 'Therapeutic Area',
    render: (item) => React.createElement(TherapeuticAreasCell, { item: item }),
    sortable: true,
    searchable: true,
    metadata: { displayRole: 'hidden' },
    getStringValue: (x) => x.relationships.therapeuticAreas.map((x) => x.therapeuticArea).join(' '),
};
const TEAM_COLUMN = {
    id: 'team',
    header: 'Team',
    render: (item) => { var _a; return React.createElement(Typography, null, (_a = item.team) === null || _a === void 0 ? void 0 : _a.name); },
    getStringValue: (item) => { var _a, _b; return (_b = (_a = item.team) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''; },
    sortable: true,
    searchable: true,
};
/***************************************
 * Column Hooks                        *
 ***************************************/
export const useColumns = (simple) => {
    const pathContext = useActiveApp();
    const isAdmin = pathContext === PathContextType.admin;
    if (simple) {
        return [TITLE_COLUMN, MEASURES_COLUMN, DATE_COLUMN];
    }
    else {
        return [
            TITLE_COLUMN,
            STUDY_ID_COLUMN,
            MEDICAL_CONDITIONS_COLUMN,
            THERAPEUTIC_AREAS_COLUMN,
            ...(isAdmin ? [TEAM_COLUMN] : []),
            MEASURES_COLUMN,
            DATE_COLUMN,
        ];
    }
};
