import { WorkspaceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { WORKSPACE_UPLOAD_DOMAIN } from './constants';
/**
 * Gets the name from a raw uploaded file.
 *
 * First uses the content disposition, and if that fails, uses the key.
 * @param raw
 * @returns
 */
const getName = (raw) => {
    if (raw.ContentDisposition) {
        const pieces = raw.ContentDisposition.split(';');
        const filenamePiece = pieces.find((x) => x.split('=', 2)[0].trim() === 'filename');
        if (filenamePiece) {
            return filenamePiece.split('=', 2)[1];
        }
    }
    const pieces = raw.Key.split('/');
    return pieces[pieces.length - 1];
};
/** Converts a raw response to an uploaded file. */
const rawToUploadedFile = (raw) => {
    var _a;
    return {
        name: getName(raw),
        url: `${WORKSPACE_UPLOAD_DOMAIN}/${raw.Key}`,
        userId: (_a = raw.Metadata) === null || _a === void 0 ? void 0 : _a.user,
        contentType: raw.ContentType,
        lastModified: raw.LastModified,
        key: raw.Key,
    };
};
/** Returns the list of uploaded files for a given workspace. */
export const useListUploadedFiles = (workspaceId) => {
    var _a;
    const { data, isLoading } = useElektraApi(WorkspaceResource.getAllFiles(workspaceId));
    return {
        data: (_a = data === null || data === void 0 ? void 0 : data.map((x) => rawToUploadedFile(x))) !== null && _a !== void 0 ? _a : [],
        isLoading,
    };
};
