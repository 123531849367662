import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { useCallback, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
/**
 * Loads the technologies to compare.
 */
export const useCompareTechnologies = () => {
    const [tools, setTools] = useQueryParam('selection', StringParam);
    const ids = useMemo(() => { var _a; return (_a = tools === null || tools === void 0 ? void 0 : tools.split(',').filter((x) => !!x)) !== null && _a !== void 0 ? _a : []; }, [tools]);
    const resource = useMemo(() => ToolResource.getBulk(ids, {
        join: ['maker', 'maker.legalPolicies', 'maker.securityPolicies'],
    }), [ids]);
    const { data, isLoading } = useElektraApi(resource, { enabled: !!ids.length });
    const toggleTechnology = useCallback(({ id }) => {
        setTools(ids.filter((x) => x !== id).join(','));
    }, [setTools, ids]);
    return { technologies: data, toggleTechnology, isLoading };
};
