import { COLORS, Card, Markdown, Typography, } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { LoadingState } from '../LoadingState';
import { MarkdownCodeExtensionChart } from './components/MarkdownCodeExtensionChart';
import { MarkdownMediaExtensionLoom } from './components/MarkdownMediaExtensionLoom';
import { MarkdownCodeExtensionDeviceTable } from './components/MarkdownCodeExtensionDeviceTable';
import { MarkdownCodeExtensionEvidenceTable } from './components/MarkdownCodeExtensionEvidenceTable';
import { MarkdownCodeExtensionImageGallery } from './components/MarkdownCodeExtensionImageGallery';
import { MarkdownCodeExtensionTable } from './components/MarkdownCodeExtensionTable';
import { useUriTransformer } from './hooks';
import { MarkdownCodeExtensionChartFacet } from './components/MarkdownCodeExtensionChartFacet';
import { MarkdownMediaExtensionEmoji } from './components/MarkdownMediaExtensionEmoji';
import { UriTransformerContext } from './contexts/UriTransformerContext';
const CODE_EXTENSIONS = {
    groupDevices: MarkdownCodeExtensionDeviceTable,
    devices: MarkdownCodeExtensionDeviceTable,
    groupEvidence: MarkdownCodeExtensionEvidenceTable,
    evidence: MarkdownCodeExtensionEvidenceTable,
    table: MarkdownCodeExtensionTable,
    chart: MarkdownCodeExtensionChart,
    'evidence-chart': MarkdownCodeExtensionChartFacet,
    'device-chart': MarkdownCodeExtensionChartFacet,
    'image-gallery': MarkdownCodeExtensionImageGallery,
};
const MEDIA_EXTENSIONS = {
    '^emoji://.+': MarkdownMediaExtensionEmoji,
    '^https://(www.)?loom.com/share/(?<id>[a-f0-9]+)': MarkdownMediaExtensionLoom,
};
export const AtlasMarkdown = ({ content }) => {
    const { isLoading, transformer } = useUriTransformer(content);
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(UriTransformerContext.Provider, { value: { transformer } },
        React.createElement(Markdown, { content: content, codeExtensions: CODE_EXTENSIONS, mediaExtensions: MEDIA_EXTENSIONS, transformUri: transformer })));
};
export const AtlasMarkdownPreview = ({ content, }) => {
    return (React.createElement(Card, null,
        React.createElement(If, { condition: !content },
            React.createElement(Then, null,
                React.createElement(Typography, { color: COLORS.gray2, align: "center", marginBottom: "8px" }, "Nothing to show."),
                React.createElement(Typography, { color: COLORS.gray2, align: "center" }, "Please enter markdown to see a preview.")),
            React.createElement(Else, null,
                React.createElement(AtlasMarkdown, { content: content })))));
};
