import { COLORS, Block, Card, Spacer, Stack, TypographyVariant, Typography, } from '@humanfirst/elektron';
import React from 'react';
const SinglePopAttribute = ({ label, condition, metadata }) => {
    return (React.createElement(Block, null,
        React.createElement(Typography, { color: COLORS.gray2 }, label),
        condition ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, metadata)) : (React.createElement(Typography, { variant: TypographyVariant.Details }, "N/A"))));
};
export const QuestionnairePopulationCard = ({ tool }) => {
    var _a, _b, _c, _d;
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Population Details"),
        React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "This Questionnaire is intended for use in the populations below"),
        React.createElement(Spacer, { size: '24px' }),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(SinglePopAttribute, { label: 'Therapeutic Areas', condition: !!((_a = tool.vendorStatedTherapeuticAreas) === null || _a === void 0 ? void 0 : _a.length), metadata: (_b = tool.vendorStatedTherapeuticAreas) === null || _b === void 0 ? void 0 : _b.flatMap((x) => x.therapeuticArea).join(', ') }),
            React.createElement(SinglePopAttribute, { label: 'Medical Conditions', condition: !!((_c = tool.vendorStatedMedicalConditions) === null || _c === void 0 ? void 0 : _c.length), metadata: (_d = tool.vendorStatedMedicalConditions) === null || _d === void 0 ? void 0 : _d.flatMap((x) => x.name).join(', ') }),
            React.createElement(SinglePopAttribute, { label: 'Age Range', condition: !!tool.ageRange, metadata: tool.ageRange }))));
};
