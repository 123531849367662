import { __awaiter } from "tslib";
import React, { useEffect } from 'react';
import { COLORS, Link, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useFormContext } from 'react-hook-form';
import { useRecaptcha } from 'src/contexts/RecaptchaContext';
import { useLogger } from 'src/hooks/logger';
const CAPTCHA_FIELD_NAME = 'captchaToken';
//token to refresh every one hour
const CAPTCHA_REFRESH_INTERVAL = 1.5 * 60 * 1000;
const RecaptchaTerms = () => {
    return (React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray2 },
        "This site is protected by reCAPTCHA and the Google",
        ' ',
        React.createElement(Link, { href: "https://policies.google.com/privacy" }, "Privacy Policy"),
        " and",
        ' ',
        React.createElement(Link, { href: "https://policies.google.com/terms" }, "Terms of Service"),
        ' ',
        "apply."));
};
const SmartRecaptcha = () => {
    const { setValue, register, formState: { submitCount }, } = useFormContext();
    const logger = useLogger(SmartRecaptcha.name);
    const executeRecaptcha = useRecaptcha();
    const hasRecaptcha = typeof executeRecaptcha !== 'undefined';
    useEffect(() => {
        if (hasRecaptcha) {
            register(CAPTCHA_FIELD_NAME, { required: true });
        }
    }, [register, hasRecaptcha]);
    useEffect(() => {
        if (!executeRecaptcha) {
            logger.info('recaptcha is not set up, skipping');
            return;
        }
        let isMounted = true;
        const executeAndSetValue = () => __awaiter(void 0, void 0, void 0, function* () {
            const token = yield executeRecaptcha();
            if (!isMounted) {
                // Because the above call could take a while
                // we bail out if `isMounted` has become false
                // as we know then that the token value is
                // probably invalid for our purposes
                return;
            }
            setValue(CAPTCHA_FIELD_NAME, token, {
                shouldValidate: true,
                shouldDirty: false,
            });
        });
        executeAndSetValue().catch((e) => logger.error(e));
        const refreshOnInterval = setInterval(executeAndSetValue, CAPTCHA_REFRESH_INTERVAL);
        return () => {
            isMounted = false;
            clearInterval(refreshOnInterval);
        };
    }, [executeRecaptcha, logger, setValue, submitCount]);
    if (!hasRecaptcha) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(RecaptchaTerms, null);
};
export { SmartRecaptcha };
