import React, { useMemo } from 'react';
import { LoadingState } from 'src/components/LoadingState';
import { ToolTable } from 'src/components/ToolTable';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useGetEvidenceForMeasureCategory, useGetToolsForMeasureCategory, } from 'src/hooks/relatedResources';
import { ToolSupportingEvidenceCell } from '../cells';
import { getComponentEvidence } from '../utils/utils';
const MeasureCategoryToolTable = ({ category, showEvidence }) => {
    const { data: tools, isLoading: isToolsLoading } = useGetToolsForMeasureCategory(category);
    const { data: evidence, isLoading: isEvidenceLoading } = useGetEvidenceForMeasureCategory(category);
    const toolsWithEvidence = useMemo(() => getComponentEvidence(tools !== null && tools !== void 0 ? tools : [], evidence !== null && evidence !== void 0 ? evidence : []), [tools, evidence]);
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasMeasureEvidence = useHasPermission('measure.evidence', 'read', {
        id: category.id,
    });
    const toolSupportingEvidenceColumn = {
        id: 'supportingEvidenceCount',
        header: 'Supporting Evidence',
        sortable: 'numeric',
        getStringValue: (item) => String(item.evidenceCount),
        render: (item) => (React.createElement(ToolSupportingEvidenceCell, { item: item, showEvidence: showEvidence })),
    };
    const { technologyTableScopes } = useGetConnectInstrumentScopes();
    if (isToolsLoading || isEvidenceLoading) {
        return React.createElement(LoadingState, null);
    }
    if (!hasMeasureEvidence && hasEvidenceSource) {
        return React.createElement(ToolTable, { data: toolsWithEvidence, scope: technologyTableScopes });
    }
    return (React.createElement(ToolTable, { extraColumns: [toolSupportingEvidenceColumn], initialState: {
            sort: !hasEvidenceSource
                ? undefined
                : {
                    column: 'supportingEvidenceCount',
                    ascending: false,
                },
        }, scope: technologyTableScopes, data: toolsWithEvidence }));
};
export { MeasureCategoryToolTable };
