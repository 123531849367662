import { useState } from 'react';
import { useDebouncedCallback } from './useDebouncedCallback';
function useDebouncedState(initialState, debounceTime) {
    const [value, setValue] = useState(initialState);
    // `setValue` is stable so we should be fine passing it directly to the callback without
    // using `useCallback` to ensure it's stable only on changes
    const setValueDebounced = useDebouncedCallback(setValue, debounceTime);
    return [value, setValueDebounced];
}
export { useDebouncedState };
