import { Block, ButtonLink, Heading, Icons, Spread, Stack, } from '@humanfirst/elektron';
import React from 'react';
import { Case, Switch } from 'react-if';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { useHasAllEvidence, useHasNoEvidence, useHasPartialEvidence, } from 'src/hooks/evidencePermission';
import { useSetTitle } from 'src/hooks/title';
import { generatePath } from 'src/utils/path';
import { useAppStatistics } from '../../hooks/useAppStatistics';
import { Can } from '../../components/Permissions';
import { ExploreAtlasOption } from './components/ExploreAtlasOption';
import { RecentActivity } from './components/RecentActivity';
import { RecentResearch } from './components/RecentResearch';
import { RecentTechnology } from './components/RecentTechnology';
const EXAMPLE_TECHNOLOGY_SEARCH = generatePath(PATHS.atlas.technologies.list) +
    '?page=1&filters=regulatoryDecisions%7C%7CIncludes+Any+Of%7C%7C%5B"FDA+Registered"%5D&filters=modalitiesSensorType%7C%7CIncludes+Any+Of%7C%7C%5B"Accelerometer+%283-axis%29"%5D';
const EXAMPLE_MEASURE_SEARCH = generatePath(PATHS.atlas.measures.detail, { id: 'rec2y2zkH2RVcVusQ' }) +
    '?filters=modalitiesSensorType%7C%7CIncludes+Any+Of%7C%7C%5B%22Electrocardiogram+%28EKG%2FECG%29%22%5D&filters=wearLocation%7C%7CIncludes+Any+Of%7C%7C%5B%22Chest%22%5D';
const EXAMPLE_MEDICAL_CONDITION_SEARCH = generatePath(PATHS.atlas.medicalConditions.list) +
    '?filters=therapeuticArea%7C%7CIncludes+Any+Of%7C%7C%5B%22Neurology%22%5D';
const EXAMPLE_VENDOR_SEARCH = generatePath(PATHS.atlas.vendors.list) +
    '?filters=type%7C%7CIncludes+Any+Of%7C%7C%5B"Data+Science+%26+Analytics"%5D';
const DashboardSearchOptions = ({ stats }) => {
    return (React.createElement(Block, { width: "100%", display: "grid", gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr 1fr 1fr'], gridGap: ['8px', '8px', '8px', '24px'] },
        React.createElement(ExploreAtlasOption, { title: t('dashboard.technologyTitle'), subtitle: t('dashboard.technologySubtitle'), buttonTitle: t('dashboard.technologyCTA'), example: t('dashboard.technologyExample'), exampleLink: EXAMPLE_TECHNOLOGY_SEARCH, icon: React.createElement(Icons.Navigation.Products, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.technologies.list, count: stats.data.technologies }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.measureLibraryTitle'), buttonTitle: t('dashboard.measureLibraryCTA'), subtitle: t('dashboard.measureLibrarySubtitle'), example: t('dashboard.measureLibraryExample'), exampleLink: EXAMPLE_MEASURE_SEARCH, icon: React.createElement(Icons.Navigation.Activity, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.measures.list, count: stats.data.measures }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.medicalConditionTitle'), subtitle: t('dashboard.medicalConditionSubtitle'), buttonTitle: t('dashboard.medicalConditionCTA'), example: t('dashboard.medicalConditionExample'), exampleLink: EXAMPLE_MEDICAL_CONDITION_SEARCH, icon: React.createElement(Icons.Navigation.Explore, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.medicalConditions.list, count: stats.data.medicalConditions }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.vendorsTitle'), subtitle: t('dashboard.vendorsSubtitle'), buttonTitle: t('dashboard.vendorsCTA'), example: t('dashboard.vendorsExample'), exampleLink: EXAMPLE_VENDOR_SEARCH, icon: React.createElement(Icons.Navigation.Vendors, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.vendors.list, count: stats.data.vendors })));
};
const Dashboard = () => {
    const stats = useAppStatistics();
    useSetTitle('Dashboard');
    const hasAllEvidence = useHasAllEvidence();
    const hasNoEvidence = useHasNoEvidence();
    const hasPartialEvidence = useHasPartialEvidence();
    return (React.createElement(Block, { paddingTop: "40px", paddingBottom: '40px', paddingLeft: ['16px', '16px', '16px', '32px'], paddingRight: ['16px', '16px', '16px', '32px'] },
        React.createElement(Stack, { maxWidth: '1780px', minWidth: "380px", flex: 1, margin: 'auto', gap: "32px" },
            React.createElement(Spread, null,
                React.createElement(Heading, { marginBottom: "0px" }, "Atlas Network"),
                React.createElement(Can, { resource: "workspace", action: "create" },
                    React.createElement(ButtonLink, { href: generatePath(PATHS.projects.create), size: "large" }, "Create New Project"))),
            React.createElement(Block, { display: "grid", gridGap: ['8px', '8px', '8px', '24px'] },
                React.createElement(DashboardSearchOptions, { stats: stats }),
                React.createElement(Block, { display: "grid", gridAutoRows: ['1fr'], gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'], gridGap: ['8px', '8px', '8px', '24px'] },
                    React.createElement(Switch, null,
                        React.createElement(Case, { condition: hasAllEvidence },
                            React.createElement(RecentTechnology, null),
                            React.createElement(RecentResearch, null)),
                        React.createElement(Case, { condition: hasPartialEvidence },
                            React.createElement(RecentActivity, null),
                            React.createElement(RecentTechnology, null)),
                        React.createElement(Case, { condition: hasNoEvidence },
                            React.createElement(RecentActivity, null),
                            React.createElement(RecentTechnology, null))))))));
};
export { Dashboard };
