/**
 * This file contains utilites for interacting with the Atlas measures category.
 *
 * Measures categories have full database representation (which has hierarchy information,
 * links, etc.). However, some locations only have access to a simplified
 * string representation of a measure category. This will look like `Heart Rate (Activity)`
 * where the item outside of the parentheses is a category, and the items inside are its
 * ancestors.
 *
 * The utilities in this file attempt to parse these strings into a `Tree` structure.
 */
import { getAllLeafPaths, mapTree } from './tree';
/**
 * Converts from raw strings like "Intensity (Activity)" into paths
 * like ["Activity", "Intensity"] (note that the strings are inverted).
 * @param raw A raw string
 * @returns A path (list of strings) that represents the string
 */
const getPathFromString = (raw) => {
    return (raw
        // Goes from "foo (Foo) (bar, baz, quax)" to "foo (Foo), bar, baz, quax"
        // while only replacing the last group in parens
        .replace(/\(([^(]+?)\)$/, ',$1')
        .split(',')
        .map((x) => x.trim())
        .reverse());
};
/**
 * Bundles an object with the path that it should live under.
 */
function getPathForObject(getCategory, object) {
    return {
        object,
        path: getPathFromString(getCategory(object)),
    };
}
/**
 * Insert an object into a tree.
 *
 * WARNING: This method is _not_ pure. It will mutate the trees given to it.
 * Let me know if you can figure out a better way to do this.
 */
function insertIntoTree(trees, path, object) {
    if (path.length === 1) {
        // We are at the end of our path. First check to see if we've already
        // inserted a temporary node. If so, we should override that value.
        for (const tree of trees) {
            if (tree.value.path === path[0]) {
                tree.value.object = object;
                return;
            }
        }
        // We couldn't find any node, so insert a node right here.
        trees.push({ value: { object: object, path: path[0] }, children: [] });
    }
    else {
        // See if we've already created the next node.
        for (const tree of trees) {
            if (tree.value.path === path[0]) {
                insertIntoTree(tree.children, path.slice(1), object);
                return;
            }
        }
        // We didn't find it any existing trees, so we'll insert an intermediate object.
        const children = [];
        insertIntoTree(children, path.slice(1), object);
        trees.push({ value: { object: null, path: path[0] }, children: children });
    }
}
/**
 * Sort trees based on their paths.
 *
 * WARNING: Sort is in place.
 */
const sortTrees = (trees) => {
    trees.sort((a, b) => a.value.path.localeCompare(b.value.path));
    trees.forEach((t) => sortTrees(t.children));
};
/**
 * Given an array of objects put them into a hierarchy.
 *
 * This will return a tree where each value contains the original
 * object and the path key to that object.
 */
function getMeasurementHierarchyObjects(objects, getCategory, shouldSort = true) {
    const trees = [];
    objects
        .map((x) => getPathForObject(getCategory, x))
        .filter((x) => !!x)
        .forEach(({ path, object }) => insertIntoTree(trees, path, object));
    if (shouldSort) {
        sortTrees(trees);
    }
    return trees;
}
/** Get the leaf measure category from a category string. */
const getMeasurementCategory = (value) => {
    var _a;
    return (_a = parseQuotedStrings([value])[0]) === null || _a === void 0 ? void 0 : _a.split('(')[0].trim();
};
/** Parses airtable quoted strings. */
const parseQuotedStrings = (quotedStrings) => {
    const results = [];
    for (const quotedString of quotedStrings) {
        // This regex aims to capture anything along the lines of
        // `Measurement (Cat1, Cat2, Cat3)`
        // The categories at the end are optional and the first measurement
        // can include anything except a quote and a comma.
        const measurementRegex = /([^,"]+?(?:\([^)]+?\))?)\s*"?(?:,|$)/g;
        let match = measurementRegex.exec(quotedString);
        while (match !== null) {
            results.push(match[1].trim());
            match = measurementRegex.exec(quotedString);
        }
    }
    return results;
};
/**
 * Accepts a list of strings like "REM (Sleep)", "Intensity (Activity)"
 * and generates a hierarchy (along with deduplicating, grouping, etc.).
 */
const getMeasurementHierarchy = (measurementsStrings, shouldSort) => {
    const trees = getMeasurementHierarchyObjects(parseQuotedStrings(measurementsStrings), (x) => x, shouldSort);
    return trees.map((x) => mapTree((y) => y.path, x));
};
/**
 * Try to infer the fullName property from partial names.
 * @param tree
 * @param currentPath
 * @returns
 */
const inferFullName = (tree, currentPath) => {
    if (tree.value.fullName === undefined) {
        if (currentPath.length) {
            tree.value.fullName = `${tree.value.measure} (${currentPath.join(', ')})`;
        }
        else {
            tree.value.fullName = tree.value.measure;
        }
    }
    for (const child of tree.children) {
        inferFullName(child, [tree.value.measure, ...currentPath]);
    }
    return tree;
};
/**
 * Similar to getMeasurementHierarchy, but instead of returning strings will return
 * OntologyTreeValues.
 *
 * NOTE: If we encounter a node that _only_ exists as an intermediate node, fullName
 * will be inferred. This can happen if you only pass in ['Foo (Bar)'] without
 * specify 'Bar'. Inference is built by appending each parent in parentheses.
 * @param measurementsStrings
 * @param shouldSort
 * @returns
 */
const getMeasurementHierarchyOntologyValue = (measurementsStrings, shouldSort) => {
    const trees = getMeasurementHierarchyObjects(parseQuotedStrings(measurementsStrings), (x) => x, shouldSort);
    return trees.map((x) => {
        const mappedTree = mapTree((y) => {
            var _a;
            return ({
                measure: y.path,
                fullName: (_a = y.object) !== null && _a !== void 0 ? _a : undefined,
            });
        }, x);
        return inferFullName(mappedTree, []);
    });
};
/** Serializes a path into a Measurement string (that can later be reparsed into the path). */
const serializePath = (path) => {
    var _a;
    // If there's a single item we'll just use that item.
    if (path.length <= 1) {
        return (_a = path[0]) !== null && _a !== void 0 ? _a : '';
    }
    // We have more than one item. So we reverse it, take the last one and
    // encode the rest surrounded by parentheses
    const elements = [...path].reverse();
    return `${elements[0]} (${elements.slice(1).join(', ')})`;
};
/** Given a measurement hierarchy, serialize to the minimal set of strings. */
const serializeMeasurementHierarchy = (hierarchy) => {
    const leafsPaths = hierarchy.flatMap(getAllLeafPaths);
    return leafsPaths.map(serializePath);
};
export { getMeasurementHierarchyOntologyValue, getMeasurementHierarchy, getMeasurementCategory, parseQuotedStrings, getPathFromString, serializeMeasurementHierarchy, };
