import { __awaiter } from "tslib";
import { WorkspaceStatus } from '@humanfirst/api-models';
import { Block, Stack, toaster, Typography } from '@humanfirst/elektron';
import { useElektraApiMutation, WorkspaceResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ButtonWithConfirmation } from 'src/components/ActionsWithConfirmation';
import { PATHS } from 'src/config/path';
/**
 * Hook to archive a workspace.
 *
 * Will archive, show a toast, and navigate back to the main workspaces.
 */
const useArchiveWorkspace = (workspace) => {
    const { mutateAsync } = useElektraApiMutation(WorkspaceResource.update(workspace.id));
    const navigate = useNavigate();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield mutateAsync({ status: WorkspaceStatus.Archived });
        toaster.info(`Archived Project: ${workspace.name}`);
        navigate(PATHS.projects.list);
    }), [mutateAsync, workspace.name, navigate]);
};
/**
 * Shows the Archive Workspace edit section.
 */
export const ArchiveWorkspace = ({ workspace, }) => {
    const doArchive = useArchiveWorkspace(workspace);
    return (React.createElement(Stack, { maxWidth: "600px" },
        React.createElement(Typography, null, "Archive your project. All of your data will be preserved, but it will no longer appear in your projects list or when adding to a project. You can unarchive a project at any time."),
        React.createElement(Block, null,
            React.createElement(ButtonWithConfirmation, { onSubmit: doArchive, title: "Are you sure you want to archive this project?", body: "This project will no longer appear in your project list.", submitText: "Archive" }, "Archive"))));
};
