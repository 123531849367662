import { __rest } from "tslib";
import { Center, DataTable, Stack, useDataTableState, } from '@humanfirst/elektron';
import React from 'react';
import { StudyResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { useToggle } from 'src/hooks/toggle';
import { ConnectOptionsButton } from 'src/components/Connect/ConnectOptionsButton';
import { useCustomizedSchema } from '../hooks/use-customized-schema';
import { useDataTableUrlMemory } from '../hooks/use-data-table-url-memory';
import { useColumns } from './columns';
import { useStudiesWithRelationships } from './utils/useStudiesWithRelationships';
import { FILTERS } from './filters';
import { SelectionOptions } from './components/SelectionOptions';
const EmptyComponent = () => {
    return React.createElement(Center, null, "No studies");
};
/** Generates the schema for a study table. */
const useBaseSchema = () => {
    const configurableColumns = useToggle('custom-columns');
    return {
        columns: useColumns(),
        configurableColumns,
        filters: FILTERS,
        selectable: true,
    };
};
/** Schema for the simplified variant of the study table. */
const useSimpleSchema = (hasActions) => {
    return {
        columns: useColumns(true),
        searchable: false,
        selectable: hasActions,
    };
};
/**
 * A standard study table.
 *
 * Accepts the data in the form of studies and will attach all relevant metadata.
 */
const StudyTable = (_a) => {
    var { data, extraActions } = _a, props = __rest(_a, ["data", "extraActions"]);
    const extendedData = useStudiesWithRelationships(data);
    const schema = useBaseSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, extendedData);
    return (React.createElement(DataTable, Object.assign({ postSelectionActions: React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(SelectionOptions, null),
            extraActions), itemType: 'studies', emptyElement: React.createElement(EmptyComponent, null) }, result)));
};
/**
 * A simplified study table.
 *
 * Will still fetch additional metadata, so this is not quite as "simple" as some of the
 * other variants.
 */
const StudyTableSimple = (_a) => {
    var { data, extraActions } = _a, props = __rest(_a, ["data", "extraActions"]);
    const extendedData = useStudiesWithRelationships(data);
    const schema = useSimpleSchema(!!extraActions);
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableState(finalSchema, extendedData);
    return (React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: extraActions, emptyElement: React.createElement(EmptyComponent, null) })));
};
/**
 * A scoped study table.
 *
 * Will fetch and display studies based on passed scopes.
 *
 * Note that this is different than other scoped tables because it does not use Algolia
 * as the backing store. Instead we'll load all of the studies and then apply scopes
 * as part of the local state. This has to be applied before we start entering the data table
 * hooks which is a bit odd, but will mostly work.
 */
const ScopedStudyTable = (_a) => {
    var { scope, extraActions } = _a, props = __rest(_a, ["scope", "extraActions"]);
    const { data: allStudies, isLoading } = useElektraApi(StudyResource.list());
    const extendedData = useStudiesWithRelationships(allStudies !== null && allStudies !== void 0 ? allStudies : [], scope);
    const schema = useBaseSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, extendedData);
    return (React.createElement(DataTable, Object.assign({ isLoading: isLoading, postSelectionActions: React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(SelectionOptions, null),
            extraActions,
            React.createElement(ConnectOptionsButton, null)), emptyElement: React.createElement(EmptyComponent, null) }, result)));
};
export { StudyTable, StudyTableSimple, ScopedStudyTable };
