/**
 * Adds a prefix to a colletion of routes.
 * This should be used when generating nested path objcets. If you want to
 * add a context to a specific path use `addPathContext`
 */
function addPathPrefix(prefix, paths) {
    const castPaths = paths;
    for (const key of Object.keys(castPaths)) {
        const element = castPaths[key];
        if (typeof element === 'string') {
            castPaths[key] = prefix + castPaths[key];
        }
        else {
            castPaths[key] = addPathPrefix(prefix, element);
        }
    }
    return paths;
}
/** Common path patterns when doing both list and details endpoints. */
const LIST_AND_DETAILS = {
    list: '',
    detail: '/:id',
};
const PATHS = {
    authentication: {
        forgotPassword: addPathPrefix('/forgot-password', {
            request: '',
            success: '/success',
        }),
        signup: addPathPrefix('/signup', {
            request: '',
            success: '/success',
        }),
        resetPassword: '/reset-password',
        activate: '/activate',
        login: addPathPrefix('/login', {
            request: '',
            ssoCallback: '/sso-callback',
        }),
        logout: '/logout',
        mfa: '/mfa',
        invitation: '/invitation',
    },
    admin: addPathPrefix('/admin', {
        reports: addPathPrefix('/reports', {
            list: '',
            create: '/create',
            edit: '/:id/edit/',
            previewTable: '/:id/preview/:tableIndex',
            preview: '/:id/preview/0',
        }),
        teams: addPathPrefix('/admin-teams', {
            list: '',
            create: '/create',
            detail: '/:id',
            edit: '/:id/edit',
        }),
        roles: addPathPrefix('/roles', {
            list: '',
            create: '/create',
            detail: '/:id',
            edit: '/:id/edit',
        }),
        projects: addPathPrefix('/admin-projects', {
            list: '',
            create: '/create',
            detail: '/:id',
            edit: '/:id/edit',
        }),
        studies: addPathPrefix('/admin-studies', {
            list: '',
            create: '/create',
            detail: '/:id',
            edit: '/:id/edit',
        }),
        users: addPathPrefix('/users', {
            list: '',
            create: '/create',
            edit: '/:id/edit',
        }),
        utilities: '/utilities',
    }),
    therapeuticAreas: addPathPrefix('/therapeutic-areas', {
        list: '',
        detail: '/:id',
    }),
    search: addPathPrefix('/search', {
        technology: addPathPrefix('/technology', {
            index: '',
            results: '/results',
            complete: '/complete',
        }),
        evidence: addPathPrefix('/evidence', {
            index: '',
            results: '/results',
        }),
    }),
    catalog: addPathPrefix('/catalog', {
        studies: addPathPrefix('/studies', {
            index: '',
            create: '/create',
            view: addPathPrefix('/:id', {
                index: '',
                edit: '/edit',
            }),
        }),
        technologies: addPathPrefix('/technologies', {
            index: '',
        }),
    }),
    dashboard: '/dashboard',
    teams: addPathPrefix('/teams', {
        list: '',
        detail: '/:id',
    }),
    compare: '/compare',
    products: '/maker/:makerId/products',
    settings: '/settings',
    projects: addPathPrefix('/projects', {
        list: '',
        create: '/create',
        detail: '/:projectId',
        edit: '/:projectId/edit',
        compare: '/:projectId/compare',
        reports: addPathPrefix('/:projectId/reports', {
            list: '',
            view: '/:id/0',
            viewTable: '/:id/:tableIndex',
        }),
        lists: addPathPrefix('/:projectId/lists', {
            view: '/:listName',
        }),
    }),
    contactForm: '/contact',
    helpCenter: {
        index: 'https://help.gohumanfirst.com/',
        usingAtlas: {
            createProject: 'https://help.gohumanfirst.com/en/articles/7436255-projects-sharing-pages-and-subscribing-to-updates#h_5a1e4cf034',
            gettingStarted: {
                searchAndBrowse: 'https://help.gohumanfirst.com/en/articles/6646783-search-and-browse-atlas',
                filters: 'https://help.gohumanfirst.com/en/articles/6659431-how-to-use-filters',
            },
            measures: {
                ontologies: 'https://help.gohumanfirst.com/en/articles/8410295-what-why-and-how-of-atlas-measure-ontologies',
            },
            technologies: {
                exploreOverview: 'https://help.gohumanfirst.com/en/articles/6666718-explore-technologies-overview',
                outOfScopeTechnologies: 'https://help.gohumanfirst.com/en/articles/6659407-out-of-scope-technologies',
            },
            measuresAndEvidence: {
                evidenceIngestion: 'https://help.gohumanfirst.com/en/articles/6663090-evidence-ingestion',
                exploreOverview: 'https://help.gohumanfirst.com/en/articles/6663064-explore-measures-overview',
            },
            medicalConditions: {
                exploreOverview: 'https://help.gohumanfirst.com/en/articles/6669320-explore-medical-conditions-overview',
            },
            vendors: {
                exploreOverview: 'https://help.gohumanfirst.com/en/articles/6669331-explore-vendors-overview',
                vendorTypes: 'https://help.gohumanfirst.com/en/articles/6793290-vendor-types',
            },
            advancedFeatures: {
                additionalOptions: 'https://help.gohumanfirst.com/en/articles/6669511-additional-options',
                evaluateAndCompareTechnologies: 'https://help.gohumanfirst.com/en/articles/6647883-evaluate-and-compare-technologies',
            },
        },
        troubleShooting: {
            zeroSearchResults: 'https://help.gohumanfirst.com/en/articles/6647916-when-search-returns-0-results',
        },
    },
    // New paths for IA rework
    atlas: addPathPrefix('/atlas', {
        index: '',
        technologies: addPathPrefix('/technologies', Object.assign({}, LIST_AND_DETAILS)),
        medicalConditions: addPathPrefix('/medical-conditions', Object.assign({}, LIST_AND_DETAILS)),
        vendors: addPathPrefix('/vendors', Object.assign({}, LIST_AND_DETAILS)),
        measures: addPathPrefix('/measures', Object.assign({}, LIST_AND_DETAILS)),
        measuresName: '/measures-name/:name',
        evidence: addPathPrefix('/evidence', Object.assign({}, LIST_AND_DETAILS)),
    }),
};
export { PATHS };
