import React from 'react';
import { COLORS, Icons } from '@humanfirst/elektron';
import { useComments } from './hooks/comments';
const WorkspaceResourceCommentsIcon = ({ workspaceId, resourceType, resource, size }) => {
    const { data: comments } = useComments(workspaceId, resourceType, resource);
    if (!!(comments === null || comments === void 0 ? void 0 : comments.length)) {
        return (React.createElement(Icons.Misc.Chat, { color1: COLORS.gray1, color2: COLORS.gray1, title: "Comments", size: size }));
    }
    return React.createElement(Icons.Misc.Chat, { color1: COLORS.gray1, title: "Comments", size: size });
};
export { WorkspaceResourceCommentsIcon };
