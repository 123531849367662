import { FREE_SEARCHABLE_MEASURES, FREE_SEARCHABLE_MEDICAL_CONDITIONS, } from 'src/constants/search';
import { startCase } from 'src/utils/text';
/** Gets the type of the option as a human readable string. */
export const getTypeDisplay = (option) => {
    switch (option.type) {
        case 'component':
            return 'Technology';
        case 'medicalCondition':
            return 'Medical Condition';
        case 'searchAll':
            return `Search All ${startCase(option.id)}`;
        default:
            return startCase(option.type);
    }
};
/** Gets the raw name from an option. */
export const getQuery = (option) => option.query;
// This is temporary for the new omni search
export const getModifiedOptions = ({ options, canSearchMeasures, canSearchMedicalConditions, }) => {
    return options
        .map((option) => {
        if (option.type === 'measure') {
            return Object.assign(Object.assign({}, option), { locked: !canSearchMeasures });
        }
        if (option.type === 'medicalCondition') {
            return Object.assign(Object.assign({}, option), { locked: !canSearchMedicalConditions });
        }
        return Object.assign(Object.assign({}, option), { disabled: false });
    })
        .sort((a, b) => {
        if (a.locked && !b.locked) {
            return 1;
        }
        if (!a.locked && b.locked) {
            return -1;
        }
        return 0;
    });
};
export const getLimitedOptions = ({ options, canSearchMedicalConditions, canSearchMeasures, }) => {
    return options
        .map((option) => {
        if (option.type === 'measure') {
            return Object.assign(Object.assign({}, option), { disabled: canSearchMeasures
                    ? false
                    : !FREE_SEARCHABLE_MEASURES.includes(`"${option.query}"`) });
        }
        if (option.type === 'medicalCondition') {
            return Object.assign(Object.assign({}, option), { disabled: canSearchMedicalConditions
                    ? false
                    : !FREE_SEARCHABLE_MEDICAL_CONDITIONS.includes(`"${option.query}"`) });
        }
        // All make and model searches should be available for everyone.
        return Object.assign(Object.assign({}, option), { disabled: false });
    })
        .sort((a, b) => {
        if (a.disabled && !b.disabled) {
            return 1;
        }
        if (!a.disabled && b.disabled) {
            return -1;
        }
        return 0;
    });
};
