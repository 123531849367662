import { Block, Button, Center, COLORS, Icons, Spinner, Stack, Typography, TypographyVariant, StyledDropdownListItem, } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { t } from 'src/constants/i18n';
import { OmnisearchNoResultsMessage } from './OmnisearchNoResultsMessage';
const RecentSearches = ({ recentSearches, clearRecentSearches, setQuery, }) => {
    if (!recentSearches || recentSearches.length === 0) {
        return null;
    }
    return (React.createElement(Block, { width: "100%", 
        // Note that the default empty component has some styles we want to ignore here.
        overrides: { Block: { style: { cursor: 'auto', textAlign: 'left' } } }, paddingLeft: "24px", paddingRight: "24px", paddingTop: "16px", paddingBottom: "16px" },
        React.createElement(Block, { display: "flex", alignItems: 'center' },
            React.createElement(Block, { flex: "1" },
                React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.BodyBold, textTransform: "uppercase" }, "Recent Searches")),
            React.createElement(Block, null,
                React.createElement(Button, { variant: "minimal", size: "compact", onClick: clearRecentSearches }, t('actions.clear')))),
        React.createElement(Stack, null, recentSearches.map((search, i) => {
            return (
            // Even though these aren't real dropdown items, try to get them as close
            // as possible.
            React.createElement(StyledDropdownListItem, { "$as": "div", 
                // eslint-disable-next-line react/no-array-index-key
                key: i, onClick: (e) => {
                    // These events by default can close the pop up, so we stop propagation
                    // and default actions.
                    e.preventDefault();
                    e.stopPropagation();
                    setQuery(search);
                } },
                React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                    React.createElement(Icons.Misc.Clock, { size: "24px", title: "Recent Search" }),
                    React.createElement(Typography, null, search))));
        }))));
};
/**
 *
 * OmnisearchEmptyState will render when there are no results to show.
 * This can be due to one of three states.
 *
 * 1. Data is not available because the data is loading. Show a spinner.
 * 2. Data is not available because the search query returned 0 results. Show not found UI.
 * 3. Data is not available because a search hasn't been made. Show recent searches or example searches.
 *
 */
const OmnisearchEmptyState = ({ hasQuery, isLoading, recentSearches, clearRecentSearches, setQuery, }) => {
    if (isLoading) {
        return (React.createElement(Center, { padding: "32px" },
            React.createElement(Spinner, null)));
    }
    return (React.createElement(If, { condition: hasQuery },
        React.createElement(Then, null,
            React.createElement(OmnisearchNoResultsMessage, null)),
        React.createElement(Else, null,
            React.createElement(RecentSearches, { recentSearches: recentSearches, clearRecentSearches: clearRecentSearches, setQuery: setQuery }))));
};
export { OmnisearchEmptyState };
