import React from 'react';
import { Link, Stack } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
const LinkWithIcon = ({ href, title, icon, iconPosition = 'end', inline = false, }) => {
    return (React.createElement(Link, { href: href },
        React.createElement(Stack, { direction: "horizontal", gap: "8px", alignItems: "center", display: inline ? 'inline-flex' : 'flex' },
            React.createElement(If, { condition: iconPosition === 'start' },
                React.createElement(Then, null,
                    icon,
                    title),
                React.createElement(Else, null,
                    title,
                    icon)))));
};
export { LinkWithIcon };
