import { __awaiter } from "tslib";
import { Block, CardSurface, Divider, FormControl, Link, Radio, Spacer, Stack, } from '@humanfirst/elektron';
import React, { useCallback, useContext } from 'react';
import { When } from 'react-if';
import { SmartForm } from 'src/components/SmartForm';
import { SmartRadioGroup } from 'src/components/SmartForm/fields';
import { PATHS } from 'src/config/path';
import { UserContext } from 'src/contexts/UserContext';
import { useIsShadowing, useStopShadowing } from 'src/hooks/authentication';
import { useToggle } from 'src/hooks/toggle';
import { useUser } from 'src/hooks/user';
import { generatePath } from 'src/utils/path';
import { PermissionsByRoles } from './permissionsByRole';
const AccessLevel = () => {
    const { setPermissionOverrides } = useContext(UserContext);
    const hasAccessLevelToggle = useToggle('show-access-levels');
    const onSubmit = useCallback((data) => {
        const permissionsToApply = PermissionsByRoles === null || PermissionsByRoles === void 0 ? void 0 : PermissionsByRoles.filter((x) => x.name === data.accessLevel).flatMap((y) => y.grants);
        setPermissionOverrides === null || setPermissionOverrides === void 0 ? void 0 : setPermissionOverrides(permissionsToApply !== null && permissionsToApply !== void 0 ? permissionsToApply : []);
    }, [setPermissionOverrides]);
    if (!hasAccessLevelToggle) {
        return null;
    }
    return (React.createElement(Block, { padding: '0 24px 24px 24px' },
        React.createElement(Divider, null),
        React.createElement(SmartForm, { onSubmit: onSubmit, submitText: 'Update Access' },
            React.createElement(FormControl, null,
                React.createElement(SmartRadioGroup, { name: `accessLevel` },
                    React.createElement(Radio, { value: 'OpenAtlas' }, "OpenAtlas"),
                    React.createElement(Radio, { value: 'AtlasPro' }, "AtlasPro"),
                    React.createElement(Radio, { value: 'AtlasEnterprise' }, "AtlasEnterprise"))))));
};
export const UserMenu = ({ close }) => {
    const isShadowing = useIsShadowing();
    const stopShadowing = useStopShadowing();
    const onClickStopShadowing = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield stopShadowing();
        close();
    }), [close, stopShadowing]);
    const user = useUser();
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { margin: "20px", width: "160px" },
            React.createElement(Spacer, { size: "24px" }),
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Link, { onClick: close, href: generatePath(PATHS.settings) }, "Account Settings"),
                React.createElement(When, { condition: (user === null || user === void 0 ? void 0 : user.teams) && user.teams.length > 0 },
                    React.createElement(Link, { onClick: close, href: generatePath(PATHS.teams.list) }, "My Teams")),
                isShadowing ? (React.createElement(Link, { href: generatePath(PATHS.admin.utilities), onClick: onClickStopShadowing }, "Stop Shadowing")) : (React.createElement(Link, { onClick: close, href: generatePath(PATHS.authentication.logout) }, "Log out")))),
        React.createElement(AccessLevel, null)));
};
