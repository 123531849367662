import { Stack, Icons, COLORS, Typography, TypographyVariant, Card, } from '@humanfirst/elektron';
import React from 'react';
import { useNavigate } from 'react-router';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { SEARCH_RESOURCES } from 'src/utils/resourceType';
const constructSavedItemsList = (bookmarks) => {
    const measures = `${bookmarks.filter((x) => x.resourceType === 'Measure').length} measures`;
    const technologies = `${bookmarks.filter((x) => x.resourceType === 'Tool').length} technologies`;
    const evidence = `${bookmarks.filter((x) => x.resourceType === 'EvidenceSource').length} evidence`;
    const vendors = `${bookmarks.filter((x) => x.resourceType === 'Vendor').length} vendors`;
    const searches = `${bookmarks.filter((x) => SEARCH_RESOURCES.has(x.resourceType)).length} searches`;
    const studies = `${bookmarks.filter((x) => x.resourceType === 'Study').length} studies`;
    const savedItems = [
        measures,
        technologies,
        evidence,
        vendors,
        searches,
        studies,
    ].filter((x) => !x.startsWith('0'));
    return savedItems.join(' • ');
};
const CardHeader = ({ listName, icon, }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.Heading3Bold },
        React.createElement(Stack, { direction: 'horizontal' },
            icon,
            listName)));
};
const getHeaderIcon = (listName) => {
    if (listName === 'Archived') {
        return React.createElement(Icons.Misc.Archive, { decorative: true, color: COLORS.black });
    }
    if (listName === 'All Saved') {
        return React.createElement(Icons.Misc.Save, { decorative: true, color: COLORS.black });
    }
    return null;
};
const ListCard = ({ bookmarks, listName, projectId }) => {
    const bookmarksByType = constructSavedItemsList(bookmarks);
    const navigate = useNavigate();
    if (!bookmarksByType.length) {
        return (React.createElement(Card, { backgroundColor: COLORS.gray4, variant: "secondary" },
            React.createElement(Stack, null,
                React.createElement(CardHeader, { listName: listName, icon: getHeaderIcon(listName) }),
                React.createElement(Typography, { color: COLORS.gray1 }, 'No Items'))));
    }
    return (React.createElement(Card, { onClick: () => navigate(generatePath(PATHS.projects.lists.view, {
            projectId: projectId,
            listName: listName,
        })) },
        React.createElement(Stack, null,
            React.createElement(CardHeader, { listName: listName, icon: getHeaderIcon(listName) }),
            React.createElement(Typography, { color: COLORS.gray1 }, bookmarksByType))));
};
export { ListCard };
