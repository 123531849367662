import { Stack, Card, Icons, Typography, COLORS, TypographyVariant, Link, } from '@humanfirst/elektron';
import React from 'react';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
export const ClickableCOI = ({ icon, measure, measureId, }) => {
    const href = generatePath(PATHS.atlas.measures.detail, {
        id: measureId,
    });
    return (React.createElement(Link, { href: href },
        React.createElement(Card, { padding: "8px 24px", variant: 'secondary' },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                icon,
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, measure),
                React.createElement(Icons.Misc.ArrowForward, { decorative: true, color: COLORS.primary })))));
};
export const PopularCOI = () => {
    return (React.createElement(Stack, { gap: '24px' },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Popular Concepts of Interest"),
        React.createElement(Stack, { direction: 'horizontal', gap: '16px' },
            React.createElement(ClickableCOI, { icon: React.createElement(Icons.Misc.Sleep, { decorative: true, size: "24px" }), measure: 'Sleep', measureId: 'recxNlNCxfMuS9ag3' }),
            React.createElement(ClickableCOI, { icon: React.createElement(Icons.Misc.Walking, { decorative: true, size: "24px" }), measure: 'Activity', measureId: 'recxStQY6w8e2RSEj' }))));
};
