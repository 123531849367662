import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { modalSlice } from './modal';
const appReducer = combineReducers({
    modal: modalSlice.reducer,
});
/**
 * Extended reducer with a custom reset action.
 */
const rootReducer = (state, action) => {
    if (action.type === 'reset') {
        return appReducer(undefined, { type: undefined });
    }
    return appReducer(state, action);
};
export const createStore = () => configureStore({
    reducer: rootReducer,
});
/** Root store for the HumanFirst application. */
export const store = createStore();
