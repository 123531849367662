import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'src/hooks/debounce';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { serializeFilters } from 'src/hooks/search/filters';
/**
 * Sends an analytics event whenever a table refinement is updated.
 *
 * Debounced to 1000 ms, with flush on unmount.
 */
const useTrackTableRefinement = (type) => {
    const track = useAnalyticsEvent();
    // Create a debounced callback with the proper tracking.
    const callback = useCallback((q, filters, resultCount) => {
        // Only track if a refinement was made
        if (!!q || !!filters) {
            // This is happening when no refinement has been made....
            track('focused-search', { query: q, filters, resultCount, type });
        }
    }, [track, type]);
    const debouncedCallback = useDebouncedCallback(callback, 1000, true);
    return useCallback((refinement, resultCount) => {
        const search = refinement.search;
        const filters = serializeFilters(refinement.filters);
        if (!!search || !!filters) {
            debouncedCallback(search, filters, resultCount);
        }
    }, [debouncedCallback]);
};
/** Tracks refinement/searches to a data table. */
const useTrackDataTableRefinement = (type, result) => {
    var _a, _b, _c, _d, _e;
    const track = useTrackTableRefinement(type);
    const search = ((_a = result.search) === null || _a === void 0 ? void 0 : _a.enable) ? (_b = result.search) === null || _b === void 0 ? void 0 : _b.search : '';
    const totalCount = ((_c = result.pagination) === null || _c === void 0 ? void 0 : _c.enable)
        ? (_d = result.pagination) === null || _d === void 0 ? void 0 : _d.totalCount
        : 0;
    const filters = ((_e = result.filters) === null || _e === void 0 ? void 0 : _e.enable) ? result.filters.filters : undefined;
    useEffect(() => {
        track({ search, filters: filters !== null && filters !== void 0 ? filters : [] }, totalCount);
    }, [search, filters, totalCount, track]);
};
export { useTrackTableRefinement, useTrackDataTableRefinement };
