/** Action creator for ApplySearchAction. */
const applyMeasuresAction = (measures) => ({
    type: 'applyMeasures',
    measures,
});
/** Action creator for ClearSearchAction. */
const clearMeasuresAction = () => ({
    type: 'clearMeasures',
});
export { applyMeasuresAction, clearMeasuresAction, };
