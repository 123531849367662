import React from 'react';
import { COLORS } from '@humanfirst/elektron';
const GRADIENTS = [
    { id: 'primary', color: COLORS.primary },
    { id: 'blue4', color: COLORS.blue4 },
    { id: 'secondary', color: COLORS.secondary },
    { id: 'blue3', color: COLORS.blue3 },
    { id: 'tertiary', color: COLORS.tertiary },
];
/** This is the set of svg linear gradient definitions. These are used to apply styles to an individual bar */
const GradientDefinitions = () => {
    return (React.createElement("defs", null, GRADIENTS.map((gradient) => (React.createElement("linearGradient", { key: gradient.id, id: gradient.id, spreadMethod: "pad", x1: "10%", x2: "10%", y1: "100%", y2: "0%" },
        React.createElement("stop", { offset: "0%", stopOpacity: "0.3", stopColor: gradient.color }),
        React.createElement("stop", { offset: "80%", stopOpacity: "1", stopColor: gradient.color }))))));
};
export { GradientDefinitions, GRADIENTS };
