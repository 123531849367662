import { SelectContext, Stack } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { When } from 'react-if';
import { AddToWorkspaceBulkButton } from 'src/components/AddToWorkspace';
import { CompareButton } from './CompareButton';
export const SelectionActions = ({ hasSaveToWorkspace = false }) => {
    const { selection } = useContext(SelectContext);
    return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", justifyContent: 'end' },
        React.createElement(CompareButton, null),
        React.createElement(When, { condition: hasSaveToWorkspace },
            React.createElement(AddToWorkspaceBulkButton, { resourceIds: selection, resourceType: 'Tool' }))));
};
