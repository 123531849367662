export const getEvidenceBucketCategory = (count) => {
    if (!count || count <= 5) {
        return [0, 5];
    }
    if (count <= 10) {
        return [6, 10];
    }
    if (count <= 50) {
        return [11, 50];
    }
    if (count <= 100) {
        return [51, 100];
    }
    return [101, Infinity];
};
export const getEvidenceBucket = (count) => {
    const [minVal, maxVal] = getEvidenceBucketCategory(count);
    if (maxVal === Infinity) {
        return '101+';
    }
    return `${minVal}-${maxVal}`;
};
export const defaultEvidenceBuckets = [
    [5, Infinity],
    [10, Infinity],
    [50, Infinity],
    [100, Infinity],
];
