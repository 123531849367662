import React, { useCallback, useState, createContext } from 'react';
import Confetti from 'react-confetti';
import { Block, COLORS } from '@humanfirst/elektron';
/** Context for handling App wide celebration moments */
const CelebrationContext = createContext({
    celebrate: () => undefined,
});
/**
 * Provides a global celebrations.
 *
 * These show a stream of confetti to help the user connect
 * with the moment.
 */
const CelebrationProvider = ({ children, }) => {
    const [showConfetti, setShowConfetti] = useState(false);
    const celebrate = useCallback(() => setShowConfetti(true), [setShowConfetti]);
    return (React.createElement(CelebrationContext.Provider, { value: { celebrate } },
        showConfetti ? (React.createElement(Block, { position: "fixed" },
            React.createElement(Confetti, { recycle: false, onConfettiComplete: () => setShowConfetti(false), colors: [COLORS.primary, COLORS.tertiary] }))) : null,
        children));
};
export { CelebrationProvider, CelebrationContext };
