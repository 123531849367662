import { Heading, Spacer, Stack } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { ScopedToolTable } from 'src/components/ScopedToolTable';
import { UpgradeBanner } from 'src/components/UpgradeBanner';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PATHS } from 'src/config/path';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { useShouldSeeUpgradeBanner } from '../MedicalCondition/util';
const ComponentList = () => {
    const shouldSeeUpgradeBanner = useShouldSeeUpgradeBanner();
    const { technologyTableScopes } = useGetConnectInstrumentScopes();
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Atlas, BREADCRUMBS.Technologies] },
        React.createElement(PageTitle, { title: "Technologies" }),
        React.createElement(When, { condition: shouldSeeUpgradeBanner },
            React.createElement(UpgradeBanner, { title: 'Make Evidence Based Decisions', body: 'Get in touch with our team to validate these technologies with peer-reviewed evidence.', actionLabel: 'Request Access' }),
            React.createElement(Spacer, { size: "32px" })),
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Heading, null, "Explore Technologies"),
            React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.technologies.exploreOverview })),
        React.createElement(ScopedToolTable, { scope: technologyTableScopes })));
};
export { ComponentList };
