import React, { useCallback, useContext, useEffect, useRef, useState, createContext, useMemo, } from 'react';
import { useLocation } from 'react-router-dom';
import { useElektraApi, AuthResource } from '@humanfirst/use-elektra-api';
import { COLORS } from '@humanfirst/elektron';
import { getCurrentEnvironement } from '../utils/environment';
const INTERCOM_APP_ID = 'lgqdyt6m';
const DEFAULT_USER_OPTIONS = {
    user_id: null,
    email: null,
    name: null,
    user_hash: null,
};
const DEFAULT_MESSENGER_OPTIONS = {
    action_color: COLORS.tertiary,
    background_color: COLORS.primary,
};
const NOOP_CLIENT = () => undefined;
const isSameUser = (a, b) => {
    const { user_id: userIdA } = a;
    const { user_id: userIdB } = b;
    return (!!userIdA || !!userIdB) && userIdA === userIdB;
};
const useIntercomClient = (appId) => {
    const unmountedRef = useRef(false);
    useEffect(() => {
        unmountedRef.current = false;
        return () => {
            unmountedRef.current = true;
        };
    }, []);
    const [intercomClient, setIntercomClient] = useState(() => NOOP_CLIENT);
    useEffect(() => {
        const env = getCurrentEnvironement();
        if (env === 'local' || env === 'e2e') {
            // Don't start intercom locally since it will fail because of a lack
            // of user hashing.
            return;
        }
        const scriptElement = document.createElement('script');
        scriptElement.async = true;
        scriptElement.src = `https://widget.intercom.io/widget/${encodeURIComponent(appId)}`;
        scriptElement.onload = () => {
            if (unmountedRef.current) {
                return;
            }
            setIntercomClient((client) => {
                client('shutdown');
                return (method, ...args) => {
                    const intercom = window.Intercom || NOOP_CLIENT;
                    return intercom.apply(null, [method, ...args]);
                };
            });
        };
        document.body.appendChild(scriptElement);
        return () => {
            // Clean up if we get unmounted
            setIntercomClient((client) => {
                // Shutdown the previous client
                client('shutdown');
                return NOOP_CLIENT;
            });
            scriptElement.onerror = null;
            scriptElement.onload = null;
            document.body.removeChild(scriptElement);
        };
    }, [appId]);
    return intercomClient;
};
const IntercomContext = createContext({
    update: () => undefined,
    showNewMessage: () => undefined,
    show: () => undefined,
    hide: () => undefined,
    startTour: () => undefined,
    queueTour: () => undefined,
    unreadCount: 0,
    isVisible: false,
});
const useIntercom = () => useContext(IntercomContext);
function bindEvents(client, setIsVisible, setUnreadCount) {
    client('onShow', () => {
        setIsVisible(true);
    });
    client('onHide', () => {
        setIsVisible(false);
    });
    client('onUnreadCountChange', (unreadCount) => {
        setUnreadCount(unreadCount);
    });
}
const IntercomProvider = ({ appId = INTERCOM_APP_ID, user = DEFAULT_USER_OPTIONS, messenger = DEFAULT_MESSENGER_OPTIONS, children, }) => {
    // Maybe try pulling user info?
    const intercomClient = useIntercomClient(appId);
    const intercomOptionsRef = useRef({});
    const [unreadCount, setUnreadCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [queuedTours, setQueuedTours] = useState([]);
    const update = useCallback((options) => {
        if (!isSameUser(options, intercomOptionsRef.current)) {
            // If the user changes, shutdown & boot
            intercomClient('shutdown');
            intercomClient('boot', Object.assign(Object.assign({ hide_default_launcher: false }, options), { app_id: appId }));
            bindEvents(intercomClient, setIsVisible, setUnreadCount);
        }
        else {
            intercomClient('update', options);
        }
        intercomOptionsRef.current = options;
    }, [intercomClient, appId]);
    const show = useCallback(() => {
        intercomClient('show');
    }, [intercomClient]);
    const hide = useCallback(() => {
        intercomClient('hide');
    }, [intercomClient]);
    const showNewMessage = useCallback((message) => {
        intercomClient('showNewMessage', message);
    }, [intercomClient]);
    const startTour = useCallback((tourId) => {
        intercomClient('startTour', tourId);
    }, [intercomClient]);
    const queueTour = useCallback((tour) => {
        setQueuedTours((old) => {
            if (!old.find((x) => x.id === tour.id)) {
                return [...old, tour];
            }
            else {
                return old;
            }
        });
    }, [setQueuedTours]);
    useEffect(() => {
        // If the intercom client changes then we should force
        // the client to shutdown and re-boot
        intercomClient('shutdown');
        intercomClient('boot', Object.assign(Object.assign({ hide_default_launcher: false }, intercomOptionsRef.current), { app_id: appId }));
        bindEvents(intercomClient, setIsVisible, setUnreadCount);
    }, [intercomClient, appId]);
    const { pathname } = useLocation();
    useEffect(() => {
        // Calling update will push the current path to the server
        update(intercomOptionsRef.current);
    }, [pathname, update]);
    // Trigger any tours that match the current path.
    useEffect(() => {
        for (const tour of queuedTours) {
            if (pathname === tour.path) {
                intercomClient('startTour', tour.id);
                setQueuedTours((old) => old.filter((x) => x !== tour));
                return;
            }
        }
    }, [pathname, queuedTours, setQueuedTours, intercomClient]);
    useEffect(() => {
        update(Object.assign({}, user, messenger));
    }, [update, user, messenger]);
    const value = useMemo(() => ({
        update,
        showNewMessage,
        startTour,
        show,
        hide,
        unreadCount,
        isVisible,
        queueTour,
    }), [
        update,
        showNewMessage,
        startTour,
        show,
        hide,
        unreadCount,
        isVisible,
        queueTour,
    ]);
    return (React.createElement(IntercomContext.Provider, { value: value }, children));
};
const IntercomProviderWithUser = ({ appId = INTERCOM_APP_ID, messenger = DEFAULT_MESSENGER_OPTIONS, children, }) => {
    const { data } = useElektraApi(AuthResource.getIntercom(), {
        staleTime: Infinity,
    });
    return (React.createElement(IntercomProvider, { appId: appId, user: data, messenger: messenger }, children));
};
export { IntercomContext, useIntercom, IntercomProvider, IntercomProviderWithUser, };
