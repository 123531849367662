import { Stack, TabPanel, TabList, Tab, Spacer } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useSearchParams } from 'react-router-dom';
import { ContentContainer } from 'src/components/ContentContainer';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PageTitle } from 'src/components/PageTitle';
import { useClearSearchStateUrl } from 'src/components/tables/hooks/use-search-state-url';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { ToolCards } from './components/ToolCards';
import { PartiallyIngestedToolCard } from './components/PartiallyIngestedToolCard';
import { PrimaryCard } from './components/PrimaryCard';
import { ToolBanners } from './components/ToolBanners';
import { ProductEvidenceContainer } from './components/ProductEvidence';
import { isPartiallyIngested } from './utils/selectors';
import { MakerEditingContextProvider } from './contexts/MakerEditingContext';
import { ConnectToolCards } from './components/Connect/ConnectToolCards';
/**
 * Detailed information for a single tool.
 * This includes the primary cards as well as the linked evidence.
 */
const ToolDetails = ({ tool }) => {
    const hasConnect = useHasPermission('connect', 'read', 'all');
    const clearSearchStateUrl = useClearSearchStateUrl();
    const [, setSearchParams] = useSearchParams();
    const [view, setView] = useQueryParam('view', withDefault(NumberParam, 0));
    const changeTab = useCallback((index) => {
        setView(index);
        clearSearchStateUrl();
    }, [setView, setSearchParams]);
    return (React.createElement(Stack, { gap: "32px" },
        React.createElement(ToolBanners, { tool: tool }),
        React.createElement(When, { condition: isPartiallyIngested(tool) },
            React.createElement(PartiallyIngestedToolCard, { tool: tool })),
        React.createElement(PrimaryCard, { tool: tool }),
        React.createElement(When, { condition: !isPartiallyIngested(tool) },
            React.createElement(When, { condition: hasConnect },
                React.createElement(TabList, { active: view, onChange: changeTab },
                    React.createElement(Tab, null, "Atlas Data"),
                    React.createElement(Tab, null, "My Organization's Data")),
                React.createElement(TabPanel, { index: 0, active: view },
                    React.createElement(ToolCards, { tool: tool }),
                    React.createElement(Spacer, { size: "32px" }),
                    React.createElement(ProductEvidenceContainer, { tool: tool })),
                React.createElement(TabPanel, { index: 1, active: view },
                    React.createElement(ConnectToolCards, { tool: tool }))),
            React.createElement(When, { condition: !hasConnect },
                React.createElement(ToolCards, { tool: tool }),
                React.createElement(ProductEvidenceContainer, { tool: tool })))));
};
/**
 * Page container for the tool page. Sets up the breadcrumbs and page container.
 */
const ToolPageContainer = ({ data: tool }) => {
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Atlas,
            BREADCRUMBS.Technologies,
            BREADCRUMBS.TechnologyDetails(tool),
        ] },
        React.createElement(PageTitle, { title: tool.model }),
        React.createElement(MakerEditingContextProvider, { tool: tool },
            React.createElement(ToolDetails, { tool: tool }))));
};
/**
 * Main connectede entry point for the Tool.
 * Will load the tool from the API based on API path.
 */
const Tool = () => {
    const { id } = useParams();
    const result = useElektraApi(ToolResource.get(id, {
        join: [
            'maker',
            'maker.legalPolicies',
            'maker.securityPolicies',
            'integrations',
            'integrators',
            'vendorStatedIntegrations',
            'vendorStatedMedicalConditions',
            'vendorStatedTherapeuticAreas',
        ],
    }));
    return React.createElement(QueryWrapper, Object.assign({ renderSuccess: ToolPageContainer }, result));
};
export { Tool, ToolDetails };
