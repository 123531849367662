import React from 'react';
import { Typography, Icons, Block, StatefulTooltip } from '@humanfirst/elektron';
const AnchoredTooltip = ({ children, triggerType = 'click' }) => {
    if (typeof children === 'string') {
        children = React.createElement(Typography, null, children);
    }
    return (React.createElement(StatefulTooltip, { placement: "auto", triggerType: triggerType, content: React.createElement(Block, { maxWidth: '400px' }, children) },
        React.createElement(Block, { alignItems: 'center', display: "flex" },
            React.createElement(Icons.Misc.Info, { title: "Info" }))));
};
export { AnchoredTooltip };
