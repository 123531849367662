import { Block, Heading, Stack } from '@humanfirst/elektron';
import { MedicalConditionsResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { ContentContainer } from 'src/components/ContentContainer';
import { MedicalConditionTable } from 'src/components/tables/MedicalConditionTable';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PATHS } from 'src/config/path';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { useHasPermissionsList } from 'src/hooks/permissions/useHasPermissionsList';
const MedicalConditionListInternal = ({ data: conditions, }) => {
    const medicalConditionsPermissionsList = useHasPermissionsList({ resource: 'medicalConditions', action: 'read' }, conditions);
    const medicalConditionsWithPermissions = medicalConditionsPermissionsList.map((x) => (Object.assign(Object.assign({}, x[0]), { access: x[1] })));
    return React.createElement(MedicalConditionTable, { data: medicalConditionsWithPermissions });
};
const MedicalConditionList = () => {
    const result = useElektraApi(MedicalConditionsResource.list());
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Atlas, BREADCRUMBS.MedicalConditions] },
        React.createElement(PageTitle, { title: "Medical Conditions" }),
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Heading, null, "Explore Medical Conditions"),
            React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.medicalConditions.exploreOverview })),
        React.createElement(Stack, { direction: "horizontal", gap: "72px" },
            React.createElement(Block, { flex: "1" },
                React.createElement(QueryWrapper, Object.assign({ renderSuccess: MedicalConditionListInternal }, result))))));
};
export { MedicalConditionList };
