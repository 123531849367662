import { __awaiter } from "tslib";
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
function useHandleSubmit(onSubmit) {
    const { reset, handleSubmit, setError } = useFormContext();
    const onSubmitCallback = useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        if (!onSubmit) {
            return;
        }
        try {
            yield onSubmit(data);
            reset(undefined, { keepValues: true });
        }
        catch (e) {
            const message = (e === null || e === void 0 ? void 0 : e.message) ? e.message : String(e);
            if (setError) {
                setError('root.submitError', { type: 'submitError', message });
            }
        }
    }), [onSubmit, setError, reset]);
    return useMemo(() => handleSubmit(onSubmitCallback), [handleSubmit, onSubmitCallback]);
}
export { useHandleSubmit };
