import { FilterType } from '@humanfirst/elektron';
function isRangeFilterValue(value) {
    if (!Array.isArray(value)) {
        return false;
    }
    if (value.length === 0) {
        return false;
    }
    return value.every((v) => Array.isArray(v) &&
        v.length === 2 &&
        v[0] <= v[1] &&
        (v[0] !== -Infinity || v[1] !== Infinity) &&
        v.every((a) => typeof a === 'number'));
}
const escapeFilterValue = (value) => {
    return `"${String(value).replace(/"/g, '\\"')}"`;
};
const serializeFilter = (filter) => {
    const { fieldId, filterType, filterValue } = filter;
    if (!fieldId) {
        return '';
    }
    switch (filterType) {
        case FilterType.EQUALS:
            return `${fieldId}:${escapeFilterValue(filterValue)}`;
        case FilterType.INCLUDES_NONE:
            if (!Array.isArray(filterValue)) {
                return '';
            }
            //serialize with NOT vs '-' as this will be going to Algolia
            return filterValue
                .map((v) => `NOT ${fieldId}:${escapeFilterValue(v)}`)
                .join(' AND ');
        case FilterType.INCLUDES_ALL:
        case FilterType.INCLUDES_ANY:
            if (!Array.isArray(filterValue)) {
                return '';
            }
            return filterValue
                .map((v) => `${fieldId}:${escapeFilterValue(v)}`)
                .join(filterType === FilterType.INCLUDES_ANY ? ' OR ' : ' AND ');
        case FilterType.IS_IN_NUMERIC_RANGE:
            if (!isRangeFilterValue(filterValue)) {
                return '';
            }
            return filterValue
                .map(([low, high]) => {
                if (low === -Infinity) {
                    return `(${fieldId} < ${high})`;
                }
                if (high === Infinity) {
                    return `(${fieldId} > ${low})`;
                }
                return `(${fieldId}:${low} TO ${high})`;
            })
                .join(' OR ');
    }
    return '';
};
const serializeFilters = (filters) => {
    return filters
        .map((f) => serializeFilter(f))
        .filter((f) => f.length > 0)
        .map((f) => `(${f})`)
        .join(' AND ');
};
export { serializeFilters };
