import { Button, CardSurface, Checkbox, COLORS, Icons, Spinner, Stack, StatefulPopover, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { When } from 'react-if';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const ConnectOptions = ({ studies, instrumentIds, hasConnectStudiesAndDHTs }) => {
    const { currentStudies, currentInstrumentIds, toggleCurrentStudies, toggleCurrentInstruments, } = useContext(ConnectContext);
    const studyTech = studies.filter((x) => !!x.parsedMetadata.technologies.length);
    return (React.createElement(CardSurface, null,
        React.createElement(Stack, { padding: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "Filter By:"),
            React.createElement(When, { condition: hasConnectStudiesAndDHTs &&
                    (studyTech.length > 0 || instrumentIds.length > 0) },
                React.createElement(Typography, { variant: TypographyVariant.Details }, "Technologies"),
                React.createElement(Checkbox, { checkmarkType: "toggle", labelPlacement: "right", onChange: () => {
                        toggleCurrentStudies(studies);
                        toggleCurrentInstruments(instrumentIds);
                    }, checked: !!currentStudies.length || !!currentInstrumentIds.length }, "Organization Data")))));
};
// eslint-disable-next-line complexity
export const ConnectOptionsButton = () => {
    var _a, _b, _c, _d, _e, _f;
    const { currentStudies, currentInstrumentIds, userTeamMetadata, userStudies, } = useContext(ConnectContext);
    const hasConnectStudiesAndDHTs = useHasPermission('connect', 'read', 'all');
    const allSourcesToDisplay = [];
    const studyTech = (_b = (_a = userStudies === null || userStudies === void 0 ? void 0 : userStudies.data) === null || _a === void 0 ? void 0 : _a.filter((x) => { var _a; return !!((_a = x.parsedMetadata) === null || _a === void 0 ? void 0 : _a.technologies.length); })) !== null && _b !== void 0 ? _b : [];
    if (hasConnectStudiesAndDHTs && studyTech.length) {
        allSourcesToDisplay.push(...((_c = userStudies.data) !== null && _c !== void 0 ? _c : []));
    }
    if (hasConnectStudiesAndDHTs) {
        allSourcesToDisplay.push(...((_d = userTeamMetadata.data) !== null && _d !== void 0 ? _d : []));
    }
    if (userTeamMetadata.isLoading || userStudies.isLoading) {
        return React.createElement(Spinner, null);
    }
    if (allSourcesToDisplay.length < 1) {
        return null;
    }
    const hasConnectData = currentStudies.length || currentInstrumentIds.length;
    const hasStudiesOrTech = currentStudies.length > 0 || currentInstrumentIds.length > 0;
    const countForStudiesOrTech = hasStudiesOrTech ? 1 : 0;
    return (React.createElement(StatefulPopover, { placement: "bottom", content: React.createElement(ConnectOptions, { studies: (_e = userStudies.data) !== null && _e !== void 0 ? _e : [], instrumentIds: (_f = userTeamMetadata.data.map((x) => x.resource)) !== null && _f !== void 0 ? _f : [], hasConnectStudiesAndDHTs: hasConnectStudiesAndDHTs }) },
        React.createElement(Button, { variant: "secondary", startEnhancer: React.createElement(Icons.Misc.Connect, { decorative: true, color: COLORS.primary }) }, hasConnectData
            ? `Custom Filters (${countForStudiesOrTech})`
            : 'Custom Filters')));
};
