import { Stack } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { ConnectHitIndicator } from '../tables/shared/ConnectHitIndicator/ConnectHitIndicator';
import { ToolSummary } from '.';
/** Renders a list of tools with links. */
const ToolList = ({ data, }) => {
    const hasConnect = useHasPermission('connect', 'read', 'all');
    return (React.createElement(Stack, null, [...data]
        .sort((a, b) => {
        var _a, _b, _c, _d, _e, _f;
        if (!((_a = a.maker) === null || _a === void 0 ? void 0 : _a.name) ||
            !((_b = b.maker) === null || _b === void 0 ? void 0 : _b.name) ||
            ((_c = a.maker) === null || _c === void 0 ? void 0 : _c.name) === ((_d = b.maker) === null || _d === void 0 ? void 0 : _d.name)) {
            return a.model.localeCompare(b.model);
        }
        else {
            return (_e = a.maker) === null || _e === void 0 ? void 0 : _e.name.localeCompare((_f = b.maker) === null || _f === void 0 ? void 0 : _f.name);
        }
    })
        .map((tool) => (React.createElement(Stack, { key: tool.id, direction: 'horizontal', alignItems: 'center' },
        React.createElement(ToolSummary, { href: generatePath(PATHS.atlas.technologies.detail, {
                id: tool.id,
            }), tool: tool }),
        React.createElement(When, { condition: hasConnect },
            React.createElement(ConnectHitIndicator, { tool: tool })))))));
};
export { ToolList };
