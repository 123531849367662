import React, { useContext, useMemo } from 'react';
import { Spinner } from '@humanfirst/elektron';
import { uniq } from 'ramda';
import { MedicalConditionsResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { OrganizationToolTable } from '../components/OrganizationToolTable';
/** returns the list of technology with associated organization data (studies & team metadata) */
const useTechWithOrgData = () => {
    var _a, _b, _c, _d, _e;
    const { userTeamMetadata, userStudies } = useContext(ConnectContext);
    const allTech = uniq([
        ...((_b = (_a = userTeamMetadata.data) === null || _a === void 0 ? void 0 : _a.flatMap((x) => x === null || x === void 0 ? void 0 : x.resource)) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = userStudies.data) === null || _c === void 0 ? void 0 : _c.flatMap((x) => x.parsedMetadata.technologies)) !== null && _d !== void 0 ? _d : []),
    ]);
    const allStudyMCsIds = (_e = userStudies.data) === null || _e === void 0 ? void 0 : _e.flatMap((x) => x.study.medicalConditions).filter((y) => !!y);
    const { data: allStudyMCs, isInitialLoading } = useElektraApi(MedicalConditionsResource.getBulk((allStudyMCsIds !== null && allStudyMCsIds !== void 0 ? allStudyMCsIds : [])), {
        enabled: allStudyMCsIds && allStudyMCsIds.length > 0,
    });
    const { data: fullTechnologies, isInitialLoading: isFullTechLoading } = useElektraApi(ToolResource.getBulk(allTech !== null && allTech !== void 0 ? allTech : [], { join: ['maker'] }), {
        enabled: allTech && allTech.length > 0,
    });
    return useMemo(() => {
        return {
            data: fullTechnologies === null || fullTechnologies === void 0 ? void 0 : fullTechnologies.map((x) => {
                var _a, _b, _c;
                return ({
                    technology: x,
                    metadata: {
                        studies: (_b = (_a = userStudies.data) === null || _a === void 0 ? void 0 : _a.filter((z) => z.parsedMetadata.technologies.includes(x.id))) !== null && _b !== void 0 ? _b : [],
                        allStudyMCs: allStudyMCs !== null && allStudyMCs !== void 0 ? allStudyMCs : [],
                        teamMetadata: (_c = userTeamMetadata.data) === null || _c === void 0 ? void 0 : _c.filter((y) => (y === null || y === void 0 ? void 0 : y.resource) === x.id),
                    },
                });
            }),
            isLoading: userTeamMetadata.isLoading ||
                userStudies.isLoading ||
                isInitialLoading ||
                isFullTechLoading,
        };
    }, [
        fullTechnologies,
        userTeamMetadata.isLoading,
        userTeamMetadata.data,
        userStudies.isLoading,
        userStudies.data,
        isInitialLoading,
        isFullTechLoading,
        allStudyMCs,
    ]);
};
export const Technologies = () => {
    const { data: toolWithOrgData, isLoading } = useTechWithOrgData();
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(OrganizationToolTable, { data: toolWithOrgData !== null && toolWithOrgData !== void 0 ? toolWithOrgData : [] });
};
