import { Block, Card, COLORS, EmphasisOval, Heading2, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { LoadingState } from 'src/components/LoadingState';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { Locked } from 'src/components/Locked';
import { LockedTooltip } from 'src/components/LockedTooltip';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { RankedEvidenceBars } from 'src/components/RankedEvidence/RankedEvidence';
import { t } from 'src/constants/i18n';
const EvidenceCard = ({ tool }) => {
    const hasAllEvidence = useHasAllEvidence();
    const hasEvidenceSummaryAccess = useHasPermission('component.evidenceSummary', 'read', tool);
    const { data: evidence = [], isLoading: isLoadingEvidence } = useElektraApi(EvidenceResource.list({
        filter: [['tools', '$cont', tool.id]],
    }), { enabled: hasEvidenceSummaryAccess });
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, t('toolDetails.evidenceCard.productEvidenceBreakdownHeader')),
            React.createElement(AnchoredTooltip, null, t('toolAttributes.productEvidenceTooltip'))),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, t('toolDetails.evidenceCard.productEvidenceBreakdownTitle')),
                React.createElement(If, { condition: hasEvidenceSummaryAccess },
                    React.createElement(Then, null,
                        React.createElement(EmphasisOval, { backgroundColor: COLORS.black },
                            React.createElement(Typography, { variant: TypographyVariant.Details, color: "inherit" }, tool.countOfEvidenceMeasures))),
                    React.createElement(Else, null,
                        React.createElement(LockedTooltip, { body: 'Get in touch with our team to upgrade and unlock the evidence breakdown for this technology.', title: 'Access Evidence-based Measures' },
                            "(",
                            React.createElement(Locked, { size: "18px", color: COLORS.gray1 }),
                            ")")))),
            React.createElement(If, { condition: isLoadingEvidence && hasEvidenceSummaryAccess },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(RankedEvidenceBars, { evidence: evidence, shouldShowLocked: !hasEvidenceSummaryAccess }),
                    React.createElement(When, { condition: hasAllEvidence },
                        React.createElement(Link, { href: "#evidence" }, t('toolDetails.evidenceCard.scrollToEvidenceLink'))),
                    React.createElement(When, { condition: !hasEvidenceSummaryAccess },
                        React.createElement(Block, null,
                            React.createElement(ContactUsFormTrigger, { location: `TDP - Evidence Breakdown Card`, contactType: 'Upgrade Account', buttonVariant: 'minimal' }, t('toolDetails.evidenceCard.upgradeForEvidenceLink')))))))));
};
export { EvidenceCard };
