import { ToolResource } from '@humanfirst/use-elektra-api';
import { Indicies } from 'src/constants/search';
import { useRelatedResource } from './useRelatedResource';
const useRelatedTechnology = ({ tool, enabled, }) => {
    return useRelatedResource({
        getResource: (ids) => ToolResource.getBulk(ids, { join: ['maker||id,name'] }),
        indexName: Indicies.Tools,
        objectID: tool.id,
        enabled,
    });
};
export { useRelatedTechnology };
