import React from 'react';
import { Block, COLORS, Icons, Link, Stack, Tag, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { getMeasurementCategory } from 'src/utils/measurements';
import { t } from 'src/constants/i18n';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useDedupeTechnologyForEvidence } from 'src/hooks/evidence';
import { getMeasureCategoryByTechnology, MISSING_TECHNOLOGY_SENTINEL, } from 'src/utils/evidence';
import { LimitedList } from 'src/components/LimitedList';
import { ToolList } from 'src/components/ToolSummary';
import { RequestIngestionButton } from 'src/components/RequestIngestionButton';
import { TRANSLATE_TO_ROMAN_NUM } from 'src/utils/clinicalTrials';
import { MeasureCategoryLink } from 'src/components/Measures/MeasureCategoryLink';
import { getDate } from './utils';
const TitleCell = ({ item }) => {
    const evidenceHref = generatePath(PATHS.atlas.evidence.detail, {
        id: item.id,
    });
    return (React.createElement(Stack, null,
        React.createElement(Link, { href: evidenceHref }, item.title),
        React.createElement(Typography, { variant: TypographyVariant.Details }, item.journal)));
};
const TherapeuticAreaCell = ({ item }) => {
    var _a;
    return (React.createElement(LimitedList, { limit: 1, elements: ((_a = item.therapeuticArea) !== null && _a !== void 0 ? _a : []).map((ta) => (React.createElement(Block, { key: ta.id },
            React.createElement(Typography, { variant: TypographyVariant.Details }, ta.therapeuticArea)))) }));
};
const MedicalConditionCell = ({ item }) => {
    var _a, _b;
    return (React.createElement(LimitedList, { limit: 1, elements: (_b = ((_a = item.medicalConditions) !== null && _a !== void 0 ? _a : [])) === null || _b === void 0 ? void 0 : _b.map((mc) => (React.createElement(Block, { key: mc.id },
            React.createElement(Typography, { variant: TypographyVariant.Details }, mc.name)))) }));
};
const DateCell = ({ item }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, getDate(item)));
};
const EvidenceTypeCell = ({ item }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, item.typeOfSource));
};
const DHTUseContextCell = ({ item }) => {
    var _a;
    return (React.createElement(LimitedList, { limit: 1, elements: ((_a = item.dhtUseContext) !== null && _a !== void 0 ? _a : []).map((x, i) => (React.createElement(Block, { key: `${item.id} ${x[i]}` },
            React.createElement(Typography, { variant: TypographyVariant.Details }, x)))) }));
};
const V3_TYPES = new Set([
    'Clinical Validation',
    'Analytical Validation',
    'Verification',
]);
const V3Cell = ({ item }) => {
    var _a, _b, _c;
    if (!((_a = item.dhtUseContext) === null || _a === void 0 ? void 0 : _a.includes('Validation'))) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "N/A"));
    }
    return (React.createElement(Stack, null, ((_c = (_b = item.v3) === null || _b === void 0 ? void 0 : _b.filter((v) => V3_TYPES.has(v))) !== null && _c !== void 0 ? _c : []).sort().map((v3, i) => (React.createElement(Block, { key: `${item.id} ${v3[i]}` },
        React.createElement(Tag, { closeable: false },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Icons.Product.Yes, { decorative: true }),
                React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, v3))))))));
};
const StudyDesignCell = ({ item }) => {
    var _a;
    return (React.createElement(LimitedList, { limit: 1, elements: ((_a = item.studyDesign) !== null && _a !== void 0 ? _a : []).sort().map((sd, i) => (React.createElement(Block, { key: `${item.id} ${sd[i]}` },
            React.createElement(Typography, { variant: TypographyVariant.Details }, sd)))) }));
};
const SampleSizeCell = ({ item }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, item.sampleSize));
};
const CTSponsorCell = ({ item }) => {
    var _a;
    if (!((_a = item.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('ClinicalTrials.gov'))) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "N/A"));
    }
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, item.ctSponsor));
};
const CTSponsorTypeCell = ({ item }) => {
    var _a;
    if (!((_a = item.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('ClinicalTrials.gov'))) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "N/A"));
    }
    return (React.createElement(Typography, { variant: TypographyVariant.Details }, item.ctSponsorType));
};
const CTPhaseCell = ({ item }) => {
    var _a;
    if (!((_a = item.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('ClinicalTrials.gov'))) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "N/A"));
    }
    if (!item.ctPhase || !TRANSLATE_TO_ROMAN_NUM.hasOwnProperty(item.ctPhase)) {
        return null;
    }
    const ctPhaseTransformed = TRANSLATE_TO_ROMAN_NUM.hasOwnProperty(item.ctPhase)
        ? TRANSLATE_TO_ROMAN_NUM[item.ctPhase]
        : undefined;
    if (!ctPhaseTransformed) {
        return null;
    }
    return (React.createElement(LimitedList, { limit: 1, elements: ctPhaseTransformed.sort().map((x, i) => (React.createElement(Block, { key: `${item.id} ${x[i]}` },
            React.createElement(Typography, { variant: TypographyVariant.Details }, x)))) }));
};
const AgeCell = ({ item }) => {
    var _a;
    return (React.createElement(LimitedList, { limit: 1, elements: ((_a = item.ageRecruitmentCriteria) !== null && _a !== void 0 ? _a : []).map((x, i) => (React.createElement(Block, { key: `${item.id} ${x[i]}` },
            React.createElement(Typography, { variant: TypographyVariant.Details }, x)))) }));
};
const MeasurementCell = ({ item }) => {
    var _a;
    const measures = (_a = item.measureCategory) !== null && _a !== void 0 ? _a : [];
    const measureNames = measures.map((x) => getMeasurementCategory(x));
    if (item.ingestionStatus === 'Partially Ingested') {
        return (React.createElement(RequestIngestionButton, { resourceType: "evidence", resourceId: item.id, formData: { title: item.title } }));
    }
    return (React.createElement(LimitedList, { limit: 1, elements: measureNames.map((measure, i) => (React.createElement(Block, { key: `${item.id} ${measures[i]}` },
            React.createElement(Link, { href: generatePath(PATHS.atlas.measuresName, {
                    name: measures[i],
                }), typographyVariant: TypographyVariant.Details }, measure)))) }));
};
/** Render measures for a single tech. */
const MesauresForTech = ({ model, measures, }) => {
    if (!measures || measures.length === 0) {
        return null;
    }
    return (React.createElement(Block, null,
        React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, model),
        React.createElement(Stack, null,
            React.createElement("ul", { style: { paddingInlineStart: '10px' } }, measures.map((y) => (React.createElement("li", { key: y },
                React.createElement(MeasureCategoryLink, { measure: y }))))))));
};
/**
 * This cell can show the measurements grouped by their technology.
 *
 * NOTE: This needs the full sourceMeasures to render properly. If missing this
 * will be empty.
 * @returns
 */
const GroupedMeasurementCell = ({ item }) => {
    const technologies = useDedupeTechnologyForEvidence(item);
    const measuresByTechnology = getMeasureCategoryByTechnology(item);
    if (item.ingestionStatus === 'Partially Ingested') {
        return (React.createElement(RequestIngestionButton, { resourceType: "evidence", resourceId: item.id, formData: { title: item.title } }));
    }
    return (React.createElement(Stack, { gap: "16px" },
        technologies.map((tech) => (React.createElement(MesauresForTech, { key: `${item.id} ${tech.id}`, model: tech.model, measures: measuresByTechnology.get(tech.id) }))),
        React.createElement(MesauresForTech, { model: t('evidenceTable.unspecifiedTechnology'), measures: measuresByTechnology.get(MISSING_TECHNOLOGY_SENTINEL) })));
};
const ToolListDeduped = ({ item }) => {
    const data = useDedupeTechnologyForEvidence(item);
    return React.createElement(ToolList, { data: data });
};
export { TitleCell, TherapeuticAreaCell, MedicalConditionCell, DateCell, MeasurementCell, GroupedMeasurementCell, ToolListDeduped, EvidenceTypeCell, DHTUseContextCell, V3Cell, StudyDesignCell, SampleSizeCell, CTSponsorTypeCell, CTSponsorCell, CTPhaseCell, AgeCell, };
