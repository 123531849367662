import { COLORS, SemanticButton, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { useParams } from 'react-router';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const ToolSupportingEvidenceCell = ({ item, showEvidence, }) => {
    const { id } = useParams();
    const hasEvidence = useHasPermission('measure.evidence', 'read', {
        id,
    });
    if (!hasEvidence) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ContactUsFormTrigger, { location: `Technology: ${item.model} (${item.id})`, contactType: 'Evidence Request' }, "Request Evidence")));
    }
    if (item.evidenceCount === 0) {
        return (React.createElement(Typography, { color: COLORS.gray1 }, "Vendor Stated Measures Only"));
    }
    return (React.createElement(SemanticButton, { onClick: () => showEvidence('Technology', `${item.model}||${item.id}`) },
        React.createElement(Typography, { color: COLORS.primary, variant: TypographyVariant.BodyBold }, `See Supporting Evidence (${item.evidenceCount})`)));
};
const MedicalConditionSupportingEvidenceCell = ({ item, showEvidence }) => {
    const { id } = useParams();
    const hasEvidence = useHasPermission('measure.evidence', 'read', {
        id,
    });
    if (!hasEvidence) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ContactUsFormTrigger, { location: `Medical Condition: ${item.name} (${item.id})`, contactType: 'Evidence Request' }, "Request Evidence")));
    }
    if (item.evidenceCount === 0) {
        return null;
    }
    return (React.createElement(SemanticButton, { onClick: showEvidence
            ? () => showEvidence('MedicalCondition', item.name)
            : undefined },
        React.createElement(Typography, { color: COLORS.primary, variant: TypographyVariant.BodyBold }, `See Supporting Evidence (${item.evidenceCount})`)));
};
export { MedicalConditionSupportingEvidenceCell, ToolSupportingEvidenceCell, };
