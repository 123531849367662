import React, { createContext, useCallback, useMemo, useState } from 'react';
import { getMeasurementHierarchy, serializeMeasurementHierarchy, } from 'src/utils/measurements';
import { intersectTrees } from 'src/utils/tree';
const SharedAttributeContext = createContext({
    tools: [],
    getSharedAttribute: () => [],
    highlighted: undefined,
    toggleSharedHighlight: () => null,
});
/**
 * Find shared measures between tools.
 *
 * This will include intermediate measures, not just the leaf nods.
 * If hasEvidence, uses the measure categories, otherwise uses the manufacture
 * measure categories.
 */
const findSharedMeasures = (tools, hasEvidence) => {
    var _a;
    if (!(tools === null || tools === void 0 ? void 0 : tools.length)) {
        return [];
    }
    const trees = tools.map((x) => {
        var _a;
        return ({
            value: 'root',
            children: getMeasurementHierarchy((_a = (hasEvidence ? x.measuresCategory : x.manufacturerMeasuresCategory)) !== null && _a !== void 0 ? _a : []),
        });
    });
    const intersection = intersectTrees(trees);
    return serializeMeasurementHierarchy((_a = intersection === null || intersection === void 0 ? void 0 : intersection.children) !== null && _a !== void 0 ? _a : []);
};
const SharedAttributeContextProvider = ({ children, tools }) => {
    const [highlighted, setHighlighted] = useState();
    const getSharedAttribute = useCallback((type, hasEvidence) => {
        var _a, _b, _c, _d;
        if (type === 'measures') {
            return findSharedMeasures(tools, hasEvidence);
        }
        else {
            if (!(tools === null || tools === void 0 ? void 0 : tools.length) || !((_a = tools === null || tools === void 0 ? void 0 : tools[0]) === null || _a === void 0 ? void 0 : _a[type])) {
                return [];
            }
            const firstToolAttributes = (_c = (_b = tools === null || tools === void 0 ? void 0 : tools[0]) === null || _b === void 0 ? void 0 : _b[type]) !== null && _c !== void 0 ? _c : [];
            const allToolAttributes = (_d = tools.flatMap((x) => { var _a; return (_a = x[type]) !== null && _a !== void 0 ? _a : []; })) !== null && _d !== void 0 ? _d : [];
            return firstToolAttributes.filter((x) => allToolAttributes.filter((y) => y === x).length === (tools === null || tools === void 0 ? void 0 : tools.length));
        }
    }, [tools]);
    const toggleSharedHighlight = useCallback((attribute) => {
        setHighlighted((h) => {
            if (h === attribute) {
                return undefined;
            }
            return attribute;
        });
    }, []);
    const value = useMemo(() => ({
        getSharedAttribute,
        tools,
        toggleSharedHighlight,
        highlighted,
    }), [getSharedAttribute, highlighted, toggleSharedHighlight, tools]);
    return (React.createElement(SharedAttributeContext.Provider, { value: value }, children));
};
export { SharedAttributeContext, SharedAttributeContextProvider };
