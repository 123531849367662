import React from 'react';
import { Block, COLORS } from '@humanfirst/elektron';
import { TagAction } from './TagAction';
import { CommentAction } from './CommentAction';
import { ReviewedAction } from './ReviewedAction';
import { BookmarkAction } from './BookmarkAction';
const EvidencePrimaryInfoActions = ({ item }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(TagAction, { item: [item] }),
        React.createElement(CommentAction, { item: item }),
        React.createElement(Block, { border: `1px solid ${COLORS.gray3}`, height: "16px" }),
        React.createElement(ReviewedAction, { reviewed: item.reviewed, resourceId: item.id }),
        React.createElement(BookmarkAction, { variant: "archive", bookmarks: [item.bookmark] }),
        React.createElement(BookmarkAction, { variant: "delete", bookmarks: [item.bookmark] })));
};
export { EvidencePrimaryInfoActions };
