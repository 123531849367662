import { path } from 'ramda';
const CONTACT_US_TO_SEARCH_BY_ALL_MEASURES = 'Contact us to unlock all 8000+ measures';
const CONTACT_US_TO_SEARCH_BY_ALL_MEDICAL_CONDITIONS = 'Contact us to unlock all 750+ medical conditions';
const TRANSLATIONS = {
    authentication: {
        expectedErrors: {
            redirectRequired: 'must authenticate with IdP',
        },
        login: {
            heading: 'Log in to your account',
            continueWithGoogle: 'Continue with Google',
            forgotPasswordCTA: 'Forgot your password?',
            continueMultiPartFormCTA: 'Continue',
            loginCTA: 'Sign in',
        },
    },
    coaching: {
        dismiss: 'Got it',
        workspacesLaunch: {
            title: 'Workspaces now available in Beta',
            paragraph: 'Organize your research and pick up where you left off to make decisions with confidence',
        },
    },
    navigation: {
        side: {
            atlasCatalog: {
                index: 'Atlas Network',
                tech: 'Technologies',
                vendor: 'Vendors',
                measures: 'Measures',
                medicalConditions: 'Medical Conditions',
                evidence: 'Evidence',
            },
            workspaces: {
                index: 'My Projects',
            },
            connectCatalog: {
                index: "My Org's Data",
            },
            admin: {
                index: 'Admin',
                teams: 'Teams',
                users: 'Users',
                roles: 'Roles',
                workspaces: 'Projects',
                studies: 'Studies',
                reports: 'Reports',
                utilities: 'Utilities',
            },
            maker: {
                index: 'My Products',
            },
        },
    },
    activation: {
        error: 'There is something wrong with your activation link. Please check your email and try again or enter your email below to request a new link.',
        loading: "We're trying to activate your account.",
        success: 'Your account has been activated.',
        heading: 'Activate Your Atlas account',
        headingError: 'Something went wrong',
        submitSuccess: 'Please check your email',
    },
    myDataCard: {
        heading: 'My Tags and Notes',
        infoTooltip: {
            text: 'Your tags and notes are visible to only you.',
            helpCenterCTA: 'See how they work',
        },
        display: {
            emptyHeading: 'You currently have no tags and notes here.',
            emptyCreateNewCTA: '+ Add Tags and Notes',
            noTags: 'No tags.',
            noNotes: 'No notes.',
            editCTA: 'Edit',
        },
        edit: {
            noteTextareaLabel: 'Enter a note',
            tagInputLabel: 'Select or create a tag',
            addTagCTA: 'Add tag',
            doneCTA: 'Done',
        },
    },
    subscribeToEmailsTray: {
        title: 'Want to hear more?',
        body: "If you subscribe you'll receive updates about Atlas features, tips, and industry news.",
        actions: {
            notNow: 'Not Now',
            subscribe: 'Subscribe',
        },
    },
    welcomeModal: {
        header: 'Welcome to Atlas!',
        current: {
            header: 'You currently have access to the following:',
            techSearch: 'Over 1,300 technologies with detailed tech specs',
            techByMakeModel: 'Search the technology landscape by make/model for connected sensors, wearables, and apps',
            tryItOut: 'Examples of full data access wherever you see this icon',
            someTAs: 'Search by Therapeutic Area (with limited evidence)',
            allTAs: 'Search by Therapeutic Area for relevant measures & technology',
            fullSearch: 'Search the technology landscape by make/model, measure, or medical condition for connected sensors, wearables, and apps',
            allEvidence: 'More than 5,800 pieces of evidence (includes peer-reviewed research, FDA decisions, clinical trials, and more!)',
            measureLibrary: 'Browse the Measure Library',
            workspaces: 'Plan studies using Workspaces',
            catalog: 'Collaborate using Atlas Connect',
        },
        upgrade: {
            header: 'Upgrade your account and get access to:',
            headerSingleItem: 'Upgrade your account and get access to ',
            allEvidence: 'More than 5,800 pieces of evidence (includes peer-reviewed research, FDA decisions, clinical trials, and more!)',
            workspaces: 'Workspaces for study planning',
            therapeuticAreas: 'Search by Therapeutic Area for relevant measures & technology',
            catalog: 'Atlas Connect and other data sources',
        },
        actionButton: {
            description: 'To learn more about upgrading:',
            label: 'Contact Us',
            noUpgradeslabel: 'Get Started',
        },
    },
    evidenceTable: {
        unspecifiedTechnology: 'Unspecified Technology',
        partiallyIngested: {
            button: 'Request Ingestion',
            header: 'Interested in this piece of evidence?',
            tooltip: 'We are working to get this piece of evidence fully ingested in Atlas. Request ingestion to move it to the front of the queue.',
            cta: 'Interested',
            successMessage: 'You will be notified once this evidence has been fully ingested.',
        },
    },
    tools: {
        editSubmitSuccess: 'Thank you for your review. A member of HumanFirst will reach out to you within 3 business days.',
    },
    toolDetails: {
        partiallyIngested: {
            header: 'Interested in this technology?',
            body: 'Technologies are uploaded daily based on user interest. Interested in this technology?',
            cta: 'Interested',
            successMessage: 'You will be notified once this technology has been integrated.',
        },
        evidenceCard: {
            productEvidenceBreakdownHeader: 'Product Evidence Breakdown',
            productEvidenceBreakdownTitle: 'Total Product Evidence',
            utilityAndUsabilityTitle: 'Utility & Usability',
            utilityAndUsabilityTitleTooltip: `Studies that evaluate the practical considerations of using the technology in an individual's daily life`,
            fdaDocumentationTitle: 'FDA Documentation',
            scrollToEvidenceLink: 'Scroll to product evidence',
            upgradeForEvidenceLink: 'Upgrade to see breakdown',
        },
        measureTags: {
            evidence: {
                title: 'Evidence-based',
                description: 'Measures that have been reported in published evidence or regulatory documentation.',
            },
            manufacturer: {
                title: 'Vendor-stated',
                description: 'Measures that the vendor has stated may be collected.',
            },
            fda: {
                title: 'FDA',
                description: 'Measures that have been reported by the FDA.',
            },
        },
    },
    toolAttributes: {
        productEvidenceTooltip: 'Evidence is broken out by peer-reviewed Papers, Clinical Trials, FDA Documentation, and Other (e.g., conference abstracts, posters, etc.).\n\nPapers are segmented by the steps in the V3 Framework (Verification, Analytical Validation, Clinical Validation). A paper studying both Analytical Validation and Clinical Validation will count towards each V3 type.\n\nClinical Trials are segmented by Phase. Not all CTs are phased, and multi-phase trials will count towards each phase in the protocol design.',
        v3EvidenceTooltip: 'This number reflects the subset of evidence in which the DHT Use Context was Validation. Total Validation Evidence may not reflect the sum of V3 Evidence counts, as not all validation studies align with the V3 framework.',
        ctPhasesEvidenceTooltip: 'This list refers to the Clinical Trial phases where this tool has captured an endpoint. Not all Clinical Trials are phased, and multi-phase trials will count towards each phase the protocol design includes.',
    },
    search: {
        searchSuggestionsText: 'Want to search by vital signs and medical conditions? Try these sample searches:',
        endOfEntriesHeader: 'Not finding what you are looking for?',
        endOfEntriesBody: 'Atlas is updated daily and a member of our team may reach out to clarify your search results.',
        partiallyIngestedTool: {
            header: 'Interested in this tool?',
            body: "This tool hasn't yet been added into Atlas. Get notified when it's ready.",
            requestSubmitted: 'You will be notified once this tool has been integrated.',
        },
        input: {
            placeholder: 'Search the entire Atlas catalog',
            label: 'Search HumanFirst',
            noResults: {
                heading: 'Nothing turned up',
                subheading: 'Didn’t find what you’re looking for? You may want to try using different keywords, or checking for typos.',
                expandSearch: "Didn't find what you're looking for? Expand your search by exploring these options:",
                links: {
                    measures: 'Measures',
                    vendors: 'Vendors',
                    medicalConditions: 'Medical Conditions',
                    tech: 'Technology',
                },
            },
        },
    },
    ontologies: {
        noResults: 'No matches found.',
        upgradePrompt: 'You are currently in the OpenAtlas access tier. To unlock all measures, get in touch with one of our representatives.',
    },
    emptyState: {
        text: "Didn't find what you're looking for? Expand your search by exploring these options:",
    },
    actions: {
        contactUs: 'Contact us',
        chatWithUs: 'Chat with us',
        expandAll: 'Expand all',
        collapseAll: 'Collapse all',
        clearSearch: 'Clear search',
        contactForAccess: 'Contact us to get access',
        clear: 'Clear',
    },
    dashboard: {
        getStartedPrompt: 'Evaluate and select the best digital measures and connected technologies for your needs.',
        technologyTitle: 'Technologies',
        technologySubtitle: 'Find technologies by model and vendor',
        technologyExample: 'FDA cleared DHTs that measure activity',
        technologyCTA: 'Explore Technologies',
        measureLibraryButton: 'Explore Measure Library',
        measureLibraryTitle: 'Measures',
        measureLibrarySubtitle: 'Search by measure or concept of interest',
        measureLibraryExample: 'tools worn on the chest that measure heart rate with ECG',
        measureLibraryCTA: 'Explore Measures',
        medicalConditionTitle: 'Medical Conditions',
        medicalConditionSubtitle: 'View top measures and technologies by medical condition',
        medicalConditionExample: 'medical conditions within the neurology therapeutic area',
        medicalConditionCTA: 'Explore Medical Conditions',
        vendorsTitle: 'Vendors',
        vendorsSubtitle: 'Browse the full list of technology manufacturers',
        vendorsExample: 'vendors with data science and analytics capabilities',
        vendorsCTA: 'Explore Vendors',
    },
    studies: {
        list: {
            createStudy: 'Add a new study',
        },
        detail: {
            editStudy: 'Edit',
        },
        edit: {},
    },
    therapeuticAreas: {
        openAtlasLearnMore: 'Learn More',
        openAtlasCard: {
            title: 'Interested in accessing detailed information about these medical conditions?',
        },
        pageDescription: 'Explore this page for Atlas coverage of the therapeutic areas and medical conditions',
        heading: 'Explore Therapeutic Areas',
        search: {
            inputPlaceholer: 'Search for medical conditions',
            noResults: 'No results were found',
            expandYourSearch: 'Expand your search by exploring these options:',
        },
        therapeuticAreaRow: {
            viewMedicalConditions: 'View Medical Conditions',
        },
        medicalConditionRow: {
            viewDetails: 'View Details',
        },
        notFoundCard: {
            // eslint-disable-next-line
            heading: 'Not finding what you’re looking for?',
            searchByTechnology: 'Search by technology',
            searchByMeasures: 'Search by measures',
        },
    },
    measureDetailPage: {
        resourceSummary: 'Explore this page for Atlas coverage of technologies and medical conditions in ',
        topMedicalConditionsToolTip: 'Includes the medical conditions most often tracked in Atlas evidence using this digital measure.',
        topTechnologyToolTip: 'The connected sensors and apps in Atlas that were most cited capturing this digital measure.',
        pageDescription: 'Explore this page for Atlas coverage of the medical conditions and technology in ',
        technologyToolTip: 'The total number of connected sensors and apps in Atlas used to capture this digital measure.',
        medicalConditionToolTip: 'The total number of medical conditions tracked in Atlas evidence using this digital measure.',
        evidenceToolTip: 'An aggregate of all pieces of evidence in Atlas using connected sensors in this measure category. Types of evidence include peer-reviewed papers, validation studies, clinical trials, and conference abstracts, among others.',
        noToolsListed: 'None meeting Atlas criteria',
    },
    medicalConditionPage: {
        topMeasuresToolTip: 'Includes the clinical measure categories most often captured in evidence by connected sensors for ',
        topTechnologyToolTip: 'The number of pieces of evidence (papers, clinical trials, conference abstracts, etc.) in Atlas citing the use of this product in ',
        pageDescription: 'Explore this page for Atlas coverage of the digital measures, published evidence and technology in ',
        technologyToolTip: 'The total number of connected sensors and apps in Atlas used to capture digital measures in this medical condition.',
        measureToolTip: 'An aggregate of the measure categories most often captured by connected sensors in  this medical condition.',
        evidenceToolTip: 'An aggregate of all pieces of evidence in Atlas using connected sensors in this medical condition. Types of evidence include peer-reviewed papers, validation studies, clinical trials, and conference abstracts, among others.',
        noPermissions: {
            heading: "Oops! You don't have permission to view this page",
            subtext: "Didn't find what you're looking for? Expand your search by exploring these options:",
        },
        noToolsListed: 'None meeting Atlas criteria',
    },
    freeUsers: {
        unlockAccessPrompt: 'Get in touch with one of our representatives to access all medical conditions, measures, evidence, and related technologies.',
    },
    allUsers: {
        beta: 'Beta',
        tryItOut: {
            heading: 'Try it out!',
            subText: 'Wherever you see this icon, we encourage you to try it out.',
        },
    },
    confirmationModal: {
        removeUserTitle: 'Remove User',
        removeUserBody: 'Are you sure you want to remove this user?',
        removeUserSubmitBtn: 'Remove',
    },
    contactUs: {
        title: 'Contact Us',
        paragraph1: 'Interested in learning more about how HumanFirst can help your organization?',
        paragraph2: 'Fill out this form and a HumanFirst specialist will get back to you within 2 business days.',
    },
    helpCenter: {
        banner: {
            cta: 'Help Center',
            title: 'Need help getting started? Search for answers and browse our knowledge base.',
        },
        seeMoreDetails: 'See more details',
    },
    policies: {
        softwareUpdates: {
            label: 'Published Software Updates',
            exists: 'Published Software Updates',
            unavailable: 'Published Software Updates',
            unavailableTooltip: {
                maker: 'Software updates can address potential security and data safety issues without requiring hardware replacement. Publicly posting information about software updates can increase uptake of those fixes (where appropriate, as may depend on research trial protocol). Transparency around software updates also offers a glimpse into the software hygiene of a company. Review your company website’s security page, product detail pages, or wherever information about product software is posted on your organizations’ website. If you believe your website does contain this information, please let us know',
                searcher: 'Software updates can address potential security and data safety issues without requiring hardware replacement. Publicly posting information about software updates can increase uptake of those fixes (where appropriate, as may depend on research trial protocol). Transparency around software updates also offers a glimpse into the software hygiene of a company.',
            },
            doesNotExist: 'Publish Software Updates',
            doesNotExistTooltip: {
                maker: 'This is displayed because someone in your organization told HumanFirst that your organization does not post software updates online.',
                searcher: 'This is an early action sign. Technology makers may choose not to post software updates on their website for a number of reasons, such as: software updates are automatically downloaded or are released via app. Your procurement process may take longer to determine whether, when, or how software updates are deployed.',
            },
            datedTooltip: {
                searcher: 'This date is from the posted policy itself.',
            },
        },
        hipaa: {
            label: 'HIPAA',
            datedTooltip: {
                searcher: 'This date is from the posted policy itself.',
            },
            tooltip: "The vendor's statement about compliance with the Health Insurance Portability and Accountability Act (HIPAA). This indicates the vendor has posted a statement about whether or not they have security measures in place to ensure HIPAA compliance.",
        },
        gdpr: {
            label: 'GDPR',
            datedTooltip: {
                searcher: 'This date is from the posted policy itself.',
            },
            tooltip: "The vendor's statement about compliance with the General Data Protection Regulation (GDPR). This indicates that the vendor has posted a statement on how it handles personal data of European Union (EU) citizens according to the standards set by this law.",
        },
        privacy: {
            label: 'Organizational Privacy Policy',
            existsTooltip: {
                maker: 'This is the website privacy policy that is linked from your site',
                searcher: "This is the privacy policy posted on the organization's website. It does not necessary apply to the organization's products.",
            },
            doesNotExist: 'No publicly posted privacy policy',
            doesNotExistTooltip: {
                maker: 'This is displayed because someone in your organization told HumanFirst that there is no publicly posted website privacy policy. It is considered good practice—and in some cases required by law—to post a website privacy policy. If you add a privacy policy to your site, please let us know.',
                searcher: 'This is an early action sign: this organization has confirmed that it does not have a publicly posted privacy policy. This gap may slow the procurement process. This could also affect your ability to create a comprehensive informed consent document.',
            },
            unavailable: 'Organizational Privacy Policy',
            unavailableTooltip: {
                maker: 'Website privacy policies should be linked from the home page. Product privacy policies may be included in, or linked to, the website privacy policy, or hosted separately on the company site. It is considered good practice—and in some cases required by law—to post a website privacy policy. Please let us know if your site has a privacy policy that we are not displaying. If no link is available, post a privacy policy on your site and let us know when it is publicly available.',
                searcher: 'This is an early action sign: we were unable to locate a publicly posted privacy policy. This gap may slow the procurement process. It could also affect your ability to create a comprehensive informed consent document. ',
            },
            datedTooltip: {
                searcher: 'This date is from the posted policy itself.',
            },
            notDatedTooltip: {
                searcher: 'This is an early action sign: the absence of an effective date on the privacy policy makes it difficult to determine the currency of the document.',
            },
        },
        cvd: {
            label: 'Coordinated Vulnerability Disclosure (CVD)',
            exists: 'Coordinated Vulnerability Disclosure (CVD) policy',
            unavailable: 'Coordinated Vulnerability Disclosure (CVD) policy',
            unavailableTooltip: {
                maker: 'An indicator of a developed security program is the existence of a publicly posted Coordinated Vulnerability Disclosure (CVD) policy, also known as a responsible disclosure policy. We look to see if the company has posted a policy on its website. To learn more about why we think CVDs are important, read this Nature article that we co-authored in 2020. If you believe your website does contain a CVD, please let us.',
            },
            doesNotExist: 'Coordinated Vulnerability Disclosure (CVD) policy',
            doesNotExistTooltip: {
                maker: 'This is displayed because someone in your organization told HumanFirst that there is no publicly posted Coordinated Vulnerability Disclosure (CVD) policy.',
            },
            datedTooltip: {
                searcher: 'This date is from the posted policy itself.',
            },
        },
        productPrivacy: {
            label: 'Product-Specific Privacy Policy',
            exists: 'Product-Specific Privacy Policy',
            unavailable: 'Product-Specific Privacy Policy',
            doesNotExist: 'No publicly posted product privacy policy',
        },
    },
    apiClient: {
        createConfirmation: {
            header: 'IMPORTANT',
            prompt: "Please record the following secret somewhere secure. You will not be able to view again. If you misplace your secret, it can not be recovered. In the case of a lost secret, you'll need to create a new Api Client.",
        },
    },
};
const t = (key) => {
    var _a;
    return (_a = path(key.split('.'), TRANSLATIONS)) !== null && _a !== void 0 ? _a : '';
};
export { CONTACT_US_TO_SEARCH_BY_ALL_MEASURES, CONTACT_US_TO_SEARCH_BY_ALL_MEDICAL_CONDITIONS, t, };
