import { __rest } from "tslib";
import { DataTable, useDataTableState, } from '@humanfirst/elektron';
import React from 'react';
import { EmptyStateOtherOptions } from 'src/components/EmptyStateOtherOptions';
import { useTrackDataTableRefinement } from 'src/hooks/analytics/track-table-refinement';
import { useCustomizedSchema } from '../hooks/use-customized-schema';
import { useDataTableUrlMemory } from '../hooks/use-data-table-url-memory';
import { ORGANIZATION_COLUMN, PRODUCT_COUNT_COLUMN } from './columns';
import { SelectionOptions } from './components/SelectionOptions';
import { VENDOR_TYPE_FILTER } from './filters';
/** Schema for the simplified version of the table (no searching/filtering). */
const SIMPLE_ORGANIZATION_TABLE_SCHEMA = {
    columns: [ORGANIZATION_COLUMN, PRODUCT_COUNT_COLUMN],
    selectable: true,
    searchable: false,
};
const ORGANIZATION_TABLE_SCHEMA = {
    columns: [ORGANIZATION_COLUMN, PRODUCT_COUNT_COLUMN],
    filters: [VENDOR_TYPE_FILTER],
    selectable: true,
};
/** Empty state when searching. */
const EMPTY_STATE_OPTIONS = React.createElement(EmptyStateOtherOptions, null);
/** Selection actions by default. */
const SELECTION_ACTIONS = React.createElement(SelectionOptions, null);
const INITIAL_STATE = { sort: { column: 'name', ascending: true } };
/** Organization table with refinement capabilitiies */
export const OrganizationTable = (_a) => {
    var { data, initialState = INITIAL_STATE } = _a, props = __rest(_a, ["data", "initialState"]);
    const schema = ORGANIZATION_TABLE_SCHEMA;
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, data, initialState);
    useTrackDataTableRefinement('vendors', result);
    return (React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: SELECTION_ACTIONS, emptyElement: EMPTY_STATE_OPTIONS, itemType: "vendors" })));
};
/** A simplified version of the organization table. */
export const OrganizationTableSimple = (_a) => {
    var { data, initialState, extraActions } = _a, props = __rest(_a, ["data", "initialState", "extraActions"]);
    const schema = SIMPLE_ORGANIZATION_TABLE_SCHEMA;
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableState(finalSchema, data, initialState);
    return React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: extraActions }));
};
