import React from 'react';
import { Spinner } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { ToolTable } from 'src/components/ToolTable';
function translateParameters(input) {
    const parameters = {
        filters: undefined,
    };
    if ('filter' in input && input.filter) {
        parameters.filters = Array.isArray(input.filter)
            ? input.filter
            : [input.filter];
    }
    return parameters;
}
export const MarkdownCodeExtensionDeviceTable = ({ code, parameters, }) => {
    const lines = code
        .trim()
        .split('\n')
        .map((line) => line.trim());
    const viewIds = lines.filter((line) => line.startsWith('viw'));
    const recordIds = lines.filter((line) => line.startsWith('rec'));
    const { filters } = translateParameters(parameters);
    const { isInitialLoading: isLoadingViews, data: viewDevices = [] } = useElektraApi(ToolResource.list({
        join: ['groups'],
        filter: [['groups.airtableId', '$in', viewIds.join(',')]],
    }), { enabled: viewIds.length > 0 });
    const { isInitialLoading: isLoadingRecords, data: recordDevices = [] } = useElektraApi(ToolResource.getBulk(recordIds), {
        enabled: recordIds.length > 0,
    });
    const isLoading = isLoadingRecords || isLoadingViews;
    const devices = [...recordDevices, ...viewDevices];
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(ToolTable, { data: devices, priorityFilters: filters });
};
