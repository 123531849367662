import React, { useContext, useState, useCallback } from 'react';
import { Button, Block, Icons, Stack, Tooltip, SemanticButton, } from '@humanfirst/elektron';
import { useFormContext } from 'react-hook-form';
import { When } from 'react-if';
import { SmartTextarea } from 'src/components/SmartForm/fields';
import { MakerEditingContext } from 'src/pages/Tool/contexts/MakerEditingContext';
const FlagAnchor = ({ toggleCallback, filled, }) => {
    return (React.createElement(SemanticButton, { onClick: (e) => {
            e.preventDefault();
            return toggleCallback();
        } },
        React.createElement(Block, { alignItems: 'center', display: "flex", padding: "8px" }, filled ? (React.createElement(Icons.Misc.Flag, { size: "14px", title: "Edit" })) : (React.createElement(Icons.Misc.Flag, { size: "14px", color2: 'transparent', title: "Edit" })))));
};
const EditFlagTooltipContent = ({ field, closeCallback, label, hasComments, rows }) => {
    const form = useFormContext();
    const { watch, setValue } = form || {};
    const value = watch(field);
    const [currentValue] = useState(value);
    const cancelCallback = useCallback(() => {
        setValue(field, currentValue);
        closeCallback();
    }, [setValue, field, currentValue, closeCallback]);
    return (React.createElement(Block, null,
        React.createElement(Stack, { width: "500px" },
            React.createElement(SmartTextarea, { id: field, label: label, name: field, rows: rows }),
            React.createElement(When, { condition: hasComments },
                React.createElement(SmartTextarea, { label: "Other comments? (Optional)", name: `${field} comments`, rows: 4 })),
            React.createElement(Stack, { direction: "horizontal", justifyContent: "flex-end" },
                React.createElement(Button, { size: "compact", variant: "tertiary", onClick: cancelCallback }, "Cancel"),
                React.createElement(Button, { size: "compact", disabled: value === currentValue, onClick: closeCallback }, "Save")))));
};
const EditFlagTooltip = ({ field, label = 'What would you like to change?', hasComments = false, rows = 4, }) => {
    const { isEditing } = useContext(MakerEditingContext);
    const [isOpen, setIsOpen] = useState(false);
    const toggleCallback = useCallback(() => setIsOpen((o) => !o), [setIsOpen]);
    const closeCallback = useCallback(() => setIsOpen(false), [setIsOpen]);
    const form = useFormContext();
    if (!form && !isEditing) {
        return null;
    }
    const { watch } = form;
    field = field.replace(/[^ a-zA-Z0-9]/g, ' ');
    const value = watch(field);
    return (React.createElement(Tooltip, { placement: 'top', isOpen: isOpen, triggerType: 'click', onRequestClose: closeCallback, content: React.createElement(EditFlagTooltipContent, { label: label, field: field, rows: rows, closeCallback: closeCallback, hasComments: hasComments }) },
        React.createElement(FlagAnchor, { filled: !!value, toggleCallback: toggleCallback })));
};
export { EditFlagTooltip };
