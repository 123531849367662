import React from 'react';
import { Card, Text, Icons, Stack } from '@humanfirst/elektron';
const SearchAndPlanEmptySection = ({ variant, }) => {
    if (variant === 'archive') {
        return (React.createElement(Card, null,
            React.createElement(Text, { align: "center" }, "You currently do not have any archived items in this project.")));
    }
    return (React.createElement(Stack, null,
        React.createElement(Text, { align: "center" }, "Your currently have no items saved to this list."),
        React.createElement(Text, { align: "center" },
            "Collect your research and save your work here. Look for",
            ' ',
            React.createElement(Icons.Misc.Save, { title: "Save to Project" }),
            " to save items to your project.")));
};
export { SearchAndPlanEmptySection };
