import { uniqBy } from 'ramda';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { deserializeWorkspaceMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
const getMeasuresOnStudies = (studies, toolId) => {
    const measuresCombined = [];
    studies.map((x) => {
        const parsedMetadata = deserializeWorkspaceMetadata(x.additionalMetadata);
        const { measureMetadata } = splitMetadata(parsedMetadata);
        measureMetadata
            .filter((m) => m.field.measuresLabel === 'conceptsOfInterest' &&
            (!!toolId ? m.field.toolId === toolId : true))
            .forEach((x) => measuresCombined.push({ name: x.value }));
    });
    return measuresCombined;
};
const getMCsOnTeamMetadata = (teamMetadata, logger) => {
    const mcsCombined = [];
    teamMetadata.forEach((x) => {
        const mcs = getTeamMetadata(x, 'Medical Conditions');
        if (mcs) {
            try {
                const parsedMCs = JSON.parse(mcs);
                mcsCombined.push(...parsedMCs);
            }
            catch (e) {
                if (logger) {
                    logger.error('unable to parse medical condition from team metadata');
                }
            }
        }
    });
    return mcsCombined;
};
const getMeasuresOnTeamMetadata = (teamMetadata, logger) => {
    const measuresCombined = [];
    teamMetadata.map((x) => {
        const measures = getTeamMetadata(x, 'Measurements');
        if (measures) {
            try {
                const parsedMeasures = JSON.parse(measures);
                measuresCombined.push(...parsedMeasures);
            }
            catch (e) {
                if (logger) {
                    logger.error('unable to parse measure from team metadata');
                }
            }
        }
    });
    return measuresCombined;
};
const uniqAndSort = (items) => {
    const unique = uniqBy((x) => x.name, items);
    unique.sort((a, b) => a.name.localeCompare(b.name));
    return unique;
};
const getMCsFromStudyAndTeamMetadata = (item, logger) => {
    const mcsCombined = [];
    const mcsForTech = item.metadata.studies.flatMap((x) => x.study.medicalConditions);
    const mappedToFullMC = item.metadata.allStudyMCs.filter((x) => { var _a; return (_a = mcsForTech.includes(x.id)) !== null && _a !== void 0 ? _a : []; });
    //collect all medical conditions from study metadata
    mcsCombined.push(...mappedToFullMC);
    //collect all medical conditions from team metadata
    const teamMetadataMCs = getMCsOnTeamMetadata(item.metadata.teamMetadata, logger);
    mcsCombined.push(...teamMetadataMCs);
    return uniqAndSort(mcsCombined);
};
const getMeasuresFromStudyAndTeamMetadata = (item, logger) => {
    const measuresCombined = [];
    //collect all measures from study metadata
    const studyMeasures = getMeasuresOnStudies(item.metadata.studies.map((x) => x.study), item.technology.id);
    measuresCombined.push(...studyMeasures);
    //collect all measures from team metadata
    const teamMetadataMeasures = getMeasuresOnTeamMetadata(item.metadata.teamMetadata, logger);
    measuresCombined.push(...teamMetadataMeasures);
    return uniqAndSort(measuresCombined);
};
export { getMCsFromStudyAndTeamMetadata, getMeasuresFromStudyAndTeamMetadata };
