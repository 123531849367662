import { Block, Spacer } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
import { PrimaryCard } from 'src/components/ResourceDetails';
const getEvidenceType = (types) => {
    if (!types) {
        return undefined;
    }
    const finalTypes = [];
    for (const type of types) {
        if (type.includes('ClinicalTrials.gov')) {
            finalTypes.push('Clinical Trial');
        }
        else {
            finalTypes.push(type);
        }
    }
    return finalTypes;
};
const getTags = (evidence) => {
    var _a;
    const types = (_a = getEvidenceType(evidence.typeOfSource)) !== null && _a !== void 0 ? _a : [];
    const tags = [];
    if (types.length > 0) {
        tags.push({ tag: types.join(', ') });
    }
    if (evidence.year) {
        tags.push({ tag: evidence.year.toString() });
    }
    if (evidence.ctId) {
        tags.push({ tag: evidence.ctId });
    }
    if (evidence.pubmedId) {
        tags.push({ tag: `PMID: ${evidence.pubmedId}` });
    }
    return tags;
};
const getEvidenceLinks = (evidence) => {
    return [{ text: 'Source', href: evidence.url }];
};
const EvidenceActions = ({ evidence, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Spacer, { size: "16px" }),
        React.createElement(Block, null,
            React.createElement(AddToWorkspaceButton, { resourceId: evidence.id, resourceType: 'EvidenceSource', variant: 'button', buttonContent: "Add to Review", body: "Add to your evidence review queue to revisit within your project" }))));
};
const EvidenceTitleCard = ({ evidence, }) => {
    return (React.createElement(PrimaryCard, { title: evidence.title, tags: useMemo(() => getTags(evidence), [evidence]), actions: React.createElement(EvidenceActions, { evidence: evidence }), links: getEvidenceLinks(evidence) }));
};
export { EvidenceTitleCard };
