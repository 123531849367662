import { Center, Typography, Icons, Stack, COLORS, TypographyVariant, Spacer, Button, } from '@humanfirst/elektron';
import React from 'react';
import { useIntercom } from 'src/contexts/IntercomContext';
const ErrorComponent = ({ error, }) => {
    const { showNewMessage } = useIntercom();
    return (React.createElement(Center, null,
        React.createElement(Stack, { alignItems: "center", marginTop: "100px" },
            React.createElement(Icons.Product.Limited, { size: "90px", color1: COLORS.gray2, decorative: true }),
            React.createElement(Typography, null, "There was an error while loading your data."),
            React.createElement(Typography, null, "Contact us and we'll help you find what you are looking for."),
            React.createElement(Button, { variant: "secondary", onClick: () => showNewMessage(`I have encountered the following error: ${String(error)}`) }, "Contact HumanFirst"),
            React.createElement(Spacer, { size: '8px' }),
            React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, "Technical Details"),
            React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray2 }, String(error)))));
};
export { ErrorComponent };
