import { Block, COLORS, Card, Heading2, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
const MetadataItem = ({ title, value, longText }) => {
    return (React.createElement(Block, null,
        React.createElement(Typography, { color: COLORS.gray1 }, title),
        React.createElement(Typography, { variant: longText ? TypographyVariant.Heading3 : TypographyVariant.BodyBold }, value)));
};
const StudyMetadataCard = ({ metadata, title, longText, }) => {
    if (metadata.length === 0) {
        return null;
    }
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, title),
            metadata.map((item, i) => (React.createElement(MetadataItem
            // eslint-disable-next-line react/no-array-index-key
            , Object.assign({ 
                // eslint-disable-next-line react/no-array-index-key
                key: `${item.title}-${i}`, longText: longText }, item)))))));
};
export { StudyMetadataCard };
