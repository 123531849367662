import { __awaiter } from "tslib";
import React, { useCallback } from 'react';
import { Button, Modal, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { UserProfileResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { UserProfileForm } from 'src/components/UserProfileForm/UserProfileForm';
const UpdateProfileModal = ({ isOpen, onClose, profile, user }) => {
    const { mutateAsync } = useElektraApiMutation(UserProfileResource.update(user.id));
    const onSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield mutateAsync(data);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error('unable to update user profile');
        }
        onClose();
    }), [mutateAsync, onClose]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h2", marginBottom: "24px" }, "Edit Profile"),
        React.createElement(UserProfileForm, { onSubmit: onSubmit, defaultValue: profile, submitComponent: ({ isLoading }) => (React.createElement(Stack, { gap: "16px", flexDirection: 'row-reverse' },
                React.createElement(Button, { type: "submit", size: "large", isLoading: isLoading }, "Save"),
                React.createElement(Button, { type: "button", size: "large", variant: "tertiary", onClick: onClose }, "Cancel"))) })));
};
export { UpdateProfileModal };
