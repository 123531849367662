import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const getToolWithEvidence = (tool, evidences) => {
    const filteredEvidence = (evidences === null || evidences === void 0 ? void 0 : evidences.filter((evidence) => {
        var _a;
        return (_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.some((source) => { var _a; return (_a = source.tools) === null || _a === void 0 ? void 0 : _a.some((currentTool) => currentTool.id === tool.id); });
    })) || [];
    return {
        tool: tool,
        evidenceCount: filteredEvidence.length,
        evidence: filteredEvidence,
    };
};
const getToolEvidenceSortValue = (item, evidence) => {
    const toolWithEvidenceCount = getToolWithEvidence(item, evidence);
    return String(toolWithEvidenceCount.evidenceCount);
};
const useShouldSeeUpgradeBanner = () => {
    const canViewEvidence = useHasPermission('component.evidenceSummary', 'read');
    const canViewRegulatoryDecisions = useHasPermission('component.regulatoryDecisions', 'read');
    const canViewMedicalConditions = useHasPermission('searchResult.medicalConditions', 'read');
    const canViewMeasures = useHasPermission('searchResult.evidenceMeasures', 'read');
    return !(canViewMeasures &&
        canViewMedicalConditions &&
        canViewRegulatoryDecisions &&
        canViewEvidence);
};
export { getToolWithEvidence, getToolEvidenceSortValue, useShouldSeeUpgradeBanner, };
