import React from 'react';
import { Block, DataTable, Link, TypographyVariant, useDataTableState, } from '@humanfirst/elektron';
import { generatePath } from 'react-router-dom';
import { PATHS } from 'src/config/path';
import { useGetMedicalConditionsForMeasureCategory, useGetToolsForMeasureCategory, } from 'src/hooks/relatedResources';
import { LimitedList } from 'src/components/LimitedList/LimitedList';
import { TechnologyColumnDisplay } from 'src/components/TechnologyColumnDisplay';
import { LoadingState } from 'src/components/LoadingState';
import { getPathFromString } from 'src/utils/measurements';
/** Renders the tech associated with a measure. */
const MeasureTech = ({ item }) => {
    const { data: tech } = useGetToolsForMeasureCategory(item);
    const sortedtech = [...(tech !== null && tech !== void 0 ? tech : [])].sort((a, b) => a.model.localeCompare(b.model));
    return (React.createElement(LimitedList, { elements: (sortedtech === null || sortedtech === void 0 ? void 0 : sortedtech.map((x) => (React.createElement(TechnologyColumnDisplay, { key: x === null || x === void 0 ? void 0 : x.id, tool: x })))) || [], emptyText: 'None meeting Atlas criteria', limit: 1 }));
};
/** Renders the medical conditions associated with a measure. */
const MeasureMedicalConditions = ({ item, }) => {
    const { data: medicalConditions, isLoading } = useGetMedicalConditionsForMeasureCategory(item);
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    const sortedMedicalConditions = [...(medicalConditions !== null && medicalConditions !== void 0 ? medicalConditions : [])].sort((a, b) => a.name.localeCompare(b.name));
    return (React.createElement(LimitedList, { limit: 1, elements: (sortedMedicalConditions === null || sortedMedicalConditions === void 0 ? void 0 : sortedMedicalConditions.map((m) => (React.createElement(Block, { key: m.id },
            React.createElement(Link, { typographyVariant: TypographyVariant.Details, href: generatePath(PATHS.atlas.medicalConditions.detail, {
                    id: m.id,
                }) }, m.name))))) || [] }));
};
const MeasureNameCell = ({ measure }) => {
    const name = getPathFromString(measure.category).pop();
    return (React.createElement(Link, { href: generatePath(PATHS.atlas.measures.detail, {
            id: measure.id,
        }) }, name));
};
const DEFAULT_EMPTY_COLUMNS = [];
const MeasureTableSimple = ({ measures, extraColumns = DEFAULT_EMPTY_COLUMNS, initialState, extraActions, }) => {
    const columns = [
        {
            id: 'measure',
            header: 'Measure',
            render: (item) => React.createElement(MeasureNameCell, { measure: item }),
            sortable: true,
            getStringValue: (item) => item.category,
        },
        {
            id: 'tech',
            header: 'Technology',
            render: (item) => React.createElement(MeasureTech, { item: item }),
        },
        {
            id: 'medicalConditions',
            header: 'Medical Condition',
            render: (item) => (React.createElement(MeasureMedicalConditions, { item: item })),
        },
        ...extraColumns,
    ];
    const result = useDataTableState({ columns, sortable: true, selectable: !!extraActions }, measures, initialState);
    return (React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: extraActions })));
};
export { MeasureTableSimple };
