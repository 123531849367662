import { Banner, Block, Breakpoint, Card, COLORS, DisplayBreakpoint, Spacer, Spread, Stack, StatefulPanel, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { WorkspaceTemplateSummaryStats } from 'src/components/WorkspaceTemplateSummaryStats';
import { useShowModalBound } from 'src/hooks/modal';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { TEMPLATES } from './data';
const RequestTemplateContent = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, align: 'center' }, "Want to request a template?"),
        React.createElement(Typography, { color: COLORS.gray1, align: 'center' }, "Not seeing a template that could help with your research? Let us know your research area and we\u2019ll work to add it!"),
        React.createElement(Block, null,
            React.createElement(ContactUsFormTrigger, { location: `Template Request`, contactType: 'General Contact', buttonVariant: 'secondary' }, "Request a Template"))));
};
const RequestTemplateCard = () => {
    return (React.createElement(Card, { backgroundColor: COLORS.gray4, variant: "secondary" },
        React.createElement(Stack, { alignItems: ['start', 'start', 'center', 'center'], minHeight: ['0px', '0px', '0px', '160px'], height: "100%" },
            React.createElement(RequestTemplateContent, null))));
};
const SingleTemplateCard = ({ template, }) => {
    const showModal = useShowModalBound('apply-workspace-template', {
        template,
    });
    return (React.createElement(Card, { onClick: showModal },
        React.createElement(Spread, { direction: ['horizontal', 'horizontal', 'horizontal', 'vertical'], alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'], minHeight: ['0px', '0px', '0px', '160px'], gap: "16px", height: "100%", minWidth: '176px' },
            React.createElement(DisplayBreakpoint, { min: Breakpoint.M },
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, color: COLORS.primary }, template.name),
                    React.createElement(Typography, { color: COLORS.gray1 }, template.shortDescription),
                    React.createElement(WorkspaceTemplateSummaryStats, { template: template }))),
            React.createElement(DisplayBreakpoint, { max: Breakpoint.S },
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, color: COLORS.primary }, template.name),
                    React.createElement(Typography, { color: COLORS.gray1 }, template.shortDescription),
                    React.createElement(WorkspaceTemplateSummaryStats, { template: template, direction: 'horizontal' }))))));
};
const WorkspaceTemplates = ({ hasWorkspaces, }) => {
    return (React.createElement(StatefulPanel, { initialState: { expanded: true }, title: React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Templates"),
            React.createElement(AnchoredTooltip, { triggerType: "hover" },
                React.createElement(Stack, { width: '360px' },
                    React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Speed Up Your Data Collection with Template Projects"),
                    React.createElement(Typography, null, "Template projects allow you to create a project from an existing template of information.")))) },
        React.createElement(Block, { padding: "24px" },
            React.createElement(When, { condition: !hasWorkspaces },
                React.createElement(Banner, { variant: 'information', title: 'Speed Up Your Data Collection with Template Projects', content: 'Template projects allow you to create a project from an existing template of information.' }),
                React.createElement(Spacer, { size: "24px" })),
            React.createElement(Block, { width: "100%", display: "grid", gridTemplateColumns: [
                    '1fr',
                    '1fr',
                    '1fr 1fr 1fr 1fr',
                    '1fr 1fr 1fr 1fr',
                ], gridGap: ['8px', '8px', '8px', '24px'] },
                TEMPLATES.map((template) => (React.createElement(SingleTemplateCard, { key: template.id, template: template }))),
                React.createElement(RequestTemplateCard, null)))));
};
export { WorkspaceTemplates };
