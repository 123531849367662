import { useElektraApi, EvidenceResource } from '@humanfirst/use-elektra-api';
import { useMemo } from 'react';
const useEvidenceFacet = (facetName, filters) => {
    const result = useElektraApi(EvidenceResource.list({
        filter: filters,
        fields: [facetName],
    }));
    const returnData = useMemo(() => {
        var _a;
        return Array.from(new Set((_a = result.data) === null || _a === void 0 ? void 0 : _a.flatMap((evidence) => {
            const facetValue = evidence[facetName];
            if (!facetValue || typeof facetValue === 'boolean') {
                return [];
            }
            return Array.isArray(facetValue) ? facetValue : [facetValue];
        })));
    }, [result.data, facetName]);
    return {
        isLoading: false,
        data: returnData,
    };
};
export { useEvidenceFacet };
