import { reducer as treeStateReducer } from '@humanfirst/elektron';
import { mapTree } from 'src/utils/tree';
/**
 * Bubbles the visibility state throughout the tree.
 *
 * Essentially if any piece of a node's path is visib.le that node will
 * be visible. Thus we have to buble visibility in both directions, from
 * the parents down to the children, and from the children up to the parents.
 * @param tree
 */
const bubbleVisible = (tree, parentVisible = false) => {
    const children = tree.children.map((node) => bubbleVisible(node, tree.value.isVisible));
    const anyVisible = children.some((x) => x.value.isVisible);
    return {
        children,
        value: Object.assign(Object.assign({}, tree.value), { isVisible: anyVisible || tree.value.isVisible || parentVisible, isOpen: anyVisible }),
    };
};
/**
 * Apply a reducer for the ontology tree state.
 *
 * This is mostly a wrapper around the BetterTree reducer, but includes some
 * more complex functionality like search
 * @param state
 * @param action
 * @returns
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'applyMeasures':
            // Find all nodes that we want to mark as visible.
            const visibleTree = mapTree((x) => {
                var _a;
                const isMatch = action.measures.some((y) => y.query === x.content.measure);
                const matchHighlight = isMatch
                    ? (_a = action.measures.find((y) => y.query === x.content.measure)) === null || _a === void 0 ? void 0 : _a.highlight
                    : undefined;
                return Object.assign(Object.assign({}, x), { isVisible: action.measures.some((y) => y.query === x.content.measure), isMatch,
                    matchHighlight });
            }, state);
            return bubbleVisible(visibleTree);
        case 'clearMeasures':
            return mapTree((x) => (Object.assign(Object.assign({}, x), { isActive: true, isOpen: false, isVisible: true })), state);
        default:
            return treeStateReducer(state, action);
    }
};
export { reducer };
