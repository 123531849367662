import { __rest } from "tslib";
import React, { useMemo } from 'react';
import { Center, DataTable, Stack, Typography, useDataTableState, } from '@humanfirst/elektron';
import { EmptyStateOtherOptions } from 'src/components/EmptyStateOtherOptions';
import { useToggle } from 'src/hooks/toggle';
import { useTrackDataTableRefinement } from 'src/hooks/analytics/track-table-refinement';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { PATHS } from 'src/config/path';
import { ConnectOptionsButton } from 'src/components/Connect/ConnectOptionsButton';
import { useCustomizedSchema } from '../hooks/use-customized-schema';
import { useDataTableUrlMemory } from '../hooks/use-data-table-url-memory';
import { useDataTableUrlAlgolia } from '../hooks/use-data-table-url-algolia';
import { SearchBookmarkButton } from '../shared/SearchBookmarkButton';
import { useColumns } from './columns';
import { SelectionActions } from './components/SelectionActions';
import { useToolFilters } from './filters';
/** Initial state for the tables, sorted by product. */
const DEFAULT_INITIAL_STATE = { sort: { column: 'product', ascending: true } };
/** Actions that include the save to workspace functionality. */
const TableActions = ({ hideCustomFilter = false, }) => (React.createElement(Stack, { direction: 'horizontal' },
    React.createElement(SelectionActions, { hasSaveToWorkspace: true }),
    hideCustomFilter ? null : React.createElement(ConnectOptionsButton, null)));
/** Help center link to help people understand our filters. */
const HELP_CENTER_LINK = (React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.gettingStarted.filters }));
/** Empty element when a search has no results. */
const EMPTY_ELEMENT = React.createElement(EmptyStateOtherOptions, null);
const ALGOLIA_CONFIGURATION = {
    searchType: 'Technology',
    getSortIndex: (state) => {
        var _a, _b;
        if (((_a = state.sort) === null || _a === void 0 ? void 0 : _a.column) === 'product') {
            if (state.sort.ascending) {
                return 'tech-name-asc';
            }
            else {
                return 'tech-name-desc';
            }
        }
        if (((_b = state.sort) === null || _b === void 0 ? void 0 : _b.column) === 'evidence') {
            if (state.sort.ascending) {
                return 'evidence-asc';
            }
            else {
                return 'evidence-desc';
            }
        }
        return undefined;
    },
};
/** Generates a stable schema based on columns/filters using user permission. */
const useBaseSchema = () => {
    const columns = useColumns();
    const filters = useToolFilters();
    return useMemo(() => ({
        columns,
        filters,
        selectable: true,
    }), [columns, filters]);
};
/** Generates a stable schema, without filters or searching. */
const useSimpleBaseSchema = () => {
    const columns = useColumns();
    return useMemo(() => ({
        columns,
        searchable: false,
        selectable: true,
    }), [columns]);
};
/** Generates a stable schema for Algolia backed instances. */
const useBaseAlgoliaSchema = () => {
    const columns = useColumns();
    const filters = useToolFilters(true);
    const customColumns = useToggle('custom-columns');
    return useMemo(() => ({
        columns,
        filters,
        selectable: true,
        configurableColumns: customColumns,
    }), [columns, filters, customColumns]);
};
/**
 * A table that can handle tools.
 *
 * This version uses the URL for state storage, includes `addToWorkspace` functionality,
 * and has filters/search capabilities.
 */
export const ToolTable = (_a) => {
    var { data, initialState = DEFAULT_INITIAL_STATE, scope, hideCustomFilter = false } = _a, props = __rest(_a, ["data", "initialState", "scope", "hideCustomFilter"]);
    const schema = useBaseSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, data, initialState, scope);
    useTrackDataTableRefinement('technologies', result);
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "technologies", postSelectionActions: React.createElement(TableActions, { hideCustomFilter: hideCustomFilter }), emptyElement: React.createElement(Center, null,
            React.createElement(Typography, null, "No Technologies")) })));
};
export const ScopedToolTable = (_a) => {
    var { initialState, scope, extraActions } = _a, props = __rest(_a, ["initialState", "scope", "extraActions"]);
    const schema = useBaseAlgoliaSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlAlgolia(finalSchema, ALGOLIA_CONFIGURATION, initialState, scope);
    useTrackDataTableRefinement('technologies', result);
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "technologies", postSelectionActions: React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
            React.createElement(TableActions, null),
            extraActions), filterHelper: HELP_CENTER_LINK, emptyElement: EMPTY_ELEMENT, postControlsEnhancer: React.createElement(SearchBookmarkButton, Object.assign({ resourceType: "ToolSearch", scope: scope }, result)) })));
};
/**
 * A simplified ToolTable.
 *
 * Very similar to the tool table, but
 *  - Does not enable refinement/filters.
 *  - Stores state in memory instead of URL.
 */
export const SimpleToolTable = (_a) => {
    var { data, initialState = DEFAULT_INITIAL_STATE, extraActions } = _a, props = __rest(_a, ["data", "initialState", "extraActions"]);
    const schema = useSimpleBaseSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableState(finalSchema, data, initialState);
    return (React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
            React.createElement(SelectionActions, null),
            extraActions) })));
};
