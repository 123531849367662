import { Banner, Block } from '@humanfirst/elektron';
import React from 'react';
import { t } from 'src/constants/i18n';
import { useShowModalBound } from 'src/hooks/modal';
const UpgradeBanner = ({ body, title, actionLabel = t('actions.contactUs'), actionType = 'Upgrade Account', }) => {
    const showModal = useShowModalBound('contact-us', {
        location: 'Upgrade Banner',
        contactType: actionType,
    });
    return (React.createElement(Block, null,
        React.createElement(Banner, { variant: "information", content: body, title: title, actionLabel: actionLabel, onAction: showModal })));
};
export { UpgradeBanner };
