import { __awaiter } from "tslib";
import { NotificationType } from '@humanfirst/api-models';
import { UserNotificationSettingsResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { MARKETING_EMAILS_LAST_PROPMT_DATE } from 'src/constants/persistentKeys';
import { usePersistentState } from 'src/hooks/persistence';
import { getCurrentEnvironement } from 'src/utils/environment';
/* Set the last seen date to right now. */
export const useSetLastSeenDate = () => {
    const [, setLastSeenMarketingPrompt] = usePersistentState(MARKETING_EMAILS_LAST_PROPMT_DATE);
    return useCallback(() => {
        setLastSeenMarketingPrompt(dayjs().toISOString());
    }, [setLastSeenMarketingPrompt]);
};
/**
 * Set the subscribe status to "subscribed". Right now we only allow setting to "subscribed" here.
 * In the future we may want to allow additonal statuses.
 */
export const useSetStatus = () => {
    const setLastSeenDate = useSetLastSeenDate();
    const { mutateAsync } = useElektraApiMutation(UserNotificationSettingsResource.updatePreferences(NotificationType.newsletter));
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield mutateAsync({ isActive: true });
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        finally {
            setLastSeenDate();
        }
    }), [setLastSeenDate, mutateAsync]);
};
export const useIsTrayOpen = () => {
    const [lastSeenMarketingPromt] = usePersistentState(MARKETING_EMAILS_LAST_PROPMT_DATE);
    const { data, isLoading } = useElektraApi(UserNotificationSettingsResource.list());
    const newsletterSetting = useMemo(() => data === null || data === void 0 ? void 0 : data.find((setting) => setting.name === NotificationType.newsletter), [data]);
    const isOpen = useMemo(() => {
        const env = getCurrentEnvironement();
        if (env === 'e2e') {
            return false;
        }
        // We will only show this tray if the user is not subscribed.
        if (!(newsletterSetting === null || newsletterSetting === void 0 ? void 0 : newsletterSetting.isActive)) {
            // If the user has never seen the marketing prompt, we will show it.
            if (!lastSeenMarketingPromt) {
                return true;
            }
            if (lastSeenMarketingPromt &&
                typeof lastSeenMarketingPromt === 'string') {
                const lastSeen = dayjs(lastSeenMarketingPromt);
                const now = dayjs();
                const monthsPassed = now.diff(lastSeen, 'month');
                // If the user has seen the marketing prompt more than 6 months ago, we will show it.
                if (monthsPassed >= 6) {
                    return true;
                }
            }
        }
        return false;
    }, [newsletterSetting, lastSeenMarketingPromt]);
    return { isOpen, isLoading };
};
