var _a;
import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef, useCallback, createContext, } from 'react';
import { useLogger } from 'src/hooks/logger';
const CURRENT_VERSION = (_a = process.env.REACT_APP_VERSION) !== null && _a !== void 0 ? _a : '0.0.0';
const VERSION_SELECTOR = 'meta[name="deploy-version"]';
const CHECK_INTERVAL = 5 * 60 * 1000; // Every 5 minutes
const VersionContext = createContext({
    version: CURRENT_VERSION,
    latestVersion: CURRENT_VERSION,
    isUpdateAvailable: false,
});
const VersionProvider = ({ checkInterval = CHECK_INTERVAL, children }) => {
    const [latestVersion, setLatestVersion] = useState(CURRENT_VERSION);
    const isMountedRef = useRef(false);
    const logger = useLogger(VersionProvider.name);
    const checkVersion = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let versionContent = undefined;
        try {
            const response = yield fetch(new URL('/', document.location.toString()).toString(), {
                cache: 'reload',
            });
            const html = yield response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const versionElement = doc.querySelector(VERSION_SELECTOR);
            versionContent = (_a = versionElement === null || versionElement === void 0 ? void 0 : versionElement.getAttribute('content')) !== null && _a !== void 0 ? _a : undefined;
        }
        catch (_b) {
            // Do nothing, versionContent wil be undefined
        }
        if (isMountedRef.current && versionContent) {
            // Don't change the state if we've unmounted
            setLatestVersion(versionContent);
        }
    }), [isMountedRef]);
    useEffect(() => {
        const interval = setInterval(checkVersion, checkInterval);
        checkVersion().catch((e) => logger.error(e));
        return () => clearInterval(interval);
    }, [checkVersion, checkInterval, logger]);
    // Track the mounted status for use async
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    const isUpdateAvailable = latestVersion !== undefined && latestVersion !== CURRENT_VERSION;
    return (React.createElement(VersionContext.Provider, { value: {
            version: CURRENT_VERSION,
            latestVersion,
            isUpdateAvailable,
        } }, children));
};
export { VersionContext, VersionProvider };
