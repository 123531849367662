import { __rest } from "tslib";
import { useController, } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
const isFieldError = (error) => {
    return (!!error &&
        typeof error === 'object' &&
        'message' in error &&
        'type' in error &&
        typeof error.type === 'string');
};
const renderErrorMessage = (error, rules) => {
    if (!error) {
        return undefined;
    }
    else if (isFieldError(error)) {
        return error.message;
    }
    else if (error.type === 'required') {
        return 'This field is required';
    }
    else if (error.type === 'maxLength') {
        const rule = rules === null || rules === void 0 ? void 0 : rules.maxLength;
        const maxLength = typeof rule === 'object' && 'value' in rule ? rule.value : rule;
        return `This field must not be longer than ${maxLength !== null && maxLength !== void 0 ? maxLength : 0} characters`;
    }
    else if (error.type === 'minLength') {
        const rule = rules === null || rules === void 0 ? void 0 : rules.minLength;
        const minLength = typeof rule === 'object' && 'value' in rule ? rule.value : rule;
        return `This field must contain at least ${minLength !== null && minLength !== void 0 ? minLength : 0} characters`;
    }
    else {
        return `Unknown Error`;
    }
};
function useSmartFormController(_a) {
    var { inputRef } = _a, props = __rest(_a, ["inputRef"]);
    const controller = useController(props);
    const rules = props.rules;
    const fieldError = controller.fieldState.error;
    const error = useMemo(() => renderErrorMessage(fieldError, rules), [fieldError, rules]);
    const controllerRef = controller.field.ref;
    const ref = useCallback((element) => {
        if (typeof inputRef === 'function') {
            inputRef(element);
        }
        else if (inputRef) {
            inputRef.current = element;
        }
        controllerRef(element);
    }, [inputRef, controllerRef]);
    return Object.assign(Object.assign({}, controller), { field: Object.assign(Object.assign({}, controller.field), { ref }), error });
}
export { renderErrorMessage, useSmartFormController, };
