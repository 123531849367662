import { Stack, Block, COLORS, TypographyVariant, Typography, Spacer, Card, Spread, Divider, Link, SemanticButton, } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { LoadingState } from '../../../../components/LoadingState';
import { getMedicalConditionEvidence, } from '../utils/utils';
import { useGetEvidenceForMeasureCategory, useGetMedicalConditionsForMeasureCategory, } from '../../../../hooks/relatedResources';
const HEALTHY_MEDICAL_CONDITIONS = [
    'None - Healthy Participants',
    'General Population',
];
const filterHealthy = (medicalConditions) => {
    return medicalConditions.filter((x) => !HEALTHY_MEDICAL_CONDITIONS.includes(x.name));
};
const TopMedicalConditionRow = ({ medicalCondition, showEvidence, hasEvidencePermission }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Spacer, null),
        React.createElement(Spread, null,
            React.createElement(Link, { href: generatePath(PATHS.atlas.medicalConditions.detail, {
                    id: medicalCondition.id,
                }) }, medicalCondition.name),
            React.createElement(If, { condition: hasEvidencePermission },
                React.createElement(Then, null,
                    React.createElement(SemanticButton, { onClick: showEvidence
                            ? () => showEvidence('MedicalCondition', medicalCondition.name)
                            : undefined },
                        React.createElement(Typography, { color: COLORS.primary }, medicalCondition.evidenceCount))),
                React.createElement(Else, null,
                    React.createElement(Typography, null, medicalCondition.evidenceCount))))));
};
const MedicalConditionsData = ({ medicalConditions, isLoading, showEvidence, hasEvidencePermission, }) => {
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    if (medicalConditions.length === 0) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Spacer, null),
            React.createElement(Typography, { color: COLORS.gray2 }, "No medical conditions listed"),
            React.createElement(Spacer, { size: '24px' })));
    }
    return (React.createElement(React.Fragment, null, medicalConditions === null || medicalConditions === void 0 ? void 0 : medicalConditions.map((mc) => (React.createElement(TopMedicalConditionRow, { showEvidence: showEvidence, medicalCondition: mc, key: mc.id, hasEvidencePermission: hasEvidencePermission })))));
};
const TopMedicalConditionsCard = ({ medicalConditions, isLoading, showEvidence, hasEvidencePermission, }) => {
    var _a;
    const sortedMCs = [...medicalConditions].sort((a, b) => { var _a, _b; return ((_a = b.evidenceCount) !== null && _a !== void 0 ? _a : 0) - ((_b = a.evidenceCount) !== null && _b !== void 0 ? _b : 0); });
    const topMedicalConditions = (_a = filterHealthy(sortedMCs)) === null || _a === void 0 ? void 0 : _a.slice(0, 5);
    return (React.createElement(Card, null,
        React.createElement(Block, null,
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading1 }, 'Top Medical Conditions'),
                React.createElement(AnchoredTooltip, null, t('measureDetailPage.topMedicalConditionsToolTip'))),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Spread, null,
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "MEDICAL CONDITION"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "EVIDENCE")),
            React.createElement(Divider, null),
            React.createElement(MedicalConditionsData, { medicalConditions: topMedicalConditions, isLoading: isLoading, showEvidence: showEvidence, hasEvidencePermission: hasEvidencePermission }))));
};
const TopMedicalConditionsForCategory = ({ category, showEvidence, hasEvidencePermission }) => {
    const { isLoading: isMedicalConditionLoading, data: conditions } = useGetMedicalConditionsForMeasureCategory(category);
    const { data: evidence, isLoading: isEvidenceLoading } = useGetEvidenceForMeasureCategory(category);
    const medicalConditionsWithEvidence = getMedicalConditionEvidence(conditions !== null && conditions !== void 0 ? conditions : [], evidence !== null && evidence !== void 0 ? evidence : []);
    return (React.createElement(TopMedicalConditionsCard, { medicalConditions: medicalConditionsWithEvidence || [], isLoading: isMedicalConditionLoading || isEvidenceLoading, showEvidence: showEvidence, hasEvidencePermission: hasEvidencePermission }));
};
export { TopMedicalConditionsCard, TopMedicalConditionsForCategory };
