import { uniq } from 'ramda';
/**
 * Buckets the sources by type and returns counts.
 */
const getEvidenceTypeCounts = (evidences) => {
    var _a, _b;
    const counts = {
        Papers: 0,
        'Clinical Trials': 0,
        Other: 0,
    };
    for (const e of evidences) {
        if ((_a = e.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('Paper')) {
            counts.Papers += 1;
        }
        else if ((_b = e.typeOfSource) === null || _b === void 0 ? void 0 : _b.includes('ClinicalTrials.gov')) {
            counts['Clinical Trials'] += 1;
        }
        else {
            counts.Other += 1;
        }
    }
    return counts;
};
/** Concatenate an array to an array within a map. */
const concatMap = (map, value, key) => {
    var _a;
    const current = (_a = map.get(key)) !== null && _a !== void 0 ? _a : [];
    map.set(key, uniq([...current, ...value]));
};
const MISSING_TECHNOLOGY_SENTINEL = 'NOT FOUND';
/**
 * Gathers all source measures by their associated technology.
 * @param item
 */
const getMeasureCategoryByTechnology = (item) => {
    var _a, _b, _c;
    // Next we go through and build up a list of the measures for each technology
    const measuresByTechnology = new Map();
    for (const sourceMeasure of (_a = item.sourceMeasures) !== null && _a !== void 0 ? _a : []) {
        if (!sourceMeasure.tools || sourceMeasure.tools.length === 0) {
            concatMap(measuresByTechnology, (_b = sourceMeasure.measureCategories) !== null && _b !== void 0 ? _b : [], MISSING_TECHNOLOGY_SENTINEL);
            continue;
        }
        for (const technology of sourceMeasure.tools) {
            concatMap(measuresByTechnology, (_c = sourceMeasure.measureCategories) !== null && _c !== void 0 ? _c : [], technology.id);
        }
    }
    return measuresByTechnology;
};
const ORDERED_AGE_GROUPS = [
    'Infants (<1 year)',
    'Children (1-10 yrs)',
    'Adolescent (11-17 yrs)',
    'Adult (18-64 yrs)',
    'Older adult (65+ yrs)',
];
const getPopulationFromEvidence = (item) => {
    var _a, _b;
    const evidencePops = uniq((_b = (_a = item.evidence) === null || _a === void 0 ? void 0 : _a.flatMap((x) => x.ageRecruitmentCriteria)) !== null && _b !== void 0 ? _b : []);
    return ORDERED_AGE_GROUPS.filter((x) => evidencePops.includes(x)).join(', ');
};
export { getEvidenceTypeCounts, getMeasureCategoryByTechnology, MISSING_TECHNOLOGY_SENTINEL, getPopulationFromEvidence, };
