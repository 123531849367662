import { Block, Button } from '@humanfirst/elektron';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLogger } from 'src/hooks/logger';
import { useUser } from 'src/hooks/user';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { LockedCard } from '../LockedCard';
const LockedApp = () => {
    const navigate = useNavigate();
    const track = useAnalyticsEvent();
    const user = useUser();
    const logger = useLogger('locked-app');
    useEffect(() => {
        const trackData = {
            email: user === null || user === void 0 ? void 0 : user.email,
            userId: user === null || user === void 0 ? void 0 : user.id,
        };
        logger.info('Locked app was shown for user', trackData);
        track('locked-app-shown', trackData);
    }, [logger, user, track]);
    const logout = useCallback(() => navigate(generatePath(PATHS.authentication.logout)), [navigate]);
    return (React.createElement(Block, { height: "100vh", width: "100vw", display: "flex", position: "relative", justifyContent: "center", alignItems: "center" },
        React.createElement(Block, { position: "absolute", top: "0", right: "0", padding: "24px" },
            React.createElement(Button, { variant: "secondary", size: "large", onClick: logout }, "Log out")),
        React.createElement(Block, { width: "50%" },
            React.createElement(LockedCard, { title: "Welcome to Atlas", body: "Get in touch with one of our representatives to access all medical conditions, measures, evidence, and related technologies.", contactType: "Upgrade Account" }))));
};
export { LockedApp };
