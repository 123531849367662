import React, { createContext, useState, useCallback, useMemo } from 'react';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { RemoveFromWorkspaceListModal } from '../components/RemoveFromWorkspaceListModal';
import { AddToWorkspaceListModal } from '../components/AddToWorkspaceListModal';
const ListContext = createContext({
    activeList: undefined,
    openRemoveModal: () => undefined,
    openAddModal: () => undefined,
});
const ListContextProvider = ({ children, activeList }) => {
    const workspaceId = useActiveWorkspaceId();
    const [bookmarksForListAction, setBookmarksForListAction] = useState(undefined);
    const [currentModal, setCurrentModal] = useState('none');
    const closeModal = useCallback(() => {
        setCurrentModal('none');
        setBookmarksForListAction(undefined);
    }, []);
    const openRemoveModal = useCallback((bookmarks) => {
        setBookmarksForListAction(bookmarks);
        setCurrentModal('remove');
    }, []);
    const openAddModal = useCallback((bookmarks) => {
        setBookmarksForListAction(bookmarks);
        setCurrentModal('add');
    }, []);
    const context = useMemo(() => ({
        activeList,
        openRemoveModal,
        openAddModal,
    }), [activeList, openRemoveModal, openAddModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListContext.Provider, { value: context }, children),
        !!activeList && !!bookmarksForListAction && !!workspaceId ? (React.createElement(RemoveFromWorkspaceListModal, { listName: activeList, bookmarks: bookmarksForListAction, workspaceId: workspaceId, onClose: closeModal, isOpen: currentModal === 'remove' })) : null,
        !!bookmarksForListAction && !!workspaceId ? (React.createElement(AddToWorkspaceListModal, { isOpen: currentModal === 'add', onClose: closeModal, bookmarks: bookmarksForListAction, workspaceId: workspaceId })) : null));
};
export { ListContext, ListContextProvider };
