import { Link, Typography } from '@humanfirst/elektron';
import React from 'react';
/** Renders a link if href is passed, otherwise just renders straight text. */
const MaybeLink = ({ href, typographyVariant, children }) => {
    if (href) {
        return (React.createElement(Link, { href: href, typographyVariant: typographyVariant }, children));
    }
    else {
        return React.createElement(Typography, { variant: typographyVariant }, children);
    }
};
export { MaybeLink };
