import { Button, Stack, Tray } from '@humanfirst/elektron';
import React from 'react';
import { t } from 'src/constants/i18n';
import { useSetStatus, useIsTrayOpen, useSetLastSeenDate } from './hooks';
const SubscribeToEmailsTray = () => {
    const setLastSeenDate = useSetLastSeenDate();
    const setStatus = useSetStatus();
    const { isOpen, isLoading } = useIsTrayOpen();
    return (React.createElement(Tray, { isOpen: !isLoading && isOpen, title: t('subscribeToEmailsTray.title'), body: t('subscribeToEmailsTray.body'), action: React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Button, { variant: "secondary", size: "large", onClick: setLastSeenDate }, t('subscribeToEmailsTray.actions.notNow')),
            React.createElement(Button, { size: "large", onClick: setStatus }, t('subscribeToEmailsTray.actions.subscribe'))) }));
};
export { SubscribeToEmailsTray };
