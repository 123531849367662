import { Card, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { PolicyAttributes } from 'src/components/ModelAttribute/PolicyAttribute';
import { getPolicies } from '../utils/util';
export const VendorDataRightsAndSecurityCard = ({ organization }) => {
    const policies = getPolicies(organization);
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Data Rights and Security"),
            React.createElement(PolicyAttributes.PrivacyPolicy, { item: policies.privacyPolicy }),
            !policies.isSamePolicy ? (React.createElement(PolicyAttributes.ProductPrivacyPolicy, { item: policies.productPrivacyPolicy })) : null,
            React.createElement(PolicyAttributes.CVDPolicy, { item: policies.cvdPolicy }),
            React.createElement(PolicyAttributes.SoftwareUpdatesPolicy, { item: policies.softwareUpdatesPolicy }),
            React.createElement(PolicyAttributes.GDPRPolicy, { item: policies.gdprPolicy }),
            React.createElement(PolicyAttributes.HIPAAPolicy, { item: policies.hipaaPolicy }))));
};
