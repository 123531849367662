import { __awaiter } from "tslib";
import React, { useCallback } from 'react';
import { Spacer, Checkbox } from '@humanfirst/elektron';
import { useElektraApiMutation, UserNotificationSettingsResource, } from '@humanfirst/use-elektra-api';
export const SingleNotificationPreference = ({ option, currentSettings }) => {
    const correctNotification = currentSettings === null || currentSettings === void 0 ? void 0 : currentSettings.find((x) => x.name === option.name);
    const wantsNotification = correctNotification
        ? correctNotification.isActive
        : true;
    const { mutateAsync: updateSettings } = useElektraApiMutation(UserNotificationSettingsResource.updatePreferences(option.name));
    const update = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield updateSettings({
            isActive: !wantsNotification,
        });
    }), [wantsNotification, updateSettings]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { checked: wantsNotification, key: option.name, labelPlacement: 'right', checkmarkType: "toggle", onChange: () => update() }, option.label),
        React.createElement(Spacer, null)));
};
