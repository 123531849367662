import { COLORS } from '@humanfirst/elektron';
/**
 * A mapping from a TechnologyTagType to data about that tag.
 */
const TECHNOLOGY_TAG_DATA = {
    evidence: {
        color: COLORS.blue3,
        title: 'Evidence-based Supported Technologies',
        description: 'Technologies that have been reported as supported in published evidence.',
    },
    vendor: {
        color: COLORS.gray3,
        title: 'Vendor-stated Supported Technologies',
        description: 'Technologies that the vendor has stated are supported.',
    },
};
export { TECHNOLOGY_TAG_DATA };
