import { Stack, Block, Link, Text } from '@humanfirst/elektron';
import React from 'react';
const SearchAndPlanJumpSection = ({ sections }) => {
    return (React.createElement(Block, null,
        React.createElement(Text, { align: "center" }, "Add more to your project."),
        React.createElement(Stack, { direction: "horizontal", gap: "16px", justifyContent: "center" }, sections
            .filter((d) => !!d.atlasLink)
            .map((def) => (React.createElement(Link, { key: def.title, href: def.atlasLink }, def.title))))));
};
export { SearchAndPlanJumpSection };
