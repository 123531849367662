import { Block, Icons, Link, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { useUser } from 'src/hooks/user';
import { OneClickForm } from 'src/components/OneClickForm';
import { PATHS } from 'src/config/path';
import { isInProgressIntegrator } from '../utils/util';
export const IntegratorComingSoon = ({ organization, }) => {
    const user = useUser();
    const ingestionInProgress = isInProgressIntegrator(organization);
    return (React.createElement(Block, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: "8px" }, ingestionInProgress ? 'In Progress...' : 'Coming Soon...'),
        React.createElement(Typography, { variant: TypographyVariant.Body },
            "This data integrator is currently being added to Atlas with a current list of their supported technologies, evidence-based measures and prior studies. Learn more about this vendor type in the",
            ' ',
            React.createElement(Link, { href: PATHS.helpCenter.usingAtlas.vendors.vendorTypes, target: "_blank" }, "Help Center"),
            ". Get notified once it's ready."),
        React.createElement(Spacer, { size: '16px' }),
        React.createElement(OneClickForm, { buttonText: "Notify Me", formData: {
                name: user === null || user === void 0 ? void 0 : user.name,
                email: user === null || user === void 0 ? void 0 : user.email,
                vendor: organization.name,
            }, name: "Request Data Integrator", successElement: React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                React.createElement(Icons.Product.Yes, { size: "2em", decorative: true }),
                React.createElement(Typography, null, "You will be notified once this provider has been added.")) })));
};
