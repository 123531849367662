import { Block, Card, Checkbox, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { ToggleContext } from 'src/contexts/ToggleContext';
import { groupToggles } from './groupToggles';
import { ToggleDetails } from './ToggleDetails';
const TogglePanelControlled = ({ toggles, setToggleValue, disabled }) => {
    const groups = groupToggles(toggles);
    const keys = Object.keys(groups).sort();
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" }, keys.map((key) => (React.createElement(Stack, { key: key },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, key),
            groups[key].map((toggle) => (React.createElement(Spread, { key: toggle.name },
                React.createElement(ToggleDetails, { toggle: toggle }),
                React.createElement(Block, { marginLeft: '16px' },
                    React.createElement(Checkbox, { checked: toggle.value, onChange: () => setToggleValue(toggle.name, !toggle.value), disabled: disabled })))))))))));
};
/**
 * Renders a panel for controlling toggles.
 * @returns
 */
const TogglePanel = () => {
    const { toggles, setToggleValue, isLoading } = useContext(ToggleContext);
    return (React.createElement(TogglePanelControlled, { toggles: toggles, setToggleValue: setToggleValue, disabled: isLoading }));
};
export { TogglePanel, TogglePanelControlled };
