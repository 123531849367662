export const checkIsCEMarked = (item) => {
    return (item.ceMark &&
        [
            'CE marked (class unspecified)',
            'Class I',
            'Class IIa',
            'Class IIb',
            'Class III',
        ].includes(item.ceMark));
};
export const checkIsFdaQualifiedSurvey = (item) => {
    var _a;
    return (_a = item.fdaClassifications) === null || _a === void 0 ? void 0 : _a.includes('FDA Qualified Survey');
};
export const checkIsMDDTQualifiedSurvey = (item) => {
    return item.mddtClassification === 'MDDT Qualified COA';
};
