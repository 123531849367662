import { Center, Text } from '@humanfirst/elektron';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { getRollbarInstance } from 'src/utils/rollbar';
const NotFound = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        // Send this 404 off to Rollbar
        getRollbarInstance().warning(`Page Not Found: ${pathname}`, { pathname });
    }, [pathname]);
    return (React.createElement(ContentContainer, null,
        React.createElement(PageTitle, { title: "Not Found" }),
        React.createElement(Center, null,
            React.createElement(Text, null, "We couldn't find what you are looking for."))));
};
export { NotFound };
