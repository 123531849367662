import { Stack, Spinner, FilterType, Heading2, TypographyVariant, Spacer, Typography, COLORS, } from '@humanfirst/elektron';
import React from 'react';
import { ScopedStudyTable } from 'src/components/tables/StudyTable';
import { useUser } from 'src/hooks/user';
import { useGetTeamMetadataForTool } from 'src/utils/teamMetadata';
import { DhtInventoryCard } from './DhtInventoryCard';
import { GenericMarkdownCard } from './GenericMarkdownCard';
import { MeasuresCard } from './MeasuresCard';
import { MedicalConditionCard } from './MedicalConditionCard';
import { RiskAssessmentCard } from './RiskAssessmentCard';
export const ConnectToolCardsInternal = ({ tool, teams }) => {
    const { isLoading, data: teamResourceMetadata } = useGetTeamMetadataForTool(tool.id, teams);
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    if (!teamResourceMetadata || teamResourceMetadata.length === 0) {
        return (React.createElement(Stack, { paddingBottom: '32px' },
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Heading3 }, "No additional data has been added by your organization. If you believe this is an error, please contact your organization administrator.")));
    }
    return (React.createElement(Stack, { gap: "32px" },
        teamResourceMetadata.map((singleMetadata) => (React.createElement(Stack, { gap: "32px", direction: "horizontal", key: singleMetadata.id },
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(RiskAssessmentCard, { metadata: singleMetadata }),
                React.createElement(GenericMarkdownCard, { metadata: singleMetadata, title: 'Data Validation Summary' })),
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(MeasuresCard, { metadata: singleMetadata }),
                React.createElement(MedicalConditionCard, { metadata: singleMetadata }),
                React.createElement(GenericMarkdownCard, { metadata: singleMetadata, title: 'Software' }),
                React.createElement(DhtInventoryCard, { metadata: singleMetadata }))))),
        React.createElement(Spacer, null)));
};
export const ConnectToolCards = ({ tool }) => {
    var _a;
    const user = useUser();
    const teams = (_a = user === null || user === void 0 ? void 0 : user.teams) !== null && _a !== void 0 ? _a : [];
    return (React.createElement(Stack, { gap: '32px' },
        React.createElement(ConnectToolCardsInternal, { tool: tool, teams: teams }),
        React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, "Studies"),
        React.createElement(ScopedStudyTable, { scope: [
                {
                    fieldId: 'technologies',
                    filterType: FilterType.EQUALS,
                    filterValue: tool.id,
                },
            ] })));
};
