import { __awaiter } from "tslib";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from 'src/config/api';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useHandleRefreshToken, useStopShadowing, useIsShadowing, } from './authentication';
import { useLogger } from './logger';
let refreshTokenPromise = null;
const useAxiosInstance = () => {
    const refreshToken = useHandleRefreshToken();
    const isShadowing = useIsShadowing();
    const navigate = useNavigate();
    const logger = useLogger('useAxiosInstance');
    const stopShadowing = useStopShadowing();
    useEffect(() => {
        const responseInterceptor = axiosInstance.interceptors.response.use((response) => response, (error) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            const originalRequest = error.config;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && // Unauthorized. Token has expired or is invalid.
                !!((_b = originalRequest.headers) === null || _b === void 0 ? void 0 : _b.Authorization) && // We have a token and attempted to make an authenticated call.
                !originalRequest.sent && // We haven't already retried this request. Specifically for the refresh token request we don't want to go into an infinite loop.
                !(originalRequest === null || originalRequest === void 0 ? void 0 : originalRequest.skipInterceptor) // We haven't explicitly skipped the interceptor. This is for the revoke and refresh token grant requests. In that those cases, if we get a 401 response, we don't want to try to refresh the token.
            ) {
                if (isShadowing) {
                    // If the user is shadowing, we don't want to refresh the token. Just stop shadowing.
                    yield stopShadowing();
                    return;
                }
                if (((_c = error.response.data) === null || _c === void 0 ? void 0 : _c.message) ===
                    'must authenticate with MFA') {
                    navigate(generatePath(PATHS.authentication.mfa));
                    return;
                }
                originalRequest.sent = true;
                if (!refreshTokenPromise) {
                    refreshTokenPromise = refreshToken();
                }
                try {
                    const newToken = yield refreshTokenPromise;
                    if (!newToken) {
                        logger.error('Refresh request returned undefined token');
                        navigate(generatePath(PATHS.authentication.logout), {
                            state: { expired: true },
                        });
                        return;
                    }
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    // Retry the original request with the new token
                    return yield axiosInstance(originalRequest);
                }
                catch (e) {
                    logger.error(e, {
                        message: 'Failed to refresh token',
                    });
                    navigate(generatePath(PATHS.authentication.logout), {
                        state: { expired: true },
                    });
                }
                finally {
                    refreshTokenPromise = null;
                }
            }
            // For all other errors just reject
            // eslint-disable-next-line promise/no-promise-in-callback
            return yield Promise.reject(error);
        }));
        return () => {
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [refreshToken, isShadowing, stopShadowing, navigate, logger]);
    return axiosInstance;
};
export { useAxiosInstance };
