import React, { useMemo } from 'react';
import { DataTable, Heading2, Stack, TypographyVariant, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { deserializeWorkspaceMetadata, groupToolAndMeasures, splitMetadata, } from 'src/utils/workspaceMetadata';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { useDataTableUrlMemory } from 'src/components/tables/hooks/use-data-table-url-memory';
import { ProductCell } from 'src/components/tables/ToolTable/cells';
import { ConceptOfInterestLink } from './ConceptOfInterestLink';
/** A column for the model of the tool. */
const MODEL_COLUMN = {
    id: 'technology',
    header: 'Technology',
    render: (item) => React.createElement(ProductCell, { item: item.tool }),
};
/** A columrn for measures for the tool. */
const MEASURES_COLUMN = {
    id: 'measures',
    header: 'Measures',
    render: ({ metadata }) => (React.createElement(Stack, null, metadata === null || metadata === void 0 ? void 0 : metadata.measures.map((x) => x.conceptsOfInterest ? (React.createElement(ConceptOfInterestLink, { key: x.conceptsOfInterest, conceptOfInterest: x.conceptsOfInterest, measureId: x.measureId })) : null))),
};
/** Table schema for the technologies tabel. */
const SCHEMA = {
    columns: [MODEL_COLUMN, MEASURES_COLUMN],
};
/** A simple data table that represents tools within a study. */
const InternalTechnologiesTable = ({ data, }) => {
    const result = useDataTableUrlMemory(SCHEMA, data);
    return React.createElement(DataTable, Object.assign({}, result));
};
/** Load tools with attached metadata. */
const useLoadToolData = (study) => {
    var _a;
    // Parse the tool info out of the metadata.
    const toolInfo = useMemo(() => {
        const parsedMetadata = deserializeWorkspaceMetadata(study.additionalMetadata);
        const { measureMetadata } = splitMetadata(parsedMetadata);
        return groupToolAndMeasures(measureMetadata);
    }, [study]);
    // Only make API requests if we have tools to fetch.
    const enabled = toolInfo.length > 0;
    // Load the tools from the API by their IDs
    const result = useElektraApi(ToolResource.getBulk(toolInfo.map((x) => x.toolId), { join: ['maker'] }), { enabled });
    // Repackage for later consumption after transforming the data to combine
    // tools with their metadata.
    return {
        isEnabled: enabled,
        result: Object.assign(Object.assign({}, result), { data: (_a = result.data) === null || _a === void 0 ? void 0 : _a.map((tool) => ({
                tool,
                metadata: toolInfo.find((y) => y.toolId === tool.id),
            })) }),
    };
};
const TechnologiesTable = ({ study }) => {
    const { isEnabled, result } = useLoadToolData(study);
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Technologies"),
        isEnabled ? (React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: InternalTechnologiesTable }))) : (React.createElement(InternalTechnologiesTable, { data: [] }))));
};
export { TechnologiesTable };
