import React from 'react';
import { Card, Stack, Button, Typography, TypographyVariant, Icons, Heading2, } from '@humanfirst/elektron';
import { generatePath } from 'react-router';
import { When } from 'react-if';
import { FormResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useRelatedTechnology } from 'src/hooks/related';
import { PATHS } from 'src/config/path';
import { ToolSummary } from 'src/components/ToolSummary';
import { useUser } from 'src/hooks/user';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
const SimilarTool = ({ tool }) => {
    return (React.createElement(Stack, { direction: "horizontal", gap: "16px" },
        React.createElement(ToolSummary, { tool: tool, href: generatePath(PATHS.atlas.technologies.detail, { id: tool.id }) })));
};
const SimilarCardUsefulnessPrompt = ({ tool, similarTech }) => {
    const user = useUser();
    const { mutateAsync, isLoading, isSuccess } = useElektraApiMutation(FormResource.submit());
    return (React.createElement(React.Fragment, null,
        React.createElement(When, { condition: !isSuccess },
            React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Details }, "Were these useful?"),
                React.createElement(Button, { isLoading: isLoading, variant: "tertiary", size: 'compact', onClick: () => mutateAsync({
                        title: 'Similar Items Found Useful',
                        data: {
                            name: user === null || user === void 0 ? void 0 : user.name,
                            email: user === null || user === void 0 ? void 0 : user.email,
                            toolId: tool.id,
                            model: tool.model,
                            similarItems: similarTech.map((x) => x.model).join(', '),
                        },
                    }) }, "Yes"),
                React.createElement(Button, { variant: "tertiary", size: 'compact', isLoading: isLoading, onClick: () => mutateAsync({
                        title: 'Similar Items Found NOT Useful',
                        data: {
                            name: user === null || user === void 0 ? void 0 : user.name,
                            email: user === null || user === void 0 ? void 0 : user.email,
                            toolId: tool.id,
                            model: tool.model,
                            similarItems: similarTech.map((x) => x.model).join(', '),
                        },
                    }) }, "No"))),
        React.createElement(When, { condition: isSuccess },
            React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                React.createElement(Icons.Product.Yes, { size: "2em", decorative: true }),
                React.createElement(Typography, { variant: TypographyVariant.Details }, "Thank you for your feedback!")))));
};
const SimilarCard = ({ tool, maximum = 5 }) => {
    const { data } = useRelatedTechnology({ tool });
    if (!data || data.length === 0) {
        return null;
    }
    const limitedData = data.slice(0, maximum);
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Heading2, { marginBottom: "0", variant: TypographyVariant.Heading2Bold }, "Similar Technologies"),
                React.createElement(AnchoredTooltip, null, "Recommendations are based on measurement similarities, taking into account the type of technology, product availability, sensors and form factor.")),
            React.createElement(Stack, { direction: "vertical", gap: "4px" }, limitedData.map((tool) => (React.createElement(SimilarTool, { key: tool.id, tool: tool })))),
            React.createElement(SimilarCardUsefulnessPrompt, { tool: tool, similarTech: limitedData }))));
};
export { SimilarCard };
