import { deserializeRefinement, searchStateReducer, serializeRefinement, } from '@humanfirst/elektron';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, } from 'use-query-params';
/** A constant empty array to prevent uneccessary renders. */
const EMPTY_FILTERS = [];
const EMPTY_SELECTION = [];
const EMPTY_COLUMNS = [];
const QUERY_PARAM_CONFIG_MAP = {
    query: StringParam,
    page: NumberParam,
    column: StringParam,
    ascending: BooleanParam,
    filters: ArrayParam,
    selection: ArrayParam,
    columns: ArrayParam,
    searchView: NumberParam,
};
/** Deserialize a list of filters. */
const deserializeFilters = (filters) => {
    return deserializeRefinement({ search: '', filters }).filters;
};
/** Serialize a list of filters. */
const serializeFilters = (filters) => {
    return serializeRefinement({ search: '', filters }).filters;
};
/** Translate from a QueryParamSearchState to a SearchState (handling null/empty). */
const queryParamStateToState = (x) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        query: (_a = x.query) !== null && _a !== void 0 ? _a : '',
        sort: x.column ? { column: x.column, ascending: !!x.ascending } : null,
        filters: x.filters ? deserializeFilters(x.filters) : EMPTY_FILTERS,
        page: (_b = x.page) !== null && _b !== void 0 ? _b : 1,
        selection: (_d = (_c = x.selection) === null || _c === void 0 ? void 0 : _c.filter((x) => typeof x === 'string')) !== null && _d !== void 0 ? _d : EMPTY_SELECTION,
        columns: (_f = (_e = x.columns) === null || _e === void 0 ? void 0 : _e.filter((x) => typeof x === 'string')) !== null && _f !== void 0 ? _f : EMPTY_COLUMNS,
    });
};
/** Translate from a SearchState to a QueryParamSearchState (handling null/empty). */
const stateToQueryParamState = (x) => ({
    query: x.query === '' ? null : x.query,
    page: x.page === 1 ? null : x.page,
    column: x.sort === null ? null : x.sort.column,
    ascending: x.sort === null ? null : x.sort.ascending,
    filters: x.filters.length === 0 ? null : serializeFilters(x.filters),
    selection: x.selection.length === 0 ? null : x.selection,
    columns: x.columns.length === 0 ? null : x.columns,
});
const mergeInitialState = (current, initialState) => {
    var _a, _b, _c, _d, _e, _f;
    return Object.assign(Object.assign({}, current), { 
        // Use || here since we want to use the initial state when the current query is an
        // empty string (not just null/undefined).
        query: current.query || ((_b = (_a = initialState === null || initialState === void 0 ? void 0 : initialState.refinement) === null || _a === void 0 ? void 0 : _a.search) !== null && _b !== void 0 ? _b : ''), filters: current.filters.length > 0
            ? current.filters
            : (_d = (_c = initialState === null || initialState === void 0 ? void 0 : initialState.refinement) === null || _c === void 0 ? void 0 : _c.filters) !== null && _d !== void 0 ? _d : EMPTY_FILTERS, sort: (_f = (_e = current.sort) !== null && _e !== void 0 ? _e : initialState === null || initialState === void 0 ? void 0 : initialState.sort) !== null && _f !== void 0 ? _f : null });
};
const useClearSearchStateUrl = () => {
    const [, setState] = useQueryParams(QUERY_PARAM_CONFIG_MAP);
    return useCallback(() => {
        setState((values) => {
            return Object.fromEntries(Object.entries(values).map(([key]) => [key, undefined]));
        });
    }, [setState]);
};
/** A version of search state that serializes the state to the URL. */
const useSearchStateUrl = (initialState) => {
    const [state, setState] = useQueryParams(QUERY_PARAM_CONFIG_MAP);
    // Create a stable reference for initial state. We don't actually want to run anything
    // if the initial state changes, so disabled exhasutive deps. This means that callers
    // don't need to worry about memoizing their initial state.
    const initialStateRef = useRef(initialState);
    // Track whether we've applied the initial state to the URL.
    const initialStateAppliedRef = useRef(false);
    // Wrap the dispatch to move between the URL state and the SearchState
    const dispatch = useCallback((action) => setState((x) => stateToQueryParamState(searchStateReducer(queryParamStateToState(x), action))), [setState]);
    // Once, on mount, we push our initial state to the URLs. This is to give a consistent
    // behavior where the URL always reflects the real state of the table.
    useEffect(() => {
        setState((x) => stateToQueryParamState(mergeInitialState(queryParamStateToState(x), initialStateRef.current)), 'replaceIn');
        initialStateAppliedRef.current = true;
    }, [setState]);
    // Create a stable reference to the state/dispatch, optionally merging in initial state for
    // consistency.
    return useMemo(() => {
        const searchState = queryParamStateToState(state);
        const shouldMergeInitialState = !initialStateAppliedRef.current && initialStateRef.current;
        const finalSearchState = shouldMergeInitialState
            ? mergeInitialState(searchState, initialStateRef.current)
            : searchState;
        return [finalSearchState, dispatch];
    }, [state, dispatch]);
};
export { useSearchStateUrl, useClearSearchStateUrl };
