import { getCTPhasesWithCountValuesFromEvidence } from 'src/utils/clinicalTrials';
import { getPopulationFromEvidence } from 'src/utils/evidence';
const getV3TypesFromEvidence = (item) => {
    return {
        validationTotal: item.evidence.filter((x) => { var _a; return (_a = x.dhtUseContext) === null || _a === void 0 ? void 0 : _a.includes('Validation'); }).length,
        verification: item.evidence.filter((x) => { var _a; return (_a = x.v3) === null || _a === void 0 ? void 0 : _a.includes('Verification'); })
            .length,
        analyticalValidation: item.evidence.filter((x) => { var _a; return (_a = x.v3) === null || _a === void 0 ? void 0 : _a.includes('Analytical Validation'); }).length,
        clinicalValidation: item.evidence.filter((x) => { var _a; return (_a = x.v3) === null || _a === void 0 ? void 0 : _a.includes('Clinical Validation'); }).length,
    };
};
const getPoliciesFromTool = (item) => {
    var _a, _b;
    const securityPolicies = (_a = item.tool.maker) === null || _a === void 0 ? void 0 : _a.securityPolicies;
    const legalPolicies = (_b = item.tool.maker) === null || _b === void 0 ? void 0 : _b.legalPolicies;
    const publishedUpdates = securityPolicies === null || securityPolicies === void 0 ? void 0 : securityPolicies.find((x) => x.type === 'Published Software Updates');
    const cvd = securityPolicies === null || securityPolicies === void 0 ? void 0 : securityPolicies.find((x) => x.type === 'Coordinated Vulnerability Disclosure (CVD)');
    const privacyPolicy = legalPolicies === null || legalPolicies === void 0 ? void 0 : legalPolicies.find((x) => x.type === 'Privacy Policy');
    const productPrivacyPolicy = legalPolicies === null || legalPolicies === void 0 ? void 0 : legalPolicies.find((x) => x.type === 'Privacy policy - Product');
    const productPrivacyPolicyUrl = (productPrivacyPolicy === null || productPrivacyPolicy === void 0 ? void 0 : productPrivacyPolicy.url)
        ? `: ${productPrivacyPolicy === null || productPrivacyPolicy === void 0 ? void 0 : productPrivacyPolicy.url}`
        : '';
    const privacyPolicyUrl = (privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url) ? `: ${privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url}` : '';
    const cvdUrl = (cvd === null || cvd === void 0 ? void 0 : cvd.url) ? `: ${cvd === null || cvd === void 0 ? void 0 : cvd.url}` : '';
    const publishedUpdatesUrl = (publishedUpdates === null || publishedUpdates === void 0 ? void 0 : publishedUpdates.url)
        ? `: ${publishedUpdates === null || publishedUpdates === void 0 ? void 0 : publishedUpdates.url}`
        : '';
    return {
        productPrivacyPolicy: productPrivacyPolicy
            ? `${productPrivacyPolicy.name}${productPrivacyPolicyUrl}`
            : 'None',
        privacyPolicy: privacyPolicy
            ? `${privacyPolicy.name}${privacyPolicyUrl}`
            : 'None',
        cvd: cvd ? `${cvd.name}${cvdUrl}` : 'None',
        publishedUpdates: publishedUpdates
            ? `${publishedUpdates.name}${publishedUpdatesUrl}`
            : 'None',
    };
};
const getCTPhasesFromEvidence = (item) => {
    const phases = getCTPhasesWithCountValuesFromEvidence(item.evidence);
    return phases
        .filter((x) => x.count > 0)
        .map((p) => p.label)
        .join(', ');
};
export const useGetToolsWithEvidenceAndVendorMetadata = (resource) => {
    return resource.map((x) => {
        if (!x.evidence.length) {
            return x.tool;
        }
        const v3Types = getV3TypesFromEvidence(x);
        const population = getPopulationFromEvidence(x);
        const phases = getCTPhasesFromEvidence(x);
        const vendorPrivacy = getPoliciesFromTool(x);
        return Object.assign(Object.assign({}, x.tool), { validationTotal: v3Types.validationTotal, v3Clinical: v3Types.clinicalValidation, v3Verification: v3Types.verification, v3Analytical: v3Types.analyticalValidation, population, clinicalTrialPhases: phases, productPrivacyPolicy: vendorPrivacy.productPrivacyPolicy, privacyPolicy: vendorPrivacy.privacyPolicy, cvd: vendorPrivacy.cvd, publishedUpdates: vendorPrivacy.publishedUpdates });
    });
};
