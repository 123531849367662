import { Banner, TypographyVariant } from '@humanfirst/elektron';
import React from 'react';
import { Case, Switch } from 'react-if';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { isGenericModel } from '../utils/selectors';
import { MaybeLink } from './MaybeLink';
/** Optional informational banners about a technology */
export const ToolBanners = ({ tool }) => {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement(Switch, null,
        React.createElement(Case, { condition: isGenericModel(tool) },
            React.createElement(Banner, { variant: "information", title: 'Generic Model', content: React.createElement(React.Fragment, null,
                    "The information on this page represents cases where a piece of evidence named a technology developer but did not name a specific model. To explore specific models, see products for",
                    ' ',
                    React.createElement(MaybeLink, { href: generatePath(PATHS.atlas.vendors.detail, {
                            id: (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.id,
                        }), typographyVariant: TypographyVariant.Body }, (_b = tool.maker) === null || _b === void 0 ? void 0 : _b.name)) })),
        React.createElement(Case, { condition: tool.availability === 'Discontinued' },
            React.createElement(Banner, { variant: "warning", title: "Product Discontinued", content: React.createElement(React.Fragment, null,
                    "This information might be outdated. See other options from",
                    ' ',
                    React.createElement(MaybeLink, { href: generatePath(PATHS.atlas.vendors.detail, {
                            id: (_c = tool.maker) === null || _c === void 0 ? void 0 : _c.id,
                        }), typographyVariant: TypographyVariant.Body }, (_d = tool.maker) === null || _d === void 0 ? void 0 : _d.name)) })),
        React.createElement(Case, { condition: tool.availability === 'Unable to determine' },
            React.createElement(Banner, { variant: "information", title: "Product Availability Cannot Be Determined", content: React.createElement(React.Fragment, null,
                    "This information might be outdated. See other options from",
                    ' ',
                    React.createElement(MaybeLink, { href: generatePath(PATHS.atlas.vendors.detail, {
                            id: (_e = tool.maker) === null || _e === void 0 ? void 0 : _e.id,
                        }), typographyVariant: TypographyVariant.Body }, (_f = tool.maker) === null || _f === void 0 ? void 0 : _f.name)) }))));
};
