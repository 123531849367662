import { __awaiter } from "tslib";
import { ResourceMetadataResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useAnalyticsEvent } from './analytics';
import { useSystemMetadataForResource } from './resourceMetadata';
const useIsSubscribed = (resourceType, resourceId) => {
    var _a;
    const track = useAnalyticsEvent();
    const { mutateAsync: createMetadata, isLoading: isCreateLoading } = useElektraApiMutation(ResourceMetadataResource.create());
    const systemMetadata = useSystemMetadataForResource(resourceType, resourceId);
    const allSubscribeMetadata = systemMetadata.filter((x) => x.metadata.systemMetadata === 'Subscribe');
    const isSubscribed = allSubscribeMetadata.length > 0;
    const singleSubscribedMetadata = isSubscribed
        ? allSubscribeMetadata[0]
        : undefined;
    const { mutateAsync: deleteMetadata, isLoading: isDeleteLoading } = useElektraApiMutation(ResourceMetadataResource.remove((_a = singleSubscribedMetadata === null || singleSubscribedMetadata === void 0 ? void 0 : singleSubscribedMetadata.id) !== null && _a !== void 0 ? _a : ''));
    const onSubscribe = () => __awaiter(void 0, void 0, void 0, function* () {
        yield createMetadata({
            resource: resourceId,
            resourceType: resourceType,
            metadata: { systemMetadata: 'Subscribe', type: 'systemMetadata' },
        });
        track('subscribe', { resourceType, resourceId });
    });
    const onUnsubscribe = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteMetadata();
        track('unsubscribe', { resourceType, resourceId });
    });
    return {
        subscribe: onSubscribe,
        unsubscribe: onUnsubscribe,
        isSubscribed,
        isLoading: isCreateLoading || isDeleteLoading,
    };
};
export { useIsSubscribed };
