import React, { useCallback, useEffect, useMemo, useRef, } from 'react';
import { Stack, Block, FormControl, Input, Button, Spacer, Link, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { LoginError } from '../';
const InputField = ({ label, name, type, placeholder, disabled, required, autoFocus, value, onChange, onValidityStateChange, }) => {
    const isValidRef = useRef();
    const inputRef = useRef(null);
    const onChangeCallback = useCallback((e) => {
        const value = e.currentTarget.value;
        const validity = e.currentTarget.validity;
        if (isValidRef.current !== validity.valid) {
            onValidityStateChange === null || onValidityStateChange === void 0 ? void 0 : onValidityStateChange(validity.valid);
            isValidRef.current = validity.valid;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, [onChange, onValidityStateChange]);
    useEffect(() => {
        // On first mount or when we get a new validity state change function, or the requirements change
        // send an update on the validity
        var _a;
        const validity = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.validity;
        if (!validity) {
            return;
        }
        onValidityStateChange === null || onValidityStateChange === void 0 ? void 0 : onValidityStateChange(validity.valid);
        isValidRef.current = validity.valid;
    }, [onValidityStateChange, required]);
    const overrides = useMemo(() => ({
        Input: {
            props: {
                'data-testid': `login-${name}`,
            },
        },
    }), [name]);
    return (React.createElement(Stack, null,
        React.createElement(FormControl, { label: label },
            React.createElement(Input, { inputRef: inputRef, id: `login-${name}`, disabled: disabled, autoFocus: autoFocus, required: required, name: name, type: type, placeholder: placeholder, onChange: onChangeCallback, value: value, overrides: overrides }))));
};
const ConditionalFormGroup = ({ condition, children }) => {
    // We can't quite use type hidden / unmounting because that breaks password managers
    // So input type hidden & `When` / `If` components aren't going to work.
    return React.createElement(Block, { display: condition ? 'block' : 'none' }, children);
};
const LoginForm = ({ onSubmit, email, stage, password, isLoading, onChangeEmail, onChangePassword, onEmailValidityStateChange, onPasswordValidityStateChange, formError, }) => {
    const onSubmitCallback = useCallback((e) => {
        e.preventDefault();
        onSubmit();
    }, [onSubmit]);
    const forgotPasswordURL = useMemo(() => {
        if (!email) {
            return generatePath(PATHS.authentication.forgotPassword.request);
        }
        const params = new URLSearchParams();
        params.set('email', email);
        return (generatePath(PATHS.authentication.forgotPassword.request) +
            `?` +
            params.toString());
    }, [email]);
    return (React.createElement("form", { onSubmit: onSubmitCallback, noValidate: true },
        React.createElement(Stack, { gap: "0" },
            React.createElement(InputField, { label: "Email", name: "email", type: "email", placeholder: "name@company.com", required: true, autoFocus: true, disabled: isLoading, value: email, onChange: onChangeEmail, onValidityStateChange: onEmailValidityStateChange }),
            React.createElement(ConditionalFormGroup, { condition: stage === 'credentials' },
                React.createElement(InputField, { label: "Password", placeholder: "Password", name: "password", type: "password", required: stage === 'credentials', disabled: isLoading, value: password, onChange: onChangePassword, onValidityStateChange: onPasswordValidityStateChange }),
                React.createElement(Block, { alignContent: "end", "data-testid": "login-forgot-password" },
                    React.createElement(Link, { href: forgotPasswordURL }, t('authentication.login.forgotPasswordCTA'))),
                React.createElement(Spacer, { gap: "16px" })),
            React.createElement(LoginError, { error: formError, marginBottom: "16px" }),
            React.createElement(Button, { type: "submit", isLoading: isLoading, size: "full", "data-testid": "login-submit" },
                React.createElement(When, { condition: stage === 'email' }, t('authentication.login.continueMultiPartFormCTA')),
                React.createElement(When, { condition: stage === 'credentials' }, t('authentication.login.loginCTA'))))));
};
export { LoginForm };
