import React, { useMemo } from 'react';
import { Stack } from '@humanfirst/elektron';
import { ExportCSVButton } from 'src/components/ExportCSVButton';
const ExportButton = ({ header, records }) => {
    const exportData = useMemo(() => records.map((record) => {
        var _a;
        const recordObj = {};
        for (let i = 0; i < header.length; i++) {
            recordObj[header[i]] = (_a = record[i]) !== null && _a !== void 0 ? _a : '';
        }
        return recordObj;
    }), [header, records]);
    return (React.createElement(Stack, { direction: "horizontal", justifyContent: "end" },
        React.createElement(ExportCSVButton, { payload: exportData, variant: "minimal" }, "Export as CSV")));
};
export { ExportButton };
