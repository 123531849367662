import React, { useEffect } from 'react';
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import { Block, Heading, Paragraph, Link, COLORS } from '@humanfirst/elektron';
import { StringParam, useQueryParam } from 'use-query-params';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { PageTitle } from 'src/components/PageTitle';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { SmartForm } from 'src/components/SmartForm';
import { t } from 'src/constants/i18n';
const ActivationError = ({ error, email }) => {
    const { mutateAsync, isSuccess } = useElektraApiMutation(AuthResource.requestActivate());
    if (isSuccess) {
        return (React.createElement(Block, null,
            React.createElement(Paragraph, null, t('activation.submitSuccess'))));
    }
    return (React.createElement(Block, null,
        React.createElement(Paragraph, { color: COLORS.alert }, String(error)),
        React.createElement(Paragraph, null, t('activation.error')),
        React.createElement(SmartForm, { onSubmit: (data) => mutateAsync(data), submitText: "Send new link" },
            React.createElement(SmartForm.Input, { name: "email", label: "Email", id: "email", defaultValue: email ? email : undefined }))));
};
const Activate = () => {
    const { mutate, isLoading, error, isSuccess } = useElektraApiMutation(AuthResource.activate());
    const [token] = useQueryParam('token', StringParam);
    const [email] = useQueryParam('email', StringParam);
    useEffect(() => mutate({ token: token !== null && token !== void 0 ? token : '' }), [token, mutate]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Activate" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Heading, null, error ? t('activation.headingError') : t('activation.heading')),
            isSuccess ? (React.createElement(React.Fragment, null,
                React.createElement(Paragraph, null, t('activation.success')),
                React.createElement(Paragraph, null,
                    "You can now",
                    ' ',
                    React.createElement(Link, { href: `${generatePath(PATHS.authentication.login.request)}?email=${encodeURIComponent(email !== null && email !== void 0 ? email : '')}` }, "log in")))) : null,
            isLoading ? React.createElement(Paragraph, null, t('activation.loading')) : null,
            error ? (React.createElement(ActivationError, { error: error, email: email !== null && email !== void 0 ? email : undefined })) : null)));
};
export { Activate };
