import { __awaiter } from "tslib";
import { Heading, Typography } from '@humanfirst/elektron';
import { AuthResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LoadingState } from 'src/components/LoadingState';
import { PageTitle } from 'src/components/PageTitle';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { SmartForm } from 'src/components/SmartForm';
import { SmartInput } from 'src/components/SmartForm/fields';
import { PATHS } from 'src/config/path';
import { useHandleRefreshToken } from 'src/hooks/authentication';
import { useLogger } from 'src/hooks/logger';
import { generatePath } from 'src/utils/path';
export const MFA = () => {
    const { data, isLoading, mutate } = useElektraApiMutation(AuthResource.mfaChallenge());
    const { mutateAsync: verifyMFA } = useElektraApiMutation(AuthResource.mfaVerify());
    const logger = useLogger('MFA');
    const refreshToken = useHandleRefreshToken();
    const navigate = useNavigate();
    useEffect(() => {
        mutate({});
    }, [mutate]);
    return (React.createElement(PrimaryContentCard, null,
        React.createElement(PageTitle, { title: "MFA" }),
        React.createElement(Heading, null, "2-Step Verification"),
        isLoading ? (React.createElement(LoadingState, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, null, "Enter the 6 digit code from your authenticator app"),
            React.createElement(SmartForm, { onSubmit: (_a) => __awaiter(void 0, [_a], void 0, function* ({ code }) {
                    yield verifyMFA({ challengeId: data.challengeId, code });
                    // The verify call will update the client authorization, so
                    // we need to fetch a new refresh token.
                    try {
                        yield refreshToken();
                        navigate(PATHS.atlas.index);
                    }
                    catch (e) {
                        logger.error(e, {
                            message: 'Failed to refresh token after MFA verification',
                        });
                        navigate(generatePath(PATHS.authentication.logout));
                    }
                }) },
                React.createElement(SmartInput, { label: "Code", name: "code", id: "code", required: true, rules: { required: true, minLength: 6, maxLength: 6 } }))))));
};
