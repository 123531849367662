import { Stack } from '@humanfirst/elektron';
import React from 'react';
import { Can } from 'src/components/Permissions';
import { isIntegratorAlgorithmUtil } from 'src/utils/vendorType';
import { isCoa, isGenericModel, isSmartphone } from '../utils/selectors';
import { QuestionnairePopulationCard } from './QuestionnairePopulationCard';
import { DataRightsAndSecurityCard } from './DataRightsAndSecurityCard';
import { EvidenceCard } from './EvidenceCard';
import { IntegratedTechnologiesCard } from './IntegratedTechnologiesCard';
import { MeasurementCard } from './MeasurementCard';
import { PriceCard } from './PriceAndAvailabilityCard';
import { SimilarCard } from './SimilarCard';
import { UtilityAndUsabilityCard } from './UtilityAndUsabilityCard';
import { QuestionnaireRegulatoryCard } from './QuestionnaireRegulatoryCard';
import { QuestionnaireDetailsCard } from './QuestionnaireDetailsCard';
import { RegulatoryCard } from './RegulatoryCard';
export const SensorCards = ({ tool }) => {
    return (React.createElement(Stack, { gap: "32px" },
        React.createElement(Stack, { gap: "32px", direction: "horizontal" },
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(EvidenceCard, { tool: tool }),
                React.createElement(RegulatoryCard, { tool: tool }),
                React.createElement(UtilityAndUsabilityCard, { tool: tool })),
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(MeasurementCard, { tool: tool }),
                React.createElement(PriceCard, { tool: tool }),
                React.createElement(DataRightsAndSecurityCard, { tool: tool }),
                React.createElement(Can, { resource: 'component.similar', action: 'read', extra: tool },
                    React.createElement(SimilarCard, { tool: tool }))))));
};
export const PhoneCards = ({ tool }) => {
    return (React.createElement(Stack, { gap: "32px" },
        React.createElement(Stack, { gap: "32px", direction: "horizontal" },
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(UtilityAndUsabilityCard, { tool: tool }),
                React.createElement(PriceCard, { tool: tool })),
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(DataRightsAndSecurityCard, { tool: tool }),
                React.createElement(Can, { resource: 'component.similar', action: 'read', extra: tool },
                    React.createElement(SimilarCard, { tool: tool }))))));
};
export const IntegratorAlgorithmCards = ({ tool }) => {
    return (React.createElement(Stack, { gap: "32px" },
        React.createElement(Stack, { gap: "32px", direction: "horizontal" },
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(EvidenceCard, { tool: tool }),
                React.createElement(RegulatoryCard, { tool: tool }),
                React.createElement(UtilityAndUsabilityCard, { tool: tool }),
                React.createElement(DataRightsAndSecurityCard, { tool: tool }),
                React.createElement(PriceCard, { tool: tool })),
            React.createElement(Stack, { gap: "32px", flex: "1" },
                React.createElement(MeasurementCard, { tool: tool }),
                React.createElement(IntegratedTechnologiesCard, { tool: tool }),
                React.createElement(Can, { resource: 'component.similar', action: 'read', extra: tool },
                    React.createElement(SimilarCard, { tool: tool }))))));
};
export const GenericModelSensorCards = ({ tool }) => {
    return (React.createElement(Stack, { gap: "32px", direction: "horizontal" },
        React.createElement(Stack, { gap: "32px", flex: "1" },
            React.createElement(EvidenceCard, { tool: tool }),
            React.createElement(RegulatoryCard, { tool: tool })),
        React.createElement(Stack, { gap: "32px", flex: "1" },
            React.createElement(MeasurementCard, { tool: tool }),
            React.createElement(DataRightsAndSecurityCard, { tool: tool }),
            React.createElement(Can, { resource: 'component.similar', action: 'read', extra: tool },
                React.createElement(SimilarCard, { tool: tool })))));
};
export const QuestionnaireCards = ({ tool }) => (React.createElement(Stack, { gap: "32px" },
    React.createElement(Stack, { gap: "32px", direction: "horizontal" },
        React.createElement(Stack, { gap: "32px", flex: "1" },
            React.createElement(QuestionnaireRegulatoryCard, { tool: tool }),
            React.createElement(QuestionnaireDetailsCard, { tool: tool })),
        React.createElement(Stack, { gap: "32px", flex: "1" },
            React.createElement(MeasurementCard, { tool: tool }),
            React.createElement(QuestionnairePopulationCard, { tool: tool }),
            React.createElement(PriceCard, { tool: tool })))));
export const ToolCards = ({ tool }) => {
    if (isIntegratorAlgorithmUtil(tool)) {
        return React.createElement(IntegratorAlgorithmCards, { tool: tool });
    }
    else if (isSmartphone(tool)) {
        return React.createElement(PhoneCards, { tool: tool });
    }
    else if (isGenericModel(tool)) {
        return React.createElement(GenericModelSensorCards, { tool: tool });
    }
    else if (isCoa(tool)) {
        return React.createElement(QuestionnaireCards, { tool: tool });
    }
    else {
        return React.createElement(SensorCards, { tool: tool });
    }
};
