import { COLORS, Heading2, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import React from 'react';
import { TherapeuticAreaResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { QueryWrapper } from 'src/components/QueryWrapper';
const TherapeuticAreaInternal = ({ data, }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, data.therapeuticArea));
};
const TherapeuticArea = ({ study }) => {
    var _a;
    const therapeuticAreaId = (_a = study.therapeuticArea) !== null && _a !== void 0 ? _a : '';
    const result = useElektraApi(TherapeuticAreaResource.get(therapeuticAreaId), {
        enabled: !!therapeuticAreaId,
    });
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Therapeutic Area"),
        React.createElement(If, { condition: !!therapeuticAreaId },
            React.createElement(Then, null,
                React.createElement(QueryWrapper, Object.assign({ renderSuccess: TherapeuticAreaInternal }, result))),
            React.createElement(Else, null,
                React.createElement(Typography, { color: COLORS.gray2 }, "No therapeutic area specified.")))));
};
export { TherapeuticArea };
