import React from 'react';
import { Button, COLORS, Icons, SemanticButton, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { useShowModalBound } from 'src/hooks/modal';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
const BookmarkAction = ({ bookmarks, variant, isBulk = false }) => {
    const workspaceId = useActiveWorkspaceId();
    const onRemove = useShowModalBound('action-in-workspace', {
        workspaceId,
        bookmarks: bookmarks,
        variant,
    });
    if (isBulk) {
        return (React.createElement(Button, { onClick: onRemove, variant: 'secondary', startEnhancer: variant === 'archive' ? (React.createElement(Icons.Misc.Archive, { title: "Archive", color: COLORS.primary })) : (React.createElement(Icons.Misc.Delete, { title: "Delete", color: COLORS.primary })), disabled: bookmarks && (bookmarks === null || bookmarks === void 0 ? void 0 : bookmarks.length) < 1 },
            React.createElement(Typography, { color: COLORS.primary, variant: TypographyVariant.BodyBold, textTransform: "capitalize" }, variant)));
    }
    return (React.createElement(SemanticButton, { "aria-label": `${variant} resource from project`, onClick: onRemove },
        React.createElement(If, { condition: variant === 'archive' },
            React.createElement(Then, null,
                React.createElement(Icons.Misc.Archive, { title: "Archive" })),
            React.createElement(Else, null,
                React.createElement(Icons.Misc.Delete, { title: "Delete" })))));
};
export { BookmarkAction };
