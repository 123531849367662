import React from 'react';
import { Block, Link } from '@humanfirst/elektron';
import { ToolImage } from 'src/components/ToolImage';
import { ToolStatusHoverState } from 'src/components/ToolImageWithHoverState/ToolStatusHoverState';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const ToolImageLink = ({ tool, }) => {
    return (React.createElement(Block, null,
        React.createElement(ToolStatusHoverState, { tool: tool },
            React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, { id: tool.id }) },
                React.createElement(ToolImage, { imageSize: 40, tool: tool })))));
};
export { ToolImageLink };
