import { CategoricalFilter } from '@humanfirst/elektron';
import { getMCsFromStudyAndTeamMetadata, getMeasuresFromStudyAndTeamMetadata, } from './utils';
export const MEASURES_FILTER = CategoricalFilter('measures', {
    label: 'Measure',
    getValue: (item) => {
        const measures = getMeasuresFromStudyAndTeamMetadata(item);
        return measures.map((x) => x.name);
    },
});
export const MEDICAL_CONDITION_FILTER = CategoricalFilter('medicalConditions', {
    label: 'Medical Condition',
    getValue: (item) => {
        const mcs = getMCsFromStudyAndTeamMetadata(item);
        return mcs.map((x) => x.name);
    },
});
