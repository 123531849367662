import { Card, Stack, Typography, TypographyVariant, Image, COLORS, Block, Markdown, Link, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { AtlasFeedbackPopover } from '../AtlasFeedback';
/** A tag for a resource. */
const ResourceTag = ({ icon, tag }) => {
    return (React.createElement(Stack, { direction: "horizontal", gap: "8px", alignItems: 'center' },
        icon,
        React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, tag.toUpperCase())));
};
/** Renders a collection of resource tags. */
const ResourceTags = ({ tags }) => {
    return (React.createElement(Stack, { direction: 'horizontal', gap: '8px' }, tags.map(({ tag, icon }, index) => (React.createElement(React.Fragment, { key: tag },
        React.createElement(When, { condition: index > 0 },
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "\u2022")),
        React.createElement(ResourceTag, { tag: tag, icon: icon }))))));
};
/** Render a collection of links. */
const Links = ({ links }) => {
    return (React.createElement(Stack, { direction: 'horizontal' }, links.map(({ text, href }) => (React.createElement(Link, { href: href, key: href }, text)))));
};
/**
 * A Primary Card contains high level information
 * for the resource on this page.
 */
const PrimaryCard = ({ image, title, description, tags, actions, links, parentResource, secondaryFact, secondaryParent, hasFeedback = true, }) => {
    const imageElement = typeof image === 'string' ? (React.createElement(Image, { src: image, alt: title, width: "208px", height: "208px" })) : (image);
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { direction: "horizontal", gap: "32px" },
            React.createElement(When, { condition: !!image },
                React.createElement(Block, { minWidth: "208px", minHeight: "208px", maxWidth: "208px", maxHeight: "208px" }, imageElement ? imageElement : null)),
            React.createElement(Stack, { gap: "8px", flex: "1" },
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h1" },
                    React.createElement(Stack, { direction: 'horizontal', alignItems: "baseline" },
                        title,
                        parentResource ? (React.createElement(Typography, null,
                            "(",
                            React.createElement(Link, { href: parentResource.href }, parentResource.text),
                            ")")) : null,
                        secondaryFact ? (React.createElement(Typography, null,
                            "(",
                            secondaryFact,
                            ")")) : null)),
                tags ? React.createElement(ResourceTags, { tags: tags }) : null,
                links ? React.createElement(Links, { links: links }) : null,
                secondaryParent ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.gray1 }, secondaryParent)) : null,
                description ? React.createElement(Markdown, { content: description }) : null,
                actions
                    ? typeof actions === 'function'
                        ? actions()
                        : actions
                    : null),
            React.createElement(When, { condition: hasFeedback },
                React.createElement(Block, null,
                    React.createElement(AtlasFeedbackPopover, null))))));
};
export { PrimaryCard };
