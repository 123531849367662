import React from 'react';
import { Card, Block, Typography, TypographyVariant, Stack, Button, } from '@humanfirst/elektron';
const ProfessionalProfileCard = ({ profile, onEditProfessionalInformation, }) => {
    if (!profile) {
        // Since this info is required during onbording, If we haven't asked the for the profile yet, assume we aren't going to.
        return null;
    }
    return (React.createElement(Card, { padding: "24px" },
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h3" }, "Professional Profile"),
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "What industry are you in?"),
                React.createElement(Typography, null, profile.industry)),
            React.createElement(Block, null,
                React.createElement(Button, { variant: "secondary", onClick: onEditProfessionalInformation }, "Edit Professional Profile")))));
};
export { ProfessionalProfileCard };
