import { useEffect, useMemo } from 'react';
import { useUser } from 'src/hooks/user';
import { useIsShadowing } from 'src/hooks/authentication';
import { useLogger } from 'src/hooks/logger';
import { useAppData } from './app-data';
import { useAnalyticsServices } from './analytics-services';
const useAnalyticsIdentify = () => {
    const logger = useLogger(useAnalyticsIdentify.name);
    const services = useAnalyticsServices();
    const user = useUser();
    const isShadowing = useIsShadowing();
    const appData = useAppData();
    const userTeams = useMemo(() => {
        var _a;
        return (_a = user === null || user === void 0 ? void 0 : user.teams) === null || _a === void 0 ? void 0 : _a.map((x) => x.id).sort().join(',');
    }, [user]);
    const userData = useMemo(() => {
        var _a;
        if (!(user === null || user === void 0 ? void 0 : user.id)) {
            return null;
        }
        return {
            id: user.id,
            name: user.name,
            email: user.email,
            createdAt: (_a = user.createdAt) !== null && _a !== void 0 ? _a : undefined,
            team: userTeams,
        };
    }, [user, userTeams]);
    // Identify the user. Note: in cases where we are shadowing we
    // do not pass a user identification.
    useEffect(() => {
        if (isShadowing) {
            return;
        }
        for (const service of services) {
            try {
                service.identify(userData, appData);
            }
            catch (e) {
                logger.error(e, { service: service.name });
            }
        }
    }, [services, isShadowing, userData, appData, logger]);
};
export { useAnalyticsIdentify };
