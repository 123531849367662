import React from 'react';
import dayjs from 'dayjs';
import { getResourceTypeFromWorkspaceSection, } from 'src/utils/resourceType';
import { BoldWrapper, SupportingTextWrapper, TimestampWrapper, } from './components/DisplayHelpers';
import { getVirtualEventMap } from './virtualEvents';
export const getEventsToShow = (events, filter) => {
    var _a, _b;
    const nonAdminEvents = (_a = events === null || events === void 0 ? void 0 : events.filter((x) => x.type !== 'workspace')) !== null && _a !== void 0 ? _a : [];
    const adminEvents = (_b = events === null || events === void 0 ? void 0 : events.filter((x) => x.type === 'workspace')) !== null && _b !== void 0 ? _b : [];
    const eventsToShow = nonAdminEvents.filter((e) => {
        var _a, _b;
        const currentEvent = e;
        if (filter && filter !== 'Everything') {
            const sectionType = new Set(getResourceTypeFromWorkspaceSection(filter));
            const currentResourceType = (_a = currentEvent.current) === null || _a === void 0 ? void 0 : _a.resourceType;
            const previousResourceType = (_b = currentEvent.previous) === null || _b === void 0 ? void 0 : _b.resourceType;
            const resourceType = currentResourceType
                ? currentResourceType
                : previousResourceType;
            return sectionType.has(resourceType);
        }
        else {
            return true;
        }
    });
    if (filter === 'Everything') {
        eventsToShow.push(...adminEvents);
    }
    eventsToShow.sort((a, b) => dayjs(b.time).diff(a.time));
    return eventsToShow;
};
/**
 * Translate from workspace events (raw previous/current values) to virtual
 * events (human-readable strings).
 *
 * Multiple virtual events could result from a single workspace event (if say
 * multiple fields were editted at once).
 * @param event
 * @param resourceMap
 * @returns
 */
export const getVirtualEvents = (event, resourceMap) => {
    const eventMap = getVirtualEventMap(event);
    const events = [];
    for (const { test, output } of eventMap) {
        if (test(event)) {
            events.push(output(event, resourceMap));
        }
    }
    if (events.length === 0) {
        // Add a fallback event. In the case where we have unhandled events
        // we'll just use the name + action + type tuple.
        events.push(React.createElement(TimestampWrapper, { timestamp: event.time },
            React.createElement(BoldWrapper, null, event.actor.name),
            React.createElement(SupportingTextWrapper, null,
                ' ',
                event.action,
                "d a",
                ' ',
                event.type === 'workspace' ? 'project' : event.type)));
    }
    return events;
};
