import { useCallback } from 'react';
import { useLogger } from '../logger';
import { useAnalyticsServices } from './analytics-services';
import { useAppData } from './app-data';
const useAnalyticsEvent = () => {
    const logger = useLogger(useAnalyticsEvent.name);
    const services = useAnalyticsServices();
    const appData = useAppData();
    return useCallback((action, properties, options) => {
        var _a;
        logger.info(`sending analytics event: ${action}`, {
            action,
            properties,
        });
        for (const service of services) {
            if ((options === null || options === void 0 ? void 0 : options.services.only) &&
                !options.services.only.includes(service.name)) {
                continue;
            }
            if ((options === null || options === void 0 ? void 0 : options.services.except) &&
                options.services.except.includes(service.name)) {
                continue;
            }
            if (service.isInitialized()) {
                try {
                    (_a = service.track) === null || _a === void 0 ? void 0 : _a.call(service, action, Object.assign(Object.assign({}, appData), properties));
                }
                catch (e) {
                    logger.error(e, {
                        service: service.name,
                        action,
                    });
                }
            }
        }
    }, [logger, services, appData]);
};
export { useAnalyticsEvent };
