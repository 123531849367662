import { StatefulDataTable } from '@humanfirst/elektron';
import React from 'react';
function parseCSV(csv) {
    return csv
        .trim()
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0)
        .map((line) => line.split(','));
}
export const MarkdownCodeExtensionTable = ({ code }) => {
    const records = parseCSV(code);
    const header = records.shift();
    if (!header) {
        return React.createElement(React.Fragment, null, "Invalid data for table");
    }
    return (React.createElement(StatefulDataTable, { data: records, columns: header.map((name, index) => ({
            id: `${name}-${index}`,
            header: name,
            sortable: true,
            render: (row) => row[index],
            getStringValue: (row) => row[index],
        })) }));
};
