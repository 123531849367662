import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { TeamSSOResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { Block, Button, StatefulDataTable, TextColumn, toaster, Typography, } from '@humanfirst/elektron';
import { useParams } from 'react-router';
import { When } from 'react-if';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { TeamSSOForm } from './TeamSSOForm';
const RemoveButton = ({ id, teamId, }) => {
    const { mutateAsync: removeLink } = useElektraApiMutation(TeamSSOResource.remove(id, teamId));
    const onRemove = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield removeLink();
            toaster.positive('Domain removed from team', {});
        }
        catch (e) {
            toaster.negative('unable to remove domain from team', {});
            // eslint-disable-next-line no-console
            console.error('unable to remove domain from team');
        }
    }), [removeLink]);
    return React.createElement(Button, { onClick: onRemove }, "Remove");
};
const TeamSSODomainsTable = ({ data, teamId, exposeOrganizationId }) => {
    const canRemoveTeamSSO = useHasPermission('team.domain', 'delete', teamId);
    const columns = [
        TextColumn('domain', {
            header: 'Domain',
            render: (item) => item === null || item === void 0 ? void 0 : item.domain,
        }),
    ];
    if (exposeOrganizationId) {
        columns.push(TextColumn('organizationId', {
            header: 'Organization ID',
            render: (item) => item === null || item === void 0 ? void 0 : item.organizationId,
        }));
    }
    if (canRemoveTeamSSO) {
        columns.push({
            id: 'action',
            header: '',
            render: (item) => React.createElement(RemoveButton, { id: item.id, teamId: teamId }),
        });
    }
    return React.createElement(StatefulDataTable, { data: data, columns: columns });
};
const TeamSSOInternal = ({ teamId, exposeOrganizationId }) => {
    const canCreateTeamSSO = useHasPermission('team.domain', 'create', teamId);
    const result = useElektraApi(TeamSSOResource.listByTeam(teamId));
    const [isFormOpen, setIsFormOpen] = useState(false);
    const closeFormModal = useCallback(() => {
        setIsFormOpen(false);
    }, []);
    const openFormModal = useCallback(() => setIsFormOpen(true), []);
    const { mutateAsync } = useElektraApiMutation(TeamSSOResource.create(teamId));
    const onFormSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        const formOrganizationId = !!data.organizationId
            ? data.organizationId
            : undefined;
        yield mutateAsync({
            organizationId: formOrganizationId,
            domain: data.domain ? data.domain : undefined,
        });
        closeFormModal();
        toaster.positive('SSO Domain Created', {});
    }), [mutateAsync, closeFormModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamSSOForm, { onSubmit: onFormSubmit, isOpen: isFormOpen, closeModal: closeFormModal, exposeOrganizationId: exposeOrganizationId }),
        React.createElement(When, { condition: canCreateTeamSSO },
            React.createElement(Block, { display: "flex", justifyContent: "flex-end" },
                React.createElement(Button, { onClick: openFormModal, size: "large" }, "New SSO Domain"))),
        React.createElement(QueryWrapper, Object.assign({}, result, { teamId: teamId, exposeOrganizationId: exposeOrganizationId, renderSuccess: TeamSSODomainsTable }))));
};
const TeamSSOComponent = ({ exposeOrganizationId = false, }) => {
    const { id } = useParams();
    const canViewTeamSSO = useHasPermission('team.domain', 'read', id);
    if (!canViewTeamSSO) {
        return (React.createElement(Typography, null, "You do not have access to view Team SSO Domain links"));
    }
    return (React.createElement(TeamSSOInternal, { teamId: id !== null && id !== void 0 ? id : '', exposeOrganizationId: exposeOrganizationId }));
};
export { TeamSSOComponent as TeamSSO };
