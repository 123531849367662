import { useEffect } from 'react';
import { useShowModalBound } from 'src/hooks/modal';
import { useIsShadowing } from 'src/hooks/authentication';
import { useCanUserSeeUserProfileQuestionnaire } from './useCanUserSeeUserProfileQuestionnaire';
import { useHasExistingUserProfile } from './useHasExistingUserProfile';
/** Check if the user should see the user profile onboarding questionnaire. If so, show the global modal */
const useUserProfileQuestionnaire = () => {
    const showModal = useShowModalBound('user-profile-questionnaire', undefined, {
        closable: false,
    });
    const canSeeModal = useCanUserSeeUserProfileQuestionnaire();
    const { existingProfile, isLoading } = useHasExistingUserProfile();
    const isShadowing = useIsShadowing();
    useEffect(() => {
        if (!existingProfile && !isLoading && canSeeModal && !isShadowing) {
            showModal();
        }
    }, [canSeeModal, existingProfile, isLoading, showModal, isShadowing]);
};
export { useUserProfileQuestionnaire };
