import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { checkPermissions } from 'src/utils/permissions';
/**
 * Whether the current user has a given permission.
 * @param resource The resource type.
 * @param action The action to be performed against the resource type.
 * @param extra Extra information to be included with the permission check.
 * This will vary based on resource type.
 */
const useHasPermission = (resource, action, instance) => {
    const { user, grants } = useContext(UserContext);
    if (!user) {
        // Unauthenticated users have no permissions.
        // NOTE: We may need to reexamine this, but basically if an unauthenticated
        // user should do it, just don't do a permission check.
        return false;
    }
    else {
        return checkPermissions({ user, grants: grants || [] }, resource, action, instance);
    }
};
export { useHasPermission };
