import { LockedColumn, TextColumn } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useToggle } from 'src/hooks/toggle';
import { EvidenceCell, MeasuresCell, MedicalConditionCell, ProductCell, RegulatoryCell, } from './cells';
import { getEvidenceSortValue } from './sort';
const MODEL_COLUMN = TextColumn('product', {
    header: 'Technology',
    render: (item) => React.createElement(ProductCell, { item: item }),
    getStringValue: (item) => { var _a, _b; return `${item.model}-${(_b = (_a = item.maker) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''}`; },
    metadata: { displayRole: 'required' },
});
const EVIDENCE_COLUMN = {
    id: 'evidence',
    header: 'Evidence Count',
    render: (item) => React.createElement(EvidenceCell, { item: item }),
    sortable: 'numeric',
    getStringValue: getEvidenceSortValue,
    metadata: { displayRole: 'hidden' },
};
const REGULATORY_COLUMN = {
    id: 'regulatoryDecisions',
    header: 'Regulatory Decision',
    render: (item) => React.createElement(RegulatoryCell, { item: item }),
    metadata: { displayRole: 'display' },
};
const MC_COLUMN = {
    id: 'medicalConditions',
    header: 'Medical Condition',
    render: (item) => React.createElement(MedicalConditionCell, { item: item }),
    getStringValue: (item) => { var _a, _b; return (_b = (_a = item.medicalConditions) === null || _a === void 0 ? void 0 : _a.flatMap((mc) => mc.name).join(',')) !== null && _b !== void 0 ? _b : ''; },
    metadata: { displayRole: 'hidden' },
};
const MEASURES_COLUMN = {
    id: 'measures',
    header: 'Measure',
    render: (item) => React.createElement(MeasuresCell, { item: item }),
    searchable: true,
    getStringValue: (item) => { var _a, _b; return (_b = (_a = item.measuresCategory) === null || _a === void 0 ? void 0 : _a.join(',')) !== null && _b !== void 0 ? _b : ''; },
    metadata: { displayRole: 'display' },
};
const useColumns = () => {
    const canViewEvidence = useHasPermission('component.evidenceSummary', 'read');
    const canViewRegulatoryDecisions = useHasPermission('component.regulatoryDecisions', 'read');
    const canViewMedicalConditions = useHasPermission('searchResult.medicalConditions', 'read');
    const canViewMeasures = useHasPermission('searchResult.evidenceMeasures', 'read');
    const customColumns = useToggle('custom-columns');
    return useMemo(() => {
        const newColumns = [MODEL_COLUMN];
        if (canViewMeasures) {
            newColumns.push(MEASURES_COLUMN);
        }
        else {
            newColumns.push(LockedColumn('Measure'));
        }
        if (customColumns) {
            if (canViewEvidence) {
                newColumns.push(EVIDENCE_COLUMN);
            }
            else {
                newColumns.push(LockedColumn('Evidence'));
            }
        }
        if (canViewRegulatoryDecisions) {
            newColumns.push(REGULATORY_COLUMN);
        }
        else {
            newColumns.push(LockedColumn('Regulatory Evidence'));
        }
        if (customColumns) {
            if (canViewMedicalConditions) {
                newColumns.push(MC_COLUMN);
            }
            else {
                newColumns.push(LockedColumn('Medical Condition'));
            }
        }
        return newColumns;
    }, [
        canViewEvidence,
        canViewMeasures,
        canViewMedicalConditions,
        canViewRegulatoryDecisions,
        customColumns,
    ]);
};
export { useColumns, MEASURES_COLUMN, EVIDENCE_COLUMN, REGULATORY_COLUMN, MODEL_COLUMN, MC_COLUMN, };
