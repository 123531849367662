import { __awaiter } from "tslib";
import { useElektraApi, useElektraApiMutation, WorkspaceCommentResource, } from '@humanfirst/use-elektra-api';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useActiveWorkspaces } from 'src/hooks/workspace';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const useCreateComment = (workspaceId, resourceType, resource) => {
    const { mutateAsync } = useElektraApiMutation(WorkspaceCommentResource.create(workspaceId));
    return useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ comment }) {
        yield mutateAsync({
            resource,
            resourceType,
            comment,
        });
    }), [resource, resourceType, mutateAsync]);
};
/**
 * Load comments for a specific bookmark.
 */
const useComments = (workspaceId, resourceType, resource) => {
    const { data, isLoading } = useElektraApi(WorkspaceCommentResource.list(workspaceId));
    if (isLoading) {
        return { data: undefined, isLoading: true };
    }
    if (data) {
        return {
            data: data
                .filter((x) => x.resource === resource && x.resourceType === resourceType)
                .sort((a, b) => dayjs(a.createdAt).diff(b.createdAt)),
            isLoading,
        };
    }
    return { data: undefined, isLoading: false };
};
/**
 * Check if a user can create a comment for a resource.
 */
const useCanCreateComment = (workspaceId, resourceType, resource) => {
    const workspaces = useActiveWorkspaces();
    // Warning: This isn't a full permission, but it's what we've got.
    return useHasPermission('comment', 'create', {
        resourceId: resource,
        resourceType: resourceType,
        workspace: workspaces.find((x) => x.id === workspaceId),
    });
};
export { useCreateComment, useComments, useCanCreateComment };
