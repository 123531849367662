import React from 'react';
import { Typography, TypographyVariant, Block, Spacer, Card, Spread, Divider, SemanticButton, COLORS, Stack, Link, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { getSortedToolsWithEvidence, } from 'src/utils/entityDetailPages';
import { LoadingState } from '../LoadingState';
const TechnologyBlock = ({ tool, showEvidence, hasEvidencePermission }) => {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(Spacer, null),
        React.createElement(Spread, null,
            React.createElement(Block, { gap: '0px' },
                React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                        id: tool.tool.id,
                    }) }, tool.tool.model),
                React.createElement(Typography, null, (_a = tool.tool.maker) === null || _a === void 0 ? void 0 : _a.name)),
            React.createElement(If, { condition: hasEvidencePermission },
                React.createElement(Then, null,
                    React.createElement(SemanticButton, { onClick: showEvidence
                            ? () => showEvidence('Technology', `${tool.tool.model}||${tool.tool.id}`)
                            : undefined },
                        React.createElement(Typography, { color: COLORS.primary }, tool.evidenceCount))),
                React.createElement(Else, null,
                    React.createElement(Typography, null, tool.evidenceCount))))));
};
const TopTechnologyCard = ({ showEvidence, tooltip, tools, evidence, isLoading = false, hasEvidencePermission, }) => {
    const relatedTools = getSortedToolsWithEvidence(tools, evidence);
    const topTools = relatedTools === null || relatedTools === void 0 ? void 0 : relatedTools.slice(0, 3);
    return (React.createElement(Card, null,
        React.createElement(Block, null,
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading1 }, 'Most Used Technologies'),
                React.createElement(AnchoredTooltip, null, tooltip)),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Spread, null,
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "TECHNOLOGIES"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "EVIDENCE")),
            React.createElement(Divider, null),
            React.createElement(If, { condition: isLoading },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(If, { condition: (topTools === null || topTools === void 0 ? void 0 : topTools.length) === 0 },
                        React.createElement(Then, null,
                            React.createElement(Spacer, null),
                            React.createElement(Typography, { color: COLORS.gray2 }, "No tools that meet Atlas criteria")),
                        React.createElement(Else, null, topTools === null || topTools === void 0 ? void 0 : topTools.map((x) => (React.createElement(TechnologyBlock, { key: x.tool.model, tool: x, showEvidence: showEvidence, hasEvidencePermission: hasEvidencePermission }))))))),
            React.createElement(Spacer, { size: '24px' }))));
};
export { TopTechnologyCard };
