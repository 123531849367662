import React from 'react';
import { SmartCheckbox, SmartInput, SmartSelect } from './fields';
import { SmartPromptWhenDirty } from './SmartPromptWhenDirty';
import { SmartFormSubmitButton } from './SmartFormSubmitButton';
import { SmartRecaptcha } from './SmartRecaptcha';
import { SmartFormErrors } from './SmartFormErrors';
import { SmartFormProvider } from './SmartFormProvider';
import { useHandleSubmit } from './useHandleSubmit';
function FormWrapper({ onSubmit, children, }) {
    const handleSubmit = useHandleSubmit(onSubmit);
    return React.createElement("form", { onSubmit: handleSubmit }, children);
}
/**
 * Renders a form with full controls, error messages, etc.
 */
function SmartForm({ submitComponent, submitText, onSubmit, validationSchema, defaultValues, children, }) {
    return (React.createElement(SmartFormProvider, { validationSchema: validationSchema, defaultValues: defaultValues },
        React.createElement(FormWrapper, { onSubmit: onSubmit },
            children,
            React.createElement(SmartFormErrors, null),
            React.createElement(SmartFormSubmitButton, { submitComponent: submitComponent, submitText: submitText }),
            React.createElement(SmartPromptWhenDirty, null))));
}
// Attach the input wrappers
SmartForm.Input = SmartInput;
SmartForm.Checkbox = SmartCheckbox;
SmartForm.Select = SmartSelect;
SmartForm.Recaptcha = SmartRecaptcha;
SmartForm.FormErrors = SmartFormErrors;
export { SmartForm };
