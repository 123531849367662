import { __awaiter } from "tslib";
import { WorkspaceResourceMetadataResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useCallback } from 'react';
/**
 * Possible statues.
 *
 * These are used since systemMetadat is very flat. We read all of the metadata
 * and assume anything that has a value included here is representing a status.
 */
const STATUSES = ['pending', 'accepted', 'rejected'];
/** Returns a function that can be use to set the status on a project upload. */
export const useSetUploadStatus = (workspaceId) => {
    const { mutateAsync: addMutate, isLoading: addLoading } = useElektraApiMutation(WorkspaceResourceMetadataResource.create(workspaceId));
    const { mutateAsync: removeMutate, isLoading: removeLoading } = useElektraApiMutation(WorkspaceResourceMetadataResource.remove(workspaceId));
    const { data: existing, isLoading: existingLoading } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'systemMetadata',
    }));
    const mutate = useCallback((key, status) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Find all of the existing system metadata that represents status
        // and remove them. This is kind of a hack because of the way metadata
        // is setup, but it should work.
        const existingStatuses = (_a = existing === null || existing === void 0 ? void 0 : existing.filter((x) => x.resource === key &&
            x.resourceType === 'Document' &&
            x.metadataType === 'systemMetadata' &&
            STATUSES.includes(x.metadataValue))) !== null && _a !== void 0 ? _a : [];
        for (const metadata of existingStatuses) {
            yield removeMutate({ id: metadata.id });
        }
        // If we're setting a new status, create the new metadata.
        if (status !== null) {
            yield addMutate({
                resource: key,
                resourceType: 'Document',
                metadataType: 'systemMetadata',
                metadataValue: status,
            });
        }
    }), [existing, addMutate, removeMutate]);
    return { mutate, isLoading: addLoading || removeLoading || existingLoading };
};
/**
 * Returns the status of a given upload.
 *
 * Returns undefined while the status is loading.
 */
export const useUploadStatus = (workspaceId, key) => {
    const metadata = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        resource: key,
        resourceType: 'Document',
        metadataType: 'systemMetadata',
    }));
    if (metadata.isLoading || !metadata.data) {
        return { isLoading: true, data: undefined };
    }
    const found = metadata.data.find((x) => STATUSES.includes(x.metadataValue));
    return {
        isLoading: false,
        data: found ? found.metadataValue : undefined,
    };
};
