import { BetterTree, Block, Center, COLORS, Heading, Icons, SemanticButton, Spacer, Spinner, Spread, Stack, stateFromTree, toggleOpenAction, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { MeasureCategoryResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useEffect, useMemo, useReducer, } from 'react';
import { Case, Default, Else, If, Switch, Then, When } from 'react-if';
import { ContentContainer } from 'src/components/ContentContainer';
import { EmptyStateOtherOptions } from 'src/components/EmptyStateOtherOptions';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PATHS } from 'src/config/path';
import { useTrackTableRefinement } from 'src/hooks/analytics/track-table-refinement';
import { getMeasurementHierarchyOntologyValue } from 'src/utils/measurements';
import { flattenTree } from 'src/utils/tree';
import { CATEGORY_GROUPS } from 'src/constants/measureCategories';
import { HelpCenterCardLink } from 'src/components/HelpCenterCardLink/HelpCenterCardLink';
import { applyMeasuresAction, clearMeasuresAction, } from '../../state/measures/actions';
import { reducer } from '../../state/measures/reducer';
import { applyHighlightJSX } from '../../utils/highlighting';
import { MeasureCategoryTreeNodeInternal, MeasureCategoryTreeNode, } from './components/MeasureCategoryTreeNode';
import { ControlButtons, OntologyControls } from './components/OntologyControls';
import { useSearchMeasureOntologyAlgolia } from './hooks/search';
import { PopularCOI } from './components/PopularCOI';
const SearchHits = ({ groupContent, match }) => {
    if (!groupContent.includes(match.content.measure)) {
        return null;
    }
    else {
        const highlightedValue = match.matchHighlight
            ? applyHighlightJSX(match.matchHighlight).value
            : null;
        return (React.createElement(MeasureCategoryTreeNodeInternal, { category: match.content.measure, categoryFullName: match.content.fullName },
            React.createElement(Stack, { direction: 'horizontal', paddingBottom: '8px', alignItems: 'center' },
                React.createElement(Typography, { color: COLORS.primary }, "\u00B7"),
                React.createElement("span", null, highlightedValue))));
    }
};
/**
 * A group of measure categories.
 *
 * This consumes the tree that contains all categories, but will
 * only render a subset of the categories, as specified by `group`
 */
const CategoryGroup = ({ state, dispatch, group, isSearching, }) => {
    const groupNode = state.children.filter((x) => x.value.content.measure === group)[0];
    const groupPath = state.children.findIndex((x) => x.value.content.measure === group);
    const isOpen = groupNode.value.isOpen;
    const flattenedGroupContent = flattenTree(groupNode).flatMap((x) => x.content.measure);
    const searchMatches = flattenTree(state).filter((y) => y.isMatch);
    const isMatchInGroup = searchMatches.some((match) => flattenedGroupContent.includes(match.content.measure));
    if (!isMatchInGroup && isSearching) {
        return null;
    }
    return (React.createElement(Block, { border: `1px solid ${COLORS.gray3}`, padding: '24px', borderRadius: '8px', minWidth: "320px", margin: '0 24px 24px 0' },
        React.createElement(Spread, { alignItems: 'baseline' },
            React.createElement(Block, { width: "296px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, group)),
            React.createElement(When, { condition: !isSearching },
                React.createElement(Spacer, null),
                React.createElement(SemanticButton, { onClick: () => {
                        dispatch(toggleOpenAction([groupPath]));
                    } },
                    React.createElement(Block, { padding: '10px 0px 10px 10px' },
                        React.createElement(If, { condition: isOpen },
                            React.createElement(Then, null,
                                React.createElement(Icons.Misc.ChevronUp, { size: '0.6em', title: "Collapse Measure Category" })),
                            React.createElement(Else, null,
                                React.createElement(Icons.Misc.ChevronDown, { size: '0.6em', title: "Expand Measure Category" }))))))),
        React.createElement(Spacer, { size: "16px" }),
        React.createElement(Typography, null,
            React.createElement(When, { condition: isSearching },
                React.createElement(Block, { paddingLeft: '24px' }, searchMatches.map((match) => (React.createElement(SearchHits, { key: match.content.measure, groupContent: flattenedGroupContent, match: match }))))),
            React.createElement(When, { condition: isOpen && !isSearching },
                React.createElement(Stack, { gap: "0px", width: "296px" }, groupNode.children.map((category, i) => (React.createElement(BetterTree, { state: category, dispatch: dispatch, path: [groupPath, i], key: category.value.content.measure, contentComponent: MeasureCategoryTreeNode, root: true, isFirst: i === 0, isLast: i === groupNode.children.length - 1 }))))))));
};
const OntologiesCommon = ({ state, dispatch, isLoading, search, setSearch, isSearching = false, }) => {
    // The only scenario where the root node is not visible is when a search has been
    // performed with no results
    const noSearchResults = !state.value.isVisible;
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: "Measure Ontologies" }),
        React.createElement(ContentContainer, { padding: "56px 56px 16px 56px", breadcrumbs: [BREADCRUMBS.Atlas, BREADCRUMBS.Measures] },
            React.createElement(Stack, { direction: "horizontal", gap: "32px", paddingBottom: '24px' },
                React.createElement(Stack, null,
                    React.createElement(Heading, null, "Measure Ontologies"),
                    React.createElement(Typography, null, "HumanFirst has developed 25 measure ontologies that categorize 19k+ digital measures, following the principles of developing measures that matter to patients. Start your search by exploring some of the most common concepts of interest such as Sleep and Activity, or expand to see our full list of ontologies.")),
                React.createElement(HelpCenterCardLink, { href: PATHS.helpCenter.usingAtlas.measures.ontologies, title: 'How to make the most of the ontology', readTime: '3 min read' })),
            React.createElement(OntologyControls, { isLoading: isLoading, setSearch: setSearch, search: search })),
        React.createElement(ContentContainer, { backgroundColor: COLORS.white, padding: "56px 56px 112px 56px" },
            React.createElement(Stack, { gap: "24px" },
                React.createElement(Switch, null,
                    React.createElement(Case, { condition: isLoading },
                        React.createElement(Center, null,
                            React.createElement(Spinner, null))),
                    React.createElement(Case, { condition: noSearchResults },
                        React.createElement(Stack, { padding: '50px' },
                            React.createElement(EmptyStateOtherOptions, { query: search, location: 'measures' }))),
                    React.createElement(Default, null,
                        React.createElement(Stack, { gap: '56px' },
                            React.createElement(When, { condition: !isSearching },
                                React.createElement(PopularCOI, null)),
                            React.createElement(Stack, { gap: '24px' },
                                React.createElement(Spread, null,
                                    !isSearching ? (React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Browse The Ontologies")) : (React.createElement(Block, null)),
                                    React.createElement(ControlButtons, { dispatch: dispatch, isSearching: isSearching })),
                                React.createElement(Block, { display: 'flex', flexWrap: true }, state.children.map((group) => (React.createElement(Block, { key: group.value.content.measure },
                                    React.createElement(CategoryGroup, { group: group.value.content.measure, state: state, dispatch: dispatch, isSearching: isSearching })))))))))))));
};
const OntologiesAlgoliaSearch = ({ state, dispatch }) => {
    const trackSearch = useTrackTableRefinement('measures');
    const [search, setSearch, isLoading, isSearching, options] = useSearchMeasureOntologyAlgolia();
    useEffect(() => {
        // NOTE: It's true that this could get out of sync, but since both callbacks are debounced
        // and this one is slower, it's probably ok.
        var _a;
        trackSearch({ filters: [], search }, (_a = options === null || options === void 0 ? void 0 : options.length) !== null && _a !== void 0 ? _a : 0);
    }, [search, trackSearch, options]);
    // Whenever the options update we want to dispatch those to the search tree.
    useEffect(() => {
        if (!isSearching) {
            dispatch(clearMeasuresAction());
        }
        else if (!isLoading) {
            dispatch(applyMeasuresAction(options !== null && options !== void 0 ? options : []));
        }
        // If we're searching, but still loading we don't want to update the options yet.
    }, [isSearching, options, dispatch, isLoading]);
    return (React.createElement(OntologiesCommon, { state: state, dispatch: dispatch, search: search, setSearch: setSearch, isLoading: isLoading, isSearching: isSearching }));
};
/**
 * Renders the HumanFirst measure ontology.
 * @returns
 */
const OntologiesInternal = ({ data }) => {
    const initialTreeState = useMemo(() => {
        // Extract categories from the measures.
        const categories = data.map((x) => x.category);
        // Build all of the trees.
        const roots = getMeasurementHierarchyOntologyValue(categories);
        const rootsWithTopLevels = CATEGORY_GROUPS.map((group) => ({
            value: { measure: group.label },
            children: roots.filter((x) => group.categories.includes(x.value.measure)),
        }));
        // Combine the trees into a single root (this makes state management easier).\
        return { value: { measure: 'root' }, children: rootsWithTopLevels };
    }, [data]);
    const [state, dispatch] = useReducer(reducer, stateFromTree(initialTreeState));
    return React.createElement(OntologiesAlgoliaSearch, { state: state, dispatch: dispatch });
};
/**
 * Loads and renders the measure ontology.
 * @returns
 */
export const Ontologies = () => {
    const results = useElektraApi(MeasureCategoryResource.list());
    return React.createElement(QueryWrapper, Object.assign({}, results, { renderSuccess: OntologiesInternal }));
};
