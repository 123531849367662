import React from 'react';
import { Stack, Typography, TypographyVariant } from '@humanfirst/elektron';
const PasswordlessSuccess = ({ email }) => (React.createElement(Stack, { gap: "16px" },
    React.createElement(Typography, { variant: TypographyVariant.Heading1, marginBottom: "24px" }, "Check your email"),
    React.createElement(Typography, null,
        "If an account for ",
        email,
        " exists, you will recieve a link that will sign you in to Atlas."),
    React.createElement(Typography, null, "You can close this window.")));
export { PasswordlessSuccess };
