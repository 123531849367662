import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { TypographyVariant, Block, Button, Link, Spread, Stack, Typography, Spacer, Text, } from '@humanfirst/elektron';
import { useElektraApiMutation, UserResource, } from '@humanfirst/use-elektra-api';
import { generatePath } from 'src/utils/path';
import { SmartForm } from 'src/components/SmartForm';
import { useLogin } from 'src/hooks/authentication';
import { useUser } from 'src/hooks/user';
import { PATHS } from 'src/config/path';
const UpdatePasswordForm = ({ onClose }) => {
    const [resetSuccessful, setResetSuccessful] = useState(false);
    const { mutateAsync } = useElektraApiMutation(UserResource.updateSettings());
    const user = useUser();
    const login = useLogin();
    const email = user === null || user === void 0 ? void 0 : user.email;
    const onSubmit = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ currentPassword, password, confirmPassword, }) {
        if (password !== confirmPassword) {
            throw new Error("Passwords don't match");
        }
        yield mutateAsync({ currentPassword, password });
        if (email) {
            yield login({ email, password });
        }
        setResetSuccessful(true);
    }), [mutateAsync, setResetSuccessful, login, email]);
    if (resetSuccessful) {
        return (React.createElement(Block, { padding: "32px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h2", marginBottom: "24px" }, "Password changed"),
            React.createElement(Text, null, "Your password has successfully been changed."),
            React.createElement(Spacer, { size: "32px" }),
            React.createElement(Block, { display: "flex", justifyContent: "flex-end" },
                React.createElement(Button, { onClick: onClose }, "OK"))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h2", marginBottom: "24px" }, "Change Password"),
        React.createElement(SmartForm, { onSubmit: onSubmit, submitComponent: ({ isLoading }) => (React.createElement(Spread, { alignItems: "center", marginTop: "32px" },
                React.createElement(Link, { href: generatePath(PATHS.authentication.forgotPassword.request) }, "Forgot password?"),
                React.createElement(Stack, { direction: "horizontal", gap: "16px" },
                    React.createElement(Button, { type: "button", size: "large", variant: "tertiary", onClick: onClose }, "Cancel"),
                    React.createElement(Button, { type: "submit", size: "large", isLoading: isLoading }, "Save")))) },
            React.createElement(SmartForm.Input, { label: "Current Password", rules: { required: true }, id: "current-password", name: "currentPassword", type: "password", placeholder: "Enter current password" }),
            React.createElement(SmartForm.Input, { label: "New Password", tooltip: React.createElement(Typography, { variant: TypographyVariant.Body }, "Your password must contain at least 8 characters, at least 1 number, and at least 1 special character."), rules: { required: true, minLength: 8 }, id: "password", name: "password", type: "password", placeholder: "Enter new password" }),
            React.createElement(SmartForm.Input, { label: "Confirm Password", rules: { required: true, minLength: 8 }, id: "confirm-password", name: "confirmPassword", type: "password", placeholder: "Re enter your new password" }))));
};
export { UpdatePasswordForm };
