import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { showModal, clearModal, selectModal } from 'src/state';
import { useAppDispatch, useAppSelector } from './redux';
import { useAnalyticsEvent } from './analytics';
const EVENT_OPEN_MODAL = 'open-modal';
const EVENT_CLOSE_MODAL = 'close-modal';
const useIsModalOpen = () => {
    const currentModal = useAppSelector(selectModal);
    return !!(currentModal === null || currentModal === void 0 ? void 0 : currentModal.open);
};
/** Returns a callback to show a global modal. */
const useShowModal = () => {
    const track = useAnalyticsEvent();
    const dispatch = useAppDispatch();
    // We can't use `useAppSelector` because it causes re-renders any time we
    // dispatch.  We need to understand what the previous state was for tracking
    // purposes, though, so instead we're grabbing the store & doing some calls.
    const store = useStore();
    return useCallback((modal, state, modalOptions) => {
        const currentModal = selectModal(store.getState());
        if (currentModal.path !== modal) {
            if (currentModal.open) {
                // If there's currently an open modal we count this as closing and opening a new one so fire
                // off an event to understand what's going on
                track(EVENT_CLOSE_MODAL, {
                    modal: currentModal.path,
                });
            }
            track(EVENT_OPEN_MODAL, {
                modal,
                closeableModal: (modalOptions === null || modalOptions === void 0 ? void 0 : modalOptions.closable) === undefined ? true : modalOptions.closable,
            });
        }
        return dispatch(showModal({ path: modal, state, modalOptions }));
    }, [dispatch, track, store]);
};
/** A version of showModal that is bound to a specific modal. */
const useShowModalBound = (modal, state, modalOptions) => {
    const callback = useShowModal();
    return useCallback(() => callback(modal, state, modalOptions), [callback, modal, state, modalOptions]);
};
/** Returns a callback to clear the current global modal. */
const useClearModal = () => {
    const track = useAnalyticsEvent();
    const dispatch = useAppDispatch();
    // We can't use `useAppSelector` because it causes re-renders any time we
    // dispatch.  We need to understand what the previous state was for tracking
    // purposes, though, so instead we're grabbing the store & doing some calls.
    const store = useStore();
    return useCallback(() => {
        const currentModal = selectModal(store.getState());
        if (currentModal.open) {
            track(EVENT_CLOSE_MODAL, {
                modal: currentModal.path,
            });
        }
        return dispatch(clearModal());
    }, [dispatch, track, store]);
};
export { useShowModal, useClearModal, useShowModalBound, useIsModalOpen };
