import { Block, toOptions } from '@humanfirst/elektron';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { When } from 'react-if';
import { TherapeuticAreaResource } from '@humanfirst/use-elektra-api';
import { SmartInput, SmartSelect } from 'src/components/SmartForm/fields';
import { SmartForm } from 'src/components/SmartForm';
import { SmartApiModelSelect } from '../SmartForm/SmartApiModelSelect';
import { FUNCTION_OPTIONS, INDUSTRY_OPTIONS } from './constants';
import { deserialize, serialize } from './serialization';
const FormInternalSimplified = () => {
    var _a, _b;
    const { watch } = useFormContext();
    const industryValue = watch('industry');
    const functionValue = watch('function');
    const isOtherFunction = ((_a = functionValue === null || functionValue === void 0 ? void 0 : functionValue[0]) === null || _a === void 0 ? void 0 : _a.id) === 'Other';
    const isOtherIndustry = ((_b = industryValue === null || industryValue === void 0 ? void 0 : industryValue[0]) === null || _b === void 0 ? void 0 : _b.id) === 'Other';
    return (React.createElement(Block, null,
        React.createElement(SmartSelect, { label: "What industry are you in?", name: "industry", "aria-label": "select industry", options: toOptions(INDUSTRY_OPTIONS), clearable: false, rules: { required: true }, required: true, creatable: true }),
        React.createElement(When, { condition: isOtherIndustry },
            React.createElement(SmartInput, { placeholder: "Specify your industry", name: "otherIndustry", "aria-label": "other industry", required: true })),
        React.createElement(When, { condition: (industryValue === null || industryValue === void 0 ? void 0 : industryValue[0].id) === 'Pharma & Biotech' },
            React.createElement(SmartSelect, { label: "Which function best describes your role?", name: "function", "aria-label": "select function/team", options: toOptions(FUNCTION_OPTIONS), clearable: false, rules: { required: true }, creatable: true })),
        React.createElement(When, { condition: isOtherFunction },
            React.createElement(SmartInput, { placeholder: "Specify your function", name: "otherFunction", "aria-label": "other function", required: true })),
        React.createElement(SmartApiModelSelect, { label: "What is your primary therapeutic area?", definition: TherapeuticAreaResource.list(), optionGetter: (x) => ({
                id: x.therapeuticArea,
                label: x.therapeuticArea,
            }), name: "therapeuticArea", clearable: false, rules: { required: true }, required: true })));
};
const UserProfileForm = ({ defaultValue, onSubmit, submitComponent, }) => {
    const deserializedValues = defaultValue
        ? deserialize(defaultValue)
        : undefined;
    return (React.createElement(SmartForm, { onSubmit: (data) => onSubmit(serialize(data)), submitText: 'Save', defaultValues: deserializedValues, submitComponent: submitComponent },
        React.createElement(FormInternalSimplified, null)));
};
export { UserProfileForm };
