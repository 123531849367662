import { __awaiter, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { ButtonLink } from '@humanfirst/elektron';
import { useLogger } from 'src/hooks/logger';
const DEFAULT_CONTENT_TYPE = 'application/octet-stream';
function getDataURL(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        // Some browsers / environments don't have `URL.createObjectURL` so we have to add a fallback
        return yield new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(String(reader.result)));
            reader.readAsDataURL(blob);
        });
    });
}
const ExportBlobButton = (_a) => {
    var { payload, contentType } = _a, props = __rest(_a, ["payload", "contentType"]);
    const logger = useLogger('export-button');
    const [uri, setUri] = useState();
    useEffect(() => {
        let isMounted = true;
        setUri(undefined);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const serializable = typeof payload === 'function' && !(payload instanceof Blob)
                ? yield payload()
                : payload;
            const blob = new Blob([serializable], {
                type: contentType !== null && contentType !== void 0 ? contentType : DEFAULT_CONTENT_TYPE,
            });
            if (URL.createObjectURL) {
                return URL.createObjectURL(blob);
            }
            return yield getDataURL(blob);
        }))()
            .then((uri) => {
            if (isMounted) {
                setUri(uri);
            }
        })
            .catch((error) => {
            logger.error(error);
        });
        return () => {
            isMounted = false;
        };
    }, [payload, logger, contentType]);
    useEffect(() => {
        return () => {
            // On un-mount, if URL is set we need to revoke it
            if (uri && URL.revokeObjectURL) {
                URL.revokeObjectURL(uri);
            }
        };
    }, [uri]);
    return (React.createElement(ButtonLink, Object.assign({ href: uri !== null && uri !== void 0 ? uri : '#' }, props, { isLoading: uri === undefined || props.isLoading })));
};
export { ExportBlobButton };
