/** Utility hooks that make state management easier. */
import { useState, useCallback } from 'react';
function simpleCompare(a, b) {
    return a === b;
}
/**
 * State hook for toggling items in a list.
 *
 * This acts like useState, with the following exceptions:
 *   1. Values will always be a list
 *   2. The only update method is to "toggle" an item. If it's in the list
 *      it will be removed. If it is not in the list it will be added.
 */
function useToggleListState(initialValue, compare = simpleCompare) {
    const [value, setValue] = useState(initialValue);
    const toggleValue = useCallback((item) => {
        setValue((previous) => {
            return previous.some((x) => compare(x, item))
                ? previous.filter((x) => !compare(x, item))
                : [...previous, item];
        });
    }, [setValue, compare]);
    const clearValue = useCallback(() => {
        setValue([]);
    }, [setValue]);
    return [value, toggleValue, clearValue];
}
export { useToggleListState };
