import { __awaiter } from "tslib";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLogger } from '../logger';
const useAnalyticsIsConnectable = (services) => {
    const logger = useLogger(useAnalyticsIsConnectable.name);
    const isMountedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState({});
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    });
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const connectableStatus = yield Promise.all(services.map((s) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    return [s.name, yield s.isConnectable()];
                }
                catch (_a) {
                    return [s.name, false];
                }
            })));
            if (!isMountedRef.current) {
                // If we aren't mounted, don't run anything
                return;
            }
            setStatus(connectableStatus.reduce((carry, [name, isConnectable]) => {
                carry[name] = isConnectable;
                return carry;
            }, {}));
            setIsLoading(false);
        }))().catch((e) => logger.error(e));
    }, [services, logger]);
    return useMemo(() => ({
        isLoading,
        status,
    }), [isLoading, status]);
};
export { useAnalyticsIsConnectable };
