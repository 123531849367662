import { __rest } from "tslib";
import { Block, Center, Text, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { LoadingState } from '../LoadingState';
function QueryWrapper(_a) {
    var { isError, error, isLoading, data, 
    // We de-structure invalidate so the rest of the props can
    // be passed to a child component.
    /* eslint-disable @typescript-eslint/no-unused-vars */
    invalidate, isSuccess, isFetching, isStale, refetch, isInitialLoading, 
    /* eslint-enable @typescript-eslint/no-unused-vars */
    renderSuccess: SuccessComponent, renderError: ErrorComponent } = _a, props = __rest(_a, ["isError", "error", "isLoading", "data", "invalidate", "isSuccess", "isFetching", "isStale", "refetch", "isInitialLoading", "renderSuccess", "renderError"]);
    if (isError) {
        if (ErrorComponent) {
            return React.createElement(ErrorComponent, Object.assign({ error: error }, props));
        }
        return (React.createElement(Center, { margin: "16px" },
            React.createElement(Block, null,
                React.createElement(Text, null, "There was an error while loading your data."),
                React.createElement(Typography, { variant: TypographyVariant.Details }, String(error)))));
    }
    else if (isLoading || !data) {
        return React.createElement(LoadingState, null);
    }
    else {
        return (
        // Components that rely on API correctness are more likely to hit
        // errors, so we wrap this in an error boundary.
        React.createElement(ErrorBoundary, null,
            React.createElement(SuccessComponent, Object.assign({ data: data }, props))));
    }
}
export { QueryWrapper };
