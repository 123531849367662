import { __awaiter, __rest } from "tslib";
import React, { useCallback } from 'react';
import { ExportBlobButton } from 'src/components/ExportBlobButton';
const DEFAULT_CSV_FILENAME = 'export.csv';
const DEFAULT_CSV_FILETYPE = 'text/csv';
function escapeCSVField(field) {
    if (!field.match(/[\n\r,"]/)) {
        return field;
    }
    field = field.replace(/"/g, '""').replace(/\n\r|\r\n|\r|\n/g, '\\n');
    return `"${field}"`;
}
function getHeaders(records) {
    return Array.from(new Set(records.flatMap((r) => Array.from(Object.keys(r)))));
}
function getCSV(records) {
    const headers = getHeaders(records);
    const lines = [];
    for (const header of headers) {
        const headerAdded = [];
        headerAdded.push(header);
        const recordsForHeader = records.map((x) => { var _a; return (_a = String(x[header])) !== null && _a !== void 0 ? _a : ''; });
        headerAdded.push(...recordsForHeader);
        lines.push(headerAdded.map(escapeCSVField));
    }
    return lines.join('\n');
}
function serializePayload(records) {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof records === 'function') {
            return yield serializePayload(yield records());
        }
        return Array.from(getCSV(records)).join('');
    });
}
const ExportCSVButton = (_a) => {
    var { download, contentType, payload } = _a, props = __rest(_a, ["download", "contentType", "payload"]);
    const serializedPayload = useCallback(() => serializePayload(payload), [payload]);
    return (React.createElement(ExportBlobButton, Object.assign({ download: download !== null && download !== void 0 ? download : DEFAULT_CSV_FILENAME, contentType: contentType !== null && contentType !== void 0 ? contentType : DEFAULT_CSV_FILETYPE, payload: serializedPayload }, props)));
};
export { ExportCSVButton };
