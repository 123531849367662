import { Block, Stack, Icons, COLORS, SearchInput, Button, Spinner, Spacer, setOpenAction, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { t } from 'src/constants/i18n';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
/** Controls for the Ontology page. */
export const OntologyControls = ({ isLoading, search, setSearch, }) => {
    return (React.createElement(Stack, null,
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Block, { maxWidth: "640px", flex: "1" },
                React.createElement(SearchInput, { value: search, onChange: setSearch, placeholder: 'Search for measures or concepts of interest', clearable: true })),
            React.createElement(When, { condition: isLoading },
                React.createElement(Spinner, null)),
            React.createElement(When, { condition: search.length > 0 },
                React.createElement(AddToWorkspaceButton, { variant: "button", resourceType: "MeasureSearch", resourceId: search, body: 'Save this filtered search to a workspace to continue where you left off.' })))));
};
/** Buttons that can control the measure ontology tree. */
export const ControlButtons = ({ dispatch, isSearching }) => {
    return (React.createElement(Stack, { direction: "horizontal", gap: "0px", justifyContent: 'end' },
        React.createElement(Button, { variant: 'secondary', onClick: () => dispatch(setOpenAction(true)), startEnhancer: React.createElement(Icons.Misc.Expand, { color: COLORS.primary, size: "16px", decorative: true }), disabled: isSearching }, t('actions.expandAll')),
        React.createElement(Spacer, null),
        React.createElement(Button, { variant: 'secondary', onClick: () => dispatch(setOpenAction(false)), startEnhancer: React.createElement(Icons.Misc.Collapse, { color: COLORS.primary, size: "16px", decorative: true }), disabled: isSearching }, t('actions.collapseAll'))));
};
