import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { Button, COLORS, Icons, Spacer, Stack, toaster, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { AuthResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { StringParam, useQueryParam } from 'use-query-params';
import { When } from 'react-if';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { PageTitle } from 'src/components/PageTitle';
import { useIntercom } from 'src/contexts/IntercomContext';
const HelpButton = () => {
    const { showNewMessage } = useIntercom();
    return (React.createElement(Stack, null,
        React.createElement(Button, { variant: "secondary", size: 'large', onClick: () => showNewMessage() }, "Having Trouble? Contact Us.")));
};
const SignupSuccess = () => {
    const [email] = useQueryParam('email', StringParam);
    const [showResend, setShowResend] = useState(true);
    const { mutateAsync } = useElektraApiMutation(AuthResource.requestActivate());
    const onResend = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (email) {
                yield mutateAsync({ email: email });
                setShowResend(false);
                toaster.positive('Verification email sent');
            }
        }
        catch (e) {
            toaster.negative(`Could not resend email; ${String(e)}`);
        }
    }), [email, mutateAsync]);
    if (!email) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageTitle, { title: "Sign up" }),
            React.createElement(PrimaryContentCard, null,
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Almost There!"),
                    React.createElement(Spacer, null),
                    React.createElement(Typography, null, "Check your email and click on the link to verify your email and start using Atlas."),
                    React.createElement(Spacer, null),
                    React.createElement(HelpButton, null)))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Sign up" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Stack, null,
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Almost There!"),
                React.createElement(Spacer, null),
                React.createElement(Typography, null,
                    "We sent an email to ",
                    email,
                    ". Click on the link to verify your email and start using Atlas."),
                React.createElement(Stack, { direction: 'horizontal', alignItems: 'center', gap: '0px' },
                    React.createElement(Typography, null, "Didn\u2019t get the email? Check your spam, or "),
                    React.createElement(When, { condition: !showResend },
                        React.createElement(Icons.Misc.Complete, { decorative: true, color: COLORS.success }),
                        React.createElement(Spacer, { size: '2px' })),
                    React.createElement(Button, { variant: "link", onClick: onResend, disabled: !showResend, size: 'compact' }, "Resend verification email")),
                React.createElement(Spacer, null),
                React.createElement(HelpButton, null)))));
};
export { SignupSuccess };
