import React, { useMemo } from 'react';
import { useToggle } from 'src/hooks/toggle';
import { getPopulationFromEvidence } from 'src/utils/evidence';
import { TitleCell, TherapeuticAreaCell, MedicalConditionCell, DateCell, MeasurementCell, GroupedMeasurementCell, ToolListDeduped, CTPhaseCell, CTSponsorCell, CTSponsorTypeCell, DHTUseContextCell, AgeCell, SampleSizeCell, StudyDesignCell, V3Cell, EvidenceTypeCell, } from './cells';
import { getMeasuresSearchValue } from './search';
import { getTherapeuticAreaSortValue, getMedicalConditionSortValue, getDateSortValue, } from './sort';
import { getSecondaryFacts } from './utils';
const HIDDEN_COLUMNS = new Set(['therapeutic-area', 'medical-condition']);
/** Searches the title as well as any metadata included in the title cell. */
const getTitleSearch = (item) => {
    return item.title + getSecondaryFacts(item).join(' ');
};
const BASE_COLUMNS = [
    {
        id: 'title',
        header: 'Title',
        render: (item) => React.createElement(TitleCell, { item: item }),
        sortable: true,
        searchable: true,
        getStringValue: getTitleSearch,
        metadata: { displayRole: 'required', group: 1 },
    },
    {
        id: 'year',
        header: 'Year',
        render: (item) => React.createElement(DateCell, { item: item }),
        sortable: true,
        searchable: true,
        getStringValue: getDateSortValue,
        metadata: { displayRole: 'display', group: 3 },
    },
    {
        id: 'therapeutic-area',
        header: 'Therapeutic Area',
        render: (item) => React.createElement(TherapeuticAreaCell, { item: item }),
        sortable: true,
        searchable: true,
        getStringValue: getTherapeuticAreaSortValue,
        metadata: { displayRole: 'hidden', group: 1 },
    },
    {
        id: 'medical-condition',
        header: 'Medical Condition',
        render: (item) => React.createElement(MedicalConditionCell, { item: item }),
        sortable: true,
        searchable: true,
        getStringValue: getMedicalConditionSortValue,
        metadata: { displayRole: 'hidden', group: 1 },
    },
];
const SECONDARY_BASE_COLUMNS = [
    {
        id: 'evidenceType',
        header: 'Evidence Type',
        render: (item) => React.createElement(EvidenceTypeCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a, _b; return (_b = (_a = item.typeOfSource) === null || _a === void 0 ? void 0 : _a.join(',')) !== null && _b !== void 0 ? _b : ''; },
        metadata: { displayRole: 'hidden', group: 3 },
    },
    {
        id: 'dhtUseContext',
        header: 'DHT Use Context',
        render: (item) => React.createElement(DHTUseContextCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a, _b; return (_b = (_a = item.dhtUseContext) === null || _a === void 0 ? void 0 : _a.join(',')) !== null && _b !== void 0 ? _b : ''; },
        metadata: { displayRole: 'hidden', group: 2 },
    },
    {
        id: 'v3',
        header: 'V3 Type',
        render: (item) => React.createElement(V3Cell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a, _b; return (_b = (_a = item.v3) === null || _a === void 0 ? void 0 : _a.join(',')) !== null && _b !== void 0 ? _b : ''; },
        metadata: { displayRole: 'hidden', group: 2 },
    },
    {
        id: 'studyDesign',
        header: 'Study Design',
        render: (item) => React.createElement(StudyDesignCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a, _b; return (_b = (_a = item.studyDesign) === null || _a === void 0 ? void 0 : _a.join(',')) !== null && _b !== void 0 ? _b : ''; },
        metadata: { displayRole: 'hidden', group: 3 },
    },
    {
        id: 'sampleSize',
        header: 'Sample Size',
        render: (item) => React.createElement(SampleSizeCell, { item: item }),
        searchable: true,
        getStringValue: (item) => (item.sampleSize ? String(item.sampleSize) : ''),
        metadata: { displayRole: 'hidden', group: 3 },
    },
    {
        id: 'age',
        header: 'Age',
        render: (item) => React.createElement(AgeCell, { item: item }),
        searchable: true,
        getStringValue: (item) => getPopulationFromEvidence({ evidence: [item] }),
        metadata: { displayRole: 'hidden', group: 1 },
    },
    {
        id: 'ctSponsorType',
        header: 'Sponsor Type',
        render: (item) => React.createElement(CTSponsorTypeCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a; return (_a = item.ctSponsorType) !== null && _a !== void 0 ? _a : ''; },
        metadata: { displayRole: 'hidden', group: 4 },
    },
    {
        id: 'ctSponsor',
        header: 'Sponsor',
        render: (item) => React.createElement(CTSponsorCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a; return (_a = item.ctSponsor) !== null && _a !== void 0 ? _a : ''; },
        metadata: { displayRole: 'hidden', group: 4 },
    },
    {
        id: 'ctPhase',
        header: 'CT Phase',
        render: (item) => React.createElement(CTPhaseCell, { item: item }),
        searchable: true,
        getStringValue: (item) => { var _a; return (_a = item.ctPhase) !== null && _a !== void 0 ? _a : ''; },
        metadata: { displayRole: 'hidden', group: 4 },
    },
];
const MEASURE_COLUMN = {
    id: 'measures',
    header: 'Measure',
    render: (item) => React.createElement(MeasurementCell, { item: item }),
    searchable: true,
    getStringValue: getMeasuresSearchValue,
    metadata: { displayRole: 'display', group: 1 },
};
const GROUPED_MEASURE_COLUMN = {
    id: 'measures',
    header: 'Measure',
    render: (item) => React.createElement(GroupedMeasurementCell, { item: item }),
    searchable: true,
    getStringValue: getMeasuresSearchValue,
    metadata: { displayRole: 'display', group: 1 },
};
const TECHNOLOGY_COLUMN = {
    id: 'technology',
    header: 'Technology',
    render: (item) => React.createElement(ToolListDeduped, { item: item }),
    searchable: true,
    getStringValue: (x) => {
        var _a, _b;
        return (_b = (_a = x.sourceMeasures) === null || _a === void 0 ? void 0 : _a.flatMap((x) => { var _a; return (_a = x.tools) === null || _a === void 0 ? void 0 : _a.flatMap((x) => x.model); }).join(' ')) !== null && _b !== void 0 ? _b : '';
    },
    metadata: { displayRole: 'display', group: 2 },
};
export const useEvidenceColumns = (hasTechnology) => {
    const hasCustomizedColumns = useToggle('custom-columns');
    return useMemo(() => {
        // We show them with custom columns, even if hidden (because the base
        // role is hidden).
        const columns = !hasCustomizedColumns
            ? BASE_COLUMNS.filter((x) => !HIDDEN_COLUMNS.has(x.id))
            : [...BASE_COLUMNS];
        if (hasTechnology) {
            columns.push(GROUPED_MEASURE_COLUMN);
        }
        else {
            columns.push(MEASURE_COLUMN);
        }
        if (hasTechnology) {
            columns.push(TECHNOLOGY_COLUMN);
        }
        const secondaryColumns = !hasCustomizedColumns
            ? SECONDARY_BASE_COLUMNS.filter((x) => !HIDDEN_COLUMNS.has(x.id))
            : [...SECONDARY_BASE_COLUMNS];
        columns.push(...secondaryColumns);
        return columns;
    }, [hasTechnology, hasCustomizedColumns]);
};
