import { TechnologySearchType } from '@humanfirst/api-models';
import { Block, ButtonLink, COLORS, Stack, Text, } from '@humanfirst/elektron';
import { ToolResource } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { SPONSOR_PRODUCT_FORM } from 'src/constants/airtableForms';
import { Indicies } from 'src/constants/search';
import { useSearchClient } from 'src/hooks/search';
import { SmartAsyncTypeahead } from '../SmartAsyncTypeahead';
import { buildAlgoliaFetcher } from './algoliaFetcher';
const hitToOption = (x) => ({
    id: x.airtableId,
    label: `${x.model} (${x.maker})`,
});
const NoToolResults = () => {
    return (React.createElement(Stack, null,
        React.createElement(Text, { color: COLORS.gray1 }, "Can't find what you're looking for?"),
        React.createElement(Block, null,
            React.createElement(ButtonLink, { href: SPONSOR_PRODUCT_FORM }, "Let us know"))));
};
/**
 * A select that operates over the Tool model.
 *
 * Look-ups are performed against our algolia index. Labels are rendered with the
 * model and the maker name.
 */
const SmartToolSelect = (props) => {
    const { isReady, searchClient } = useSearchClient(ToolResource.getSearchToken(TechnologySearchType.MakeOrModel));
    const toolFetcher = useMemo(() => {
        return buildAlgoliaFetcher(searchClient, Indicies.Tools, hitToOption);
    }, [searchClient]);
    return (React.createElement(SmartAsyncTypeahead, Object.assign({ noResultsMsg: React.createElement(NoToolResults, null) }, props, { getter: toolFetcher, disabled: !isReady || props.disabled })));
};
export { SmartToolSelect };
