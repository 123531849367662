import React, { useMemo } from 'react';
import { ExportCSVButton } from 'src/components/ExportCSVButton';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { ToolAttribute } from 'src/components/ModelAttribute/ToolAttribute';
function attributeToString(item, attribute) {
    if (typeof item[attribute] === 'string' ||
        typeof item[attribute] === 'number') {
        return String(item[attribute]);
    }
    if (Array.isArray(item[attribute])) {
        if (item[attribute].every((a) => typeof a === 'string')) {
            return item[attribute].join(', ');
        }
        return item[attribute]
            .map((a) => {
            if (typeof a === 'object' && (a === null || a === void 0 ? void 0 : a.hasOwnProperty('url'))) {
                return a.url;
            }
            return String(a);
        })
            .join(', ');
    }
    return '';
}
function getToolAttributes(tool, permissions) {
    var _a, _b, _c;
    const attributes = {};
    attributes[ToolAttribute.ProductDescription.label] = attributeToString(tool, 'productDescription');
    // This should probably be handled by a value getter
    // but there's a lot of complexity around that.
    if (permissions.showDetailedEvidence) {
        attributes[ToolAttribute.Population.label] = attributeToString(tool, 'population');
    }
    if (permissions.showEvidenceSummary) {
        attributes[ToolAttribute.Evidence.label] = String(tool.countOfEvidenceMeasures);
        attributes[ToolAttribute.ValidationTotal.label] = attributeToString(tool, 'validationTotal');
        attributes[ToolAttribute.V3Verification.label] = attributeToString(tool, 'v3Verification');
        attributes[ToolAttribute.V3Analytical.label] = attributeToString(tool, 'v3Analytical');
        attributes[ToolAttribute.V3Clinical.label] = attributeToString(tool, 'v3Clinical');
    }
    if (permissions.showDetailedEvidence) {
        attributes[ToolAttribute.CTPhases.label] = attributeToString(tool, 'clinicalTrialPhases');
    }
    if (permissions.showRegulatoryDecisions) {
        attributes[ToolAttribute.FDAClassification.label] = attributeToString(tool, 'fdaClassification');
        attributes[ToolAttribute.CEMark.label] = attributeToString(tool, 'ceMark');
    }
    attributes[ToolAttribute.SensorType.label] = attributeToString(tool, 'modalitiesSensorType');
    attributes[ToolAttribute.FormFactor.label] = attributeToString(tool, 'formFactor');
    attributes[ToolAttribute.WearLocation.label] = attributeToString(tool, 'wearLocation');
    attributes[ToolAttribute.BatteryType.label] = attributeToString(tool, 'batteryType');
    attributes[ToolAttribute.BatteryMin.label] = attributeToString(tool, 'batteryLifeMin');
    attributes[ToolAttribute.BatteryMax.label] = attributeToString(tool, 'batteryLifeMax');
    attributes[ToolAttribute.Waterproof.label] = attributeToString(tool, 'waterproof');
    attributes[ToolAttribute.Connectivity.label] = attributeToString(tool, 'dataExchangeModalities');
    attributes[ToolAttribute.OperatingSystem.label] = attributeToString(tool, 'compatibleOperatingSystem');
    attributes[ToolAttribute.Manual.label] = attributeToString(tool, 'manualCDN');
    attributes[ToolAttribute.SpecSheet.label] = attributeToString(tool, 'specificationSheetCDN');
    if (tool.componentType !== 'Software-Based Component') {
        attributes[ToolAttribute.Software.label] = attributeToString(tool, 'softwareAlgorithm');
    }
    attributes[ToolAttribute.HasApi.label] = `${attributeToString(tool, 'hasAPI')} ${attributeToString(tool, 'apiUrl')}`;
    attributes[ToolAttribute.HasSDK.label] = `${attributeToString(tool, 'hasSDK')} ${attributeToString(tool, 'sdkUrl')}`;
    attributes[ToolAttribute.AccessToPreprocessedData.label] = attributeToString(tool, 'accessToPreprocessedData');
    if (tool.componentType === 'Software-Based Component') {
        attributes[ToolAttribute.RequiredHardwareSBC.label] = attributeToString(tool, 'requiredHardwareSBC');
        attributes[ToolAttribute.RequiredSensorsSBC.label] = attributeToString(tool, 'requiredSensorsSBC');
        attributes[ToolAttribute.DataCollection.label] = attributeToString(tool, 'dataCollection');
    }
    attributes[ToolAttribute.Availability.label] = attributeToString(tool, 'availability');
    attributes[ToolAttribute.Distribution.label] = attributeToString(tool, 'distributionChannel');
    attributes[ToolAttribute.Price.label] = attributeToString(tool, 'price');
    attributes[ToolAttribute.ProductPrivacyPolicy.label] = attributeToString(tool, 'productPrivacyPolicy');
    attributes[ToolAttribute.PrivacyPolicy.label] = attributeToString(tool, 'privacyPolicy');
    attributes[ToolAttribute.CVD.label] = attributeToString(tool, 'cvd');
    attributes[ToolAttribute.PublishedUpdates.label] = attributeToString(tool, 'publishedUpdates');
    const toolPath = generatePath(PATHS.atlas.technologies.detail, {
        id: tool.id,
    });
    const toolUrl = new URL(toolPath, window.location.href);
    const makerPath = generatePath(PATHS.atlas.vendors.detail, {
        id: (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.id,
    });
    const makerUrl = new URL(makerPath, window.location.href);
    return Object.assign({ 'Atlas URL': toolUrl.toString(), 'Atlas Maker URL': makerUrl.toString(), Maker: (_c = (_b = tool.maker) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '', Model: tool.model }, attributes);
}
const CSVButton = ({ resource, }) => {
    const showRegulatoryDecisions = useHasPermission('component.regulatoryDecisions', 'read');
    const showEvidenceSummary = useHasPermission('component.evidenceSummary', 'read');
    const showDetailedEvidence = useHasPermission('component.evidence', 'read');
    const records = useMemo(() => {
        if (Array.isArray(resource)) {
            return resource.map((r) => getToolAttributes(r, {
                showEvidenceSummary,
                showRegulatoryDecisions,
                showDetailedEvidence,
            }));
        }
        return [
            getToolAttributes(resource, {
                showEvidenceSummary,
                showRegulatoryDecisions,
                showDetailedEvidence,
            }),
        ];
    }, [
        resource,
        showEvidenceSummary,
        showRegulatoryDecisions,
        showDetailedEvidence,
    ]);
    const generatedTimeString = new Date()
        .toISOString()
        .replace(/\..+/, '')
        .replace(/[^0-9T]/g, '-');
    const filename = `atlas-technology-${generatedTimeString}.csv`;
    return (React.createElement(ExportCSVButton, { download: filename, payload: records, variant: "minimal", size: "large" }, "To CSV"));
};
export { CSVButton };
