import { Block, Card, COLORS, Icons, Spacer, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { ToolAttribute } from 'src/components/ModelAttribute/ToolAttribute';
const QuestionnaireRegulatoryCard = ({ tool }) => {
    const attributes = [
        [ToolAttribute.FDAClassification, Icons.Misc.CRO],
        [ToolAttribute.MDDTClassification, Icons.Misc.CRO],
    ];
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Regulatory Information"),
        React.createElement(Spacer, { size: '32px' }),
        React.createElement(Stack, { gap: "24px" }, attributes.map(([Attribute, Icon]) => (React.createElement(Stack, { key: Attribute.label, direction: "horizontal", gap: "16px" },
            React.createElement(Block, { minWidth: "24px" },
                React.createElement(Icon, { size: "24px", color: COLORS.gray1, title: Attribute.label, decorative: true })),
            React.createElement(Stack, { gap: "8px", flex: "1" },
                React.createElement(Spread, null,
                    React.createElement(Typography, { color: COLORS.gray1 }, Attribute.label),
                    React.createElement(EditFlagTooltip, { field: Attribute.label })),
                React.createElement(Attribute, { item: tool }))))))));
};
export { QuestionnaireRegulatoryCard };
