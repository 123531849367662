import { useMemo } from 'react';
const SCHEMA_DEFAULTS = {
    pagination: { pageSize: 20 },
    sortable: true,
    searchable: true,
};
/** Applies priority filters to a set of filters. */
function applyPriorityFilters(filters, priorityFilters) {
    if (!filters) {
        return filters;
    }
    if (!priorityFilters) {
        return filters.flatMap((x) => {
            var _a;
            return (Object.assign(Object.assign({}, x), { metadata: Object.assign(Object.assign({}, x.metadata), { priority: (_a = x.metadata.priority) !== null && _a !== void 0 ? _a : 'standard' }) }));
        });
    }
    return filters.map((x) => {
        const priorityAfterOverrides = x.metadata.priority === 'priority' ? 'standard' : x.metadata.priority;
        return Object.assign(Object.assign({}, x), { metadata: Object.assign(Object.assign({}, x.metadata), { priority: (priorityFilters === null || priorityFilters === void 0 ? void 0 : priorityFilters.includes(x.id))
                    ? 'priority'
                    : priorityAfterOverrides }) });
    });
}
/**
 * Combines a schema with props for a generic table to produce a final schema.
 *
 * The base schema may define columns/filters, but a client may want to specify
 * extra columns, or different filter priorities. This hook combines all of that
 * information to create a final schema. It will also apply defaults for pagination,
 * seachability, etc.
 * @param schema
 * @param props
 */
export const useCustomizedSchema = (schema, { extraColumns, priorityFilters }) => {
    return useMemo(() => (Object.assign(Object.assign(Object.assign({}, SCHEMA_DEFAULTS), schema), { columns: [...schema.columns, ...(extraColumns !== null && extraColumns !== void 0 ? extraColumns : [])], filters: applyPriorityFilters(schema.filters, priorityFilters), filterVariant: 'advanced' })), [extraColumns, schema, priorityFilters]);
};
