import { Block, CardContent, CardSurface, COLORS, Divider, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { uniqBy } from 'ramda';
import React from 'react';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getEvidenceBucket } from 'src/utils/buckets';
import { isIntegratorAlgorithmUtil } from 'src/utils/vendorType';
const TherapeuticAreaRow = ({ therapeuticArea, evidenceCount, hasFullEvidenceAccess }) => {
    return (React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
        React.createElement(Stack, { flex: "1" },
            React.createElement(Typography, null, therapeuticArea === null || therapeuticArea === void 0 ? void 0 : therapeuticArea.therapeuticArea)),
        React.createElement(Block, { flex: "1" },
            React.createElement(Typography, { variant: TypographyVariant.Details }, hasFullEvidenceAccess
                ? evidenceCount
                : getEvidenceBucket(evidenceCount)))));
};
export const VendorTherapeuticAreasInternal = ({ data: evidence, hasFullEvidenceAccess }) => {
    const therapeuticAreas = evidence
        .flatMap((e) => e.therapeuticArea)
        .filter((ta) => !!(ta === null || ta === void 0 ? void 0 : ta.id) && (ta === null || ta === void 0 ? void 0 : ta.therapeuticArea) !== 'Not Specified')
        .sort((a, b) => { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.therapeuticArea) !== null && _a !== void 0 ? _a : '').localeCompare((_b = b === null || b === void 0 ? void 0 : b.therapeuticArea) !== null && _b !== void 0 ? _b : ''); }) || [];
    const uniqueTAs = uniqBy((x) => { var _a; return (_a = x === null || x === void 0 ? void 0 : x.id) !== null && _a !== void 0 ? _a : ''; }, therapeuticAreas);
    if (uniqueTAs.length < 1) {
        return null;
    }
    return (React.createElement(CardSurface, null,
        React.createElement(CardContent, null,
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Therapeutic Areas"),
                React.createElement(Typography, { variant: TypographyVariant.Details }, "The therapeutic areas this vendor\u2019s integration platform has been used in, as reported in published evidence."),
                React.createElement(Stack, null,
                    React.createElement(Stack, null,
                        React.createElement(Stack, { direction: "horizontal" },
                            React.createElement(Stack, { flex: "1", direction: "horizontal" },
                                React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Therapeutic Area")),
                            React.createElement(Block, { flex: "1" },
                                React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                                    React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Evidence Count"))))),
                    React.createElement(Divider, null), uniqueTAs === null || uniqueTAs === void 0 ? void 0 :
                    uniqueTAs.map((area) => {
                        if (!area) {
                            return null;
                        }
                        const evidenceCount = therapeuticAreas.filter((x) => (x === null || x === void 0 ? void 0 : x.id) === area.id).length;
                        return (React.createElement(TherapeuticAreaRow, { key: area === null || area === void 0 ? void 0 : area.id, therapeuticArea: area, evidenceCount: evidenceCount, hasFullEvidenceAccess: hasFullEvidenceAccess }));
                    }))))));
};
export const VendorTherapeuticAreas = ({ organization }) => {
    var _a, _b, _c;
    const hasFullEvidenceAccess = useHasPermission('organization.evidence', 'read', organization);
    const vendorTech = (_c = (_b = (_a = organization.components) === null || _a === void 0 ? void 0 : _a.filter((x) => isIntegratorAlgorithmUtil(x))) === null || _b === void 0 ? void 0 : _b.flatMap((x) => x.id)) !== null && _c !== void 0 ? _c : [];
    const result = useElektraApi(EvidenceResource.list({
        or: vendorTech.map((id) => ['tools', '$cont', id]),
        join: ['therapeuticArea'],
    }), { enabled: vendorTech.length > 0 });
    if (vendorTech.length === 0) {
        return null;
    }
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: VendorTherapeuticAreasInternal }, result, { hasFullEvidenceAccess: hasFullEvidenceAccess })));
};
