const MODAL_PATHS = {
    lockedMaterial: 'locked',
    atlasSuggestions: 'atlas-feedback',
    pmfSurvey: 'atlas-pmf',
    userProfileQuestionnaire: 'user-profile-questionnaire',
    addToWorkspace: 'add-to-workspace',
    share: 'share',
    applyWorkspaceTemplate: 'apply-workspace-template',
    contactUs: 'contact-us',
    removeFromWorkspace: 'remove-from-workspace',
    uploadRemoval: 'upload-removal',
    requestEvidenceIngestion: 'request-evidence-ingestion',
};
export { MODAL_PATHS };
