import { Divider, Icons, SideNavigation as SideNavigationElektron, Spacer, Stack, } from '@humanfirst/elektron';
import React, { memo } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router';
import { When } from 'react-if';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { useUser } from 'src/hooks/user';
import { IfToggle } from 'src/components/IfToggle';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const ADMIN_LINKS = [
    { url: '/admin/users', title: t('navigation.side.admin.users') },
    { url: '/admin/admin-teams', title: t('navigation.side.admin.teams') },
    { url: '/admin/roles', title: t('navigation.side.admin.roles') },
    {
        url: '/admin/admin-projects',
        title: t('navigation.side.admin.workspaces'),
    },
    { url: '/admin/admin-studies', title: t('navigation.side.admin.studies') },
    { url: '/admin/reports', title: t('navigation.side.admin.reports') },
    { url: '/admin/utilities', title: t('navigation.side.admin.utilities') },
    { url: '/admin/documents', title: t('navigation.side.admin.documents') },
];
const SideNavigationInternal = ({ currentPath, isOpen, setIsOpen }) => {
    var _a;
    const user = useUser();
    const isMaker = (user === null || user === void 0 ? void 0 : user.role) === 4;
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read', 'all');
    const hasConnect = useHasPermission('connect', 'read', 'all');
    return (React.createElement(SideNavigationElektron, { isOpen: isOpen, setIsOpen: setIsOpen, automaticallyExpand: true },
        React.createElement(SideNavigationElektron.LinkWithPanel, { title: t('navigation.side.atlasCatalog.index'), path: "/atlas", icon: Icons.Navigation.Atlas, isActive: !!matchPath({ path: '/atlas', end: false }, location.pathname) },
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.atlasCatalog.tech'), path: "/atlas/technologies", isActive: !!matchPath({ path: '/atlas/technologies', end: false }, currentPath) }),
            React.createElement(IfToggle, { name: "list-evidence" },
                React.createElement(When, { condition: hasEvidenceSource },
                    React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.atlasCatalog.evidence'), path: "/atlas/evidence", isActive: !!matchPath({ path: '/atlas/evidence', end: false }, currentPath) }))),
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.atlasCatalog.measures'), path: "/atlas/measures", isActive: !!matchPath({ path: '/atlas/measures', end: false }, currentPath) }),
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.atlasCatalog.medicalConditions'), path: "/atlas/medical-conditions", isActive: !!matchPath({ path: '/atlas/medical-conditions', end: false }, currentPath) }),
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.atlasCatalog.vendor'), path: "/atlas/vendors", isActive: !!matchPath({ path: '/atlas/vendors', end: false }, currentPath) })),
        React.createElement(When, { condition: hasConnect },
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.connectCatalog.index'), path: "/catalog/studies", icon: Icons.Navigation.Reports, isActive: !!matchPath({ path: '/catalog/studies', end: false }, currentPath) })),
        React.createElement(Stack, { direction: 'horizontal', gap: '0px' },
            React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.workspaces.index'), path: "/projects", icon: Icons.Navigation.Workspaces, isActive: !!matchPath({ path: '/projects', end: false }, currentPath) })),
        isMaker ? (React.createElement(SideNavigationElektron.Link, { title: t('navigation.side.maker.index'), icon: Icons.Navigation.Products, isActive: !!matchPath({ path: '/maker/:id/products', end: false }, currentPath), path: generatePath(PATHS.products, {
                makerId: (_a = user.organizationId) !== null && _a !== void 0 ? _a : 'demo',
            }) })) : null,
        (user === null || user === void 0 ? void 0 : user.isAdministrator) ? (React.createElement(SideNavigationElektron.LinkWithPanel, { title: t('navigation.side.admin.index'), icon: Icons.Navigation.Admin, path: '/admin/admin-projects', isActive: !!matchPath({ path: '/admin', end: false }, currentPath) }, ADMIN_LINKS.map(({ title, url }) => (React.createElement(SideNavigationElektron.Link, { title: title, path: url, key: title, isActive: !!matchPath({ path: url, end: false }, currentPath) }))))) : null,
        React.createElement(Spacer, { size: "24px" }),
        React.createElement(Divider, null),
        React.createElement(Spacer, { size: "24px" }),
        React.createElement(SideNavigationElektron.Link, { title: "Help Center", path: PATHS.helpCenter.index, icon: Icons.Misc.Help }),
        React.createElement(SideNavigationElektron.Link, { title: "Evidence Ingestion", path: PATHS.helpCenter.usingAtlas.measuresAndEvidence.evidenceIngestion, icon: Icons.Misc.LinkArrow }),
        React.createElement(SideNavigationElektron.Link, { title: "Measure Ontology", path: PATHS.helpCenter.usingAtlas.measuresAndEvidence.exploreOverview, icon: Icons.Misc.LinkArrow })));
};
const SideNavigationInternalMemo = memo(SideNavigationInternal);
const SideNavigation = ({ isOpen, setIsOpen }) => {
    const location = useLocation();
    return (React.createElement(SideNavigationInternalMemo, { currentPath: location.pathname, isOpen: isOpen, setIsOpen: setIsOpen }));
};
export { SideNavigation };
