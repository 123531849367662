import React from 'react';
import { Block, COLORS, Image } from '@humanfirst/elektron';
import ToolDefaultImage from 'src/assets/tool-no-image.png';
const DEFAULT_OVERRIDES = {
    Block: {
        style: {
            backgroundColor: COLORS.gray4,
            borderRadius: '8px',
        },
    },
};
const MISSING_OVERRIDES = {
    Block: {
        style: {
            border: `dashed 1px ${COLORS.gray1}`,
            opacity: '0.3',
            borderRadius: '8px',
        },
    },
};
const ToolImage = ({ imageSize, tool, }) => {
    var _a, _b, _c, _d;
    const attachment = (_a = tool.imageSubmittedCDN) === null || _a === void 0 ? void 0 : _a[0];
    const thumbnails = attachment === null || attachment === void 0 ? void 0 : attachment.thumbnails;
    const isAvailable = tool.availability !== 'Discontinued' &&
        tool.componentType !== 'Model Unspecified';
    const alt = tool.model;
    // Default to the raw image.
    let imageUrl = attachment === null || attachment === void 0 ? void 0 : attachment.url;
    // If thumnails and the image size are defined, try to match the closest thumbnail
    if (thumbnails && !!imageSize) {
        // Create array of sizes in the order we would lke to check them. Largest to smallest.
        const sizeOptions = [
            thumbnails.full, // width/height will be 3000
            {
                height: Number(attachment.width),
                width: Number(attachment.width),
                url: attachment.url,
            }, // width/height will be 560
            thumbnails.large, // width/height will be 512
            thumbnails.small, // width/height will be 36
        ];
        for (const { width, height, url } of sizeOptions) {
            // These values should be the same. Checking them both to be through
            if (width > imageSize && height > imageSize) {
                imageUrl = url;
            }
        }
    }
    const size = imageSize !== undefined ? `${imageSize}px` : undefined;
    const vendorImageUrl = (_d = (_c = (_b = tool.maker) === null || _b === void 0 ? void 0 : _b.logoCDN) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.url;
    if (!imageUrl && vendorImageUrl) {
        imageUrl = vendorImageUrl;
    }
    return (React.createElement(Block, { width: size, height: size, minWidth: size, minHeight: size, display: "flex", padding: "8px", overrides: isAvailable ? DEFAULT_OVERRIDES : MISSING_OVERRIDES },
        React.createElement(Image, { key: tool.id, width: "100%", height: "100%", objectFit: "contain", alt: alt, src: imageUrl, fallback: ToolDefaultImage })));
};
export { ToolImage };
