import { toValue } from '@humanfirst/elektron';
const serialize = (formContent) => {
    var _a, _b, _c, _d, _e;
    if (!((_a = formContent.team) === null || _a === void 0 ? void 0 : _a.length)) {
        throw new Error('Missing Team');
    }
    return {
        name: formContent.name,
        team: { id: formContent.team[0].id },
        customFieldsSet: ((_b = formContent.customFieldsSet) === null || _b === void 0 ? void 0 : _b.length)
            ? formContent.customFieldsSet[0].id
            : null,
        conceptsOfInterest: ((_c = formContent.conceptsOfInterest) === null || _c === void 0 ? void 0 : _c.length)
            ? formContent.conceptsOfInterest.map((x) => x.id)
            : null,
        medicalConditions: ((_d = formContent.medicalConditions) === null || _d === void 0 ? void 0 : _d.length)
            ? formContent.medicalConditions.map((x) => x.id)
            : null,
        therapeuticArea: ((_e = formContent.therapeuticArea) === null || _e === void 0 ? void 0 : _e.length)
            ? formContent.therapeuticArea[0].id
            : undefined,
        additionalMetadata: formContent.additionalMetadata,
        tools: formContent.tools.length ? formContent.tools.map((x) => x.id) : null,
        isArchived: formContent.isArchived,
    };
};
const deserialize = (study) => {
    var _a, _b, _c, _d, _e;
    return {
        name: study.name,
        team: study.team ? [{ id: study.team.id }] : undefined,
        customFieldsSet: study.customFieldsSet
            ? [{ id: study.customFieldsSet }]
            : undefined,
        additionalMetadata: (_a = study.additionalMetadata) !== null && _a !== void 0 ? _a : undefined,
        conceptsOfInterest: ((_b = study.conceptsOfInterest) === null || _b === void 0 ? void 0 : _b.length)
            ? study.conceptsOfInterest.map((x) => ({
                id: x,
            }))
            : undefined,
        medicalConditions: ((_c = study.medicalConditions) === null || _c === void 0 ? void 0 : _c.length)
            ? study.medicalConditions.map((x) => ({
                id: x,
            }))
            : undefined,
        therapeuticArea: toValue(study.therapeuticArea),
        tools: (_e = (_d = study.tools) === null || _d === void 0 ? void 0 : _d.map((x) => ({ id: x, label: x }))) !== null && _e !== void 0 ? _e : [],
        isArchived: study.isArchived,
    };
};
export { deserialize, serialize };
