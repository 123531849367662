import { TextColumn, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { MedicalConditionTitleCell } from './cells';
export const MEDICAL_CONDITION_COLUMN = TextColumn('name', {
    header: 'Medical Condition',
    render: (item) => React.createElement(MedicalConditionTitleCell, { condition: item }),
    // Include synonyms in the name so they'll pop up in search.
    getStringValue: (item) => { var _a, _b; return item.name + ' ' + ((_b = (_a = item.synonyms) === null || _a === void 0 ? void 0 : _a.join(' ')) !== null && _b !== void 0 ? _b : ''); },
});
export const THERAPEUTIC_AREA_COLUMN = TextColumn('therapeuticArea', {
    header: 'Therapeutic Area',
    render: (item) => {
        var _a;
        return (React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = item.therapeuticArea) === null || _a === void 0 ? void 0 : _a.therapeuticArea));
    },
    getStringValue: (item) => { var _a, _b; return (_b = (_a = item.therapeuticArea) === null || _a === void 0 ? void 0 : _a.therapeuticArea) !== null && _b !== void 0 ? _b : ''; },
});
export const ICD_10_COLUMN = TextColumn('icd10', { header: 'ICD-10' });
