import { FilterType } from '@humanfirst/elektron';
const isSafeValue = (value) => {
    return !!value.match(/^[-a-zA-Z0-9]+$/);
};
const escapeFilterValue = (value) => {
    const stringValue = typeof value === 'string' ? value : String(value);
    if (isSafeValue(stringValue)) {
        return stringValue;
    }
    return `"${stringValue.replace(/"/g, '\\"')}"`;
};
function serializeNumericRange(filterValue) {
    if (!Array.isArray(filterValue)) {
        return serializeFilterValue(FilterType.IS_IN_NUMERIC_RANGE, [
            filterValue,
            Infinity,
        ]);
    }
    if (filterValue.every((n) => Array.isArray(n))) {
        return filterValue.flatMap(serializeNumericRange);
    }
    const [low = -Infinity, high = Infinity] = filterValue;
    if (typeof low !== 'number' || typeof high !== 'number') {
        return [];
    }
    // If the low & high are both infinity, skip them
    if (low === -Infinity && high === Infinity) {
        return [];
    }
    if (low === -Infinity) {
        return [`<${high}`];
    }
    if (high === Infinity) {
        return [`>${low}`];
    }
    return [`${low}..${high}`];
}
function serializeFilterValue(filterType, filterValue) {
    switch (filterType) {
        case FilterType.INCLUDES_ALL:
            if (!Array.isArray(filterValue)) {
                return serializeFilterValue(FilterType.EQUALS, filterValue);
            }
            return filterValue.flatMap((v) => serializeFilterValue(FilterType.EQUALS, v));
        case FilterType.INCLUDES_NONE:
        case FilterType.INCLUDES_ANY:
            if (!Array.isArray(filterValue)) {
                return serializeFilterValue(FilterType.EQUALS, filterValue);
            }
            return [
                filterValue
                    .map(escapeFilterValue)
                    .sort((a, b) => a.localeCompare(b)) // Sort so that we have a consistent query serialized value
                    .join(','),
            ];
        case FilterType.IS_IN_NUMERIC_RANGE:
            return serializeNumericRange(filterValue);
        case FilterType.EQUALS:
            return [escapeFilterValue(filterValue)];
        case FilterType.GREATER_THAN:
            return [`>${String(filterValue)}`];
        case FilterType.LESS_THAN:
            return [`<${String(filterValue)}`];
    }
}
const serializeQuery = ({ search, filters }) => {
    const query = [];
    if (search) {
        query.push(search);
    }
    if (filters) {
        for (const { filterValue, filterType, fieldId } of filters) {
            if (filterValue) {
                query.push(...serializeFilterValue(filterType, filterValue).map((v) => `${filterType === FilterType.INCLUDES_NONE ? '-' : ''}${fieldId}:${v}`));
            }
        }
    }
    return query
        .sort((a, b) => a.localeCompare(b)) // Sort so that we have a consistent query serialized value
        .join(' ');
};
function isCollapsibleFilter({ filterType }) {
    return (filterType === FilterType.EQUALS ||
        filterType === FilterType.INCLUDES_ANY ||
        filterType === FilterType.INCLUDES_ALL ||
        filterType === FilterType.IS_IN_NUMERIC_RANGE);
}
/**
 * Takes in and returns a list of filters after having collapsed them to
 * as compact of a representation as possible.
 *
 * Currently, the only collapsible filters is the equals and includes all
 * filter.  Multiple equals or single value includes any filters can be represented as a single includes all
 * filter, so we'll collapse them all together in that case.
 *
 * @param filters
 */
function collapseFilters(filters) {
    return filters.reduce((carry, target) => {
        const existingFilter = carry.find((f) => f.fieldId === target.fieldId && isCollapsibleFilter(f));
        if (existingFilter && isCollapsibleFilter(target)) {
            existingFilter.filterType =
                existingFilter.filterType === FilterType.IS_IN_NUMERIC_RANGE
                    ? FilterType.IS_IN_NUMERIC_RANGE
                    : FilterType.INCLUDES_ALL;
            const arrayValue = Array.isArray(target.filterValue)
                ? target.filterValue
                : [target.filterValue];
            if (!Array.isArray(existingFilter.filterValue)) {
                existingFilter.filterValue = [existingFilter.filterValue, ...arrayValue];
            }
            else {
                existingFilter.filterValue.push(...arrayValue);
            }
        }
        else {
            carry.push(target);
        }
        return carry;
    }, []);
}
const PATTERN_WHITESPACE = /[\t\v\f\ufeff\p{Zs}]+/uy; // Any whitespace
const PATTERN_QUOTED_STRING = /(['"])((?:(?!\1)[^\\]|\\\1)*)(\1)?/y; // A single- or double-quoted string
const PATTERN_FACET_INDICATOR = /:/y; // The token to identify that a facet has been started
const PATTERN_FACET_NEXT_VALUE_INDICATOR = /,/y; // The token to identify that we're starting a new value in a facet
/**
 * Parse out tokens from the query string as an iterable.
 *
 * This supports query strings that have:
 * - search terms
 * - quoted search terms
 * - facets with single values
 * - facets with multiple values
 * - facets with quoted values
 * - facets with mixes of quoted and unquoted values
 *
 * While multiple instances of the iterables MIGHT operate alongside one another,
 * more testing and hardening should be done as this was designed for a single
 * iteration through to happen - so don't call parseTokens() against two different
 * queries at the exact same time!
 */
function* parseTokens(query) {
    let lastIndex = 0;
    let openFacet = undefined;
    let buffer = '';
    while (lastIndex < query.length) {
        PATTERN_WHITESPACE.lastIndex = lastIndex;
        PATTERN_QUOTED_STRING.lastIndex = lastIndex;
        PATTERN_FACET_INDICATOR.lastIndex = lastIndex;
        PATTERN_FACET_NEXT_VALUE_INDICATOR.lastIndex = lastIndex;
        if (PATTERN_WHITESPACE.exec(query)) {
            lastIndex = PATTERN_WHITESPACE.lastIndex;
            if (buffer) {
                // If we're seeing whitespace but still have data in the
                // buffer, we can safely emit this as a keyword
                yield ['KEYWORD', buffer];
            }
            if (openFacet) {
                // If we're seeing whitespace and have an open facet, we're done
                // and this facet is now considered closed
                yield ['END_FACET', openFacet];
                openFacet = undefined;
            }
            buffer = '';
            continue;
        }
        const quotedStringMatch = PATTERN_QUOTED_STRING.exec(query);
        if (quotedStringMatch && !buffer) {
            lastIndex = PATTERN_QUOTED_STRING.lastIndex;
            const quoteType = quotedStringMatch[1];
            buffer += quotedStringMatch[2].replace(new RegExp('\\\\(\'|")', 'g'), quoteType);
            yield ['KEYWORD', buffer];
            buffer = '';
            continue;
        }
        const facetIndicatorMatch = PATTERN_FACET_INDICATOR.exec(query);
        if (facetIndicatorMatch && buffer) {
            lastIndex = PATTERN_FACET_INDICATOR.lastIndex;
            openFacet = buffer;
            yield ['START_FACET', openFacet];
            buffer = '';
            continue;
        }
        const facetNextValueIndicator = PATTERN_FACET_NEXT_VALUE_INDICATOR.exec(query);
        if (facetNextValueIndicator && openFacet) {
            lastIndex = PATTERN_FACET_NEXT_VALUE_INDICATOR.lastIndex;
            if (buffer) {
                // If we're seeing a "next value" indicator - in other words, a comma -
                // but we still have data in the buffer it's a great time to emit a new keyword!
                //
                // This won't be a quoted string, just some raw data in the buffer
                yield ['KEYWORD', buffer];
            }
            buffer = '';
            continue;
        }
        buffer += query[lastIndex];
        lastIndex++;
    }
    if (buffer) {
        // At the end of parsing if we still have data in the buffer we gotta flush it
        yield ['KEYWORD', buffer];
    }
    if (openFacet) {
        // At the end of parsing if we still have a facet open we gotta flush it
        yield ['END_FACET', openFacet];
    }
}
/**
 * Parses a numeric range from an input string or returns undefiend if it's not a numeric range.
 *
 * Must be in the format of either `>1234`, `<1234`, or `0..1234`.
 *
 * They are all normalized to a list that contains exactly 2 numbers - with the first being
 * the lowest and the second being the highest.  In the case of less than or greater than
 * we set the other side to an infinity.
 *
 * Currently only supports integers
 *
 * @param value
 */
function parseNumericRange(value) {
    const match = value.match(/^(?:(?<operator>[<>])(?<value>-?\d+)|(?<low>-?\d+)\.\.(?<high>-?\d+))$/);
    if (!(match === null || match === void 0 ? void 0 : match.groups)) {
        // This wasn't a numeric range.  Pretty normal as we pass nearly anything in
        // and not "exceptional" so an undefined makes sense.
        return undefined;
    }
    if (match.groups.operator) {
        return [
            [
                match.groups.operator === '>'
                    ? parseInt(match.groups.value)
                    : -Infinity,
                match.groups.operator === '<' ? parseInt(match.groups.value) : Infinity,
            ],
        ];
    }
    return [[parseInt(match.groups.low), parseInt(match.groups.high)]];
}
function deserializeQuery(query) {
    const tokens = parseTokens(query);
    const keywords = [];
    const filters = [];
    const values = [];
    // We can currently get 3 types of tokens
    // - KEYWORD - either a search term itself or a value in a facet
    // - START_FACET - when we see a new facet
    // - END_FACET - when a facet ends
    //
    // Keywords between the start and end of a facet just means "multiple"
    // and should be considered as an "or" logic
    for (const [tokenType, tokenValue] of tokens) {
        if (tokenType === 'KEYWORD') {
            values.push(tokenValue);
        }
        else if (tokenType === 'START_FACET') {
            // If we're starting a new facet we can assume that we're
            // not ending a facet - an end facet should be explicit - so
            // push all the values as keywords
            keywords.push(...values);
            values.length = 0;
        }
        else if (tokenType === 'END_FACET') {
            if (values.length === 0) {
                // If there were no facet values, don't do anything
            }
            else if (values.length === 1) {
                const value = values[0];
                // We might want to keep some sort of flag in the case of the
                // string having been wrapped in quotes?  Because ">4" probably
                // should be interpreted as the literal characters `>4` rather than
                // greater-than four.
                //
                // For now, I've not done that here as it's a bit of an edge case and
                // this is complex enough as it is.
                const numericRange = parseNumericRange(value);
                // Don't forget to copy the `values` array throughout here because we keep using the
                // same array reference for everything throughout
                if (numericRange) {
                    filters.push({
                        fieldId: tokenValue,
                        filterType: FilterType.IS_IN_NUMERIC_RANGE,
                        filterValue: numericRange,
                    });
                }
                else if (tokenValue.startsWith('-')) {
                    filters.push({
                        fieldId: tokenValue.slice(1),
                        filterType: FilterType.INCLUDES_NONE,
                        filterValue: [...values],
                    });
                }
                else {
                    filters.push({
                        fieldId: tokenValue,
                        filterType: FilterType.INCLUDES_ANY,
                        filterValue: [...values],
                    });
                }
            }
            else if (tokenValue.startsWith('-')) {
                filters.push({
                    fieldId: tokenValue.slice(1),
                    filterType: FilterType.INCLUDES_NONE,
                    filterValue: [...values],
                });
            }
            else {
                filters.push({
                    fieldId: tokenValue,
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: [...values],
                });
            }
            values.length = 0;
        }
    }
    // Push leftover values as keywords if there are any
    keywords.push(...values);
    const serializableQuery = {};
    if (keywords.length > 0) {
        // We normalize the search keywords spacing to just one space - even if there are multiple
        serializableQuery.search = keywords.join(' ');
    }
    if (filters.length > 0) {
        serializableQuery.filters = collapseFilters(filters);
    }
    return serializableQuery;
}
export { serializeQuery, deserializeQuery };
