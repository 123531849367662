// This can also be implemented using a css wrapper:  https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
const truncate = (str, len) => {
    return str.length > len ? str.substr(0, len - 1) + '...' : str;
};
/** Converts a string to start case, where each word has the first letter capitalized. */
const startCase = (str) => str
    .split(/\W+/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
export { truncate, startCase };
