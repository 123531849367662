import React from 'react';
import { Block, Heading2, Stack, Card, TypographyVariant, } from '@humanfirst/elektron';
import { GroupedFakeBlocks } from 'src/components/FakeBlock';
import { LockedCard } from 'src/components/LockedCard';
import { ScopedEvidenceTable } from 'src/components/ScopedEvidenceTable';
const LockedEvidenceTable = ({ title = 'Product Evidence', lockedCta = 'Tell us more to unlock evidence for this product including peer-reviewed papers, FDA decisions, and more.', }) => {
    return (React.createElement(Block, { position: 'relative', overrides: {
            Block: {
                props: {
                    'aria-hidden': true,
                },
                style: {
                    userSelect: 'none',
                },
            },
        } },
        React.createElement(Stack, { position: "absolute", top: "30%", width: '30%', right: '35%' },
            React.createElement(Block, { width: '400px' },
                React.createElement(LockedCard, { title: `Access ${title}`, body: lockedCta }))),
        React.createElement(Card, { padding: '48px' },
            React.createElement(Stack, { gap: "24px" },
                React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold, marginBottom: "32px" }, title),
                React.createElement(GroupedFakeBlocks, null)))));
};
const LockableEvidenceTable = ({ isLocked, title, lockedCta, scope, }) => {
    if (isLocked) {
        return React.createElement(LockedEvidenceTable, { lockedCta: lockedCta, title: title });
    }
    return React.createElement(ScopedEvidenceTable, { scope: scope });
};
export { LockableEvidenceTable };
