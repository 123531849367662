import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { Block, Button, FileUploader, Icons, SemanticButton, Spread, Stack, Typography, toaster, } from '@humanfirst/elektron';
import { useUploadFile } from 'src/hooks/upload/upload-file';
export const WorkspaceFileUploader = ({ workspaceId, onUpload }) => {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { mutate } = useUploadFile(workspaceId);
    const doUpload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const successFiles = [];
        const errorFiles = [];
        for (const file of files) {
            try {
                yield mutate(file);
                successFiles.push(file);
            }
            catch (e) {
                toaster.negative(`Failed to upload ${file.name}: ${String(e)}`);
                errorFiles.push(file);
            }
        }
        setIsLoading(false);
        onUpload === null || onUpload === void 0 ? void 0 : onUpload(successFiles, errorFiles);
    }), [setIsLoading, mutate, files, onUpload]);
    return (React.createElement(Stack, null,
        React.createElement(FileUploader, { onDrop: (accepted) => setFiles((existing) => [...existing, ...accepted]) }),
        files.map((x) => (React.createElement(Spread, { key: x.name, direction: "horizontal", alignItems: 'baseline' },
            React.createElement(Typography, null, x.name),
            React.createElement(SemanticButton, { onClick: () => {
                    setFiles((y) => y.filter((z) => z !== x));
                } },
                React.createElement(Icons.Misc.Close, { title: "Remove" }))))),
        React.createElement(Block, { display: "flex", flexDirection: "row-reverse" },
            React.createElement(Button, { disabled: files.length === 0, isLoading: isLoading, size: "large", onClick: () => doUpload() }, "Upload"))));
};
