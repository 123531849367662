import { __rest } from "tslib";
import { useElektraApi } from '@humanfirst/use-elektra-api';
import { uniqBy } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { SmartSelect } from './fields';
function SmartApiModelSelect(_a) {
    var { definition, labelKey, labelGetter, optionGetter } = _a, rest = __rest(_a, ["definition", "labelKey", "labelGetter", "optionGetter"]);
    const { data, isLoading } = useElektraApi(definition);
    const defaultOptionGetter = useCallback((x) => ({
        id: x.id,
        label: labelKey ? x[labelKey] : labelGetter === null || labelGetter === void 0 ? void 0 : labelGetter(x),
    }), [labelGetter, labelKey]);
    const options = useMemo(() => {
        var _a;
        return uniqBy((x) => x.id, (_a = data === null || data === void 0 ? void 0 : data.flatMap(optionGetter !== null && optionGetter !== void 0 ? optionGetter : defaultOptionGetter)) !== null && _a !== void 0 ? _a : []);
    }, [data, defaultOptionGetter, optionGetter]);
    return React.createElement(SmartSelect, Object.assign({ isLoading: isLoading, options: options }, rest));
}
export { SmartApiModelSelect };
