import { Block, ButtonLink, COLORS, Heading, Icons, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { ToolTable } from 'src/components/ToolTable';
import { NEW_PRODUCT_FORM } from 'src/constants/airtableForms';
import { useUser } from 'src/hooks/user';
/** Renders all of the products for a specific org. */
const MakerProductsForOrg = ({ id }) => {
    const result = useElektraApi(ToolResource.list({ filter: [['maker', '$eq', id]], join: ['maker'] }));
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: 'My Products' }),
        React.createElement(Block, { padding: "56px" },
            React.createElement(Stack, { direction: "horizontal", width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "16px" },
                React.createElement(Heading, null, "My Products"),
                React.createElement(ButtonLink, { href: NEW_PRODUCT_FORM, size: "large" }, "New Product")),
            React.createElement(QueryWrapper, Object.assign({ renderSuccess: ToolTable }, result)))));
};
const UnassociatedMaker = ({ name }) => (React.createElement(React.Fragment, null,
    React.createElement(PageTitle, { title: 'My Products' }),
    React.createElement(Block, { padding: '56px', maxWidth: '992px' },
        React.createElement(Heading, null,
            "Welcome to Atlas",
            name ? `, ${name}` : '',
            "!"),
        React.createElement(Block, { maxWidth: '480px' },
            React.createElement(Typography, { variant: TypographyVariant.Body }, "HumanFirst is reviewing your profile. If your company has products included in Atlas, we will link your account to the organization so you can view or request updates to product-level information.")),
        React.createElement(Spacer, { size: '56px' }),
        React.createElement(Stack, { direction: 'horizontal', border: `1px solid ${COLORS.gray3}`, borderRadius: '8px', height: '200px', alignItems: 'center', justifyContent: 'center' },
            React.createElement(Icons.Navigation.Products, { size: '2em', decorative: true }),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.BodyBold }, "This is where you\u2019ll manage your products")))));
/** Renders the products for the current user if they are a maker. */
const MakerProducts = () => {
    const user = useUser();
    if (user === null || user === void 0 ? void 0 : user.organizationId) {
        return React.createElement(MakerProductsForOrg, { id: user === null || user === void 0 ? void 0 : user.organizationId });
    }
    else {
        return React.createElement(UnassociatedMaker, { name: user === null || user === void 0 ? void 0 : user.name });
    }
};
export { MakerProducts };
