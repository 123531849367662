import { useElektraApi, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import { PATHS } from 'src/config/path';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { generatePath } from 'src/utils/path';
import { serializeQuery } from 'src/utils/query';
const RESOURCE_TYPE_SEARCH_PATHS = {
    ToolSearch: PATHS.atlas.technologies.list,
    MeasureSearch: PATHS.atlas.measures.list,
    VendorSearch: PATHS.atlas.vendors.list,
    EvidenceSearch: PATHS.atlas.evidence.list,
};
const getSearchPath = (resourceType, projectId) => {
    const searchPath = RESOURCE_TYPE_SEARCH_PATHS[resourceType];
    if (!searchPath) {
        return null;
    }
    const isProjectSearchRoute = resourceType === 'EvidenceSearch' || resourceType === 'ToolSearch';
    //saved evidence and tech searches will be resumed within the project space
    if (isProjectSearchRoute && projectId) {
        return (generatePath(PATHS.projects.detail, { projectId: projectId }) +
            `?searchView=${resourceType === 'EvidenceSearch' ? 1 : 0}&`);
    }
    return generatePath(searchPath) + '?';
};
export const getResourceDisplay = (item) => {
    if (item.resourceType === 'ToolSearch') {
        return 'Technology';
    }
    else {
        return item.resourceType.replace(/search/i, '');
    }
};
export const useSearchName = (item) => {
    var _a, _b;
    const workspaceId = useActiveWorkspaceId();
    const { data: names } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        resource: serializeQuery(item.query),
        resourceType: item.resourceType,
        metadataType: 'alternativeTitle',
    }));
    return (_b = (_a = names === null || names === void 0 ? void 0 : names[0]) === null || _a === void 0 ? void 0 : _a.metadataValue) !== null && _b !== void 0 ? _b : null;
};
export const getSearchUrl = (item, projectId) => {
    const queryParams = new URLSearchParams();
    if (item.query.search) {
        queryParams.set('query', item.query.search);
    }
    if (item.query.filters) {
        for (const filter of item.query.filters) {
            queryParams.append('filters', `${filter.fieldId}||${filter.filterType}||${JSON.stringify(filter.filterValue)}`);
        }
    }
    const searchPath = getSearchPath(item.resourceType, projectId);
    if (!searchPath) {
        return null;
    }
    return searchPath + queryParams.toString();
};
