import { __awaiter } from "tslib";
import { useElektraApiMutation, ToolEditRequestResource, } from '@humanfirst/use-elektra-api';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { Button, COLORS, Card, Spacer, Spread, Stack, Typography, TypographyVariant, toaster, } from '@humanfirst/elektron';
import { SmartForm } from 'src/components/SmartForm';
import { CelebrationContext } from 'src/contexts/CelebrationContext';
import { t } from 'src/constants/i18n';
const SubmitEdits = () => {
    return (React.createElement(Card, null,
        React.createElement(Spread, { alignItems: 'start' },
            React.createElement(Stack, null,
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Flagging Changes"),
                React.createElement(Typography, { color: COLORS.gray1 }, "To submit changes to a section, click the flag icon and leave a comment for review. Once you've made all changes, submit them using the button to the right.")),
            React.createElement(Button, { size: "large", type: "submit" }, "Submit changes"))));
};
/** A context for handling the whether makers are in edit mode on the tool page. */
const MakerEditingContext = createContext({
    isEditing: false,
    setIsEditing: () => undefined,
});
const MakerEditingContextProvider = ({ tool, children }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { celebrate } = useContext(CelebrationContext);
    const { mutateAsync: createToolEditRequest } = useElektraApiMutation(ToolEditRequestResource.create(tool.id));
    const onSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        const dataArray = Object.entries(data);
        const filteredData = dataArray.filter((keyValue) => keyValue[1] !== '');
        const submittedData = Object.fromEntries(filteredData);
        if (Object.keys(submittedData).length > 0) {
            celebrate();
            yield createToolEditRequest(submittedData);
            toaster.positive(t('tools.editSubmitSuccess'));
        }
        setIsEditing(false);
    }), [celebrate, createToolEditRequest]);
    return (React.createElement(MakerEditingContext.Provider, { value: { isEditing, setIsEditing } }, isEditing ? (React.createElement(SmartForm
    // Submit is handled by the submit edits which comes at the top (instead of the bottom).
    , { 
        // Submit is handled by the submit edits which comes at the top (instead of the bottom).
        submitComponent: () => null, submitText: "Submit Changes", onSubmit: onSubmit },
        React.createElement(SubmitEdits, null),
        React.createElement(Spacer, { size: "32px" }),
        children)) : (children)));
};
export { MakerEditingContext, MakerEditingContextProvider };
