import { MeasureCategoryResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { LoadingState } from 'src/components/LoadingState';
import { NotFound } from 'src/pages/NotFound';
const MeasuresByName = () => {
    const { name } = useParams();
    const { data } = useElektraApi(MeasureCategoryResource.list({
        filter: [['category', '$eq', name]],
    }), { enabled: !!name });
    const navigate = useNavigate();
    useEffect(() => {
        if (!!data && (data === null || data === void 0 ? void 0 : data.length) > 0) {
            navigate(generatePath(PATHS.atlas.measures.detail, { id: data[0].id }), {
                replace: true,
            });
        }
    }, [data, navigate]);
    if (!!data && (data === null || data === void 0 ? void 0 : data.length) < 1) {
        return React.createElement(NotFound, null);
    }
    return React.createElement(LoadingState, null);
};
export { MeasuresByName };
