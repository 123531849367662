import React, { useCallback } from 'react';
import { Heading, Spacer, TabPanel, TabList, Tab } from '@humanfirst/elektron';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from 'src/components/ContentContainer';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { PageTitle } from '../../components/PageTitle';
import { BREADCRUMBS } from '../../config/breadcrumbs';
import { Studies } from './Studies';
import { Technologies } from './Technologies';
const Catalog = ({ view = 0 }) => {
    const navigate = useNavigate();
    const breadcrumbForView = view === 0 ? BREADCRUMBS.Studies : BREADCRUMBS.Instruments;
    const changeTab = useCallback((index) => {
        const path = index === 0
            ? PATHS.catalog.studies.index
            : PATHS.catalog.technologies.index;
        navigate(generatePath(path));
    }, [navigate]);
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Connect, breadcrumbForView] },
        React.createElement(PageTitle, { title: "My Organization's Data" }),
        React.createElement(Heading, null, "My Organization's Data"),
        React.createElement(TabList, { variant: "toggle", active: view, onChange: changeTab },
            React.createElement(Tab, null, "Studies"),
            React.createElement(Tab, null, "Technologies")),
        React.createElement(Spacer, { size: "24px" }),
        React.createElement(TabPanel, { index: 0, active: view },
            React.createElement(Studies, null)),
        React.createElement(TabPanel, { index: 1, active: view },
            React.createElement(Technologies, null)),
        React.createElement(Spacer, { size: "24px" })));
};
export { Catalog };
