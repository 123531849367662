import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { PATHS } from 'src/config/path';
import { RECENT_ACTIVITY } from 'src/constants/persistentKeys';
import { usePersistentState } from '../persistence';
/**
 * Translation from app paths to events.
 */
const PATH_TO_TYPE_MAP = {
    [PATHS.atlas.technologies.detail]: 'technology-page',
    [PATHS.atlas.measures.detail]: 'measure-page',
    [PATHS.atlas.medicalConditions.detail]: 'medical-condition-page',
};
/**
 * Records an event.
 *
 * This is optimized for keeping a small list of the most recent pages. To
 * support this, we deduplicate based on ID and only keep a maxmium of 5 items.
 */
const recordEvent = (event, history) => {
    const newHistory = history
        ? Object.assign({}, history) : {};
    const events = newHistory[event.type] || [];
    // Remove any events with the same ID as our new event (so only the newest
    // event is recorded).
    const filteredEvents = events.filter((x) => x.id !== event.id);
    newHistory[event.type] = [event, ...filteredEvents].slice(0, 5);
    return newHistory;
};
/** Call this hook at the root to track activity as users change pages. */
export const useTrackActivityPageviews = () => {
    const [, setActivityHistory] = usePersistentState(RECENT_ACTIVITY);
    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        for (const path of Object.keys(PATH_TO_TYPE_MAP)) {
            const match = matchPath(path, pathname);
            if (match) {
                setActivityHistory((history) => recordEvent({
                    type: PATH_TO_TYPE_MAP[path],
                    time: new Date().toISOString(),
                    id: PATH_TO_TYPE_MAP[path] + match.params.id,
                    details: { id: match.params.id },
                }, history));
            }
        }
    }, [pathname, setActivityHistory]);
};
/** Returns the latest activity history. */
export const useActivityHistory = () => {
    const [activityHistory] = usePersistentState(RECENT_ACTIVITY);
    return activityHistory;
};
