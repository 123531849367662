import { Block, Stack, Spacer } from '@humanfirst/elektron';
import { EvidenceResource } from '@humanfirst/use-elektra-api';
import React from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router';
import ImageMCExample from 'src/assets/mc_example.png';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { CatalogResourceDetails, CatalogResourceError, } from 'src/components/CatalogResourceDetails';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PartiallyIngestedEvidenceCard } from './components/PartiallyIngestedEvidenceCard';
import { EvidenceTitleCard } from './components/EvidenceTitleCard';
import { GeneralEvidenceProperties } from './components/GeneralEvidenceProperties';
import { ClinicalTrialProperties } from './components/ClinicalTrialProperties';
import { MedicalConditionsCard } from './components/MedicalConditionCard';
import { MeasuresByTechCard } from './components/GroupedMeasuresByTech';
import { AbstractCard } from './components/AbstractCard';
const EvidenceDetail = ({ data: evidence, }) => {
    var _a, _b;
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: evidence.title }),
        React.createElement(ContentContainer, { backButton: false, breadcrumbs: [
                BREADCRUMBS.Atlas,
                BREADCRUMBS.Evidence,
                BREADCRUMBS.EvidenceDetails({
                    id: evidence.id,
                    name: evidence.title,
                }),
            ] },
            React.createElement(When, { condition: evidence.ingestionStatus !== 'Display on Website' },
                React.createElement(PartiallyIngestedEvidenceCard, { evidence: evidence }),
                React.createElement(Spacer, { size: "16px" })),
            React.createElement(EvidenceTitleCard, { evidence: evidence }),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Stack, { direction: "horizontal", gap: "16px" },
                React.createElement(Stack, { flex: '1' },
                    React.createElement(GeneralEvidenceProperties, { evidence: evidence }),
                    React.createElement(When, { condition: (_a = evidence.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('ClinicalTrials.gov') },
                        React.createElement(ClinicalTrialProperties, { evidence: evidence })),
                    React.createElement(When, { condition: evidence.abstract },
                        React.createElement(AbstractCard, { evidence: evidence }))),
                React.createElement(Stack, { flex: '1' },
                    React.createElement(MedicalConditionsCard, { medicalConditions: (_b = evidence.medicalConditions) !== null && _b !== void 0 ? _b : [] }),
                    React.createElement(MeasuresByTechCard, { evidence: evidence }))))));
};
const EvidenceUpgradePage = ({ error }) => (React.createElement(CatalogResourceError, { error: error, imageUrl: ImageMCExample, resourceName: 'evidence' }));
const EvidencePage = () => {
    const { id } = useParams();
    return (React.createElement(CatalogResourceDetails, { resource: EvidenceResource.get(id, {
            join: ['sourceMeasures', 'sourceMeasures.tools', 'medicalConditions'],
        }), errorComponent: EvidenceUpgradePage, resourceDetails: EvidenceDetail, permissionResourceName: 'evidenceSource' }));
};
export { EvidencePage as Evidence, EvidenceDetail };
