import { Block, Breakpoint, Button, Center, COLORS, DisplayBreakpoint, FillContainer, Icons, Link, Logo, NavigationBar, SemanticButton, Spacer, Stack, } from '@humanfirst/elektron';
import React from 'react';
import { Omnisearch } from 'src/components/OmniSearch';
import { useUser } from 'src/hooks/user';
import { IfToggle } from '../IfToggle';
import { TogglePanel } from '../TogglePanel';
import { HelpMenu } from './components/HelpMenu';
import { NavigationPopover } from './components/NavigationPopover';
import { Notifications } from './components/Notifications';
import { UserMenu } from './components/UserMenu';
import { UserSummary } from './components/UserSummary';
/**
 * Renders the navigation controls:
 * 1) Hamburger menu below the S breakpoint.
 * 2) Logo at all breakpoints.
 */
const NavigationControls = ({ setIsOpen, isOpen }) => {
    return (React.createElement(Stack, { gap: "24px", direction: "horizontal", alignItems: 'center' },
        React.createElement(DisplayBreakpoint, { max: Breakpoint.S },
            React.createElement(SemanticButton, { onClick: () => setIsOpen((prev) => !prev) }, isOpen ? (React.createElement(Icons.Misc.Close, { size: "24px", title: "Close" })) : (React.createElement(Icons.Misc.NavigationMenu, { size: "24px", title: "Expand Menu" })))),
        React.createElement(Center, null,
            React.createElement(Link, { href: "/" },
                React.createElement(Logo, { height: "16px" })))));
};
/**
 * Renders the main search bar for the top navigation.
 *
 * Note: This has a flex=1 so it takes most of the space in the navigation.
 * @returns
 */
const SearchBar = () => {
    return (React.createElement(Block, { width: "auto", flex: "1", justifyContent: "center", padding: "0px 16px" },
        React.createElement(DisplayBreakpoint, { min: Breakpoint.M },
            React.createElement(Omnisearch, null))));
};
/**
 * Actions that can be taken directly from the top navigation.
 *
 * These are not the primary site navigation (those live in the side navigation),
 * but view notifications, edit profile, etc.
 */
const Actions = () => {
    return (React.createElement(Stack, { direction: "horizontal", gap: "24px", alignItems: 'center' },
        React.createElement(IfToggle, { name: "show-toggles" },
            React.createElement(NavigationPopover, { content: () => (React.createElement(Block, { maxHeight: "80vh", overflow: 'auto', maxWidth: "360px" },
                    React.createElement(TogglePanel, null))) },
                React.createElement(SemanticButton, null,
                    React.createElement(Icons.Product.Api, { size: "1.5em", title: "Edit Toggles" })))),
        React.createElement(NavigationPopover, { content: ({ close }) => React.createElement(HelpMenu, { close: close }) },
            React.createElement(Button, { variant: "minimal", startEnhancer: React.createElement(Icons.Misc.Chat, { color2: COLORS.primary, size: "1.5em", decorative: true }) }, "Contact us")),
        React.createElement(Notifications, null)));
};
/**
 * Top navigation for the Atlas Web app.
 *
 * Includes three main components:
 *  - Logo + side navigation controls (side navigation controls only used on smaller screens)
 *  - Omnisearch search bar
 *  - Other Actions
 */
const TopNavigationBar = ({ setIsOpen, isOpen }) => {
    const user = useUser();
    return (React.createElement(NavigationBar, null,
        React.createElement(FillContainer, { display: "flex", fillWidth: "parent", justifyContent: 'space-between', gridGap: "8px" },
            React.createElement(NavigationControls, { setIsOpen: setIsOpen, isOpen: isOpen }),
            React.createElement(Block, { display: "flex", flex: "1" },
                React.createElement(SearchBar, null),
                React.createElement(Actions, null)),
            React.createElement(Spacer, { size: "16px" }),
            React.createElement(NavigationPopover, { content: ({ close }) => React.createElement(UserMenu, { close: close }) },
                React.createElement(SemanticButton, { "data-testid": "user-semantic-button" },
                    React.createElement(Block, { padding: "4px 8px", borderRadius: "8px", border: `1px solid ${COLORS.primary}` },
                        React.createElement(UserSummary, { user: user })))))));
};
export { TopNavigationBar };
