import React, { createElement } from 'react';
import { Button } from '@humanfirst/elektron';
import { useFormContext } from 'react-hook-form';
function SmartFormSubmitButton({ submitComponent, submitText, }) {
    const { formState: { isValid, isSubmitting }, } = useFormContext();
    if (submitComponent) {
        return createElement(submitComponent, {
            isLoading: isSubmitting,
            isValid: isValid,
            submitText: submitText !== null && submitText !== void 0 ? submitText : 'Submit',
        });
    }
    return (React.createElement(Button, { type: "submit", isLoading: isSubmitting, disabled: !isValid, size: "full" }, submitText !== null && submitText !== void 0 ? submitText : 'Submit'));
}
export { SmartFormSubmitButton };
