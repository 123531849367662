import { Block, Button, ButtonLink, CardSurface, COLORS, Heading, Icons, Modal, Spread, Stack, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
import { When } from 'react-if';
import { AtlasMarkdown } from 'src/components/AtlasMarkdown';
import { Can } from 'src/components/Permissions';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { useUser } from 'src/hooks/user';
import { ActivityLog } from '../ActivityLog';
import { ShareButton } from './components/ShareButton';
import { ProjectResearchCriteria } from './components/ProjectResearchCriteria';
const ActivityLogModalButton = ({ workspace }) => {
    const [isOpen, setIsOpen] = useState(false);
    const showModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
            React.createElement(ActivityLog, { workspace: workspace })),
        React.createElement(Button, { onClick: showModal, variant: 'secondary', startEnhancer: React.createElement(Icons.Misc.ActivityLog, { decorative: true }) }, "Activity")));
};
const WorkspaceDescriptionCard = ({ data: workspace, }) => {
    var _a, _b;
    const user = useUser();
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { padding: '32px', display: "flex" },
            React.createElement(Stack, { flex: "1", gap: '16px' },
                React.createElement(Spread, { alignItems: "start", gap: '24px' },
                    React.createElement(Heading, null, workspace.name),
                    React.createElement(Stack, { direction: "horizontal" },
                        React.createElement(Can, { resource: "workspace", action: "update", extra: workspace },
                            React.createElement(ButtonLink, { href: generatePath(PATHS.projects.edit, {
                                    projectId: workspace.id,
                                }), startEnhancer: React.createElement(Icons.Misc.Edit, { decorative: true, color: COLORS.white }) }, "Edit"),
                            React.createElement(ActivityLogModalButton, { workspace: workspace })),
                        React.createElement(When, { condition: !!((_a = user === null || user === void 0 ? void 0 : user.teams) === null || _a === void 0 ? void 0 : _a.length) },
                            React.createElement(ShareButton, { project: workspace })))),
                React.createElement(When, { condition: workspace.description },
                    React.createElement(AtlasMarkdown, { content: (_b = workspace.description) !== null && _b !== void 0 ? _b : '' })),
                React.createElement(ProjectResearchCriteria, { workspace: workspace })))));
};
export { WorkspaceDescriptionCard };
