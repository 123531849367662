import React from 'react';
import { Heading, Paragraph } from '@humanfirst/elektron';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { PageTitle } from 'src/components/PageTitle';
const ForgotPasswordSuccess = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Reset Password" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Heading, null, "Reset Password"),
            React.createElement(Paragraph, null, "If an account with that email exists, a password reset link has been sent."))));
};
export { ForgotPasswordSuccess };
