import { __awaiter } from "tslib";
import { parse } from 'query-string';
import { Heading, Link, Paragraph, Block, COLORS } from '@humanfirst/elektron';
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { When } from 'react-if';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { SmartForm } from 'src/components/SmartForm';
import { PageTitle } from 'src/components/PageTitle';
import { useLogin } from 'src/hooks/authentication';
const InvitationError = ({ error, defaultEmail }) => {
    const { mutateAsync } = useElektraApiMutation(AuthResource.requestActivate());
    const [isSuccess, setIsSuccess] = useState(false);
    const onSubmit = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ email }) {
        yield mutateAsync({ email, isInvite: true });
        setIsSuccess(true);
    }), [mutateAsync, setIsSuccess]);
    if (isSuccess) {
        return (React.createElement(Block, null,
            React.createElement(Paragraph, null, "We've sent you an email with a link to complete the invitation process.")));
    }
    return (React.createElement(Block, null,
        React.createElement(Heading, null, "Something went wrong"),
        React.createElement(Block, null,
            React.createElement(Paragraph, { color: COLORS.alert }, String(error)),
            React.createElement(Paragraph, null, "There is something wrong with your invitation link. Please fill out the form below and you'll receive an email to reset your password."),
            React.createElement(SmartForm, { onSubmit: onSubmit, submitText: "Send Activation Link", defaultValues: { email: defaultEmail } },
                React.createElement(SmartForm.Input, { name: "email", label: "Email", id: "email", rules: { required: true } })))));
};
const Invitation = () => {
    const location = useLocation();
    const { mutateAsync: activateUser, error } = useElektraApiMutation(AuthResource.activate());
    const login = useLogin();
    const navigate = useNavigate();
    const params = useParams();
    const queryParams = parse(location.search);
    const onSubmit = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ password }) {
        const token = queryParams.token || params.token;
        const email = queryParams.email;
        yield activateUser({ password, token });
        yield login({ email, password });
        navigate('/');
    }), [activateUser, params, queryParams, login, navigate]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Finish Creating account" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(When, { condition: !!error },
                React.createElement(InvitationError, { error: error, defaultEmail: queryParams.email })),
            React.createElement(When, { condition: !error },
                React.createElement(Heading, null, "Finish Creating Account"),
                React.createElement(Block, null,
                    React.createElement(Paragraph, null,
                        "To finish creating your account, please set a password and review our",
                        ' ',
                        React.createElement(Link, { href: "https://www.gohumanfirst.com/terms-and-conditions" }, "Terms and Conditions")),
                    React.createElement(SmartForm, { onSubmit: onSubmit },
                        React.createElement(SmartForm.Input, { label: "Password", id: "password", name: "password", type: "password", placeholder: "New Password", rules: { required: true } }),
                        React.createElement(SmartForm.Checkbox, { name: "read-terms", rules: { required: true } },
                            React.createElement(React.Fragment, null,
                                "I\u2019ve read and accepted the",
                                ' ',
                                React.createElement(Link, { href: "https://www.gohumanfirst.com/terms-and-conditions" }, "Terms and Conditions")))))))));
};
export { Invitation };
