const INDUSTRY_OPTIONS = [
    'Pharma & Biotech',
    'Contract Research Org',
    'Academic Research',
    'Clinician/Provider Group',
    'Technology Manufacturer',
    'Government & Public Health',
    'Consulting Services',
    'Insurance/Payor Group',
    'Other',
];
const FUNCTION_OPTIONS = [
    'Asset Strategy / Commercial Lead',
    'Business Development',
    'Clinical Development / Study Team',
    'Clinical Program Management',
    'Clinical Operations',
    'Data Science / Informatics',
    'Digital Health / Innovation',
    'Health Economics & Outcomes Research (HEOR)',
    'IT / IS / Engineering',
    'Medical Affairs',
    'Procurement / Vendor Management',
    'Program Management',
    'Regulatory Affairs',
    'Other',
];
export { FUNCTION_OPTIONS, INDUSTRY_OPTIONS };
