import { __awaiter } from "tslib";
import { Block, Typography, TypographyVariant, Stack, Button, toaster, Modal, } from '@humanfirst/elektron';
import { useElektraApi, useElektraApiMutation, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { When } from 'react-if';
import pLimit from 'p-limit';
import { useNavigate } from 'react-router';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { useLogger } from 'src/hooks/logger';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const REQUEST_LIMIT = 10;
const RemoveFromWorkspaceListInternal = ({ data, bookmarks, activeList, workspaceId, onClose }) => {
    const logger = useLogger('remove-from-workspace-list');
    const isLastItemInList = data.length === bookmarks.length;
    const navigate = useNavigate();
    const listItems = useMemo(() => data.filter((li) => {
        return bookmarks.find((bookmark) => li.resource === (bookmark === null || bookmark === void 0 ? void 0 : bookmark.resource) &&
            li.resourceType === (bookmark === null || bookmark === void 0 ? void 0 : bookmark.resourceType));
    }), [data, bookmarks]);
    const { mutateAsync, isLoading } = useElektraApiMutation(WorkspaceResourceMetadataResource.remove(workspaceId));
    const removeItem = () => __awaiter(void 0, void 0, void 0, function* () {
        const limiter = pLimit(REQUEST_LIMIT);
        try {
            yield Promise.all(listItems.map((listItem) => limiter(() => __awaiter(void 0, void 0, void 0, function* () { return yield mutateAsync({ id: listItem.id }); }))));
            onClose();
            if (isLastItemInList) {
                navigate(`${generatePath(PATHS.projects.detail, {
                    projectId: workspaceId,
                })}?view=1`);
            }
        }
        catch (e) {
            logger.warn(`Cannot remove items from project list: ${String(e)}`);
            toaster.negative(`These items could not be removed from this list. Please try again later.`);
        }
    });
    return (React.createElement(Stack, { gap: "24px" },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold },
            "Remove from ",
            activeList),
        React.createElement(Typography, null,
            "Are you sure you want to remove these items from ",
            React.createElement("b", null, activeList),
            "? This will not delete the items from your project, but will remove them from this list."),
        React.createElement(When, { condition: isLastItemInList },
            React.createElement(Typography, null,
                "Please note, these are the last items in ",
                activeList,
                ". Removing them will delete the list.")),
        React.createElement(Block, { display: "flex", justifyContent: 'flex-end' },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Button, { size: "large", variant: "tertiary", onClick: onClose }, "Cancel"),
                React.createElement(Button, { size: "large", variant: "primary", isLoading: isLoading, onClick: removeItem }, "Remove from List")))));
};
const RemoveFromWorkspaceListModal = ({ isOpen, onClose, listName, bookmarks, workspaceId }) => {
    const listsResult = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'tag',
        metadataValue: listName,
    }));
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(QueryWrapper, Object.assign({}, listsResult, { renderSuccess: RemoveFromWorkspaceListInternal, activeList: listName, workspaceId: workspaceId, bookmarks: bookmarks, onClose: onClose }))));
};
export { RemoveFromWorkspaceListModal };
