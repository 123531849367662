import { Block, Button, Heading, Modal, Stack } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { SmartForm } from 'src/components/SmartForm';
import { SmartInput } from 'src/components/SmartForm/fields';
import { serialize } from './serialization';
const TeamSSOForm = ({ onSubmit, isOpen, closeModal, exposeOrganizationId }) => {
    return (React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
        React.createElement(Stack, null,
            React.createElement(Heading, null, "Create Team SSO Domain Link"),
            React.createElement(SmartForm, { onSubmit: (data) => onSubmit(serialize(data)), submitText: "Save", submitComponent: ({ isLoading, isValid }) => (React.createElement(Block, { display: "flex", flexDirection: "row-reverse" },
                    React.createElement(Button, { type: "submit", isLoading: isLoading, disabled: !isValid, size: "large" }, "Save"))) },
                React.createElement(When, { condition: exposeOrganizationId },
                    React.createElement(SmartInput, { label: "Organization ID", name: "organizationId", id: "organizationId" })),
                React.createElement(SmartInput, { label: "Domain", name: "domain", id: "domain" })))));
};
export { TeamSSOForm };
