import React, { useContext } from 'react';
import { Typography, TypographyVariant, Icons, StatefulTooltip, Stack, COLORS, } from '@humanfirst/elektron';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const useGetConnectSourcesForDisplay = (tool) => {
    var _a, _b, _c, _d;
    const { userStudies, userTeamMetadata } = useContext(ConnectContext);
    const isLoading = userStudies.isLoading || userTeamMetadata.isLoading;
    const hasConnectStudiesAndDHTs = useHasPermission('connect', 'read', 'all');
    const techTeamMetadata = (_b = (_a = userTeamMetadata.data) === null || _a === void 0 ? void 0 : _a.filter((t) => t.resourceType === 'Tool' && t.resource === tool.id)) !== null && _b !== void 0 ? _b : [];
    const techStudies = (_d = (_c = userStudies.data) === null || _c === void 0 ? void 0 : _c.filter((s) => s.parsedMetadata.technologies.includes(tool.id))) !== null && _d !== void 0 ? _d : [];
    const allSourcesToDisplay = [];
    if (hasConnectStudiesAndDHTs) {
        allSourcesToDisplay.push(...(techStudies !== null && techStudies !== void 0 ? techStudies : []));
        allSourcesToDisplay.push(...(techTeamMetadata !== null && techTeamMetadata !== void 0 ? techTeamMetadata : []));
    }
    return { isLoading, data: allSourcesToDisplay };
};
export const ConnectHitIndicator = ({ tool }) => {
    const hasConnectStudiesAndDHTs = useHasPermission('connect', 'read', 'all');
    const { isLoading, data } = useGetConnectSourcesForDisplay(tool);
    const { isViewingFromConnectContext } = useContext(ConnectContext);
    if (isViewingFromConnectContext ||
        !hasConnectStudiesAndDHTs ||
        isLoading ||
        (!isLoading && !data.length)) {
        return null;
    }
    return (React.createElement(StatefulTooltip, { placement: "right", triggerType: "hover", content: React.createElement(Stack, null,
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Icons.Misc.Connect, { decorative: true, size: "24px", color: COLORS.primary }),
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "My Organization's Data")),
            React.createElement(Typography, null, "Your organization has data about this item.")) },
        React.createElement(Icons.Misc.Connect, { title: 'Connect', decorative: true, color: COLORS.primary })));
};
