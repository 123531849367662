import dayjs from 'dayjs';
import { groupBy } from 'ramda';
/**
 * Parses additionalMetadata to get team info.
 * Parsing assumptions:
 *  1. Data attributes are split by a blank newline ('\n\n')
 *  2. Titles are always the first line.
 *  3. Everything after the title is the value of the attribute
 *
 * Be careful about this. It's pretty error prone...
 */
export const deserializeWorkspaceMetadata = (raw) => {
    if (!raw) {
        return [];
    }
    return raw.split('\n\n').map((x) => {
        const [title, ...value] = x.split('\n');
        return { title, value: value.join('\n') };
    });
};
/**
 * Converts a field metadata to serialized metadata.
 * Strings are encoded directly, dates are serilaized to YYYY-MM-DD, and arrays are mapped
 * to comma seperated strings.
 * @param title
 * @param value
 * @returns
 */
export const fieldToMetadata = (title, value) => {
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        return { title, value };
    }
    if (value instanceof Date) {
        return { title, value: dayjs(value).format('YYYY-MM-DD') };
    }
    if (value.length === 0) {
        return null;
    }
    return { title, value: value.map((x) => x.id).join(', ') };
};
/** Converts a workspace metadata array into a string for saving to the API. */
export const serializeWorkspaceMetadata = (metadata) => {
    return metadata
        .reduce((acc, { title, value }) => `${acc}\n\n${title}\n${value}`, '')
        .trim();
};
const TOOL_METADATA_TITLES = [
    'Performance of the Vendor in the Study',
    'Services Provided by Vendor (Logistics, Data Analytics, Project Management, etc.)',
    'Digital Services Vendor Name',
];
const SUMMARY = new Set([
    'Brief Description',
    'Detailed Description',
    'Findings Summary',
    'Additional Commentary',
]);
const STUDY_DETAILS = new Set([
    'Org Study ID',
    'Registration ID',
    'Study Design',
    'Phase',
    'Intervention',
    'Overall Status',
    'Status Reason',
    'Start Date',
    'Completion Date',
    'Study Contact(s)',
    'Inclusion Criteria',
    'Exclusion Criteria',
]);
const POPULATION_DETAILS = new Set([
    'Sample Size',
    'Participant Age Min',
    'Participant Age Max',
    'Participant Sex',
    'Participant Race/Ethnicity',
]);
// Fields that are dealt with out of the standard metadata flow.
const OUT_OF_BAND = new Set([
    'Study Name',
    'Concept of Interest',
    'Therapeutic Area',
    'Indication (Medical Condition)',
    'Metadata Status',
]);
/** Further split general metadata into study details/population details/other. */
export const splitGeneralMetadata = (metadata) => {
    const summary = [];
    const studyDetails = [];
    const populationDetails = [];
    const customFields = [];
    for (const m of metadata) {
        if (SUMMARY.has(m.title)) {
            summary.push(m);
        }
        else if (STUDY_DETAILS.has(m.title)) {
            studyDetails.push(m);
        }
        else if (POPULATION_DETAILS.has(m.title)) {
            populationDetails.push(m);
        }
        else if (!OUT_OF_BAND.has(m.title)) {
            customFields.push(m);
        }
    }
    return { summary, studyDetails, populationDetails, customFields };
};
/** Splits metadata into known good groups. */
export const splitMetadata = (metadata) => {
    const toolMetadata = [];
    const generalMetadata = [];
    const measureMetadata = [];
    for (const m of metadata) {
        const toolField = parseToolFieldKey(m.title);
        if (toolField) {
            measureMetadata.push({
                field: toolField,
                value: m.value,
            });
        }
        else if (TOOL_METADATA_TITLES.includes(m.title)) {
            toolMetadata.push(m);
        }
        else {
            generalMetadata.push(m);
        }
    }
    return { toolMetadata, generalMetadata, measureMetadata };
};
/**
 * Splits a flat list of metadata into specific groups that deal with
 * a single measure.
 */
export const groupToolAndMeasures = (measureMetadata) => {
    const groups = groupBy((x) => x.field.toolId, measureMetadata);
    return Object.values(groups).map((group) => {
        return {
            toolId: group[0].field.toolId,
            toolPerformance: findToolInfoValue(group, 'performance'),
            measures: groupMeasures(group),
        };
    });
};
export function groupMeasures(group) {
    const measureFields = group.filter((x) => isNumber(x.field.measuresGroupNumber));
    const measureGroups = groupBy((x) => x.field.measuresGroupNumber.toString(), measureFields);
    return Object.values(measureGroups).map((measureGroup) => ({
        toolId: measureGroup[0].field.toolId,
        conceptsOfInterest: findMetadataInfoValue(measureGroup, 'conceptsOfInterest'),
        specificDigitalMeasures: findMetadataInfoValue(measureGroup, 'specificDigitalMeasures'),
        measureId: findMetadataInfoValue(measureGroup, 'measureId'),
        endpointType: findMetadataInfoValue(measureGroup, 'endpointType'),
        endpointDescription: findMetadataInfoValue(measureGroup, 'endpointDescription'),
    }));
}
function findToolInfoValue(group, label) {
    const value = group.find((x) => x.field.label === label);
    return value === null || value === void 0 ? void 0 : value.value;
}
function findMetadataInfoValue(group, label) {
    const value = group.find((x) => x.field.measuresLabel === label);
    return value === null || value === void 0 ? void 0 : value.value;
}
const toolFieldKeySeparator = '_';
export function buildToolFieldKey({ toolId, label, measuresGroupNumber, measuresLabel, }) {
    let key = `tool${toolFieldKeySeparator}${toolId}`;
    if (label) {
        key += `${toolFieldKeySeparator}${label}`;
    }
    if (isNumber(measuresGroupNumber) && measuresLabel) {
        key += `${toolFieldKeySeparator}${measuresGroupNumber}${toolFieldKeySeparator}${measuresLabel}`;
    }
    return key;
}
export function parseToolFieldKey(key) {
    const [tool, toolId, label, measuresGroupNumberStr, measuresLabel] = key.split(toolFieldKeySeparator);
    if (tool !== 'tool' || !toolId) {
        return;
    }
    if (label === 'performance') {
        return {
            toolId,
            label,
        };
    }
    const measuresGroupNumber = toNumber(measuresGroupNumberStr);
    if (label === 'measures' &&
        isNumber(measuresGroupNumber) &&
        isMeasuresLabel(measuresLabel)) {
        return {
            toolId,
            label,
            measuresGroupNumber,
            measuresLabel,
        };
    }
    // return undefined if invalid because there are many other keys
    // in the metadata text that will not be one in this format
    return undefined;
}
function toNumber(str) {
    const maybeNumber = Number.parseInt(str !== null && str !== void 0 ? str : '');
    return isNaN(maybeNumber) ? undefined : maybeNumber;
}
function isNumber(n) {
    return typeof n === 'number';
}
function isMeasuresLabel(str) {
    return (str === 'conceptsOfInterest' ||
        str === 'specificDigitalMeasures' ||
        str === 'endpointType' ||
        str === 'endpointDescription' ||
        str === 'measureId');
}
/** Gets the value of a metadata item from something with metadata */
export const getMetadata = (hasMetadata, t) => {
    var _a;
    const metadata = deserializeWorkspaceMetadata(hasMetadata.additionalMetadata);
    return (_a = metadata.find(({ title }) => title === t)) === null || _a === void 0 ? void 0 : _a.value;
};
