import { StringParam, withDefault } from 'use-query-params';
import { useDebouncedMemo } from 'src/hooks/debounce';
import { useSearchOmni } from 'src/hooks/search';
import { useDebouncedQueryParam } from 'src/hooks/query-params';
const MEASURE_OMNISEARCH_KIND = ['measure'];
/** Use the search state (currently stored in the query params) */
export const useSearchState = () => {
    return useDebouncedQueryParam('query', withDefault(StringParam, ''));
};
/** Search the measure ontology using a remote service (algolia). */
export const useSearchMeasureOntologyAlgolia = () => {
    // Search that will be rendered to the user.
    const [search, setSearch] = useSearchState();
    // If the `search` is > 0 we're searching -- even if our debounce hasn't caught up with us yet.
    const isSearching = search.length > 0;
    // Search that will be sent to algolia. Can be lagging behind search above.
    // Whenever the user search updates we want to wait a second and then issue
    // that search to algolia.
    const searchQuery = useDebouncedMemo(() => search, [search], 250);
    const isSearchEnabled = searchQuery.length > 0;
    const { isLoading, options } = useSearchOmni({
        query: searchQuery,
        kinds: MEASURE_OMNISEARCH_KIND,
        enabled: isSearchEnabled,
    });
    // A pending search is when we think we're searching but our debounce hasn't caught up
    // with us yet.  We treat this as similar to a loading state because even though we've not
    // made a request yet, we're still "waiting" for the data
    const isPendingSearch = isSearching && search !== searchQuery;
    return [search, setSearch, isLoading || isPendingSearch, isSearching, options];
};
