import { Block, COLORS, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import dayjs from 'dayjs';
/** Time before a toggle should warn about expiration. */
export const WARNING_AGE = 60;
/**
 * Renders details about a toggle.
 *
 * Will include the name, description, and toggle age. If the age is
 * beyond WARNING_AGE the age will be colored alert.
 * @param param0
 * @returns
 */
export const ToggleDetails = ({ toggle }) => {
    const toggleAge = toggle.created
        ? dayjs().diff(dayjs(toggle.created), 'days')
        : null;
    const ageWarning = toggleAge && toggleAge > WARNING_AGE;
    return (React.createElement(Block, null,
        React.createElement(Typography, null, toggle.name),
        React.createElement(When, { condition: toggle.description },
            React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, toggle.description)),
        React.createElement(When, { condition: toggleAge !== null },
            React.createElement(Typography, { color: ageWarning ? COLORS.alert : COLORS.gray1, variant: TypographyVariant.Details },
                "Created ",
                toggleAge,
                " days ago"))));
};
