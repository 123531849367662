import React from 'react';
import { MarkdownCodeExtensionBarChartGrouped } from './MarkdownCodeExtensionBarChartGrouped';
import { MarkdownCodeExtensionBarChart } from './MarkdownCodeExtensionBarChart';
function parseCSV(csv) {
    return csv
        .trim()
        .split('\n')
        .map((line) => line.split(','));
}
export const MarkdownCodeExtensionChart = ({ code, parameters, }) => {
    const records = parseCSV(code);
    const { group: groupKey, item: itemKey, value: valueKey, style = 'bar', export: hasExport = 'false', } = parameters;
    const header = records.shift();
    if (!header) {
        return React.createElement(React.Fragment, null, "Invalid data for graph");
    }
    if (style === 'bar') {
        if (typeof groupKey !== 'undefined') {
            return (React.createElement(MarkdownCodeExtensionBarChartGrouped, { groupKey: groupKey.toString(), itemKey: itemKey === null || itemKey === void 0 ? void 0 : itemKey.toString(), valueKey: valueKey === null || valueKey === void 0 ? void 0 : valueKey.toString(), header: header, records: records, enableExport: hasExport.toString().toLowerCase() === 'true' }));
        }
        return (React.createElement(MarkdownCodeExtensionBarChart, { itemKey: itemKey === null || itemKey === void 0 ? void 0 : itemKey.toString(), valueKey: valueKey === null || valueKey === void 0 ? void 0 : valueKey.toString(), header: header, records: records, enableExport: hasExport.toString().toLowerCase() === 'true' }));
    }
    return React.createElement(React.Fragment, null, "Unknown chart type");
};
