import React, { useContext, useMemo } from 'react';
import { COLORS, Stack, StatefulDataTable, TextColumn, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { When } from 'react-if';
import { deserializeWorkspaceMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { ToolSummary } from 'src/components/ToolSummary';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { ConceptOfInterestLink } from './ConceptOfInterestLink';
const useEndpointTools = (endpoints) => {
    const toolIds = Array.from(new Set(endpoints
        .map(({ toolId }) => toolId)
        .filter((toolId) => toolId.length > 0)));
    const isEnabled = toolIds.length > 0;
    const result = useElektraApi(ToolResource.getBulk(toolIds, { join: ['maker'] }), {
        enabled: isEnabled,
    });
    return useMemo(() => (Object.assign(Object.assign({}, result), { isEnabled })), [result, isEnabled]);
};
const EndpointTable = ({ endpoints, data: tools, }) => {
    const { isViewingFromConnectContext } = useContext(ConnectContext);
    const columns = useMemo(() => [
        {
            id: 'endpoint',
            header: 'Endpoint',
            render: (endpoint) => (React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Typography, null, endpoint.specificDigitalMeasures),
                React.createElement(When, { condition: endpoint.endpointDescription.length > 0 },
                    React.createElement(AnchoredTooltip, { triggerType: "click" },
                        React.createElement(Typography, null, endpoint.endpointDescription))))),
            searchable: true,
            sortable: true,
            getStringValue: (x) => x.specificDigitalMeasures,
        },
        TextColumn('endpointType', {
            header: 'Type',
        }),
        TextColumn('conceptsOfInterest', {
            header: 'Measure',
            render: (item) => (React.createElement(ConceptOfInterestLink, { conceptOfInterest: item.conceptsOfInterest, measureId: item.measureId })),
        }),
        {
            id: 'instrument',
            header: 'Instrument',
            sortable: true,
            searchable: true,
            getStringValue: ({ toolId }) => {
                var _a;
                const tool = tools.find((tool) => tool.id === toolId);
                return (_a = tool === null || tool === void 0 ? void 0 : tool.model) !== null && _a !== void 0 ? _a : 'Not Specified';
            },
            render: ({ toolId }) => {
                const tool = tools.find((tool) => tool.id === toolId);
                if (!tool) {
                    return (React.createElement(Typography, { color: COLORS.gray4, variant: TypographyVariant.Body }, "Not Specified"));
                }
                return (React.createElement(ToolSummary, { tool: tool, href: `${generatePath(PATHS.atlas.technologies.detail, {
                        id: toolId,
                    })}${isViewingFromConnectContext ? '?view=1' : ''}` }));
            },
        },
    ], [tools]);
    return React.createElement(StatefulDataTable, { data: endpoints, columns: columns });
};
const Endpoints = ({ study }) => {
    const { measureMetadata } = useMemo(() => {
        const parsedMetadata = deserializeWorkspaceMetadata(study.additionalMetadata);
        return splitMetadata(parsedMetadata);
    }, [study]);
    const endpoints = useMemo(() => {
        return Object.values(measureMetadata
            .filter((metadata) => typeof metadata.field.measuresGroupNumber !== 'undefined')
            .reduce((carry, metadata) => {
            const key = `${metadata.field.toolId}-${metadata.field.measuresGroupNumber}`;
            if (!(key in carry)) {
                carry[key] = {
                    endpointType: '',
                    conceptsOfInterest: '',
                    specificDigitalMeasures: '',
                    endpointDescription: '',
                    toolId: metadata.field.toolId,
                };
            }
            if (metadata.field.measuresLabel) {
                carry[key][metadata.field.measuresLabel] = metadata.value;
            }
            return carry;
        }, {}));
    }, [measureMetadata]);
    const result = useEndpointTools(endpoints);
    if (!result.isEnabled) {
        return (React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Endpoints"),
            React.createElement(EndpointTable, { data: [], endpoints: endpoints })));
    }
    return (React.createElement(Stack, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Endpoints"),
        React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: EndpointTable, endpoints: endpoints }))));
};
export { Endpoints };
