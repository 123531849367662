var _a;
import Rollbar from 'rollbar';
import { getCurrentEnvironement } from './environment';
let ROLLBAR_INSTANCE = null;
const ROLLBAR_TOKEN = (_a = process.env.ROLLBAR_CLIENT_TOKEN) !== null && _a !== void 0 ? _a : '';
/**
 * Initialize rollbar.
 *
 * This should be called at most once so that we don't catch
 * duplicate uncaught errors.
 */
const initializeRollbar = () => {
    ROLLBAR_INSTANCE = new Rollbar({
        accessToken: ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        checkIgnore: () => {
            const env = getCurrentEnvironement();
            return env === 'local' || env === 'e2e';
        },
        payload: {
            environment: getCurrentEnvironement(),
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: process.env.REACT_APP_VERSION,
                },
            },
        },
    });
    return ROLLBAR_INSTANCE;
};
/** Gets (or creates) a rollbar instance. */
const getRollbarInstance = () => {
    if (!ROLLBAR_INSTANCE) {
        return initializeRollbar();
    }
    return ROLLBAR_INSTANCE;
};
/** Updates rollback to track the currently logged in user. */
const updateRollbarUser = (user) => {
    const instance = getRollbarInstance();
    instance.configure({
        payload: {
            person: user ? { id: user.id, email: user.email } : { id: null },
        },
    }); // ID types have a bit of a mismatch here
};
export { getRollbarInstance, initializeRollbar, updateRollbarUser };
