import { ResourceMetadataResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { uniq } from 'ramda';
const DEFAULT_TAGS = ['Favorite', 'Needs Review'];
function useWorkspaceMetadata(metadataType, resourceType, resource) {
    var _a;
    const { data } = useElektraApi(ResourceMetadataResource.list());
    return ((_a = data === null || data === void 0 ? void 0 : data.filter((x) => x.resourceType === resourceType &&
        x.metadata.type === metadataType &&
        (resource === undefined || x.resource === resource))) !== null && _a !== void 0 ? _a : []);
}
/**
 * Returns all the tags associated with a resource.
 */
const useTagsForResource = (resourceType, resource) => {
    return useWorkspaceMetadata('tag', resourceType, resource);
};
const useNotesForResource = (resourceType, resource) => {
    return useWorkspaceMetadata('note', resourceType, resource);
};
/**
 * Returns all the metadata associated with a resource.
 */
const useSystemMetadataForResource = (resourceType, resource) => {
    return useWorkspaceMetadata('systemMetadata', resourceType, resource);
};
/**
 * Returns a list of all tag values that are available for a resource type.
 */
const useAvailableTags = (resourceType) => {
    const additionalTags = useWorkspaceMetadata('tag', resourceType).map((v) => v.metadata.tag);
    return uniq([...DEFAULT_TAGS, ...additionalTags]);
};
export { useTagsForResource, useAvailableTags, useNotesForResource, useSystemMetadataForResource, DEFAULT_TAGS, };
