import { toOptions } from '@humanfirst/elektron';
import React from 'react';
import { TeamResource, StudyFieldsResource, TherapeuticAreaResource, MedicalConditionsResource, } from '@humanfirst/use-elektra-api';
import { CONCEPT_OF_INTEREST_OPTIONS } from 'src/constants/studies';
import { SmartForm } from 'src/components/SmartForm';
import { SmartCheckbox, SmartInput, SmartSelect, SmartTextarea, } from 'src/components/SmartForm/fields';
import { SmartToolSelect } from 'src/components/SmartForm/modelFields/SmartToolSelect';
import { SmartApiModelSelect } from 'src/components/SmartForm/SmartApiModelSelect';
import { deserialize, serialize } from './serialization';
const StudyForm = ({ onSubmit, study, }) => {
    const defaultValues = study
        ? deserialize(study)
        : { name: '' };
    return (React.createElement(SmartForm, { onSubmit: (data) => onSubmit(serialize(data)), defaultValues: defaultValues, submitText: "Save" },
        React.createElement(SmartInput, { label: "Study Name", name: "name", id: "studyName", autoComplete: "off", rules: { required: true } }),
        React.createElement(SmartApiModelSelect, { label: "Team", name: "team", id: "team", labelKey: "name", definition: TeamResource.list(), clearable: false, required: true }),
        React.createElement(SmartApiModelSelect, { label: "Study Fields", name: "customFieldsSet", id: "studyCustomFields", labelKey: 'name', definition: StudyFieldsResource.getAllFields(), clearable: false }),
        React.createElement(SmartSelect, { required: true, options: toOptions(CONCEPT_OF_INTEREST_OPTIONS), label: "Concept of Interest", multi: true, creatable: true, name: "conceptsOfInterest", id: "conceptsOfInterest" }),
        React.createElement(SmartApiModelSelect, { label: "Therapeutic Area", name: "therapeuticArea", id: "therapeuticArea", labelKey: "therapeuticArea", definition: TherapeuticAreaResource.list(), required: true }),
        React.createElement(SmartApiModelSelect, { label: "Indication (Medical Condition)", name: "medicalConditions", id: "medicalConditions", labelKey: "name", definition: MedicalConditionsResource.list(), multi: true, required: true }),
        React.createElement(SmartToolSelect, { label: "Final Technology Selections", name: "tools", id: "tools", clearable: false, multi: true }),
        React.createElement(SmartTextarea, { label: "Additional Metadata", name: "additionalMetadata", id: "additionalMetadata", rows: 16 }),
        React.createElement(SmartCheckbox, { name: "isArchived" }, "Archived")));
};
export { StudyForm };
