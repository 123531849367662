import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Block, StatefulTooltip, Icons, Divider, Button, setOpenAction, BetterTree, setStateAction, stateFromTree, useTreeState, } from '@humanfirst/elektron';
import { useGetEvidenceForMeasureCategory, useGetMedicalConditionsForMeasureCategory, useGetToolsForMeasureCategory, } from 'src/hooks/relatedResources';
import { getMeasurementHierarchyOntologyValue, getPathFromString, } from 'src/utils/measurements';
import { t } from 'src/constants/i18n';
import { ResourceSummaryCard } from 'src/components/CatalogResourceDetails';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { MeasureCategoryTreeNode } from 'src/pages/Ontologies/components/MeasureCategoryTreeNode';
import { TargetMeasureContext } from '../contexts/TargetMeasureContext';
const MeasureHierarchy = ({ category, }) => {
    var _a, _b, _c;
    const categoryName = (_a = getPathFromString(category.category).pop()) !== null && _a !== void 0 ? _a : '';
    const descendantMeasures = (_c = (_b = category.descendants) === null || _b === void 0 ? void 0 : _b.flatMap((x) => x.category)) !== null && _c !== void 0 ? _c : [];
    const measures = [category.category, ...descendantMeasures];
    const measureWithHierarchy = getMeasurementHierarchyOntologyValue(measures);
    const root = {
        value: { measure: 'root' },
        children: measureWithHierarchy,
    };
    const [targetMeasure, setTargetMeasure] = useState('');
    const [state, dispatch] = useTreeState(root);
    const assignTargetMeasure = useCallback(() => {
        setTargetMeasure(() => categoryName);
    }, [categoryName]);
    useEffect(() => {
        dispatch(setStateAction(stateFromTree(root)));
        dispatch(setOpenAction(true));
        assignTargetMeasure();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category.id]);
    return (React.createElement(TargetMeasureContext.Provider, { value: { targetMeasure, assignTargetMeasure } },
        React.createElement(Block, null,
            React.createElement(StatefulTooltip, { placement: 'bottom', content: React.createElement(BetterTree, { state: state.children[0], dispatch: dispatch, path: [0], root: true, isFirst: true, isLast: state.children.length > 0, contentComponent: MeasureCategoryTreeNode }), triggerType: 'click', onClick: () => dispatch(setOpenAction(true)) },
                React.createElement(Button, { variant: "minimal", startEnhancer: React.createElement(Icons.Misc.Hierarchy, { size: "1.5em", decorative: true }) }, "View in Ontology")),
            React.createElement(Divider, null))));
};
const MeasureCategoryResourceSummaryCard = ({ category }) => {
    const { data: tools, isLoading: isToolsLoading } = useGetToolsForMeasureCategory(category);
    const { data: medicalConditions, isLoading: isMedicalConditionsLoading } = useGetMedicalConditionsForMeasureCategory(category);
    const { data: sources, isLoading: isSourcesLoading } = useGetEvidenceForMeasureCategory(category);
    const hasEvidence = useHasPermission('evidenceSource', 'read');
    const categoryName = useMemo(() => { var _a; return (_a = getPathFromString(category.category).pop()) !== null && _a !== void 0 ? _a : ''; }, [category]);
    const statistics = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (isToolsLoading || isSourcesLoading || isMedicalConditionsLoading) {
            return null;
        }
        if (!hasEvidence) {
            return [
                {
                    count: (_a = medicalConditions === null || medicalConditions === void 0 ? void 0 : medicalConditions.length) !== null && _a !== void 0 ? _a : 0,
                    label: 'Medical Conditions',
                    tooltip: t('measureDetailPage.medicalConditionToolTip'),
                },
                {
                    count: (_b = tools === null || tools === void 0 ? void 0 : tools.length) !== null && _b !== void 0 ? _b : 0,
                    label: 'Technologies',
                    tooltip: t('measureDetailPage.technologyToolTip'),
                },
            ];
        }
        return [
            {
                count: (_c = tools === null || tools === void 0 ? void 0 : tools.length) !== null && _c !== void 0 ? _c : 0,
                label: 'Technologies',
                tooltip: t('measureDetailPage.technologyToolTip'),
            },
            {
                count: (_d = medicalConditions === null || medicalConditions === void 0 ? void 0 : medicalConditions.length) !== null && _d !== void 0 ? _d : 0,
                label: 'Medical Conditions',
                tooltip: t('measureDetailPage.medicalConditionToolTip'),
            },
            {
                count: (_e = sources === null || sources === void 0 ? void 0 : sources.length) !== null && _e !== void 0 ? _e : 0,
                label: 'Evidence',
                tooltip: t('measureDetailPage.evidenceToolTip'),
            },
        ];
    }, [
        isToolsLoading,
        isSourcesLoading,
        isMedicalConditionsLoading,
        sources === null || sources === void 0 ? void 0 : sources.length,
        medicalConditions === null || medicalConditions === void 0 ? void 0 : medicalConditions.length,
        tools === null || tools === void 0 ? void 0 : tools.length,
        hasEvidence,
    ]);
    return (React.createElement(ResourceSummaryCard, { name: categoryName, id: category.id, resourceType: 'Measure', statistics: statistics, measureHierarchy: React.createElement(MeasureHierarchy, { category: category }) }));
};
export { MeasureCategoryResourceSummaryCard };
