import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { Block, Button, Image as ImageComponent, Icons, Stack, Typography, TypographyVariant, COLORS, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { LoadingState } from 'src/components/LoadingState';
import { UriTransformerContext } from '../contexts/UriTransformerContext';
const ImageWithLoading = ({ src, alt, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const imageRef = useRef(null);
    useEffect(() => {
        if (imageRef.current && !imageRef.current.complete) {
            setIsLoading(true);
        }
    }, []);
    const handleImageLoad = () => setIsLoading(false);
    return (React.createElement(React.Fragment, null,
        isLoading ? React.createElement(LoadingState, null) : null,
        React.createElement(Block, { display: isLoading ? 'none' : 'flex', justifyContent: "center" },
            React.createElement(ImageComponent, { src: src, alt: alt, imageRef: imageRef, onLoad: handleImageLoad, maxWidth: "100%" }))));
};
export const MarkdownCodeExtensionImageGallery = ({ code, parameters, }) => {
    const { transformer } = useContext(UriTransformerContext);
    const images = useMemo(() => code
        .trim()
        .split('\n')
        .map((line) => line.trim())
        .map((line) => line.match(/^([^ ]+)(?: (.+))?$/))
        .filter((m) => m !== null)
        .map((matches) => {
        var _a;
        return ({
            url: matches[1],
            title: ((_a = matches[2]) !== null && _a !== void 0 ? _a : '').replace(/(^"|"$)/g, ''),
        });
    })
        .map((x) => transformer ? { title: x.title, url: transformer(x.url) } : x), [code, transformer]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const prevImageCallback = useCallback(() => setSelectedImageIndex((i) => Math.max(i - 1, 0)), [setSelectedImageIndex]);
    const nextImageCallback = useCallback(() => setSelectedImageIndex((i) => Math.min(i + 1, images.length - 1)), [setSelectedImageIndex, images]);
    // Preload the next image. Right now this will just load it into the
    // browser cache.
    useEffect(() => {
        const nextImage = selectedImageIndex + 1;
        if (nextImage < images.length) {
            const img = new Image();
            img.src = images[nextImage].url;
        }
    }, [selectedImageIndex, images]);
    if (images.length === 0) {
        return React.createElement(React.Fragment, null);
    }
    const title = Array.isArray(parameters.title)
        ? parameters.title.join(' ')
        : parameters.title;
    const normalizedIndex = ((selectedImageIndex % images.length) + images.length) % images.length;
    const selectedImage = images[normalizedIndex];
    return (React.createElement(Stack, { direction: "vertical" },
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Block, { flex: "1" },
                React.createElement(When, { condition: !!title },
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, decodeURIComponent(title !== null && title !== void 0 ? title : '')))),
            React.createElement(Button, { disabled: normalizedIndex <= 0, onClick: prevImageCallback, startEnhancer: React.createElement(Icons.Misc.ArrowBack, { color: COLORS.white, decorative: true }) }, "Previous"),
            React.createElement(Button, { disabled: normalizedIndex >= images.length - 1, onClick: nextImageCallback, endEnhancer: React.createElement(Icons.Misc.ArrowForward, { color: COLORS.white, decorative: true }) }, "Next")),
        React.createElement(ImageWithLoading, { key: selectedImage.url, src: selectedImage.url, alt: selectedImage.title })));
};
