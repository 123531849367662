import { Block, Stack, Spinner, Typography, Link } from '@humanfirst/elektron';
import { useElektraApi, ToolResource } from '@humanfirst/use-elektra-api';
import React from 'react';
import { generatePath } from 'react-router';
import { VendorLogo } from 'src/components/VendorLogo';
import { PATHS } from 'src/config/path';
const OrganizationCell = ({ item }) => {
    return (React.createElement(Stack, { direction: "horizontal", alignItems: 'center', gap: '16px' },
        React.createElement(VendorLogo, { vendor: item, imageSize: '40px' }),
        React.createElement(Block, { width: '240px' },
            React.createElement(Link, { href: generatePath(PATHS.atlas.vendors.detail, { id: item.id }) }, item.name))));
};
const ProductCountCell = ({ item }) => {
    var _a;
    const { data, isLoading } = useElektraApi(ToolResource.getStatistics({
        filter: [['makerIdsJoined', '$cont', `,${item.id}`]],
    }));
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(Typography, null, (_a = data === null || data === void 0 ? void 0 : data.count) !== null && _a !== void 0 ? _a : 0);
};
export { OrganizationCell, ProductCountCell };
