import React from 'react';
import { Link, Typography, TypographyVariant } from '@humanfirst/elektron';
import { generatePath } from 'react-router';
import { If, Then, Else } from 'react-if';
import { PATHS } from 'src/config/path';
import { getTeamName } from './utils';
/** Renders associated team and a link to it */
const WorkspaceTeamCell = ({ item }) => {
    var _a;
    if (!item.team) {
        return (React.createElement(Typography, null,
            React.createElement("em", null, "None")));
    }
    if ((_a = item.team) === null || _a === void 0 ? void 0 : _a.id) {
        return (React.createElement(Link, { href: generatePath(PATHS.teams.detail, { id: item.team.id }), typographyVariant: TypographyVariant.BodyBold }, getTeamName(item)));
    }
    // If we have a team but no ID, just render the text. I'm pretty sure this
    // should never happen, but better than hitting an exception.
    return (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, getTeamName(item)));
};
/**
 * Renders the name of the workspace.
 * If the workspace is locked this is just text, otherwise it's a link.
 */
const WorkspaceNameCell = ({ item }) => {
    return (React.createElement(If, { condition: !item.locked },
        React.createElement(Then, null,
            React.createElement(Link, { href: generatePath(PATHS.projects.detail, {
                    projectId: item.id,
                }) }, item.name)),
        React.createElement(Else, null,
            React.createElement(Typography, null, item.name))));
};
export { WorkspaceNameCell, WorkspaceTeamCell };
