import { COLORS } from '@humanfirst/elektron';
import React from 'react';
const HIGHLIGHT_START_TAG = '<em>';
const HIGHLIGHT_END_TAG = '</em>';
/**
 * Gets a subset of items, prioritizing those that match a predicate.
 *
 * This runs in three steps:
 * 1) If there is no maximum, or the number of items is less than the maximum, return all items.
 * 2) If there are items matching the predicate, return at most `max` of them.
 * 3) Finally, return the the first `max` items from the original input.
 */
function limitItems(pred, items, max) {
    if (max === undefined || max >= items.length) {
        return items;
    }
    const matchedItems = items.filter(pred);
    const finalItems = matchedItems.length ? matchedItems : items;
    return finalItems.slice(0, max);
}
/**
 * Applies highlighting to a string, returning a JSX fragment.
 *
 * This will do auto tag closing; if we encounter an end tag before a start tag
 * everything before that will be considered highlighted. Similarly for a start
 * tag with no end.
 *
 * WARNING: If the startTag and endTag contain regex special characters this may
 * have unexpected results.
 */
const applyHighlightJSX = (value, startTag = HIGHLIGHT_START_TAG, endTag = HIGHLIGHT_END_TAG) => {
    // Split on start and end tags, including them in the split array
    // ['foo', '<start>', 'bar', '</end>']
    const parts = value
        .split(new RegExp(`(${startTag}|${endTag})`))
        .filter((x) => !!x);
    // We have no parts, it is not highlighted and we have no value.
    if (parts.length === 0) {
        return { isHighlighted: false, value: null };
    }
    // We have exactly one part. We did not find either start or end
    // tags so just wrap up in a span. It is not highlighted.
    if (parts.length === 1) {
        return {
            isHighlighted: false,
            value: React.createElement("span", null, value),
        };
    }
    // We have at least one highlighted value. The algorithm here goes
    // in two passes. First we loop through our array. Whenever we encounter
    // a start tag we mark the _next_ item as highlighted. Whenever we encounter
    // an end tag we mark the _previous_ item as highlighted. Otherwise we simply
    // add the chunk to the results array.
    // Once we've composed the result array we fold map over and replace with
    // the appropriate tags.
    const result = [];
    let highlightNext = false;
    for (const part of parts) {
        switch (part) {
            case startTag:
                highlightNext = true;
                break;
            case endTag:
                result[result.length - 1].isHighlighted = true;
                break;
            default:
                result.push({ value: part, isHighlighted: highlightNext });
                highlightNext = false;
        }
    }
    return {
        // We always have at least one highlight in this case.
        isHighlighted: true,
        value: (React.createElement("span", null, result.map(({ value: v, isHighlighted }, i) => isHighlighted ? (React.createElement("mark", { 
            // eslint-disable-next-line react/no-array-index-key
            key: i, style: { backgroundColor: COLORS.blue4, color: COLORS.primary } }, v)) : (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("span", { key: i }, v))))),
    };
};
export { limitItems, applyHighlightJSX, HIGHLIGHT_START_TAG, HIGHLIGHT_END_TAG };
