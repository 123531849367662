const PHASE_ROMAN_NUMERAL_KEYS = [
    'Phase I',
    'Phase II',
    'Phase III',
    'Phase IV',
];
export const TRANSLATE_TO_ROMAN_NUM = {
    'Early Phase 1': ['Phase I'],
    'Phase 1': ['Phase I'],
    'Phase 2': ['Phase II'],
    'Phase 1/Phase 2': ['Phase I', 'Phase II'],
    'Phase 3': ['Phase III'],
    'Phase 2/Phase 3': ['Phase II', 'Phase III'],
    'Phase 4': ['Phase IV'],
};
export const getCTPhasesWithCountValuesFromEvidence = (evidence) => {
    const evidenceCTPhases = evidence
        .filter((e) => !!e.ctPhase)
        .map((ev) => ev.ctPhase);
    return getCTPhasesWithCountValues(evidenceCTPhases);
};
export const getCTPhasesWithCountValues = (ctPhases) => {
    var _a;
    const counts = {
        'Phase I': 0,
        'Phase II': 0,
        'Phase III': 0,
        'Phase IV': 0,
    };
    for (const phase of ctPhases) {
        if (phase) {
            const romanNumKeys = (_a = TRANSLATE_TO_ROMAN_NUM[phase]) !== null && _a !== void 0 ? _a : [];
            for (const key of romanNumKeys) {
                counts[key] += 1;
            }
        }
    }
    return PHASE_ROMAN_NUMERAL_KEYS.map((key) => ({
        label: key,
        count: counts[key],
    }));
};
