import { __awaiter } from "tslib";
import { Button, Heading, Spacer, StatefulTabs, toOptions, toaster, } from '@humanfirst/elektron';
import { MedicalConditionsResource, TherapeuticAreaResource, useElektraApi, useElektraApiMutation, WorkspaceResource, } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { ContentContainer } from 'src/components/ContentContainer';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { SmartForm } from 'src/components/SmartForm';
import { SmartInput, SmartSelect, SmartTextarea, } from 'src/components/SmartForm/fields';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { useSetTitle } from 'src/hooks/title';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { SmartApiModelSelect } from 'src/components/SmartForm/SmartApiModelSelect';
import { deserializeScopeToOptions, serializeOptionsToScope, } from 'src/utils/workspaceScope';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { HARD_CODED_COIS } from 'src/constants/projectConceptsOfInterest';
import { ArchiveWorkspace } from './components/ArchiveWorkspace';
/**
 * A specialized submit component that will be disabled if there has been no updates.
 */
const SubmitComponent = () => {
    const { formState: { isValid, isSubmitting, isDirty }, } = useFormContext();
    return (React.createElement(Button, { type: "submit", isLoading: isSubmitting, disabled: !isValid || !isDirty, size: "full" }, "Submit"));
};
const EditWorkspaceForm = ({ workspace, }) => {
    const navigate = useNavigate();
    const { mutateAsync } = useElektraApiMutation(WorkspaceResource.update(workspace.id));
    const defaultValues = useMemo(() => {
        var _a;
        return (Object.assign({ name: workspace.name, description: (_a = workspace.description) !== null && _a !== void 0 ? _a : undefined }, deserializeScopeToOptions(workspace.scope)));
    }, [workspace]);
    return (React.createElement(SmartForm, { submitComponent: SubmitComponent, defaultValues: defaultValues, onSubmit: (data) => __awaiter(void 0, void 0, void 0, function* () {
            const dataToSend = {
                scope: serializeOptionsToScope(data),
            };
            if (data.name) {
                dataToSend.name = data.name;
            }
            if (data.description !== undefined) {
                dataToSend.description = data.description;
            }
            yield mutateAsync(dataToSend);
            toaster.positive('Project Updated', {});
            navigate(generatePath(PATHS.projects.detail, { projectId: workspace.id }));
        }) },
        React.createElement(SmartInput, { label: "Name", name: "name", id: "name", required: true, autoComplete: "off" }),
        React.createElement(SmartTextarea, { label: "Description", name: "description", id: "description", rows: 4 }),
        React.createElement(SmartApiModelSelect, { id: "therapeuticAreas", label: "Therapeutic Area (Optional)", name: "therapeuticAreas", optionGetter: (ta) => ({
                id: ta.therapeuticArea,
                label: ta.therapeuticArea,
            }), definition: TherapeuticAreaResource.list(), labelKey: "therapeuticArea", multi: true }),
        React.createElement(SmartApiModelSelect, { id: "medicalConditions", label: "Medical Condition (Optional)", name: "medicalConditions", optionGetter: (mc) => ({ id: mc.name, label: mc.name }), definition: MedicalConditionsResource.list(), labelKey: "name", multi: true }),
        React.createElement(SmartSelect, { id: "allMeasuresFlat", label: "Concept of Interest (Optional)", name: "allMeasuresFlat", options: toOptions(HARD_CODED_COIS), multi: true })));
};
const WorkspaceEditInternal = ({ data }) => {
    useSetTitle(`Edit Project`);
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Workspaces,
            BREADCRUMBS.WorkspaceDetails(data),
            BREADCRUMBS.WorkspaceEdit(data),
        ] },
        React.createElement(Heading, null,
            "Edit: ",
            data.name),
        React.createElement(StatefulTabs, null,
            React.createElement(StatefulTabs.TabPanel, { title: "Project" },
                React.createElement(Spacer, { size: "16px" }),
                React.createElement(EditWorkspaceForm, { workspace: data })),
            React.createElement(StatefulTabs.TabPanel, { title: "Archive" },
                React.createElement(Spacer, { size: "16px" }),
                React.createElement(ArchiveWorkspace, { workspace: data })))));
};
const WorkspaceEdit = () => {
    const workspaceId = useActiveWorkspaceId();
    const result = useElektraApi(WorkspaceResource.get(workspaceId));
    return React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: WorkspaceEditInternal }));
};
export { WorkspaceEdit };
