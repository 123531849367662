import { deserializeQuery } from 'src/utils/query';
const SCOPE_FILTER_MAPPINGS = {
    therapeuticAreas: 'Therapeutic Area',
    medicalConditions: 'Medical Condition',
    // This is a temporary fix to support previous projects that have selected conceptsOfInterest scopes.
    // We will deserialize both 'conceptsOfInterest' and 'allMeasuresFlat' to 'Concept of Interest' for now.
    // We will stop serializing 'conceptsOfInterest' from this point on.
    conceptsOfInterest: 'Concept of Interest',
    allMeasuresFlat: 'Concept of Interest',
};
const isValueArray = (value) => {
    return Array.isArray(value) && value.every((item) => typeof item === 'string');
};
const isString = (value) => {
    return typeof value === 'string';
};
const scopeToFilterValues = (scope) => {
    const { filters } = deserializeQuery(scope);
    return Object.entries(SCOPE_FILTER_MAPPINGS).reduce((acc, [key, label]) => {
        var _a;
        const filterValue = (_a = filters === null || filters === void 0 ? void 0 : filters.find((x) => x.fieldId === key)) === null || _a === void 0 ? void 0 : _a.filterValue;
        let value = [];
        if (isValueArray(filterValue)) {
            value = filterValue;
        }
        else if (isString(filterValue)) {
            value = [filterValue];
        }
        // This is to support both allMeasuresFlat and the deprecated conceptsOfInterest scope both showing "Concept of Interest  "
        if (acc[label]) {
            acc[label] = [...acc[label], ...value];
        }
        else {
            acc[label] = value;
        }
        return acc;
    }, {});
};
const serializeOptionsToScope = (formContent) => {
    var _a, _b, _c;
    const scopes = [];
    if ((_a = formContent.medicalConditions) === null || _a === void 0 ? void 0 : _a.length) {
        scopes.push(`medicalConditions:${formContent.medicalConditions
            .map((mc) => `"${mc.label}"`)
            .join(',')}`);
    }
    if ((_b = formContent.therapeuticAreas) === null || _b === void 0 ? void 0 : _b.length) {
        scopes.push(`therapeuticAreas:${formContent.therapeuticAreas
            .map((ta) => `"${ta.label}"`)
            .join(',')}`);
    }
    if ((_c = formContent.allMeasuresFlat) === null || _c === void 0 ? void 0 : _c.length) {
        scopes.push(`allMeasuresFlat:${formContent.allMeasuresFlat
            .map((coi) => `"${coi.label}"`)
            .join(',')}`);
    }
    return scopes.join(' ');
};
const deserializeScopeToOptions = (scope) => {
    if (!scope) {
        return {};
    }
    const { filters } = deserializeQuery(scope);
    return Object.keys(SCOPE_FILTER_MAPPINGS).reduce((acc, key) => {
        var _a;
        const filterValue = (_a = filters === null || filters === void 0 ? void 0 : filters.find((x) => x.fieldId === key)) === null || _a === void 0 ? void 0 : _a.filterValue;
        let value = [];
        if (isValueArray(filterValue)) {
            value = filterValue.map((label) => ({ label, id: label }));
        }
        else if (isString(filterValue)) {
            value = [{ label: filterValue, id: filterValue }];
        }
        acc[key] = value;
        return acc;
    }, {});
};
export { scopeToFilterValues, serializeOptionsToScope, deserializeScopeToOptions, };
