import { Button, COLORS, Icons, SelectContext, Stack, } from '@humanfirst/elektron';
import React, { useContext, useMemo } from 'react';
import { useShowModalBound } from 'src/hooks/modal';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { ListContext } from '../../contexts/ListContext';
/**
 * Returns the currently selected bookmarks.
 *
 * Requires that the item under selection has a unique ID, and the associated workspace metadata.
 */
const useBookmarksSelected = () => {
    const { selection, selectable: selectableBookmarks } = useContext(SelectContext);
    const castSelectableBookmarks = selectableBookmarks;
    return useMemo(() => {
        return selection
            .map((x) => {
            var _a;
            return (_a = castSelectableBookmarks.find((y) => y.id === x)) === null || _a === void 0 ? void 0 : _a.__bookmark;
        })
            .filter((x) => !!x);
    }, [selection, castSelectableBookmarks]);
};
/** An action that triggers the Add To List flow */
const AddToListAction = () => {
    const bookmarks = useBookmarksSelected();
    const { openAddModal } = useContext(ListContext);
    return (React.createElement(Button, { disabled: bookmarks.length === 0, variant: "secondary", onClick: () => openAddModal(bookmarks) }, "Add to List"));
};
/** An action that triggers the Remove from List flow. */
const RemoveFromListAction = () => {
    const bookmarks = useBookmarksSelected();
    const { openRemoveModal } = useContext(ListContext);
    return (React.createElement(Button, { disabled: bookmarks.length === 0, variant: "secondary", onClick: () => openRemoveModal(bookmarks) }, "Remove from List"));
};
/** An action that triggers an archival from project. */
const ArchiveFromWorkspaceAction = () => {
    const workspaceId = useActiveWorkspaceId();
    const bookmarks = useBookmarksSelected();
    const onArchive = useShowModalBound('action-in-workspace', {
        workspaceId,
        bookmarks,
        variant: 'archive',
    });
    return (React.createElement(Button, { disabled: bookmarks.length === 0, variant: "secondary", onClick: onArchive, startEnhancer: React.createElement(Icons.Misc.Archive, { color: COLORS.primary, decorative: true }) }, "Archive"));
};
/** An action that triggers a remove from project. */
const RemoveFromWorkspaceAction = () => {
    const workspaceId = useActiveWorkspaceId();
    const bookmarks = useBookmarksSelected();
    const onRemove = useShowModalBound('action-in-workspace', {
        workspaceId,
        bookmarks,
        variant: 'delete',
    });
    return (React.createElement(Button, { disabled: bookmarks.length === 0, variant: "secondary", onClick: onRemove, startEnhancer: React.createElement(Icons.Misc.Delete, { color: COLORS.primary, decorative: true }) }, "Remove"));
};
/** An action that triggers a restore to project. */
const RestoreAction = () => {
    const workspaceId = useActiveWorkspaceId();
    const bookmarks = useBookmarksSelected();
    const onRestore = useShowModalBound('action-in-workspace', {
        workspaceId,
        bookmarks,
        variant: 'restore',
    });
    return (React.createElement(Button, { variant: "secondary", disabled: bookmarks.length === 0, onClick: onRestore, startEnhancer: React.createElement(Icons.Misc.Restore, { color: COLORS.primary, decorative: true }) }, "Restore to Project"));
};
export const WorkspaceBookmarkActions = ({ isArchive, isList }) => {
    return (React.createElement(Stack, { direction: 'horizontal' },
        isArchive ? React.createElement(RestoreAction, null) : null,
        !isArchive ? React.createElement(AddToListAction, null) : null,
        isList ? React.createElement(RemoveFromListAction, null) : null,
        !isArchive ? React.createElement(ArchiveFromWorkspaceAction, null) : null,
        !isList ? React.createElement(RemoveFromWorkspaceAction, null) : null));
};
