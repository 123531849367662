import { __awaiter } from "tslib";
import assert from 'assert';
import { useEffect, useMemo, useState } from 'react';
import algoliarecommend from '@algolia/recommend';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from 'src/constants/search';
import { useLogger } from '../logger';
function useRelated({ indexName, objectID, enabled = true, }) {
    const logger = useLogger(useRelated.name);
    const [response, setResponse] = useState({
        isLoading: enabled,
        isError: false,
    });
    const client = useMemo(() => algoliarecommend(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY), []);
    useEffect(() => {
        if (!enabled) {
            setResponse((r) => (r.isLoading ? Object.assign(Object.assign({}, r), { isLoading: false }) : r));
            return;
        }
        let isMounted = true;
        setResponse((r) => (!r.isLoading ? Object.assign(Object.assign({}, r), { isLoading: true }) : r));
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                const { results } = yield client.getRelatedProducts([
                    {
                        indexName,
                        objectID,
                    },
                ]);
                if (!isMounted) {
                    return;
                }
                assert(results.length > 0, 'results array is empty');
                setResponse({
                    isLoading: false,
                    isError: false,
                    error: undefined,
                    data: results[0].hits,
                });
            }
            catch (error) {
                if (!isMounted) {
                    return;
                }
                setResponse((r) => (Object.assign(Object.assign({}, r), { isLoading: false, isError: true, error })));
            }
        }))().catch((e) => logger.error(e));
        return () => {
            isMounted = false;
        };
    }, [client, enabled, indexName, objectID, logger]);
    return response;
}
export { useRelated };
