import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { ConnectContext } from 'src/contexts/ConnectContext';
/**
 * Resets connect filter whenever the pathname changes.
 */
const useResetConnectFilters = () => {
    const { pathname } = useLocation();
    const { resetConnectSelections } = useContext(ConnectContext);
    useEffect(() => {
        resetConnectSelections();
    }, [pathname, resetConnectSelections]);
};
export { useResetConnectFilters };
