import { COLORS, Markdown, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
const Heading = ({ children }) => {
    return (React.createElement(Typography, { marginBottom: "8px", variant: TypographyVariant.Body, color: COLORS.gray1 }, children));
};
const Paragraph = ({ children }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.Heading3 }, children));
};
const OVERRIDDEN_COMPONENTS = {
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    h5: Heading,
    h6: Heading,
    p: Paragraph,
};
export const MarkdownMetadataDisplay = ({ metadata }) => {
    return (React.createElement(Stack, { gap: "0px" },
        React.createElement(Markdown, { content: metadata, overrideComponents: OVERRIDDEN_COMPONENTS })));
};
/** Parsing that just returns an empty array if there is an error. */
export const checkedParse = (data) => {
    try {
        return JSON.parse(data);
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Could not parse JSON, ${String(e)}`);
        return [];
    }
};
