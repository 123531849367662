import { __awaiter } from "tslib";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLogger } from '../logger';
const useAnalyticsSessionIdentifiers = (services) => {
    const logger = useLogger(useAnalyticsSessionIdentifiers.name);
    const isMountedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [identifiers, setIdentifiers] = useState({});
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    });
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const serviceIdentifiers = {};
            for (const s of services) {
                try {
                    const identifier = yield s.getSessionIdentifier();
                    if (!identifier) {
                        continue;
                    }
                    serviceIdentifiers[s.name] = identifier;
                }
                catch (e) {
                    logger.error(e, { service: s.name });
                }
            }
            if (!isMountedRef.current) {
                // If we aren't mounted, don't run anything
                return;
            }
            setIdentifiers(serviceIdentifiers);
            setIsLoading(false);
        }))().catch((e) => logger.error(e));
    }, [logger, services]);
    return useMemo(() => ({
        isLoading,
        identifiers,
    }), [isLoading, identifiers]);
};
export { useAnalyticsSessionIdentifiers };
