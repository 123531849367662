import { Text, Link, Stack, Icons, COLORS, RankedStack, Typography, TypographyVariant, Spacer, Markdown, Block, } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useToggle } from 'src/hooks/toggle';
import { Locked } from '../Locked';
import { AttributeTooltip } from './ModelAttributeTooltip';
const DEFAULT_NOT_SPECIFIED_TEXT = 'Not Specified';
/**
 * Attaches all metadata to an attribute component.
 */
function attachAttributeMetadata(component, metadata) {
    const attributeComponent = component;
    attributeComponent.label = metadata.label;
    attributeComponent.info = metadata.info;
    attributeComponent.displayName = metadata.label.replace(' ', '');
    return attributeComponent;
}
/** Get the value of an attribute from an item. */
function getValue(definition, item) {
    return typeof definition.attribute === 'function'
        ? definition.attribute(item)
        : item[definition.attribute];
}
const checkNotSpecified = (value, notSpecifiedText) => {
    if (typeof value === 'number' || Array.isArray(value)) {
        return false;
    }
    return !!(value === undefined ||
        value === null ||
        value === '' ||
        value.toLowerCase() === notSpecifiedText.toLowerCase());
};
/**
 * Builds a ModelAttribute from a name of the attribute.
 * This assumes that the value will either be null, a string-like value
 * or a string array on each item. Each value is wrapped in a <Text> element.
 */
function buildStringModelAttribute(definition) {
    var _a;
    const notSpecifiedText = (_a = definition.notSpecifiedText) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const tooltip = definition.tooltip;
    const notSpecifiedTooltip = definition.notSpecifiedTooltip;
    const component = ({ item }) => {
        const value = getValue(definition, item);
        let displayValue = Array.isArray(value) ? value.join(' · ') : value;
        let valueTooltip = tooltip;
        const notSpecified = checkNotSpecified(displayValue, notSpecifiedText);
        if (notSpecified) {
            displayValue = notSpecifiedText;
            valueTooltip = notSpecifiedTooltip;
        }
        return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
            notSpecified ? (React.createElement(Typography, { color: COLORS.gray2 }, notSpecifiedText)) : (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, displayValue)),
            React.createElement(When, { condition: !!tooltip },
                React.createElement(Spacer, { size: "8px" }),
                React.createElement(AttributeTooltip, { tooltip: valueTooltip }))));
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Builds a ModelAttribute from a name of the attribute.
 * This assumes that the value will either be null, a string-like value
 * or a string array on each item. Each value is passed into <Markdown> element.
 */
function buildMarkdownModelAttribute(definition) {
    var _a;
    const notSpecifiedText = (_a = definition.notSpecifiedText) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const tooltip = definition.tooltip;
    const notSpecifiedTooltip = definition.notSpecifiedTooltip;
    const component = ({ item }) => {
        const value = getValue(definition, item);
        let displayValue = Array.isArray(value) ? value.join(' · ') : value;
        let valueTooltip = tooltip;
        const notSpecified = checkNotSpecified(displayValue, notSpecifiedText);
        if (notSpecified) {
            displayValue = notSpecifiedText;
            valueTooltip = notSpecifiedTooltip;
        }
        return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
            React.createElement(Block, null,
                React.createElement(If, { condition: notSpecified },
                    React.createElement(Then, null,
                        React.createElement(Typography, null, displayValue)),
                    React.createElement(Else, null,
                        React.createElement(Markdown, { content: String(displayValue) })))),
            React.createElement(When, { condition: !!tooltip },
                React.createElement(Spacer, { size: "8px" }),
                React.createElement(AttributeTooltip, { tooltip: valueTooltip }))));
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Builds a ModelAttribute from a name of the attribute.
 * This assumes that the value will either be null, a string-like value
 * or a string array on each item. Each value is wrapped in a <Text> element.
 */
function buildLinkModelAttribute(definition) {
    var _a;
    const notSpecifiedText = (_a = definition.notSpecifiedText) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const tooltip = definition.tooltip;
    const notSpecifiedTooltip = definition.notSpecifiedTooltip;
    const component = ({ item }) => {
        const value = getValue(definition, item);
        const notSpecified = checkNotSpecified(value, notSpecifiedText);
        if (notSpecified) {
            return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
                React.createElement(Typography, { color: COLORS.gray2 }, notSpecifiedText),
                React.createElement(When, { condition: !!notSpecifiedTooltip },
                    React.createElement(Spacer, { size: "8px" }),
                    React.createElement(AttributeTooltip, { tooltip: notSpecifiedTooltip }))));
        }
        if (typeof value !== 'string') {
            return React.createElement(Text, null, value);
        }
        return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
            React.createElement(Link, { href: value }, definition.linkText ? definition.linkText(item) : 'Available Here'),
            React.createElement(When, { condition: !!tooltip },
                React.createElement(Spacer, { size: "8px" }),
                React.createElement(AttributeTooltip, { tooltip: tooltip }))));
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Renders a boolean like value.
 *
 * Boolean like values have 3 states instead of the traditional states
 *
 * - Yes
 * - No
 * - Partial
 *
 * This will render the raw value of the attribute along with an informative
 * icon.
 */
function buildBooleanishModelAttribute(definition) {
    var _a;
    const notSpecifiedText = (_a = definition.notSpecifiedText) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const tooltip = definition.tooltip;
    const notSpecifiedTooltip = definition.notSpecifiedTooltip;
    const component = ({ item }) => {
        const value = getValue(definition, item);
        if (!value) {
            return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
                React.createElement(Typography, { color: COLORS.gray2 }, notSpecifiedText),
                React.createElement(When, { condition: !!notSpecifiedTooltip },
                    React.createElement(Spacer, { size: "8px" }),
                    React.createElement(AttributeTooltip, { tooltip: notSpecifiedTooltip }))));
        }
        const icon = value === 'Yes' ? (React.createElement(Icons.Product.Yes, { size: "24px", title: "Yes" })) : value === 'No' ? (React.createElement(Icons.Product.No, { size: "24px", title: "No" })) : (React.createElement(Icons.Product.Limited, { size: "24px", title: "Limited" }));
        return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: '0px' },
            icon,
            React.createElement(Spacer, { size: "8px" }),
            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, value),
            React.createElement(When, { condition: !!tooltip },
                React.createElement(Spacer, { size: "8px" }),
                React.createElement(AttributeTooltip, { tooltip: tooltip }))));
    };
    return attachAttributeMetadata(component, definition);
}
/** Build a model attribute that renders as a list. */
function buildListAttribute(definition) {
    var _a;
    const notSpecifiedText = (_a = definition.notSpecifiedText) !== null && _a !== void 0 ? _a : DEFAULT_NOT_SPECIFIED_TEXT;
    const component = ({ item }) => {
        const value = getValue(definition, item);
        if (typeof value === 'number') {
            return React.createElement(Text, null, value);
        }
        if (checkNotSpecified(value, notSpecifiedText)) {
            return React.createElement(Text, { color: COLORS.gray2 }, notSpecifiedText);
        }
        const listItems = Array.isArray(value) ? value : value === null || value === void 0 ? void 0 : value.trim().split('\n');
        return (React.createElement("ul", { style: { paddingInlineStart: '20px' } }, listItems === null || listItems === void 0 ? void 0 : listItems.map((x) => (React.createElement("li", { key: x },
            React.createElement(Text, null, x))))));
    };
    return attachAttributeMetadata(component, definition);
}
/**
 * Renders a value against a mapping.
 */
function buildMappedModelAttribute(definition) {
    var _a, _b;
    const notSpecifiedIcon = (_a = definition.notSpecifiedIcon) !== null && _a !== void 0 ? _a : (React.createElement(Icons.Misc.Error, { color: COLORS.gray2, size: '24px', decorative: true }));
    const notSpecifiedText = (_b = definition.notSpecifiedText) !== null && _b !== void 0 ? _b : DEFAULT_NOT_SPECIFIED_TEXT;
    const tooltip = definition.tooltip;
    const notSpecifiedTooltip = definition.notSpecifiedTooltip;
    const component = ({ item }) => {
        var _a, _b, _c;
        const value = getValue(definition, item);
        const mappedValue = !value || !(value in definition.mappings)
            ? {
                icon: notSpecifiedIcon,
                text: notSpecifiedText,
                tooltip: notSpecifiedTooltip,
            }
            : definition.mappings[value];
        return (React.createElement(Block, { display: "flex", alignItems: "center" },
            React.createElement(Block, null, (_a = mappedValue.icon) !== null && _a !== void 0 ? _a : (React.createElement(Icons.Misc.Error, { color: COLORS.gray2, size: '24px', decorative: true }))),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Text, null, mappedValue.text),
            React.createElement(When, { condition: !!((_b = mappedValue.tooltip) !== null && _b !== void 0 ? _b : tooltip) },
                React.createElement(Spacer, { size: "8px" }),
                React.createElement(AttributeTooltip, { tooltip: (_c = mappedValue.tooltip) !== null && _c !== void 0 ? _c : tooltip }))));
    };
    return attachAttributeMetadata(component, definition);
}
function buildRankedStackModelAttribute(definition) {
    const component = ({ item }) => {
        const value = getValue(definition, item);
        if (!(value in definition.mappings)) {
            return (React.createElement(RankedStack, { values: [
                    {
                        label: (React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.black }, value)),
                        backgroundColor: COLORS.gray3,
                    },
                ] }));
        }
        const values = Object.entries(definition.mappings).map(([mappingValue, { label, backgroundColor }]) => ({
            label: (React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: mappingValue === value ? COLORS.black : COLORS.gray1 }, label)),
            backgroundColor: mappingValue === value ? backgroundColor : COLORS.gray4,
        }));
        return React.createElement(RankedStack, { values: values, isSegmented: true });
    };
    return attachAttributeMetadata(component, definition);
}
function buildLockableAttribute({ attribute, resource, action, }) {
    const WrappedModelAttribute = attribute;
    const Component = ({ item }) => {
        const hasPermission = useHasPermission(resource, action, item);
        if (!hasPermission) {
            return React.createElement(Locked, { color: COLORS.gray1 });
        }
        return React.createElement(WrappedModelAttribute, { item: item });
    };
    return attachAttributeMetadata(Component, WrappedModelAttribute);
}
function buildToggleBasedAttribute({ hasToggleAttribute, noToggleAttribute, toggle, }) {
    const HasToggleAttribute = hasToggleAttribute;
    const NoToggleAttribute = noToggleAttribute;
    const Component = ({ item }) => {
        const hasToggle = useToggle(toggle);
        if (hasToggle) {
            return React.createElement(HasToggleAttribute, { item: item });
        }
        return React.createElement(NoToggleAttribute, { item: item });
    };
    return attachAttributeMetadata(Component, HasToggleAttribute);
}
function buildPermissionBasedAttribute({ hasPermissionAttribute, noPermissionAttribute, resource, action, }) {
    const HasPermissionAttribute = hasPermissionAttribute;
    const NoPermissionAttribute = noPermissionAttribute;
    const Component = ({ item }) => {
        const hasPermission = useHasPermission(resource, action, item);
        if (hasPermission) {
            return React.createElement(HasPermissionAttribute, { item: item });
        }
        return React.createElement(NoPermissionAttribute, { item: item });
    };
    return attachAttributeMetadata(Component, HasPermissionAttribute);
}
export { buildStringModelAttribute, buildLinkModelAttribute, attachAttributeMetadata, buildBooleanishModelAttribute, buildMappedModelAttribute, buildRankedStackModelAttribute, buildListAttribute, buildLockableAttribute, buildToggleBasedAttribute, buildPermissionBasedAttribute, buildMarkdownModelAttribute, DEFAULT_NOT_SPECIFIED_TEXT, };
