import { Block, Button, Card, Image, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { AuthResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { QueryWrapper } from 'src/components/QueryWrapper';
const DisplayMFASecret = ({ data: { secret, qrCode } }) => {
    return (React.createElement(Block, null,
        React.createElement(Image, { width: "350px", height: "350px", src: qrCode, alt: "" }),
        React.createElement(Typography, null,
            "Secret: ",
            secret)));
};
const MFAEnroll = () => {
    const { data, mutate } = useElektraApiMutation(AuthResource.mfaEnroll());
    return (React.createElement(Block, null,
        React.createElement(Typography, null, "To enroll in MFA use the button below and save the resulting data to your authenticator app."),
        data ? (React.createElement(DisplayMFASecret, { data: data === null || data === void 0 ? void 0 : data.factor })) : (React.createElement(Button, { onClick: () => mutate({}) }, "Enroll in MFA"))));
};
const MFAStatus = ({ data }) => {
    if (data.enrolled) {
        return (React.createElement(Typography, null, "You currently have MFA enabled. If you need help reseting your MFA please reach out to support."));
    }
    else {
        return React.createElement(MFAEnroll, null);
    }
};
export const MFA = () => {
    const result = useElektraApi(AuthResource.mfaStatus());
    return (React.createElement(Card, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: '24px' }, "MFA Configuration"),
        React.createElement(QueryWrapper, Object.assign({ renderSuccess: MFAStatus }, result))));
};
