export const getVendorTypes = (organization) => {
    if (!organization.type) {
        return [];
    }
    const types = [];
    if (organization.type.includes('Maker')) {
        types.push('Technology Developer');
    }
    if (organization.type.includes('Integrator - Active') ||
        organization.type.includes('Integrator - Inactive') ||
        organization.type.includes('Integrator - In Progress')) {
        types.push('Data Integrator');
    }
    if (organization.type.includes('Data Science and Analytics')) {
        types.push('Data Science & Analytics');
    }
    return types;
};
export const isIntegratorAlgorithmUtil = (tool) => { var _a; return (_a = tool.sbcSubtype) === null || _a === void 0 ? void 0 : _a.includes('7 - SBC - Integrator Algorithm'); };
