import React, { useMemo } from 'react';
import { Block } from '@humanfirst/elektron';
const LOOM_BASE_URL = 'https://loom.com/';
const IFRAME_STYLES = {
    borderStyle: 'none',
    width: '100%',
    height: '45vi',
};
const LoomEmbed = ({ id, width, height }) => {
    const iframeUrl = useMemo(() => {
        const loomUrl = new URL(`/embed/${encodeURIComponent(id)}`, LOOM_BASE_URL);
        loomUrl.searchParams.set('hide_owner', 'true');
        loomUrl.searchParams.set('hideEmbedTopBar', 'true');
        loomUrl.searchParams.set('hide_share', 'true');
        loomUrl.searchParams.set('hide_title', 'true');
        // Undocumented features gleamed from their embed javascript code
        loomUrl.searchParams.set('show_powered_by_loom', 'false');
        loomUrl.searchParams.set('minimal_player', 'true');
        return loomUrl.toString();
    }, [id]);
    return (React.createElement(Block, { width: width, height: height, position: "relative" },
        React.createElement("iframe", { "data-testid": "loom-embed", src: iframeUrl, allowFullScreen: true, style: IFRAME_STYLES })));
};
export { LoomEmbed };
