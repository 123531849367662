import { Card, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { checkedParse } from './shared';
export const MedicalConditionCard = ({ metadata }) => {
    const relevantMetadata = getTeamMetadata(metadata, 'Medical Conditions');
    if (!relevantMetadata) {
        return null;
    }
    const parsedMCs = checkedParse(relevantMetadata);
    if (!parsedMCs || typeof parsedMCs !== 'object' || !parsedMCs.length) {
        return null;
    }
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: "0" }, 'Medical Conditions')),
            React.createElement(Stack, null, parsedMCs.map((mc) => {
                if (mc.id) {
                    return (React.createElement(Link, { key: mc.id, href: generatePath(PATHS.atlas.medicalConditions.detail, {
                            id: mc.id,
                        }) }, mc.name));
                }
                return React.createElement(Typography, { key: mc.name }, mc.name);
            })))));
};
