import { __awaiter } from "tslib";
import { WorkspaceResource, useElektraApiRaw, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useAnalyticsEvent } from '../analytics';
import { WORKSPACE_UPLOAD_DOMAIN } from './constants';
import { useSetUploadStatus } from './upload-status';
/** Sanitize a file name. */
const sanitizeName = (name) => encodeURIComponent(name);
/** Uploads a file to a given workspace. */
export const useUploadFile = (workspaceId) => {
    const raw = useElektraApiRaw();
    const track = useAnalyticsEvent();
    const { mutate: mutateUploadStatus } = useSetUploadStatus(workspaceId);
    const { mutateAsync: createProjectEvent } = useElektraApiMutation(WorkspaceResource.uploadFile(workspaceId));
    return {
        mutate: (file) => __awaiter(void 0, void 0, void 0, function* () {
            const trackParameters = {
                name: file.name,
                type: file.type,
                size: file.size,
                workspaceId,
            };
            // Step 1: Get a token for crafting this upload.
            // Note that we do this at mutate time instead of ahead of time so we don't have to handle
            // cases where the token is still loading and we try to make a mutate call. This way the
            // dependencies are serialized.
            const { token } = yield raw(WorkspaceResource.uploadToken(workspaceId, 'create'));
            // Step 2, make the fetch.
            try {
                const url = `${WORKSPACE_UPLOAD_DOMAIN}/workspaces/${workspaceId}/uploads/${sanitizeName(file.name)}`;
                track('file-upload-started', trackParameters);
                yield fetch(`${url}?jwt=${token}`, {
                    headers: {
                        'Content-Disposition': `attachment; filename=${encodeURIComponent(file.name)}`,
                    },
                    method: 'PUT',
                    body: file,
                });
                track('file-upload-complete', Object.assign(Object.assign({}, trackParameters), { url }));
                yield createProjectEvent({
                    fileName: file.name,
                });
                // construct the key so that we can add upload status to the file
                const key = `workspaces/${workspaceId}/uploads/${sanitizeName(file.name)}`;
                yield mutateUploadStatus(key, 'pending');
            }
            catch (e) {
                track('file-upload-error', Object.assign(Object.assign({}, trackParameters), { error: String(e) }));
                // Rethrow for higher levels of error handling.
                throw e;
            }
        }),
    };
};
