import React from 'react';
import { Block, COLORS, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import dayjs from 'dayjs';
const CUSTOM_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const BoldWrapper = ({ children }) => {
    return (React.createElement(Typography, { component: "span", variant: TypographyVariant.BodyBold }, children));
};
const SupportingTextWrapper = ({ children, }) => {
    return (React.createElement(Typography, { component: "span", color: COLORS.gray1 }, children));
};
const TimestampWrapper = ({ children, timestamp }) => {
    return (React.createElement(Stack, { gap: "8px", direction: "horizontal" },
        React.createElement("span", null,
            React.createElement(Block, { width: "144px" },
                React.createElement(Typography, { component: "span", color: COLORS.gray2 }, dayjs(timestamp).format(CUSTOM_DATE_FORMAT)))),
        React.createElement("span", null, children)));
};
export { TimestampWrapper, SupportingTextWrapper, BoldWrapper };
