import React from 'react';
import { useToggle } from 'src/hooks/toggle';
const IfToggle = ({ name, children, }) => {
    const isToggle = useToggle(name);
    return isToggle ? React.createElement(React.Fragment, null, children) : null;
};
const IfNotToggle = ({ name, children, }) => {
    const isToggle = useToggle(name);
    return !isToggle ? React.createElement(React.Fragment, null, children) : null;
};
export { IfToggle, IfNotToggle };
