import React, { useMemo } from 'react';
import { Stack } from '@humanfirst/elektron';
import { When } from 'react-if';
import { MultiBarChart } from 'src/components/Charts';
import { ExportButton } from './ExportButton';
export const MarkdownCodeExtensionBarChartGrouped = ({ header, records, groupKey, itemKey, valueKey, enableExport = false, }) => {
    const groupColumn = typeof groupKey === 'string' ? header.indexOf(groupKey) : 0;
    const itemColumn = typeof itemKey === 'string' ? header.indexOf(itemKey) : groupColumn + 1;
    const valueColumn = typeof valueKey === 'string' ? header.indexOf(valueKey) : itemColumn + 1;
    const data = useMemo(() => records.map((row) => ({
        x: row[groupColumn],
        item: row[itemColumn],
        y: parseInt(row[valueColumn]),
    })), [records, itemColumn, groupColumn, valueColumn]);
    return (React.createElement(Stack, { gap: "0px" },
        React.createElement(When, { condition: enableExport },
            React.createElement(Stack, { direction: "horizontal", justifyContent: "end" },
                React.createElement(ExportButton, { header: header, records: records }))),
        React.createElement(MultiBarChart, { data: data })));
};
