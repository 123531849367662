import { __rest } from "tslib";
import { StatefulTabs } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { Else, If, Then } from 'react-if';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { SearchAndPlanSection } from './SearchAndPlanSection';
import { WorkspaceBookmarkActions } from './components/Actions/Actions';
import { CommentsColumn } from './components/CommentsColumn';
import { OtherListsColumn } from './components/OtherListsColumn';
import { SearchAndPlanEmptySection } from './components/SearchAndPlanEmptySection';
import { SECTIONS, SECTIONS_WITH_STUDIES } from './sections';
import { filterBookmarks } from './utils';
const EXTRA_COLUMNS = [OtherListsColumn, CommentsColumn];
const SearchAndPlanSectionsList = ({ bookmarks, isArchive, isList, }) => {
    const hasStudiesInProjects = useHasPermission('connect', 'read', 'all');
    const correctSections = hasStudiesInProjects
        ? SECTIONS_WITH_STUDIES
        : SECTIONS;
    const sectionsToDisplay = useMemo(() => {
        return correctSections.reduce((acc, section) => {
            const filteredBookmarks = filterBookmarks(bookmarks, section);
            if (filteredBookmarks.length) {
                acc.push(Object.assign(Object.assign({}, section), { filteredBookmarks }));
            }
            return acc;
        }, []);
    }, [bookmarks, correctSections]);
    return (React.createElement(If, { condition: !sectionsToDisplay.length },
        React.createElement(Then, null,
            React.createElement(SearchAndPlanEmptySection, { variant: isArchive ? 'archive' : undefined })),
        React.createElement(Else, null,
            React.createElement(StatefulTabs, { variant: "toggle" }, sectionsToDisplay.map((definition) => {
                const { filteredBookmarks } = definition, original = __rest(definition, ["filteredBookmarks"]);
                return (React.createElement(StatefulTabs.TabPanel, { title: original.title, key: original.title },
                    React.createElement(SearchAndPlanSection, { bookmarks: filteredBookmarks, definition: original, extraColumns: EXTRA_COLUMNS, extraActions: React.createElement(WorkspaceBookmarkActions, { isArchive: !!isArchive, isList: !!isList }) })));
            })))));
};
export { SearchAndPlanSectionsList };
