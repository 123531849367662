import React from 'react';
import { Spread, Stack, Tag, Text, Icons } from '@humanfirst/elektron';
import { When } from 'react-if';
import { Locked } from 'src/components/Locked';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getQuery, getTypeDisplay } from './utils';
const MaybeLock = ({ option }) => {
    const canViewMedicalCondition = useHasPermission('medicalConditions', 'read', { id: option.id });
    const canViewMeasure = useHasPermission('measure', 'read', {
        id: option.id,
        category: option.query,
    });
    if ((option.type === 'measure' && !canViewMeasure) ||
        (option.type === 'medicalCondition' && !canViewMedicalCondition)) {
        return React.createElement(Locked, null);
    }
    return null;
};
const OmnisearchOptionNode = ({ option }) => {
    return (React.createElement(Spread, { direction: "horizontal", alignItems: "center", width: "100%", padding: "16px 24px" },
        React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
            React.createElement(When, { condition: option.type === 'searchAll' },
                React.createElement(Icons.Misc.Search, { decorative: true })),
            React.createElement(MaybeLock, { option: option }),
            React.createElement(Text, null, getQuery(option)),
            React.createElement(Tag, { variant: "outlined", closeable: false }, getTypeDisplay(option)))));
};
export { getTypeDisplay, OmnisearchOptionNode };
