import React from 'react';
import { COLORS, Icons } from '@humanfirst/elektron';
const EMOJI_SCHEME_PATTERN = /^emoji:\/\/(?<name>[^#\/ ?]+)/;
const EMOJI_ICONS = {
    notebook: React.createElement(Icons.Navigation.Reports, { active: true, title: "Notebook" }),
    mail: React.createElement(Icons.Misc.Mail, { title: "Mail" }),
    error: React.createElement(Icons.Misc.Error, { title: "Error" }),
    help: React.createElement(Icons.Misc.Help, { color: COLORS.primary, title: "Help" }),
};
const MarkdownMediaExtensionEmoji = ({ src, alt }) => {
    const emoji = src.replace(EMOJI_SCHEME_PATTERN, '$1');
    if (emoji in EMOJI_ICONS) {
        return React.createElement(React.Fragment, null, EMOJI_ICONS[emoji]);
    }
    return React.createElement(React.Fragment, null, alt);
};
export { MarkdownMediaExtensionEmoji };
