import React from 'react';
import { useGetEvidenceForMeasureCategory, useGetToolsForMeasureCategory, } from 'src/hooks/relatedResources';
import { TopTechnologyCard } from 'src/components/TopTechnologyCard';
import { t } from 'src/constants/i18n';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const MeasureCategoryTopTechnology = ({ category, showEvidence }) => {
    const { data: tools, isLoading: isToolsLoading } = useGetToolsForMeasureCategory(category);
    const { data: evidence, isLoading: isEvidenceLoading } = useGetEvidenceForMeasureCategory(category);
    const hasEvidencePermission = useHasPermission('measure.evidence', 'read', category);
    return (React.createElement(TopTechnologyCard, { tooltip: t('measureDetailPage.topTechnologyToolTip'), tools: tools !== null && tools !== void 0 ? tools : [], evidence: evidence !== null && evidence !== void 0 ? evidence : [], isLoading: isToolsLoading || isEvidenceLoading, hasEvidencePermission: hasEvidencePermission, showEvidence: showEvidence }));
};
export { MeasureCategoryTopTechnology };
