import { __rest } from "tslib";
import React from 'react';
import { DataTable, Center, Typography, } from '@humanfirst/elektron';
import { useCustomizedSchema } from 'src/components/tables/hooks/use-customized-schema';
import { useDataTableUrlMemory } from 'src/components/tables/hooks/use-data-table-url-memory';
import { useToggle } from 'src/hooks/toggle';
import { MEASURES_FILTER, MEDICAL_CONDITION_FILTER } from './filters';
import { useColumns } from './columns';
const BASE_FILTERS = [MEASURES_FILTER, MEDICAL_CONDITION_FILTER];
/** Generates the schema for org's technology table. */
const useBaseSchema = () => {
    const configurableColumns = useToggle('custom-columns');
    return {
        columns: useColumns(),
        configurableColumns,
        filters: BASE_FILTERS,
        sortable: true,
    };
};
const DEFAULT_INITIAL_STATE = { sort: { column: 'product', ascending: true } };
const OrganizationToolTable = (_a) => {
    var { data } = _a, props = __rest(_a, ["data"]);
    const schema = useBaseSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, data, DEFAULT_INITIAL_STATE);
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "technologies", emptyElement: React.createElement(Center, null,
            React.createElement(Typography, null, "No Technologies")) })));
};
export { OrganizationToolTable };
