import React from 'react';
import { Typography, TypographyVariant, Tag, Link, COLORS, } from '@humanfirst/elektron';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useActiveWorkspaceId } from '../../hooks/workspace';
const TitleCell = ({ item, admin }) => {
    const workspaceId = useActiveWorkspaceId();
    return (React.createElement(Link, { href: generatePath(admin ? PATHS.admin.reports.edit : PATHS.projects.reports.view, {
            id: item.id,
            projectId: workspaceId,
        }) }, item.title));
};
const getReportDisplayType = (type) => {
    switch (type) {
        case 'measure':
            return 'Curated Evidence';
        case 'product':
            return 'Comparison';
        case 'presentation':
            return 'Insights Summary';
    }
};
const ReportTypeCell = ({ item, }) => {
    if (!item.type) {
        return null;
    }
    const type = getReportDisplayType(item.type);
    return (React.createElement(Tag, { closeable: false, color: COLORS.gray3, title: type },
        React.createElement(Typography, { variant: TypographyVariant.DetailsBold, singleLine: true }, type)));
};
export { TitleCell, ReportTypeCell };
