import { FilterableFieldType, } from '@humanfirst/elektron';
import { getVendorTypes } from 'src/utils/vendorType';
import { getCategories } from 'src/utils/filters';
const VENDOR_TYPE_FILTER = {
    label: 'Vendor Type',
    id: 'type',
    type: FilterableFieldType.MULTI_CATEGORICAL,
    categories: getCategories([
        'Data Integrator',
        'Data Science & Analytics',
        'Technology Developer',
    ]),
    getValue: (x) => { var _a; return (_a = getVendorTypes(x)) !== null && _a !== void 0 ? _a : []; },
    metadata: { priority: 'priority' },
};
export { VENDOR_TYPE_FILTER };
