import React, { useMemo } from 'react';
import { When } from 'react-if';
import { Block } from '@humanfirst/elektron';
import { serializeQuery } from 'src/utils/query';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
const SearchBookmarkButton = ({ search, filters, resourceType, scope }) => {
    const searchQuery = (search === null || search === void 0 ? void 0 : search.enable) ? search.search : undefined;
    const filtersList = (filters === null || filters === void 0 ? void 0 : filters.enable) ? filters.filters : undefined;
    const query = useMemo(() => serializeQuery({
        search: searchQuery,
        filters: [...(filtersList !== null && filtersList !== void 0 ? filtersList : []), ...(scope !== null && scope !== void 0 ? scope : [])],
    }), [scope, searchQuery, filtersList]);
    return (React.createElement(When, { condition: query.length > 0 },
        React.createElement(Block, null,
            React.createElement(AddToWorkspaceButton, { resourceId: query, resourceType: resourceType, buttonContent: "Save Search", variant: "button", body: 'Save this filtered search to a workspace to continue where you left off.' }))));
};
export { SearchBookmarkButton };
