import { CategoricalFilter } from '@humanfirst/elektron';
/**
 * Available filters for the studies table.
 *
 * Right now this focuses on the related fields, so requires the full
 * data model to be loaded.
 */
export const FILTERS = [
    CategoricalFilter('therapeuticArea', {
        label: 'Therapeutic Area',
        getValue: (x) => x.relationships.therapeuticAreas.map((x) => x.therapeuticArea),
        metadata: { priority: 'priority' },
    }),
    CategoricalFilter('medicalConditions', {
        label: 'Medical Conditions',
        getValue: (x) => x.relationships.medicalConditions.map((x) => x.name),
        metadata: { priority: 'priority' },
    }),
    CategoricalFilter('measures', {
        label: 'Measures',
        getValue: (x) => x.relationships.measureCategories.map((x) => x.category),
        metadata: { priority: 'priority' },
    }),
    CategoricalFilter('toolIds', {
        label: 'Technology IDs',
        getValue: (x) => x.relationships.tools.map((x) => x.id),
        metadata: { priority: 'hidden' },
    }),
    CategoricalFilter('techMaker', {
        label: 'Technology Maker IDs',
        getValue: (x) => x.relationships.tools.map((x) => { var _a, _b; return (_b = (_a = x.maker) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''; }),
        metadata: { priority: 'hidden' },
    }),
];
