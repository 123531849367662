import { useMemo } from 'react';
import { serializeFilters } from './filters';
import { useRawSearch } from './useRawSearch';
function serializeFacets(facets) {
    if (!facets) {
        return [];
    }
    const allFacets = [];
    for (const [key, value] of Object.entries(facets)) {
        allFacets.push({
            field: key,
            facets: Object.entries(value).map(([facetKey, facetValue]) => ({
                facet: facetKey,
                count: facetValue,
            })),
        });
    }
    return allFacets;
}
const useSearchFacets = ({ searchClient, indexName, query = '', filters, enabled, }) => {
    const options = useMemo(() => ({
        query,
        filters: serializeFilters(filters !== null && filters !== void 0 ? filters : []),
        facets: ['*'],
        length: 1,
    }), [query, filters]);
    const { isError, isLoading, error, response } = useRawSearch({
        searchClient,
        indexName,
        options,
        enabled,
    });
    return useMemo(() => ({
        isLoading,
        isError,
        error,
        facets: serializeFacets(response === null || response === void 0 ? void 0 : response.facets),
    }), [isLoading, isError, error, response]);
};
export { useSearchFacets };
