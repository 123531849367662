import React from 'react';
import { useToggle } from 'src/hooks/toggle';
import { AppliedResearchCriteria } from './AppliedResearchCriteria';
const WrappedScopeTable = ({ TableComponent, scope, extraActions, toggleScope, workspaceId, scopeApplied, }) => {
    const showProjectResearchInsights = useToggle('project-research-insights');
    const shouldApplyScope = !showProjectResearchInsights ? true : scopeApplied;
    return (React.createElement(React.Fragment, null,
        React.createElement(AppliedResearchCriteria, { workspaceId: workspaceId, onApplyResearchCriteria: toggleScope, researchCriteriaApplied: scopeApplied, scope: scope }),
        React.createElement(TableComponent, { scope: shouldApplyScope ? scope : [], extraActions: extraActions })));
};
export { WrappedScopeTable };
