import { Banner, Block, ButtonLink, Stack } from '@humanfirst/elektron';
import { StudyResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { When } from 'react-if';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { StudyTable } from 'src/components/tables/StudyTable';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { SAMPLE_STUDY } from 'src/constants/studies';
import { useShowModalBound } from 'src/hooks/modal';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useUser } from 'src/hooks/user';
import { generatePath } from 'src/utils/path';
export const StudiesInternal = ({ studies, }) => {
    var _a;
    const canCreateStudy = useHasPermission('study', 'create');
    const showModal = useShowModalBound('contact-us');
    const user = useUser();
    return (React.createElement(Stack, { gap: "24px" },
        React.createElement(When, { condition: canCreateStudy && !!((_a = user === null || user === void 0 ? void 0 : user.teams) === null || _a === void 0 ? void 0 : _a.length) },
            React.createElement(Stack, { alignItems: 'flex-end' },
                React.createElement(Block, null,
                    React.createElement(ButtonLink, { href: generatePath(PATHS.catalog.studies.create), size: "large" }, t('studies.list.createStudy'))))),
        React.createElement(When, { condition: studies.length === 1 && studies[0].id === 'demo' && !canCreateStudy },
            React.createElement(Banner, { variant: 'information', title: "Add your unique data to Atlas", content: "Contact our team to add your unique data to Atlas.", actionLabel: "Contact Us", onAction: showModal })),
        React.createElement(StudyTable, { data: studies })));
};
export const Studies = () => {
    var _a;
    const result = useElektraApi(StudyResource.list());
    const data = (_a = result.data) !== null && _a !== void 0 ? _a : [];
    const studies = data.length ? data : [SAMPLE_STUDY];
    return (React.createElement(QueryWrapper
    // Render the page as soon as studies have returned.
    // It is possible that no toolIds are present, so the second
    // api request may or may not execute. If toolIds are present,
    // the second api call will be rendered when the data arrives.
    , Object.assign({}, result, { renderSuccess: StudiesInternal, studies: studies })));
};
