/* istanbul ignore next */
/* eslint-disable */
import { redactSensitiveUrl } from '../utils';
/**
 * This snippet comes directly from Heap with some changes to make it compliant
 * with typecript. It is... not great. Don't @ me.
 */
const initialize = (appId, cdnUrl = 'https://cdn.heapanalytics.com', trackingUrl = 'https://heapanalytics.com') => {
    const anyWindow = window;
    anyWindow.heap = anyWindow.heap || [];
    const heap = anyWindow.heap;
    heap.load = function (e, t) {
        ;
        (anyWindow.heap.appid = e), (anyWindow.heap.config = t = t || {});
        var r = document.createElement('script');
        (r.type = 'text/javascript'),
            (r.async = !0),
            (r.src = cdnUrl + '/js/heap-' + e + '.js');
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
        for (var n = function (e) {
            return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
        }, p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
        ], o = 0; o < p.length; o++)
            heap[p[o]] = n(p[o]);
    };
    heap.load(appId, {
        trackingServer: trackingUrl,
        rewrite: (props) => {
            if (!!props.pageview_properties.query) {
                props.pageview_properties.query = redactSensitiveUrl(props.pageview_properties.query);
            }
            return props;
        },
    });
};
/* eslint-enable */
export { initialize };
