import React, { useMemo } from 'react';
import { Block } from '@humanfirst/elektron';
import { ResourceSummaryCard } from 'src/components/CatalogResourceDetails';
import { TopTechnologyCard } from 'src/components/TopTechnologyCard/TopTechnologyCard';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { t } from 'src/constants/i18n';
import { TopMeasuresCard } from './TopMeasuresCard';
import { TogglePMC } from './TogglePMC';
const DEFAULT_EVIDENCE = [];
const DEFAULT_TECH = [];
const MedicalConditionTopCards = ({ medicalCondition, showEvidence, filterEvidenceByPMC, setFilterEvidenceByPMC, tools = DEFAULT_TECH, evidence = DEFAULT_EVIDENCE, categoryWithToolsAndEvidence, isLoading, }) => {
    const hasAllEvidence = useHasAllEvidence();
    const statistics = useMemo(() => {
        var _a, _b, _c;
        return [
            {
                count: (_a = tools.length) !== null && _a !== void 0 ? _a : 0,
                label: 'Technologies',
                tooltip: t('medicalConditionPage.technologyToolTip'),
            },
            {
                count: (_b = categoryWithToolsAndEvidence === null || categoryWithToolsAndEvidence === void 0 ? void 0 : categoryWithToolsAndEvidence.length) !== null && _b !== void 0 ? _b : 0,
                label: 'Measures',
                tooltip: t('medicalConditionPage.measureToolTip'),
            },
            {
                count: (_c = evidence.length) !== null && _c !== void 0 ? _c : 0,
                label: 'Evidence',
                tooltip: t('medicalConditionPage.evidenceToolTip'),
            },
        ];
    }, [evidence === null || evidence === void 0 ? void 0 : evidence.length, categoryWithToolsAndEvidence === null || categoryWithToolsAndEvidence === void 0 ? void 0 : categoryWithToolsAndEvidence.length, tools === null || tools === void 0 ? void 0 : tools.length]);
    return (React.createElement(Block, { display: "grid", 
        // repeat(3, 1fr) doesn’t work here. The reason is that 1fr is about the space avaliable. It doesn’t prevent content that is larger than a track from overflowing.
        // 1fr isn’t guarenteed to be the smae as another 1fr for that reason. Below we say the MAXIMUM you can be is one fr of the space available. By default the maximum is auto.
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))", gridTemplateRows: "1fr", gap: "16px" },
        React.createElement(ResourceSummaryCard, { name: medicalCondition.name, subheadingContent: React.createElement(TogglePMC, { conditionName: medicalCondition.name, filterEvidenceByPMC: filterEvidenceByPMC, setFilterEvidenceByPMC: setFilterEvidenceByPMC }), resourceType: "MedicalCondition", id: medicalCondition.id, 
            // Null will show loading sate in resourcessummary card.
            statistics: isLoading ? null : statistics }),
        React.createElement(TopTechnologyCard, { tooltip: `${t('medicalConditionPage.topTechnologyToolTip')}${medicalCondition.name}.`, showEvidence: showEvidence, tools: tools, evidence: evidence, isLoading: isLoading, hasEvidencePermission: hasAllEvidence }),
        React.createElement(TopMeasuresCard, { condition: medicalCondition, showEvidence: showEvidence, categories: categoryWithToolsAndEvidence, isLoading: isLoading, hasEvidencePermission: hasAllEvidence })));
};
export { MedicalConditionTopCards };
