/** Encode a state object for placing in a URL. */
const encodeForUrl = (state) => {
    return encodeURIComponent(JSON.stringify(state));
};
/** Decode a state object from a URL */
const decodeFromUrl = (state) => {
    if (!state) {
        return null;
    }
    try {
        return JSON.parse(decodeURIComponent(state));
    }
    catch (_a) {
        return null;
    }
};
export { encodeForUrl, decodeFromUrl };
