import { __rest } from "tslib";
import React, { useCallback, useContext, useState } from 'react';
import { Block, COLORS, Checkbox, Icons, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { Else, If, Then, When } from 'react-if';
import { useHasPermissions } from 'src/hooks/permissions/useHasPermissions';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { getAttributesForComponentType } from 'src/components/ModelAttribute/ToolAttribute';
import { isPartiallyIngested } from 'src/pages/Tool/utils/selectors';
import { MeasureTag } from 'src/components/MeasureTag';
import { MeasureTagButton } from 'src/components/MeasurementTree/MeasurementTree';
import { TargetTagContext } from 'src/components/MeasurementTree/contexts/TargetTagContext';
import { SharedAttributeContext, } from 'src/contexts/SharedAttributeContext';
const ATTRIBUTE_SHARING_ENABLED = new Set([
    'Required Hardware',
    'Sensor Modality',
    'Wear Location',
    'Connectivity',
    'Compatible Operating System',
    'Form Factor',
]);
const ATTRIBUTE_SHARING_ENABLED_MAP = {
    'Required Hardware': 'requiredHardwareSBC',
    'Sensor Modality': 'modalitiesSensorType',
    'Wear Location': 'wearLocation',
    Connectivity: 'dataExchangeModalities',
    'Compatible Operating System': 'compatibleOperatingSystem',
    'Form Factor': 'formFactor',
};
const CompareRow = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(Block, Object.assign({ minWidth: "100%", backgroundColor: COLORS.white, overrides: {
            Block: {
                style: {
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: COLORS.gray3,
                },
            },
        } }, props),
        React.createElement(Block, { display: "flex", flexDirection: 'row' }, children)));
};
function CompareCell({ tool, Attribute, heading, disabled }) {
    const itemAttributes = getAttributesForComponentType(tool).flatMap((x) => x[0].label);
    if (isPartiallyIngested(tool) &&
        (heading === 'Technical Specifications' ||
            heading === 'Price & Availability')) {
        return React.createElement(CompareCellContent, { disabled: disabled }, "--");
    }
    if (heading === 'Technical Specifications') {
        if (itemAttributes.includes(Attribute.label)) {
            return (React.createElement(CompareCellContent, { disabled: disabled },
                React.createElement(Attribute, { item: tool })));
        }
        else {
            return (React.createElement(CompareCellContent, { disabled: disabled },
                React.createElement(Typography, { color: COLORS.gray2 }, "N/A")));
        }
    }
    return (React.createElement(CompareCellContent, { disabled: disabled },
        React.createElement(Attribute, { item: tool })));
}
const CompareCellContent = (_a) => {
    var { children, disabled, color = COLORS.white, isHeader } = _a, props = __rest(_a, ["children", "disabled", "color", "isHeader"]);
    return (React.createElement(Block, Object.assign({ flex: `${isHeader ? '0' : '1'} 0`, display: "flex", padding: "16px 32px", minWidth: '296px', backgroundColor: color, 
        // Anything that exceeds the width of the cell will be hidden.
        // This combined with minWidth: 0 on children helps manage long strings of text. Giving us
        // the ability to truncated instead of causing layout issues.
        overflow: "hidden", overrides: {
            Block: {
                style: {
                    cursor: props.onClick ? 'pointer' : undefined,
                    overflowWrap: 'anywhere',
                },
            },
        } }, props),
        React.createElement(Block, { display: "flex", flex: "1", 
            // There is alot of nesting going on here, we need to set min width at this level as well.
            // Without it, children won't be able to shrink past it's content (containing the link).
            // If this happens, any width setting we do for the truncation below won't have any effect.
            minWidth: 0, overrides: { Block: { style: { opacity: disabled ? 0.5 : 1 } } } }, children)));
};
const CompareHeading = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(CompareCellContent, Object.assign({ isHeader: true, backgroundColor: COLORS.gray4, position: "sticky", left: "0px" }, props), children));
};
const CompareSectionHeader = ({ heading, description, items, onToggle, isOpen, disabled, minHeight, }) => {
    return (React.createElement(CompareRow, { backgroundColor: COLORS.gray4, minHeight: minHeight },
        React.createElement(CompareHeading, { onClick: onToggle, disabled: disabled },
            React.createElement(Stack, { gap: "0", flex: "1", width: "100%" },
                React.createElement(Spread, null,
                    React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, heading),
                    React.createElement(Block, { paddingLeft: "8px" },
                        React.createElement(Typography, { variant: TypographyVariant.LabelBold },
                            React.createElement(If, { condition: isOpen },
                                React.createElement(Then, null,
                                    React.createElement(Icons.Misc.ChevronUp, { size: "2em", title: "Collapse" })),
                                React.createElement(Else, null,
                                    React.createElement(Icons.Misc.ChevronDown, { size: "2em", title: "Expand" })))))),
                React.createElement(When, { condition: !!description },
                    React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1 }, description)))),
        items.map(({ id }) => (React.createElement(CompareCellContent, { key: id, disabled: disabled, onClick: onToggle })))));
};
const MeasuresSectionLegend = ({ toggleHighlight, targetTag }) => (React.createElement(Stack, null,
    React.createElement(MeasureTagButton, { tag: 'shared', highlight: targetTag, toggle: toggleHighlight, variant: "toggle" }),
    React.createElement(MeasureTagButton, { tag: 'evidence', highlight: targetTag, toggle: toggleHighlight, variant: "toggle" }),
    React.createElement(MeasureTagButton, { tag: 'manufacturer', highlight: targetTag, toggle: toggleHighlight, variant: "toggle" }),
    React.createElement(MeasureTagButton, { tag: 'fda', highlight: targetTag, toggle: toggleHighlight, variant: "toggle" })));
const SharedAttributeSelection = ({ label }) => {
    const { toggleSharedHighlight, highlighted } = useContext(SharedAttributeContext);
    if (!label) {
        return null;
    }
    return (React.createElement(Checkbox, { checkmarkType: "toggle", checked: highlighted === ATTRIBUTE_SHARING_ENABLED_MAP[label], onChange: () => toggleSharedHighlight(ATTRIBUTE_SHARING_ENABLED_MAP[label]), labelPlacement: "right" },
        React.createElement(MeasureTag, { tag: 'shared' })));
};
/**
 * Renders a single section of compare (including heading and values).
 */
function CompareSection({ heading, description, attributes, items, disabled, }) {
    const [isOpen, setIsOpen] = useState(true);
    const [targetTag, setTargetTag] = useState();
    const toggleOpen = useCallback(() => setIsOpen((o) => !o), [setIsOpen]);
    const toggleHighlight = useCallback((tag) => {
        setTargetTag((h) => {
            if (h === tag) {
                return undefined;
            }
            return tag;
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TargetTagContext.Provider, { value: { targetTag, assignTargetTag: toggleHighlight } },
            React.createElement(CompareSectionHeader, { items: items, heading: heading, description: description, isOpen: isOpen, onToggle: toggleOpen, disabled: disabled }),
            React.createElement(When, { condition: isOpen }, attributes.map((Attribute) => {
                var _a;
                return (React.createElement(CompareRow, { key: Attribute.label },
                    React.createElement(CompareHeading, { disabled: disabled },
                        React.createElement(Stack, null,
                            React.createElement(Stack, { direction: "horizontal" },
                                React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.gray1 }, Attribute.label),
                                Attribute.info ? (React.createElement(AnchoredTooltip, null, Attribute.info)) : null),
                            React.createElement(When, { condition: Attribute.label === 'Measures' },
                                React.createElement(MeasuresSectionLegend, { targetTag: targetTag, toggleHighlight: toggleHighlight })),
                            React.createElement(When, { condition: ATTRIBUTE_SHARING_ENABLED.has((_a = Attribute === null || Attribute === void 0 ? void 0 : Attribute.label) !== null && _a !== void 0 ? _a : '') },
                                React.createElement(SharedAttributeSelection, { label: Attribute === null || Attribute === void 0 ? void 0 : Attribute.label })))),
                    items.map((item) => (React.createElement(CompareCell, { key: item.id, tool: item, Attribute: Attribute, disabled: disabled, heading: heading })))));
            })))));
}
function LockableCompareSection(_a) {
    var { resource, action, heading, lockedHeading, description, lockedDescription, items } = _a, props = __rest(_a, ["resource", "action", "heading", "lockedHeading", "description", "lockedDescription", "items"]);
    const hasPermission = useHasPermissions(items.map((item) => ({
        resource,
        action,
        extra: item,
    })));
    if (!hasPermission) {
        heading = lockedHeading !== null && lockedHeading !== void 0 ? lockedHeading : heading;
        description = lockedDescription !== null && lockedDescription !== void 0 ? lockedDescription : description;
    }
    return (React.createElement(CompareSection, Object.assign({ heading: heading, description: description, items: items }, props)));
}
export { LockableCompareSection, CompareSection, CompareHeading, CompareCellContent, CompareRow, };
