import { CardContent, CardSurface, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ScopedRankedEvidenceBars } from 'src/components/RankedEvidence';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
export const VendorEvidenceBreakdown = ({ organization }) => {
    // Technically a user could have access to all of the evidence for these tools individually,
    // but we're just going to make a generic check
    const hasEvidenceSummaryAccess = useHasPermission('component.evidenceSummary', 'read');
    return (React.createElement(CardSurface, null,
        React.createElement(CardContent, null,
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Vendor Evidence Breakdown"),
                React.createElement(ScopedRankedEvidenceBars, { scope: [['maker', '$cont', organization.id]], shouldShowLocked: !hasEvidenceSummaryAccess })))));
};
