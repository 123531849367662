import React from 'react';
import { Navigate } from 'react-router-dom';
import { Catalog } from 'src/pages/Catalog/Catalog';
import { Compare } from 'src/pages/Compare';
import { Settings } from 'src/pages/Settings';
import { EditStudy } from 'src/pages/EditStudy';
import { Study } from 'src/pages/Study';
import { NotFound } from 'src/pages/NotFound';
import { TeamsList } from 'src/pages/Teams/TeamsList';
import { TeamDetail } from 'src/pages/Teams/TeamDetail';
import { Contact } from 'src/pages/Contact';
import { LegacyRedirect } from './LegacyRedirect';
export const CommonRoutes = {
    children: {
        index: {
            index: true,
            element: React.createElement(Navigate, { replace: true, to: "atlas" }),
        },
        contact: {
            path: 'contact',
            element: React.createElement(Contact, null),
        },
        settings: {
            path: 'settings',
            element: React.createElement(Settings, null),
        },
        compare: {
            path: 'compare',
            element: React.createElement(Compare, null),
        },
        studies: {
            path: 'catalog/studies',
            children: {
                index: {
                    index: true,
                    element: React.createElement(Catalog, { view: 0 }),
                },
                create: {
                    path: 'create',
                    element: React.createElement(EditStudy, null),
                },
                view: {
                    path: ':id',
                    children: {
                        index: { index: true, element: React.createElement(Study, null) },
                        edit: { path: 'edit', element: React.createElement(EditStudy, null) },
                    },
                },
            },
        },
        technologies: {
            path: 'catalog/technologies',
            children: {
                index: {
                    index: true,
                    element: React.createElement(Catalog, { view: 1 }),
                },
            },
        },
        teams: {
            path: 'teams',
            children: {
                list: { index: true, element: React.createElement(TeamsList, null) },
                detail: { path: ':id', element: React.createElement(TeamDetail, null) },
            },
        },
        fallback: {
            path: '*',
            element: (React.createElement(LegacyRedirect, null,
                React.createElement(NotFound, null))),
        },
    },
};
