import { Button, COLORS, Icons, Modal } from '@humanfirst/elektron';
import { ProjectCollaboratorResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useState } from 'react';
import { ShareProjectForm } from 'src/components/ProjectCollaborators/ShareProjectForm';
/**
 * A button that can be used to share a project.
 *
 * This will open up a modal experience to actually control sharing.
 */
export const ShareButton = ({ project }) => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const { data: collaborators } = useElektraApi(ProjectCollaboratorResource.list(project.id));
    const hasCollaborators = ((_a = collaborators === null || collaborators === void 0 ? void 0 : collaborators.length) !== null && _a !== void 0 ? _a : 0) > 1;
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "secondary", onClick: () => setIsOpen(true), startEnhancer: hasCollaborators ? (React.createElement(Icons.Misc.Share, { size: "16px", color: COLORS.primary, decorative: true })) : (React.createElement(Icons.Misc.Lock, { size: "16px", color: COLORS.primary, decorative: true })) }, "Share"),
        React.createElement(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement(ShareProjectForm, { project: project, onSubmit: () => setIsOpen(false) }))));
};
