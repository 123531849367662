import { useHasPermission } from './permissions/useHasPermission';
import { useHasPermissions } from './permissions/useHasPermissions';
/**
 * Returns true if a user has ALL evidence access. This means unrestricted evidence access.
 * A user has "all" evidence if theyhave the following read permissions.
 * 1. evidenceSource
 * 2. measure.evidence
 * 3. component.evidence
 * 4. medicalConditions.evidence
 */
const useHasAllEvidence = () => {
    return useHasPermissions([
        { resource: 'evidenceSource', action: 'read' },
        { resource: 'measure.evidence', action: 'read' },
        { resource: 'component.evidence', action: 'read' },
        { resource: 'medicalConditions.evidence', action: 'read' },
    ]);
};
/**
 * Checks for partial evidence access. Things such as a summaries, and counts.
 * This is a hack to represent our "AtlasPro" role for the majority of users.
 *
 * They will have EvidenceSource, but not evidence for major entities.
 * */
const useHasPartialEvidence = () => {
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasEverythingElse = useHasPermissions([
        { resource: 'measure.evidence', action: 'read' },
        { resource: 'component.evidence', action: 'read' },
        { resource: 'medicalConditions.evidence', action: 'read' },
    ]);
    return hasEvidenceSource && !hasEverythingElse;
};
/** This is the most basic tier of evidence acceess. None. It is a proxy the EvidenceSource permission */
const useHasNoEvidence = () => {
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    return !hasEvidenceSource;
};
export { useHasAllEvidence, useHasPartialEvidence, useHasNoEvidence };
