import { __awaiter } from "tslib";
import { Block, Button, Card, COLORS, Icons, SemanticButton, StatefulPopover, TagSelector, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { useRemoveMetadata } from 'src/pages/Workspace/utils';
import { useAddTag, useAvailableTags, useTagsForResource, } from '../hooks/hooks';
const EvidenceTagSelector = ({ item }) => {
    var _a, _b;
    const [isLoading, setIsLoading] = useState(false);
    const workspaceId = (_b = (_a = item.bookmark) === null || _a === void 0 ? void 0 : _a.workspaceId) !== null && _b !== void 0 ? _b : '';
    const availableTags = useAvailableTags(workspaceId);
    const addTag = useAddTag(workspaceId);
    const { mutateAsync: removeTag } = useRemoveMetadata(workspaceId);
    const currentTags = useTagsForResource(workspaceId, item.id);
    const onChange = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            if (e.type === 'add') {
                yield addTag(item.id, e.tag);
            }
            else if (e.type === 'remove') {
                const existing = currentTags.find((x) => x.metadataValue === e.tag);
                if (existing) {
                    yield removeTag({ id: existing.id });
                }
            }
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }), [setIsLoading, addTag, removeTag, currentTags, item.id]);
    return (React.createElement(Card, null,
        React.createElement(Block, { width: "258px" },
            React.createElement(TagSelector, { creatable: true, value: currentTags.map((x) => x.metadataValue), onChange: onChange, options: availableTags, isLoading: isLoading }))));
};
const EvidenceTagBulkSelector = ({ items }) => {
    var _a;
    const projectId = (_a = useActiveWorkspaceId()) !== null && _a !== void 0 ? _a : '';
    const [isLoading, setIsLoading] = useState(false);
    const availableTags = useAvailableTags(projectId);
    const addTag = useAddTag(projectId);
    const { mutateAsync: removeTag } = useRemoveMetadata(projectId);
    const allItemsHave = availableTags.filter((x) => items.every((y) => { var _a; return (_a = y.tags) === null || _a === void 0 ? void 0 : _a.some((z) => z.metadataValue === x); }));
    const onChange = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        try {
            setIsLoading(true);
            if (e.type === 'add') {
                const itemWithoutTag = items.filter((x) => { var _a; return !((_a = x.tags) === null || _a === void 0 ? void 0 : _a.some((y) => y.metadataValue === e.tag)); });
                for (const item of itemWithoutTag) {
                    yield addTag(item.id, e.tag);
                }
            }
            else if (e.type === 'remove') {
                const itemWithTag = items.filter((x) => { var _a; return (_a = x.tags) === null || _a === void 0 ? void 0 : _a.some((y) => y.metadataValue === e.tag); });
                for (const item of itemWithTag) {
                    const tagId = (_c = (_b = item.tags) === null || _b === void 0 ? void 0 : _b.find((x) => x.metadataValue === e.tag)) === null || _c === void 0 ? void 0 : _c.id;
                    if (tagId) {
                        yield removeTag({ id: tagId });
                    }
                }
            }
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }), [setIsLoading, addTag, removeTag, items]);
    return (React.createElement(Card, null,
        React.createElement(Block, { width: "258px" },
            React.createElement(TagSelector, { creatable: true, value: allItemsHave, onChange: onChange, options: availableTags, isLoading: isLoading }))));
};
/**
 * Actions in an EvidenceReview for interacting with tags.
 * @returns
 */
export const TagAction = ({ item, isBulk = false }) => {
    const isDisabled = item.length < 1;
    return (React.createElement(StatefulPopover, { triggerType: "click", content: isBulk ? (React.createElement(EvidenceTagBulkSelector, { items: item })) : (React.createElement(EvidenceTagSelector, { item: item[0] })) }, isBulk ? (React.createElement(Button, { disabled: isDisabled, variant: 'secondary', startEnhancer: React.createElement(Icons.Misc.Tag, { title: "Tags", color: COLORS.primary }) }, "Add Tags")) : (React.createElement(SemanticButton, null,
        React.createElement(Icons.Misc.Tag, { title: "Tags" })))));
};
