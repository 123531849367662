import React from 'react';
import { Card, Heading2, Spacer, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { EvidenceResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { TechnologiesTagged } from 'src/components/TechnologyTagged/TechnologyTagged';
import { isIntegratorAlgorithmUtil } from 'src/utils/vendorType';
const IntegratedTechnologiesCard = ({ tool }) => {
    var _a, _b, _c, _d, _e;
    const { data: techWithVendorStatedTech } = useElektraApi(ToolResource.get(tool.id, {
        join: ['vendorStatedIntegrations||id'],
    }));
    const { data: sources = [] } = useElektraApi(EvidenceResource.list({
        filter: [['tools', '$cont', tool.id]],
        join: ['sourceMeasures', 'sourceMeasures.tools'],
    }));
    const allTech = (_b = (_a = tool.integrations) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.model.localeCompare(b.model))) !== null && _b !== void 0 ? _b : [];
    const vendorStatedTechIds = (_d = (_c = techWithVendorStatedTech === null || techWithVendorStatedTech === void 0 ? void 0 : techWithVendorStatedTech.vendorStatedIntegrations) === null || _c === void 0 ? void 0 : _c.map((x) => x.id)) !== null && _d !== void 0 ? _d : [];
    const evidenceBasedTech = sources
        .flatMap((x) => x.sourceMeasures)
        .flatMap((x) => x === null || x === void 0 ? void 0 : x.tools)
        .filter((t) => !!t)
        .filter((t) => !isIntegratorAlgorithmUtil(t))
        .flatMap((r) => r.id);
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, "Supported Technologies"),
        React.createElement(Spacer, { size: '24px' }),
        React.createElement(When, { condition: ((_e = tool.integrations) === null || _e === void 0 ? void 0 : _e.length) === 0 },
            React.createElement(Typography, null, "None Listed")),
        React.createElement(TechnologiesTagged, { evidenceBasedTechIds: evidenceBasedTech, vendorStatedTechIds: vendorStatedTechIds, allTechnologies: allTech })));
};
export { IntegratedTechnologiesCard };
