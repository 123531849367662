import { Block, Card, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useElektraApi, UserNotificationSettingsResource, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { SingleNotificationPreference } from './components/SingleNotificationPreference';
import { NOTIFICATION_OPTIONS } from './constants';
const Notifications = () => {
    const notificationSettings = useElektraApi(UserNotificationSettingsResource.list());
    const allNotifications = [...NOTIFICATION_OPTIONS];
    return (React.createElement(Card, { padding: "24px" },
        React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: '24px' }, "Email Notification Preferences"),
        React.createElement(Block, null, allNotifications.map((option) => {
            return (React.createElement(SingleNotificationPreference, { key: option.name, option: option, currentSettings: notificationSettings.data }));
        }))));
};
export { Notifications };
