import React from 'react';
import { useUser } from 'src/hooks/user';
import { AnchoredTooltip } from '../AnchoredTooltip';
const isSplitTooltipGuard = (x) => {
    return !!(x && typeof x === 'object' && ('maker' in x || 'searcher' in x));
};
const AttributeTooltip = ({ tooltip }) => {
    const user = useUser();
    const isMaker = (user === null || user === void 0 ? void 0 : user.role) === 4;
    if (isSplitTooltipGuard(tooltip)) {
        tooltip = isMaker ? tooltip === null || tooltip === void 0 ? void 0 : tooltip.maker : tooltip === null || tooltip === void 0 ? void 0 : tooltip.searcher;
    }
    if (!tooltip) {
        return null;
    }
    return React.createElement(AnchoredTooltip, null, tooltip);
};
export { AttributeTooltip };
