import { useMemo } from 'react';
import { useResourceNameMap } from './useResourceNameMap';
/** Get a list of resources from a series of events. */
export const getResourcesFromEvents = (events) => {
    return events.flatMap(({ type, current, previous }) => {
        if (type === 'workspace' ||
            type === 'document' ||
            type === 'collaborator') {
            return [];
        }
        const result = [];
        if (current) {
            result.push({
                resource: current.resource,
                resourceType: current.resourceType,
            });
        }
        if (previous) {
            result.push({
                resource: previous.resource,
                resourceType: previous.resourceType,
            });
        }
        return result;
    });
};
export const useEventsResourceNameMap = (events) => {
    const resources = useMemo(() => getResourcesFromEvents(events !== null && events !== void 0 ? events : []), [events]);
    return useResourceNameMap(resources);
};
