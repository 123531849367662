import { WorkspaceResourceMetadataResource, useElektraApi, WorkspaceBookmarkResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useMemo } from 'react';
import { useUser } from 'src/hooks/user';
function getBookmarksMatchingMetadata(bookmarks, metadata, metadataType, metadataValue) {
    return bookmarks.filter((b) => getMetadataMatching(metadata, metadataType, metadataValue).some((m) => m.resourceType === b.resourceType && m.resource === b.resource));
}
/**
 * Returns all metadata with systemMetadata with value "Seen".
 * This is scoped to the current user.
 */
const useGetSeenMetadata = (workspaceId, resourceType) => {
    const user = useUser();
    const options = {
        metadataType: 'systemMetadata',
        metadataValue: 'Seen',
    };
    if (resourceType) {
        options.resourceType = resourceType;
    }
    return useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, options, user === null || user === void 0 ? void 0 : user.id), { enabled: !!(user === null || user === void 0 ? void 0 : user.id) });
};
/**
 * Hook for removing any workspace resource metadata
 */
const useRemoveMetadata = (workspaceId) => {
    return useElektraApiMutation(WorkspaceResourceMetadataResource.remove(workspaceId));
};
/**
 * Hook for creating any workspace resource metadata
 */
const useCreateMetadata = (workspaceId) => {
    return useElektraApiMutation(WorkspaceResourceMetadataResource.create(workspaceId));
};
/**
 * Returns all metadata with systemMetadata with value "Reviewed".
 * This is scoped to the current user.
 */
const useGetReviewedMetadata = (workspaceId, resourceType) => {
    const user = useUser();
    const options = {
        metadataType: 'systemMetadata',
        metadataValue: 'Reviewed',
    };
    if (resourceType) {
        options.resourceType = resourceType;
    }
    return useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, options, user === null || user === void 0 ? void 0 : user.id), { enabled: !!(user === null || user === void 0 ? void 0 : user.id) });
};
/**
 * Returns all metadata with systemMetadata with value "Archived".
 */
const useGetArchivedMetadata = (workspaceId, resourceType) => {
    const options = {
        metadataType: 'systemMetadata',
        metadataValue: 'Archived',
    };
    if (resourceType) {
        options.resourceType = resourceType;
    }
    return useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, options));
};
function getMetadataMatching(metadata, metadataType, metadataValue) {
    return metadata.filter((m) => m.metadataType === metadataType &&
        (metadataValue === undefined || m.metadataValue === metadataValue));
}
/**
 * Returns all metadata with userTag metadataType
 */
const useGetUserTagMetadata = (workspaceId, resourceType) => {
    const options = {
        metadataType: 'userTag',
    };
    if (resourceType) {
        options.resourceType = resourceType;
    }
    return useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, options));
};
const useGetBookmarksAndMetadata = (workspace) => {
    const bookmarksResult = useElektraApi(WorkspaceBookmarkResource.list(workspace.id));
    const archivedResult = useElektraApi(WorkspaceResourceMetadataResource.list(workspace.id, {
        metadataType: 'systemMetadata',
        metadataValue: 'Archived',
    }), { enabled: true });
    const listsResult = useElektraApi(WorkspaceResourceMetadataResource.list(workspace.id, {
        metadataType: 'tag',
    }), { enabled: true });
    const metadata = useMemo(() => { var _a, _b; return [...((_a = listsResult.data) !== null && _a !== void 0 ? _a : []), ...((_b = archivedResult.data) !== null && _b !== void 0 ? _b : [])]; }, [listsResult.data, archivedResult.data]);
    const bookmarks = useMemo(() => { var _a; return (_a = bookmarksResult.data) !== null && _a !== void 0 ? _a : []; }, [bookmarksResult.data]);
    const isError = bookmarksResult.isError || listsResult.isError;
    const isLoading = bookmarksResult.isLoading ||
        (listsResult.isFetching && listsResult.isLoading) ||
        (archivedResult.isFetching && archivedResult.isLoading);
    return { isLoading, isError, bookmarks, metadata };
};
export { getBookmarksMatchingMetadata, getMetadataMatching, useGetBookmarksAndMetadata, useGetUserTagMetadata, useGetSeenMetadata, useGetReviewedMetadata, useRemoveMetadata, useCreateMetadata, useGetArchivedMetadata, };
