import { Banner, Block, Icons, Stack, Tab, TabList, TabPanel, } from '@humanfirst/elektron';
import { WorkspaceReportResource, WorkspaceResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { BetaTag } from 'src/components/Badges';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { useToggle } from 'src/hooks/toggle';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { LoadingState } from 'src/components/LoadingState';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useClearSearchStateUrl } from 'src/components/tables/hooks/use-search-state-url';
import { Documents } from './components/Documents/Documents';
import { ReportsList } from './components/ReportsList';
import { ScopedSearch } from './components/ScopedSearch';
import { SearchAndPlan } from './components/SearchAndPlan';
import { WorkspaceDescriptionCard } from './components/WorkspaceDescriptionCard';
import { Review } from './components/Review';
const TabWithIcon = ({ icon, title, }) => {
    return (React.createElement(Stack, { direction: "horizontal", gap: "8px", alignContent: "center" },
        icon,
        " ",
        title));
};
const ReportBanner = ({ report, projectId, }) => {
    const navigate = useNavigate();
    return (React.createElement(Banner, { variant: 'information', title: "New Report Added to Your Project", content: null, actionLabel: "View Report", onAction: () => navigate(generatePath(PATHS.projects.reports.view, {
            id: report.id,
            projectId,
        })) }));
};
const WorkspaceScopeTabs = ({ setView, view, workspace, }) => {
    const showUploads = useHasPermission('project.uploads', 'read');
    const showLitReview = useToggle('lit-review');
    const hasAllEvidence = useHasAllEvidence();
    const clearSearchStateUrl = useClearSearchStateUrl();
    const onTabChange = useCallback((v) => {
        clearSearchStateUrl();
        setView(v);
    }, [clearSearchStateUrl, setView]);
    const showLitReviewTab = showLitReview && hasAllEvidence;
    return (React.createElement(React.Fragment, null,
        React.createElement(TabList, { onChange: onTabChange, active: view },
            React.createElement(Tab, null,
                React.createElement(TabWithIcon, { title: "Search", icon: React.createElement(Icons.Misc.Search, { title: "Search tab", color: "currentColor" }) })),
            React.createElement(Tab, null,
                React.createElement(TabWithIcon, { title: "Saved", icon: React.createElement(Icons.Misc.Save, { title: "Saved tab", color: "currentColor" }) })),
            showLitReviewTab ? (React.createElement(Tab, null,
                React.createElement(TabWithIcon, { title: "Review", icon: React.createElement(Icons.Misc.Activity, { title: "Evidence Review tab", color: "currentColor" }) }))) : null,
            React.createElement(Tab, null,
                React.createElement(TabWithIcon, { title: "Reports", icon: React.createElement(Icons.Misc.ReportSimple, { title: "Reports tab", color: "currentColor" }) })),
            showUploads ? (React.createElement(Tab, null,
                React.createElement(Stack, { direction: "horizontal" },
                    React.createElement(TabWithIcon, { title: "Custom Data", icon: React.createElement(Icons.Misc.Connect, { title: "Custom Data tab", color: "currentColor" }) }),
                    React.createElement(BetaTag, null)))) : null),
        React.createElement(Block, null,
            React.createElement(TabPanel, { index: 0, active: view, renderHidden: true },
                React.createElement(ScopedSearch, { data: workspace })),
            React.createElement(TabPanel, { index: 1, active: view },
                React.createElement(SearchAndPlan, { data: workspace })),
            showLitReviewTab ? (React.createElement(TabPanel, { index: 2, active: view },
                React.createElement(Review, { data: workspace }))) : null,
            React.createElement(TabPanel, { index: showLitReviewTab ? 3 : 2, active: view, renderHidden: true },
                React.createElement(ReportsList, { data: workspace })),
            showUploads ? (React.createElement(TabPanel, { index: showLitReviewTab ? 4 : 3, active: view },
                React.createElement(Documents, { workspaceId: workspace.id }))) : null)));
};
const WorkspacesInternal = ({ data: workspace, hasInsightReport, recentReport }) => {
    const hasEvidenceReview = useToggle('lit-review');
    const reportTab = hasEvidenceReview ? 3 : 2;
    const defaultTab = hasInsightReport ? reportTab : 0;
    const [view, setView] = useQueryParam('view', withDefault(NumberParam, defaultTab));
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Workspaces,
            BREADCRUMBS.WorkspaceDetails({
                id: workspace.id,
                name: workspace.name,
            }),
        ] },
        React.createElement(PageTitle, { title: "My Projects" }),
        React.createElement(Stack, { gap: '32px' },
            recentReport ? (React.createElement(ReportBanner, { report: recentReport, projectId: workspace.id })) : null,
            React.createElement(WorkspaceDescriptionCard, { data: workspace }),
            React.createElement(WorkspaceScopeTabs, { workspace: workspace, setView: setView, view: view }))));
};
/** Determine if a report is a recent (should show a banner). */
const isRecentReport = (report) => report.type === 'presentation' &&
    dayjs().diff(report.createdAt, 'hours') <= 72;
const WorkspaceDetails = () => {
    const workspaceId = useActiveWorkspaceId();
    const result = useElektraApi(WorkspaceResource.get(workspaceId));
    const { data: reportsData, isInitialLoading: reportsLoading } = useElektraApi(WorkspaceReportResource.list(workspaceId));
    if (reportsLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: WorkspacesInternal, recentReport: reportsData === null || reportsData === void 0 ? void 0 : reportsData.find(isRecentReport), hasInsightReport: !!(reportsData === null || reportsData === void 0 ? void 0 : reportsData.some((x) => x.type === 'presentation')) })));
};
export { WorkspaceDetails };
