import { __rest } from "tslib";
import { useElektraApi, EvidenceResource, ToolResource, MedicalConditionsResource, MeasureCategoryResource, } from '@humanfirst/use-elektra-api';
import { uniqBy } from 'ramda';
import { useMemo } from 'react';
const extractAndDeduplicate = (data, extractKey) => {
    if (!data) {
        return undefined;
    }
    return uniqBy((x) => x.id, data.flatMap((x) => { var _a; return (_a = x[extractKey]) !== null && _a !== void 0 ? _a : []; }));
};
const useAttachToolsToMeasuresWithEvidence = (measuresWithEvidence) => {
    const tools = useMemo(() => {
        return measuresWithEvidence
            ? Array.from(new Set(measuresWithEvidence.flatMap(({ evidence }) => evidence === null || evidence === void 0 ? void 0 : evidence.flatMap((e) => {
                var _a;
                return (_a = e.sourceMeasures) === null || _a === void 0 ? void 0 : _a.flatMap((sm) => { var _a; return (_a = sm.tools) === null || _a === void 0 ? void 0 : _a.map((x) => x.id); });
            })))).filter((tech) => !!tech)
            : [];
    }, [measuresWithEvidence]);
    const { data: allTools } = useElektraApi(ToolResource.getBulk(tools, {
        join: ['maker', 'evidence||id'],
    }), {
        enabled: !!tools.length,
    });
    return measuresWithEvidence
        ? measuresWithEvidence.map((measure) => {
            var _a, _b;
            // Gather all of the source measures for this measure
            const sourceMeasureIds = (_a = measure.evidence) === null || _a === void 0 ? void 0 : _a.flatMap((e) => { var _a; return (_a = e.sourceMeasures) !== null && _a !== void 0 ? _a : []; }).map((x) => x.id);
            // Reduce the subset of tools based on the source measure ids
            const toolsForMeasure = (_b = allTools === null || allTools === void 0 ? void 0 : allTools.filter((tool) => { var _a; return (_a = tool.evidence) === null || _a === void 0 ? void 0 : _a.some((x) => sourceMeasureIds === null || sourceMeasureIds === void 0 ? void 0 : sourceMeasureIds.includes(x.id)); })) !== null && _b !== void 0 ? _b : [];
            return Object.assign(Object.assign({}, measure), { tools: toolsForMeasure });
        })
        : [];
};
/** Gets all Sources related to a medical condition. */
const useGetEvidenceForMedicalCondition = (id) => {
    return useElektraApi(EvidenceResource.list({
        filter: [['medicalConditions.id', '$eq', id]],
        join: [
            'sourceMeasures||id,measureCategories',
            'sourceMeasures.tools||id,model,imageSubmittedCDN,imageSubmittedExists',
            'medicalConditions||id,name',
            'primaryMedicalConditions||id,name',
            'therapeuticArea||id,therapeuticArea',
        ],
    }));
};
/** Gets all source measures that relate to a measure category. */
const useGetSourceMeasuresForEvidence = (id) => {
    const _a = useGetEvidenceForMedicalCondition(id), { data: evidence } = _a, rest = __rest(_a, ["data"]);
    return Object.assign({ data: extractAndDeduplicate(evidence, 'sourceMeasures') }, rest);
};
/**  Gets all measures that are related to a source. */
const useGetMeasureCategoriesForMedicalCondition = (id) => {
    return useElektraApi(MeasureCategoryResource.list({
        filter: [['medicalConditions.id', '$eq', id]],
    }));
};
/** Gets all tools that are related to a source. */
const useGetToolsForEvidence = (id) => {
    const _a = useGetSourceMeasuresForEvidence(id), { data: sourceMeasures } = _a, rest = __rest(_a, ["data"]);
    return Object.assign({ data: extractAndDeduplicate(sourceMeasures, 'tools') }, rest);
};
/** Gets all Sources related to a measure category. */
const useGetEvidenceForMeasureCategory = (measureCategory) => {
    return useElektraApi(EvidenceResource.list({
        filter: [['measureCategory', '$cont', measureCategory.category]],
        join: [
            'sourceMeasures||id,measureCategories',
            'sourceMeasures.tools||id,model,imageSubmittedCDN,imageSubmittedExists',
            'medicalConditions||id,name',
            'therapeuticArea||id,therapeuticArea',
            'primaryMedicalConditions||id,name',
        ],
    }));
};
/** Gets all tools that are related to a measure category by evidence and vendor stated. */
const useGetToolsForMeasureCategory = (measureCategory) => {
    return useElektraApi(ToolResource.list({
        or: [
            ['measuresCategory', '$cont', measureCategory.category],
            ['vendorMeasures', '$cont', measureCategory.category],
        ],
        join: ['maker||id,name,evidenceCount', 'medicalConditions||id,name'],
    }));
};
/** Gets all medical conditions for a measure category. */
const useGetMedicalConditionsForMeasureCategory = (measureCategory) => {
    return useElektraApi(MedicalConditionsResource.list({
        filter: [['measureCategory', '$cont', measureCategory.category]],
    }));
};
export { useGetEvidenceForMeasureCategory, useGetToolsForMeasureCategory, useGetMedicalConditionsForMeasureCategory, useGetEvidenceForMedicalCondition, useGetMeasureCategoriesForMedicalCondition, useGetToolsForEvidence, useAttachToolsToMeasuresWithEvidence, };
