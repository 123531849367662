import { useElektraApi, } from '@humanfirst/use-elektra-api';
import { useMemo } from 'react';
import { useRelatedIDs } from './useRelatedIDs';
function useRelatedResource({ getResource, indexName, objectID, enabled = true, }) {
    var _a;
    const relatedResults = useRelatedIDs({
        indexName,
        objectID,
        enabled,
    });
    const results = useElektraApi(getResource((_a = relatedResults.data) !== null && _a !== void 0 ? _a : []), {
        enabled: enabled && relatedResults.data !== undefined,
    });
    return useMemo(() => {
        const { data: ids = [] } = relatedResults;
        const { data } = results;
        const sortedData = data === undefined
            ? undefined
            : [...data].sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
        const isLoading = results.isLoading || relatedResults.isLoading;
        const isError = results.isError || relatedResults.isError;
        const error = results.error || relatedResults.error;
        return Object.assign(Object.assign({}, results), { data: sortedData, isLoading,
            isError,
            error });
    }, [results, relatedResults]);
}
export { useRelatedResource };
