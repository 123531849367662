import { Block, ButtonLink, Card, COLORS, Divider, Heading2, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { MeasureCategoryResource, MedicalConditionsResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { ToolSummary } from 'src/components/ToolSummary';
import { PATHS } from 'src/config/path';
import { useActivityHistory } from 'src/hooks/activity/activity';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { generatePath } from 'src/utils/path';
const HorizontalToolList = ({ data }) => {
    return (React.createElement(Stack, { direction: "horizontal" }, data.slice(0, 3).map((tool) => (React.createElement(Block, { width: "33%", key: tool.id },
        React.createElement(ToolSummary, { imageSize: 56, href: generatePath(PATHS.atlas.technologies.detail, {
                id: tool.id,
            }), tool: tool }))))));
};
const RecentTechnology = ({ ids }) => {
    const result = useElektraApi(ToolResource.getBulk(ids, { join: ['maker'] }), {
        enabled: !!ids.length,
    });
    return (React.createElement(Stack, { gap: "8px" },
        React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.DetailsBold }, "TECHNOLOGIES"),
        React.createElement(If, { condition: !ids.length },
            React.createElement(Then, null,
                React.createElement(Typography, { color: COLORS.gray1 }, "You haven't recently viewed any technologies."),
                React.createElement(Block, null,
                    React.createElement(ButtonLink, { variant: "secondary", href: PATHS.atlas.technologies.list, size: "large" }, "Explore Technologies"))),
            React.createElement(Else, null,
                React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: HorizontalToolList }))))));
};
const MeasureList = ({ data }) => {
    return (React.createElement(Stack, null, data.map((measure) => (React.createElement(Link, { href: generatePath(PATHS.atlas.measures.detail, { id: measure.id }), key: measure.id }, measure.category)))));
};
const RecentMeasures = ({ ids }) => {
    const result = useElektraApi(MeasureCategoryResource.getBulk(ids), {
        enabled: !!ids.length,
    });
    return (React.createElement(Stack, { gap: "8px" },
        React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.DetailsBold }, "MEASURES"),
        React.createElement(If, { condition: !ids.length },
            React.createElement(Then, null,
                React.createElement(Typography, { color: COLORS.gray1 }, "You haven't recently viewed any measures."),
                React.createElement(Block, null,
                    React.createElement(ButtonLink, { variant: "secondary", href: PATHS.atlas.measures.list, size: "large" }, "Explore Measures"))),
            React.createElement(Else, null,
                React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: MeasureList }))))));
};
const MedicalConditionList = ({ data, }) => {
    return (React.createElement(Stack, null, data.map((medicalCondition) => (React.createElement(Link, { href: generatePath(PATHS.atlas.medicalConditions.detail, {
            id: medicalCondition.id,
        }), key: medicalCondition.id }, medicalCondition.name)))));
};
const RecentMedicalConditions = ({ ids }) => {
    const result = useElektraApi(MedicalConditionsResource.getBulk(ids), {
        enabled: !!ids.length,
    });
    return (React.createElement(Stack, { gap: "8px" },
        React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.DetailsBold }, "MEDICAL CONDITIONS"),
        React.createElement(If, { condition: !ids.length },
            React.createElement(Then, null,
                React.createElement(Typography, { color: COLORS.gray1 }, "You haven't recently viewed any medical conditions."),
                React.createElement(Block, null,
                    React.createElement(ButtonLink, { variant: "secondary", href: PATHS.atlas.medicalConditions.list, size: "large" }, "Explore Medical Conditions"))),
            React.createElement(Else, null,
                React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: MedicalConditionList }))))));
};
/** Extract IDs for specific event types from the ActivityHistory */
const getActivityIds = (event, history) => {
    var _a, _b;
    return (_b = (_a = history === null || history === void 0 ? void 0 : history[event]) === null || _a === void 0 ? void 0 : _a.map((x) => x.details.id)) !== null && _b !== void 0 ? _b : [];
};
/** Shows all Recent activity for a user */
const RecentActivity = () => {
    const activityHistory = useActivityHistory();
    const technologies = getActivityIds('technology-page', activityHistory);
    const measures = getActivityIds('measure-page', activityHistory);
    const medicalConditions = getActivityIds('medical-condition-page', activityHistory);
    const hasMedicalConditions = useHasPermission('medicalConditions', 'read', 'all');
    return (React.createElement(Card, { fill: true, padding: '48px' },
        React.createElement(Heading2, null, "My Recent Activity"),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(RecentTechnology, { ids: technologies }),
            React.createElement(Divider, null),
            React.createElement(RecentMeasures, { ids: measures }),
            React.createElement(When, { condition: hasMedicalConditions },
                React.createElement(Divider, null),
                React.createElement(RecentMedicalConditions, { ids: medicalConditions })))));
};
export { RecentActivity, RecentMedicalConditions, RecentMeasures, RecentTechnology, };
