import { __awaiter } from "tslib";
import { Center, Heading, Link, Paragraph, Spacer, Stack, } from '@humanfirst/elektron';
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { PageTitle } from 'src/components/PageTitle';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { SmartForm } from 'src/components/SmartForm';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [defaultEmail] = useQueryParam('email', StringParam);
    const { mutateAsync } = useElektraApiMutation(AuthResource.requestResetPassword());
    const onSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        yield mutateAsync(data);
        navigate(generatePath(PATHS.authentication.forgotPassword.success));
    }), [navigate, mutateAsync]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Reset Password" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Heading, null, "Reset your password"),
            React.createElement(Paragraph, null, "Enter the email address associated with your account and we\u2019ll send you a link to reset your password."),
            React.createElement(SmartForm, { defaultValues: { email: defaultEmail !== null && defaultEmail !== void 0 ? defaultEmail : '' }, onSubmit: onSubmit },
                React.createElement(SmartForm.Input, { label: "Email", rules: { required: true }, id: "email", name: "email", type: "email", placeholder: "name@company.com" })),
            React.createElement(Spacer, { size: "24px" }),
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Center, null,
                    React.createElement(Link, { href: generatePath(PATHS.authentication.login.request) }, "Return to login"))))));
};
export { ForgotPassword };
