import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha, } from 'react-google-recaptcha-v3';
import { useLogger } from 'src/hooks/logger';
const useRecaptcha = () => {
    const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
    const logger = useLogger(useRecaptcha.name);
    if (!RECAPTCHA_SITE_KEY) {
        logger.warn('recaptcha token unavailable, skipping hook');
        return undefined;
    }
    // Because this is based on the process.env, we're ok to optionally load this hook.
    // If we call the hook without the site key then we'll get an error.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { executeRecaptcha } = useGoogleReCaptcha();
    return executeRecaptcha;
};
const RecaptchaProvider = ({ children }) => {
    // Technically this could be done outside the provider but it makes testing a lot harder.
    // Yes, you could use `jest.resetModules()` but that breaks react hooks for the run
    // and it's a lot easier to just handle it this way
    const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
    const logger = useLogger(RecaptchaProvider.name);
    useEffect(() => {
        if (!RECAPTCHA_SITE_KEY) {
            logger.warn('recaptcha token unavailable, skipping provider');
        }
    }, [logger]);
    if (!RECAPTCHA_SITE_KEY) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_SITE_KEY }, children));
};
export { RecaptchaProvider, useRecaptcha };
