import { Block, Card, COLORS, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { RequestIngestionButton } from 'src/components/RequestIngestionButton';
import { t } from 'src/constants/i18n';
const PartiallyIngestedEvidenceCard = ({ evidence, }) => {
    return (React.createElement(Card, null,
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, t('evidenceTable.partiallyIngested.header')),
            React.createElement(Typography, { color: COLORS.gray1 }, t('evidenceTable.partiallyIngested.tooltip')),
            React.createElement(Block, null,
                React.createElement(RequestIngestionButton, { resourceType: "evidence", resourceId: evidence.id, formData: { title: evidence.title } })))));
};
export { PartiallyIngestedEvidenceCard };
