import React, { useCallback, useState } from 'react';
import { Button, Checkbox } from '@humanfirst/elektron';
import { ConfirmationModal } from './ConfirmationModal';
const CheckboxWithConfirmation = ({ onSubmit, title, body, submitText, children, checkedState }) => {
    const [isOpen, setIsOpen] = useState(false);
    const showModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { onSubmit: onSubmit, title: title, body: body, submitText: submitText, isOpen: isOpen, closeModal: closeModal }),
        React.createElement(Checkbox, { checkmarkType: 'toggle', onChange: showModal, checked: checkedState, labelPlacement: 'right' }, children)));
};
const ButtonWithConfirmation = ({ onSubmit, title, body, submitText, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const showModal = useCallback(() => setIsOpen(true), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { onSubmit: onSubmit, title: title, body: body, submitText: submitText, isOpen: isOpen, closeModal: closeModal }),
        React.createElement(Button, { variant: "tertiary", onClick: showModal }, children)));
};
export { ButtonWithConfirmation, CheckboxWithConfirmation };
