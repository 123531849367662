const getCurrentEnvironement = () => {
    var _a;
    const environment = (_a = process.env.REACT_ENVIRONMENT) !== null && _a !== void 0 ? _a : 'local';
    if (environment === 'staging') {
        return 'staging';
    }
    else if (environment === 'e2e') {
        return 'e2e';
    }
    else if (environment === 'production') {
        return 'production';
    }
    else {
        return 'local';
    }
};
export { getCurrentEnvironement };
