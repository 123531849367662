import React from 'react';
import { Tag, Typography, TypographyVariant } from '@humanfirst/elektron';
const COMPONENT_TYPE_MAP = {
    'Physical Sensor': 'Sensor',
    'Software-Based Component': 'Software',
    Accessory: 'Accessory',
    'Model Unspecified': 'Unspecified',
    'Smartphone/Tablet': 'Smartphone',
    Diagnostic: 'Diagnostic',
    'Clinical Outcome Assessment (COA)': 'Questionnaire',
    Laboratory: 'Laboratory',
    Questionnaire: 'Questionnaire',
};
const ComponentInstrumentTypeTag = ({ componentType, subType }) => {
    const copy = !componentType
        ? 'Unspecified'
        : COMPONENT_TYPE_MAP[componentType];
    return (React.createElement(Tag, { closeable: false },
        React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: "currentColor" },
            copy,
            (subType === null || subType === void 0 ? void 0 : subType.length) ? ' - ' + subType[0] : null)));
};
export { ComponentInstrumentTypeTag, COMPONENT_TYPE_MAP };
