import { Block, Card, COLORS, Icons, Spacer, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { ToolAttribute, getAttributesForComponentType, } from 'src/components/ModelAttribute/ToolAttribute';
const QuestionnaireDetailsCard = ({ tool }) => {
    var _a;
    const attributes = getAttributesForComponentType(tool);
    const notes = (_a = tool.generalNotes) === null || _a === void 0 ? void 0 : _a.trim();
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Questionnaire Details"),
        React.createElement(Spacer, { size: '32px' }),
        React.createElement(Stack, { gap: "24px" },
            attributes.map(([Attribute, Icon]) => (React.createElement(Stack, { key: Attribute.label, direction: "horizontal", gap: "16px" },
                React.createElement(Block, { minWidth: "24px" },
                    React.createElement(Icon, { size: "24px", color: COLORS.gray1, title: Attribute.label, decorative: true })),
                React.createElement(Stack, { gap: "8px", flex: "1" },
                    React.createElement(Spread, null,
                        React.createElement(Typography, { color: COLORS.gray1 }, Attribute.label),
                        React.createElement(EditFlagTooltip, { field: Attribute.label })),
                    React.createElement(Attribute, { item: tool }))))),
            React.createElement(When, { condition: notes },
                React.createElement(Stack, { direction: "horizontal", gap: "16px" },
                    React.createElement(Block, { minWidth: "24px" },
                        React.createElement(Icons.Product.Manual, { size: "24px", color: COLORS.gray1, decorative: true })),
                    React.createElement(Stack, { gap: "8px" },
                        React.createElement(Spread, { flex: "1" },
                            React.createElement(Typography, null, ToolAttribute.GeneralNotes.label),
                            React.createElement(EditFlagTooltip, { field: ToolAttribute.GeneralNotes.label })),
                        React.createElement(ToolAttribute.GeneralNotes, { item: tool })))))));
};
export { QuestionnaireDetailsCard };
