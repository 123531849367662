import React from 'react';
import { ToolTable } from 'src/components/ToolTable';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { ToolSupportingEvidenceCell } from '../cells';
import { getToolEvidenceSortValue } from '../util';
const MedicalConditionToolTable = ({ showEvidence, techWithEvidence, evidence, condition }) => {
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read');
    const hasMedicalConditionEvidence = useHasPermission('medicalConditions.evidence', 'read', {
        id: condition.id,
    });
    const toolsSupportingEvidenceColumn = {
        id: 'supportingEvidenceCount',
        header: 'Supporting Evidence',
        sortable: 'numeric',
        getStringValue: (item) => getToolEvidenceSortValue(item, evidence || []),
        render: (item) => (React.createElement(ToolSupportingEvidenceCell, { item: item, showEvidence: showEvidence, evidence: evidence })),
    };
    const { technologyTableScopes } = useGetConnectInstrumentScopes();
    if (!hasMedicalConditionEvidence && hasEvidenceSource) {
        return (React.createElement(ToolTable, { data: techWithEvidence !== null && techWithEvidence !== void 0 ? techWithEvidence : [], scope: technologyTableScopes }));
    }
    return (React.createElement(ToolTable, { extraColumns: [toolsSupportingEvidenceColumn], initialState: {
            sort: !hasEvidenceSource
                ? undefined
                : {
                    column: 'supportingEvidenceCount',
                    ascending: false,
                },
        }, data: techWithEvidence !== null && techWithEvidence !== void 0 ? techWithEvidence : [], scope: technologyTableScopes }));
};
export { MedicalConditionToolTable };
