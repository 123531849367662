import { useElektraApi } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { filterBookmarks } from './utils';
/**
 * Renders a section where the bookmarked resources need to be fetched
 * from the API.
 */
function SectionFromApi({ bookmarks, definition, extraColumns, extraActions, }) {
    // Identify the bookmarks for the specific type that we care about.
    const resourceBookmarks = filterBookmarks(bookmarks, definition);
    // Load the related resources from the API.
    const result = useElektraApi(definition.resourceEndpoint(resourceBookmarks), {
        enabled: resourceBookmarks.length > 0,
    });
    // Attach the bookmarks to the resources.
    const combinedData = useMemo(() => {
        if (result.data) {
            return result.data
                .map((x) => {
                const bookmark = resourceBookmarks.find((y) => y.resource === x.id);
                // This really shouldn't happen, but we don't want to show an item
                // if we can't find the bookmark (otherwise commenting/deleting will
                // not work).
                if (!bookmark) {
                    return null;
                }
                return Object.assign(Object.assign({}, x), { __bookmark: bookmark });
            })
                .filter((x) => !!x);
        }
        return undefined;
    }, [result.data, resourceBookmarks]);
    return (React.createElement(QueryWrapper, Object.assign({}, result, { data: combinedData, renderSuccess: definition.display, extraColumns: extraColumns, extraActions: extraActions })));
}
function SectionFromMapping({ bookmarks, definition, extraColumns, extraActions, }) {
    const resourceBookmarks = filterBookmarks(bookmarks, definition);
    const combinedData = definition.resourceMapping(resourceBookmarks);
    const Component = definition.display;
    return (React.createElement(Component, { data: combinedData, extraColumns: extraColumns, extraActions: extraActions }));
}
export function SearchAndPlanSection({ bookmarks, definition, extraColumns, extraActions, }) {
    if (definition.type === 'api') {
        return (React.createElement(SectionFromApi, { definition: definition, bookmarks: bookmarks, extraColumns: extraColumns, extraActions: extraActions }));
    }
    else if (definition.type === 'mapped') {
        return (React.createElement(SectionFromMapping, { definition: definition, bookmarks: bookmarks, extraColumns: extraColumns, extraActions: extraActions }));
    }
    return null;
}
