const BREADCRUMBS = {
    Connect: { label: "My Organization's Data", url: '/catalog/studies' },
    Studies: { label: 'Studies', url: '/catalog/studies' },
    Instruments: { label: 'Technologies', url: '/catalog/technologies' },
    StudyDetails: (study) => ({
        label: study.name,
        url: `/catalog/studies/${study.id}`,
    }),
    Admin: { label: 'Admin', url: '/admin' },
    AdminReports: { label: 'Reports', url: '/admin/reports' },
    AdminReportDetails: (report) => ({
        label: report.title,
        url: `/admin/reports/${report.id}`,
    }),
    AdminReportPreview: (report) => ({
        label: 'Preview',
        url: `/admin/reports/${report.id}/preview`,
    }),
    Atlas: { label: 'Atlas Network', url: '/atlas' },
    Technologies: { label: 'Technologies', url: '/atlas/technologies' },
    TechnologyDetails: (tool) => ({
        label: tool.model,
        url: `/atlas/technologies/${tool.id}`,
    }),
    MedicalConditions: {
        label: 'Medical Conditions',
        url: '/atlas/medical-conditions',
    },
    MedicalConditionDetails: (medicalCondition) => ({
        label: `${medicalCondition.name}`,
        url: `/atlas/medical-conditions/${medicalCondition.id}`,
    }),
    Report: (report) => ({
        label: report.title,
        url: `/projects/${report.projectId}/reports/${report.id}`,
    }),
    Workspaces: { label: 'My Projects', url: '/projects' },
    WorkspaceDetails: (project) => ({
        label: `${project.name}`,
        url: `/projects/${project.id}`,
    }),
    WorkspaceEdit: (project) => ({
        label: `Edit`,
        url: `/projects/${project.id}/edit`,
    }),
    WorkspaceReports: (project) => ({
        label: `${project.name} Reports`,
        url: `/projects/${project.id}/reports`,
    }),
    WorkspaceLists: (list) => ({
        label: `${list.listName}`,
        url: `/projects/${list.projectId}/lists/${encodeURI(list.listName)}`,
    }),
    Measures: { label: 'Measures', url: '/atlas/measures' },
    MeasureDetails: (measure) => ({
        label: `${measure.category}`,
        url: `/atlas/measures/${measure.id}`,
    }),
    Vendors: { label: 'Vendors', url: '/atlas/vendors' },
    VendorDetails: (vendor) => ({
        label: `${vendor.name}`,
        url: `/atlas/vendors/${vendor.id}`,
    }),
    Evidence: { label: 'Evidence', url: '/atlas/evidence' },
    EvidenceDetails: (evidence) => ({
        label: `${evidence.name}`,
        url: `/atlas/evidence/${evidence.id}`,
    }),
};
export { BREADCRUMBS };
