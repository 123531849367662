import React from 'react';
import { TextColumn } from '@humanfirst/elektron';
import { OrganizationCell, ProductCountCell } from './cells';
const ORGANIZATION_COLUMN = TextColumn('name', {
    header: 'Vendor',
    render: (item) => React.createElement(OrganizationCell, { item: item }),
});
const PRODUCT_COUNT_COLUMN = {
    id: 'productCount',
    header: '# Products in Atlas',
    render: (item) => React.createElement(ProductCountCell, { item: item }),
    searchable: false,
    sortable: false,
};
export { PRODUCT_COUNT_COLUMN, ORGANIZATION_COLUMN };
