import { __rest } from "tslib";
import React from 'react';
import { Block, COLORS, Stack } from '@humanfirst/elektron';
const FakeBlock = (_a) => {
    var { height = '48px', width = '100%' } = _a, props = __rest(_a, ["height", "width"]);
    return (React.createElement(Block, Object.assign({ backgroundColor: COLORS.gray4, borderRadius: '4px', width: width, height: height }, props)));
};
const GroupedFakeBlocks = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(FakeBlock, { width: '10%', height: '16px' }),
        React.createElement(FakeBlock, { width: '90%' }),
        React.createElement(Stack, { direction: 'horizontal', gap: '24px' },
            React.createElement(Stack, null,
                React.createElement(FakeBlock, { minWidth: '304px' }),
                React.createElement(FakeBlock, null)),
            React.createElement(FakeBlock, { height: '104px' })),
        React.createElement(FakeBlock, { height: '2px' }),
        React.createElement(FakeBlock, { width: '10%', height: '16px' }),
        React.createElement(FakeBlock, { width: '90%' }),
        React.createElement(Stack, { direction: 'horizontal', gap: '24px' },
            React.createElement(Stack, null,
                React.createElement(FakeBlock, { minWidth: '304px' }),
                React.createElement(FakeBlock, null)),
            React.createElement(FakeBlock, { height: '104px' }))));
};
export { FakeBlock, GroupedFakeBlocks };
