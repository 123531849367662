import { __awaiter } from "tslib";
import React, { useCallback, useEffect } from 'react';
import { Block, Typography, TypographyVariant, COLORS, Stack, toaster, Icons, DataTable, useDataTableState, SemanticButton, Tag, } from '@humanfirst/elektron';
import { formatDateFromNow } from 'src/utils/date';
import { useShowModalBound } from 'src/hooks/modal';
import { useUploadStatus } from 'src/hooks/upload/upload-status';
import { writeToClipboard } from './clipboardUtils';
const contentTypeToExtension = (mimeType) => {
    switch (mimeType) {
        case 'text/plain':
            return 'TXT';
        case 'application/pdf':
            return 'PDF';
        case 'image/jpeg':
            return 'JPG';
        case 'image/png':
            return 'PNG';
        case 'application/msword':
            return 'DOC';
        case 'text/html':
            return 'HTML';
        case 'application/json':
            return 'JSON';
        case 'text/csv':
            return 'CSV';
        default:
            return 'N/A';
    }
};
const TITLE_COLUMN = {
    id: 'file',
    header: 'File',
    render: (item) => {
        var _a;
        return (React.createElement(Stack, { direction: "horizontal", gap: "16px", alignItems: 'center' },
            React.createElement(Block, { borderRadius: "8px", backgroundColor: COLORS.gray4, padding: "8px", width: "56px", height: "56px" },
                React.createElement(Block, { display: "flex", justifyContent: "center" },
                    React.createElement(Icons.Misc.Report, { color: COLORS.gray1, decorative: true, size: "1.4em" })),
                React.createElement(Typography, { align: "center", variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, contentTypeToExtension(item.contentType))),
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, item.name),
                React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1 },
                    "Uploaded ",
                    formatDateFromNow((_a = item === null || item === void 0 ? void 0 : item.lastModified) !== null && _a !== void 0 ? _a : '')))));
    },
};
const ActionCell = ({ item }) => {
    const [isCopied, setIsCopied] = React.useState(false);
    const handleCopyClick = useCallback((originalUrl) => __awaiter(void 0, void 0, void 0, function* () {
        const parsedURL = new URL(originalUrl);
        parsedURL.searchParams.delete('jwt');
        const cleanedURL = parsedURL.toString();
        try {
            yield writeToClipboard(cleanedURL);
            setIsCopied(true);
        }
        catch (err) {
            toaster.warning('Unable to copy link to clipboard');
        }
    }), []);
    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [isCopied]);
    const showModal = useShowModalBound('upload-removal', {
        file: item.name,
    });
    return (React.createElement(Stack, { direction: "horizontal", justifyContent: 'flex-end' },
        React.createElement(SemanticButton, { onClick: () => handleCopyClick(item.url) }, isCopied ? (React.createElement(Icons.Misc.Check, { title: 'Copied', size: '20px', color: COLORS.success })) : (React.createElement(Icons.Misc.Copy, { title: 'Copy', color1: COLORS.gray1, size: '20px' }))),
        React.createElement(SemanticButton, { onClick: showModal },
            React.createElement(Icons.Misc.Trash, { title: "Request Removal", color: COLORS.gray1, size: '20px' }))));
};
const ACTIONS_COLUMN = {
    id: 'actions',
    header: '',
    render: (item) => {
        return React.createElement(ActionCell, { item: item });
    },
};
const DocumentStatus = ({ workspaceId, item }) => {
    const { data: status } = useUploadStatus(workspaceId, item.key);
    if (!status) {
        return null;
    }
    switch (status) {
        case 'accepted':
            return (React.createElement(Tag, { closeable: false, kind: "positive" }, "Accepted"));
        case 'rejected':
            return (React.createElement(Tag, { closeable: false, kind: "negative" }, "Rejected"));
        case 'pending':
            return (React.createElement(Tag, { closeable: false, kind: "neutral" }, "Pending"));
        default:
            return (React.createElement(Tag, { closeable: false, kind: "neutral" }, status));
    }
};
export const DocumentTable = ({ data, workspaceId, isLoading }) => {
    const STATUS_COLUMN = {
        id: 'status',
        header: 'Status',
        render: (item) => React.createElement(DocumentStatus, { item: item, workspaceId: workspaceId }),
    };
    const columns = [TITLE_COLUMN, STATUS_COLUMN, ACTIONS_COLUMN];
    const result = useDataTableState({ columns, searchable: false }, data !== null && data !== void 0 ? data : []);
    if ((!data || data.length < 1) && !isLoading) {
        return (React.createElement(Typography, { variant: TypographyVariant.Body }, "No custom data uploaded yet."));
    }
    return React.createElement(DataTable, Object.assign({}, result, { isLoading: isLoading }));
};
