import { FilterType } from '@humanfirst/elektron';
import { useContext, useMemo } from 'react';
import { uniq } from 'ramda';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { useHasPermission } from '../permissions/useHasPermission';
export const useGetConnectInstrumentScopes = () => {
    const { currentStudyTechIds, currentInstrumentIds } = useContext(ConnectContext);
    const hasConnectStudiesAndDHTs = useHasPermission('connect', 'read', 'all');
    const instrumentOrStudyIds = useMemo(() => {
        if (!hasConnectStudiesAndDHTs) {
            return [];
        }
        return uniq([...currentStudyTechIds, ...(currentInstrumentIds !== null && currentInstrumentIds !== void 0 ? currentInstrumentIds : [])]);
    }, [currentInstrumentIds, currentStudyTechIds, hasConnectStudiesAndDHTs]);
    return {
        technologyTableScopes: instrumentOrStudyIds.length
            ? [
                {
                    fieldId: 'airtableId',
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: instrumentOrStudyIds,
                },
            ]
            : [],
        evidenceTableScopes: instrumentOrStudyIds.length
            ? [
                {
                    fieldId: 'toolIds',
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: instrumentOrStudyIds,
                },
            ]
            : [],
    };
};
