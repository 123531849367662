import { EvidenceResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { useEvidenceFacet } from 'src/hooks/facets';
import { getCTPhasesWithCountValues } from 'src/utils/clinicalTrials';
/** Combine the results of multiple API calls */
const combineResults = (results) => {
    return {
        isLoading: results.some((x) => x.result.isLoading),
        data: results.map(({ label, result }) => {
            var _a, _b;
            return { label, count: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0 };
        }),
    };
};
export const useCTPhasesCounts = (scope) => {
    var _a;
    const { data, isLoading } = useEvidenceFacet('ctPhase', [
        ...scope,
        ['typeOfSource', '$eq', 'ClinicalTrials.gov'],
    ]);
    if (isLoading) {
        return { data: [], isLoading: true };
    }
    return {
        data: getCTPhasesWithCountValues((_a = data) !== null && _a !== void 0 ? _a : []),
        isLoading: false,
    };
};
/** Use V3 counts for a given scope. */
export const useV3Counts = (scope) => {
    var _a;
    const av = useElektraApi(EvidenceResource.getStatistics({
        filter: [...scope, ['v3', '$eq', 'Analytical Validation']],
    }));
    const cv = useElektraApi(EvidenceResource.getStatistics({
        filter: [...scope, ['v3', '$eq', 'Clinical Validation']],
    }));
    const verification = useElektraApi(EvidenceResource.getStatistics({
        filter: [...scope, ['v3', '$eq', 'Verification']],
    }));
    const combine = combineResults([
        { label: 'Verification', result: verification },
        { label: 'Analytical Validation', result: av },
        { label: 'Clinical Validation', result: cv },
    ]);
    return { data: (_a = combine.data) !== null && _a !== void 0 ? _a : [], isLoading: combine.isLoading };
};
/** Use Evidence counts for a given scope. */
export const useEvidenceCounts = (scope) => {
    var _a;
    const papers = useElektraApi(EvidenceResource.getStatistics({
        filter: [...scope, ['typeOfSource', '$eq', 'Paper']],
    }));
    const clinicalTrials = useElektraApi(EvidenceResource.getStatistics({
        filter: [...scope, ['typeOfSource', '$eq', 'ClinicalTrials.gov']],
    }));
    const other = useElektraApi(EvidenceResource.getStatistics({
        filter: [
            ...scope,
            ['typeOfSource', '$ne', 'Paper'],
            ['typeOfSource', '$ne', 'ClinicalTrials.gov'],
        ],
    }));
    const combine = combineResults([
        { label: 'Papers', result: papers },
        { label: 'Clinical Trials', result: clinicalTrials },
        { label: 'Other', result: other },
    ]);
    return { data: (_a = combine.data) !== null && _a !== void 0 ? _a : [], isLoading: combine.isLoading };
};
