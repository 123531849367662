import { FilterableFieldType, NumericRangeFilter, } from '@humanfirst/elektron';
import { useMemo } from 'react';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getCategories } from 'src/utils/filters';
import { getMeasurementHierarchy } from 'src/utils/measurements';
import { checkIsCEMarked, checkIsFdaQualifiedSurvey, checkIsMDDTQualifiedSurvey, } from 'src/utils/regulatoryStatus';
import { flattenTree } from 'src/utils/tree';
import { AlgoliaCategoricalFilter } from '../shared/algolia-filter-helpers';
export const FILTERS = [
    AlgoliaCategoricalFilter('technologyType', {
        label: 'Technology Type',
        getValue: (item) => {
            var _a;
            if (item.componentType === 'Clinical Outcome Assessment (COA)') {
                return 'Questionnaire';
            }
            return (_a = item.componentType) !== null && _a !== void 0 ? _a : '';
        },
        metadata: { group: 2, example: 'e.g., physical sensor, software' },
    }),
    AlgoliaCategoricalFilter('airtableId', {
        label: 'Model ID',
        getValue: (item) => item.id,
        metadata: { priority: 'hidden' },
    }),
    AlgoliaCategoricalFilter('regulatoryDecisions', {
        label: 'Regulatory Decision',
        getValue: (item) => {
            const returnValue = [];
            if (checkIsCEMarked(item)) {
                returnValue.push('CE Marked');
            }
            if (item.fdaStatus) {
                returnValue.push(item.fdaStatus);
            }
            if (checkIsFdaQualifiedSurvey(item)) {
                returnValue.push('FDA Qualified Survey');
            }
            if (checkIsMDDTQualifiedSurvey(item)) {
                returnValue.push('MDDT Qualified COA');
            }
            return returnValue;
        },
        metadata: { group: 1, example: 'e.g., FDA registration, CE Mark' },
    }),
    AlgoliaCategoricalFilter('availability', {
        label: 'Availability',
        getValue: (item) => {
            var _a;
            return (_a = item.availability) !== null && _a !== void 0 ? _a : 'Unable to determine';
        },
        metadata: {
            group: 2,
            example: 'e.g., available, discontinued',
            priority: 'priority',
        },
    }),
    NumericRangeFilter('productEvidence', {
        label: 'Product Evidence Count',
        categories: getCategories([
            [5, Infinity],
            [10, Infinity],
            [50, Infinity],
            [100, Infinity],
        ], FilterableFieldType.NUMERIC_RANGE),
        getValue: (item) => item.countOfEvidenceMeasures || 0,
        metadata: { group: 1, example: 'e.g., >5, >10' },
    }),
    NumericRangeFilter('organizationEvidence', {
        label: 'Vendor Evidence Count',
        categories: getCategories([
            [5, Infinity],
            [10, Infinity],
            [50, Infinity],
            [100, Infinity],
        ], FilterableFieldType.NUMERIC_RANGE),
        getValue: (item) => { var _a, _b; return (_b = (_a = item.maker) === null || _a === void 0 ? void 0 : _a.evidenceCount) !== null && _b !== void 0 ? _b : 0; },
        metadata: { group: 1, example: 'e.g., >5, >10' },
    }),
    AlgoliaCategoricalFilter('formFactor', {
        label: 'Form Factor',
        getValue: (item) => { var _a; return (_a = item.formFactor) !== null && _a !== void 0 ? _a : []; },
        metadata: { group: 2, example: 'e.g., strap, watch' },
    }),
    AlgoliaCategoricalFilter('wearLocation', {
        label: 'Wear Location',
        getValue: (item) => { var _a; return (_a = item.wearLocation) !== null && _a !== void 0 ? _a : []; },
        metadata: { group: 2, example: 'e.g., wrist, chest' },
    }),
    AlgoliaCategoricalFilter('modalitiesSensorType', {
        label: 'Sensor Modality',
        getValue: (item) => { var _a; return (_a = item.modalitiesSensorType) !== null && _a !== void 0 ? _a : []; },
        metadata: { group: 2, example: 'e.g., accelerometer, glucometer' },
    }),
    AlgoliaCategoricalFilter('waterproof', {
        label: 'Waterproof',
        getValue: (item) => { var _a; return (_a = item.waterproof) !== null && _a !== void 0 ? _a : []; },
        metadata: { group: 2, example: 'e.g., safe for swimming' },
    }),
    AlgoliaCategoricalFilter('api', {
        label: 'API',
        getValue: (item) => item.hasAPI && item.hasAPI !== 'Not specified' ? item.hasAPI : '',
        metadata: { group: 3, example: 'e.g., API available' },
    }),
    AlgoliaCategoricalFilter('sdk', {
        label: 'SDK',
        getValue: (item) => item.hasSDK && item.hasSDK !== 'Not specified' ? item.hasSDK : '',
        metadata: { group: 3, example: 'e.g., SDK available' },
    }),
];
export const MEASURES_FILTER = AlgoliaCategoricalFilter('allMeasuresFlat', {
    label: 'Measure',
    getValue: (item) => {
        var _a;
        return getMeasurementHierarchy((_a = item.measuresCategory) !== null && _a !== void 0 ? _a : []).flatMap(flattenTree) ||
            [];
    },
    metadata: {
        priority: 'priority',
        group: 1,
        example: 'e.g., sleep, activity',
    },
});
export const MEDICAL_CONDITION_FILTER = AlgoliaCategoricalFilter('medicalConditions', {
    label: 'Medical Condition',
    getValue: (item) => { var _a, _b; return (_b = (_a = item.medicalConditions) === null || _a === void 0 ? void 0 : _a.flatMap((mc) => mc.name)) !== null && _b !== void 0 ? _b : []; },
    metadata: {
        priority: 'priority',
        group: 1,
        example: "e.g., Parkinson's Disease",
    },
});
const DATA_PLATFORM_FILTER = AlgoliaCategoricalFilter('dataIntegrator', {
    label: 'Data Platform',
    metadata: { group: 3, example: 'e.g., Current Health, Koneksa Heath' },
});
export const useToolFilters = (includeAlgoliaOnlyFilters) => {
    const canViewMeasures = useHasPermission('searchResult.evidenceMeasures', 'read');
    const canViewMedicalConditions = useHasPermission('searchResult.medicalConditions', 'read');
    return useMemo(() => {
        return [
            ...(canViewMedicalConditions ? [MEDICAL_CONDITION_FILTER] : []),
            ...(canViewMeasures ? [MEASURES_FILTER] : []),
            ...FILTERS,
            ...(includeAlgoliaOnlyFilters ? [DATA_PLATFORM_FILTER] : []),
        ];
    }, [canViewMeasures, canViewMedicalConditions, includeAlgoliaOnlyFilters]);
};
