import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
const DEFAULT_LEAVE_CONFIRMATION = "It looks like you were editing something — changes you've entered may be lost if you leave the page now.  Are you sure?";
const BEFORE_UNLOAD_EVENT = 'beforeunload';
const SmartPromptWhenDirty = () => {
    const { formState: { isDirty, isSubmitting }, } = useFormContext();
    const when = isDirty && !isSubmitting;
    const onBeforeUnloadCallback = useCallback((e) => {
        // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.preventDefault();
        // Chrome requires returnValue to be set, in some versions?
        e.returnValue = DEFAULT_LEAVE_CONFIRMATION;
        return DEFAULT_LEAVE_CONFIRMATION;
    }, []);
    useEffect(() => {
        if (!when) {
            return;
        }
        window.addEventListener(BEFORE_UNLOAD_EVENT, onBeforeUnloadCallback);
        return () => window.removeEventListener(BEFORE_UNLOAD_EVENT, onBeforeUnloadCallback);
    }, [onBeforeUnloadCallback, when]);
    return null;
};
export { SmartPromptWhenDirty };
