import { CardContent, CardSurface, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { OrganizationProductTable } from './ProductTable';
export const VendorTechnologies = ({ organization }) => {
    return (React.createElement(CardSurface, null,
        React.createElement(CardContent, null,
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Products"),
                React.createElement(OrganizationProductTable, { organization: organization })))));
};
