import { uniqBy } from 'ramda';
import { deserializeWorkspaceMetadata, groupMeasures, splitMetadata, } from 'src/utils/workspaceMetadata';
/** Gets measures for a study. */
const getMeasuresForStudy = (study) => {
    const { measureMetadata } = splitMetadata(deserializeWorkspaceMetadata(study.additionalMetadata));
    return uniqBy((x) => x.conceptsOfInterest, groupMeasures(measureMetadata)).map((x) => ({ category: x.conceptsOfInterest, id: x.measureId }));
};
/** Gets therapeutic areas for a study. */
const getTherapeuticAreasForStudy = (study, therapeuticAreas) => {
    const ta = therapeuticAreas.find((x) => x.id === study.therapeuticArea);
    return ta ? [ta] : [];
};
/** Gets tools for a study. */
const getToolsforStudy = (study, tools) => {
    var _a, _b;
    return ((_b = (_a = study.tools) === null || _a === void 0 ? void 0 : _a.map((x) => tools.find((y) => y.id === x)).filter((x) => !!x)) !== null && _b !== void 0 ? _b : []);
};
/** Gets medical conditions for a study. */
const getMedicalConditionsForStudy = (study, medicalConditions) => {
    var _a, _b;
    return ((_b = (_a = study.medicalConditions) === null || _a === void 0 ? void 0 : _a.map((x) => medicalConditions.find((y) => y.id === x)).filter((x) => !!x)) !== null && _b !== void 0 ? _b : []);
};
/** Attaches all necessary metadata to a study. */
export const getStudyWithRelationships = (study, context) => {
    return Object.assign(Object.assign({}, study), { relationships: {
            measureCategories: getMeasuresForStudy(study),
            therapeuticAreas: getTherapeuticAreasForStudy(study, context.therapeuticAreas),
            tools: getToolsforStudy(study, context.technologies),
            medicalConditions: getMedicalConditionsForStudy(study, context.medicalConditions),
        } });
};
