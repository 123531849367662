import { useMemo } from 'react';
import { useLogger } from 'src/hooks/logger';
import { HeapAnalyticsService } from './heap';
import { LogRocketAnalyticsService } from './logrocket';
const SERVICES = [HeapAnalyticsService, LogRocketAnalyticsService];
const useAnalyticsServices = () => {
    const logger = useLogger(useAnalyticsServices.name);
    return useMemo(() => {
        const initializedServices = [];
        for (const service of SERVICES) {
            if (!service.isInitialized()) {
                try {
                    service.initialize();
                    initializedServices.push(service);
                }
                catch (e) {
                    logger.error(e, {
                        service: service.name,
                    });
                }
            }
            else {
                // We've already initialized elsewhere.
                initializedServices.push(service);
            }
        }
        return initializedServices;
    }, [logger]);
};
export { useAnalyticsServices };
