import React from 'react';
import { Stack, Checkbox, Typography, Link } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { PATHS } from 'src/config/path';
const TogglePMC = ({ setFilterEvidenceByPMC, filterEvidenceByPMC, conditionName, }) => {
    return (React.createElement(Stack, { direction: "horizontal", alignItems: "flex-start" },
        React.createElement(Checkbox, { onChange: () => setFilterEvidenceByPMC((prev) => !prev), checked: filterEvidenceByPMC, checkmarkType: "toggle", labelPlacement: "right" }, "Filter by primary research focus"),
        React.createElement(AnchoredTooltip, null,
            React.createElement(Stack, null,
                React.createElement(If, { condition: filterEvidenceByPMC },
                    React.createElement(Then, null,
                        React.createElement(Typography, null,
                            "Currently, this page displays only evidence where",
                            ' ',
                            React.createElement("strong", null, conditionName),
                            " was the primary research focus."),
                        React.createElement(Typography, null,
                            "To view all evidence where ",
                            React.createElement("strong", null, conditionName),
                            " was reported, please use the toggle.")),
                    React.createElement(Else, null,
                        React.createElement(Typography, null,
                            "Currently, this page displays all evidence where",
                            ' ',
                            React.createElement("strong", null, conditionName),
                            " was reported."),
                        React.createElement(Typography, null,
                            "To view evidence where ",
                            React.createElement("strong", null, conditionName),
                            " was the primary research focus, please use the toggle."))),
                React.createElement(Typography, null,
                    "Read our",
                    ' ',
                    React.createElement(Link, { href: PATHS.helpCenter.usingAtlas.medicalConditions.exploreOverview }, "Help Center article"),
                    ' ',
                    "for more details on using medical condition pages.")))));
};
export { TogglePMC };
