import { __awaiter } from "tslib";
import { FilterableFieldType, } from '@humanfirst/elektron';
import { useCallback, useMemo } from 'react';
import { useSearchWithFacets, useSearchTypeSpecificIndexAndCredentials, } from 'src/hooks/search';
import { serializeFilters } from 'src/hooks/search/filters';
import { deserializeQuery } from 'src/utils/query';
/** Annotates a filter schema with counts for the categories. */
const annotateWithCounts = (filterSchema, facet) => {
    const categories = filterSchema.categories;
    return Object.assign(Object.assign({}, filterSchema), { categories: categories
            .map((x) => {
            var _a;
            const count = (_a = facet === null || facet === void 0 ? void 0 : facet.facets.find((y) => x.label === y.facet)) === null || _a === void 0 ? void 0 : _a.count;
            if (!count) {
                return null;
            }
            else {
                return Object.assign(Object.assign({}, x), { metadata: count });
            }
        })
            .filter((x) => !!x) });
};
/** Builds a bound search function. */
const buildSearchFunction = (searchFacets, facetName, transform) => {
    return (query) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield searchFacets(facetName, query, true /** showCount */);
        return transform
            ? result.map((x) => (Object.assign(Object.assign({}, x), { label: transform(x.label) })))
            : result;
    });
};
/** Build the appropriate filters. */
export const useBuildFilters = (schema, { searchFacets, facets, }) => {
    return useMemo(() => {
        if (!schema.filters) {
            return [];
        }
        return schema.filters.map((x) => {
            var _a, _b;
            // It's really difficult to get the types passed through here,
            // so we're just going to do a cast for now.
            const filterSchema = x;
            const transform = filterSchema.visualFacetTransform;
            if (filterSchema.type !== FilterableFieldType.CATEGORICAL &&
                filterSchema.type !== FilterableFieldType.MULTI_CATEGORICAL) {
                // If it's not a categorical filter, we can't do any category generation, so
                // return it unmodified.
                return filterSchema;
            }
            const facet = facets.find((y) => y.field === filterSchema.facetName);
            if (!filterSchema.generateOptions) {
                // In cases where we're not generating new options, we may still want to transform
                // by appending counts.
                return annotateWithCounts(filterSchema, facet);
            }
            else if (filterSchema.useImmediateFacets) {
                // We've been asked to provide values, directly from our fetched facets.
                return Object.assign(Object.assign({}, filterSchema), { categories: (_b = (_a = facets
                        .find((x) => x.field === filterSchema.facetName)) === null || _a === void 0 ? void 0 : _a.facets.map((x) => ({
                        label: transform ? transform(x.facet) : x.facet,
                        value: x.facet,
                        metadata: x.count,
                    }))) !== null && _b !== void 0 ? _b : [] });
            }
            else {
                // By default we provide a search function.
                return Object.assign(Object.assign({}, filterSchema), { categories: buildSearchFunction(searchFacets, filterSchema.facetName, transform) });
            }
        });
    }, [schema, searchFacets, facets]);
};
/** Apply the search to Algolia. */
export const useApplySearchAlgolia = (state, schema, algoliaConfiguration) => {
    // We want to support advance queries in the search
    const { finalFilters, search } = useMemo(() => {
        const { search, filters: queryFilters } = deserializeQuery(state.query);
        return {
            finalFilters: [...state.filters, ...(queryFilters !== null && queryFilters !== void 0 ? queryFilters : [])],
            search,
        };
    }, [state.filters, state.query]);
    const result = useSearchWithFacets({
        searchType: algoliaConfiguration.searchType,
        query: search,
        filters: finalFilters,
        page: state.page,
        sort: algoliaConfiguration.getSortIndex(state),
    });
    // Translate from the schema -> final available filters
    const filters = useBuildFilters(schema, result);
    const { searchClientResults, index } = useSearchTypeSpecificIndexAndCredentials(algoliaConfiguration.searchType, true);
    const getAllResults = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const { searchClient, isReady } = searchClientResults;
        if (!isReady) {
            return [];
        }
        const { results } = yield searchClient.search([
            {
                indexName: index,
                params: {
                    query: search !== null && search !== void 0 ? search : '',
                    filters: serializeFilters(finalFilters !== null && finalFilters !== void 0 ? finalFilters : []),
                    hitsPerPage: 250,
                },
            },
        ]);
        return (_b = (_a = results[0]) === null || _a === void 0 ? void 0 : _a.hits.map((x) => x.objectID)) !== null && _b !== void 0 ? _b : [];
    }), [finalFilters, index, search, searchClientResults]);
    return {
        isLoading: result.isLoading,
        data: result.data,
        totalItemCount: result.hitCount,
        pageCount: result.pageCount,
        availableFilters: filters,
        getAllResults: schema.selectable ? getAllResults : undefined,
    };
};
