import React from 'react';
import { COLORS, Icons, Typography, TypographyVariant, Stack, Block, } from '@humanfirst/elektron';
import { useIsShadowing } from 'src/hooks/authentication';
/** Summary information about a user. */
const UserSummary = ({ user, }) => {
    var _a, _b;
    const isShadowing = useIsShadowing();
    return (React.createElement(Stack, { alignItems: "center", direction: "horizontal" },
        React.createElement(Stack, { direction: "vertical", maxWidth: "160px", overflow: "hidden", gap: "0" },
            React.createElement(Typography, { variant: TypographyVariant.BodyBold, singleLine: true, align: "left" }, (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : null),
            React.createElement(Typography, { variant: TypographyVariant.Label, color: COLORS.gray1, singleLine: true, align: "left" }, (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : null)),
        React.createElement(Block, { flex: "1" },
            React.createElement(Icons.Misc.User, { size: "1.5em", color: isShadowing ? COLORS.alert : COLORS.gray1, title: "User" }))));
};
export { UserSummary };
