import { Block, COLORS, Divider, Select, Spacer, Spread, Stack, Typography, TypographyVariant, toOptions, toValue, } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceEventResource, } from '@humanfirst/use-elektra-api';
import React, { useMemo, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { useEventsResourceNameMap } from 'src/hooks/workspace';
import { LoadingState } from 'src/components/LoadingState';
import { getEventsToShow, getVirtualEvents, } from './utils';
const workspaceSectionOptions = [
    'Technologies',
    'Searches',
    'Medical Conditions',
    'Measures',
    'Vendors',
    'Evidence',
    'Studies',
    'Comparisons',
    'Everything',
];
/**
 * Renders a log of all events in this workspace.
 */
export const ActivityLog = ({ workspace }) => {
    const [filter, setFilter] = useState('Everything');
    const { isLoading: isLoadingEvents, data: events } = useElektraApi(WorkspaceEventResource.list(workspace.id));
    const { isLoading: isLoadingMetadata, data: resourceNameMap } = useEventsResourceNameMap(events);
    const isLoading = isLoadingEvents || isLoadingMetadata;
    const eventsToShow = useMemo(() => {
        return getEventsToShow(events, filter);
    }, [events, filter]);
    const virtualEvents = useMemo(() => {
        if (!eventsToShow || !resourceNameMap) {
            return [];
        }
        return eventsToShow.flatMap((e) => getVirtualEvents(e, resourceNameMap));
    }, [eventsToShow, resourceNameMap]);
    return (React.createElement(Stack, { padding: '36px' },
        React.createElement(Spread, { gap: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Activity for"),
            React.createElement(Block, { flex: "1" },
                React.createElement(Select, { "aria-label": "Select a section", clearable: false, searchable: false, options: toOptions(workspaceSectionOptions), value: toValue(filter), onChange: (x) => setFilter(x.value[0].id) }))),
        React.createElement(Divider, null),
        React.createElement(Spacer, null),
        React.createElement(If, { condition: isLoading },
            React.createElement(Then, null,
                React.createElement(LoadingState, null)),
            React.createElement(Else, null,
                React.createElement(When, { condition: !isLoading && virtualEvents.length < 1 },
                    React.createElement(Typography, { color: COLORS.gray3 }, "There is no current activity within this section.")),
                React.createElement(Stack, { maxHeight: '60vh', overflow: 'scrollY' }, virtualEvents === null || virtualEvents === void 0 ? void 0 : virtualEvents.map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                React.createElement(Block, { key: i }, x))))))));
};
