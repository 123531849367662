import { Link, Block, TextColumn, TypographyVariant, Typography, } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { LimitedList } from 'src/components/LimitedList';
import { ToolSummary } from 'src/components/ToolSummary';
import { PATHS } from 'src/config/path';
import { useLogger } from 'src/hooks/logger';
import { generatePath } from 'src/utils/path';
import { getMCsFromStudyAndTeamMetadata, getMeasuresFromStudyAndTeamMetadata, } from './utils';
const ProductCell = ({ item }) => {
    var _a;
    return (React.createElement(ToolSummary, { tool: item.technology, href: generatePath(PATHS.atlas.technologies.detail, {
            id: (_a = item.technology) === null || _a === void 0 ? void 0 : _a.id,
        }) }));
};
const PRODUCT_COLUMN = TextColumn('product', {
    header: 'Technology',
    render: (item) => React.createElement(ProductCell, { item: item }),
    metadata: { displayRole: 'required' },
    sortable: true,
    getStringValue: (item) => { var _a; return `${item.technology.model}${(_a = item.technology.maker) === null || _a === void 0 ? void 0 : _a.name}`; },
    searchable: true,
});
const STUDY_COUNT_COLUMN = TextColumn('studyCount', {
    header: 'Studies',
    render: (item) => item.metadata.studies.length ? String(item.metadata.studies.length) : '',
    metadata: { displayRole: 'required' },
    sortable: true,
    getStringValue: (item) => { var _a; return String((_a = item.metadata.studies.length) !== null && _a !== void 0 ? _a : 0); },
});
const LimitedListMaybeLink = ({ items, resourcePathBase }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(LimitedList, { elements: items === null || items === void 0 ? void 0 : items.map((m) => (React.createElement(Block, { key: m.name },
                React.createElement(If, { condition: m.id },
                    React.createElement(Then, null,
                        React.createElement(Link, { href: generatePath(resourcePathBase, {
                                id: m.id,
                            }), typographyVariant: TypographyVariant.Details }, m.name)),
                    React.createElement(Else, null,
                        React.createElement(Typography, { variant: TypographyVariant.Details }, m.name)))))), limit: 1 })));
};
/** Renders the measures associated with a product. */
const MeasuresCell = ({ item }) => {
    const logger = useLogger('my-organization-data');
    const measures = getMeasuresFromStudyAndTeamMetadata(item, logger);
    return (React.createElement(LimitedListMaybeLink, { items: measures, resourcePathBase: PATHS.atlas.measures.detail }));
};
/** Renders the medical conditions associated with a product. */
const MedicalConditionCell = ({ item }) => {
    const logger = useLogger('my-organization-data');
    const mcs = getMCsFromStudyAndTeamMetadata(item, logger);
    return (React.createElement(LimitedListMaybeLink, { items: mcs, resourcePathBase: PATHS.atlas.medicalConditions.detail }));
};
const MEASURES_COLUMN = {
    id: 'measures',
    header: 'Measures',
    render: (item) => React.createElement(MeasuresCell, { item: item }),
    searchable: true,
    metadata: { displayRole: 'display' },
    getStringValue: (item) => getMeasuresFromStudyAndTeamMetadata(item)
        .map((x) => x.name)
        .join(', '),
};
const MC_COLUMN = {
    id: 'medicalConditions',
    header: 'Medical Conditions',
    render: (item) => React.createElement(MedicalConditionCell, { item: item }),
    searchable: true,
    getStringValue: (item) => getMCsFromStudyAndTeamMetadata(item)
        .map((x) => x.name)
        .join(', '),
    metadata: { displayRole: 'hidden' },
};
const baseColumns = [
    PRODUCT_COLUMN,
    MEASURES_COLUMN,
    MC_COLUMN,
    STUDY_COUNT_COLUMN,
];
export const useColumns = () => {
    return baseColumns;
};
export { MEASURES_COLUMN, PRODUCT_COLUMN, STUDY_COUNT_COLUMN, MC_COLUMN };
