import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { checkPermissions } from 'src/utils/permissions';
const useHasPermissions = (permissions, logic = 'every') => {
    const { user, grants } = useContext(UserContext);
    const checkPermission = ({ resource, action, extra }) => {
        if (!user) {
            // Unauthenticated users have no permissions.
            // NOTE: We may need to reexamine this, but basically if an unauthenticated
            // user should do it, just don't do a permission check.
            return false;
        }
        else {
            return checkPermissions({ user, grants: grants || [] }, resource, action, extra);
        }
    };
    if (logic === 'every') {
        return permissions.every(checkPermission);
    }
    else {
        return permissions.some(checkPermission);
    }
};
export { useHasPermissions };
