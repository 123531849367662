import { uniq } from 'ramda';
import React, { createContext, useCallback, useMemo, useState, } from 'react';
import { useLocation, matchPath } from 'react-router';
import { useGetAllStudiesWithMetadata, } from 'src/hooks/studies/studyMetadata';
import { useUser } from 'src/hooks/user';
import { useGetTeamsMetadata } from 'src/utils/teamMetadata';
/** Context for handling the active state of different connect items */
const ConnectContext = createContext({
    currentStudies: [],
    currentStudyTechIds: [],
    currentInstrumentIds: [],
    userStudies: { isLoading: false, data: [] },
    userTeamMetadata: { isLoading: false, data: [] },
    toggleCurrentStudies: () => null,
    resetConnectSelections: () => null,
    toggleCurrentInstruments: () => null,
    isViewingFromConnectContext: false,
});
/** Paths to determine if currently in a connect context. */
const CONNECT_CONTEXT_PATHS = ['/catalog'];
const ConnectProvider = ({ children }) => {
    var _a;
    const user = useUser();
    const location = useLocation();
    const pathname = location.pathname;
    const isViewingFromConnectContext = useMemo(() => CONNECT_CONTEXT_PATHS.some((path) => {
        const match = matchPath({ path, end: false }, pathname);
        return match !== null;
    }), [pathname]);
    const teams = (_a = user === null || user === void 0 ? void 0 : user.teams) !== null && _a !== void 0 ? _a : [];
    const [currentStudies, setCurrentStudies] = useState([]);
    const [currentInstrumentIds, setCurrentInstrumentsIds] = useState([]);
    const userStudiesResult = useGetAllStudiesWithMetadata();
    const userTeamMetadataResult = useGetTeamsMetadata(teams);
    const toggleCurrentStudies = useCallback((studies) => {
        const currentStudyIds = currentStudies.flatMap((x) => x.study.id);
        const isAlreadySelected = studies.every((x) => currentStudyIds.includes(x.study.id));
        if (!isAlreadySelected) {
            setCurrentStudies([...studies]);
        }
        else {
            setCurrentStudies([]);
        }
    }, [currentStudies]);
    const resetConnectSelections = useCallback(() => {
        setCurrentStudies([]);
        setCurrentInstrumentsIds([]);
    }, []);
    const toggleCurrentInstruments = useCallback((instrumentIds) => {
        const isAlreadySelected = instrumentIds.every((x) => currentInstrumentIds.includes(x));
        if (!isAlreadySelected) {
            setCurrentInstrumentsIds([...instrumentIds]);
        }
        else {
            setCurrentInstrumentsIds([]);
        }
    }, [currentInstrumentIds]);
    const value = useMemo(() => ({
        toggleCurrentStudies,
        currentStudies,
        currentStudyTechIds: uniq(currentStudies.flatMap((x) => x.parsedMetadata.technologies)),
        isViewingFromConnectContext,
        resetConnectSelections,
        currentInstrumentIds,
        toggleCurrentInstruments,
        userTeamMetadata: userTeamMetadataResult,
        userStudies: userStudiesResult,
    }), [
        toggleCurrentStudies,
        currentStudies,
        resetConnectSelections,
        currentInstrumentIds,
        toggleCurrentInstruments,
        userTeamMetadataResult,
        userStudiesResult,
        isViewingFromConnectContext,
    ]);
    return (React.createElement(ConnectContext.Provider, { value: value }, children));
};
export { ConnectContext, ConnectProvider };
