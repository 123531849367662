import { Stack, Typography, StatefulTooltip, SemanticButton, Button, Icons, Tooltip, COLORS, } from '@humanfirst/elektron';
import React, { useState } from 'react';
import { useShowModalBound } from 'src/hooks/modal';
const DEFAULT_BODY = 'Save to a project to continue where you left off.';
const AddToWorkspaceButton = ({ resourceId, resourceType, buttonContent = 'Save to Project', body = DEFAULT_BODY, variant = 'icon', iconSize, }) => {
    const showModal = useShowModalBound('add-to-workspace', {
        resourceId,
        resourceType,
    });
    if (variant === 'button') {
        return (React.createElement(StatefulTooltip, { content: React.createElement(Stack, { width: '264px' },
                React.createElement(Typography, null, body)), triggerType: "hover", placement: "bottom" },
            React.createElement(Button, { onClick: showModal, size: "large", variant: "tertiary", startEnhancer: React.createElement(Icons.Misc.Save, { decorative: true, size: iconSize !== null && iconSize !== void 0 ? iconSize : '16px', color: COLORS.gray1 }) }, buttonContent)));
    }
    return (React.createElement(StatefulTooltip, { content: React.createElement(Stack, { width: '264px' },
            React.createElement(Typography, null, body)), triggerType: "hover", placement: "bottomRight" },
        React.createElement(SemanticButton, { onClick: showModal },
            React.createElement(Icons.Misc.Save, { size: iconSize !== null && iconSize !== void 0 ? iconSize : '24px', title: "Save" }))));
};
const AddToWorkspaceBulkButton = ({ resourceIds, resourceType, buttonContent = 'Save to Project', body = DEFAULT_BODY, }) => {
    const showModal = useShowModalBound('add-to-workspace', {
        resourceIds,
        resourceType,
    });
    const [isOpen, setIsOpen] = useState(false);
    const isDisabled = resourceIds.length < 1;
    return (React.createElement(Tooltip, { content: React.createElement(Stack, { width: '264px' },
            React.createElement(Typography, null, body)), triggerType: "hover", placement: "bottom", isOpen: isOpen },
        React.createElement("div", { onPointerEnter: () => setIsOpen(true), onPointerLeave: () => setIsOpen(false) },
            React.createElement(Button, { onClick: showModal, variant: "secondary", startEnhancer: React.createElement(Icons.Misc.Save, { decorative: true }), disabled: isDisabled }, buttonContent))));
};
export { AddToWorkspaceButton, AddToWorkspaceBulkButton };
