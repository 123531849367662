import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
/** Resets the form when submit is successful. */
export const SmartFormResetOnSubmit = () => {
    const { reset, formState } = useFormContext();
    useEffect(() => {
        reset();
    }, [formState.isSubmitSuccessful, reset]);
    return null;
};
