var _a, _b, _c, _d, _e, _f, _g, _h;
/** Object storing possible indices for performing search */
const Indicies = {
    Tools: (_a = process.env.ALGOLIA_INDEX_TOOL) !== null && _a !== void 0 ? _a : 'elektra-tools',
    Evidence: (_b = process.env.ALGOLIA_INDEX_EVIDENCE) !== null && _b !== void 0 ? _b : 'hf-evidence',
    SearchTerms: (_c = process.env.ALGOLIA_INDEX_SEARCH_TERM) !== null && _c !== void 0 ? _c : 'hf-search-terms',
    OmniSearch: (_d = process.env.ALGOLIA_INDEX_OMNISEARCH) !== null && _d !== void 0 ? _d : 'production_omnisearch',
    Measures: (_e = process.env.ALGOLIA_INDEX_MEASURES) !== null && _e !== void 0 ? _e : 'hf-measures',
    MedicalConditions: (_f = process.env.ALGOLIA_INDEX_MEDICAL_CONDITIONS) !== null && _f !== void 0 ? _f : 'hf-medical-conditions',
};
const ALGOLIA_APP_ID = (_g = process.env.ALGOLIA_APP_ID) !== null && _g !== void 0 ? _g : 'G4YBATMS7Z';
const ALGOLIA_SEARCH_KEY = (_h = process.env.ALGOLIA_SEARCH_KEY) !== null && _h !== void 0 ? _h : '';
/** Measures that are available to users without the full measure search */
const FREE_SEARCHABLE_MEASURES = [
    `"Peripheral Blood Pressure"`,
    `"Body Temperature"`,
    `"Nocturnal Scratching"`,
    `"Heart Rate"`,
    `"Respiratory Rate"`,
    `"Activity"`,
    `"Sleep"`,
];
const FREE_SEARCHABLE_MEDICAL_CONDITIONS = [
    `"Parkinson's Disease"`,
    '"COPD"',
    '"Atrial Fibrillation"',
    '"Major Depressive Disorder"',
    '"Osteoarthritis"',
];
export { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, Indicies, FREE_SEARCHABLE_MEASURES, FREE_SEARCHABLE_MEDICAL_CONDITIONS, };
