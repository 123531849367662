import { Link, Spacer, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { TargetTagContext } from 'src/components/MeasurementTree/contexts/TargetTagContext';
import { MeasureTagPipWithTooltip, } from 'src/components/MeasureTag';
import { PATHS } from 'src/config/path';
import { TargetMeasureContext } from 'src/pages/Measures/MeasureDetails/contexts/TargetMeasureContext';
/** Displays Measure Category as Link with Tags as applicable. */
export const MeasureCategoryTreeNodeInternal = ({ category, categoryFullName, children, typographyVariant = TypographyVariant.Details, targetTag, targetNode, tags, }) => {
    const targetTagMatch = targetTag && !!tags ? tags === null || tags === void 0 ? void 0 : tags.includes(targetTag) : true;
    if (category === targetNode) {
        return (React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, children));
    }
    if (!categoryFullName) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details }, children));
    }
    return (React.createElement(Spread, { overrides: targetTagMatch ? undefined : { Block: { style: { opacity: 0.3 } } } },
        React.createElement(Link, { href: generatePath(PATHS.atlas.measuresName, {
                name: encodeURIComponent(categoryFullName),
            }), typographyVariant: typographyVariant, "data-testid": `measureLink${category}` }, children),
        React.createElement(Stack, { gap: '0px', direction: 'horizontal' },
            (tags === null || tags === void 0 ? void 0 : tags.filter((x) => x === 'shared').length) ? (React.createElement(MeasureTagPipWithTooltip, { key: 'shared', tag: 'shared' })) : (React.createElement(Spacer, { size: '14px' })),
            (tags === null || tags === void 0 ? void 0 : tags.filter((t) => t === 'evidence').length) ? (React.createElement(MeasureTagPipWithTooltip, { key: 'evidence', tag: 'evidence' })) : (React.createElement(Spacer, { size: '14px' })),
            (tags === null || tags === void 0 ? void 0 : tags.filter((t) => t === 'manufacturer').length) ? (React.createElement(MeasureTagPipWithTooltip, { key: 'manufacturer', tag: 'manufacturer' })) : (React.createElement(Spacer, { size: '14px' })),
            (tags === null || tags === void 0 ? void 0 : tags.filter((t) => t === 'fda').length) ? (React.createElement(MeasureTagPipWithTooltip, { key: 'fda', tag: 'fda' })) : (React.createElement(Spacer, { size: '14px' })),
            (tags === null || tags === void 0 ? void 0 : tags.filter((t) => t === 'author').length) ? (React.createElement(MeasureTagPipWithTooltip, { key: 'author', tag: 'author' })) : (React.createElement(Spacer, { size: '14px' })))));
};
/** Displays a single measure category wrapped as needed */
export const MeasureCategoryTreeNode = ({ state }) => {
    const content = state.value.content.measure;
    const tags = state.value.content.tags;
    const { targetMeasure } = useContext(TargetMeasureContext);
    const { targetTag } = useContext(TargetTagContext);
    return (React.createElement(MeasureCategoryTreeNodeInternal, { category: content, categoryFullName: state.value.content.fullName, typographyVariant: state.value.isOpen && !!state.children.length
            ? TypographyVariant.DetailsBold
            : TypographyVariant.Details, targetTag: targetTag, targetNode: targetMeasure, tags: tags }, content));
};
