import { COLORS, SemanticButton, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { useParams } from 'react-router';
import { ContactUsFormTrigger } from 'src/components/ContactUsFormTrigger';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getToolWithEvidence } from './util';
const ToolSupportingEvidenceCell = ({ item, evidence, showEvidence, }) => {
    const toolWithEvidence = getToolWithEvidence(item, evidence);
    const { id } = useParams();
    const hasEvidence = useHasPermission('medicalConditions.evidence', 'read', {
        id,
    });
    if (!hasEvidence) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ContactUsFormTrigger, { location: `Technology: ${item.model} (${item.id})`, contactType: 'Evidence Request' }, "Request Evidence")));
    }
    if (toolWithEvidence.evidenceCount === 0) {
        return (React.createElement(Typography, { color: COLORS.gray1 }, "Vendor Stated Medical Conditions Only"));
    }
    return (React.createElement(SemanticButton, { onClick: () => showEvidence('Technology', `${toolWithEvidence.tool.model}||${toolWithEvidence.tool.id}`) },
        React.createElement(Typography, { color: COLORS.primary, variant: TypographyVariant.BodyBold }, `See Supporting Evidence (${toolWithEvidence.evidenceCount})`)));
};
const MeasureCategorySupportingEvidenceCell = ({ item, showEvidence }) => {
    const { id } = useParams();
    const hasEvidence = useHasPermission('medicalConditions.evidence', 'read', {
        id,
    });
    if (!hasEvidence) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ContactUsFormTrigger, { location: `Measure: ${item.name} (${item.id})`, contactType: 'Evidence Request' }, "Request Evidence")));
    }
    if (item.evidenceCount === 0) {
        return null;
    }
    return (React.createElement(SemanticButton, { onClick: showEvidence ? () => showEvidence('Measures', item.name) : undefined },
        React.createElement(Typography, { color: COLORS.primary, variant: TypographyVariant.BodyBold }, `See Supporting Evidence (${item.evidenceCount})`)));
};
export { MeasureCategorySupportingEvidenceCell, ToolSupportingEvidenceCell, };
