import { __rest } from "tslib";
import { useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { QueryWrapper } from '../QueryWrapper';
/**
 * Internal handling once a resource has sucessfully loaded.
 *
 * Note that this can still bail out to the error component because we do a frontend permission check here.
 */
function CatalogResourceDetailsInternal({ data, permissionResourceName, errorComponent: ErrorComponent, resourceDetails: ResourceDetails, }) {
    const hasPermission = useHasPermission(permissionResourceName, 'read', data);
    if (!hasPermission) {
        return React.createElement(ErrorComponent, { error: 'Unauthorized' });
    }
    return React.createElement(ResourceDetails, { data: data });
}
/**
 * Shows details about a single catalog resource.
 *
 * This is mostly a logic component that handles loading and permission checks, but
 * it will also help with some layout concerns (seperating related data vs summary data).
 */
function CatalogResourceDetails(_a) {
    var { resource } = _a, props = __rest(_a, ["resource"]);
    const result = useElektraApi(resource);
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: CatalogResourceDetailsInternal, renderError: props.errorComponent }, result, props)));
}
export { CatalogResourceDetails };
