import { COLORS } from '@humanfirst/elektron';
export const createDataEvents = () => [
    {
        target: 'data',
        eventHandlers: {
            onMouseOver: () => {
                return [
                    {
                        target: 'data',
                        mutation: ({ style }) => ({
                            style: Object.assign(Object.assign({}, style), { fillOpacity: 0.5, strokeWidth: 1, stroke: COLORS.primary, cursor: 'pointer' }),
                        }),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: true }),
                    },
                ];
            },
            onMouseOut: () => {
                return [
                    {
                        target: 'data',
                        mutation: () => ({}),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: false }),
                    },
                ];
            },
        },
    },
];
