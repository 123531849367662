import React from 'react';
import { Block, Center, Spinner, Stack } from '@humanfirst/elektron';
import { When } from 'react-if';
import { CommentChain } from './components/CommentChain';
import { CommentForm } from './components/CommentForm';
import { useCanCreateComment, useComments, useCreateComment, } from './hooks/comments';
/**
 * Renders the main body for comment.
 */
const InternalWorkspaceResourceComments = ({ workspaceId, resourceType, resource, comments }) => {
    const createComment = useCreateComment(workspaceId, resourceType, resource);
    const canCreateComment = useCanCreateComment(workspaceId, resourceType, resource);
    return (React.createElement(Stack, null,
        React.createElement(Block, { maxHeight: "400px", width: "320px", overflow: "auto" },
            React.createElement(CommentChain, { comments: comments })),
        React.createElement(When, { condition: canCreateComment },
            React.createElement(CommentForm, { onSubmit: createComment }))));
};
const WorkspaceResourceComments = ({ workspaceId, resourceType, resource, }) => {
    const { data: comments, isLoading } = useComments(workspaceId, resourceType, resource);
    if (isLoading || !comments) {
        return (React.createElement(Center, null,
            React.createElement(Spinner, null)));
    }
    return (React.createElement(InternalWorkspaceResourceComments, { workspaceId: workspaceId, resourceType: resourceType, resource: resource, comments: comments }));
};
export { WorkspaceResourceComments };
