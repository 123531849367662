import { useEffect } from 'react';
import { useShowModalBound } from 'src/hooks/modal';
import { useIsUserQualified } from './useIsUserQualified';
/** Check if the user is qualified. If so, show the global modal */
const useProductMarketFitSurvey = () => {
    const userQualified = useIsUserQualified();
    const showModal = useShowModalBound('atlas-pmf');
    useEffect(() => {
        if (userQualified) {
            showModal();
        }
    }, [userQualified, showModal]);
};
export { useProductMarketFitSurvey };
