import { Button } from '@humanfirst/elektron';
import React, { useCallback } from 'react';
import { useShowModalBound } from 'src/hooks/modal';
const ContactUsFormTrigger = ({ location, contactType = 'General Contact', buttonVariant = 'primary', children, }) => {
    const showModal = useShowModalBound('contact-us', { location, contactType });
    const callback = useCallback((e) => {
        e.stopPropagation();
        showModal();
    }, [showModal]);
    return (React.createElement(Button, { onClick: callback, variant: buttonVariant }, children));
};
export { ContactUsFormTrigger };
