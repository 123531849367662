import { __awaiter } from "tslib";
import { Button, COLORS, Modal, Spacer, Spread, Stack, StatefulDataTable, Tag, TextColumn, toaster, Typography, } from '@humanfirst/elektron';
import { InviteResource, TeamMemberResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useMemo, useState } from 'react';
import { When } from 'react-if';
import { ButtonWithConfirmation } from 'src/components/ActionsWithConfirmation/ActionsWithConfirmation';
import { t } from 'src/constants/i18n';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useUser } from 'src/hooks/user';
import { formatDate } from 'src/utils/date';
import { InviteMemberForm } from './InviteMemberForm';
const prepareTeamMemberRows = (members, invites) => {
    const invitesConsolidated = invites.map((x) => {
        const isExpired = new Date(x.expiration) < new Date();
        const activationColumnData = isExpired
            ? 'Invitation expired'
            : 'Invitation pending';
        return {
            id: x.id,
            email: x.email,
            activation: activationColumnData,
            isInvite: true,
        };
    });
    return [
        ...members.map((x) => {
            var _a, _b, _c;
            return ({
                id: x.id,
                userId: (_a = x.user) === null || _a === void 0 ? void 0 : _a.id,
                email: (_b = x.user) === null || _b === void 0 ? void 0 : _b.email,
                name: (_c = x.user) === null || _c === void 0 ? void 0 : _c.name,
                activation: x.createdAt,
                isInvite: false,
            });
        }),
        ...invitesConsolidated,
    ];
};
const DisplayActionColumn = ({ teamUser, canDeleteMembers, canCreateInvites, teamId }) => {
    const user = useUser();
    const { mutateAsync: removeInvite } = useElektraApiMutation(InviteResource.remove(teamId, teamUser.id));
    const { mutateAsync: createInvite } = useElektraApiMutation(InviteResource.create(teamId));
    const { mutateAsync: removeMember } = useElektraApiMutation(TeamMemberResource.remove(teamId, teamUser.id));
    const removeMemberFromTeam = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield removeMember();
            toaster.positive(`Team member removed`, {});
        }
        catch (e) {
            toaster.negative(`Sorry, ${String(e)}`, {});
        }
    }), [removeMember]);
    const removeInviteFromTeam = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield removeInvite();
            toaster.positive(`Invite removed`, {});
        }
        catch (e) {
            toaster.negative(`Sorry, ${String(e)}`, {});
        }
    }), [removeInvite]);
    const resendTeamInvite = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield removeInvite();
            const invite = yield createInvite({ email: teamUser.email });
            toaster.positive(`Invite sent for ${invite.email}`, {});
        }
        catch (e) {
            toaster.negative(`Sorry, ${String(e)}`, {});
        }
    }), [removeInvite, createInvite, teamUser.email]);
    if (!canDeleteMembers ||
        teamUser.userId === (user === null || user === void 0 ? void 0 : user.id) ||
        teamUser.role === 'admin') {
        return null;
    }
    if (!teamUser.isInvite) {
        return (React.createElement(ButtonWithConfirmation, { onSubmit: removeMemberFromTeam, title: t('confirmationModal.removeUserTitle'), body: t('confirmationModal.removeUserBody'), submitText: t('confirmationModal.removeUserSubmitBtn') }, "Remove"));
    }
    if (teamUser.isInvite &&
        canCreateInvites &&
        teamUser.activation === 'Invitation expired') {
        return (React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Button, { variant: "secondary", onClick: resendTeamInvite }, "Re-send Invite"),
            React.createElement(ButtonWithConfirmation, { onSubmit: removeInviteFromTeam, title: t('confirmationModal.removeUserTitle'), body: t('confirmationModal.removeUserBody'), submitText: t('confirmationModal.removeUserSubmitBtn') }, "Remove")));
    }
    else {
        return (React.createElement(ButtonWithConfirmation, { onSubmit: removeInviteFromTeam, title: t('confirmationModal.removeUserTitle'), body: t('confirmationModal.removeUserBody'), submitText: t('confirmationModal.removeUserSubmitBtn') }, "Remove"));
    }
};
const DisplayActivationColumn = ({ teamUser, }) => {
    if (teamUser.isInvite) {
        return (React.createElement(Tag, { color: teamUser.activation === 'Invitation pending'
                ? COLORS.tertiary
                : COLORS.gray3, closeable: false, kind: 'neutral' }, teamUser.activation));
    }
    else {
        return React.createElement(Typography, null, formatDate(teamUser.activation));
    }
};
const TeamDetailTable = ({ members, invitees, canDeleteMembers, canCreateInvites, teamId }) => {
    const teamMembers = useMemo(() => prepareTeamMemberRows(members, invitees), [invitees, members]);
    return (React.createElement(StatefulDataTable, { data: teamMembers, initialState: { sort: { column: 'name', ascending: true } }, columns: [
            TextColumn('name', {
                header: 'Name',
            }),
            TextColumn('email', {
                header: 'Email',
            }),
            {
                id: 'joined',
                header: 'Joined Date',
                render: (x) => React.createElement(DisplayActivationColumn, { teamUser: x }),
            },
            {
                id: 'action',
                header: '',
                render: (x) => (React.createElement(DisplayActionColumn, { teamUser: x, teamId: teamId, canCreateInvites: canCreateInvites, canDeleteMembers: canDeleteMembers })),
            },
        ] }));
};
const TeamUsers = ({ data: members, invitees, team }) => {
    const canCreateInvites = useHasPermission('team.member', 'create', {
        id: team.id,
    });
    const [isOpen, setIsOpen] = useState(false);
    const showModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const canDeleteMembers = useHasPermission('team.member', 'delete', {
        id: team.id,
    });
    const { mutateAsync: createInvite } = useElektraApiMutation(InviteResource.create(team.id));
    const onSubmit = useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const invite = yield createInvite({ email });
            closeModal();
            toaster.positive(`Invite sent for ${invite.email}`, {});
        }
        catch (e) {
            toaster.negative(`Sorry, ${String(e)}`, {});
        }
    }), [closeModal, createInvite]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
            React.createElement(InviteMemberForm, { closeModal: closeModal, onSubmit: onSubmit })),
        React.createElement(When, { condition: canCreateInvites },
            React.createElement(Spread, { justifyContent: 'end' },
                React.createElement(Button, { onClick: showModal, size: 'large' }, "Invite Member"))),
        React.createElement(Spacer, { size: '24px' }),
        React.createElement(TeamDetailTable, { members: members, invitees: invitees, canDeleteMembers: canDeleteMembers, canCreateInvites: canCreateInvites, teamId: team.id })));
};
export { TeamUsers };
