import { useRoutes } from 'react-router';
import { ROUTES, LOCKED_APP_ROUTES } from './config/routes';
/** Render routes using react router v6 hooks. */
const NewRootRouter = () => {
    return useRoutes([ROUTES]);
};
export const LockedAppRouter = () => {
    return useRoutes([LOCKED_APP_ROUTES]);
};
export default NewRootRouter;
