import { Block, Button, COLORS, Divider, Link, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { memo } from 'react';
import { When } from 'react-if';
import { AtlasFeedbackButton } from 'src/components/AtlasFeedback';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { useIntercom } from 'src/contexts/IntercomContext';
import { useSearchOmni } from 'src/hooks/search';
import { applyHighlightJSX } from 'src/utils/highlighting';
import { generatePath } from 'src/utils/path';
const EMPTY_SEARCH_VENDOR_KIND = ['vendor'];
const EMPTY_SEARCH_TECH_KIND = ['model'];
const EMPTY_SEARCH_MC_KIND = ['medicalCondition'];
const EMPTY_SEARCH_MEASURE_KIND = ['measure'];
const getHighlightedByType = (data, type) => {
    return (data
        .filter((o) => o.type === type && !!o.highlight)
        .map((x) => (Object.assign(Object.assign({}, x), { highlightNode: applyHighlightJSX(x.highlight) }))) || []);
};
const SingleOtherAtlasMatch = ({ title, matches, query, detailLinkPath, viewMoreLinkPath }) => {
    return (React.createElement(Stack, { alignItems: 'center', width: '280px' },
        React.createElement(Typography, { textTransform: 'uppercase', variant: TypographyVariant.DetailsBold, color: COLORS.gray2 }, title),
        matches.slice(0, 2).map((x) => (React.createElement(Link, { key: x.id, href: generatePath(detailLinkPath, {
                id: x.id,
            }) }, x.highlightNode.value))),
        React.createElement(When, { condition: matches.length > 2 },
            React.createElement(Link, { typographyVariant: TypographyVariant.Details, href: generatePath(viewMoreLinkPath) + `?page=1&query=${query}` }, "View More"))));
};
const OtherAtlasMatches = ({ query, techMatches, vendorMatches, mcMatches, measureMatches }) => {
    const techMatchesHighlighted = getHighlightedByType(techMatches, 'model');
    const vendorMatchesHighlighted = getHighlightedByType(vendorMatches, 'vendor');
    const measureMatchesHighlighted = getHighlightedByType(measureMatches, 'measure');
    const mcMatchesHighlighted = getHighlightedByType(mcMatches, 'medicalCondition');
    return (React.createElement(Stack, { alignItems: 'center', gap: '24px' },
        React.createElement(Typography, null, "But we found similar results: "),
        React.createElement(Stack, { direction: 'horizontal', gap: '24px' },
            React.createElement(When, { condition: measureMatches.length > 0 },
                React.createElement(SingleOtherAtlasMatch, { title: "measures", matches: measureMatchesHighlighted, query: query, viewMoreLinkPath: PATHS.atlas.measures.list, detailLinkPath: PATHS.atlas.measures.detail })),
            React.createElement(When, { condition: mcMatches.length > 0 },
                React.createElement(SingleOtherAtlasMatch, { title: "medical conditions", matches: mcMatchesHighlighted, query: query, viewMoreLinkPath: PATHS.atlas.medicalConditions.list, detailLinkPath: PATHS.atlas.medicalConditions.detail })),
            React.createElement(When, { condition: techMatches.length > 0 },
                React.createElement(SingleOtherAtlasMatch, { title: "technologies", matches: techMatchesHighlighted, query: query, viewMoreLinkPath: PATHS.atlas.technologies.list, detailLinkPath: PATHS.atlas.technologies.detail })),
            React.createElement(When, { condition: vendorMatches.length > 0 },
                React.createElement(SingleOtherAtlasMatch, { title: "vendors", matches: vendorMatchesHighlighted, query: query, viewMoreLinkPath: PATHS.atlas.vendors.list, detailLinkPath: PATHS.atlas.vendors.detail })))));
};
const EmptyStateOtherOptions = ({ query, location }) => {
    var _a, _b, _c, _d;
    const { showNewMessage } = useIntercom();
    const mcMatches = (_a = useSearchOmni({
        query: query,
        enabled: !!query,
        kinds: EMPTY_SEARCH_MC_KIND,
    }).options) !== null && _a !== void 0 ? _a : [];
    const measureMatches = (_b = useSearchOmni({
        query: query,
        enabled: !!query,
        kinds: EMPTY_SEARCH_MEASURE_KIND,
    }).options) !== null && _b !== void 0 ? _b : [];
    const vendorMatches = (_c = useSearchOmni({
        query: query,
        enabled: !!query,
        kinds: EMPTY_SEARCH_VENDOR_KIND,
    }).options) !== null && _c !== void 0 ? _c : [];
    const techMatches = (_d = useSearchOmni({
        query: query,
        enabled: !!query,
        kinds: EMPTY_SEARCH_TECH_KIND,
    }).options) !== null && _d !== void 0 ? _d : [];
    const allMatches = [
        ...techMatches,
        ...vendorMatches,
        ...mcMatches,
        ...measureMatches,
    ];
    const notFoundMsg = query
        ? `We're sorry, we could not find "${query}"`
        : "We're sorry, we could not find anything matching your search";
    if (allMatches.length < 1 || !query) {
        return (React.createElement(Stack, { alignItems: 'center', padding: '16px' },
            React.createElement(Block, { maxWidth: '300px' },
                React.createElement(Typography, { align: 'center', variant: TypographyVariant.BodyBold }, notFoundMsg)),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Stack, { gap: '24px', direction: "horizontal" },
                React.createElement(Stack, { alignItems: 'center' },
                    React.createElement(Typography, null, "Something missing?"),
                    React.createElement(AtlasFeedbackButton, null, "Let us know")),
                React.createElement(Stack, { gap: "12px", alignItems: 'center' },
                    React.createElement(Typography, null, "Tips to improve your search"),
                    React.createElement(Link, { href: PATHS.helpCenter.troubleShooting.zeroSearchResults }, "More Suggestions")),
                React.createElement(Stack, { alignItems: 'center' },
                    React.createElement(Typography, null, "Questions or feedback?"),
                    React.createElement(Button, { variant: "minimal", onClick: () => showNewMessage() }, t('actions.chatWithUs'))))));
    }
    return (React.createElement(Stack, { alignItems: 'center', padding: '16px' },
        React.createElement(Block, null,
            React.createElement(Typography, { align: 'center', variant: TypographyVariant.BodyBold }, location
                ? `Unfortunately, we could not find "${query}" in ${location}`
                : `Unfortunately, we could not find "${query}"`)),
        React.createElement(Spacer, { size: '16px' }),
        React.createElement(Stack, { gap: '48px' },
            React.createElement(Stack, { alignItems: 'center' },
                React.createElement(OtherAtlasMatches, { techMatches: techMatches, vendorMatches: vendorMatches, mcMatches: mcMatches, measureMatches: measureMatches, query: query })),
            React.createElement(Divider, null),
            React.createElement(Stack, { gap: "24px", direction: 'horizontal', justifyContent: 'center' },
                React.createElement(Stack, { alignItems: 'center' },
                    React.createElement(Typography, null, "Something missing?"),
                    React.createElement(AtlasFeedbackButton, null, "Let us know")),
                React.createElement(Stack, { gap: "12px", alignItems: 'center' },
                    React.createElement(Typography, null, "Tips to improve your search"),
                    React.createElement(Link, { href: PATHS.helpCenter.troubleShooting.zeroSearchResults }, "More Suggestions")),
                React.createElement(Stack, { alignItems: 'center' },
                    React.createElement(Typography, null, "Questions or feedback?"),
                    React.createElement(Button, { variant: "minimal", onClick: () => showNewMessage() }, t('actions.chatWithUs')))))));
};
const EmptyStateOtherOptionsMemo = memo(EmptyStateOtherOptions);
export { EmptyStateOtherOptionsMemo as EmptyStateOtherOptions };
