import { __awaiter } from "tslib";
import { Block, Button, COLORS, ControlsPopover, Icons, Input, Spread, Stack, toaster, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useElektraApiMutation, WorkspaceCommentResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
/**
 * Represents a single comment.
 */
export const CommentComponent = ({ comment, }) => {
    var _a;
    const workspaceId = useActiveWorkspaceId();
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(comment.comment);
    const { mutateAsync: deleteComment } = useElektraApiMutation(WorkspaceCommentResource.archive(workspaceId, comment.id));
    const { mutateAsync: editComment } = useElektraApiMutation(WorkspaceCommentResource.update(workspaceId, comment.id));
    const canDeleteComment = useHasPermission('comment', 'delete', comment);
    const canEditComment = useHasPermission('comment', 'update', comment);
    const onDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield deleteComment();
        }
        catch (_b) {
            toaster.negative('Comment could not be deleted.', {});
            // eslint-disable-next-line no-console
            console.error('unable to remove comment from workspace');
        }
    }), [deleteComment]);
    const onEdit = useCallback((newComment) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield editComment({ comment: newComment });
            setIsEditing(false);
        }
        catch (_c) {
            toaster.negative('Comment could not be edited.', {});
            // eslint-disable-next-line no-console
            console.error('unable to edit comment');
        }
    }), [editComment]);
    const menuOptions = [];
    if (canDeleteComment) {
        menuOptions.push({
            title: 'Delete',
            onClick: onDelete,
            icon: React.createElement(Icons.Misc.Delete, { color: COLORS.black, decorative: true }),
        });
    }
    if (canEditComment) {
        menuOptions.push({
            title: 'Edit',
            onClick: () => setIsEditing(true),
            icon: React.createElement(Icons.Misc.Edit, { color: COLORS.black, decorative: true }),
        });
    }
    return (React.createElement(Block, { minWidth: '200px' },
        React.createElement(Spread, null,
            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, (_a = comment.creator) === null || _a === void 0 ? void 0 : _a.name),
            React.createElement(When, { condition: canDeleteComment || canEditComment },
                React.createElement(ControlsPopover, { iconSize: "24px", popoverPlacement: "bottomLeft", menuItems: menuOptions }))),
        React.createElement(If, { condition: isEditing },
            React.createElement(Then, null,
                React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                    React.createElement(Input, { value: value, onChange: (e) => setValue(e.target.value) }),
                    React.createElement(Button, { onClick: () => onEdit(value) }, "Update"))),
            React.createElement(Else, null,
                React.createElement(Typography, null, comment.comment)))));
};
