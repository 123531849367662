import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useLogger } from '../logger';
import { useAnalyticsServices } from './analytics-services';
import { useAnalyticsEvent } from './analytics-event';
import { useAnalyticsIsConnectable } from './is-connectable';
import { useAnalyticsSessionIdentifiers } from './session-identifiers';
import { useAppData } from './app-data';
import { useAnalyticsIdentify } from './identify';
const useTrackIdentifiers = () => {
    const services = useAnalyticsServices();
    const track = useAnalyticsEvent();
    // Notify all services about session identifiers from other services
    const lastKnownSessionRef = useRef({});
    const sessionIdentifiersResult = useAnalyticsSessionIdentifiers(services);
    useEffect(() => {
        if (sessionIdentifiersResult.isLoading) {
            return;
        }
        for (const [service, identifier] of Object.entries(sessionIdentifiersResult.identifiers)) {
            // Only send the tracking event if the session identifier has changed
            if (lastKnownSessionRef.current[service] === identifier) {
                continue;
            }
            lastKnownSessionRef.current[service] = identifier;
            track('analytics-session', { service, identifier }, {
                services: {
                    except: [service],
                },
            });
        }
    }, [sessionIdentifiersResult, track]);
};
const useTrackIsConnectable = () => {
    const services = useAnalyticsServices();
    const track = useAnalyticsEvent();
    // Notify all services when blocked
    const isConnectableResult = useAnalyticsIsConnectable(services);
    useEffect(() => {
        if (isConnectableResult.isLoading) {
            return;
        }
        for (const [service, isConnectable] of Object.entries(isConnectableResult.status)) {
            if (!isConnectable) {
                track('analytics-blocked', { service });
            }
        }
    }, [isConnectableResult, track]);
};
const useTrackPathChange = () => {
    const logger = useLogger(useTrackPathChange.name);
    const services = useAnalyticsServices();
    const location = useLocation();
    const pathname = location.pathname;
    const search = location.search;
    const appData = useAppData();
    // Notify all services about path changes.
    useEffect(() => {
        for (const service of services) {
            try {
                service.pathChange(pathname, search, appData);
            }
            catch (e) {
                logger.error(e);
            }
        }
    }, [logger, services, pathname, appData, search]);
};
const useAnalytics = () => {
    useAnalyticsIdentify();
    useTrackIdentifiers();
    useTrackIsConnectable();
    useTrackPathChange();
};
export { useAnalytics };
