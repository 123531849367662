import { Banner, Block, COLORS, Link, TypographyVariant, } from '@humanfirst/elektron';
import React, { useContext } from 'react';
import { StatusContext } from '../../contexts/StatusContext';
import { formatDateFromNow } from '../../utils/date';
export const StatusIndicator = ({ padding, }) => {
    const { isHealthy, description, scheduledMaintenance, lastUpdated, url } = useContext(StatusContext);
    if (scheduledMaintenance.length > 0) {
        return (React.createElement(Block, { padding: padding },
            React.createElement(Banner, { variant: "information", title: "Upcoming Maintenance Window", content: React.createElement(Link, { typographyVariant: TypographyVariant.BodyBold, color: COLORS.gray1, href: url },
                    "Updated ",
                    formatDateFromNow(lastUpdated),
                    " \u2014 Read More") })));
    }
    if (!isHealthy) {
        return (React.createElement(Block, { padding: padding },
            React.createElement(Banner, { variant: "error", title: description.length > 48
                    ? description.substring(0, 30) + '...'
                    : description, content: React.createElement(Link, { typographyVariant: TypographyVariant.BodyBold, color: COLORS.alert, href: url },
                    "Updated ",
                    formatDateFromNow(lastUpdated),
                    " \u2014 Read More") })));
    }
    return null;
};
