import { Stack, Spread, Typography, TypographyVariant, Spacer, Block, Card, Divider, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { AddToWorkspaceButton } from 'src/components/AddToWorkspace';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { LoadingState } from 'src/components/LoadingState';
/** Renders a single statistic. */
const StatisticBlock = ({ count, label, tooltip, }) => {
    return (React.createElement(Spread, { direction: 'horizontal' },
        React.createElement(Spread, null,
            React.createElement(Typography, { align: "center", variant: TypographyVariant.Details }, label),
            React.createElement(Spacer, null),
            React.createElement(AnchoredTooltip, null, tooltip)),
        React.createElement(Typography, { align: "center", variant: TypographyVariant.Heading1 }, count)));
};
const ResourceSummaryCard = ({ statistics, name, id, measureHierarchy, resourceType, subheadingContent, }) => {
    return (React.createElement(Card, null,
        React.createElement(Stack, null,
            React.createElement(Spread, { direction: "horizontal", alignItems: "flex-start", gap: "8px" },
                React.createElement(Stack, { direction: 'horizontal', alignItems: "flex-start" },
                    React.createElement(Block, { maxWidth: ['208px', '208px', '208px', '100%'], overrides: {
                            Block: {
                                style: {
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            },
                        } },
                        React.createElement(Typography, { variant: TypographyVariant.Heading1 }, name.replace('/', ' / '))),
                    React.createElement(AddToWorkspaceButton, { resourceId: id, resourceType: resourceType }))),
            React.createElement(When, { condition: !!subheadingContent },
                subheadingContent,
                React.createElement(Divider, null)),
            React.createElement(Spacer, { size: '8px' }),
            measureHierarchy,
            React.createElement(Block, { gap: '24px' }, statistics === null ? (React.createElement(Block, { width: "100%" },
                React.createElement(LoadingState, null))) : (React.createElement(Stack, { gap: "16px" }, statistics.map(({ count, label, tooltip }) => (React.createElement(StatisticBlock, { key: label, label: label, tooltip: tooltip, count: count })))))))));
};
export { ResourceSummaryCard };
