import { Text, Icons, Stack, StatefulDataList, } from '@humanfirst/elektron';
import React from 'react';
import { LoadingState } from 'src/components/LoadingState';
import { CUSTOM_TAG_GROUP, LISTS_GROUP, REVIEWED_STATUS_GROUP } from '../groups';
import { useConstructLitReviewEvidence, useEvidenceSelected, } from '../hooks/hooks';
import { DATE_ADDED_SORT, TITLE_SORT } from '../sorts';
import { BookmarkAction } from './BookmarkAction';
import { EvidenceReviewCard } from './EvidenceReviewCard';
import { TagAction } from './TagAction';
const GROUPS = [REVIEWED_STATUS_GROUP, LISTS_GROUP, CUSTOM_TAG_GROUP];
const SORTS = [TITLE_SORT, DATE_ADDED_SORT];
const SCHEMA = {
    groups: GROUPS,
    sorts: SORTS,
    childComponent: EvidenceReviewCard,
};
const INITIAL_STATE = { sort: 'createdAt' };
const EvidenceListActions = () => {
    const selectedItems = useEvidenceSelected();
    const selectedBookmarks = selectedItems.map((x) => x.bookmark);
    return (React.createElement(Stack, { direction: 'horizontal' },
        React.createElement(TagAction, { item: selectedItems, isBulk: true }),
        React.createElement(BookmarkAction, { variant: 'archive', bookmarks: selectedBookmarks, isBulk: true }),
        React.createElement(BookmarkAction, { variant: 'delete', bookmarks: selectedBookmarks, isBulk: true })));
};
const EvidenceReviewDataListInternal = ({ evidence }) => {
    return (React.createElement(StatefulDataList, Object.assign({}, SCHEMA, { data: evidence, initialState: INITIAL_STATE, postSelectionActions: React.createElement(EvidenceListActions, null) })));
};
const EvidenceReviewDataList = ({ data: bookmarks, workspaceId }) => {
    const { isLoading, data } = useConstructLitReviewEvidence(bookmarks, workspaceId);
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    if (!data.length) {
        return (React.createElement(Stack, { gap: "6px" },
            React.createElement(Text, { align: "center" }, "No evidence to display."),
            React.createElement(Text, { align: "center" },
                "Collect and save your evidence here. Look for",
                ' ',
                React.createElement(Icons.Misc.Save, { title: "Save to Project" }),
                " to save evidence to your project.")));
    }
    return React.createElement(EvidenceReviewDataListInternal, { evidence: data !== null && data !== void 0 ? data : [] });
};
export { EvidenceReviewDataList };
