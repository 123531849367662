import { Card, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { checkedParse } from './shared';
export const MeasuresCard = ({ metadata, }) => {
    const relevantMetadata = getTeamMetadata(metadata, 'Measurements');
    if (!relevantMetadata) {
        return null;
    }
    const parsedMeasures = checkedParse(relevantMetadata);
    if (!parsedMeasures ||
        typeof parsedMeasures !== 'object' ||
        !parsedMeasures.length) {
        return null;
    }
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: "0" }, 'Measurements')),
            React.createElement(Stack, null, parsedMeasures.map((measure) => {
                if (measure.id) {
                    return (React.createElement(Link, { key: measure.id, href: generatePath(PATHS.atlas.measures.detail, {
                            id: measure.id,
                        }) }, measure.name));
                }
                return React.createElement(Typography, { key: measure.name }, measure.name);
            })))));
};
