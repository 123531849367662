import { BetterTree, Block, Checkbox, COLORS, SemanticButton, setStateAction, Stack, stateFromTree, StatefulTooltip, Typography, TypographyVariant, useTreeState, } from '@humanfirst/elektron';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { MeasureTag } from 'src/components/MeasureTag';
import { Locked } from 'src/components/Locked';
import { getMeasurementHierarchyOntologyValue } from 'src/utils/measurements';
import { MeasureCategoryTreeNode } from 'src/pages/Ontologies/components/MeasureCategoryTreeNode';
import { LockedTooltip } from '../LockedTooltip';
import { AnchoredTooltip } from '../AnchoredTooltip';
import { TargetTagContext } from './contexts/TargetTagContext';
/**
 * Determines if two ontology trees are equal (including leaf ordering).
 *
 * NOTE: This will only look at the measure names, not other tags
 * or full name.
 * @param a The first tree
 * @param b The second tree
 * @returns
 */
const treeEqual = (a, b) => {
    if (a.value.measure !== b.value.measure ||
        a.children.length !== b.children.length) {
        return false;
    }
    for (let i = 0; i < a.children.length; i++) {
        if (!treeEqual(a.children[i], b.children[i])) {
            return false;
        }
    }
    return true;
};
export const MeasureTagButton = ({ tag, highlight, toggle, variant = 'button' }) => {
    return (React.createElement(If, { condition: variant === 'button' },
        React.createElement(Then, null,
            React.createElement(StatefulTooltip, { content: React.createElement(MeasureTag, { tag: tag, includeDescription: true }), triggerType: 'hover', placement: "bottom" },
                React.createElement(SemanticButton, { onClick: () => toggle(tag) },
                    React.createElement(Block, { color: highlight === tag ? COLORS.primary : COLORS.black },
                        React.createElement(MeasureTag, { tag: tag }))))),
        React.createElement(Else, null,
            React.createElement(Checkbox, { checkmarkType: "toggle", checked: highlight === tag, onChange: () => toggle(tag), labelPlacement: "right" },
                React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                    React.createElement(MeasureTag, { tag: tag }),
                    React.createElement(AnchoredTooltip, { triggerType: "hover" },
                        React.createElement(MeasureTag, { tag: tag, includeDescription: true })))))));
};
function setMeasureTag(measure, tag) {
    measure.value.tags = [tag];
    if (measure.children) {
        for (const child of measure.children) {
            setMeasureTag(child, tag);
        }
    }
    return measure;
}
function countMeasureTag(measure, tag) {
    var _a;
    // Check the measure value here since the synthetic root node has no value
    // but rolls up all of the children tags.
    let withTagCount = measure.value.measure !== 'root' && ((_a = measure.value.tags) === null || _a === void 0 ? void 0 : _a.includes(tag))
        ? 1
        : 0;
    if (measure.children) {
        for (const child of measure.children) {
            withTagCount += countMeasureTag(child, tag);
        }
    }
    return withTagCount;
}
function mergeMeasures(left, right) {
    var _a, _b;
    const leftChildren = left.children || [];
    const rightChildren = right.children || [];
    left.value.tags = [
        ...((_a = left.value.tags) !== null && _a !== void 0 ? _a : []),
        ...((_b = right.value.tags) !== null && _b !== void 0 ? _b : []),
    ];
    const children = [];
    for (const leftChild of leftChildren) {
        const rightChild = rightChildren.find(({ value }) => value.measure === leftChild.value.measure);
        if (!rightChild) {
            children.push(leftChild);
            continue;
        }
        children.push(mergeMeasures(leftChild, rightChild));
    }
    for (const rightChild of rightChildren) {
        const leftChild = leftChildren.find(({ value }) => value.measure === rightChild.value.measure);
        // Only push if there's NO left child
        if (leftChild) {
            continue;
        }
        children.push(rightChild);
    }
    left.children = children;
    return left;
}
const LockedMeasurementCount = ({ body, title, }) => {
    return (React.createElement(Stack, { direction: "horizontal", alignItems: "center", gap: "0" },
        React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "("),
        React.createElement(LockedTooltip, { body: body, title: title },
            React.createElement(Locked, { size: "18px", color: COLORS.gray1 })),
        React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, ")")));
};
const MeasurementTreeLegend = ({ hasToolMeasures, toggleHighlight, measurementHierarchy, targetTag, }) => {
    const [manufactureBasedCount, evidenceBasedCount, fdaBasedCount, authorBasedCount,] = useMemo(() => {
        return [
            countMeasureTag(measurementHierarchy, 'manufacturer'),
            countMeasureTag(measurementHierarchy, 'evidence'),
            countMeasureTag(measurementHierarchy, 'fda'),
            countMeasureTag(measurementHierarchy, 'author'),
        ];
    }, [measurementHierarchy]);
    return (React.createElement(Stack, { direction: "horizontal", gap: "16px" },
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(MeasureTagButton, { tag: 'manufacturer', highlight: targetTag, toggle: toggleHighlight }),
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                "(",
                manufactureBasedCount,
                ")")),
        React.createElement(When, { condition: authorBasedCount > 0 },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(MeasureTagButton, { tag: 'author', highlight: targetTag, toggle: toggleHighlight }),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    "(",
                    authorBasedCount,
                    ")"))),
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(MeasureTagButton, { tag: 'evidence', highlight: targetTag, toggle: toggleHighlight }),
            React.createElement(If, { condition: hasToolMeasures },
                React.createElement(Then, null,
                    React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                        "(",
                        evidenceBasedCount,
                        ")")),
                React.createElement(Else, null,
                    React.createElement(LockedMeasurementCount, { body: "Get in touch with our team to upgrade and unlock evidence-based measures for this technology.", title: "Access Evidence-based Measure" })))),
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(MeasureTagButton, { tag: 'fda', highlight: targetTag, toggle: toggleHighlight }),
            React.createElement(If, { condition: hasToolMeasures },
                React.createElement(Then, null,
                    React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                        "(",
                        fdaBasedCount,
                        ")")),
                React.createElement(Else, null,
                    React.createElement(LockedMeasurementCount, { body: "Get in touch with our team to upgrade and unlock FDA evidence-based measures for this technology.", title: "Access FDA Evidence-based Measure" }))))));
};
const MeasurementTreeTreeDisplay = ({ state, dispatch }) => {
    return (React.createElement(Block, null, state.children.map((tree, i) => (React.createElement(BetterTree
    // Ordering is actually important here (we're using the i in the path), so
    // we are using index as the key.
    // eslint-disable-next-line react/no-array-index-key
    , { 
        // Ordering is actually important here (we're using the i in the path), so
        // we are using index as the key.
        // eslint-disable-next-line react/no-array-index-key
        key: i, isFirst: true, isLast: true, path: [i], contentComponent: MeasureCategoryTreeNode, state: tree, dispatch: dispatch })))));
};
const MeasurementTree = ({ hasToolMeasures = true, evidenceMeasuresCategory, manufacturerMeasuresCategory, fdaMeasuresCategory, sharedMeasureCategory, authorMeasuresCategory, showLegend = true, }) => {
    const measurementHierarchy = useMemo(() => {
        const measuresTypes = [
            [manufacturerMeasuresCategory, 'manufacturer'],
            [evidenceMeasuresCategory, 'evidence'],
            [fdaMeasuresCategory, 'fda'],
            [sharedMeasureCategory !== null && sharedMeasureCategory !== void 0 ? sharedMeasureCategory : [], 'shared'],
            [authorMeasuresCategory !== null && authorMeasuresCategory !== void 0 ? authorMeasuresCategory : [], 'author'],
        ];
        let result = {
            value: { measure: 'root' },
            children: [],
        };
        for (const [measuresCategory, tag] of measuresTypes) {
            const measures = setMeasureTag({
                value: { measure: 'root' },
                children: getMeasurementHierarchyOntologyValue(measuresCategory) || [],
            }, tag);
            result = mergeMeasures(result, measures);
        }
        return result;
    }, [
        manufacturerMeasuresCategory,
        evidenceMeasuresCategory,
        fdaMeasuresCategory,
        sharedMeasureCategory,
        authorMeasuresCategory,
    ]);
    const [targetTag, setTargetTag] = useState();
    const toggleHighlight = useCallback((tag) => {
        setTargetTag((h) => {
            if (h === tag) {
                return undefined;
            }
            return tag;
        });
    }, []);
    const root = {
        value: { measure: 'root' },
        children: measurementHierarchy.children,
    };
    const [state, dispatch] = useTreeState(root);
    // We want to reset the state if we get different values, but if we have
    // the same underlying values we would like to maintain the current open/close
    // state. To handle this we cache the measurementHierarchy and only update
    // if that actively changes.
    const [cachedMeasurementHierarchy, setCachedMeasurementHierarchy] = useState(measurementHierarchy);
    useEffect(() => {
        if (!treeEqual(cachedMeasurementHierarchy, measurementHierarchy)) {
            setCachedMeasurementHierarchy(measurementHierarchy);
            dispatch(setStateAction(stateFromTree({
                value: { measure: 'root' },
                children: measurementHierarchy.children,
            })));
        }
    }, [dispatch, cachedMeasurementHierarchy, measurementHierarchy]);
    if (showLegend) {
        return (React.createElement(TargetTagContext.Provider, { value: { targetTag, assignTargetTag: toggleHighlight } },
            React.createElement(MeasurementTreeLegend, { hasToolMeasures: hasToolMeasures, toggleHighlight: toggleHighlight, measurementHierarchy: measurementHierarchy, targetTag: targetTag }),
            React.createElement(MeasurementTreeTreeDisplay, { state: state, dispatch: dispatch })));
    }
    return React.createElement(MeasurementTreeTreeDisplay, { state: state, dispatch: dispatch });
};
export { MeasurementTree };
