import { __awaiter, __rest } from "tslib";
import React, { useCallback } from 'react';
import { ExportBlobButton } from 'src/components/ExportBlobButton';
const DEFAULT_JSON_FILENAME = 'export.json';
const DEFAULT_JSON_FILETYPE = 'application/json';
const ExportJSONButton = (_a) => {
    var { download, contentType, payload } = _a, props = __rest(_a, ["download", "contentType", "payload"]);
    const serializedPayload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (typeof payload === 'function') {
            return JSON.stringify(yield payload());
        }
        return JSON.stringify(payload);
    }), [payload]);
    return (React.createElement(ExportBlobButton, Object.assign({ download: download !== null && download !== void 0 ? download : DEFAULT_JSON_FILENAME, contentType: contentType !== null && contentType !== void 0 ? contentType : DEFAULT_JSON_FILETYPE, payload: serializedPayload }, props)));
};
export { ExportJSONButton };
