import { COLORS } from '@humanfirst/elektron';
import { t } from 'src/constants/i18n';
/**
 * A mapping from a MeasureTagType to data about that tag.
 */
const MEASURE_TAG_DATA = {
    author: {
        color: COLORS.secondary,
        title: 'Author-stated',
        description: 'The measures that are stated in the validation of this technology.',
    },
    evidence: {
        color: COLORS.blue3,
        title: `${t('toolDetails.measureTags.evidence.title')}`,
        description: `${t('toolDetails.measureTags.evidence.description')}`,
    },
    manufacturer: {
        color: COLORS.gray3,
        title: `${t('toolDetails.measureTags.manufacturer.title')}`,
        description: `${t('toolDetails.measureTags.manufacturer.description')}`,
    },
    fda: {
        color: COLORS.tertiary,
        title: `${t('toolDetails.measureTags.fda.title')}`,
        description: `${t('toolDetails.measureTags.fda.description')}`,
    },
    shared: {
        color: COLORS.success,
        title: 'Shared',
        description: 'Measures that are shared between all selected technologies',
    },
};
export { MEASURE_TAG_DATA };
