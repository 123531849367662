import { getPathFromString } from './measurements';
const getSortedToolsWithEvidence = (allTools, evidences) => {
    return allTools === null || allTools === void 0 ? void 0 : allTools.flatMap((tool) => {
        var _a;
        const filteredEvidence = (_a = evidences === null || evidences === void 0 ? void 0 : evidences.filter((evidence) => {
            var _a;
            return (_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.some((source) => { var _a; return (_a = source.tools) === null || _a === void 0 ? void 0 : _a.some((currentTool) => currentTool.id === tool.id); });
        })) !== null && _a !== void 0 ? _a : [];
        return {
            tool: tool,
            evidenceCount: filteredEvidence.length,
            evidence: filteredEvidence,
        };
    }).sort((a, b) => b.evidenceCount - a.evidenceCount);
};
const getSortedMeasuresWithEvidence = (categories, evidences) => {
    if (categories) {
        return categories === null || categories === void 0 ? void 0 : categories.flatMap((x) => {
            var _a;
            const evidenceMeasures = (_a = evidences === null || evidences === void 0 ? void 0 : evidences.filter((e) => {
                var _a, _b;
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                return ((_a = e.measureCategory) === null || _a === void 0 ? void 0 : _a.includes(`\"${x.category}\"`)) ||
                    ((_b = e.measureCategory) === null || _b === void 0 ? void 0 : _b.includes(x.category));
            })) !== null && _a !== void 0 ? _a : [];
            return Object.assign(Object.assign({}, x), { name: getPathFromString(x.category).pop(), evidenceCount: evidenceMeasures.length, evidence: evidenceMeasures });
        }).filter((x) => x.evidenceCount > 0 && !!x.name).sort((a, b) => b.evidenceCount - a.evidenceCount);
    }
    return null;
};
export { getSortedMeasuresWithEvidence, getSortedToolsWithEvidence, };
