import React from 'react';
import { Spinner } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { EvidenceTable } from 'src/components/EvidenceTable';
function translateParameters(input) {
    const parameters = {
        hasSelections: true,
        filters: undefined,
    };
    if ('filter' in input && input.filter) {
        parameters.filters = Array.isArray(input.filter)
            ? input.filter
            : [input.filter];
    }
    return parameters;
}
export const MarkdownCodeExtensionEvidenceTable = ({ code, parameters, }) => {
    const lines = code
        .trim()
        .split('\n')
        .map((line) => line.trim());
    const viewIds = lines.filter((line) => line.startsWith('viw'));
    const recordIds = lines.filter((line) => line.startsWith('rec'));
    const { filters } = translateParameters(parameters);
    const { isInitialLoading: isLoadingViews, data: viewEvidence = [] } = useElektraApi(EvidenceResource.list({
        join: ['groups'],
        filter: [['groups.airtableId', '$in', viewIds.join(',')]],
    }), { enabled: viewIds.length > 0 });
    const { isInitialLoading: isLoadingRecords, data: recordsEvidence = [] } = useElektraApi(EvidenceResource.getBulk(recordIds), {
        enabled: recordIds.length > 0,
    });
    const isLoading = isLoadingRecords || isLoadingViews;
    const evidence = [...viewEvidence, ...recordsEvidence];
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(EvidenceTable, { data: evidence, priorityFilters: filters });
};
