import { __awaiter } from "tslib";
import { EvidenceResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { TechnologySearchType, } from '@humanfirst/api-models';
import { useCallback, useMemo } from 'react';
import { Indicies } from 'src/constants/search';
import { useSearchClient } from './useSearchClient';
import { useSearchFacets } from './useSearchFacets';
import { useSearchIDs } from './useSearchIDs';
import { serializeFilters } from './filters';
/** Gets the resource for a search type + data combination. */
const getResource = (searchType, data) => {
    if (searchType === 'Evidence') {
        // The cast is necessary here because typescript can't narrow the generic Q
        return EvidenceResource.getBulk(data !== null && data !== void 0 ? data : [], {
            join: [
                'medicalConditions',
                'therapeuticArea',
                'sourceMeasures',
                'sourceMeasures.tools',
            ],
        });
    }
    else {
        return ToolResource.getBulk(data !== null && data !== void 0 ? data : [], {
            join: ['maker||id,name,evidenceCount'],
        });
    }
};
export const useSearchTypeSpecificIndexAndCredentials = (searchType, enabled) => {
    const searchClientResultsTechnology = useSearchClient(ToolResource.getSearchToken(TechnologySearchType.AllAttributes), enabled && searchType === 'Technology');
    const searchClientResultsEvidence = useSearchClient(EvidenceResource.getSearchToken(), enabled && searchType === 'Evidence');
    if (searchType === 'Evidence') {
        return {
            searchClientResults: searchClientResultsEvidence,
            index: Indicies.Evidence,
        };
    }
    else {
        return {
            searchClientResults: searchClientResultsTechnology,
            index: Indicies.Tools,
        };
    }
};
function useSearchWithFacets({ searchType, query, filters, sort, page, enabled = true, }) {
    const { searchClientResults, index } = useSearchTypeSpecificIndexAndCredentials(searchType, enabled);
    const { searchClient, isReady } = searchClientResults;
    let indexName = index;
    if (sort) {
        indexName += `-sort-${sort}`;
    }
    const searchResults = useSearchIDs({
        searchClient,
        indexName,
        query,
        filters,
        page,
        enabled: enabled && isReady,
    });
    // Don't apply the query or filters when fetching facets because right now
    // we want to get all of them
    const facetsResults = useSearchFacets({
        query,
        filters,
        searchClient,
        indexName,
        enabled: enabled && isReady,
    });
    const searchFacets = useCallback((facet, facetQuery, showCount) => __awaiter(this, void 0, void 0, function* () {
        if (!isReady) {
            return [];
        }
        //filter out the current facets filters so they do not affect that facet's counts
        const filteredFilters = filters === null || filters === void 0 ? void 0 : filters.filter((x) => x.fieldId !== facet);
        const options = {
            query,
            filters: serializeFilters(filteredFilters !== null && filteredFilters !== void 0 ? filteredFilters : []),
        };
        try {
            const finalOptions = showCount ? Object.assign({}, options) : {};
            const result = yield searchClient.searchForFacetValues([
                {
                    indexName: indexName,
                    params: Object.assign(Object.assign({}, finalOptions), { facetName: facet, facetQuery, sortFacetValuesBy: showCount ? 'count' : 'alpha' }),
                },
            ]);
            return result[0].facetHits.map((x) => ({
                label: x.value,
                value: x.value,
                metadata: showCount ? x.count : undefined,
            }));
        }
        catch (_a) {
            return [];
        }
    }), [isReady, filters, query, searchClient, indexName]);
    const resource = getResource(searchType, searchResults.data);
    const dataResults = useElektraApi(resource, {
        keepPreviousData: true,
        enabled: enabled && searchResults.data !== undefined,
    });
    return useMemo(() => {
        var _a;
        const isLoading = searchClientResults.isLoading ||
            searchResults.isLoading ||
            facetsResults.isLoading ||
            dataResults.isLoading;
        const isError = searchClientResults.isError ||
            searchResults.isError ||
            facetsResults.isError ||
            dataResults.isError;
        const error = searchClientResults.error ||
            searchResults.error ||
            facetsResults.error ||
            dataResults.error;
        return Object.assign(Object.assign({}, searchResults), { data: (_a = dataResults.data) !== null && _a !== void 0 ? _a : [], facets: facetsResults.facets, isLoading,
            isError,
            error,
            searchFacets });
    }, [
        searchResults,
        facetsResults,
        dataResults,
        searchClientResults,
        searchFacets,
    ]);
}
export { useSearchWithFacets };
