import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useRef, useState, createContext, useMemo, } from 'react';
import Joi from 'joi';
import { useLogger } from 'src/hooks/logger';
const CHECK_INTERVAL = 5 * 60 * 1000; // Every 5 minutes
const STATUS_PAGE_DOMAIN = 'gohumanfirst.statuspage.io';
const IMPACT_SCHEMA = Joi.string().valid('none', 'minor', 'major', 'critical');
const STATUS_EVENT_SCHEMA = Joi.object()
    .rename('created_at', 'createdAt')
    .rename('updated_at', 'updatedAt')
    .rename('monitoring_at', 'monitoringAt')
    .rename('resolved_at', 'resolvedAt')
    .rename('shortlink', 'shortLink')
    .keys({
    createdAt: Joi.date().iso(),
    updatedAt: Joi.date().iso(),
    monitoringAt: Joi.date().iso().allow(null).default(null),
    resolvedAt: Joi.date().iso().allow(null).default(null),
    impact: IMPACT_SCHEMA,
    name: Joi.string(),
    shortLink: Joi.string(),
});
const INCIDENT_SCHEMA = STATUS_EVENT_SCHEMA.keys({
    status: Joi.string().valid('investigating', 'identified', 'monitoring', 'resolved', 'postmortem'),
}).options({ stripUnknown: true });
const MAINTENANCE_SCHEMA = STATUS_EVENT_SCHEMA.rename('scheduled_for', 'scheduledFor')
    .rename('scheduled_until', 'scheduledUntil')
    .keys({
    status: Joi.string().valid('scheduled', 'in_progress', 'verifying', 'completed'),
    scheduledFor: Joi.date().iso(),
    scheduledUntil: Joi.date().iso(),
})
    .options({ stripUnknown: true });
const SUMMARY_SCHEMA = Joi.object()
    .rename('incidents', 'activeIncidents')
    .rename('scheduled_maintenances', 'scheduledMaintenance')
    .keys({
    status: Joi.object()
        .rename('indicator', 'impact')
        .keys({
        impact: IMPACT_SCHEMA,
        description: Joi.string(),
    })
        .required(),
    page: Joi.object()
        .rename('updated_at', 'updatedAt')
        .keys({
        id: Joi.string(),
        name: Joi.string(),
        url: Joi.string().uri(),
        updatedAt: Joi.date().iso(),
    })
        .required(),
    activeIncidents: Joi.array().items(INCIDENT_SCHEMA).required(),
    scheduledMaintenance: Joi.array().items(MAINTENANCE_SCHEMA).required(),
})
    .options({ stripUnknown: true });
const STATUS_CONTEXT_LOADING_VALUE = {
    isOnline: true,
    isHealthy: true,
    description: 'Status is Loading',
    lastUpdated: new Date(0),
    url: '#',
    activeIncidents: [],
    scheduledMaintenance: [],
};
const StatusContext = createContext(STATUS_CONTEXT_LOADING_VALUE);
const StatusProvider = ({ checkInterval = CHECK_INTERVAL, children }) => {
    const logger = useLogger(StatusProvider.name);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [error, setError] = useState();
    const [summary, setSummary] = useState();
    const isMountedRef = useRef(false);
    // Track the mounted status for use async
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    useEffect(() => {
        const listenerIsOnline = () => setIsOnline(true);
        const listenerIsOffline = () => setIsOnline(false);
        window.addEventListener('offline', listenerIsOffline);
        window.addEventListener('online', listenerIsOnline);
        return () => {
            window.removeEventListener('offline', listenerIsOffline);
            window.removeEventListener('online', listenerIsOnline);
        };
    }, []);
    const wasEverOffline = useRef(!isOnline);
    useEffect(() => {
        if (isOnline) {
            // We only want to log that the user is online in the case that they went
            // offline previously
            if (wasEverOffline.current) {
                logger.info('client is online');
            }
        }
        else {
            wasEverOffline.current = true;
            logger.error('client is offline');
        }
    }, [isOnline, logger]);
    const checkStatus = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`https://${STATUS_PAGE_DOMAIN}/api/v2/summary.json`, {
                cache: 'reload',
            });
            const payload = yield response.json();
            // Try to deserialize as this payload?
            if (isMountedRef.current) {
                // Don't change the state if we've unmounted
                const { value, error } = SUMMARY_SCHEMA.validate(payload, {
                    abortEarly: true,
                });
                if (error) {
                    throw error;
                }
                setSummary(value);
            }
        }
        catch (e) {
            if (isMountedRef.current) {
                setError(e);
            }
        }
    }), [isMountedRef]);
    useEffect(() => {
        const interval = setInterval(() => checkStatus().catch((e) => logger.error(e)), checkInterval);
        checkStatus().catch((e) => logger.error(e));
        return () => clearInterval(interval);
    }, [checkStatus, checkInterval, logger]);
    const status = useMemo(() => {
        if (!summary) {
            return Object.assign(Object.assign({}, STATUS_CONTEXT_LOADING_VALUE), { isOnline,
                error });
        }
        const activeIncident = summary.activeIncidents[0] || null;
        return {
            isOnline,
            isHealthy: summary.activeIncidents.length === 0 &&
                summary.status.impact === 'none',
            description: (activeIncident === null || activeIncident === void 0 ? void 0 : activeIncident.name) || summary.status.description,
            lastUpdated: (activeIncident === null || activeIncident === void 0 ? void 0 : activeIncident.updatedAt) || summary.page.updatedAt,
            url: (activeIncident === null || activeIncident === void 0 ? void 0 : activeIncident.shortLink) || summary.page.url,
            activeIncidents: summary.activeIncidents,
            scheduledMaintenance: summary.scheduledMaintenance,
            error,
        };
    }, [isOnline, summary, error]);
    return (React.createElement(StatusContext.Provider, { value: status }, children));
};
export { StatusContext, StatusProvider };
