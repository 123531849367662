import { __awaiter } from "tslib";
import { Button, toaster } from '@humanfirst/elektron';
import { FormResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { useLogger } from 'src/hooks/logger';
/**
 * A "form" that is just a single buttom.
 *
 * Used to minimze customer friction when making requests to human first.
 */
const OneClickForm = ({ successElement, formData, name, buttonText, buttonVariant = 'primary', onSuccess, }) => {
    const { mutateAsync, isLoading, isSuccess } = useElektraApiMutation(FormResource.submit());
    const logger = useLogger(OneClickForm.name);
    return (React.createElement(If, { condition: isSuccess && !!successElement },
        React.createElement(Then, null, successElement),
        React.createElement(Else, null,
            React.createElement(Button, { isLoading: isLoading, disabled: isSuccess, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        yield mutateAsync({ title: name, data: formData });
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    }
                    catch (e) {
                        if ((e === null || e === void 0 ? void 0 : e.status) === 429) {
                            logger.warn(e);
                            toaster.negative('You have reached the request limit. Unable to submit. Try again soon.');
                        }
                        else {
                            logger.error(e);
                            toaster.negative('Unable to submit form at this time.');
                        }
                    }
                }), size: "large", variant: buttonVariant }, buttonText))));
};
export { OneClickForm };
