import { __awaiter } from "tslib";
import { Button, Spread, Typography, TypographyVariant, Modal, Stack, toaster, } from '@humanfirst/elektron';
import { FormResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useState } from 'react';
import { When } from 'react-if';
import { PATHS } from 'src/config/path';
import { useToggleListState } from 'src/hooks/state';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { BetaTag } from 'src/components/Badges';
import { useCompareTechnologies } from '../../../pages/Compare/hooks/hooks';
import { ComparePDFButton } from './components/ComparePDFButton';
import { Measures, ProjectForReportDelivery, } from './components/DataFieldSections';
const REQUEST_TIME = '5 business days';
const ConfirmationStep = ({ closeModal, }) => {
    return (React.createElement(Stack, { padding: '32px', gap: '12px' },
        React.createElement(Typography, { variant: TypographyVariant.Heading1 }, "Export Request Received"),
        React.createElement(Typography, null, "We have recieved your request. You will be notified via email when your export is ready to view."),
        React.createElement(Typography, { variant: TypographyVariant.BodyBold },
            "Please allow for up to ",
            REQUEST_TIME,
            " for your request to be fulfilled."),
        React.createElement(Spread, { direction: 'horizontal', justifyContent: 'flex-end' },
            React.createElement(Button, { onClick: closeModal }, "Close"))));
};
const CompareExportForm = ({ setStage, closeModal, technologies }) => {
    const [selectedDataFields, toggleSelectedDataFields] = useToggleListState([]);
    const projectId = useActiveWorkspaceId();
    const linkToCompare = new URL(PATHS.compare, window.location.href).toString() +
        `?selection=${technologies.map((x) => x.id).join(',')}`;
    const { mutateAsync, isLoading } = useElektraApiMutation(FormResource.submit());
    const onSubmit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield mutateAsync({
                title: 'Request Comparison Export',
                data: {
                    'Link to Compare': linkToCompare,
                    'Project ID': projectId !== null && projectId !== void 0 ? projectId : 'None',
                    'Requested Fields': `\n-${[...selectedDataFields].join('\n-')}`,
                },
            });
            setStage('complete');
        }
        catch (e) {
            closeModal();
            toaster.negative('Unable to make request at this time.');
        }
    }), [
        closeModal,
        linkToCompare,
        mutateAsync,
        projectId,
        selectedDataFields,
        setStage,
    ]);
    return (React.createElement(Stack, { gap: "16px", maxHeight: '80vh' },
        React.createElement(Stack, null,
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Typography, { variant: TypographyVariant.Heading1 }, "Export Comparison"),
                React.createElement(BetaTag, null)),
            React.createElement(Typography, null,
                "PDF exports will be delivered as a report within ",
                REQUEST_TIME,
                " of the request.")),
        React.createElement(ProjectForReportDelivery, { toggleSelection: toggleSelectedDataFields }),
        React.createElement(Measures, { toggleSelection: toggleSelectedDataFields, technologies: technologies !== null && technologies !== void 0 ? technologies : [] }),
        React.createElement(Spread, { direction: 'horizontal', justifyContent: 'flex-end', gap: '12px' },
            React.createElement(Button, { size: "large", variant: "tertiary", onClick: closeModal }, "Cancel"),
            React.createElement(Button, { size: "large", isLoading: isLoading, onClick: onSubmit }, "Request Export"))));
};
export const RequestComparePDF = () => {
    const { technologies } = useCompareTechnologies();
    const [isOpen, setIsOpen] = useState(false);
    const [stage, setStage] = useState('form');
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
            React.createElement(When, { condition: stage === 'form' },
                React.createElement(CompareExportForm, { technologies: technologies !== null && technologies !== void 0 ? technologies : [], closeModal: closeModal, setStage: setStage })),
            React.createElement(When, { condition: stage === 'complete' },
                React.createElement(ConfirmationStep, { closeModal: closeModal }))),
        React.createElement(ComparePDFButton, { openModal: openModal, technologies: technologies !== null && technologies !== void 0 ? technologies : [] })));
};
