import React, { useMemo } from 'react';
import { VictoryContainer, VictoryPie, VictoryTooltip, VictoryLegend, VictoryLabel, } from 'victory';
import { COLORS } from '@humanfirst/elektron';
import { If, Then } from 'react-if';
import { PIE_CHART_THEME, sharedLabelStyles } from '../theme';
import { createDataEvents } from '../utils';
const DEFAULT_CHART_WIDTH = 640;
const DEFAULT_CHART_HEIGHT = 380;
const DEFAULT_RADIUS = 175;
const DEFAULT_INNER_RADIUS = 90;
const DEFAULT_LEGEND_X_OFFSET = 400;
const DEFAULT_LEGEND_LABEL_FONT_SIZE = 30;
const DEFAULT_LEGEND_SYMBOL_SPACER = 16;
const DEFAULT_COLOR_SCALE = [
    COLORS.primary,
    COLORS.blue4,
    COLORS.secondary,
    COLORS.blue3,
];
const DEFAULT_PADDING = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 40,
};
const DEFAULT_DATA_EVENTS = createDataEvents();
const RADIUS_OFFSET = 5;
const labelGetterFn = ({ datum }) => `${datum.x}: ${datum.y}`;
const PieChart = ({ data, donut = false, chartWidth = DEFAULT_CHART_WIDTH, chartHeight = DEFAULT_CHART_HEIGHT, radius = DEFAULT_RADIUS, innerRadius = DEFAULT_INNER_RADIUS, colorScale = DEFAULT_COLOR_SCALE, legendSymbolSpacer = DEFAULT_LEGEND_SYMBOL_SPACER, legendXOffset = DEFAULT_LEGEND_X_OFFSET, legendLabelFontSize = DEFAULT_LEGEND_LABEL_FONT_SIZE, padding = DEFAULT_PADDING, dataEvents = DEFAULT_DATA_EVENTS, totalDataCount = data.length, totalLabel, }) => {
    const mappedLegendData = useMemo(() => {
        return data.map(({ x: name, y: count }) => ({
            name: `${name}: ${count}`,
            symbol: { type: 'square' },
        }));
    }, [data]);
    const legendHeight = useMemo(() => {
        const lineHeight = sharedLabelStyles.fontSize;
        const numberOfItems = mappedLegendData.length;
        return (numberOfItems * lineHeight +
            numberOfItems * DEFAULT_LEGEND_LABEL_FONT_SIZE);
    }, [mappedLegendData]);
    const legendYPosition = (chartHeight - legendHeight) / 2;
    const emptyState = data.length === 0;
    return (React.createElement(VictoryContainer, { width: chartWidth, height: chartHeight, theme: PIE_CHART_THEME },
        React.createElement(VictoryPie, { standalone: false, radius: radius, innerRadius: donut ? innerRadius : 0, 
            // This must have an element and must not be undefined otherwise VictoryPie assumes VictoryTooltip will be rendered
            labelComponent: emptyState ? React.createElement(React.Fragment, null) : React.createElement(VictoryTooltip, null), labels: labelGetterFn, theme: PIE_CHART_THEME, padding: padding, data: emptyState ? [{ y: 1, x: 'unknown' }] : data, events: emptyState ? undefined : dataEvents, colorScale: emptyState ? [COLORS.gray3] : colorScale }),
        React.createElement(If, { condition: !!totalLabel },
            React.createElement(Then, null,
                React.createElement(VictoryLabel, { textAnchor: "middle", style: { fontSize: DEFAULT_LEGEND_LABEL_FONT_SIZE }, x: radius + RADIUS_OFFSET, y: chartHeight / 2 - 0.25 * DEFAULT_LEGEND_LABEL_FONT_SIZE, text: totalDataCount }),
                React.createElement(VictoryLabel, { textAnchor: "middle", style: { fontSize: DEFAULT_LEGEND_LABEL_FONT_SIZE }, x: radius + RADIUS_OFFSET, y: chartHeight / 2 + 0.75 * DEFAULT_LEGEND_LABEL_FONT_SIZE, text: totalLabel }))),
        React.createElement(VictoryLegend, { colorScale: colorScale, style: {
                labels: Object.assign(Object.assign({}, sharedLabelStyles), { fontSize: legendLabelFontSize }),
            }, symbolSpacer: legendSymbolSpacer, standalone: false, data: mappedLegendData, y: legendYPosition, x: legendXOffset })));
};
export { PieChart };
