import React from 'react';
import { ListPage } from 'src/pages/Workspace/components/SearchAndPlan/ListPage';
import { WorkspaceEdit } from 'src/pages/WorkspaceEdit';
import { Report } from 'src/pages/Report';
import { WorkspaceDetails } from 'src/pages/Workspace';
import { WorkspaceCreate } from 'src/pages/WorkspaceCreate';
import { Workspaces } from 'src/pages/Workspaces';
import { Compare } from 'src/pages/Compare';
const ProjectRoutes = {
    path: 'projects',
    children: {
        list: {
            index: true,
            element: React.createElement(Workspaces, null),
        },
        create: { path: 'create', element: React.createElement(WorkspaceCreate, null) },
        details: {
            path: ':projectId',
            children: {
                details: { index: true, element: React.createElement(WorkspaceDetails, null) },
                edit: { path: 'edit', element: React.createElement(WorkspaceEdit, null) },
                compare: {
                    path: 'compare',
                    element: React.createElement(Compare, null),
                },
                reports: {
                    path: 'reports',
                    children: {
                        view: { path: ':id/*', element: React.createElement(Report, null) },
                    },
                },
                lists: {
                    path: 'lists',
                    children: {
                        list: { index: true, element: React.createElement(WorkspaceDetails, null) },
                        view: { path: ':listName', element: React.createElement(ListPage, null) },
                    },
                },
            },
        },
    },
};
export { ProjectRoutes };
