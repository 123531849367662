/**
 * Groups of measure categories, with an associated label.
 */
export const CATEGORY_GROUPS = [
    {
        label: 'Cardiopulmonary & Physical Activity',
        categories: [
            'Activity',
            'Cardiovascular',
            'Musculoskeletal/Gait',
            'Pulmonary',
        ],
    },
    {
        label: 'Endocrinology, Gastrointestinal & Nutrition',
        categories: [
            'Anthropometrics',
            'Endocrinology/Metabolism',
            'Gastrointestinal/Nutrition',
        ],
    },
    {
        label: 'Genitourinary & Reproduction',
        categories: ["Women's Health", "Men's Health", 'Genitourinary/Nephrology'],
    },
    {
        label: 'Hematology, Oncology, Infectious Diseases & Immunology',
        categories: [
            'Dermatology',
            'Hematology/Oncology',
            'Immunology',
            'Infectious Diseases',
        ],
    },
    {
        label: 'Neuropsychology, Head, Eyes, Ears, Nose & Throat, Sleep',
        categories: [
            'Body Temperature',
            'Otolaryngology',
            'Neuro/Psych',
            'Sleep',
            'Ophthalmology',
        ],
    },
    {
        label: 'Non-physiological',
        categories: [
            'Environment',
            'Healthcare Outcomes',
            'Location',
            'Utility and Usability',
            'Digital Indicators of Health',
        ],
    },
];
