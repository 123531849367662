import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { Block, Button, COLORS, Divider, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { AuthResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { LoginError } from '..';
const PasswordlessCTA = ({ email, onLinkSendSuccess, }) => {
    const track = useAnalyticsEvent();
    const [passwordlessError, setPasswordlessError] = useState();
    const { isError, isLoading: isAuthorizeLoading } = useElektraApi(AuthResource.getAuthorize({ loginHint: email, provider: 'passwordless' }), { retry: false });
    const { mutateAsync, isLoading: isLinkLoading } = useElektraApiMutation(AuthResource.passwordless());
    const sendLink = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            track('login-attempt', { email });
            yield mutateAsync({ email });
            onLinkSendSuccess();
        }
        catch (e) {
            setPasswordlessError('Unable to send Magic Link. Please sign in with password.');
        }
    }), [onLinkSendSuccess, email, mutateAsync, track]);
    if (isAuthorizeLoading || isError) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null,
            React.createElement(Block, { padding: "0px 16px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold, color: COLORS.gray2 }, "or"))),
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading3 }, "Log in without a password"),
            React.createElement(Typography, null, "We'll send a Magic Link to your email that will sign you in automatically."),
            React.createElement(LoginError, { error: passwordlessError }),
            React.createElement(Button, { size: "full", variant: "secondary", isLoading: isLinkLoading, onClick: sendLink, disabled: !!passwordlessError }, "Send Magic Link"))));
};
export { PasswordlessCTA };
