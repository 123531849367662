import React, { useCallback, useMemo } from 'react';
import { MedicalConditionsResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { useParams } from 'react-router-dom';
import { Block, COLORS } from '@humanfirst/elektron';
import { useQueryParams, withDefault, NumberParam, ArrayParam, useQueryParam, BooleanParam, } from 'use-query-params';
import { uniq } from 'ramda';
import { CatalogResourceDetails } from 'src/components/CatalogResourceDetails/CatalogResourceDetails';
import { CatalogResourceError } from 'src/components/CatalogResourceDetails';
import ImageMCExample from 'src/assets/mc_example.png';
import { ContentContainer, HeaderContentContainer, } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { useAttachToolsToMeasuresWithEvidence, useGetEvidenceForMedicalCondition, useGetMeasureCategoriesForMedicalCondition, } from 'src/hooks/relatedResources';
import { getSortedMeasuresWithEvidence } from 'src/utils/entityDetailPages';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { MedicalConditionTabList, MedicalConditionTabPanels, } from './components/Tabs';
import { MedicalConditionTopCards } from './components/TopCards';
const MedicalConditionDetails = ({ data: condition, }) => {
    const [queryParams, setQueryP] = useQueryParams({
        view: withDefault(NumberParam, 0),
        filters: withDefault(ArrayParam, []),
    });
    const setViewAndFilters = useCallback((view, filters) => setQueryP({ view, filters }, 'push'), [setQueryP]);
    const { view } = queryParams;
    const showSupportingEvidenceTable = useCallback((source, selected) => {
        const preFilter = source === 'Technology'
            ? [`tools||Includes Any Of||["${selected}"]`]
            : [`allMeasuresFlat||Includes Any Of||["${selected}"]`];
        setViewAndFilters(2, preFilter);
    }, [setViewAndFilters]);
    const { data: evidence, isLoading: isEvidenceLoading } = useGetEvidenceForMedicalCondition(condition.id);
    const [filterEvidenceByPMC, setFilterEvidenceByPMC] = useQueryParam('primary', withDefault(BooleanParam, false));
    const filteredEvidenceByPMC = useMemo(() => {
        return filterEvidenceByPMC
            ? evidence === null || evidence === void 0 ? void 0 : evidence.filter((e) => { var _a; return (_a = e.primaryMedicalConditions) === null || _a === void 0 ? void 0 : _a.some((mc) => mc.id === condition.id); })
            : evidence;
    }, [filterEvidenceByPMC, evidence, condition.id]);
    // Grab all toolid's associated with the evidence for this condition
    const tools = useMemo(() => {
        return filteredEvidenceByPMC
            ? uniq(filteredEvidenceByPMC
                .flatMap((x) => { var _a; return (_a = x.sourceMeasures) === null || _a === void 0 ? void 0 : _a.flatMap((y) => y.tools); })
                .filter((x) => !!x)
                .map((x) => x.id))
            : [];
    }, [filteredEvidenceByPMC]);
    const { data: fullTools, isLoading: isFullToolLoading } = useElektraApi(ToolResource.getBulk(tools || [], {
        join: ['maker||id,name,evidenceCount', 'medicalConditions||id,name'],
    }));
    const { data: measureCategories, isLoading: isMeasureCategoriesLoading } = useGetMeasureCategoriesForMedicalCondition(condition.id);
    // Join the evidence with the measure categories for this condition
    const categoryWithEvidence = getSortedMeasuresWithEvidence(measureCategories, filteredEvidenceByPMC);
    const categoryWithToolsAndEvidence = useAttachToolsToMeasuresWithEvidence(categoryWithEvidence !== null && categoryWithEvidence !== void 0 ? categoryWithEvidence : []);
    const areResourcesLoading = isFullToolLoading || isEvidenceLoading || isMeasureCategoriesLoading;
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: condition.name }),
        React.createElement(HeaderContentContainer, { backButton: false, breadcrumbs: [
                BREADCRUMBS.Atlas,
                BREADCRUMBS.MedicalConditions,
                BREADCRUMBS.MedicalConditionDetails(condition),
            ] },
            React.createElement(MedicalConditionTopCards, { filterEvidenceByPMC: filterEvidenceByPMC, setFilterEvidenceByPMC: setFilterEvidenceByPMC, showEvidence: showSupportingEvidenceTable, medicalCondition: condition, evidence: filteredEvidenceByPMC, tools: fullTools, categoryWithToolsAndEvidence: categoryWithToolsAndEvidence, isLoading: areResourcesLoading })),
        React.createElement(ContentContainer, { padding: "24px 56px 0px 56px" },
            React.createElement(MedicalConditionTabList, { setViewAndFilters: setViewAndFilters, view: view })),
        React.createElement(ContentContainer, { backgroundColor: COLORS.white },
            React.createElement(MedicalConditionTabPanels, { isLoading: areResourcesLoading, view: view, medicalCondition: condition, evidence: filteredEvidenceByPMC, filterEvidenceByPMC: filterEvidenceByPMC, categoryWithToolsAndEvidence: categoryWithToolsAndEvidence, showEvidence: showSupportingEvidenceTable, tools: fullTools }))));
};
const MedicalConditionUpgradePage = ({ error }) => (React.createElement(CatalogResourceError, { error: error, imageUrl: ImageMCExample, resourceName: 'medical condition' }));
const MedicalConditionPage = () => {
    const { id } = useParams();
    return (React.createElement(CatalogResourceDetails, { resource: MedicalConditionsResource.get(id), errorComponent: MedicalConditionUpgradePage, resourceDetails: MedicalConditionDetails, permissionResourceName: 'medicalConditions' }));
};
export { MedicalConditionPage as MedicalCondition, MedicalConditionDetails };
