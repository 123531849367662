const TITLE_SORT = {
    id: 'title',
    label: 'Title',
    getValue: (item) => item.title,
};
const DATE_ADDED_SORT = {
    id: 'createdAt',
    label: 'Date Added',
    getValue: (item) => 
    //we want to sort descending.
    !!item.bookmark ? -new Date(item.bookmark.createdAt).getTime() : 0,
};
export { TITLE_SORT, DATE_ADDED_SORT };
