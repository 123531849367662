import { Center, Block, Text, Stack, Divider } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { LoadingState } from 'src/components/LoadingState';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getBookmarksMatchingMetadata, getMetadataMatching, useGetBookmarksAndMetadata, } from '../../utils';
import { SECTIONS, SECTIONS_WITH_STUDIES } from './sections';
import { ListCard } from './components/ListCard';
import { SearchAndPlanJumpSection } from './components/SearchAndPlanJumpSection';
const ErrorState = () => {
    return (React.createElement(Center, { margin: "16px" },
        React.createElement(Block, null,
            React.createElement(Text, null, "There was an error while loading your data."))));
};
/**
 * Displays the Search and Plan flow.
 *
 * This is a display component that organizes all of the bookmarks in the
 * appropriate ordering.
 */
const SearchAndPlanInternal = ({ bookmarks, metadata, workspaceId, }) => {
    const hasStudiesInProjects = useHasPermission('connect', 'read', 'all');
    const correctJumpSections = hasStudiesInProjects
        ? SECTIONS_WITH_STUDIES
        : SECTIONS;
    const archivedBookmarks = useMemo(() => getBookmarksMatchingMetadata(bookmarks, metadata, 'systemMetadata', 'Archived'), [bookmarks, metadata]);
    const activeBookmarks = useMemo(() => bookmarks.filter((b) => !archivedBookmarks.includes(b)), [bookmarks, archivedBookmarks]);
    const listNames = useMemo(() => getMetadataMatching(metadata, 'tag')
        .map(({ metadataValue }) => metadataValue)
        .filter((name, index, arr) => arr.indexOf(name) === index), [metadata]);
    const activeListsAndBookmarks = useMemo(() => listNames
        .map((name) => [
        name,
        getBookmarksMatchingMetadata(activeBookmarks, metadata, 'tag', name),
    ])
        .filter(([, bookmarks]) => bookmarks.length > 0), [listNames, activeBookmarks, metadata]);
    return (React.createElement(Stack, { gap: '48px' },
        React.createElement(Block, { width: "100%", display: "grid", gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr'], gridGap: ['8px', '8px', '8px', '24px'] },
            React.createElement(ListCard, { listName: 'All Saved', bookmarks: activeBookmarks, projectId: workspaceId }),
            React.createElement(ListCard, { listName: 'Archived', bookmarks: archivedBookmarks, projectId: workspaceId }),
            activeListsAndBookmarks.map((x) => (React.createElement(ListCard, { key: x[0], listName: x[0], bookmarks: x[1], projectId: workspaceId })))),
        React.createElement(Divider, null),
        React.createElement(SearchAndPlanJumpSection, { sections: correctJumpSections })));
};
/**
 * Displays the search and plan interface.
 *
 * This component is just responsible for loading the bookmarks which define
 * the search and plan flow.
 */
const SearchAndPlan = ({ data: workspace }) => {
    const { isLoading, isError, metadata, bookmarks } = useGetBookmarksAndMetadata(workspace);
    if (isError) {
        return React.createElement(ErrorState, null);
    }
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement(SearchAndPlanInternal, { bookmarks: bookmarks, metadata: metadata, workspaceId: workspace.id })));
};
export { SearchAndPlan };
