import React from 'react';
import { FilterType, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { EvidenceTableScoped } from 'src/components/tables/EvidenceTable';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const VendorEvidence = ({ organization, }) => {
    var _a, _b;
    const result = useElektraApi(ToolResource.list({
        filter: [['maker', '$eq', organization.id]],
    }));
    const ids = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.map((x) => x.id)) !== null && _b !== void 0 ? _b : [];
    const hasVendorEvidence = useHasPermission('organization.evidence', 'read', organization);
    if (!hasVendorEvidence) {
        return null;
    }
    return (React.createElement(Stack, { gap: "32px", paddingTop: '8px' },
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Evidence"),
        React.createElement(EvidenceTableScoped, { scope: [
                {
                    fieldId: 'toolIds',
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: ids,
                },
            ] })));
};
export { VendorEvidence };
