import { FilterType, Tab, TabList, TabPanel } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { Else, If, Then } from 'react-if';
import { LoadingState } from 'src/components/LoadingState';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { LockableEvidenceTable } from '../../Tool/components/LockableEvidenceTable';
import { MedicalConditionMeasureTable } from './MedicalConditionMeasureTable';
import { MedicalConditionToolTable } from './MedicalConditionToolTable';
const MedicalConditionTabList = ({ view, setViewAndFilters, }) => {
    const hasAllEvidence = useHasAllEvidence();
    return (React.createElement(TabList, { onChange: setViewAndFilters, active: view },
        React.createElement(Tab, null, "Technologies"),
        React.createElement(Tab, null, "Measures"),
        hasAllEvidence ? React.createElement(Tab, null, "Evidence") : null));
};
const MedicalConditionTabPanels = ({ isLoading, view, medicalCondition, showEvidence, filterEvidenceByPMC, tools, evidence, categoryWithToolsAndEvidence, }) => {
    const hasAllEvidence = useHasAllEvidence();
    const scope = useMemo(() => {
        return filterEvidenceByPMC
            ? [
                {
                    fieldId: 'primaryMedicalConditions',
                    filterType: FilterType.EQUALS,
                    filterValue: medicalCondition.name,
                },
            ]
            : [
                {
                    fieldId: 'medicalConditions',
                    filterType: FilterType.EQUALS,
                    filterValue: medicalCondition.name,
                },
            ];
    }, [filterEvidenceByPMC, medicalCondition.name]);
    const { evidenceTableScopes } = useGetConnectInstrumentScopes();
    const finalScope = [...scope, ...evidenceTableScopes];
    return (React.createElement(React.Fragment, null,
        React.createElement(TabPanel, { index: 0, active: view },
            React.createElement(If, { condition: isLoading },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(MedicalConditionToolTable, { condition: medicalCondition, showEvidence: showEvidence, techWithEvidence: tools !== null && tools !== void 0 ? tools : [], evidence: evidence !== null && evidence !== void 0 ? evidence : [] })))),
        React.createElement(TabPanel, { index: 1, active: view },
            React.createElement(If, { condition: isLoading },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(MedicalConditionMeasureTable, { condition: medicalCondition, measuresWithEvidence: categoryWithToolsAndEvidence, showEvidence: showEvidence })))),
        hasAllEvidence ? (React.createElement(TabPanel, { index: 2, active: view },
            React.createElement(LockableEvidenceTable, { isLocked: !hasAllEvidence, scope: finalScope, title: "Evidence", lockedCta: "Tell us more to unlock evidence for this medical condition." }))) : null));
};
export { MedicalConditionTabList, MedicalConditionTabPanels };
