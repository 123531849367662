import { Stack, Typography } from '@humanfirst/elektron';
import React from 'react';
import { MeasureTagPip } from './MeasureTagPip';
import { MEASURE_TAG_DATA } from './data';
/** A measure tag (optionally including the description). */
const MeasureTag = ({ tag, includeDescription }) => {
    const { title, description } = MEASURE_TAG_DATA[tag];
    if (includeDescription) {
        return (React.createElement(Stack, null,
            React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                React.createElement(MeasureTagPip, { tag: tag }),
                React.createElement(Typography, { color: "inherit" }, title)),
            React.createElement(Typography, null, description)));
    }
    else {
        return (React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
            React.createElement(MeasureTagPip, { tag: tag }),
            React.createElement(Typography, { color: "inherit" }, title)));
    }
};
export { MeasureTag };
