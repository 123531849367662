import { WorkspaceStatus } from '@humanfirst/api-models';
/** Get the team name from a workspace. */
const getTeamName = (item) => { var _a, _b; return (_b = (_a = item.team) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''; };
/** Get a human readable string for the current status. */
const getStatusDisplay = (workspace) => {
    switch (workspace.status) {
        case WorkspaceStatus.Archived:
            return 'Archived';
        case WorkspaceStatus.Completed:
            return 'Complete';
        default:
            return 'In Progress';
    }
};
export { getStatusDisplay, getTeamName };
