import { __awaiter } from "tslib";
import { Center, Heading, Text, Button, COLORS, Link, Spacer, Block, Paragraph, Card, Stack, Icons, Logo, Image, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/hooks/user';
import { PageTitle } from 'src/components/PageTitle';
import { generatePath } from 'src/utils/path';
import { useIntercom } from 'src/contexts/IntercomContext';
import { PATHS } from 'src/config/path';
import ProductImage from 'src/assets/explore/inside-atlas/technology.png';
import { StatusIndicator } from 'src/components/StatusIndicator';
import { MARKETING_EMAILS_LAST_PROPMT_DATE } from 'src/constants/persistentKeys';
import { usePersistentState } from 'src/hooks/persistence';
import { useAnalyticsEvent } from '../../hooks/analytics';
import { SignupForm } from './SignupForm';
const SignupCopy = () => {
    return (React.createElement(Block, null,
        React.createElement(Logo, { height: "2em" }),
        React.createElement(Spacer, { size: "24px" }),
        React.createElement(Heading, null, "Differentiate your clinical research with precision measures"),
        React.createElement(Paragraph, { variant: TypographyVariant.Heading3 }, "Reduce costs and de-risk trial portfolios by measuring what matters to your patient population. HumanFirst\u2019s insights and evidence platform helps biopharma bring better treatments to market faster."),
        React.createElement(Spacer, { size: "24px" }),
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading3 },
                React.createElement(Icons.Product.Yes, { decorative: true }),
                " Over 19,000 physiological and behavioral measures classified into 1,700+ categories."),
            React.createElement(Typography, { variant: TypographyVariant.Heading3 },
                React.createElement(Icons.Product.Yes, { decorative: true }),
                " Thousands of pieces of curated evidence, mapped to the most comprehensive resource for digital biomarker and digital health technology information."),
            React.createElement(Typography, { variant: TypographyVariant.Heading3 },
                React.createElement(Icons.Product.Yes, { decorative: true }),
                " Collaborative project spaces for sharing, deciding, and tracking protocol decisions.")),
        React.createElement(Spacer, { size: "32px" }),
        React.createElement(Center, null,
            React.createElement(Image, { width: "70%", src: ProductImage, alt: "Technology from HumanFirst Atlas" }))));
};
const Signup = () => {
    const [name] = useQueryParam('name', StringParam);
    const [email] = useQueryParam('email', StringParam);
    const [, setLastSeenMarketingPrompt] = usePersistentState(MARKETING_EMAILS_LAST_PROPMT_DATE);
    const user = useUser();
    const { show } = useIntercom();
    const showIntercom = useCallback(() => {
        if (show) {
            show();
        }
    }, [show]);
    const trackSignupFailure = useAnalyticsEvent();
    const [showSignUpError, setShowSignUpError] = useState(false);
    const { mutateAsync } = useElektraApiMutation(AuthResource.signUp());
    const navigate = useNavigate();
    const onSubmit = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ email, name, subscribeUpdates, password, captchaToken, }) {
        try {
            yield mutateAsync({
                email,
                name,
                subscribeUpdates,
                password,
                captchaToken,
            });
            const now = new Date();
            setLastSeenMarketingPrompt(now.toISOString());
            navigate(`${generatePath(PATHS.authentication.signup.success)}?email=${encodeURIComponent(email)}`);
        }
        catch (e) {
            const errorString = typeof (e === null || e === void 0 ? void 0 : e.message) === 'string' ? e.message : String(e);
            if (errorString ===
                'Sign up is currently disabled.  Please reach out via Intercom or support@gohumanfirst.com') {
                setShowSignUpError(true);
            }
            else {
                trackSignupFailure('signup-error', {
                    name,
                    email,
                    error: String(e),
                });
                // Re-throw so smart form can display error like normal
                throw e;
            }
        }
    }), [mutateAsync, navigate, trackSignupFailure, setLastSeenMarketingPrompt]);
    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: "Sign up" }),
        React.createElement(Block, { display: "flex", flexDirection: [
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
            ], justifyContent: ['start', 'start', 'start', 'center'], alignItems: ['center', 'center', 'center', 'start'], marginTop: ['24px', '24px', '24px', '80px'] },
            React.createElement(Block, { maxWidth: "500px", marginLeft: '24px', marginRight: '24px', display: ['none', 'none', 'none', 'block'] },
                React.createElement(SignupCopy, null)),
            React.createElement(Spacer, { size: "24px" }),
            React.createElement(Block, { maxWidth: "500px" },
                React.createElement(Stack, { gap: "24px" },
                    React.createElement(StatusIndicator, null),
                    React.createElement(Card, null,
                        React.createElement(Heading, null, "Join HumanFirst Atlas"),
                        React.createElement(SignupForm, { onSubmit: onSubmit, email: email !== null && email !== void 0 ? email : undefined, name: name !== null && name !== void 0 ? name : undefined }),
                        React.createElement(Spacer, { size: "24px" }),
                        React.createElement(Center, null,
                            React.createElement(Text, null,
                                "Have an account?",
                                ' ',
                                React.createElement(Link, { href: generatePath(PATHS.authentication.login.request) }, "Log in"))),
                        showSignUpError ? (React.createElement(Block, { marginTop: "32px" },
                            React.createElement(Paragraph, { color: COLORS.alert, align: "center" },
                                "Sign up is currently disabled. Please reach out via Please reach out via Intercom using the button below or",
                                ' ',
                                React.createElement(Link, { href: "mailto:support@gohumanfirst.com" }, "support@gohumanfirst.com")),
                            React.createElement(Button, { size: "full", variant: "secondary", onClick: showIntercom }, "Reach out via Intercom"))) : null))))));
};
export { Signup };
