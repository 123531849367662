import React from 'react';
import { Typography, TypographyVariant, Link, Block, Stack, Spacer, } from '@humanfirst/elektron';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { ToolImageLink } from 'src/components/ToolImageLink/ToolImageLink';
const TechnologyColumnDisplay = ({ tool }) => {
    var _a;
    if (!tool) {
        return null;
    }
    return (React.createElement(Block, { maxWidth: '200px' },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(ToolImageLink, { tool: tool }),
            React.createElement(Block, null,
                React.createElement(Link, { href: generatePath(PATHS.atlas.technologies.detail, {
                        id: tool.id,
                    }) }, tool.model),
                React.createElement(Typography, { variant: TypographyVariant.Details }, (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name))),
        React.createElement(Spacer, null)));
};
export { TechnologyColumnDisplay };
