import React from 'react';
import { createRoot } from 'react-dom/client';
/** Normalize provides a CSS reset to make sure our app will function across browsers. */
import 'normalize.css';
/** Custom opinionated css reset. Rests most elements margins + adds border-box as default box-sizing property */
import '../public/custom.css';
import { App } from './App';
import { initializeRollbar } from './utils/rollbar';
initializeRollbar();
const container = document.getElementById('root');
if (!container) {
    throw new Error('Could not find root element');
}
const root = createRoot(container);
root.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)));
