import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { COLORS, Icons, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { PageTitle } from 'src/components/PageTitle';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { useUser } from 'src/hooks/user';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { useToken } from 'src/hooks/authentication';
import { PATHS } from 'src/config/path';
import { encodeForUrl } from 'src/utils/serialize';
import { MultiStageLogin } from './components';
import { REDIRECT_ERROR_MESSAGE } from './constants';
/**
 * Adds state to a redirect login URL.
 */
const addState = (url, next) => {
    // Only handle workos since other links might have a different
    // structures.
    if (url.includes('https://api.workos.com/') && next) {
        return `${url}&state=${encodeForUrl({ next })}`;
    }
    return url;
};
/** Decodes a URI component or returns '/' if the value can not be decoded. */
const safedecodeURIComponent = (uri) => {
    try {
        return decodeURIComponent(uri);
    }
    catch (_a) {
        // Default to '/' instead of '' to align with default behavior below.
        return '/';
    }
};
const Login = () => {
    var _a;
    const location = useLocation();
    const navigate = useNavigate();
    const user = useUser();
    const getToken = useToken();
    const track = useAnalyticsEvent();
    const [defaultEmail] = useQueryParam('email', useMemo(() => withDefault(StringParam, ''), []));
    // Double-slashes at the start of this count as a separate domain.
    //
    // However, that fails in most cases because `//search` tries to go to the host
    // `search` which is rejected for security reasons (cross-origin restrictions).
    //
    // There's no cases that we want to allow that at this time, so clean it up.
    const [nextPath] = useQueryParam('next', StringParam);
    const normalizedNextPath = safedecodeURIComponent(nextPath !== null && nextPath !== void 0 ? nextPath : '/').replace(/^\/+/, '/');
    // Our callback page might redirect here with an error message
    const ssoError = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.error;
    const onSubmitCallback = useCallback((_b) => __awaiter(void 0, [_b], void 0, function* ({ email, password }) {
        try {
            // Reset any error state we may have here. Not super thrilled about this
            if (location.state) {
                navigate(PATHS.authentication.login.request, {
                    replace: true,
                    state: undefined,
                });
            }
            if (password) {
                // Track this as an actual attempt, but only if we have a password.
                // This can help differentiate from the event where this is submitted
                // without a password to kickoff SSO.
                track('login-attempt', { email });
            }
            yield getToken({ username: email, password, grant_type: 'password' });
            navigate(normalizedNextPath);
        }
        catch (e) {
            const errorString = typeof (e === null || e === void 0 ? void 0 : e.message) === 'string' ? e.message : String(e);
            if (errorString === REDIRECT_ERROR_MESSAGE) {
                // If a redirect is required it's not a "true" error
                // We will track this as a "login attempt" since this indicates a user is attempting
                // a non-password based login.
                track('login-attempt', { email });
                const url = e.body.url;
                if (url) {
                    window.location.assign(addState(url, normalizedNextPath));
                }
                return;
            }
            if (password === '') {
                // For now, an empty password _probably_ means that this is not an actual invalid password
                // because nobody can possibly have this and we shouldn't allow it to even attempt to submit
                // in the first place.
                //
                // Instead, an empty password means that we're testing if this is a "password" based
                // account instead of an SSO account and it fires off on every user.
                // In the future, this will be replaced by a check against the authorization URL but we
                // aren't there yet.
                //
                // With that in mind, throw this early so we don't get to the tracking.
                throw e;
            }
            track('login-error', {
                email,
                error: String(e),
            });
            throw e;
        }
    }), [getToken, track, navigate, normalizedNextPath, location.state]);
    /**
     * Listen for a user. If we have a user, it means we have authenticated.
     * Redirect to the appropriate place
     */
    useEffect(() => {
        if (user) {
            navigate(normalizedNextPath);
        }
    }, [user, navigate, normalizedNextPath]);
    if (user) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Sign In" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(When, { condition: ssoError },
                React.createElement(Stack, { gap: "4px", direction: "horizontal", alignItems: 'center' },
                    React.createElement(Icons.Misc.Error, { color: COLORS.alert, size: "1em", decorative: true }),
                    React.createElement(Typography, { color: COLORS.alert, variant: TypographyVariant.BodyBold }, ssoError)),
                React.createElement(Spacer, { size: "16px" })),
            React.createElement(MultiStageLogin, { defaultEmail: defaultEmail, onSubmit: onSubmitCallback }))));
};
export { Login };
