import { __awaiter } from "tslib";
import { Center } from '@humanfirst/elektron';
import React, { useEffect, useRef, useState, useContext, useCallback, } from 'react';
import { REFRESH_LOCK } from 'src/constants/persistentKeys';
import { useAuthenticationToken, useHandleRefreshToken, } from 'src/hooks/authentication';
import { useLogger } from 'src/hooks/logger';
import { AuthenticationContext } from 'src/contexts/AuthenticationContext';
import { usePersistentState } from 'src/hooks/persistence';
import { LoadingState } from '../LoadingState';
const IGNORE_REFRESH_PATHS = ['/login/sso-callback', '/logout', '/mfa'];
const REFRESH_LOCK_DELAY = 1000;
/**
 *
 * A wrapper to persist the authentication token across page refreshes.
 * Since the token is stored in memory, it will be lost when the page is refreshed.
 *
 * This component will attempt to refresh the token when the page is loaded.
 */
const PersistAuthWrapper = ({ children, }) => {
    const refreshToken = useHandleRefreshToken();
    const refreshTried = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = useAuthenticationToken();
    const { shadoweeToken, setToken, setSessionIdentifier } = useContext(AuthenticationContext);
    const [refreshLock, setRefreshLock] = usePersistentState(REFRESH_LOCK);
    const setLock = useCallback(() => {
        setRefreshLock(Date.now().toString());
    }, [setRefreshLock]);
    const clearLock = useCallback(() => {
        setRefreshLock(null);
    }, [setRefreshLock]);
    const isLockActive = useCallback(() => {
        if (!refreshLock) {
            return false;
        }
        return Date.now() - Number(refreshLock) < REFRESH_LOCK_DELAY;
    }, [refreshLock]);
    const logger = useLogger('PersistAuthWrapper');
    const verifyRefreshToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!refreshTried.current) {
                refreshTried.current = true;
                setIsLoading(true);
                while (isLockActive()) {
                    yield new Promise((resolve) => 
                    /* If the current lock is active, it means that another or window just made a refresh request.
                     * Wait a small delay to handle the case where multiple tabs are opened back to back.
                     * The randomness is meant to stagger requests in the case of multiple tabs being opened in quick succession.
                     * */
                    setTimeout(resolve, REFRESH_LOCK_DELAY + Math.random() * 500));
                }
                setLock();
                yield refreshToken();
                clearLock();
                setIsLoading(false);
            }
        }
        catch (e) {
            setIsLoading(false);
            logger.error(e);
        }
    }), [logger, refreshToken, setIsLoading, isLockActive, clearLock, setLock]);
    useEffect(() => {
        // We were shadowing before the page was refreshed.
        // Pick up where we left off.
        if (shadoweeToken) {
            setToken(shadoweeToken);
            return;
        }
        if (!token && !IGNORE_REFRESH_PATHS.includes(window.location.pathname)) {
            verifyRefreshToken().catch((e) => logger.error(e));
        }
    }, [
        verifyRefreshToken,
        refreshToken,
        token,
        setIsLoading,
        logger,
        shadoweeToken,
        setToken,
        setSessionIdentifier,
    ]);
    if (isLoading) {
        return (React.createElement(Center, { margin: "16px" },
            React.createElement(LoadingState, null)));
    }
    return React.createElement(React.Fragment, null, children);
};
export { PersistAuthWrapper };
