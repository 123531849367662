import { Block, COLORS, Icons, Spacer, Stack, StatefulTooltip, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ContactUsFormTrigger } from '../ContactUsFormTrigger';
const LockedTooltipContent = ({ title, body }) => {
    return (React.createElement(Block, { width: '296px' },
        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
            React.createElement(Icons.Misc.LockSolid, { color: COLORS.tertiary, size: "24px", decorative: true }),
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, title)),
        React.createElement(Spacer, { size: "16px" }),
        React.createElement(Typography, null, body),
        React.createElement(Spacer, { size: "16px" }),
        React.createElement(ContactUsFormTrigger, { location: `Locked Tooltip - ${title}`, contactType: 'Upgrade Account' }, "Request Access")));
};
export const LockedTooltip = ({ children, title, body }) => {
    return (React.createElement(StatefulTooltip, { triggerType: "hover", placement: 'right', content: React.createElement(LockedTooltipContent, { body: body, title: title }) }, children));
};
