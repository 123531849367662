import { __awaiter } from "tslib";
import React, { useCallback, useState } from 'react';
import { Icons, Stack, Collapse, SemanticButton, Block, Card, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { useCreateMetadata } from 'src/pages/Workspace/utils';
import { EvidenceSecondaryInfo } from './EvidenceSecondaryInfo';
import { EvidencePrimaryInfo } from './EvidencePrimaryInfo';
const EvidenceReviewCard = ({ item, select }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const workspaceId = useActiveWorkspaceId();
    const { mutateAsync: createMetadata } = useCreateMetadata(workspaceId);
    const markAsSeen = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield createMetadata({
            resourceType: 'EvidenceSource',
            resource: item.id,
            metadataType: 'systemMetadata',
            metadataValue: 'Seen',
        });
    }), [createMetadata, item.id]);
    const onToggleExpansion = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsExpanded((prev) => !prev);
        if (!isExpanded && !item.seen) {
            yield markAsSeen();
        }
    }), [markAsSeen, isExpanded, item.seen]);
    return (React.createElement(Block, null,
        React.createElement(Card, null,
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(SemanticButton, { onClick: onToggleExpansion },
                    React.createElement(Block, { display: "flex", height: "100%", alignItems: "flex-start" },
                        React.createElement(If, { condition: isExpanded },
                            React.createElement(Then, null,
                                React.createElement(Icons.Misc.ChevronUp, { title: "Collapse" })),
                            React.createElement(Else, null,
                                React.createElement(Icons.Misc.ChevronDown, { title: "Expand" }))))),
                React.createElement(Block, { flex: "1" },
                    React.createElement(EvidencePrimaryInfo, { item: item, select: select }))),
            React.createElement(Collapse, { expanded: isExpanded },
                React.createElement(EvidenceSecondaryInfo, { evidence: item })))));
};
export { EvidenceReviewCard };
