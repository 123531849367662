import { uniqBy } from 'ramda';
/** returns the policies associated with a vendor */
function getPolicies(organization) {
    var _a, _b, _c, _d, _e, _f;
    const privacyPolicy = (_a = organization.legalPolicies) === null || _a === void 0 ? void 0 : _a.find((x) => x.type === 'Privacy Policy');
    const productPrivacyPolicy = (_b = organization.legalPolicies) === null || _b === void 0 ? void 0 : _b.find((x) => x.type === 'Privacy policy - Product');
    const hipaaPolicy = (_c = organization.legalPolicies) === null || _c === void 0 ? void 0 : _c.find((x) => x.type === 'HIPAA');
    const gdprPolicy = (_d = organization.legalPolicies) === null || _d === void 0 ? void 0 : _d.find((x) => x.type === 'GDPR');
    const cvdPolicy = (_e = organization.securityPolicies) === null || _e === void 0 ? void 0 : _e.find((x) => x.type === 'Coordinated Vulnerability Disclosure (CVD)');
    const softwareUpdatesPolicy = (_f = organization.securityPolicies) === null || _f === void 0 ? void 0 : _f.find((x) => x.type === 'Published Software Updates');
    const isSamePolicy = Boolean((privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url) && (productPrivacyPolicy === null || productPrivacyPolicy === void 0 ? void 0 : productPrivacyPolicy.url) === (privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url));
    return {
        gdprPolicy,
        hipaaPolicy,
        privacyPolicy,
        productPrivacyPolicy,
        cvdPolicy,
        softwareUpdatesPolicy,
        isSamePolicy,
    };
}
const isActiveIntegrator = (organization) => {
    var _a;
    return (_a = organization.type) === null || _a === void 0 ? void 0 : _a.some((y) => y === 'Integrator - Active');
};
const isInactiveIntegrator = (organization) => {
    var _a;
    return (_a = organization.type) === null || _a === void 0 ? void 0 : _a.some((y) => y === 'Integrator - Inactive');
};
const isInProgressIntegrator = (organization) => {
    var _a;
    return (_a = organization.type) === null || _a === void 0 ? void 0 : _a.some((y) => y === 'Integrator - In Progress');
};
const isMaker = (organization) => {
    var _a;
    // It looks like 'type' === maker is not filled out all the time, so we need
    // a negative check for the null as well as the positive check.
    return (organization.type === null || ((_a = organization.type) === null || _a === void 0 ? void 0 : _a.some((y) => y === 'Maker')));
};
const getIntegratedTech = (organization) => {
    var _a, _b;
    const integratedTech = (_b = (_a = organization.components) === null || _a === void 0 ? void 0 : _a.flatMap((x) => { var _a; return (_a = x.integrations) !== null && _a !== void 0 ? _a : []; })) !== null && _b !== void 0 ? _b : [];
    const sortedIntegratedTech = [...integratedTech].sort((a, b) => a.model.localeCompare(b.model));
    return uniqBy((x) => x.id, sortedIntegratedTech);
};
export { getPolicies, isActiveIntegrator, getIntegratedTech, isMaker, isInactiveIntegrator, isInProgressIntegrator, };
