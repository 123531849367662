import { Card, COLORS, RankedStack, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { MarkdownMetadataDisplay } from './shared';
const RISK_MAPPING = {
    'Low Risk': {
        label: 'Low Risk',
        backgroundColor: COLORS.success,
    },
    'Moderate Risk': {
        label: 'Moderate Risk',
        backgroundColor: COLORS.tertiary,
    },
    'High Risk': {
        label: 'High Risk',
        backgroundColor: COLORS.alert,
    },
};
export const RiskAssessmentCard = ({ metadata }) => {
    const riskNote = getTeamMetadata(metadata, 'Risk Note');
    const riskScore = getTeamMetadata(metadata, 'Risk Score');
    if (!riskNote && !riskScore) {
        return null;
    }
    const values = Object.entries(RISK_MAPPING).map(([mappingValue, { label, backgroundColor }]) => ({
        label: (React.createElement(Typography, { variant: TypographyVariant.Label, color: mappingValue === riskScore ? COLORS.black : COLORS.gray2 }, label)),
        backgroundColor: mappingValue === riskScore ? backgroundColor : COLORS.gray4,
    }));
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: "0" }, "Risk Assessment"),
            React.createElement(When, { condition: !!riskNote },
                React.createElement(Stack, null,
                    React.createElement(Typography, { color: COLORS.gray1 }, "Risk Notes"),
                    React.createElement(MarkdownMetadataDisplay, { metadata: riskNote }))),
            React.createElement(When, { condition: !!riskScore },
                React.createElement(Stack, null,
                    React.createElement(Typography, { color: COLORS.gray1 }, "Risk Score"),
                    React.createElement(RankedStack, { values: values, isSegmented: true }))))));
};
