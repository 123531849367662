var _a;
import { uriTransformer } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceResource } from '@humanfirst/use-elektra-api';
import { useMemo } from 'react';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { escapeRegex } from 'src/utils/regexp';
const WORKSPACE_UPLOAD_DOMAIN = (_a = process.env.WORKSPACE_UPLOAD_DOMAIN) !== null && _a !== void 0 ? _a : 'https://workspaces.cdn.gohumanfirst.com';
/** Domains that require JWT injection. */
const REQUIRES_JWT = [
    `${escapeRegex(WORKSPACE_UPLOAD_DOMAIN)}/workspaces/[-a-zA-Z0-9]+/uploads/[-a-zA-Z0-9._%=]+`,
];
/** Determine if we need to apply a transformation to a content string. */
const requiresTransformation = (content) => {
    return (!!content && REQUIRES_JWT.some((x) => content.match(new RegExp(x, 'g'))));
};
/** Generates a transformer function for a given token. */
const transformerFactory = (token) => {
    return (uri) => {
        // Note: This appends updates the base regex to match exactly (start
        // with ^ and ends with $). This is required to prevent malicious URLs like
        // https://evil.com?https://workspaces...
        const withToken = REQUIRES_JWT.some((x) => uri.match(new RegExp(`^${x}$`)))
            ? uri + `?jwt=${token}`
            : uri;
        return uriTransformer(withToken);
    };
};
/**
 * Generates a uriTransformer for given markdown content.
 * @param content
 * @returns
 */
export const useUriTransformer = (content) => {
    const workspaceId = useActiveWorkspaceId();
    const applyTransform = requiresTransformation(content);
    const { data, isInitialLoading } = useElektraApi(WorkspaceResource.uploadToken(workspaceId), {
        enabled: !!workspaceId && applyTransform,
        refetchInterval: 115000, //1 min 55sec bc jwt expiration is 2min
    });
    return useMemo(() => {
        if (!applyTransform) {
            return { transformer: uriTransformer, isLoading: false };
        }
        if (isInitialLoading || !(data === null || data === void 0 ? void 0 : data.token)) {
            return { transformer: uriTransformer, isLoading: isInitialLoading };
        }
        return {
            transformer: transformerFactory(data.token),
            isLoading: isInitialLoading,
        };
    }, [data === null || data === void 0 ? void 0 : data.token, isInitialLoading, applyTransform]);
};
