import React from 'react';
import { Block, Heading, StatefulTabs } from '@humanfirst/elektron';
import { TabContent } from 'src/components/TabContent';
import { PageTitle } from 'src/components/PageTitle';
import { ContentContainer } from 'src/components/ContentContainer';
import { useToggle } from 'src/hooks/toggle';
import { Profile } from './components/Profile';
import { Notifications } from './components/Notifications/';
import { MFA } from './components/MFA/MFA';
const Settings = () => {
    const mfaEnrollment = useToggle('mfa-enrollment');
    return (React.createElement(ContentContainer, null,
        React.createElement(PageTitle, { title: "Settings" }),
        React.createElement(Block, { marginBottom: "40px" },
            React.createElement(Heading, null, "Account Settings")),
        React.createElement(StatefulTabs, null,
            React.createElement(StatefulTabs.TabPanel, { title: "Profile" },
                React.createElement(TabContent, null,
                    React.createElement(Profile, null))),
            React.createElement(StatefulTabs.TabPanel, { title: "Notifications" },
                React.createElement(TabContent, null,
                    React.createElement(Notifications, null))),
            mfaEnrollment ? (React.createElement(StatefulTabs.TabPanel, { title: "MFA" },
                React.createElement(TabContent, null,
                    React.createElement(MFA, null)))) : null)));
};
export { Settings };
