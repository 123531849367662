import { Block, Button, Center, COLORS, Icons, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { LinkWithIcon } from 'src/components/LinkWithIcon';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { useIntercom } from 'src/contexts/IntercomContext';
import { generatePath } from 'src/utils/path';
import { AtlasFeedbackButton } from 'src/components/AtlasFeedback';
const ExploreLinks = () => (React.createElement(React.Fragment, null,
    React.createElement(LinkWithIcon, { href: generatePath(PATHS.atlas.technologies.list), title: t('search.input.noResults.links.tech'), iconPosition: "start", icon: React.createElement(Icons.Navigation.Products, { active: true, size: "24px", decorative: true }) }),
    React.createElement(LinkWithIcon, { href: generatePath(PATHS.atlas.measures.list), title: t('search.input.noResults.links.measures'), iconPosition: "start", icon: React.createElement(Icons.Navigation.Activity, { active: true, size: "24px", decorative: true }) }),
    React.createElement(LinkWithIcon, { href: generatePath(PATHS.atlas.medicalConditions.list), title: t('search.input.noResults.links.medicalConditions'), iconPosition: "start", icon: React.createElement(Icons.Navigation.Explore, { active: true, size: "24px", decorative: true }) }),
    React.createElement(LinkWithIcon, { href: generatePath(PATHS.atlas.vendors.list), title: t('search.input.noResults.links.vendors'), iconPosition: "start", icon: React.createElement(Icons.Navigation.Vendors, { active: true, size: "24px", decorative: true }) })));
const OmnisearchNoResultsMessage = () => {
    const { showNewMessage } = useIntercom();
    return (React.createElement(Block, null,
        React.createElement(Block, { padding: "32px 24px" },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: "24px" }, "Nothing turned up"),
            React.createElement(Typography, { variant: TypographyVariant.Body }, "Didn\u2019t find what you\u2019re looking for? You may want to try using different keywords, or checking for typos.")),
        React.createElement(Block, { padding: "24px", backgroundColor: COLORS.gray4 },
            React.createElement(Stack, { gap: "16px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Expand your search by exploring these options:"),
                React.createElement(Stack, { gap: "24px", direction: "horizontal", justifyContent: "center", flexWrap: true },
                    React.createElement(ExploreLinks, null)),
                React.createElement(Center, { gap: "16px" },
                    React.createElement(Link, { href: PATHS.helpCenter.troubleShooting.zeroSearchResults }, "More Suggestions"),
                    React.createElement(Button, { variant: "minimal", onClick: () => showNewMessage() }, t('actions.chatWithUs')),
                    React.createElement(AtlasFeedbackButton, null, "Report Missing Information"))))));
};
export { OmnisearchNoResultsMessage, ExploreLinks };
