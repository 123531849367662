import React, { useEffect } from 'react';
import { matchPath, useLocation, useNavigate, generatePath, } from 'react-router-dom';
import { useAnalyticsEvent } from 'src/hooks/analytics';
const LEGACY_REDIRECTS = [
    {
        from: '/timeline',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
    },
    {
        from: `/workspace/:projectId/workspaces`,
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
    },
    {
        from: `/workspace/:workspaceId/tools/:id`,
        to: () => '/tools/:id',
    },
    {
        from: `/workspace/:workspaceId/explore`,
        to: () => '/explore',
    },
    {
        from: '/reports/:id',
        to: () => `/projects`,
    },
    {
        from: '/workspaces/:projectId/reports',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
    },
    {
        from: '/reports',
        to: () => `/projects`,
    },
    {
        from: '/measure-priorities',
        to: () => `/projects`,
    },
    {
        from: '/measure-selections',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
    },
    {
        from: '/technology-priorities',
        to: () => `/projects`,
    },
    {
        from: '/technology-selections',
        to: () => `/projects`,
    },
    {
        from: '/tool-search',
        to: () => '/atlas/technologies',
    },
    {
        from: '/search/technology',
        to: () => '/atlas/technologies',
    },
    {
        from: '/search/technology/results',
        to: () => '/atlas/technologies',
    },
    {
        from: '/search/technology/complete',
        exact: false,
        to: () => '/atlas/technologies',
    },
    {
        from: '/evidence-search',
        to: () => `/`,
    },
    {
        from: '/search/evidence',
        to: () => '/',
    },
    {
        from: '/search/evidence/results',
        to: () => '/',
    },
    {
        from: '/search/investigations',
        to: () => `/atlas/technologies`,
    },
    {
        from: '/investigations/complete',
        to: () => `/atlas/technologies`,
    },
    {
        from: '/workspaces/:projectId/reports/:id',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
        exact: true,
    },
    {
        from: '/admin/reports/:id/preview',
        to: () => `/admin/reports/:id/preview/0`,
        exact: true,
    },
    {
        from: '/workspaces/:projectId',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}`; },
    },
    {
        from: '/workspaces/:projectId/edit',
        to: (project) => { var _a; return `/projects/${(_a = project.projectId) !== null && _a !== void 0 ? _a : ''}/edit`; },
    },
    {
        from: '/workspaces',
        to: () => '/projects',
    },
    {
        from: '/admin/admin-workspaces',
        to: () => `/admin/admin-projects`,
    },
    {
        from: '/workspaces/:projectId/compare',
        to: (project) => `/projects/${project.projectId}`,
    },
    {
        from: '/catalog',
        to: () => `/catalog/studies`,
    },
];
const LegacyRedirect = ({ children, }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const track = useAnalyticsEvent();
    const pathname = location.pathname;
    useEffect(() => {
        for (const { from, to } of LEGACY_REDIRECTS) {
            const match = matchPath({ path: from }, pathname);
            if (match) {
                const next = generatePath(to(match.params));
                track('legacy-redirect', { pathname, next });
                navigate(next);
                break;
            }
        }
    }, [pathname, navigate, track]);
    return React.createElement(React.Fragment, null, children);
};
export { LegacyRedirect };
