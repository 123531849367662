import React, { createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getPathContext, PathContextType } from 'src/utils/path';
const WorkspaceContext = createContext({
    activeWorkspaceId: null,
});
const UrlWorkspaceProvider = ({ children, }) => {
    const location = useLocation();
    const pathContext = getPathContext(location.pathname);
    const activeWorkspaceId = (pathContext === null || pathContext === void 0 ? void 0 : pathContext.type) === PathContextType.project ? pathContext.projectId : null;
    return (React.createElement(ActiveWorkspaceProvider, { activeWorkspaceId: activeWorkspaceId }, children));
};
const ActiveWorkspaceProvider = ({ activeWorkspaceId, children }) => {
    const value = useMemo(() => ({ activeWorkspaceId }), [activeWorkspaceId]);
    return (React.createElement(WorkspaceContext.Provider, { value: value }, children));
};
export { WorkspaceContext, UrlWorkspaceProvider, ActiveWorkspaceProvider, };
