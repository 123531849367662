import { EvidenceResource, MeasureCategoryResource, MedicalConditionsResource, OrganizationResource, StudyResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import { useMemo } from 'react';
import { getMeasurementCategory } from '../../utils/measurements';
/**
 * Maps from a resource type + resource id to a human-readable name.
 */
export class ResourceNameMap {
    constructor(resources) {
        this.map = new Map();
        for (const { resourceType, resource, name } of resources) {
            this.map.set(ResourceNameMap.getKey(resourceType, resource), name);
        }
    }
    static getKey(resourceType, resourceId) {
        return `${resourceType}:${resourceId}`;
    }
    /**
     * Get the name for a resource type + resource id.
     *
     * Will return an empty string in cases where the resource + id
     * could not be found.
     */
    get(resourceType, resourceId) {
        var _a;
        if (!resourceType || !resourceId) {
            return '';
        }
        return (_a = this.map.get(ResourceNameMap.getKey(resourceType, resourceId))) !== null && _a !== void 0 ? _a : '';
    }
}
/**
 * Map describing how to fetch each resource type and how to get the name.
 */
const resourceFetchers = [
    {
        resource: 'Tool',
        definition: ToolResource.getBulk,
        nameGetter: (x) => x.model,
        idGetter: (x) => x.id,
    },
    {
        resource: 'Vendor',
        definition: OrganizationResource.getBulk,
        nameGetter: (x) => x.name,
        idGetter: (x) => x.id,
    },
    {
        resource: 'EvidenceSource',
        definition: EvidenceResource.getBulk,
        nameGetter: (x) => x.title,
        idGetter: (x) => x.id,
    },
    {
        resource: 'Measure',
        definition: MeasureCategoryResource.getBulk,
        nameGetter: (x) => getMeasurementCategory(x.category),
        idGetter: (x) => x.id,
    },
    {
        resource: 'MedicalCondition',
        definition: MedicalConditionsResource.getBulk,
        nameGetter: (x) => x.name,
        idGetter: (x) => x.id,
    },
    {
        resource: 'Study',
        definition: StudyResource.getBulk,
        nameGetter: (x) => x.name,
        idGetter: (x) => x.id,
    },
];
function useGenericResourceNames(resourceType, resources) {
    // Get a unique set of the IDs for this resource type
    // plus sort so we keep the caching consistent
    const ids = useMemo(() => Array.from(new Set(resources
        .filter((x) => x.resourceType === resourceType)
        .map((x) => x.resource))).sort((a, b) => a.localeCompare(b)), [resources, resourceType]);
    const resourceFetcher = resourceFetchers.find(({ resource }) => resource === resourceType);
    if (!resourceFetcher) {
        throw new Error('invalid resource type');
    }
    const result = useElektraApi(resourceFetcher.definition(ids), {
        enabled: ids.length > 0,
    });
    return useMemo(() => {
        var _a;
        return ({
            isLoading: result.isLoading,
            isError: result.isError,
            error: result.error,
            data: ((_a = result.data) !== null && _a !== void 0 ? _a : []).map((record) => ({
                resourceType,
                resource: resourceFetcher.idGetter(record),
                name: resourceFetcher.nameGetter(record),
            })),
        });
    }, [result, resourceType, resourceFetcher]);
}
const combineResourceResults = (results) => {
    const isLoading = results.some((x) => x.isLoading);
    const isError = results.some((x) => x.isError);
    const data = results.flatMap((x) => x.data);
    return {
        isLoading,
        isError,
        data: new ResourceNameMap(data),
    };
};
export const useResourceNameMap = (resources) => {
    // Get the ids for this specific type of resource.
    const toolResult = useGenericResourceNames('Tool', resources);
    const vendorResult = useGenericResourceNames('Vendor', resources);
    const evidenceResult = useGenericResourceNames('EvidenceSource', resources);
    const measureResult = useGenericResourceNames('Measure', resources);
    const medicalConditionResult = useGenericResourceNames('MedicalCondition', resources);
    const studyResult = useGenericResourceNames('Study', resources);
    return useMemo(() => combineResourceResults([
        toolResult,
        vendorResult,
        evidenceResult,
        measureResult,
        medicalConditionResult,
        studyResult,
    ]), [
        evidenceResult,
        measureResult,
        medicalConditionResult,
        studyResult,
        toolResult,
        vendorResult,
    ]);
};
