import { __awaiter } from "tslib";
import { Heading, Link, Paragraph } from '@humanfirst/elektron';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Else, If, Then, When } from 'react-if';
import { generatePath } from 'src/utils/path';
import { PageTitle } from 'src/components/PageTitle';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { PATHS } from 'src/config/path';
import { useLogout } from 'src/hooks/authentication';
import { useLogger } from 'src/hooks/logger';
const isSessoinExpired = (locationState) => {
    return locationState !== null &&
        typeof locationState === 'object' &&
        typeof (locationState === null || locationState === void 0 ? void 0 : locationState.expired) === 'boolean'
        ? locationState.expired
        : false;
};
const Logout = () => {
    const logout = useLogout();
    const logger = useLogger('Logout');
    useEffect(() => {
        const revokeCredentials = () => __awaiter(void 0, void 0, void 0, function* () {
            yield logout();
        });
        revokeCredentials().catch((error) => logger.error(error));
    }, [logout, logger]);
    const location = useLocation();
    const sessionExpired = isSessoinExpired(location.state);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Log Out" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Heading, null,
                React.createElement(If, { condition: sessionExpired },
                    React.createElement(Then, null, "Session Expired"),
                    React.createElement(Else, null, "You have been logged out"))),
            React.createElement(When, { condition: sessionExpired },
                React.createElement(Paragraph, null, "For your security, your session has ended due to inactivity or session timeout. Please log in again to continue.")),
            React.createElement(Paragraph, null,
                "Use this link to",
                ' ',
                React.createElement(Link, { href: generatePath(PATHS.authentication.login.request) }, "log in")))));
};
export { Logout };
