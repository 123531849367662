import { __awaiter } from "tslib";
import { Heading, Link, Paragraph, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { useElektraApiMutation, AuthResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { SmartForm } from 'src/components/SmartForm';
import { PageTitle } from 'src/components/PageTitle';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const ResetPassword = () => {
    const { mutateAsync, isSuccess } = useElektraApiMutation(AuthResource.resetPassword());
    const [token] = useQueryParam('token', StringParam);
    const onSubmit = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        yield mutateAsync(Object.assign(Object.assign({}, data), { token: token !== null && token !== void 0 ? token : '' }));
    }), [mutateAsync, token]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "Reset Password" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Heading, null, "Reset your password"),
            !isSuccess ? (React.createElement(SmartForm, { onSubmit: onSubmit },
                React.createElement(SmartForm.Input, { label: "New Password", tooltip: React.createElement(Typography, { variant: TypographyVariant.Body }, "Your password must contain at least 8 characters, at least 1 number, and at least 1 special character."), rules: { required: true, minLength: 8 }, id: "password", name: "password", type: "password", placeholder: "New Password" }))) : (React.createElement(React.Fragment, null,
                React.createElement(Paragraph, null, "Your password has been reset."),
                React.createElement(Paragraph, null,
                    "You can now",
                    ' ',
                    React.createElement(Link, { href: generatePath(PATHS.authentication.login.request) }, "log in")))))));
};
export { ResetPassword };
