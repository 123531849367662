import { Stack, Typography, TypographyVariant, Checkbox, Collapse, Spacer, Link, } from '@humanfirst/elektron';
import React from 'react';
import { generatePath } from 'react-router';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { PATHS } from 'src/config/path';
import { IfNotToggle, IfToggle } from 'src/components/IfToggle';
import { ScopeTagList } from './ScopeTagList';
const AppliedResearchCriteriaWithOutToggle = ({ scope, workspaceId }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "horizontal", marginBottom: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "Applied Research Criteria"),
            React.createElement(AnchoredTooltip, { triggerType: "hover" },
                React.createElement(Typography, { marginBottom: "16px" },
                    "The following research criteria have been applied to your search results. To make changes, you may",
                    ' ',
                    React.createElement(Link, { href: generatePath(PATHS.projects.edit, {
                            projectId: workspaceId,
                        }) }, "Edit"),
                    ' ',
                    "this project."))),
        React.createElement(Stack, { direction: "horizontal", gap: "8px", flexWrap: true, marginBottom: "24px" },
            React.createElement(ScopeTagList, { scope: scope }))));
};
const AppliedResearchCriteriaWithToggle = ({ researchCriteriaApplied, scope, onApplyResearchCriteria }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Apply Research Criteria"),
            React.createElement(AnchoredTooltip, { triggerType: "hover" },
                React.createElement(Typography, { marginBottom: "16px" }, "Toggle on to narrow results by your Project\u2019s Research Criteria.")),
            React.createElement(Checkbox, { checkmarkType: "toggle", checked: researchCriteriaApplied, onChange: onApplyResearchCriteria })),
        React.createElement(Collapse, { expanded: researchCriteriaApplied },
            React.createElement(Stack, { direction: "horizontal", gap: "8px", flexWrap: true, paddingTop: "16px" },
                React.createElement(ScopeTagList, { scope: scope }))),
        React.createElement(Spacer, { size: "24px" })));
};
const AppliedResearchCriteria = ({ scope, workspaceId, onApplyResearchCriteria, researchCriteriaApplied, }) => {
    if (!(scope === null || scope === void 0 ? void 0 : scope.length)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(IfToggle, { name: "project-research-insights" },
            React.createElement(AppliedResearchCriteriaWithToggle, { scope: scope, onApplyResearchCriteria: onApplyResearchCriteria, researchCriteriaApplied: researchCriteriaApplied })),
        React.createElement(IfNotToggle, { name: "project-research-insights" },
            React.createElement(AppliedResearchCriteriaWithOutToggle, { workspaceId: workspaceId, scope: scope }))));
};
export { AppliedResearchCriteria };
