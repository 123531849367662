import React from 'react';
import { LoomEmbed } from 'src/components/LoomEmbed';
const LOOM_PATTERN = /^https:\/\/(?:www\.)?loom.com\/share\/(?<id>[a-f0-9]+)/;
function getParameter(params, key) {
    const value = params[key];
    if (typeof value === 'string') {
        return value;
    }
    if (Array.isArray(value)) {
        return value[0];
    }
    return undefined;
}
const MarkdownMediaExtensionLoom = ({ src, parameters, }) => {
    var _a, _b;
    const matches = src.match(LOOM_PATTERN);
    const id = (_a = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _a === void 0 ? void 0 : _a.id;
    if (!id) {
        return null;
    }
    return (React.createElement(LoomEmbed, { id: id, width: (_b = getParameter(parameters, 'width')) !== null && _b !== void 0 ? _b : '100%', height: getParameter(parameters, 'height') }));
};
export { MarkdownMediaExtensionLoom };
