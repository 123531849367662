import React from 'react';
import { MakerProducts } from 'src/pages/MakerProducts';
export const MakerRoutes = {
    path: 'maker/:makerId',
    children: {
        products: {
            path: 'products',
            element: React.createElement(MakerProducts, null),
        },
    },
};
