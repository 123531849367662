import { __awaiter } from "tslib";
import { FilterableFieldType } from '@humanfirst/elektron';
const getCorrectFacet = (facets, fieldId) => {
    var _a, _b;
    return (_b = (_a = facets === null || facets === void 0 ? void 0 : facets.filter((x) => x.field === fieldId)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets;
};
function getSearchCategoryFn(showCount, searchFn, facet) {
    return (query) => __awaiter(this, void 0, void 0, function* () {
        return yield searchFn(facet, query, showCount);
    });
}
function getNumericRangeFilterDisplay(category) {
    const minValue = category[0];
    const maxValue = category[1];
    if (minValue === -Infinity) {
        return `<${maxValue}`;
    }
    if (maxValue === Infinity) {
        return `>${minValue}`;
    }
    return `${minValue}-${maxValue}`;
}
function getCategories(categoryOptions, filterType) {
    var _a, _b;
    if (filterType === FilterableFieldType.NUMERIC_RANGE) {
        return ((_a = categoryOptions === null || categoryOptions === void 0 ? void 0 : categoryOptions.map((x) => ({
            label: getNumericRangeFilterDisplay(x),
            value: x,
        }))) !== null && _a !== void 0 ? _a : []);
    }
    return ((_b = categoryOptions === null || categoryOptions === void 0 ? void 0 : categoryOptions.map((x) => ({
        label: x,
        value: x,
    }))) !== null && _b !== void 0 ? _b : []);
}
function getCategoriesFromFacets(showCount, categoryOptions, limitedOptions) {
    var _a, _b;
    if (limitedOptions) {
        return ((_a = categoryOptions === null || categoryOptions === void 0 ? void 0 : categoryOptions.filter((x) => limitedOptions === null || limitedOptions === void 0 ? void 0 : limitedOptions.has(x.facet)).map((x) => ({
            label: x.facet,
            value: x.facet,
            metadata: showCount ? x.count : undefined,
        }))) !== null && _a !== void 0 ? _a : []);
    }
    return ((_b = categoryOptions === null || categoryOptions === void 0 ? void 0 : categoryOptions.map((x) => ({
        label: x.facet,
        value: x.facet,
        metadata: showCount ? x.count : undefined,
    }))) !== null && _b !== void 0 ? _b : []);
}
export { getCategories, getCategoriesFromFacets, getSearchCategoryFn, getNumericRangeFilterDisplay, getCorrectFacet, };
