import React from 'react';
import { Card, Stack } from '@humanfirst/elektron';
import { StudyResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { useParams } from 'react-router';
import { SAMPLE_STUDY } from 'src/constants/studies';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { deserializeWorkspaceMetadata, splitGeneralMetadata, splitMetadata, } from 'src/utils/workspaceMetadata';
import { TechnologiesTable } from './components/TechnologiesTable';
import { MedicalConditions } from './components/MedicalConditions';
import { StudyPrimaryCard } from './components/StudyPrimaryCard';
import { Endpoints } from './components/Endpoints';
import { TherapeuticArea } from './components/TherapeuticArea';
import { StudyMetadataCard } from './components/StudyMetadataCard';
const StudyInternal = ({ data: study }) => {
    const { summary, studyDetails, populationDetails, customFields } = splitGeneralMetadata(splitMetadata(deserializeWorkspaceMetadata(study.additionalMetadata))
        .generalMetadata);
    return (React.createElement(ContentContainer, { breadcrumbs: [
            BREADCRUMBS.Connect,
            BREADCRUMBS.Studies,
            BREADCRUMBS.StudyDetails(study),
        ] },
        React.createElement(PageTitle, { title: study.name }),
        React.createElement(Stack, { gap: "16px" },
            React.createElement(StudyPrimaryCard, { study: study }),
            React.createElement(StudyMetadataCard, { metadata: summary, title: "Summary", longText: true }),
            React.createElement(Stack, { direction: "horizontal", gap: "16px" },
                React.createElement(Stack, { flex: "1" },
                    React.createElement(StudyMetadataCard, { metadata: studyDetails, title: "Study Details" }),
                    React.createElement(StudyMetadataCard, { metadata: populationDetails, title: "Population Details" })),
                React.createElement(Stack, { flex: "1" },
                    React.createElement(Card, null,
                        React.createElement(TherapeuticArea, { study: study })),
                    React.createElement(Card, null,
                        React.createElement(MedicalConditions, { study: study })),
                    React.createElement(Card, null,
                        React.createElement(TechnologiesTable, { study: study })),
                    React.createElement(StudyMetadataCard, { metadata: customFields, title: "Custom Data" }))),
            React.createElement(Endpoints, { study: study }))));
};
const Study = () => {
    const { id } = useParams();
    const result = useElektraApi(StudyResource.get(id), {
        enabled: id !== 'demo',
    });
    if (id === 'demo') {
        return React.createElement(StudyInternal, { data: SAMPLE_STUDY });
    }
    return React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: StudyInternal }));
};
export { Study };
