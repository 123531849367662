import { __awaiter } from "tslib";
import React, { createContext, useCallback, useContext, useEffect, useMemo, } from 'react';
import { useElektraApi, ToggleResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { LoggerContext } from './LoggerContext';
const ToggleContext = createContext({
    toggles: [],
    getToggleValue: () => false,
    setToggleValue: () => Promise.resolve(),
    isLoading: false,
});
/**
 * Provides a set of toggles that allow for dynamic control of the site.
 */
const ToggleProvider = ({ userId, children }) => {
    const { data: toggles = [], isLoading } = useElektraApi(ToggleResource.list(userId));
    const { mutateAsync, isLoading: isMutateLoading } = useElektraApiMutation(ToggleResource.override());
    const setToggleValue = useCallback((toggleName, value) => __awaiter(void 0, void 0, void 0, function* () {
        yield mutateAsync({ toggleName, value, user: userId });
    }), [mutateAsync, userId]);
    const getToggleValue = useCallback((name) => { var _a, _b; return (_b = (_a = toggles.find((x) => x.name === name)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : false; }, [toggles]);
    const { addFeatureFlagEvaluation } = useContext(LoggerContext);
    useEffect(() => {
        for (const toggle of toggles) {
            addFeatureFlagEvaluation(toggle.name, toggle.value);
        }
    }, [addFeatureFlagEvaluation, toggles]);
    const value = useMemo(() => ({
        toggles,
        getToggleValue,
        setToggleValue,
        isLoading: isLoading || isMutateLoading,
    }), [toggles, getToggleValue, setToggleValue, isLoading, isMutateLoading]);
    return (React.createElement(ToggleContext.Provider, { value: value }, children));
};
export { ToggleContext, ToggleProvider };
