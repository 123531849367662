import React from 'react';
import { Card, Block, Typography, TypographyVariant, Stack, Button, Spinner, } from '@humanfirst/elektron';
import { AuthResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { Else, If, Then, When } from 'react-if';
const PersonalInformationCard = ({ onEditPersonalInformation, user }) => {
    var _a;
    const { data, isLoading } = useElektraApi(AuthResource.discoverAuthMethod((_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : ''), { enabled: !!(user === null || user === void 0 ? void 0 : user.email) });
    const isManaged = (data === null || data === void 0 ? void 0 : data.action) === 'redirect';
    return (React.createElement(Card, { padding: "24px" },
        React.createElement(If, { condition: isLoading },
            React.createElement(Then, null,
                React.createElement(Spinner, null)),
            React.createElement(Else, null,
                React.createElement(Stack, { gap: "24px" },
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, component: "h2" }, "Personal Information"),
                    React.createElement(Stack, { gap: "24px", direction: ['vertical', 'vertical', 'horizontal'] },
                        React.createElement(Block, null,
                            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Name"),
                            React.createElement(Typography, null, user.name)),
                        React.createElement(Block, null,
                            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Email"),
                            React.createElement(Typography, null, user.email)),
                        React.createElement(When, { condition: !isManaged },
                            React.createElement(Block, null,
                                React.createElement(Block, null,
                                    React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Password"),
                                    React.createElement(Typography, null, "********"))))),
                    React.createElement(When, { condition: !isManaged },
                        React.createElement(Block, null,
                            React.createElement(Button, { variant: "secondary", onClick: onEditPersonalInformation }, "Edit Personal Information"))))))));
};
export { PersonalInformationCard };
