import { useMemo, useRef } from 'react';
import { useSearch } from './useSearch';
const useSearchIDs = (options) => {
    const result = useSearch(options);
    const { data } = result;
    const previousIdsRef = useRef();
    const ids = useMemo(() => {
        const newIds = data === null || data === void 0 ? void 0 : data.map(({ objectID }) => objectID);
        const previousIds = previousIdsRef.current;
        if (newIds === undefined || previousIds === undefined) {
            return newIds;
        }
        if (previousIds.length !== newIds.length) {
            return newIds;
        }
        if (!newIds.every((v, i) => previousIds[i] === v)) {
            return newIds;
        }
        return previousIdsRef.current;
    }, [data]);
    previousIdsRef.current = ids;
    return useMemo(() => {
        return Object.assign(Object.assign({}, result), { data: ids });
    }, [result, ids]);
};
export { useSearchIDs };
