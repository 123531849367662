import React, { useEffect, useState, createContext } from 'react';
import uuid from 'react-uuid';
import { useAppDispatch } from 'src/hooks/redux';
const SHADOWEE_TOKEN = 'shadowee-token';
/** Context for handling authentication tokens. */
const AuthenticationContext = createContext({
    setToken: () => undefined,
    token: null,
    sessionIdentifier: null,
    setSessionIdentifier: () => undefined,
    setShadowerToken: () => undefined,
    shadoweeToken: null,
    setShadoweeToken: () => undefined,
    shadowerToken: null,
});
const useSessionStorage = (key) => {
    var _a;
    const [token, setToken] = useState((_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.getItem(key));
    useEffect(() => {
        var _a, _b;
        if (token !== null) {
            (_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.setItem(key, token);
        }
        else {
            (_b = window.sessionStorage) === null || _b === void 0 ? void 0 : _b.removeItem(key);
        }
    }, [key, token]);
    return [token, setToken];
};
/**
 * Provides the authentication token for all API calls.
 *
 * The token is serialized to the local storage, but it is up to the children
 * to notify this context when the token changes.
 */
const AuthenticationProvider = ({ children, }) => {
    const dispatch = useAppDispatch();
    const [token, setToken] = useState(null);
    const [shadowerToken, setShadowerToken] = useState(null);
    const [shadoweeToken, setShadoweeToken] = useSessionStorage(SHADOWEE_TOKEN);
    const [sessionIdentifier, setSessionIdentifier] = useState(uuid());
    useEffect(() => {
        // Reset the redux store whenever the authentication state changes.
        dispatch({ type: 'reset' });
    }, [token, dispatch]);
    // If we have a token, make sure we also have a session identifier.
    useEffect(() => {
        if (sessionIdentifier === null && token !== null) {
            setSessionIdentifier(uuid());
        }
    }, [token, sessionIdentifier]);
    return (React.createElement(AuthenticationContext.Provider, { value: {
            token,
            setToken,
            shadowerToken,
            setShadowerToken,
            shadoweeToken,
            setShadoweeToken,
            sessionIdentifier,
            setSessionIdentifier,
        } }, children));
};
export { AuthenticationProvider, AuthenticationContext, };
