const REQUEST_PRICING_FORM = 'https://airtable.com/shrg4dcPdgoJxdBPq';
const SPONSOR_PRODUCT_FORM = 'https://airtable.com/shrUR4Tvq2zDIVsUY';
const WORKSPACE_CATALOG_FORM = 'https://airtable.com/shryJNMnNc8Vh8MXz';
const NEW_PRODUCT_FORM = 'https://airtable.com/shrrYULnNv0pHhHbA';
const CONTACT_FORM = 'https://airtable.com/shrHzqXec6233zMd3';
const NEW_MEDICAL_CONDITION_FORM = 'https://airtable.com/shrY1mifxwjN4sAQ5';
const DEPLOYMENT_FORM = 'https://airtable.com/shrsPPtxndbm48Txi';
const NEW_MEASURE_FORM = 'https://airtable.com/shrLGJvF5dCfOT082';
const NEW_PRODUCT2_FORM = 'https://airtable.com/shrQjrjZrMywTKLbx';
const REQUEST_INGESTION_FORM = 'https://airtable.com/shr5SFa8C4ROKeMEG';
export { REQUEST_PRICING_FORM, SPONSOR_PRODUCT_FORM, WORKSPACE_CATALOG_FORM, NEW_PRODUCT_FORM, CONTACT_FORM, NEW_MEASURE_FORM, DEPLOYMENT_FORM, NEW_MEDICAL_CONDITION_FORM, NEW_PRODUCT2_FORM, REQUEST_INGESTION_FORM, };
