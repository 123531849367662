import { Card, Heading2, Stack, TypographyVariant } from '@humanfirst/elektron';
import React from 'react';
import { MetadataItem } from './MetadataItem';
const ClinicalTrialProperties = ({ evidence, }) => {
    var _a, _b;
    const partiallyIngested = evidence.ingestionStatus === 'Partially Ingested';
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Clinical Trial Details"),
            React.createElement(Stack, null,
                React.createElement(MetadataItem, { label: 'Enrollment Type', condition: !!evidence.ctEnrollmentType, metadata: evidence.ctEnrollmentType, partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Phase', condition: !!evidence.ctPhase, metadata: evidence.ctPhase, partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Sponsor', condition: !!evidence.ctSponsor, metadata: evidence.ctSponsor, partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Status', condition: !!evidence.ctStatus, metadata: evidence.ctStatus, partiallyIngested: partiallyIngested }),
                React.createElement(MetadataItem, { label: 'Country', condition: !!((_a = evidence.ctCountry) === null || _a === void 0 ? void 0 : _a.length), metadata: (_b = evidence.ctCountry) === null || _b === void 0 ? void 0 : _b.join(', '), partiallyIngested: partiallyIngested })))));
};
export { ClinicalTrialProperties };
