import { WorkspaceStatus } from '@humanfirst/api-models';
import { useElektraApi, WorkspaceResource } from '@humanfirst/use-elektra-api';
import { useContext, useMemo } from 'react';
import { WorkspaceContext } from 'src/contexts/WorkspaceContext';
/** Returns the ID of the active workspace. */
const useActiveWorkspaceId = () => {
    const { activeWorkspaceId } = useContext(WorkspaceContext);
    return activeWorkspaceId;
};
const useActiveWorkspaces = () => {
    const { data: workspaces } = useElektraApi(WorkspaceResource.list());
    return useMemo(() => {
        var _a;
        return (_a = workspaces === null || workspaces === void 0 ? void 0 : workspaces.filter((x) => !(x.status === WorkspaceStatus.Archived || x.locked))) !== null && _a !== void 0 ? _a : [];
    }, [workspaces]);
};
export { useActiveWorkspaceId, useActiveWorkspaces };
