import { Block, COLORS, DateColumn, Heading, Link, StatefulDataTable, TextColumn, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { InviteResource, TeamMemberResource, TeamResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { generatePath } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
const DisplayTeamMembersInternal = ({ data: members }) => {
    return (React.createElement(Typography, null,
        members.length,
        " ",
        members.length === 1 ? 'member' : 'members'));
};
const DisplayNameColumn = ({ team }) => {
    const canViewMembers = useHasPermission('team.member', 'read', {
        id: team.id,
    });
    if (!canViewMembers) {
        return React.createElement(Typography, null, team.name);
    }
    return (React.createElement(Link, { href: generatePath(PATHS.teams.detail, { id: team.id }) }, team.name));
};
const DisplayTeamMembers = ({ team }) => {
    const canViewMembers = useHasPermission('team.member', 'read', {
        id: team.id,
    });
    const result = useElektraApi(TeamMemberResource.list(team.id), {
        enabled: canViewMembers,
    });
    if (!canViewMembers) {
        return (React.createElement(Block, { maxWidth: '160px' },
            React.createElement(Typography, { color: COLORS.gray3, variant: TypographyVariant.Details }, "You do not have permission to view this team's members")));
    }
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: DisplayTeamMembersInternal }, result, { teamId: team.id })));
};
const DisplayTeamInvitesInternal = ({ data: invites }) => {
    const filteredInvites = (invites === null || invites === void 0 ? void 0 : invites.filter((invite) => !invite.claimedBy && new Date(invite.expiration) > new Date())) || [];
    return (React.createElement(Typography, null,
        filteredInvites.length,
        " pending",
        ' ',
        filteredInvites.length === 1 ? 'invite' : 'invites'));
};
const DisplayTeamInvites = ({ team }) => {
    const canViewMembers = useHasPermission('team.member', 'read', {
        id: team.id,
    });
    const result = useElektraApi(InviteResource.list(team.id), {
        enabled: canViewMembers,
    });
    if (!canViewMembers) {
        return (React.createElement(Block, { maxWidth: '160px' },
            React.createElement(Typography, { color: COLORS.gray3, variant: TypographyVariant.Details }, "You do not have permission to view this team's invites")));
    }
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: DisplayTeamInvitesInternal }, result, { teamId: team.id })));
};
const TeamsTable = ({ data: teams }) => {
    return (React.createElement(StatefulDataTable, { data: teams, columns: [
            TextColumn('name', {
                header: 'Team Name',
                render: (team) => React.createElement(DisplayNameColumn, { team: team }),
            }),
            DateColumn('createdAt', { header: 'Created' }),
            TextColumn('users', {
                header: 'Members',
                render: (team) => React.createElement(DisplayTeamMembers, { team: team }),
            }),
            TextColumn('users', {
                header: 'Invites',
                render: (team) => React.createElement(DisplayTeamInvites, { team: team }),
            }),
        ] }));
};
const TeamsList = () => {
    const result = useElektraApi(TeamResource.list());
    return (React.createElement(ContentContainer, null,
        React.createElement(PageTitle, { title: "My Teams" }),
        React.createElement(Heading, null, "My Teams"),
        React.createElement(QueryWrapper, Object.assign({ renderSuccess: TeamsTable }, result))));
};
export { TeamsList };
