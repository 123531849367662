import { Block, Card, Col, COLORS, Heading2, Link, Row, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { LoadingState } from 'src/components/LoadingState';
import { ToolAttribute } from 'src/components/ModelAttribute/ToolAttribute';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { t } from 'src/constants/i18n';
const RegulatoryCard = ({ tool }) => {
    const hasAllEvidence = useHasAllEvidence();
    const hasEvidenceSummaryAccess = useHasPermission('component.evidenceSummary', 'read', tool);
    const { data: evidence = [], isLoading: isLoadingEvidence } = useElektraApi(EvidenceResource.list({
        filter: [['tools', '$cont', tool.id]],
    }), { enabled: hasEvidenceSummaryAccess });
    const fdaSource = evidence === null || evidence === void 0 ? void 0 : evidence.filter((e) => { var _a; return (_a = e.typeOfSource) === null || _a === void 0 ? void 0 : _a.includes('FDA Documentation'); }).sort((a, b) => a.title.localeCompare(b.title));
    const showFDALinks = hasAllEvidence && fdaSource.length;
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, "Regulatory Decisions")),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(If, { condition: isLoadingEvidence && hasEvidenceSummaryAccess },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(Block, null,
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Typography, { color: COLORS.gray1 }, ToolAttribute.FDAClassification.label)),
                            React.createElement(Col, { xs: 8 },
                                React.createElement(ToolAttribute.FDAClassification, { item: tool }))),
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Typography, { color: COLORS.gray1 }, ToolAttribute.FDAStatus.label)),
                            React.createElement(Col, { xs: 8 },
                                React.createElement(ToolAttribute.FDAStatus, { item: tool }))),
                        React.createElement(Spacer, { size: "8px" }),
                        React.createElement(When, { condition: showFDALinks },
                            React.createElement(React.Fragment, null,
                                React.createElement(Row, null,
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Typography, { color: COLORS.gray1 }, t('toolDetails.evidenceCard.fdaDocumentationTitle'))),
                                    React.createElement(Col, { xs: 8 },
                                        React.createElement(Stack, null, fdaSource.map((x) => (React.createElement(Link, { key: x.id, href: x.url }, x.title)))))),
                                React.createElement(Spacer, { size: "8px" }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Typography, { color: COLORS.gray1 }, ToolAttribute.CEMark.label)),
                            React.createElement(Col, { xs: 8 },
                                React.createElement(ToolAttribute.CEMark, { item: tool })))))))));
};
export { RegulatoryCard };
