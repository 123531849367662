import React from 'react';
import { Link, Typography, TypographyVariant } from '@humanfirst/elektron';
import { getMeasurementCategory, parseQuotedStrings, } from 'src/utils/measurements';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
/** Render a single measures with link in a measure list. */
const MeasureCategoryLink = ({ measure }) => {
    const measureNameFull = parseQuotedStrings([measure]);
    const measureName = getMeasurementCategory(measure);
    if (measureNameFull.length < 1) {
        return (React.createElement(Typography, { variant: TypographyVariant.Details }, measureName));
    }
    return (React.createElement(Link, { href: generatePath(PATHS.atlas.measuresName, {
            name: encodeURIComponent(measureNameFull[0]),
        }), typographyVariant: TypographyVariant.Details }, measureName));
};
export { MeasureCategoryLink };
