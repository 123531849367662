import { ElektraApiProvider } from '@humanfirst/use-elektra-api';
import React from 'react';
import { useAxiosInstance } from 'src/hooks/api';
import { useAuthenticationToken, useSessionIdentifier, } from '../hooks/authentication';
/**
 * A wrapper for the use-elektra-api provider that uses the atlas-web contexts
 * to provide user/logout information.
 */
export const WrappedApiProvider = ({ children, }) => {
    const token = useAuthenticationToken();
    const sessionIdentifier = useSessionIdentifier();
    const axiosInstance = useAxiosInstance();
    return (React.createElement(ElektraApiProvider, { axiosInstance: axiosInstance, token: token !== null && token !== void 0 ? token : undefined, sessionIdentifier: sessionIdentifier !== null && sessionIdentifier !== void 0 ? sessionIdentifier : undefined }, children));
};
