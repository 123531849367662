import { generatePath as routerGeneratePath, matchPath } from 'react-router-dom';
var PathContextType;
(function (PathContextType) {
    PathContextType["admin"] = "admin";
    PathContextType["project"] = "project";
    PathContextType["maker"] = "maker";
    PathContextType["medicalConditions"] = "medical-conditions";
    PathContextType["measures"] = "measures";
    PathContextType["vendors"] = "vendors";
    PathContextType["technologies"] = "technologies";
})(PathContextType || (PathContextType = {}));
const CONTEXT_PREFIXES = {
    [PathContextType.admin]: '/admin',
    [PathContextType.project]: '/projects/:projectId',
    [PathContextType.maker]: '/maker/:makerId',
    [PathContextType.technologies]: '/atlas/technologies/:id',
    [PathContextType.medicalConditions]: '/atlas/medical-conditions/:id',
    [PathContextType.measures]: '/atlas/measures/:id',
    [PathContextType.vendors]: '/atlas/vendors/:id',
};
/**
 * Determines the context (project/admin/maker) from a URL.
 * If the context can not be determined this returns null.
 */
const getPathContext = (path) => {
    if (path === '/projects/create') {
        // Special handling for the create path which could match the project
        // path, but should not be treated as having an active project.
        return null;
    }
    for (const context of [
        PathContextType.admin,
        PathContextType.maker,
        PathContextType.project,
        PathContextType.measures,
        PathContextType.medicalConditions,
        PathContextType.technologies,
        PathContextType.vendors,
    ]) {
        const match = matchPath({ path: CONTEXT_PREFIXES[context], end: false }, path);
        if (match) {
            return Object.assign({ type: context }, match.params);
        }
    }
    return null;
};
/**
 * Generates a path given params.
 */
const generatePath = (path, params) => {
    const encodedParams = {};
    if (params) {
        for (const [key, value] of Object.entries(params)) {
            if (typeof value === 'string') {
                encodedParams[key] = encodeURIComponent(value);
            }
            else if (value == null) {
                encodedParams[key] = 'not-found';
            }
            else {
                encodedParams[key] = value;
            }
        }
    }
    return routerGeneratePath(path, encodedParams);
};
export { generatePath, getPathContext, PathContextType };
