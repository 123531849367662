import { __awaiter, __rest } from "tslib";
import { uniq } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLogger } from 'src/hooks/logger';
import { SmartSelect } from './fields';
/** Merge two sets of options (and sorts by label) */
const mergeOptions = (oldOptions, newOptions) => {
    return uniq([...newOptions, ...oldOptions]).sort((a, b) => a.label.localeCompare(b.label));
};
/**
 * Use options loaded via an async query function.
 * Options will be gathered across queries into an aggregate options object.
 * To maintain consistency, options will be sorted alphabetically by label.
 */
const useAsyncOptions = (fetcher, initialValues) => {
    const logger = useLogger('useAsyncOptions');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const updateOptions = useCallback((query_1, ...args_1) => __awaiter(void 0, [query_1, ...args_1], void 0, function* (query, idLookup = false) {
        setIsLoading(true);
        try {
            const newOptions = yield fetcher(query, idLookup);
            setOptions((oldOptions) => mergeOptions(oldOptions, newOptions));
        }
        catch (e) {
            // Errors should be logged but should be non-fatal.
            // eslint-disable-next-line no-console
            console.error(e);
        }
        finally {
            setIsLoading(false);
        }
    }), [fetcher]);
    // Sometimes our initial values may not be fully initialized. We assume that
    // we can search for these using the same fetcher, so we issue a series of requests
    // to fill in these values.
    useEffect(() => {
        if (initialValues) {
            for (const { id } of initialValues) {
                updateOptions(id, true).catch((e) => logger.error(e));
            }
        }
        // Fire an empty query just to fill in some options here.
        updateOptions('').catch((e) => logger.error(e));
    }, [initialValues, updateOptions, logger]);
    return {
        options,
        isLoading,
        updateOptions,
    };
};
/**
 * A Type-Ahead select where options are loaded asynchronously.
 *
 * If this has a default value it will do a query to load the default value (in case
 * we only have an ID but not a label).
 */
const SmartAsyncTypeahead = (_a) => {
    var { getter } = _a, props = __rest(_a, ["getter"]);
    const { watch } = useFormContext();
    // WARNING: We only want to run this once, so we're doing some
    // tricks with memo. I think this should only run once...
    const initialValues = useMemo(() => {
        return watch(props.name);
    }, [watch, props.name]);
    const { options, updateOptions, isLoading } = useAsyncOptions(getter, initialValues);
    return (React.createElement(SmartSelect, Object.assign({}, props, { onInputChange: (e) => updateOptions(e.currentTarget.value), isLoading: isLoading, options: options })));
};
export { SmartAsyncTypeahead, useAsyncOptions };
