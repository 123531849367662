import { Block, Heading, Stack } from '@humanfirst/elektron';
import { OrganizationResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { ContentContainer } from 'src/components/ContentContainer';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { OrganizationTable } from 'src/components/tables/OrganizationTable';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PATHS } from 'src/config/path';
const OrganizationListInternal = ({ data, }) => {
    return React.createElement(OrganizationTable, { data: data });
};
const OrganizationList = () => {
    const result = useElektraApi(OrganizationResource.list());
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Atlas, BREADCRUMBS.Vendors] },
        React.createElement(PageTitle, { title: "Vendors" }),
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Heading, null, "Explore Vendors"),
            React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.vendors.exploreOverview })),
        React.createElement(Stack, { direction: "horizontal", gap: "72px" },
            React.createElement(Block, { flex: "1" },
                React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: OrganizationListInternal }))))));
};
export { OrganizationList };
