import { Typography, TypographyVariant, Stack, Select, toOptions, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
import { useActiveWorkspaces } from 'src/hooks/workspace';
import { getMeasurementHierarchy } from 'src/utils/measurements';
import { flattenTree } from 'src/utils/tree';
const ProjectForReportDelivery = ({ toggleSelection }) => {
    const [project, setProject] = useState();
    const projects = useActiveWorkspaces();
    projects.sort((a, b) => {
        if (a.updatedAt && b.updatedAt) {
            return b.updatedAt.localeCompare(a.updatedAt);
        }
        else if (a.updatedAt) {
            return -1;
        }
        else if (b.updatedAt) {
            return 1;
        }
        else {
            return 0;
        }
    });
    const onChange = useCallback((params) => {
        setProject(params.value);
        toggleSelection(`Project:${params.option.id}`);
    }, [toggleSelection]);
    return (React.createElement(Stack, { gap: '4px', padding: '8px 0px' },
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Project for delivery"),
        React.createElement(Select, { "aria-label": "project-selection", value: project, options: projects.map((project) => ({
                id: project.id,
                label: project.name,
            })), clearable: false, creatable: true, onChange: onChange })));
};
const Measures = ({ toggleSelection, technologies }) => {
    const [measures, setMeasures] = useState([]);
    const measureOptions = getMeasurementHierarchy(technologies.flatMap((x) => x.measuresCategory))
        .flatMap((c) => flattenTree(c))
        .sort();
    const onChange = useCallback((params) => {
        setMeasures(params.value);
        toggleSelection(`measure:${params.option.id}`);
    }, [toggleSelection]);
    return (React.createElement(Stack, { gap: '4px' },
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Measures to include"),
        React.createElement(Select, { "aria-label": "measures-selection", options: toOptions(measureOptions), value: measures, multi: true, onChange: onChange, clearable: false })));
};
export { Measures, ProjectForReportDelivery };
