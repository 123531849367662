import React, { useContext } from 'react';
import { Navigate, generatePath, useLocation } from 'react-router-dom';
import { PATHS } from 'src/config/path';
import { ToggleContext } from 'src/contexts/ToggleContext';
import { UserContext } from 'src/contexts/UserContext';
import { useAuthenticationToken } from 'src/hooks/authentication';
import { LoadingState } from '../LoadingState';
/** Requires authentication before rendering children. */
const RequiresAuthentication = ({ children, }) => {
    const { isLoading: isToggleLoading } = useContext(ToggleContext);
    const { user, isLoading: isUserLoading } = useContext(UserContext);
    const location = useLocation();
    const token = useAuthenticationToken();
    if (isUserLoading || isToggleLoading) {
        return React.createElement(LoadingState, null);
    }
    if (!token || !user) {
        const next = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
        return (React.createElement(Navigate, { to: `${generatePath(PATHS.authentication.login.request)}?next=${next}` }));
    }
    return React.createElement(React.Fragment, null, children);
};
export { RequiresAuthentication };
