import { Block, Stack, StatefulTooltip, Typography, TypographyVariant, COLORS, Icons, Spinner, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React, { useContext, useMemo } from 'react';
import { When } from 'react-if';
import { SharedAttributeContext, } from 'src/contexts/SharedAttributeContext';
import { useEvidenceFacet } from 'src/hooks/facets';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { isCoa } from 'src/pages/Tool/utils/selectors';
import { getCTPhasesWithCountValues } from 'src/utils/clinicalTrials';
import { MeasurementTree } from '../MeasurementTree/MeasurementTree';
import { MeasureTag, MeasureTagPip } from '../MeasureTag';
import { DEFAULT_NOT_SPECIFIED_TEXT } from './ModelAttribute';
const EMPTY_MEASURES = [];
const NotSpecifiedDisplay = () => {
    return (React.createElement(Typography, { color: COLORS.gray2 }, DEFAULT_NOT_SPECIFIED_TEXT));
};
const MeasuresDisplay = ({ item }) => {
    var _a, _b;
    const hasTechEvidenceMeasures = useHasPermission('component.evidenceSummary', 'read', item);
    const { getSharedAttribute } = useContext(SharedAttributeContext);
    const sharedMeasures = useMemo(() => {
        var _a;
        return (_a = getSharedAttribute('measures', hasTechEvidenceMeasures)) !== null && _a !== void 0 ? _a : [];
    }, [getSharedAttribute, hasTechEvidenceMeasures]);
    return (React.createElement(Block, { flex: 1 },
        React.createElement(MeasurementTree, { manufacturerMeasuresCategory: isCoa(item) ? EMPTY_MEASURES : item.manufacturerMeasuresCategory, evidenceMeasuresCategory: hasTechEvidenceMeasures
                ? (_a = item.evidenceMeasuresCategory) !== null && _a !== void 0 ? _a : EMPTY_MEASURES
                : EMPTY_MEASURES, fdaMeasuresCategory: hasTechEvidenceMeasures
                ? (_b = item.fdaMeasuresCategory) !== null && _b !== void 0 ? _b : EMPTY_MEASURES
                : EMPTY_MEASURES, authorMeasuresCategory: isCoa(item) ? item.manufacturerMeasuresCategory : EMPTY_MEASURES, sharedMeasureCategory: sharedMeasures, showLegend: false, hasToolMeasures: hasTechEvidenceMeasures })));
};
const SharedDisplayHelper = ({ tool, attribute }) => {
    const { getSharedAttribute, highlighted } = useContext(SharedAttributeContext);
    const sharedAttributes = useMemo(() => {
        var _a;
        return (_a = getSharedAttribute(attribute)) !== null && _a !== void 0 ? _a : [];
    }, [attribute, getSharedAttribute]);
    const toolAttributes = tool[attribute];
    //remove anything that is not a string[]
    if (!Array.isArray(toolAttributes) ||
        !(toolAttributes === null || toolAttributes === void 0 ? void 0 : toolAttributes.length) ||
        typeof toolAttributes[0] !== 'string' ||
        toolAttributes[0].toLowerCase() === DEFAULT_NOT_SPECIFIED_TEXT.toLowerCase()) {
        return (React.createElement(Typography, { color: COLORS.gray2 }, DEFAULT_NOT_SPECIFIED_TEXT));
    }
    if (toolAttributes[0] === 'Not specified') {
        return (React.createElement(Typography, { color: COLORS.gray2 }, DEFAULT_NOT_SPECIFIED_TEXT));
    }
    const isSelected = highlighted === attribute;
    const sortedToolAttributes = [...toolAttributes].map((x) => String(x)).sort();
    return (React.createElement(Stack, null, sortedToolAttributes.map((x) => (React.createElement(Stack, { key: x, direction: 'horizontal', alignItems: 'center', overrides: isSelected && !sharedAttributes.includes(x)
            ? { Block: { style: { opacity: 0.3 } } }
            : undefined },
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, x),
        React.createElement(When, { condition: sharedAttributes.includes(x) },
            React.createElement(StatefulTooltip, { content: React.createElement(MeasureTag, { tag: 'shared' }), triggerType: 'hover', placement: "bottom" },
                React.createElement(MeasureTagPip, { tag: 'shared' }))))))));
};
const waterproofToLevel = (waterproof) => {
    if (waterproof === 'No protection from water') {
        return 0;
    }
    else if (waterproof === 'Protected from water drops') {
        return 1;
    }
    else if (waterproof === 'Protected from sprays or splashes') {
        return 2;
    }
    else if (waterproof === 'Safe for bathing/showering') {
        return 3;
    }
    else if (waterproof === 'Safe for swimming') {
        return 4;
    }
    else {
        return -1;
    }
};
const WaterproofDisplay = ({ item }) => {
    if (!item.waterproof ||
        item.waterproof.length === 0 ||
        item.waterproof[0].toLowerCase() ===
            DEFAULT_NOT_SPECIFIED_TEXT.toLowerCase()) {
        return React.createElement(NotSpecifiedDisplay, null);
    }
    const waterproofLevel = waterproofToLevel(item.waterproof[0]);
    return (React.createElement(Stack, null,
        waterproofLevel > -1 ? (React.createElement(Block, { display: "flex" }, new Array(4).fill(null).map((_, i) => {
            return (React.createElement(Icons.Product.Waterproof, { size: "24px", 
                // The key is the only value here (we actually just want to build a
                // list of cutoffs).
                // eslint-disable-next-line react/no-array-index-key
                key: i, title: i < waterproofLevel ? 'Filled drop' : 'Empty drop', color: i < waterproofLevel ? COLORS.primary : COLORS.gray3 }));
        }))) : null,
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, item.waterproof)));
};
export const CTPhasesDisplay = ({ item }) => {
    const { data, isLoading } = useEvidenceFacet('ctPhase', [
        ['tools', '$cont', item.id],
    ]);
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    if (!data || data.length === 0) {
        return React.createElement(NotSpecifiedDisplay, null);
    }
    const values = getCTPhasesWithCountValues(data);
    const nonZeroValues = values.filter((x) => x.count > 0);
    if (nonZeroValues.length < 1) {
        return React.createElement(NotSpecifiedDisplay, null);
    }
    return (React.createElement(Stack, null, nonZeroValues.map((x) => (React.createElement(Typography, { key: x.label, variant: TypographyVariant.BodyBold }, x.label)))));
};
const ORDERED_AGE_GROUPS = [
    'Infants (<1 year)',
    'Children (1-10 yrs)',
    'Adolescent (11-17 yrs)',
    'Adult (18-64 yrs)',
    'Older adult (65+ yrs)',
];
const PopulationDisplay = ({ item }) => {
    const { data, isLoading } = useEvidenceFacet('ageRecruitmentCriteria', [
        ['tools', '$cont', item.id],
    ]);
    if (isCoa(item)) {
        return (React.createElement(React.Fragment, null, item.ageRange ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, item.ageRange)) : (React.createElement(NotSpecifiedDisplay, null))));
    }
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    if (!data || data.length === 0) {
        return React.createElement(NotSpecifiedDisplay, null);
    }
    return (React.createElement(Stack, null, ORDERED_AGE_GROUPS.map((ageGroup) => {
        if (data.includes(ageGroup)) {
            return (React.createElement(Typography, { key: ageGroup, variant: TypographyVariant.BodyBold }, ageGroup));
        }
        else {
            return null;
        }
    })));
};
const TotalValidationDisplay = ({ item }) => {
    var _a, _b;
    const result = useElektraApi(EvidenceResource.getStatistics({
        filter: [
            ['tools', '$cont', item.id],
            ['dhtUseContext', '$eq', 'Validation'],
        ],
    }));
    if (result.isLoading) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null, typeof ((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.count) === 'number' ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.count)) : (React.createElement(NotSpecifiedDisplay, null))));
};
const V3Display = ({ item, v3Filter }) => {
    var _a, _b;
    const result = useElektraApi(EvidenceResource.getStatistics({
        filter: [
            ['tools', '$cont', item.id],
            ['v3', '$in', v3Filter.join(',')],
        ],
    }));
    if (isCoa(item) && v3Filter.length !== 3) {
        return React.createElement(Typography, { color: COLORS.gray2 }, "N/A");
    }
    if (result.isLoading) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null, typeof ((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.count) === 'number' ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.count)) : (React.createElement(NotSpecifiedDisplay, null))));
};
const V3AnalyicalDisplay = ({ item }) => {
    return React.createElement(V3Display, { item: item, v3Filter: ['Analytical Validation'] });
};
const V3ClinicalDisplay = ({ item }) => {
    return React.createElement(V3Display, { item: item, v3Filter: ['Clinical Validation'] });
};
const V3VerificationDisplay = ({ item }) => {
    return React.createElement(V3Display, { item: item, v3Filter: ['Verification'] });
};
const RequiredHardwareDisplay = ({ item }) => {
    return React.createElement(SharedDisplayHelper, { attribute: "requiredHardwareSBC", tool: item });
};
const SensorTypeDisplay = ({ item }) => {
    return React.createElement(SharedDisplayHelper, { attribute: "modalitiesSensorType", tool: item });
};
const WearLocationDisplay = ({ item }) => {
    return React.createElement(SharedDisplayHelper, { attribute: "wearLocation", tool: item });
};
const ConnectivityDisplay = ({ item }) => {
    return React.createElement(SharedDisplayHelper, { attribute: "dataExchangeModalities", tool: item });
};
const OperatingSystemDisplay = ({ item }) => {
    return (React.createElement(SharedDisplayHelper, { attribute: "compatibleOperatingSystem", tool: item }));
};
const FormFactorDisplay = ({ item }) => {
    return React.createElement(SharedDisplayHelper, { attribute: "formFactor", tool: item });
};
const CEMarkDisplay = ({ item }) => {
    if (isCoa(item)) {
        return React.createElement(Typography, { color: COLORS.gray2 }, "N/A");
    }
    return (React.createElement(React.Fragment, null, item.ceMark &&
        item.ceMark.toLowerCase() !== DEFAULT_NOT_SPECIFIED_TEXT.toLowerCase() ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, item.ceMark)) : (React.createElement(NotSpecifiedDisplay, null))));
};
const MDDTClassificationDisplay = ({ item }) => {
    if (!isCoa(item)) {
        return React.createElement(Typography, { color: COLORS.gray2 }, "N/A");
    }
    return (React.createElement(React.Fragment, null, item.mddtClassification ? (React.createElement(Typography, { variant: TypographyVariant.BodyBold }, item.mddtClassification)) : (React.createElement(NotSpecifiedDisplay, null))));
};
export { OperatingSystemDisplay, ConnectivityDisplay, WearLocationDisplay, SensorTypeDisplay, SharedDisplayHelper, RequiredHardwareDisplay, MeasuresDisplay, WaterproofDisplay, TotalValidationDisplay, V3AnalyicalDisplay, V3ClinicalDisplay, V3VerificationDisplay, PopulationDisplay, FormFactorDisplay, CEMarkDisplay, MDDTClassificationDisplay, };
