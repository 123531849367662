import { __awaiter } from "tslib";
import { Block, COLORS, Icons, SemanticButton, Spinner, StatefulPopover, Typography, } from '@humanfirst/elektron';
import React, { useCallback } from 'react';
import { Else, If, Then } from 'react-if';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { useCreateMetadata, useRemoveMetadata } from 'src/pages/Workspace/utils';
const ReviewedAction = ({ reviewed, resourceId, seen }) => {
    const workspaceId = useActiveWorkspaceId();
    const { mutateAsync: createMetadata, isLoading: isCreateLoading } = useCreateMetadata(workspaceId);
    const { mutateAsync: removeMetadata, isLoading: isRemoveLoading } = useRemoveMetadata(workspaceId);
    const onRemove = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield removeMetadata({ id: (reviewed === null || reviewed === void 0 ? void 0 : reviewed.id) ? reviewed.id : '' });
    }), [reviewed === null || reviewed === void 0 ? void 0 : reviewed.id, removeMetadata]);
    const onCreate = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield createMetadata({
            resourceType: 'EvidenceSource',
            resource: resourceId,
            metadataType: 'systemMetadata',
            metadataValue: 'Reviewed',
        });
        if (!seen) {
            yield createMetadata({
                resourceType: 'EvidenceSource',
                resource: resourceId,
                metadataType: 'systemMetadata',
                metadataValue: 'Seen',
            });
        }
    }), [createMetadata, resourceId, seen]);
    const label = `Mark as ${reviewed ? 'not reviewed' : 'reviewed'}`;
    if (isCreateLoading || isRemoveLoading) {
        return React.createElement(Spinner, { "$size": "24px" });
    }
    return (React.createElement(StatefulPopover, { triggerType: "hover", placement: "bottom", content: React.createElement(Block, { backgroundColor: COLORS.gray1, borderRadius: "16px", padding: "0px 8px" },
            React.createElement(Typography, { color: COLORS.white }, label)) },
        React.createElement(SemanticButton, { "aria-label": label, onClick: reviewed ? onRemove : onCreate },
            React.createElement(If, { condition: !!reviewed },
                React.createElement(Then, null,
                    React.createElement(Icons.Product.Yes, { color1: COLORS.blue3, title: label })),
                React.createElement(Else, null,
                    React.createElement(Icons.Product.Yes, { color1: COLORS.gray3, title: label }))))));
};
export { ReviewedAction };
