import { useMemo } from 'react';
import { SourceMeasureResource, useElektraApi, } from '@humanfirst/use-elektra-api';
/**
 * Returns a unique list of Tools for a given evidence.
 */
const useDedupeTechnologyForEvidence = (evidence) => {
    return useMemo(() => {
        var _a, _b;
        // Grab the tools.
        const tools = (_b = (_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.flatMap((x) => x.tools).filter((t) => !!t)) !== null && _b !== void 0 ? _b : [];
        // Dedup em.
        const deduped = tools === null || tools === void 0 ? void 0 : tools.filter((t, i) => (tools === null || tools === void 0 ? void 0 : tools.findIndex((x) => x.id === t.id)) === i);
        return deduped !== null && deduped !== void 0 ? deduped : [];
    }, [evidence]);
};
/**
 * Returns a reloaded source measures on evidence joined with measures and tools.
 */
const useSourceMeasuresForEvidence = (evidence) => {
    var _a, _b;
    return useElektraApi(SourceMeasureResource.getBulk((_b = (_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.map((x) => x.id)) !== null && _b !== void 0 ? _b : [], { join: ['measures', 'measures.category', 'tools'] }));
};
export { useDedupeTechnologyForEvidence, useSourceMeasuresForEvidence };
