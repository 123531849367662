const getMedicalConditionEvidence = (medicalConditions, sources) => {
    return medicalConditions.map((mc) => {
        const matchedSources = sources.filter((source) => { var _a; return (_a = source.medicalConditions) === null || _a === void 0 ? void 0 : _a.some(({ id }) => id === mc.id); });
        return Object.assign(Object.assign({}, mc), { evidenceCount: matchedSources.length, evidence: matchedSources });
    });
};
const getComponentEvidence = (components, sources) => {
    return components.flatMap((component) => {
        const matchedSources = sources.filter((evidence) => {
            var _a;
            return (_a = evidence.sourceMeasures) === null || _a === void 0 ? void 0 : _a.some((sm) => { var _a; return (_a = sm.tools) === null || _a === void 0 ? void 0 : _a.some(({ id }) => id === component.id); });
        });
        return Object.assign(Object.assign({}, component), { evidenceCount: matchedSources.length });
    });
};
export { getMedicalConditionEvidence, getComponentEvidence, };
