import { Card, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { MarkdownMetadataDisplay } from './shared';
export const DhtInventoryCard = ({ metadata }) => {
    const dhtContact = getTeamMetadata(metadata, 'DHT Inventory Contact');
    const dhtEmail = getTeamMetadata(metadata, 'DHT Inventory Email');
    if (!dhtContact && !dhtEmail) {
        return null;
    }
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: '24px' }, "DHT Inventory"),
            React.createElement(When, { condition: !!dhtContact },
                React.createElement(MarkdownMetadataDisplay, { metadata: dhtContact })),
            React.createElement(When, { condition: !!dhtEmail },
                React.createElement(Typography, null, dhtEmail)))));
};
