import React from 'react';
import { Block, COLORS, SemanticButton, StatefulTooltip, } from '@humanfirst/elektron';
import { TechnologyTag } from 'src/components/TechnologyTag';
const TechnologyTagButton = ({ tag, highlight, toggle }) => {
    return (React.createElement(StatefulTooltip, { content: React.createElement(TechnologyTag, { tag: tag, includeDescription: true }), triggerType: 'hover', placement: "bottom" },
        React.createElement(SemanticButton, { onClick: toggle },
            React.createElement(Block, { color: highlight === tag ? COLORS.primary : COLORS.black },
                React.createElement(TechnologyTag, { tag: tag })))));
};
export { TechnologyTagButton };
