import { WorkspaceStatus, } from '@humanfirst/api-models';
import React from 'react';
import { Block } from '@humanfirst/elektron';
import { SEARCH_RESOURCES, getSection } from 'src/utils/resourceType';
import { ShowMore } from 'src/components/TopNavigationBar/components/ShowMore';
import { scopeToFilterValues } from 'src/utils/workspaceScope';
import { BoldWrapper, SupportingTextWrapper, TimestampWrapper, } from './components/DisplayHelpers';
const getResourceName = (map, resourceType, resource) => {
    if (!resourceType || !resource || SEARCH_RESOURCES.has(resourceType)) {
        return 'an item';
    }
    return map.get(resourceType, resource);
};
/** Virtual events for the project. */
const workspaceVirtualEvents = [
    {
        test: ({ action }) => action === 'create',
        output: ({ actor, time }) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " created the project"))),
        eventType: 'created project',
    },
    {
        eventType: 'updated project description',
        test: ({ action, current, previous }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.description) !== (previous === null || previous === void 0 ? void 0 : previous.description),
        output: ({ actor, time, current }) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null,
                ' ',
                "updated the project description to:",
                ' '),
            React.createElement(Block, null,
                React.createElement(ShowMore, { limitedHeight: '40px' },
                    React.createElement(SupportingTextWrapper, null, current === null || current === void 0 ? void 0 : current.description))))),
    },
    {
        eventType: 'updated project name',
        test: ({ action, current, previous }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.name) !== (previous === null || previous === void 0 ? void 0 : previous.name),
        output: ({ actor, time, current }) => (React.createElement("span", null,
            React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null,
                    ' ',
                    "updated the project name to:",
                    ' '),
                React.createElement(BoldWrapper, null, current === null || current === void 0 ? void 0 : current.name)))),
    },
    {
        eventType: 'archived project',
        test: ({ action, current }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.status) === WorkspaceStatus.Archived,
        output: ({ actor, time }) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " archived the project"))),
    },
    {
        eventType: 'converted to collaborative',
        test: ({ action, current, previous }) => action === 'update' && (previous === null || previous === void 0 ? void 0 : previous.team) === null && !!(current === null || current === void 0 ? void 0 : current.team),
        output: ({ actor, time }) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null,
                ' ',
                "updated the project to be collaborative."))),
    },
    {
        eventType: 'transferred project ownership',
        test: ({ action, current, previous }) => { var _a, _b; return action === 'update' && ((_a = previous === null || previous === void 0 ? void 0 : previous.creator) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = current === null || current === void 0 ? void 0 : current.creator) === null || _b === void 0 ? void 0 : _b.id); },
        output: ({ actor, time }) => {
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null,
                    ' ',
                    "transferred ownership of the project.")));
        },
    },
    {
        eventType: 'updated project scope',
        test: ({ action, current, previous }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.scope) !== (previous === null || previous === void 0 ? void 0 : previous.scope),
        output: ({ actor, time, current }) => {
            var _a;
            const scopes = scopeToFilterValues((_a = current === null || current === void 0 ? void 0 : current.scope) !== null && _a !== void 0 ? _a : '');
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null,
                    (current === null || current === void 0 ? void 0 : current.scope)
                        ? ` updated the project scope to: `
                        : ' removed the project scope',
                    React.createElement("ul", null, Object.entries(scopes).map(([key, value]) => value.length > 0 ? (React.createElement("li", { key: key },
                        key,
                        ": ",
                        value.join(', '))) : null)))));
        },
    },
];
/** Virtual events for comments. */
const commentVirtualEvents = [
    {
        eventType: 'added comment',
        test: ({ action }) => action === 'create',
        output: ({ actor, current, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " added a "),
            React.createElement(BoldWrapper, null, "comment "),
            React.createElement(SupportingTextWrapper, null, "to "),
            React.createElement(BoldWrapper, null,
                getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource),
                ":",
                ' '),
            React.createElement(SupportingTextWrapper, null, current === null || current === void 0 ? void 0 : current.comment))),
    },
    {
        eventType: 'removed comment',
        test: ({ action, current }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.archivedAt) !== null,
        output: ({ actor, current, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " removed a "),
            React.createElement(BoldWrapper, null, "comment "),
            React.createElement(SupportingTextWrapper, null, "from "),
            React.createElement(BoldWrapper, null,
                getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource),
                ":",
                ' '),
            React.createElement(SupportingTextWrapper, null, current === null || current === void 0 ? void 0 : current.comment))),
    },
    {
        eventType: 'updated comment',
        test: ({ action, current }) => action === 'update' && (current === null || current === void 0 ? void 0 : current.archivedAt) === null,
        output: ({ actor, current, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " updated a "),
            React.createElement(BoldWrapper, null, "comment "),
            React.createElement(SupportingTextWrapper, null, "on "),
            React.createElement(BoldWrapper, null, getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource)),
            React.createElement(SupportingTextWrapper, null,
                " to: ", current === null || current === void 0 ? void 0 :
                current.comment))),
    },
];
/** Virtual events for bookmarks. */
const bookmarkVirtualEvents = [
    {
        eventType: 'added to project',
        test: ({ action }) => action === 'create',
        output: ({ actor, current, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " added "),
            React.createElement(BoldWrapper, null, getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource)),
            React.createElement(SupportingTextWrapper, null, " to "),
            React.createElement(BoldWrapper, null, getSection(current === null || current === void 0 ? void 0 : current.resourceType)))),
    },
    {
        eventType: 'removed from project',
        test: ({ action }) => action === 'delete',
        output: ({ actor, previous, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " removed "),
            React.createElement(BoldWrapper, null, getResourceName(map, previous === null || previous === void 0 ? void 0 : previous.resourceType, previous === null || previous === void 0 ? void 0 : previous.resource)),
            React.createElement(SupportingTextWrapper, null, " from "),
            React.createElement(BoldWrapper, null, getSection(previous === null || previous === void 0 ? void 0 : previous.resourceType)))),
    },
];
const metadataVirtualEvents = [
    {
        eventType: 'archived from project',
        test: ({ action, current }) => action === 'create' &&
            (current === null || current === void 0 ? void 0 : current.metadataType) === 'systemMetadata' &&
            current.metadataValue === 'Archived',
        output: ({ actor, current, time }, map) => {
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " archived "),
                React.createElement(BoldWrapper, null, getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource)),
                React.createElement(SupportingTextWrapper, null, " from "),
                React.createElement(BoldWrapper, null, getSection(current === null || current === void 0 ? void 0 : current.resourceType))));
        },
    },
    {
        eventType: 'added to list',
        test: ({ action, current }) => action === 'create' && (current === null || current === void 0 ? void 0 : current.metadataType) === 'tag',
        output: ({ actor, current, time }, map) => {
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " added "),
                React.createElement(BoldWrapper, null, getResourceName(map, current === null || current === void 0 ? void 0 : current.resourceType, current === null || current === void 0 ? void 0 : current.resource)),
                React.createElement(SupportingTextWrapper, null, " to "),
                React.createElement(BoldWrapper, null, current === null || current === void 0 ? void 0 : current.metadataValue)));
        },
    },
    {
        eventType: 'restored to project',
        test: ({ action, previous }) => action === 'delete' &&
            (previous === null || previous === void 0 ? void 0 : previous.metadataType) === 'systemMetadata' &&
            previous.metadataValue === 'Archived',
        output: ({ actor, previous, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " restored "),
            React.createElement(BoldWrapper, null, getResourceName(map, previous === null || previous === void 0 ? void 0 : previous.resourceType, previous === null || previous === void 0 ? void 0 : previous.resource)),
            React.createElement(SupportingTextWrapper, null, " to "),
            React.createElement(BoldWrapper, null, getSection(previous === null || previous === void 0 ? void 0 : previous.resourceType)))),
    },
    {
        eventType: 'removed from list',
        test: ({ action, previous }) => action === 'delete' && (previous === null || previous === void 0 ? void 0 : previous.metadataType) === 'tag',
        output: ({ actor, previous, time }, map) => (React.createElement(TimestampWrapper, { timestamp: time },
            React.createElement(BoldWrapper, null, actor.name),
            React.createElement(SupportingTextWrapper, null, " removed "),
            React.createElement(BoldWrapper, null, getResourceName(map, previous === null || previous === void 0 ? void 0 : previous.resourceType, previous === null || previous === void 0 ? void 0 : previous.resource)),
            React.createElement(SupportingTextWrapper, null, " from "),
            React.createElement(BoldWrapper, null, previous === null || previous === void 0 ? void 0 : previous.metadataValue))),
    },
];
const documentVirtualEvents = [
    {
        eventType: 'uploaded custom data to project',
        test: ({ action, previous }) => action === 'create' && previous === null,
        output: ({ actor, current, time }) => {
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " uploaded "),
                React.createElement(BoldWrapper, null, current === null || current === void 0 ? void 0 :
                    current.fileName,
                    " "),
                React.createElement(SupportingTextWrapper, null, "to the project")));
        },
    },
];
const sharingVirtualEvents = [
    {
        eventType: 'shared the project with a collaborator',
        test: ({ action, previous }) => action === 'create' && previous === null,
        output: ({ actor, current, time }) => {
            var _a;
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " added "),
                React.createElement(BoldWrapper, null, (_a = current === null || current === void 0 ? void 0 : current.user) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " "),
                React.createElement(SupportingTextWrapper, null, "as a collaborator to the project")));
        },
    },
    {
        eventType: 'stopped sharing the project with a collaborator',
        test: ({ action, current }) => action === 'delete' && current === null,
        output: ({ actor, previous, time }) => {
            var _a;
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " removed "),
                React.createElement(BoldWrapper, null, (_a = previous === null || previous === void 0 ? void 0 : previous.user) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " "),
                React.createElement(SupportingTextWrapper, null, "as a collaborator on the project")));
        },
    },
    {
        eventType: 'updated a collaborator to no longer be editor',
        test: ({ action, previous, current }) => action === 'update' &&
            !!(previous === null || previous === void 0 ? void 0 : previous.isEditor) &&
            (current === null || current === void 0 ? void 0 : current.isEditor) === false,
        output: ({ actor, previous, time }) => {
            var _a;
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " updated "),
                React.createElement(BoldWrapper, null, (_a = previous === null || previous === void 0 ? void 0 : previous.user) === null || _a === void 0 ? void 0 :
                    _a.name,
                    "'s "),
                React.createElement(SupportingTextWrapper, null, "permissions to view-only")));
        },
    },
    {
        eventType: 'updated a collaborator to be an editor',
        test: ({ action, previous, current }) => action === 'update' &&
            !!(current === null || current === void 0 ? void 0 : current.isEditor) &&
            (previous === null || previous === void 0 ? void 0 : previous.isEditor) === false,
        output: ({ actor, previous, time }) => {
            var _a;
            return (React.createElement(TimestampWrapper, { timestamp: time },
                React.createElement(BoldWrapper, null, actor.name),
                React.createElement(SupportingTextWrapper, null, " gave "),
                React.createElement(BoldWrapper, null, (_a = previous === null || previous === void 0 ? void 0 : previous.user) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " "),
                React.createElement(SupportingTextWrapper, null, "editor permissions on the project")));
        },
    },
];
function getVirtualEventMap(event) {
    switch (event.type) {
        case 'workspace':
            return workspaceVirtualEvents;
        case 'comment':
            return commentVirtualEvents;
        case 'bookmark':
            return bookmarkVirtualEvents;
        case 'metadata':
            return metadataVirtualEvents;
        case 'document':
            return documentVirtualEvents;
        case 'collaborator':
            return sharingVirtualEvents;
        default:
            return [];
    }
}
export { getVirtualEventMap };
