import React, { useMemo } from 'react';
import { Card, COLORS, EmphasisOval, Heading2, Stack, Text, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { getMeasurementHierarchy } from 'src/utils/measurements';
import { countNodes } from 'src/utils/tree';
import { MeasurementTree } from 'src/components/MeasurementTree/MeasurementTree';
import { isCoa } from '../utils/selectors';
const EMPTY_MEASURES = [];
const MeasurementCardContents = ({ tool }) => {
    var _a, _b, _c;
    const manufacturerMeasures = (_a = tool.manufacturerMeasuresCategory) !== null && _a !== void 0 ? _a : EMPTY_MEASURES;
    const evidenceMeasures = (_b = tool.evidenceMeasuresCategory) !== null && _b !== void 0 ? _b : EMPTY_MEASURES;
    const fdaMeasuresCategory = (_c = tool.fdaMeasuresCategory) !== null && _c !== void 0 ? _c : EMPTY_MEASURES;
    const measureWithHierarchy = getMeasurementHierarchy([
        ...(manufacturerMeasures !== null && manufacturerMeasures !== void 0 ? manufacturerMeasures : []),
        ...(evidenceMeasures !== null && evidenceMeasures !== void 0 ? evidenceMeasures : []),
        ...(fdaMeasuresCategory !== null && fdaMeasuresCategory !== void 0 ? fdaMeasuresCategory : []),
    ]);
    const hasTechEvidenceMeasures = useHasPermission('component.evidenceSummary', 'read', tool);
    const totalProductMeasuresCount = useMemo(() => measureWithHierarchy.reduce((x, y) => x + countNodes(y), 0), [measureWithHierarchy]);
    if (totalProductMeasuresCount === 0) {
        return React.createElement(Text, { color: COLORS.gray1 }, "Unable to determine.");
    }
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(When, { condition: hasTechEvidenceMeasures },
            React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Total Product Measures"),
                React.createElement(EmphasisOval, { backgroundColor: COLORS.black },
                    React.createElement(Typography, { variant: TypographyVariant.Details, color: "inherit" }, totalProductMeasuresCount)),
                React.createElement(AnchoredTooltip, null, "This is the total number of measures this sensor can collect. Vendor stated measures may also be collected in evidence."))),
        React.createElement(MeasurementTree, { hasToolMeasures: hasTechEvidenceMeasures, manufacturerMeasuresCategory: isCoa(tool) ? EMPTY_MEASURES : manufacturerMeasures, evidenceMeasuresCategory: hasTechEvidenceMeasures ? evidenceMeasures : EMPTY_MEASURES, fdaMeasuresCategory: hasTechEvidenceMeasures ? fdaMeasuresCategory : EMPTY_MEASURES, authorMeasuresCategory: isCoa(tool) ? manufacturerMeasures : EMPTY_MEASURES })));
};
const MeasurementCard = ({ tool, }) => {
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, "Measurements"),
        React.createElement(MeasurementCardContents, { tool: tool })));
};
export { MeasurementCard };
