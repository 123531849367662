import { createSlice } from '@reduxjs/toolkit';
/** Initial state of the modal slice. */
const initialState = {
    path: '',
    state: {},
    open: false,
    modalOptions: undefined,
};
/** Slice for controlling the state of the global modal manager. */
const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        /** Shows a new modal. */
        showModal: (state, { payload }) => (Object.assign(Object.assign(Object.assign({}, state), payload), { open: true })),
        /** Clears the existing modal. */
        clearModal: (state) => (Object.assign(Object.assign({}, state), initialState)),
    },
});
export const selectModal = (state) => state.modal;
export const { showModal, clearModal } = modalSlice.actions;
export { modalSlice };
