import { Stack, Typography } from '@humanfirst/elektron';
import React from 'react';
import { TechnologyTagPip } from './TechnologyTagPip';
import { TECHNOLOGY_TAG_DATA } from './data';
/** A technology tag (optionally including the description). */
const TechnologyTag = ({ tag, includeDescription }) => {
    const { title, description } = TECHNOLOGY_TAG_DATA[tag];
    if (includeDescription) {
        return (React.createElement(Stack, null,
            React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
                React.createElement(TechnologyTagPip, { tag: tag }),
                React.createElement(Typography, { color: "inherit" }, title)),
            React.createElement(Typography, null, description)));
    }
    else {
        return (React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
            React.createElement(TechnologyTagPip, { tag: tag }),
            React.createElement(Typography, { color: "inherit" }, title)));
    }
};
export { TechnologyTag };
