import { __awaiter } from "tslib";
import { Block, Button, COLORS, FormControl, Icons, Select, SemanticButton, Spread, Stack, StatefulMenu, StatefulPopover, Typography, TypographyVariant, toaster, } from '@humanfirst/elektron';
import { ProjectCollaboratorResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useReducer } from 'react';
import { When } from 'react-if';
import { LoadingState } from 'src/components/LoadingState';
import { useUser } from 'src/hooks/user';
import { QueryWrapper } from '../QueryWrapper';
import { useAllShareableUsers, useCanUpdateSharing, useReconcileCollaboratorState, } from './hooks';
import { getInitailStateFromCollaboratorsList, projectCollaboratorReducer, } from './state';
/** Converts a user to a value for a select. */
const userToValue = (user) => ({ label: user.email, id: user.id });
/** Convert a role id to a human readable string. */
const roleIdToString = (role) => role === 'editor' ? 'Can edit' : 'Can view';
/** Gets a role from a collaborator object. */
const getCollaboratorRoleId = (collaborator) => collaborator.isEditor ? 'editor' : 'viewer';
/**
 * A menu for controlling a collaborator.
 */
const CollaboratorActionsMenu = ({ value, onChange, canUpdate }) => {
    if (!canUpdate) {
        return React.createElement(Typography, null, roleIdToString(value));
    }
    return (React.createElement(StatefulPopover, { placement: "bottom", content: React.createElement(StatefulMenu, { onItemSelect: ({ item }) => {
                onChange(item.id);
            }, items: [
                { label: 'Can edit', id: 'editor' },
                { label: 'Can view', id: 'viewer' },
                { label: 'Remove', id: 'remove' },
            ] }) },
        React.createElement(SemanticButton, null,
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Typography, null, roleIdToString(value)),
                React.createElement(Icons.Misc.ChevronDown, { size: '0.6em', decorative: true })))));
};
/**
 * Allows selecting users from a team.
 *
 * Will exclude any users who are already in the collaborators list.
 */
const UserSelector = ({ onSelect, current }) => {
    const { isLoading, data } = useAllShareableUsers();
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    // Map to values for the select and remove all users who are already collaborators.
    const usersOptions = data === null || data === void 0 ? void 0 : data.map((x) => userToValue(x)).filter((x) => !current.find((y) => { var _a; return ((_a = y.user) === null || _a === void 0 ? void 0 : _a.id) === x.id; }));
    if ((usersOptions === null || usersOptions === void 0 ? void 0 : usersOptions.length) === 0) {
        return (React.createElement(Stack, { padding: "16px", backgroundColor: COLORS.blue4, 
            // Extra margin bottom on this to keep it inline with the form control.
            marginBottom: '16px', direction: 'horizontal', borderRadius: "8px", border: `1px solid ${COLORS.primary}` },
            React.createElement(Block, { width: "24px" },
                React.createElement(Icons.Misc.Info, { decorative: true, color: COLORS.primary, size: "24px" })),
            React.createElement(Typography, null, "You\u2019ve shared the project with everyone on your team(s). If you want to invite more team members, please contact us.")));
    }
    return (React.createElement(FormControl, { label: "Users" },
        React.createElement(Select, { id: "users", onChange: (e) => e &&
                onSelect({
                    id: e.value[0].id,
                    email: e.value[0].label,
                }), options: usersOptions })));
};
/**
 * Shows a single collaborator
 * @returns
 */
const SingleCollaborator = ({ collaborator, removeCollaborator, updateCollaborator, project, canUpdateSharing, }) => {
    var _a;
    const user = useUser();
    const isCreator = collaborator.user.id === ((_a = project.creator) === null || _a === void 0 ? void 0 : _a.id);
    const isCurrentUser = collaborator.user.id === (user === null || user === void 0 ? void 0 : user.id);
    const canUpdateUser = !isCreator && (!isCurrentUser || !!user.isAdministrator) && canUpdateSharing;
    return (React.createElement(Stack, { width: "100%", direction: 'horizontal' },
        React.createElement(Block, { border: `1px solid ${COLORS.gray3}`, borderRadius: "8px", padding: "8px", flex: "1" },
            React.createElement(Spread, { direction: "horizontal" },
                React.createElement(Typography, null,
                    collaborator.user.email,
                    " ",
                    isCurrentUser ? '(you)' : ''),
                isCreator ? (React.createElement(Typography, { color: COLORS.gray2 }, "Creator")) : (React.createElement(CollaboratorActionsMenu, { value: getCollaboratorRoleId(collaborator), onChange: (value) => {
                        if (value === 'remove') {
                            removeCollaborator();
                        }
                        else {
                            updateCollaborator({
                                isEditor: value === 'editor',
                            });
                        }
                    }, canUpdate: canUpdateUser }))))));
};
/**
 * The content of the share modal.
 *
 * This includes a selector to choose a team for the project and then
 * choose the associated users for that team.
 * @returns
 */
export const ShareProjectFormInternal = ({ project, data: collaborators, onSubmit }) => {
    var _a;
    const [data, dispatch] = useReducer(projectCollaboratorReducer, {
        collaborators: getInitailStateFromCollaboratorsList(collaborators),
        isDirty: false,
    });
    const { callback, isLoading, error } = useReconcileCollaboratorState(project, collaborators);
    const canUpdateSharing = useCanUpdateSharing(project);
    const onShare = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield callback(data);
            toaster.positive('Project sharing updated');
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
        }
        catch (_b) {
            // The error should be handled above, but don't call the submit callback.
        }
    }), [callback, onSubmit, data]);
    return (React.createElement(Stack, { gap: "32px" },
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Stack, null,
                React.createElement(Stack, { direction: 'horizontal' },
                    React.createElement(Typography, { variant: TypographyVariant.Heading2Bold }, "Share Project")),
                React.createElement(Typography, null, canUpdateSharing
                    ? 'Select users from your team to collaborate on this project.'
                    : 'Below are the collaborators on this project. Only those with edit permissions can change who can collaborate on the project.')),
            React.createElement(Block, null,
                React.createElement(When, { condition: canUpdateSharing },
                    React.createElement(UserSelector, { onSelect: (user) => {
                            dispatch({
                                type: 'add',
                                data: { user, isEditor: true },
                            });
                        }, current: data.collaborators })))),
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Shared With"),
            ((_a = data.collaborators) !== null && _a !== void 0 ? _a : []).map((x) => (React.createElement(SingleCollaborator, { collaborator: x, project: project, removeCollaborator: () => dispatch({ type: 'remove', data: x }), updateCollaborator: ({ isEditor }) => dispatch({ type: 'update', data: Object.assign(Object.assign({}, x), { isEditor }) }), key: x.user.id, canUpdateSharing: canUpdateSharing })))),
        React.createElement(Block, { display: "flex", justifyContent: 'flex-end' },
            error ? React.createElement(Typography, { color: COLORS.alert }, error) : null,
            React.createElement(Button, { disabled: !data.isDirty, isLoading: isLoading, onClick: onShare, size: "large" }, "Save"))));
};
export const ShareProjectForm = ({ project, onSubmit }) => {
    const result = useElektraApi(ProjectCollaboratorResource.list(project.id));
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: ShareProjectFormInternal, onSubmit: onSubmit, project: project }, result)));
};
