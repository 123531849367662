import { useEffect } from 'react';
import { useLocation } from 'react-router';
/**
 * Scrolls to the top of the page whenever the pathname changes.
 */
const useScrollToTopOnPathChange = () => {
    const { pathname, hash } = useLocation();
    useEffect(() => {
        var _a;
        if (!hash) {
            (_a = window.scrollTo) === null || _a === void 0 ? void 0 : _a.call(window, 0, 0);
        }
    }, [pathname, hash]);
};
export { useScrollToTopOnPathChange };
