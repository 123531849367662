import { Stack, Icons, Button } from '@humanfirst/elektron';
import React from 'react';
import { useUser } from 'src/hooks/user';
import { usePersistentState } from 'src/hooks/persistence';
import { OneClickForm } from '../OneClickForm';
export const RequestIngestionButton = ({ resourceId, resourceType, formData, }) => {
    const user = useUser();
    const [hasSubmitted, setHasSubmitted] = usePersistentState(`requested-ingestion:${resourceType}:${resourceId}`);
    if (hasSubmitted) {
        return (React.createElement(Button, { variant: "secondary", size: "large", disabled: true },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Icons.Product.Yes, { decorative: true }),
                "Requested Ingestion")));
    }
    return (React.createElement(OneClickForm, { buttonText: 'Request Ingestion', buttonVariant: "secondary", onSuccess: () => setHasSubmitted(true), name: "Request Ingestion", formData: Object.assign({ name: user === null || user === void 0 ? void 0 : user.name, email: user === null || user === void 0 ? void 0 : user.email, resourceType,
            resourceId }, formData) }));
};
