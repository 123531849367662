// ESLint interprets the "render" functions as components (even though they're not)
// and so requires display names. This isn't actually necessary so we disable the rule.
/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { DataTable, DateColumn, TextColumn, useDataTableState, } from '@humanfirst/elektron';
import { TitleCell, ReportTypeCell } from './cells';
const getWorkspaceName = (item) => { var _a, _b; return `${(_b = (_a = item.workspace) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''}`; };
const WorkspaceColumn = {
    id: 'project',
    header: 'Project',
    render: getWorkspaceName,
    sortable: true,
    getStringValue: getWorkspaceName,
};
const ReportTable = ({ reports, admin }) => {
    const columns = useMemo(() => {
        const columns = [
            TextColumn('title', {
                header: 'Title',
                render: (item) => React.createElement(TitleCell, { item: item, admin: admin }),
            }),
            TextColumn('type', {
                header: 'Report Type',
                render: (item) => React.createElement(ReportTypeCell, { item: item }),
            }),
            DateColumn('createdAt', { header: 'Created', sortable: true }),
            DateColumn('updatedAt', {
                header: 'Last Updated',
            }),
        ];
        if (admin) {
            columns.splice(1, 0, WorkspaceColumn);
        }
        return columns;
    }, [admin]);
    const result = useDataTableState({ columns, sortable: true }, reports);
    return React.createElement(DataTable, Object.assign({}, result));
};
export { ReportTable };
