import { CardSurface, Block, Stack, Typography, TypographyVariant, StatefulPopover, Button, Icons, COLORS, } from '@humanfirst/elektron';
import React from 'react';
import { useShowModalBound } from 'src/hooks/modal';
const FeedbackContent = () => {
    const showModal = useShowModalBound('atlas-feedback');
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { paddingTop: "16px", paddingBottom: "16px", paddingLeft: "16px", paddingRight: "16px", width: "248px" },
            React.createElement(Stack, null,
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Suggestions on this page?"),
                React.createElement(Typography, null, "Atlas is continually updated. Help improve this page by letting us know if something is missing or is not correct."),
                React.createElement(Block, null,
                    React.createElement(Button, { onClick: showModal }, "Let us know"))))));
};
const AtlasFeedbackPopover = () => {
    return (React.createElement(StatefulPopover, { content: React.createElement(FeedbackContent, null), triggerType: "click", placement: "bottomRight" },
        React.createElement(Button, { variant: "minimal" },
            React.createElement(Icons.Misc.CommentEdit, { size: "24px", color: COLORS.primary, title: "Edit" }))));
};
export { AtlasFeedbackPopover };
