export const TEMPLATES = [
    {
        id: 'atopic-dermatitis',
        name: 'Atopic Dermatitis',
        shortDescription: 'Atopic Dermatitis has been studied across various digital measures, including meaningful aspects of health like Nocturnal Scratch.',
        description: 'Atlas contains 130+ digital measures and 45 digital health technologies (DHTs) that have been previously assessed in patients with Atopic Dermatitis.\n\nThis project is pre-populated with examples of Atlas catalog searches, measures, technologies, and vendors to help you narrow the landscape and find the highest quality solutions.',
        previewDescriptions: [
            {
                resource: 'Searches',
                description: 'Review saved searches relevant to Atopic Dermatitis, filtered by commonly desired criteria, such as FDA registered devices, purchasing availability, and form factor. As you create your own search criteria, you can save generative searches and filters here to return to later.',
            },
            {
                resource: 'Measures',
                description: 'Dive into selected measures from the 60+ measure categories studied in patients with Atopic Dermatitis. While you are exploring, you can save targeted measures that are relevant to your study here. For example, explore the saved measure Nocturnal Scratching, which has been used in 22 pieces of evidence, including peer-reviewed papers and clinical trials.',
            },
            {
                resource: 'Technologies',
                description: 'Compare relevant technologies by different implementation considerations such as form factor (e.g. a patch) and wear locations (e.g. wrist).',
            },
            {
                resource: 'Vendors',
                description: 'Explore the Data Rights & Security of experienced Vendors with technologies for patients with Atopic Dermatitis.',
            },
        ],
        bookmarks: [
            { resourceType: 'Tool', resource: 'recWT6QnEgLyLNl7A' },
            { resourceType: 'Tool', resource: 'rec37g4nAWWHXpIpU' },
            { resourceType: 'Tool', resource: 'rectAZEhOpRLNFTy2' },
            { resourceType: 'Vendor', resource: 'recYGPoNbIsRc8vex' },
            { resourceType: 'Vendor', resource: 'recgHExnH61dD0qPB' },
            { resourceType: 'Vendor', resource: 'reckvpye5RTpc9VoC' },
            { resourceType: 'Measure', resource: 'recYv0jQ0vGF9JKfn' },
            {
                resourceType: 'ToolSearch',
                resource: 'availability:"Available to purchase" formFactor:"Strap/Band",Patch,Watch medicalConditions:"Atopic Dermatitis (including Eczema)" regulatoryDecisions:"FDA Registered"',
            },
        ],
    },
    {
        id: 'activity',
        name: 'Activity',
        shortDescription: 'Digital measures of activity have been used in >300 clinical trials by more than 50 industry sponsors.',
        description: 'Atlas contains 4,315+ digital measures and 944 digital health technologies (DHTs) that have been used to assess Activity.\n\nThis project is pre-populated with examples of Atlas catalog searches, measures, technologies, and vendors to help you narrow the landscape and find the highest quality solutions.',
        previewDescriptions: [
            {
                resource: 'Searches',
                description: 'Review saved searches relevant to Activity, filtered by commonly desired criteria, such as FDA registered devices, purchasing availability, and form factor. As you create your own search criteria, you can save generative searches and filters here to return to later.',
            },
            {
                resource: 'Measures',
                description: 'Launch into our Activity Ontology, which has 115+ measure categories. While you are exploring, you can save targeted measures that are relevant to your study here. For example, you may want to return to Steps, which has been used in 1,346 pieces of evidence, including peer-reviewed papers and clinical trials to capture Activity.',
            },
            {
                resource: 'Technologies',
                description: 'Compare relevant technologies by different implementation considerations such as form factor (e.g. watch) and wear location (e.g. wrist).',
            },
            {
                resource: 'Vendors',
                description: 'Explore the Data Rights & Security of experienced Vendors with technologies measuring Activity.',
            },
        ],
        bookmarks: [
            { resource: 'rectAZEhOpRLNFTy2', resourceType: 'Tool' },
            { resource: 'rec37g4nAWWHXpIpU', resourceType: 'Tool' },
            { resource: 'recQIrFOQC8JL7tLH', resourceType: 'Tool' },
            { resource: 'recqdwAchvlgjNCTl', resourceType: 'Tool' },
            { resource: 'recjlOjORGrDbxXUL', resourceType: 'Tool' },
            { resource: 'rec0QHfDGsacfCljg', resourceType: 'Tool' },
            { resource: 'rec9vne4r19VtEBM5', resourceType: 'Tool' },
            { resource: 'recstyzR61Mlmea2v', resourceType: 'Tool' },
            { resource: 'recAJ1sKhyPhOZjxS', resourceType: 'Tool' },
            {
                resource: 'rece1GOVmlJlyYZny',
                resourceType: 'Tool',
            },
            {
                resource: 'allMeasuresFlat:Steps availability:"Available to purchase" api:"API: Yes" formFactor:Watch organizationEvidence:>100 wearLocation:Wrist',
                resourceType: 'ToolSearch',
            },
            {
                resource: 'allMeasuresFlat:"2-Minute Walk Test","6-Minute Walk Test","Ambulatory Assessments","Timed Up and Go Test" availability:"Available to purchase" regulatoryDecisions:"CE Marked","FDA Registered"',
                resourceType: 'ToolSearch',
            },
            {
                resource: 'allMeasuresFlat:"Activity Intensity" availability:"Available to purchase" modalitiesSensorType:"Accelerometer (3-axis)" regulatoryDecisions:"FDA Registered" technologyType:"Physical Sensor"',
                resourceType: 'ToolSearch',
            },
            {
                resource: 'activity frequency/duration',
                resourceType: 'MeasureSearch',
            },
            { resource: 'energy expenditure', resourceType: 'MeasureSearch' },
            { resource: 'recLcFuumOXZXyK1O', resourceType: 'Vendor' },
            { resource: 'rectGD22HJd3Dijf8', resourceType: 'Vendor' },
            { resource: 'recAPJOykPT7MLvmd', resourceType: 'Vendor' },
            { resource: 'recxStQY6w8e2RSEj', resourceType: 'Measure' },
        ],
    },
    {
        id: 'sleep',
        name: 'Sleep',
        shortDescription: 'Digital measures of sleep have been used in >200 clinical trials by more than 30 industry sponsors.',
        description: 'Atlas contains 1270+ digital measures and 543 digital health technologies (DHTs) that have been used to assess Sleep.\n\nThis project is pre-populated with examples of Atlas catalog searches, measures, technologies, and vendors to help you narrow the landscape and find the highest quality solutions.',
        previewDescriptions: [
            {
                resource: 'Searches',
                description: 'Review saved searches relevant to Sleep, filtered by commonly desired criteria, FDA registered devices, purchasing availability, and form factor. As you create your own search criteria, you can save generative searches and filters here to return to later.',
            },
            {
                resource: 'Measures',
                description: 'Launch into our Sleep Ontology, which has 80+ measure categories. While you are exploring, you can save targeted measures that are relevant to your study here. For example, you may want to return to Sleep Efficiency, which has been used in 598 pieces of evidence, including peer-reviewed papers and clinical trials.',
            },
            {
                resource: 'Technologies',
                description: 'Compare relevant technologies by different implementation considerations such as form factor (e.g. sleep mat) and technology type (e.g. physical sensor, software).',
            },
            {
                resource: 'Vendors',
                description: 'Explore the Data Rights & Security of experienced Vendors with technologies measuring Sleep.',
            },
        ],
        bookmarks: [
            { resourceType: 'Tool', resource: 'recGCjoZQzzBDakde' },
            { resourceType: 'Tool', resource: 'recuprjM9GDgogBRb' },
            { resourceType: 'Tool', resource: 'rec1hwcOwjyBsxu28' },
            { resourceType: 'Tool', resource: 'rec0QHfDGsacfCljg' },
            { resourceType: 'Tool', resource: 'recLthh7C0hRE6bte' },
            { resourceType: 'Tool', resource: 'recCKao8DuOGmTHoB' },
            { resourceType: 'Tool', resource: 'recbm3dwMxWEiPjpW' },
            { resourceType: 'Tool', resource: 'rece1GOVmlJlyYZny' },
            { resourceType: 'Tool', resource: 'recTdJmsYNFIhoVbg' },
            { resourceType: 'Tool', resource: 'rec9VZPHIlTi3mTi8' },
            { resourceType: 'Vendor', resource: 'rec1pnJA3gfkxNaCj' },
            { resourceType: 'Vendor', resource: 'recbCKlaElSK3hkJp' },
            { resourceType: 'Vendor', resource: 'recaSma8S4cSavbf7' },
            { resourceType: 'Measure', resource: 'recxNlNCxfMuS9ag3' },
            {
                resourceType: 'MeasureSearch',
                resource: 'wake after sleep onset',
            },
            {
                resourceType: 'MeasureSearch',
                resource: 'sleep efficiency',
            },
            {
                resourceType: 'ToolSearch',
                resource: 'allMeasuresFlat:"Sleep Stage Detection" availability:"Available to purchase" organizationEvidence:>10 productEvidence:>5 regulatoryDecisions:"CE Marked"',
            },
            {
                resourceType: 'ToolSearch',
                resource: 'allMeasuresFlat:"Sleep Efficiency" availability:"Available to purchase" formFactor:"Sleep mat",Mat',
            },
            {
                resourceType: 'ToolSearch',
                resource: 'allMeasuresFlat:"Activity during Sleep" availability:"Available to purchase" formFactor:Watch organizationEvidence:>100 regulatoryDecisions:"FDA Registered" technologyType:"Physical Sensor"',
            },
        ],
    },
];
