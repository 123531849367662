import { Block, Button, Modal, Stack, Text } from '@humanfirst/elektron';
import React, { useState } from 'react';
import { When } from 'react-if';
import { Locked } from '../Locked';
/**
 * Render a list of elements, with a maximum number.
 * @returns
 */
const LimitedList = ({ elements, limit = Infinity, isLocked, emptyText, listStyling, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    if (elements.length === 0) {
        if (isLocked) {
            return React.createElement(Locked, null);
        }
        else {
            return React.createElement(Text, null, emptyText);
        }
    }
    const limitedItems = elements.slice(0, limit);
    const hiddenItems = elements.length - limit;
    return (React.createElement(Block, null,
        React.createElement(Modal, { isOpen: modalOpen, onClose: () => setModalOpen(false) },
            React.createElement(Block, { maxHeight: '80vh', overflow: 'auto' },
                React.createElement("ul", { style: { padding: '0px' } }, elements))),
        React.createElement(Stack, { gap: '0px' },
            React.createElement("ul", { style: { paddingInlineStart: listStyling ? '20px' : '0px' } }, limitedItems),
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(When, { condition: hiddenItems > 0 },
                    React.createElement(Button, { onClick: () => setModalOpen(true), variant: 'minimal', disabled: isLocked },
                        "+",
                        hiddenItems)),
                React.createElement(When, { condition: isLocked },
                    React.createElement(Locked, null))))));
};
export { LimitedList };
