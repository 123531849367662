import React from 'react';
import { Stack, Icons, COLORS, Typography } from '@humanfirst/elektron';
const LoginError = ({ error, marginBottom }) => {
    if (!error) {
        return null;
    }
    return (React.createElement(Stack, { direction: "horizontal", "data-testid": "login-error", marginBottom: marginBottom },
        React.createElement(Icons.Misc.Error, { color: COLORS.alert, size: "1em", decorative: true }),
        React.createElement(Typography, { color: COLORS.alert }, error)));
};
export { LoginError };
