import React from 'react';
import { Block, COLORS, Divider, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { ToolResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { generatePath } from 'react-router-dom';
import { When } from 'react-if';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { ToolSummary } from 'src/components/ToolSummary';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
function getEvidenceDisplay(tool, hasComponentEvidenceSummary) {
    var _a, _b;
    const numEvidences = (_b = (_a = tool.evidence) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    return hasComponentEvidenceSummary ? numEvidences.toString() : '';
}
const ProductRow = ({ tool, }) => {
    const hasComponentEvidenceSummary = useHasPermission('component.evidenceSummary', 'read', tool);
    const evidenceCount = getEvidenceDisplay(tool, hasComponentEvidenceSummary);
    return (React.createElement(Stack, { gap: "0px", direction: 'horizontal', alignItems: 'center' },
        React.createElement(Block, { flex: "1" },
            React.createElement(ToolSummary, { tool: tool, href: generatePath(PATHS.atlas.technologies.detail, {
                    id: tool.id,
                }) })),
        React.createElement(Block, { flex: "1" },
            React.createElement(Typography, { variant: TypographyVariant.Details }, evidenceCount))));
};
const ProductTable = ({ data: tools }) => {
    const hasComponentEvidenceSummary = useHasPermission('component.evidenceSummary', 'read');
    return (React.createElement(Stack, null,
        React.createElement(Stack, { direction: "horizontal", gap: "0px" },
            React.createElement(Block, { flex: "1" },
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Technology")),
            React.createElement(Block, { flex: "1" },
                React.createElement(When, { condition: hasComponentEvidenceSummary },
                    React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                        React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.gray1 }, "Total Technology Evidence"),
                        React.createElement(AnchoredTooltip, null, t('toolAttributes.productEvidenceTooltip')))))),
        React.createElement(Divider, null),
        tools.map((tool, i) => (React.createElement(React.Fragment, { key: tool.id },
            React.createElement(ProductRow, { tool: tool }),
            i < tools.length - 1 ? React.createElement(Divider, null) : null)))));
};
const OrganizationProductTable = ({ organization }) => {
    const result = useElektraApi(ToolResource.list({
        filter: [['maker', '$eq', organization.id]],
        join: ['evidence', 'evidence.source'],
        sort: [['model', 'ASC']],
    }), { enabled: !!organization.id });
    return React.createElement(QueryWrapper, Object.assign({}, result, { renderSuccess: ProductTable }));
};
export { OrganizationProductTable, ProductTable, getEvidenceDisplay };
