import { useMemo } from 'react';
import algoliasearch from 'algoliasearch';
import { FilterType } from '@humanfirst/elektron';
import { Indicies } from 'src/constants/search';
import { useSearch } from './useSearch';
const APP_ID = 'G4YBATMS7Z';
const OMNISEARCH_TOKEN = '56e21b1c9eab3de362ca12b3d2ef6843';
const OMNISEARCH_INDEX = Indicies.OmniSearch;
export const searchTypeItems = [
    'measure',
    'vendor',
    'model',
    'medicalCondition',
    'component',
    'searchAll',
];
function isOmniRecord(v) {
    if (typeof v.id !== 'string') {
        return false;
    }
    if (typeof v.kind !== 'string') {
        return false;
    }
    const kind = v.kind;
    if (!searchTypeItems.includes(kind)) {
        return false;
    }
    return typeof v.name === 'string';
}
const hitsToOptions = (hits) => {
    if (!hits.every(isOmniRecord)) {
        throw new Error('invalid record format');
    }
    return hits.map((hit) => {
        var _a, _b;
        return ({
            id: hit.id,
            query: hit.name,
            type: hit.kind,
            highlight: (_b = (_a = hit._highlightResult) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.value,
        });
    });
};
const useSearchOmni = ({ query, kinds, enabled, }) => {
    const searchClient = useMemo(() => algoliasearch(APP_ID, OMNISEARCH_TOKEN), []);
    const filters = useMemo(() => {
        if (!kinds) {
            return undefined;
        }
        return [
            {
                fieldId: 'kind',
                filterType: FilterType.INCLUDES_ANY,
                filterValue: kinds,
            },
        ];
    }, [kinds]);
    const { data, isLoading, isError, error } = useSearch({
        searchClient,
        indexName: OMNISEARCH_INDEX,
        query,
        filters,
        enabled,
    });
    const [options, parseError] = useMemo(() => {
        if (typeof data === 'undefined') {
            return [undefined, undefined];
        }
        try {
            const options = hitsToOptions(data);
            return [options, undefined];
        }
        catch (e) {
            return [undefined, e];
        }
    }, [data]);
    return useMemo(() => ({
        options,
        isLoading,
        isError: isError || !!parseError,
        error: error || parseError,
    }), [options, isLoading, isError, error, parseError]);
};
export { useSearchOmni };
