import { Block, COLORS, Link, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ToolImage } from 'src/components/ToolImage';
import { ToolStatusHoverState } from 'src/components/ToolImageWithHoverState/ToolStatusHoverState';
const MaybeLink = ({ href, children, }) => {
    if (!href) {
        return React.createElement(React.Fragment, null, children);
    }
    return React.createElement(Link, { href: href }, children);
};
/** A compact representation of a tool. */
const ToolSummary = ({ tool, href, imageSize = 40, }) => {
    var _a;
    return (React.createElement(ToolStatusHoverState, { tool: tool },
        React.createElement(Stack, { direction: "horizontal", alignItems: 'center', gap: '8px' },
            React.createElement(MaybeLink, { href: href },
                React.createElement(ToolImage, { imageSize: imageSize, tool: tool })),
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    React.createElement(MaybeLink, { href: href }, tool.model)),
                React.createElement(Typography, { variant: TypographyVariant.Label, color: COLORS.gray1 }, (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name)))));
};
export { ToolSummary };
