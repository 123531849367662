import { TeamResourceMetadataResource, useElektraApi, } from '@humanfirst/use-elektra-api';
/**
 * Parses team metadata.
 * Parsing assumptions:
 *  1. Data attributes are split by a blank newline ('\n\n')
 *  2. Titles are always the first line.
 *  3. Everything after the title is the value of the attribute
 *
 * Be careful about this. It's pretty error prone...
 */
export const deserializeTeamMetadata = (raw) => {
    if (!raw) {
        return [];
    }
    return raw.split('\n\n').map((x) => {
        const [title, ...value] = x.split('\n');
        return { title, value: value.join('\n') };
    });
};
const TOOL_TEAM_METADATA_TITLES = [
    'Risk Note',
    'Risk Score',
    'Data Validation Summary',
    'Measurements',
    'Medical Conditions',
    'Software',
    'DHT Inventory Contact',
    'DHT Inventory Email',
];
/** Splits metadata into known good groups. */
export const splitMetadata = (metadata) => {
    const knownMetadata = [];
    const generalMetadata = [];
    for (const m of metadata) {
        if (TOOL_TEAM_METADATA_TITLES.includes(m.title)) {
            knownMetadata.push(m);
        }
        else {
            generalMetadata.push(m);
        }
    }
    return { knownMetadata, generalMetadata };
};
/** Gets the value of a metadata item from something with metadata */
export const getTeamMetadata = (hasMetadata, t) => {
    var _a;
    const metadata = deserializeTeamMetadata(hasMetadata.metadataValue);
    return (_a = metadata.find(({ title }) => title === t)) === null || _a === void 0 ? void 0 : _a.value;
};
export const useGetTeamMetadataForTool = (toolId, teams) => {
    var _a;
    const { isLoading, data } = useElektraApi(TeamResourceMetadataResource.listTeams(teams.flatMap((x) => x.id), {
        resourceType: 'Tool',
        resource: toolId,
        metadataType: 'note',
    }));
    return { isLoading, data: (_a = data === null || data === void 0 ? void 0 : data.flatMap((x) => x.teamMetadata)) !== null && _a !== void 0 ? _a : [] };
};
export const useGetTeamsMetadata = (teams) => {
    var _a;
    const { isLoading, data } = useElektraApi(TeamResourceMetadataResource.listTeams(teams.flatMap((x) => x.id), {
        resourceType: 'Tool',
        metadataType: 'note',
    }));
    return { isLoading, data: (_a = data === null || data === void 0 ? void 0 : data.flatMap((x) => x.teamMetadata)) !== null && _a !== void 0 ? _a : [] };
};
export const useGetTeamMetadata = (team) => {
    return useElektraApi(TeamResourceMetadataResource.list(team.id, {
        resourceType: 'Tool',
        metadataType: 'note',
    }));
};
