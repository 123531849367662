import { SelectContext } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi, useElektraApiMutation, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import { uniq } from 'ramda';
import { useCallback, useContext, useMemo } from 'react';
import { useGetSeenMetadata, useGetReviewedMetadata, useGetArchivedMetadata, useGetUserTagMetadata, } from 'src/pages/Workspace/utils';
const useConstructLitReviewEvidence = (bookmarks, workspaceId) => {
    const finalData = [];
    //get evidence from bookmarks
    const { data: evidence, isLoading: isEvidenceLoading } = useElektraApi(EvidenceResource.getBulk(bookmarks.map((x) => x.resource), {
        join: [
            'sourceMeasures||measureCategories,id',
            'sourceMeasures.tools||id,model,imageSubmittedCDN,imageSubmittedExists',
        ],
    }));
    // get evidence "lists"
    const { data: lists, isInitialLoading: isListsLoading } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'tag',
        resourceType: 'EvidenceSource',
    }));
    // get evidence "Reviewed" metadata
    const { data: reviewedMetadata, isLoading: isReviewedMetadataLoading } = useGetReviewedMetadata(workspaceId, 'EvidenceSource');
    // get evidence "Seen" metadata
    const { data: seenMetadata, isLoading: isSeenMetadataLoading } = useGetSeenMetadata(workspaceId, 'EvidenceSource');
    const { data: archivedMetadata, isLoading: isArchivedMetadataLoading } = useGetArchivedMetadata(workspaceId, 'EvidenceSource');
    //get custom tags
    const { data: tags, isLoading: isTagsLoading } = useGetUserTagMetadata(workspaceId);
    // attach metadata to evidence
    evidence === null || evidence === void 0 ? void 0 : evidence.filter((evidence) => !(archivedMetadata === null || archivedMetadata === void 0 ? void 0 : archivedMetadata.find((m) => m.resource === evidence.id))).map((e) => {
        var _a;
        finalData.push(Object.assign(Object.assign({}, e), { bookmark: bookmarks.find((x) => x.resource === e.id), lists: (_a = lists === null || lists === void 0 ? void 0 : lists.filter((x) => x.resource === e.id)) !== null && _a !== void 0 ? _a : [], reviewed: reviewedMetadata === null || reviewedMetadata === void 0 ? void 0 : reviewedMetadata.find((x) => x.resource === e.id), seen: seenMetadata === null || seenMetadata === void 0 ? void 0 : seenMetadata.find((x) => x.resource === e.id), tags: tags === null || tags === void 0 ? void 0 : tags.filter((x) => x.resource === e.id) }));
    });
    const isLoading = isSeenMetadataLoading ||
        isListsLoading ||
        isEvidenceLoading ||
        isReviewedMetadataLoading ||
        isArchivedMetadataLoading ||
        isTagsLoading;
    return { isLoading: isLoading, data: finalData };
};
/**
 * Returns all unique tags that are used in a workspace.
 * @param workspaceId
 * @returns
 */
const useAvailableTags = (workspaceId) => {
    var _a;
    const { data } = useGetUserTagMetadata(workspaceId);
    return uniq((_a = data === null || data === void 0 ? void 0 : data.map((x) => x.metadataValue)) !== null && _a !== void 0 ? _a : []);
};
/**
 * Returns all user tags for a given resource.
 * @param workspaceId
 * @param evidenceId
 */
const useTagsForResource = (workspaceId, evidenceId) => {
    var _a;
    const { data } = useGetUserTagMetadata(workspaceId);
    return (_a = data === null || data === void 0 ? void 0 : data.filter((x) => x.resource === evidenceId)) !== null && _a !== void 0 ? _a : [];
};
const useTagsForEvidence = (workspaceId, evidenceId) => {
    var _a;
    const { data } = useGetUserTagMetadata(workspaceId);
    return (_a = data === null || data === void 0 ? void 0 : data.filter((x) => x.resource === evidenceId)) !== null && _a !== void 0 ? _a : [];
};
/**
 * Returns a callback that can be used to add a tag to a piece of evidence.
 * @param workspaceId
 */
const useAddTag = (workspaceId) => {
    const { mutateAsync: addTag } = useElektraApiMutation(WorkspaceResourceMetadataResource.create(workspaceId));
    return useCallback((id, tag) => {
        return addTag({
            metadataType: 'userTag',
            metadataValue: tag,
            resourceType: 'EvidenceSource',
            resource: id,
        });
    }, [addTag]);
};
const useEvidenceSelected = () => {
    const { selection, selectable: selectableEvidence } = useContext(SelectContext);
    const castSelectableEvidence = selectableEvidence;
    return useMemo(() => {
        return selection
            .map((x) => {
            return castSelectableEvidence.find((y) => y.id === x);
        })
            .filter((x) => !!x);
    }, [selection, castSelectableEvidence]);
};
export { useConstructLitReviewEvidence, useAddTag, useAvailableTags, useTagsForResource, useTagsForEvidence, useEvidenceSelected, };
