import React from 'react';
import { EmptyStateOtherOptions } from 'src/components/EmptyStateOtherOptions';
import { createGenericTable } from '../shared/createGenericTable';
import { ICD_10_COLUMN, MEDICAL_CONDITION_COLUMN, THERAPEUTIC_AREA_COLUMN, } from './columns';
import { TA_FILTER } from './filters';
const COLUMNS = [
    MEDICAL_CONDITION_COLUMN,
    THERAPEUTIC_AREA_COLUMN,
    ICD_10_COLUMN,
];
/** A table that shows medical conditions. */
export const MedicalConditionTable = createGenericTable({
    columns: COLUMNS,
    filters: [TA_FILTER],
}, {
    emptyElement: React.createElement(EmptyStateOtherOptions, null),
    itemType: 'medical conditions',
}, { sort: { column: 'name', ascending: true } }, { stateStorage: 'url', track: 'medicalCondition' });
/** A simplified version of the medical condition table without search/filters. */
export const MedicalConditionTableSimple = createGenericTable({
    columns: COLUMNS,
    searchable: false,
});
