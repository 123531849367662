import { __awaiter } from "tslib";
import { NotificationResource, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { useCallback } from 'react';
/**
 * Creates a callback that will mark all notifications as read.
 */
const useMarkAllNotificationsAsReadCallback = (notifications) => {
    // NOTE: When using the update without a specific ID, the ID _must_ be passed
    // in at call time.
    const { mutateAsync } = useElektraApiMutation(NotificationResource.update());
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        for (const notification of notifications) {
            if (notification.isUnread) {
                yield mutateAsync(Object.assign(Object.assign({}, notification), { isUnread: false }));
            }
        }
    }), [mutateAsync, notifications]);
};
export { useMarkAllNotificationsAsReadCallback };
