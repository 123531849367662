import { __awaiter } from "tslib";
import { useCallback, useMemo } from 'react';
import algoliasearch from 'algoliasearch';
import { useElektraApi, } from '@humanfirst/use-elektra-api';
// 115 min
const API_KEY_STALE_THRESHOLD = 1000 * 60 * 115;
const APP_ID = 'G4YBATMS7Z';
const PENDING_SEARCH_CLIENT = new Proxy({}, {
    apply() {
        throw new Error('search client is not ready yet');
    },
    set() {
        throw new Error('search client is not ready yet');
    },
    get() {
        throw new Error('search client is not ready yet');
    },
});
/**
 * Returns a search client or null, as well as helper functions to control the
 * underlying search token resource..
 *
 * Only non-stale clients without errors will be returned.  Otherwise, `null` is returned as a client.
 */
const useSearchClient = (resource, enabled = true) => {
    const results = useElektraApi(resource, {
        staleTime: API_KEY_STALE_THRESHOLD,
        enabled: !!resource && enabled,
    });
    const refreshIfNeeded = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { isStale, refetch } = results;
        if (isStale) {
            yield refetch({ throwOnError: true, cancelRefetch: false });
        }
    }), [results]);
    const { isSuccess, isStale, data } = results;
    const searchClient = useMemo(() => {
        return isSuccess && !isStale && (data === null || data === void 0 ? void 0 : data.algoliaApiKey)
            ? algoliasearch(APP_ID, data.algoliaApiKey)
            : PENDING_SEARCH_CLIENT;
    }, [isSuccess, isStale, data === null || data === void 0 ? void 0 : data.algoliaApiKey]);
    return useMemo(() => {
        return Object.assign(Object.assign({}, results), { isReady: searchClient !== PENDING_SEARCH_CLIENT, searchClient,
            refreshIfNeeded });
    }, [results, refreshIfNeeded, searchClient]);
};
export { useSearchClient };
