import { __awaiter } from "tslib";
import { Typography, TypographyVariant, Stack, Button, toaster, Modal, Input, } from '@humanfirst/elektron';
import { useElektraApi, useElektraApiMutation, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import React, { useCallback, useState } from 'react';
import { When } from 'react-if';
import pLimit from 'p-limit';
import { useNavigate } from 'react-router';
import { useLogger } from 'src/hooks/logger';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
const REQUEST_LIMIT = 10;
const ListActionsModal = ({ isOpen, onClose, listName, workspaceId, action }) => {
    const [rename, setRename] = useState(undefined);
    const logger = useLogger('list-action');
    const navigate = useNavigate();
    const { data: listItems = [] } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'tag',
        metadataValue: listName,
    }));
    const { mutateAsync: deleteMutateAsync, isLoading: isRemoveLoading } = useElektraApiMutation(WorkspaceResourceMetadataResource.remove(workspaceId));
    const { mutateAsync: createMutateAsync, isLoading: isCreateLoading } = useElektraApiMutation(WorkspaceResourceMetadataResource.create(workspaceId));
    const limiter = pLimit(REQUEST_LIMIT);
    const onDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield Promise.all(listItems.map((item) => limiter(() => __awaiter(void 0, void 0, void 0, function* () {
                yield deleteMutateAsync({ id: item.id });
            }))));
            navigate(`${generatePath(PATHS.projects.detail, {
                projectId: workspaceId,
            })}?view=1`);
        }
        catch (e) {
            logger.warn(`Cannot delete project list: ${String(e)}`);
            toaster.negative(`Could not delete the list at this time. Please try again later.`);
        }
    }), [deleteMutateAsync, limiter, listItems, logger, navigate, workspaceId]);
    const onRename = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!!rename) {
                yield Promise.all(listItems.map((item) => limiter(() => __awaiter(void 0, void 0, void 0, function* () {
                    yield deleteMutateAsync({ id: item.id });
                    yield createMutateAsync({
                        metadataValue: rename,
                        metadataType: 'tag',
                        resourceType: item.resourceType,
                        resource: item.resource,
                    });
                }))));
            }
            onClose();
            navigate(generatePath(PATHS.projects.lists.view, {
                projectId: workspaceId,
                listName: rename,
            }));
        }
        catch (e) {
            logger.warn(`Cannot rename project list: ${String(e)}`);
            toaster.negative(`Could not rename the list at this time. Please try again later.`);
        }
    }), [
        createMutateAsync,
        deleteMutateAsync,
        limiter,
        listItems,
        logger,
        navigate,
        onClose,
        rename,
        workspaceId,
    ]);
    if (!action) {
        return null;
    }
    const isLoading = isCreateLoading || isRemoveLoading;
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(Stack, null,
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold },
                action === 'rename' ? 'Rename' : 'Delete',
                " List"),
            React.createElement(When, { condition: action === 'rename' },
                React.createElement(Input, { placeholder: "Please specify", value: rename !== null && rename !== void 0 ? rename : listName, onChange: (x) => setRename(x.target.value) })),
            React.createElement(When, { condition: action === 'deleteList' },
                React.createElement(Typography, null, "Are you sure you want to delete this list? Deleting this list can not be undone. This will not delete the items from your project.")),
            React.createElement(Stack, { alignItems: 'end' },
                React.createElement(Button, { size: "large", onClick: action === 'rename' ? onRename : onDelete, isLoading: isLoading, disabled: action === 'rename' ? !rename : false }, action === 'rename' ? 'Rename' : 'Delete')))));
};
export { ListActionsModal };
