import { Card, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { getTeamMetadata } from 'src/utils/teamMetadata';
import { MarkdownMetadataDisplay } from './shared';
export const GenericMarkdownCard = ({ metadata, title }) => {
    const relevantMetadata = getTeamMetadata(metadata, title);
    if (!relevantMetadata) {
        return null;
    }
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: "0" }, title)),
            React.createElement(MarkdownMetadataDisplay, { metadata: relevantMetadata }))));
};
