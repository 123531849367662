import { ButtonLink, COLORS, DataTable, FilterType, Stack, Tag, Typography, useDataTableState, } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceResourceMetadataResource, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { serializeQuery } from 'src/utils/query';
import { getNumericRangeFilterDisplay } from 'src/utils/filters';
import { getResourceDisplay, getSearchUrl, } from './utils';
const ALTERNATIVE_LABELS = {
    allMeasuresFlat: 'Measure',
    modalitiesSensorType: 'Sensor Modality',
    dataIntegrator: 'Data Platform',
    organizationEvidence: 'Vendor Evidence Count',
    productEvidence: 'Product Evidence Count',
};
function getLabelName(fieldId) {
    if (fieldId in ALTERNATIVE_LABELS) {
        return ALTERNATIVE_LABELS[fieldId];
    }
    const splitWords = fieldId.replace(/([a-z])([A-Z])/g, '$1 $2');
    return splitWords[0].toUpperCase() + splitWords.slice(1);
}
function getLabelValue(filterType, filterValue) {
    if (filterType === FilterType.IS_IN_NUMERIC_RANGE) {
        return getNumericRangeFilterDisplay(filterValue);
    }
    return String(filterValue);
}
const FilterComponent = ({ filter }) => {
    const labelName = getLabelName(filter.fieldId);
    const labelValue = getLabelValue(filter.filterType, filter.filterValue);
    const label = `${labelName}: ${labelValue}`;
    return (React.createElement(Tag, { color: COLORS.gray1, kind: "custom", variant: "solid", closeable: false }, label));
};
const DisplaySearchName = ({ item, }) => {
    var _a;
    const workspaceId = useActiveWorkspaceId();
    const { data: names } = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        resource: serializeQuery(item.query),
        resourceType: item.resourceType,
        metadataType: 'alternativeTitle',
    }));
    if (!(names === null || names === void 0 ? void 0 : names.length)) {
        return React.createElement(Typography, { color: COLORS.gray1 }, "Untitled");
    }
    const displayName = (_a = names[0]) === null || _a === void 0 ? void 0 : _a.metadataValue;
    return React.createElement(Typography, null, displayName);
};
const ContinueSearchButton = ({ item }) => {
    const projectId = useActiveWorkspaceId();
    const url = getSearchUrl(item, projectId);
    return url ? React.createElement(ButtonLink, { href: url }, "Continue Search") : null;
};
const SEARCH_TABLE_COLUMNS = [
    {
        id: 'search-name',
        header: 'Name',
        render: (item) => React.createElement(DisplaySearchName, { item: item }),
    },
    {
        id: 'search-resource',
        header: 'Resource',
        render: (item) => React.createElement(Typography, null, getResourceDisplay(item)),
    },
    {
        id: 'search terms',
        header: 'Search Terms',
        render: (item) => {
            if (!item.query.search) {
                return React.createElement(Typography, { color: COLORS.gray2 }, "none");
            }
            return React.createElement(Typography, null, item.query.search);
        },
    },
    {
        id: 'filters',
        header: 'Filters',
        render: (item) => {
            if (!item.query.filters) {
                return React.createElement(Typography, { color: COLORS.gray2 }, "none");
            }
            const flatFilters = item.query.filters.flatMap((filter) => {
                return Array.isArray(filter.filterValue)
                    ? filter.filterValue.map((value) => ({
                        fieldId: filter.fieldId,
                        filterType: filter.filterType,
                        filterValue: value,
                    }))
                    : filter;
            });
            return (React.createElement(Stack, { alignItems: "start" }, flatFilters.map((filter, i) => (React.createElement(FilterComponent, { key: i, filter: filter })))));
        },
    },
    {
        id: 'continue',
        header: '',
        render: (item) => React.createElement(ContinueSearchButton, { item: item }),
    },
];
const DEFAULT_EMPTY_COLUMNS = [];
const SearchTable = ({ data, extraColumns = DEFAULT_EMPTY_COLUMNS, initialState, extraActions, }) => {
    const columns = [...SEARCH_TABLE_COLUMNS, ...extraColumns];
    const result = useDataTableState({ columns, searchable: false, selectable: !!extraActions }, data, initialState);
    return (React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: extraActions })));
};
export { SearchTable };
