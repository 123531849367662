import React from 'react';
import { Typography, TypographyVariant, Block, Spacer, Card, Spread, Divider, SemanticButton, COLORS, Stack, Link, } from '@humanfirst/elektron';
import { Else, If, Then } from 'react-if';
import { t } from 'src/constants/i18n';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { LoadingState } from 'src/components/LoadingState';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { getPathFromString } from 'src/utils/measurements';
/**
 * Filter measure categories by removing all utility and usability categories.
 */
const filterNonPhysiological = (categories) => {
    return categories.filter((x) => getPathFromString(x.category)[0] !== 'Utility and Usability');
};
const MeasureBlock = ({ measure, showEvidence, hasEvidencePermission }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Spacer, null),
        React.createElement(Spread, null,
            React.createElement(Link, { href: generatePath(PATHS.atlas.measures.detail, { id: measure.id }), key: measure.id }, measure.name),
            React.createElement(If, { condition: hasEvidencePermission },
                React.createElement(Then, null,
                    React.createElement(SemanticButton, { onClick: () => showEvidence('Measures', measure.name) },
                        React.createElement(Typography, { color: COLORS.primary }, measure.evidenceCount))),
                React.createElement(Else, null,
                    React.createElement(Typography, null, measure.evidenceCount))))));
};
const TopMeasuresCard = ({ showEvidence, condition, categories, isLoading = false, hasEvidencePermission, }) => {
    const topMeasures = filterNonPhysiological(categories).slice(0, 5);
    return (React.createElement(Card, null,
        React.createElement(Block, null,
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading1 }, 'Top Measures'),
                React.createElement(AnchoredTooltip, null, `${t('medicalConditionPage.topMeasuresToolTip')}${condition.name}.`)),
            React.createElement(Spacer, { size: '16px' }),
            React.createElement(Spread, null,
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "MEASURES"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "EVIDENCE")),
            React.createElement(Divider, null),
            React.createElement(If, { condition: isLoading },
                React.createElement(Then, null,
                    React.createElement(LoadingState, null)),
                React.createElement(Else, null,
                    React.createElement(If, { condition: (topMeasures === null || topMeasures === void 0 ? void 0 : topMeasures.length) === 0 },
                        React.createElement(Then, null,
                            React.createElement(Spacer, null),
                            React.createElement(Typography, { color: COLORS.gray2 }, "No measures listed"),
                            React.createElement(Spacer, { size: '24px' })),
                        React.createElement(Else, null, topMeasures === null || topMeasures === void 0 ? void 0 : topMeasures.map((x) => (React.createElement(MeasureBlock, { key: x.name, measure: x, showEvidence: showEvidence, hasEvidencePermission: hasEvidencePermission }))))))))));
};
export { TopMeasuresCard };
