const serializeSimpleSelect = (value) => {
    var _a;
    return !!value && value.length > 0 ? (_a = value[0]) === null || _a === void 0 ? void 0 : _a.id : null;
};
const serialize = (formContent) => {
    var _a, _b, _c, _d, _e, _f;
    const industry = !!formContent.industry && formContent.industry.length > 0
        ? ((_a = formContent.industry[0]) === null || _a === void 0 ? void 0 : _a.id) === 'Other'
            ? (_b = formContent.otherIndustry) !== null && _b !== void 0 ? _b : null
            : (_c = formContent.industry[0]) === null || _c === void 0 ? void 0 : _c.id
        : null;
    const medicalConditions = formContent.hasMCs === 'Yes' && formContent.medicalConditions
        ? formContent.medicalConditions
        : null;
    const trialPhases = !!formContent.trialPhase && formContent.trialPhase.length > 0
        ? formContent.trialPhase.map((x) => x.id)
        : [];
    const upcomingClinicalTrial = serializeSimpleSelect(formContent.upcomingClinicalTrial);
    const teamfunction = !!formContent.function && formContent.function.length > 0
        ? ((_d = formContent.function[0]) === null || _d === void 0 ? void 0 : _d.id) === 'Other'
            ? (_e = formContent.otherFunction) !== null && _e !== void 0 ? _e : null
            : (_f = formContent.function[0]) === null || _f === void 0 ? void 0 : _f.id
        : null;
    const therapeuticArea = serializeSimpleSelect(formContent.therapeuticArea);
    return {
        industry,
        medicalConditions,
        trialPhases,
        upcomingClinicalTrial,
        function: teamfunction,
        therapeuticArea,
    };
};
const deserialize = (userProfile) => {
    var _a, _b, _c;
    const industry = userProfile.industry
        ? [{ id: userProfile.industry, label: userProfile.industry }]
        : [];
    // We need to explicitly set undefined here to avoid null values in the form
    const otherIndustry = ((_a = industry[0]) === null || _a === void 0 ? void 0 : _a.id) === 'Other'
        ? userProfile.industry
            ? userProfile.industry
            : undefined
        : undefined;
    const hasMCs = userProfile.medicalConditions ? 'Yes' : 'No';
    const medicalConditions = userProfile.medicalConditions
        ? userProfile.medicalConditions
        : '';
    const trialPhase = ((_b = userProfile.trialPhases) === null || _b === void 0 ? void 0 : _b.map((x) => ({ id: x, label: x }))) || [];
    const teamfunction = userProfile.function
        ? [{ id: userProfile.function, label: userProfile.function }]
        : [];
    const therapeuticArea = userProfile.therapeuticArea
        ? [{ id: userProfile.therapeuticArea, label: userProfile.therapeuticArea }]
        : [];
    // We need to explicitly set undefined here to avoid null values in the form
    const otherFunction = ((_c = teamfunction[0]) === null || _c === void 0 ? void 0 : _c.id) === 'Other'
        ? userProfile.function
            ? userProfile.function
            : undefined
        : undefined;
    const upcomingClinicalTrial = userProfile.upcomingClinicalTrial
        ? [
            {
                id: userProfile.upcomingClinicalTrial,
                label: userProfile.upcomingClinicalTrial,
            },
        ]
        : [];
    return {
        industry,
        otherIndustry,
        hasMCs,
        medicalConditions,
        trialPhase,
        function: teamfunction,
        otherFunction,
        upcomingClinicalTrial,
        therapeuticArea,
    };
};
export { serialize, deserialize };
