import { __awaiter } from "tslib";
import { Block, CardSurface, COLORS, ControlsPopover, Divider, Icons, Markdown, SemanticButton, Spacer, Spread, Stack, StatefulPopover, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { NotificationResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { Else, If, Then, When } from 'react-if';
import { formatDateFromNow } from 'src/utils/date';
import { useMarkAllNotificationsAsReadCallback } from '../hooks/hooks';
import { ShowMore } from './ShowMore';
const NotificationsIcon = ({ hasUnreadNotifications }) => {
    return hasUnreadNotifications ? (React.createElement(Icons.Misc.UnreadNotificationsBell, { color: COLORS.primary, size: "1.5em", title: "Unread Notifications" })) : (React.createElement(Icons.Misc.NotificationsBell, { color: COLORS.primary, size: "1.5em", title: "Notifications" }));
};
const NotificationItemContent = ({ notification, }) => {
    return (React.createElement(Block, { key: notification.id, padding: '16px 0' },
        React.createElement(Stack, { direction: 'horizontal', gap: '16px' },
            React.createElement(Block, { width: '312px' },
                React.createElement(ShowMore, { limitedHeight: '88px' },
                    React.createElement(Markdown, { content: notification.message, compact: true })),
                React.createElement(When, { condition: !!notification.createdAt },
                    React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, formatDateFromNow(notification.createdAt)))),
            React.createElement(When, { condition: notification.isUnread },
                React.createElement(Block, { paddingTop: '4px' },
                    React.createElement(Icons.Misc.Circle, { decorative: true, size: '8px', color1: COLORS.alert, color2: COLORS.alert }))))));
};
export const NotificationItem = ({ notification, }) => {
    const { mutateAsync } = useElektraApiMutation(NotificationResource.update(notification.id));
    const dismissNotification = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (notification.isUnread) {
            yield mutateAsync(Object.assign(Object.assign({}, notification), { isUnread: false }));
        }
    }), [mutateAsync, notification]);
    if (notification.isUnread) {
        return (React.createElement(SemanticButton, { onClick: dismissNotification, "data-testid": `notificaton-${notification.id}` },
            React.createElement(NotificationItemContent, { notification: notification })));
    }
    else {
        return React.createElement(NotificationItemContent, { notification: notification });
    }
};
const NotificationsList = ({ notifications }) => {
    const markAllAsRead = useMarkAllNotificationsAsReadCallback(notifications);
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { width: "400px", display: "flex", flexDirection: "column", padding: '24px', maxHeight: "90vh", overrides: { Block: { style: { overflowY: 'auto' } } } },
            React.createElement(Spread, null,
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, `Notifications (${notifications.length})`),
                React.createElement(When, { condition: notifications.length },
                    React.createElement(ControlsPopover, { iconSize: "24px", popoverPlacement: "bottomLeft", menuItems: [
                            {
                                title: 'Mark all as read',
                                onClick: markAllAsRead,
                                icon: React.createElement(Icons.Misc.Complete, { decorative: true }),
                            },
                        ] }))),
            React.createElement(If, { condition: notifications.length },
                React.createElement(Then, null, notifications.map((notification, i) => (React.createElement(React.Fragment, { key: notification.id },
                    React.createElement(NotificationItem, { notification: notification }),
                    i !== notifications.length - 1 ? React.createElement(Divider, null) : null)))),
                React.createElement(Else, null,
                    React.createElement(Spacer, { size: "16px" }),
                    React.createElement(Typography, { color: COLORS.gray1 }, "You have no notifications."))))));
};
const NotificationsInternal = ({ notifications }) => {
    const hasUnreadNotifications = notifications.some((n) => n.isUnread);
    return (React.createElement(StatefulPopover, { content: () => React.createElement(NotificationsList, { notifications: notifications }), accessibilityType: "menu", autoFocus: true, placement: "bottom" },
        React.createElement(SemanticButton, null,
            React.createElement(NotificationsIcon, { hasUnreadNotifications: hasUnreadNotifications }))));
};
const Notifications = () => {
    var _a;
    const result = useElektraApi(NotificationResource.list({ sort: [['createdAt', 'DESC']] }));
    const notifications = (_a = result.data) !== null && _a !== void 0 ? _a : [];
    return React.createElement(NotificationsInternal, { notifications: notifications });
};
export { Notifications, NotificationsInternal };
