var _a, _b, _c, _d;
import React, { createContext, useMemo } from 'react';
import { datadogLogs, } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { escapeRegex } from 'src/utils/regexp';
import { getCurrentEnvironement } from 'src/utils/environment';
import { redactSensitiveUrl } from 'src/hooks/analytics/utils';
const DD_APPLICATION_ID = process.env.DD_APPLICATION_ID;
const DD_CLIENT_TOKEN = process.env.DD_CLIENT_TOKEN;
const DD_SERVICE = process.env.DD_SERVICE;
const DD_ENV = (_a = process.env.DD_ENV) !== null && _a !== void 0 ? _a : getCurrentEnvironement();
const DD_VERSION = (_c = (_b = process.env.DD_VERSION) !== null && _b !== void 0 ? _b : process.env.REACT_APP_VERSION) !== null && _c !== void 0 ? _c : '0.0.0';
const DD_SITE = process.env.DD_SITE;
const DD_PROXY = process.env.DD_PROXY;
const DD_TRACE_URLS = (_d = process.env.DD_TRACE_URLS) !== null && _d !== void 0 ? _d : '';
const SESSION_SAMPLE_RATE = 100;
const SESSION_REPLACE_SAMPLE_RATE = 20;
const USE_SECURE_SESSION_COOKIES = true;
function parseAllowedTracingURLs(input) {
    const urls = input.split(',');
    return urls
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .map((url) => {
        if (!url.includes('*')) {
            return url;
        }
        const pattern = escapeRegex(url).replace(/\\\*/g, '.*');
        return new RegExp(`^${pattern}`);
    });
}
const NOOP_LOGGER = {
    info: () => undefined,
    error: () => undefined,
    debug: () => undefined,
    warn: () => undefined,
};
class DatadogLoggerWrapper {
    constructor(logger) {
        this.logger = logger;
    }
    log(level, message, context) {
        var _a;
        const error = message instanceof Error ? message : undefined;
        message = (_a = (message instanceof Error ? message.message : message)) !== null && _a !== void 0 ? _a : '';
        this.logger.log(String(message), context, level, error);
    }
    debug(message, context) {
        return this.log('debug', message, context);
    }
    error(message, context) {
        return this.log('error', message, context);
    }
    info(message, context) {
        return this.log('info', message, context);
    }
    warn(message, context) {
        return this.log('warn', message, context);
    }
}
const LoggerContext = createContext({
    createLogger: () => NOOP_LOGGER,
    startView: () => undefined,
    setUser: () => undefined,
    addFeatureFlagEvaluation: () => undefined,
});
if (DD_APPLICATION_ID && DD_CLIENT_TOKEN) {
    datadogRum.init({
        applicationId: DD_APPLICATION_ID,
        clientToken: DD_CLIENT_TOKEN,
        site: DD_SITE,
        proxy: DD_PROXY,
        service: DD_SERVICE,
        env: DD_ENV,
        version: DD_VERSION,
        allowedTracingUrls: parseAllowedTracingURLs(DD_TRACE_URLS),
        sessionSampleRate: SESSION_SAMPLE_RATE,
        sessionReplaySampleRate: SESSION_REPLACE_SAMPLE_RATE,
        useSecureSessionCookie: USE_SECURE_SESSION_COOKIES,
        trackViewsManually: true,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: (event) => {
            event.view.url = redactSensitiveUrl(event.view.url);
        },
    });
}
if (DD_CLIENT_TOKEN) {
    datadogLogs.init({
        clientToken: DD_CLIENT_TOKEN,
        site: DD_SITE,
        proxy: DD_PROXY,
        service: DD_SERVICE,
        env: DD_ENV,
        version: DD_VERSION,
        sessionSampleRate: SESSION_SAMPLE_RATE,
        useSecureSessionCookie: USE_SECURE_SESSION_COOKIES,
        forwardErrorsToLogs: true,
        beforeSend: (event) => {
            if (event.message) {
                event.message = redactSensitiveUrl(event.message);
            }
            if (event.http) {
                event.http.url = redactSensitiveUrl(event.http.url);
            }
            event.view.url = redactSensitiveUrl(event.view.url);
        },
    });
}
const createConsoleLoggerContext = () => {
    const logger = console;
    return {
        createLogger: () => logger,
        startView: (name) => logger.info('Start View', { name }),
        setUser: () => undefined,
        addFeatureFlagEvaluation: (key, isEnabled) => logger.info(`Feature Flag`, { key, isEnabled }),
    };
};
const createDatadogLoggerContext = () => {
    return {
        createLogger: (name, context) => new DatadogLoggerWrapper(datadogLogs.createLogger(name, { context })),
        startView: (name) => {
            datadogRum.startView(name);
        },
        setUser: (user, properties) => {
            if (!user) {
                datadogRum.clearUser();
                datadogLogs.clearUser();
            }
            else {
                const userData = Object.assign({ id: user.id, email: user.email, name: user.name }, properties);
                datadogRum.setUser(userData);
                datadogLogs.setUser(userData);
            }
        },
        addFeatureFlagEvaluation: (key, isEnabled) => {
            datadogRum.addFeatureFlagEvaluation(key, isEnabled);
        },
    };
};
const LoggerProvider = ({ children }) => {
    const value = useMemo(() => {
        if (DD_APPLICATION_ID && DD_CLIENT_TOKEN) {
            return createDatadogLoggerContext();
        }
        return createConsoleLoggerContext();
    }, []);
    return (React.createElement(LoggerContext.Provider, { value: value }, children));
};
export { LoggerContext, LoggerProvider, createDatadogLoggerContext, createConsoleLoggerContext, };
