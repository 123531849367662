import { Block } from '@humanfirst/elektron';
import { useElektraApi, WorkspaceReportResource, WorkspaceResource, } from '@humanfirst/use-elektra-api';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { ContentContainer } from 'src/components/ContentContainer';
import { ErrorComponent } from 'src/components/ErrorComponent';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { ReportDisplay } from 'src/components/ReportDisplay';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { useActiveWorkspaceId } from 'src/hooks/workspace';
import { getHeap } from 'src/utils/heap';
const ReportInternal = ({ data: report, workspace }) => {
    var _a;
    const reportType = (_a = report.type) === null || _a === void 0 ? void 0 : _a.replace(/^./, report.type[0].toUpperCase());
    const reportTitle = `${reportType} Report: ${report.title}`;
    useEffect(() => {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        (_a = getHeap()) === null || _a === void 0 ? void 0 : _a.addEventProperties({ reportTitle });
    }, [reportTitle]);
    return (React.createElement(Block, null,
        React.createElement(PageTitle, { title: reportTitle }),
        React.createElement(ContentContainer, { breadcrumbs: workspace && report
                ? [
                    BREADCRUMBS.Workspaces,
                    BREADCRUMBS.WorkspaceDetails(workspace),
                    BREADCRUMBS.Report(Object.assign(Object.assign({}, report), { projectId: workspace.id })),
                ]
                : undefined },
            React.createElement(ReportDisplay, { report: report }))));
};
const Report = () => {
    const workspaceId = useActiveWorkspaceId();
    const { id } = useParams();
    const { data: workspace } = useElektraApi(WorkspaceResource.get(workspaceId));
    const result = useElektraApi(WorkspaceReportResource.get(workspaceId, id));
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: ReportInternal, renderError: ErrorComponent, workspace: workspace }, result)));
};
export { Report };
