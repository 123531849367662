import { __awaiter } from "tslib";
import { Block, Button, Modal, Stack, Typography, TypographyVariant, toOptions, toaster, } from '@humanfirst/elektron';
import { WorkspaceResourceMetadataResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import { uniq } from 'ramda';
import React, { useCallback } from 'react';
import pLimit from 'p-limit';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { SmartForm } from 'src/components/SmartForm';
import { SmartSelect } from 'src/components/SmartForm/fields';
import { useLogger } from 'src/hooks/logger';
const REQUEST_LIMIT = 10;
const AddToWorkspaceListModalInternal = ({ data, bookmarks, workspaceId, onClose }) => {
    const allLists = uniq(data.map((x) => x.metadataValue));
    const logger = useLogger('add-to-workspace-list');
    const { mutateAsync } = useElektraApiMutation(WorkspaceResourceMetadataResource.create(workspaceId));
    const onSubmit = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ list }) {
        const listName = list[0].id;
        const limiter = pLimit(REQUEST_LIMIT);
        try {
            yield Promise.all(bookmarks.map((bookmark) => limiter(() => __awaiter(void 0, void 0, void 0, function* () {
                return yield mutateAsync({
                    metadataValue: listName,
                    metadataType: 'tag',
                    resourceType: bookmark.resourceType,
                    resource: bookmark.resource,
                });
            }))));
            onClose();
            toaster.positive('Added items to list');
        }
        catch (e) {
            logger.warn(`Cannot add item to workspace list: ${String(e)}`);
            toaster.negative(`Could not add item.`);
        }
    }), [bookmarks, mutateAsync, logger, onClose]);
    return (React.createElement(Block, null,
        React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, marginBottom: "16px" }, "Add to List"),
        React.createElement(SmartForm, { submitComponent: (props) => (React.createElement(Block, { display: "flex", justifyContent: "flex-end" },
                React.createElement(Stack, { gap: "16px", direction: 'horizontal' },
                    React.createElement(Button, { variant: "tertiary", size: "large", onClick: onClose }, "Cancel"),
                    React.createElement(Button, { size: "large", isLoading: props.isLoading, disabled: !props.isValid, type: "submit" }, "Add")))), onSubmit: onSubmit },
            React.createElement(SmartSelect, { name: "list", "aria-label": "workspace-list", options: toOptions(allLists), label: "Select a list", creatable: true, rules: { required: true }, noResultsMsg: "Type to create a new list" }))));
};
const AddToWorkspaceListModal = ({ isOpen, onClose, bookmarks, workspaceId }) => {
    const listsResult = useElektraApi(WorkspaceResourceMetadataResource.list(workspaceId, {
        metadataType: 'tag',
    }));
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(QueryWrapper, Object.assign({}, listsResult, { renderSuccess: AddToWorkspaceListModalInternal, bookmarks: bookmarks, workspaceId: workspaceId, onClose: onClose }))));
};
export { AddToWorkspaceListModal };
