import { TextColumn, Typography, TypographyVariant, DateColumn, } from '@humanfirst/elektron';
import React from 'react';
import { WorkspaceNameCell, WorkspaceTeamCell } from './cells';
import { getStatusDisplay, getTeamName } from './utils';
export const NAME_COLUMN = TextColumn('name', {
    header: 'Name',
    render: (item) => React.createElement(WorkspaceNameCell, { item: item }),
    metadata: { displayRole: 'required' },
});
export const STATUS_COLUMN = TextColumn('status', {
    id: 'status',
    header: 'Status',
    getStringValue: getStatusDisplay,
    render: (item) => (React.createElement(Typography, { variant: TypographyVariant.Details }, getStatusDisplay(item))),
    metadata: { displayRole: 'display' },
});
export const TEAM_COLUMN = TextColumn('team', {
    header: 'Team',
    render: (item) => React.createElement(WorkspaceTeamCell, { item: item }),
    getStringValue: getTeamName,
    metadata: { displayRole: 'display' },
});
export const CREATED_AT_COLUMN = DateColumn('createdAt', {
    header: 'Date Created',
    metadata: { displayRole: 'hidden' },
});
export const UPDATED_AT_COLUMN = DateColumn('updatedAt', {
    header: 'Last Updated',
    metadata: { displayRole: 'display' },
});
