import { Center, COLORS, Stack, Typography } from '@humanfirst/elektron';
import React from 'react';
import { CommentComponent } from './CommentComponent';
/** Renders a chain of comments, and a form to create new comments. */
export const CommentChain = ({ comments }) => {
    if (comments.length === 0) {
        return (React.createElement(Center, null,
            React.createElement(Typography, { color: COLORS.gray1 }, "No comments yet")));
    }
    return (React.createElement(Stack, null, comments.map((comment) => (React.createElement(CommentComponent, { key: comment.id, comment: comment })))));
};
