import { DataTable, Link, TextColumn, } from '@humanfirst/elektron';
import React from 'react';
import { PATHS } from 'src/config/path';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { getPathFromString } from 'src/utils/measurements';
import { generatePath } from 'src/utils/path';
import { useDataTableQueryParams } from 'src/hooks/useDataTableQueryParams';
import { EmptyStateOtherOptions } from '../EmptyStateOtherOptions';
import { LimitedList } from '../LimitedList/LimitedList';
import { TechnologyColumnDisplay } from '../TechnologyColumnDisplay';
import { buildMeasureFilters } from './filters';
const MeasureCategoryRelatedToolsCell = ({ item, }) => {
    return (React.createElement(LimitedList, { elements: item.tools.map((x) => (React.createElement(TechnologyColumnDisplay, { key: x === null || x === void 0 ? void 0 : x.id, tool: x }))), emptyText: 'None meeting Atlas criteria', limit: 1 }));
};
const CATEGORY_COLUMN = TextColumn('category', {
    header: 'Measure',
    sortable: true,
    searchable: true,
    getStringValue: (item) => { var _a; return (_a = getPathFromString(item.category).pop()) !== null && _a !== void 0 ? _a : ''; },
    render: (item) => {
        var _a;
        const name = (_a = getPathFromString(item.category).pop()) !== null && _a !== void 0 ? _a : '';
        return (React.createElement(React.Fragment, null,
            React.createElement(Link, { href: generatePath(PATHS.atlas.measures.detail, {
                    id: item.id,
                }), key: item.id }, name)));
    },
});
const RELATED_TOOLS_COLUMN = {
    id: 'tools',
    header: 'Technologies',
    render: (item) => React.createElement(MeasureCategoryRelatedToolsCell, { item: item }),
};
const DEFAULT_COLOMNS = [];
const MeasureTable = ({ measureCategories, extraColumns = DEFAULT_COLOMNS, initialState, }) => {
    const sortOrder = { column: 'category', ascending: true };
    // Construct the full list of columns. Extra columns are appended.
    const columns = [CATEGORY_COLUMN, RELATED_TOOLS_COLUMN, ...extraColumns];
    const hasAllEvidence = useHasAllEvidence();
    const showEvidenceFilter = hasAllEvidence;
    const filters = buildMeasureFilters(measureCategories, showEvidenceFilter);
    const result = useDataTableQueryParams({
        columns,
        filters,
        pagination: { pageSize: 20 },
        searchable: true,
        sortable: true,
        filterVariant: 'advanced',
    }, measureCategories, initialState !== null && initialState !== void 0 ? initialState : { sort: sortOrder });
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "measures", emptyElement: React.createElement(EmptyStateOtherOptions, null) })));
};
export { MeasureTable };
