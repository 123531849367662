import { Block, Heading, Icons, Spacer } from '@humanfirst/elektron';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { BREADCRUMBS } from 'src/config/breadcrumbs';
import { PATHS } from 'src/config/path';
import { t } from 'src/constants/i18n';
import { ScopedEvidenceTable } from 'src/components/ScopedEvidenceTable';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { ExploreAtlasOption } from '../Dashboard/components/ExploreAtlasOption';
import { useAppStatistics } from '../../hooks/useAppStatistics';
import { IfNotToggle, IfToggle } from '../../components/IfToggle';
const EvidenceSearchByOptions = () => {
    const stats = useAppStatistics();
    return (React.createElement(Block, { width: "100%", display: "grid", gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr 1fr 1fr'], gridGap: ['8px', '8px', '8px', '24px'] },
        React.createElement(ExploreAtlasOption, { title: t('dashboard.technologyTitle'), subtitle: 'Explore evidence by technology', buttonTitle: t('dashboard.technologyCTA'), icon: React.createElement(Icons.Navigation.Products, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.technologies.list, count: stats.data.technologies }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.measureLibraryTitle'), buttonTitle: t('dashboard.measureLibraryCTA'), subtitle: 'Explore evidence by measure', icon: React.createElement(Icons.Navigation.Activity, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.measures.list, count: stats.data.measures }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.medicalConditionTitle'), subtitle: 'Explore evidence by medical condition', buttonTitle: t('dashboard.medicalConditionCTA'), icon: React.createElement(Icons.Navigation.Explore, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.medicalConditions.list, count: stats.data.medicalConditions }),
        React.createElement(ExploreAtlasOption, { title: t('dashboard.vendorsTitle'), subtitle: 'Explore evidence by vendors', buttonTitle: t('dashboard.vendorsCTA'), icon: React.createElement(Icons.Navigation.Vendors, { active: true, size: "48px", decorative: true }), action: PATHS.atlas.vendors.list, count: stats.data.vendors })));
};
const EvidenceList = () => {
    const hasEvidenceSource = useHasPermission('evidenceSource', 'read', 'all');
    const { evidenceTableScopes } = useGetConnectInstrumentScopes();
    return (React.createElement(ContentContainer, { breadcrumbs: [BREADCRUMBS.Atlas, BREADCRUMBS.Evidence] },
        React.createElement(PageTitle, { title: "Evidence" }),
        React.createElement(Heading, null, "Explore Evidence"),
        React.createElement(Spacer, { size: "48px" }),
        React.createElement(If, { condition: hasEvidenceSource },
            React.createElement(Then, null,
                React.createElement(IfToggle, { name: "list-evidence" },
                    React.createElement(ScopedEvidenceTable, { scope: evidenceTableScopes })),
                React.createElement(IfNotToggle, { name: "list-evidence" },
                    React.createElement(EvidenceSearchByOptions, null))),
            React.createElement(Else, null,
                React.createElement(EvidenceSearchByOptions, null)))));
};
export { EvidenceList };
