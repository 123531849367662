import { Icons, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { When } from 'react-if';
import { getTemplateBookmarksByType, } from 'src/utils/workspaceTemplate';
const WorkspaceTemplateSummaryStats = ({ template, direction = 'vertical' }) => {
    const vendors = getTemplateBookmarksByType(template, 'vendor');
    const technologies = getTemplateBookmarksByType(template, 'technology');
    const measures = getTemplateBookmarksByType(template, 'measure');
    const searches = getTemplateBookmarksByType(template, 'search');
    return (React.createElement(Stack, { gap: direction === 'horizontal' ? '24px' : undefined, direction: direction },
        React.createElement(When, { condition: vendors.length > 0 },
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Icons.Navigation.Search, { active: true, decorative: true }),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    searches.length,
                    " ",
                    searches.length > 1 ? 'Searches' : 'Search'))),
        React.createElement(When, { condition: measures.length > 0 },
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Icons.Navigation.Activity, { active: true, decorative: true }),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    measures.length,
                    " ",
                    measures.length > 1 ? 'Measures' : 'Measure'))),
        React.createElement(When, { condition: technologies.length > 0 },
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Icons.Navigation.Products, { active: true, decorative: true }),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    technologies.length,
                    ' ',
                    technologies.length > 1 ? 'Technologies' : 'Technology'))),
        React.createElement(When, { condition: vendors.length > 0 },
            React.createElement(Stack, { direction: 'horizontal', alignContent: 'center' },
                React.createElement(Icons.Navigation.Vendors, { active: true, decorative: true }),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold },
                    vendors.length,
                    " ",
                    vendors.length > 1 ? 'Vendors' : 'Vendor')))));
};
export { WorkspaceTemplateSummaryStats };
