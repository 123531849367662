import { Block, COLORS, Heading, Icons, Image, Spacer, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { ContactUsFormTrigger } from '../ContactUsFormTrigger';
import { ContentContainer } from '../ContentContainer';
/**
 * Handle an error for a single catalog resource.
 *
 * We make the assumption that this is an authorization error and send the user
 * to an upgrade page.
 */
const CatalogResourceError = ({ imageUrl, resourceName }) => {
    return (React.createElement(ContentContainer, null,
        React.createElement(Block, null,
            React.createElement(Stack, { direction: "horizontal", alignItems: "baseline", gap: "8px" },
                React.createElement(Icons.Misc.LockSolid, { color: COLORS.tertiary, size: "24px", decorative: true }),
                React.createElement(Heading, { marginBottom: "0px" },
                    "Unlock full access to this ",
                    resourceName)),
            React.createElement(Spacer, { size: "24px" }),
            React.createElement(Stack, { direction: "horizontal", gap: "80px", flexWrap: true },
                React.createElement(Block, { flex: "1" },
                    React.createElement(Typography, { variant: TypographyVariant.Heading2, marginBottom: "24px" }, "Get in touch with our team to learn more about upgrading your account."),
                    React.createElement(ContactUsFormTrigger, { location: `Locked ${resourceName} Page`, contactType: 'Upgrade Account' }, "Request Access"),
                    React.createElement(Spacer, { size: "36px" }),
                    React.createElement(Image, { alt: 'Example of an all access page', width: "100%", src: imageUrl }))))));
};
export { CatalogResourceError };
