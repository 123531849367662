import debounce from 'lodash.debounce';
import { useMemo, useEffect } from 'react';
/**
 * Creates a debounced callback in way that's hook compliant.
 * Also optionally flushes/cancels the debounced callback when unmounting.
 * WARNING: The input function should be created using useCallback or similar.
 * Otherwise debouncing may not work how you expect.
 */
function useDebouncedCallback(fun, debounceTime, flushOnUnmount = false) {
    // Create a memomized version of the debounced function.
    const debounced = useMemo(() => debounce(fun, debounceTime), [fun, debounceTime]);
    // Handle unmounting. We either flush or cancel depending on how we were initialized.
    useEffect(() => {
        return () => {
            if (flushOnUnmount) {
                debounced.flush();
            }
            else {
                debounced.cancel();
            }
        };
    }, [debounced, flushOnUnmount]);
    return debounced;
}
export { useDebouncedCallback };
