import { FilterableFieldType, CategoricalFilter, NumericRangeFilter, } from '@humanfirst/elektron';
import { defaultEvidenceBuckets } from 'src/utils/buckets';
import { getCategories } from 'src/utils/filters';
const buildMeasureFilters = (measures, showEvidenceFilter) => {
    const makerCategories = [
        ...new Set(measures
            .flatMap(({ tools }) => tools === null || tools === void 0 ? void 0 : tools.map((tool) => { var _a, _b; return (_b = (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : []; }))
            .flat()),
    ].sort();
    const modelCategories = [
        ...new Set(measures
            .flatMap(({ tools }) => tools === null || tools === void 0 ? void 0 : tools.map((tool) => tool.model || []))
            .flat()),
    ].sort();
    const baseFilters = [
        CategoricalFilter('availability', {
            label: 'Technology Availability',
            getValue: (item) => item.tools.map((tool) => { var _a; return (_a = tool.availability) !== null && _a !== void 0 ? _a : ''; }) || [],
            categories: getCategories([
                'Announced/In development',
                'Available to purchase',
                'Soon to be discontinued',
                'Discontinued',
                'Unable to determine',
            ]),
        }),
        CategoricalFilter('vendor', {
            label: 'Vendor',
            id: 'vendor',
            type: FilterableFieldType.MULTI_CATEGORICAL,
            getValue: (item) => (item.tools.map((tool) => { var _a; return (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.name; }).filter((x) => !!x) ||
                []),
            categories: getCategories(makerCategories),
        }),
        CategoricalFilter('model', {
            label: 'Model',
            getValue: (item) => item.tools.map((tool) => tool.model) || [],
            categories: getCategories(modelCategories),
            metadata: { priority: 'priority' },
        }),
    ];
    if (showEvidenceFilter) {
        baseFilters.push(NumericRangeFilter('evidenceCount', {
            label: 'Evidence Count',
            categories: getCategories(defaultEvidenceBuckets, FilterableFieldType.NUMERIC_RANGE),
            metadata: { priority: 'priority' },
        }));
    }
    return baseFilters;
};
export { buildMeasureFilters };
