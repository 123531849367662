import { __awaiter, __rest } from "tslib";
import { Block, Heading, Stack, toaster } from '@humanfirst/elektron';
import { StudyResource, useElektraApi, useElektraApiMutation, } from '@humanfirst/use-elektra-api';
import React, { useCallback } from 'react';
import { Else, If, Then } from 'react-if';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from 'src/components/ContentContainer';
import { PageTitle } from 'src/components/PageTitle';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { StudyForm } from 'src/components/StudyForm';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
const CreateOrEditStudy = ({ data: study }) => {
    const navigate = useNavigate();
    const { mutateAsync } = useElektraApiMutation(study ? StudyResource.update(study.id) : StudyResource.create());
    const onSubmit = useCallback(
    // De-structure and only pick fields we know will be updating
    // This is so we don't accidentally try to write createdAt / updatedAt / etc
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var { createdAt, updatedAt } = _a, rest = __rest(_a, ["createdAt", "updatedAt"]);
        const result = yield mutateAsync(Object.assign({}, rest));
        toaster.positive('Study Saved', {});
        navigate(generatePath(PATHS.catalog.studies.view.index, { id: result.id }), { replace: true });
    }), [mutateAsync, navigate]);
    return (React.createElement(Stack, { maxWidth: "960px" },
        React.createElement(StudyForm, { onSubmit: onSubmit, study: study })));
};
const EditStudy = () => {
    const { id } = useParams();
    const results = useElektraApi(StudyResource.get(id), {
        enabled: !!id,
    });
    const title = !id ? 'Create Study' : 'Edit Study';
    return (React.createElement(ContentContainer, { backButton: true },
        React.createElement(PageTitle, { title: title }),
        React.createElement(Heading, null, title),
        React.createElement(Block, { paddingBottom: "16px" },
            React.createElement(If, { condition: !id },
                React.createElement(Then, null,
                    React.createElement(CreateOrEditStudy, null)),
                React.createElement(Else, null,
                    React.createElement(QueryWrapper, Object.assign({}, results, { renderSuccess: CreateOrEditStudy })))))));
};
export { EditStudy };
