import { Block, COLORS, CardSurface, Heading2, Icons, LinkTarget, Spacer, Spread, Stack, Typography, TypographyVariant, FilterType, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { When } from 'react-if';
import { useLocation } from 'react-router';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { OneClickForm } from 'src/components/OneClickForm';
import { QueryWrapper } from 'src/components/QueryWrapper';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { RankedEvidenceStack } from 'src/components/RankedEvidence';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { LockableEvidenceTable } from './LockableEvidenceTable';
export const LockedEvidenceCard = () => {
    const location = useLocation();
    return (React.createElement(CardSurface, null,
        React.createElement(Stack, { minHeight: "90px", padding: "32px", gap: "8px" },
            React.createElement(Spread, { alignItems: "center" },
                React.createElement(Typography, { variant: TypographyVariant.Heading2Bold, color: COLORS.gray1 }, "Product Evidence"),
                React.createElement(Icons.Misc.Lock, { size: "24px", title: "Locked" })),
            React.createElement(Typography, { variant: TypographyVariant.Body }, "Upgrade your account to see evidence for this product including peer-reviewed papers, FDA decisions, and more."),
            React.createElement(Block, null,
                React.createElement(OneClickForm, { name: "Upgrade Account", formData: { source: location.pathname }, buttonText: 'Request Evidence', successElement: React.createElement(Stack, { direction: "horizontal" },
                        React.createElement(Icons.Product.Yes, { decorative: true }),
                        React.createElement(Typography, null, "A member of the HumanFirst team will reach out with 2 business days.")) })))));
};
export const ProductEvidenceTable = ({ tool, data: evidence }) => {
    const showDetailedEvidence = useHasPermission('component.evidence', 'read', tool);
    const scope = useMemo(() => {
        return [
            {
                fieldId: 'tools',
                filterType: FilterType.EQUALS,
                filterValue: `${tool.model}||${tool.id}`,
            },
        ];
    }, [tool.id, tool.model]);
    const { evidenceTableScopes } = useGetConnectInstrumentScopes();
    const finalScope = [...scope, ...evidenceTableScopes];
    return (React.createElement(Block, null,
        React.createElement(LinkTarget, { id: "evidence" }),
        React.createElement(When, { condition: showDetailedEvidence },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold, marginBottom: "32px" }, "Product Evidence"),
                React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.measuresAndEvidence.evidenceIngestion }),
                React.createElement(EditFlagTooltip, { field: "Product Evidence", label: "Please provide links to evidence you would like to add", hasComments: true, rows: 1 })),
            React.createElement(RankedEvidenceStack, { evidence: evidence }),
            React.createElement(Spacer, { size: "64px" })),
        React.createElement(When, { condition: showDetailedEvidence },
            React.createElement(LockableEvidenceTable, { isLocked: false, scope: finalScope }))));
};
export const ProductEvidenceContainer = ({ tool, }) => {
    // Load the actual sources with the appropriate joins
    const result = useElektraApi(EvidenceResource.list({
        filter: [['tools', '$cont', tool.id]],
    }));
    return (React.createElement(QueryWrapper, Object.assign({ renderSuccess: ProductEvidenceTable, tool: tool }, result)));
};
