import { __rest } from "tslib";
import { useQueryParam, } from 'use-query-params';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from './debounce';
/** Use a query param as the backing state, but debounce the actual setting. */
function useDebouncedQueryParam(paramName, config, _a = {}) {
    var { debounceTimeout = 200 } = _a, options = __rest(_a, ["debounceTimeout"]);
    const [value, setValue] = useQueryParam(paramName, config, options);
    const [cachedValue, setCachedValue] = useState(value);
    const debouncedSetValue = useDebouncedCallback(setValue, debounceTimeout, true);
    useEffect(() => {
        debouncedSetValue(cachedValue);
    }, [cachedValue, debouncedSetValue]);
    return [cachedValue, setCachedValue];
}
export { useDebouncedQueryParam };
