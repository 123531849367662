import { __awaiter } from "tslib";
import { Button, COLORS, Icons, Stack, Textarea, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React, { useState } from 'react';
import { When } from 'react-if';
import { useLogger } from 'src/hooks/logger';
/**
 * Form to create a new comment.
 */
export const CommentForm = ({ onSubmit }) => {
    const [comment, setComment] = useState('');
    const logger = useLogger('CommentForm');
    return (React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
            e.preventDefault();
            if (!comment) {
                return;
            }
            try {
                yield onSubmit({ comment });
                setComment('');
            }
            catch (e) {
                logger.error(`Could not submit comment. Error: ${String(e)} `);
            }
        }) },
        React.createElement(Stack, { alignItems: 'flex-end' },
            React.createElement(Textarea, { name: "comment", "aria-label": "Comment", id: "comment", value: comment, onChange: (e) => setComment(e.target.value), autoComplete: 'off', placeholder: "Write a comment" }),
            React.createElement(When, { condition: comment.length > 2048 },
                React.createElement(Stack, { width: "320px" },
                    React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.alert },
                        React.createElement(Icons.Misc.Error, { decorative: true, color: COLORS.alert }),
                        `Comment must be less than or equal to 2048 characters. Currently: ${comment.length}`))),
            React.createElement(When, { condition: !!comment },
                React.createElement(Stack, { direction: 'horizontal' },
                    React.createElement(Button, { variant: "tertiary", onClick: () => setComment('') }, "Cancel"),
                    React.createElement(Button, { type: "submit", disabled: comment.length > 2048 }, "Save"))))));
};
