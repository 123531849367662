import { NotificationType } from '@humanfirst/api-models';
export const NOTIFICATION_OPTIONS = [
    {
        name: NotificationType.newsletter,
        label: 'Atlas features, tips, and industry news',
    },
    {
        name: NotificationType.reportPublished,
        label: 'New Report Published',
    },
    {
        name: NotificationType.resourceUpdated,
        label: 'Updates to Atlas Technologies and Vendors you follow',
    },
    {
        name: NotificationType.removedFromTeam,
        label: 'Removal from teams in Atlas',
    },
    {
        name: NotificationType.invitedToTeam,
        label: 'Invitations to teams in Atlas',
    },
];
