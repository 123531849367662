export const sortCollaborators = (collaborators) => [...collaborators].sort((a, b) => a.user.email.localeCompare(b.user.email));
export const getInitailStateFromCollaboratorsList = (collaborators) => sortCollaborators(collaborators.map((x) => ({
    user: { id: x.user.id, email: x.user.email },
    isEditor: x.isEditor,
})));
export const projectCollaboratorReducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return Object.assign(Object.assign({}, state), { collaborators: sortCollaborators([...state.collaborators, action.data]), isDirty: true });
        case 'remove':
            return Object.assign(Object.assign({}, state), { collaborators: state.collaborators.filter((x) => x.user.id !== action.data.user.id), isDirty: true });
        case 'update':
            return Object.assign(Object.assign({}, state), { collaborators: state.collaborators.map((x) => x.user.id !== action.data.user.id ? x : action.data), isDirty: true });
        default:
            return state;
    }
};
