import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { checkPermissions } from 'src/utils/permissions';
/** Returns an array of tuples for each resource of [Resource, hasPermission] */
function useHasPermissionsList(permission, list) {
    const { user, grants } = useContext(UserContext);
    const { resource, action } = permission;
    return !user
        ? list.map((item) => [item, false])
        : list.map((item) => [
            item,
            checkPermissions({ user, grants: grants || [] }, resource, action, item),
        ]);
}
export { useHasPermissionsList };
