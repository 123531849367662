import { Link, Typography } from '@humanfirst/elektron';
import React from 'react';
import { PATHS } from 'src/config/path';
import { generatePath } from 'src/utils/path';
/** Renders a concept of interest, either as a link or a plain text field. */
export const ConceptOfInterestLink = ({ measureId, conceptOfInterest, }) => {
    if (measureId) {
        return (React.createElement(Link, { href: generatePath(PATHS.atlas.measures.detail, {
                id: measureId,
            }) }, conceptOfInterest));
    }
    else {
        return React.createElement(Typography, null, conceptOfInterest);
    }
};
