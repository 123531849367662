import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { Center, Spinner } from '@humanfirst/elektron';
import { PageTitle } from 'src/components/PageTitle';
import { PrimaryContentCard } from 'src/components/PrimaryContentCard';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { useToken } from 'src/hooks/authentication';
import { PATHS } from 'src/config/path';
import { decodeFromUrl } from 'src/utils/serialize';
import { ToggleContext } from 'src/contexts/ToggleContext';
const navigateWithState = (navigate, state) => {
    const decodedState = decodeFromUrl(state);
    let nextPath = '/';
    if (!!decodedState &&
        typeof decodedState === 'object' &&
        'next' in decodedState &&
        typeof decodedState.next === 'string') {
        nextPath = decodedState.next;
    }
    navigate(nextPath, { replace: true });
};
const SsoCallback = () => {
    const getToken = useToken();
    const navigate = useNavigate();
    const track = useAnalyticsEvent();
    const { isLoading } = useContext(ToggleContext);
    const [codeQuery, setCode] = useQueryParam('code', StringParam);
    const [stateQuery] = useQueryParam('state', StringParam);
    // We need to make this callback doesn't actually run multiple times
    // since we'll be updating the code. So we copy the url query params into
    // a local state.
    const [codeAndState] = useState({ code: codeQuery, state: stateQuery });
    const { code, state } = codeAndState;
    const exchangeCode = useCallback((code) => getToken({ code, grant_type: 'https://workos.com/' }), [getToken]);
    const onSuccessCallback = useCallback(() => {
        navigateWithState(navigate, state);
    }, [navigate, state]);
    const onErrorCallback = useCallback((e) => {
        var _a;
        const errorHasMessage = typeof e === 'object' && e && 'message' in e;
        const errorString = String(errorHasMessage ? e.message : e);
        track('sso-login-error', {
            error: errorString,
        });
        // If error comes back with a redirect url, we will immediately navigate to it..
        if ((_a = e === null || e === void 0 ? void 0 : e.body) === null || _a === void 0 ? void 0 : _a.url) {
            window.location.replace(e.body.url);
            return;
        }
        // There is some error on our end, we will redirect to the login page.
        navigate(PATHS.authentication.login.request, {
            replace: true,
            state: { error: errorString },
        });
    }, [navigate, track]);
    const promiseRef = useRef();
    useEffect(() => {
        if (isLoading) {
            return;
        }
        let isMounted = true;
        if (!promiseRef.current) {
            if (code) {
                promiseRef.current = exchangeCode(code);
            }
            else {
                promiseRef.current = Promise.reject('Unknown error communicating with identity provider');
            }
        }
        // Remove `code` from the URL so that we don't end up including it in any
        // referrers or otherwise leaked.  This MUST be called before the `then`
        // so that we remove it before we do the redirect.
        //
        // This is suggested in RFC6819 4.4 - https://www.rfc-editor.org/rfc/rfc6819#section-4.4
        setCode(null);
        promiseRef.current
            .then(() => {
            if (isMounted) {
                onSuccessCallback();
            }
        })
            .catch((e) => {
            if (isMounted) {
                onErrorCallback(e);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [
        code,
        setCode,
        exchangeCode,
        onSuccessCallback,
        onErrorCallback,
        isLoading,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, { title: "SSO Callback" }),
        React.createElement(PrimaryContentCard, null,
            React.createElement(Center, null,
                React.createElement(Spinner, null)))));
};
export { SsoCallback };
