import { Card, Heading2, Spread, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { ToolAttribute } from 'src/components/ModelAttribute/ToolAttribute';
import { AttributeTooltip } from 'src/components/ModelAttribute/ModelAttributeTooltip';
const DataRightsAndSecurityCard = ({ tool }) => {
    var _a, _b, _c, _d;
    const privacyPolicy = (_b = (_a = tool.maker) === null || _a === void 0 ? void 0 : _a.legalPolicies) === null || _b === void 0 ? void 0 : _b.find((x) => x.type === 'Privacy Policy');
    const productPrivacyPolicy = (_d = (_c = tool.maker) === null || _c === void 0 ? void 0 : _c.legalPolicies) === null || _d === void 0 ? void 0 : _d.find((x) => x.type === 'Privacy policy - Product');
    const isSamePolicy = Boolean((privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url) && (productPrivacyPolicy === null || productPrivacyPolicy === void 0 ? void 0 : productPrivacyPolicy.url) === (privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url));
    const securityPolicyAttributes = [
        ToolAttribute.CVD,
        ToolAttribute.PublishedUpdates,
    ];
    const dataPrivacyStatementAttributes = [
        ToolAttribute.GDPR,
        ToolAttribute.HIPAA,
    ];
    return (React.createElement(Card, { padding: "32px" },
        React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold }, "Data Rights & Security"),
        React.createElement(Stack, { gap: "24px" },
            React.createElement(Stack, { gap: "24px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Privacy Policy"),
                React.createElement(Spread, null,
                    React.createElement(ToolAttribute.PrivacyPolicy, { item: tool }),
                    React.createElement(EditFlagTooltip, { field: "Privacy Policy" })),
                isSamePolicy ? (React.createElement(Stack, { direction: "horizontal" },
                    React.createElement(Typography, { variant: TypographyVariant.Body }, "Applies to data collected by the website and product(s)"),
                    React.createElement(AttributeTooltip, { tooltip: {
                            maker: 'This means the privacy policy explicitly says it applies to the website and specific products or products in general.',
                            searcher: 'This means the privacy policy explicitly says it applies to the website and specific products or products in general.',
                        } }))) : (React.createElement(ToolAttribute.ProductPrivacyPolicy, { item: tool }))),
            React.createElement(Stack, { gap: "24px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Security"),
                React.createElement(Stack, { gap: "24px" }, securityPolicyAttributes.map((Attribute) => (React.createElement(Spread, { key: Attribute.label },
                    React.createElement(Attribute, { item: tool }),
                    React.createElement(EditFlagTooltip, { field: Attribute.label })))))),
            React.createElement(Stack, { gap: "24px" },
                React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Data Privacy Compliance Statements"),
                React.createElement(Stack, { gap: "24px" }, dataPrivacyStatementAttributes.map((Attribute) => (React.createElement(Spread, { key: Attribute.label },
                    React.createElement(Attribute, { item: tool }),
                    React.createElement(EditFlagTooltip, { field: Attribute.label })))))))));
};
export { DataRightsAndSecurityCard };
