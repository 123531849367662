import React from 'react';
import { generatePath, useParams, Navigate } from 'react-router';
import { ComponentList } from 'src/pages/ComponentList';
import { Dashboard } from 'src/pages/Dashboard';
import { MeasureDetails, MeasuresByName, } from 'src/pages/Measures/MeasureDetails';
import { MedicalConditionList } from 'src/pages/MedicalConditionList';
import { MedicalCondition } from 'src/pages/MedicalCondition';
import { Ontologies } from 'src/pages/Ontologies';
import { Organization } from 'src/pages/Organization';
import { OrganizationList } from 'src/pages/OrganizationList';
import { Tool } from 'src/pages/Tool';
import { Evidence } from 'src/pages/Evidence';
import { EvidenceList } from 'src/pages/EvidenceList';
const AtlasRoutes = {
    path: 'atlas',
    children: {
        index: {
            index: true,
            element: React.createElement(Dashboard, null),
        },
        technologies: {
            path: 'technologies',
            children: {
                list: {
                    index: true,
                    element: React.createElement(ComponentList, null),
                },
                details: {
                    path: ':id',
                    element: React.createElement(Tool, null),
                },
            },
        },
        vendors: {
            path: 'vendors',
            children: {
                list: {
                    index: true,
                    element: React.createElement(OrganizationList, null),
                },
                details: {
                    path: ':id',
                    element: React.createElement(Organization, null),
                },
            },
        },
        evidence: {
            path: 'evidence',
            children: {
                list: {
                    index: true,
                    element: React.createElement(EvidenceList, null),
                },
                details: {
                    path: ':id',
                    element: React.createElement(Evidence, null),
                },
            },
        },
        measures: {
            path: 'measures',
            children: {
                list: {
                    index: true,
                    element: React.createElement(Ontologies, null),
                },
                details: {
                    path: ':id',
                    element: React.createElement(MeasureDetails, null),
                },
            },
        },
        measuresName: {
            path: 'measures-name',
            children: {
                details: {
                    path: ':name',
                    element: React.createElement(MeasuresByName, null),
                },
            },
        },
        medicalConditions: {
            path: 'medical-conditions',
            children: {
                list: {
                    index: true,
                    element: React.createElement(MedicalConditionList, null),
                },
                details: {
                    path: ':id',
                    element: React.createElement(MedicalCondition, null),
                },
            },
        },
    },
};
/** Navigate to a new URL, perserving the URL params. */
const NavigateWithParams = ({ to }) => {
    const params = useParams();
    return React.createElement(Navigate, { to: generatePath(to, params), replace: true });
};
const AtlasRedirects = {
    children: {
        dashboard: {
            path: 'dashboard',
            element: React.createElement(Navigate, { to: "/atlas", replace: true }),
        },
        tools: {
            path: 'tools',
            children: {
                list: {
                    index: true,
                    element: React.createElement(Navigate, { to: "/atlas/technologies", replace: true }),
                },
                detail: {
                    path: ':id',
                    children: {
                        index: {
                            index: true,
                            element: React.createElement(NavigateWithParams, { to: "/atlas/technologies/:id" }),
                        },
                    },
                },
            },
        },
        organizations: {
            path: 'organizations',
            children: {
                list: {
                    index: true,
                    element: React.createElement(NavigateWithParams, { to: "/atlas/vendors" }),
                },
                detail: {
                    path: ':id',
                    children: {
                        index: {
                            index: true,
                            element: React.createElement(NavigateWithParams, { to: "/atlas/vendors/:id" }),
                        },
                    },
                },
            },
        },
        medicalConditions: {
            path: 'medical-conditions',
            children: {
                list: {
                    index: true,
                    element: React.createElement(Navigate, { to: "/atlas/medical-conditions", replace: true }),
                },
                detail: {
                    path: ':id',
                    children: {
                        index: {
                            index: true,
                            element: (React.createElement(NavigateWithParams, { to: "/atlas/medical-conditions/:id" })),
                        },
                    },
                },
            },
        },
        measures: {
            path: 'explore/ontologies',
            element: React.createElement(Navigate, { to: "/atlas/measures", replace: true }),
        },
    },
};
export { AtlasRoutes, AtlasRedirects };
